import { Button } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import VerificationCodeTextField, {
	VerificationCodeTextFieldHelperText,
} from "../../pages/Profile/pages/Security/pages/TwoFAConfiguration/components/VerificationCodeTextField";
import { useVerifyCodeMutation } from "../../pages/Profile/pages/Security/pages/TwoFAConfiguration/queries/twoFactorAuthQueries";
import { apiErrorParser } from "../hooks/api";
import { useBetterMediaQuery } from "../hooks/useBetterMediaQuery";
import CertiblokIcon from "./icons/CertiblokIcon";
import LoadingTextButton from "./LoadingTextButton";
import { ModalNavigator, useModalNavigator, useModalRoute } from "./ModalNavigator/ModalNavigator";
import MLDialog from "./poppers";
import WhiteModal from "./WhiteModal";

type InsertSecurityCodeModalProps = {
	description?: string;
	isDisableTwoFA?: boolean;
	onSuccess?: () => void;
	onCancel?: () => void;
};
const InsertSecurityCodeModal = ModalNavigator.create(
	({ description, onSuccess, onCancel, isDisableTwoFA }: InsertSecurityCodeModalProps) => {
		const { t } = useTranslation();
		const { visible } = useModalRoute();
		const { popModal } = useModalNavigator();

		const isMd = useBetterMediaQuery("md");

		const [code, setCode] = useState("");
		const [helperText, setHelperText] = useState<VerificationCodeTextFieldHelperText>({ value: "", status: "success" });

		const verifyCodeMutation = useVerifyCodeMutation({
			variant: isDisableTwoFA ? "disableTwoFAVerification" : "standardVerification",
			onSuccess: () => {
				onSuccess?.();
				popModal();
			},
			onError: (err) => {
				switch (err) {
					case "InvalidCode":
						setHelperText({ value: t("settings.wrongCode"), status: "error" });
						break;
					case "ExpiredCode":
						setHelperText({ value: t("settings.expiredCode"), status: "error" });
						break;
					default:
						MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
						break;
				}
			},
		});

		const onClose = () => {
			if (verifyCodeMutation.isLoading) return;
			onCancel?.();
			popModal();
		};

		return (
			<WhiteModal
				open={visible}
				onClose={onClose}
				containerSx={{
					borderRadius: 10,
				}}
				responsive
			>
				<div className="flex flex-col gap-6">
					<div className="w-16 flex items-center justify-center h-16 md:w-[72px] md:h-[72px] bg-alert-a12 rounded-full">
						<CertiblokIcon size={isMd ? 48 : 40} color="warning" name={"shield_bold_01"} />
					</div>
					<div>
						<h6 className="text-h6 font-bold md:text-h4 md:font-normal pb-2">{t("settings.insertCode")}</h6>
						<p className="text-caption font-normal md:text-h6 text-black-a60">
							{description ?? t("global.authCodeNecessary")}
						</p>
						<VerificationCodeTextField
							value={code}
							onChange={(value) => {
								setCode(value);
								setHelperText({ value: "", status: "success" });
							}}
							onEnterPress={(code) => verifyCodeMutation.mutate(code)}
							className={"md:gap-1 lg:gap-5 mt-6 md:mt-9"}
							helperText={helperText}
						/>
					</div>
					<div className="flex justify-between gap-3 mt-3">
						<Button
							children={t("global.cancel")}
							startIcon={<CertiblokIcon size={24} color="inherit" name={"close"} />}
							size={isMd ? "large" : "small"}
							disabled={verifyCodeMutation.isLoading}
							onClick={onClose}
							variant="outlined"
							color="secondary"
						/>
						<LoadingTextButton
							children={t("global.confirm")}
							size={isMd ? "large" : "small"}
							variant="contained"
							loading={verifyCodeMutation.isLoading}
							disabled={verifyCodeMutation.isLoading || code.length < 6}
							onClick={() => verifyCodeMutation.mutate(code)}
							color="secondary"
							className={"flex-grow md:flex-grow-0 md:min-w-[206px]"}
						/>
					</div>
				</div>
			</WhiteModal>
		);
	}
);
export default InsertSecurityCodeModal;
