import { Button, Fade } from "@mui/material";
import { useAtom, useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import { SwitchTransition } from "react-transition-group";
import { useBetterMediaQuery } from "../../../hooks/useBetterMediaQuery";
import { useModalNavigator } from "../../ModalNavigator/ModalNavigator";
import LoadingIconButton from "../../buttons/LoadingIconButton";
import CertiblokIcon from "../../icons/CertiblokIcon";
import { selectedFolderAtom, uploadDocumentStepAtom } from "../atoms/uploadDocumentAtoms";
import { useUploadDocumentContext } from "../contexts/UploadDocumentContext";
import { useResetUploadDocuments } from "../hooks/useResetUploadDocuments";
import { useUploadFiles } from "../hooks/useUploadFiles";
import { useSaveUploadedDocumentEdits } from "../queries/uploadDocumentQueries";
import { useAreAllFilesUploaded, useCanUploadDocuments, useFilesToUploadCount } from "../stores/filesToUploadStore";
import { getUploadedCBDocuments, useHasUnsavedEdits } from "../stores/uploadedFilesStore";
import UnsavedEditsModal from "./UnsavedEditsModal";

const UploadDocumentFooter = () => {
	const { t } = useTranslation();
	const isMd = useBetterMediaQuery("md");

	const { onCancel, onSuccess, type } = useUploadDocumentContext();
	const isEditRoom = type === "uploadEditRoomDocument";

	const { popBottomSheet, pushModal } = useModalNavigator();

	const [activeStep, setActiveStep] = useAtom(uploadDocumentStepAtom);

	const selectedFolder = useAtomValue(selectedFolderAtom);
	const filesToUploadCount = useFilesToUploadCount();
	const areAllFilesUploaded = useAreAllFilesUploaded();
	const canUploadDocuments = useCanUploadDocuments();
	const uploadFiles = useUploadFiles();

	const hasUnsavedEdits = useHasUnsavedEdits();
	const saveUploadedDocumentEdits = useSaveUploadedDocumentEdits();

	// const navigate = useNavigate();

	const resetUploadDocuments = useResetUploadDocuments();

	const onClose = () => {
		if (activeStep === "chooseFolder") {
			onCancel?.();
		} else {
			onSuccess?.({ documents: getUploadedCBDocuments(), selectedFolder: selectedFolder ?? "" });
		}
		resetUploadDocuments();
		popBottomSheet();
	};

	const backToFolderButton = (
		<Button
			onClick={() => {
				setActiveStep("chooseFolder");
			}}
			children={t("uploadDocument.backToFolders")}
			variant="outlined"
			color="secondary"
			startIcon={<CertiblokIcon name="arrow_mini_outline_left" color="inherit" size={24} />}
			className="flex-shrink-0"
		/>
	);

	const uploadDocumentButton = (
		<Button
			children={
				!areAllFilesUploaded || filesToUploadCount === 0
					? t("uploadDocument.startUploading")
					: t("uploadDocument.goToAssign")
				// : isEditRoom
				// ? t("editRoom.saveInEditRoom")
			}
			color="secondary"
			variant="contained"
			disabled={!canUploadDocuments}
			onClick={() => {
				areAllFilesUploaded
					? setActiveStep("recap")
					: // ? isEditRoom
					  // 	? saveUploadedDocumentEdits.mutate(
					  // 			{},
					  // 			{
					  // 				onSuccess: () => {
					  // 					onClose();
					  // 				},
					  // 			}
					  // 	  )
					  uploadFiles();
			}}
			className="flex-grow md:flex-grow-0"
			fullWidth={!isMd}
			startIcon={<CertiblokIcon size={24} color="inherit" name={"success"} />}
		/>
	);

	return (
		<div className="flex items-center justify-between gap-6">
			{activeStep === "chooseFolder" && (
				<>
					<Button
						onClick={onClose}
						children={t("global.cancel")}
						variant="outlined"
						color="secondary"
						startIcon={<CertiblokIcon name="close" color="inherit" size={24} />}
					/>
					<Button
						sx={{
							"&.Mui-disabled": {
								opacity: 1,
							},
						}}
						children={isMd ? t("uploadDocument.continue") : t("global.continue")}
						color="secondary"
						variant="contained"
						disabled={!selectedFolder}
						onClick={() => setActiveStep("uploadDocuments")}
						className="flex-grow md:flex-grow-0"
						startIcon={<CertiblokIcon size={24} color="inherit" name={"success"} />}
					/>
				</>
			)}
			{activeStep === "uploadDocuments" &&
				(isMd ? (
					<>
						<Fade in={filesToUploadCount === 0}>{backToFolderButton}</Fade>
						<Fade in={filesToUploadCount > 0}>{uploadDocumentButton}</Fade>
					</>
				) : (
					<SwitchTransition>
						<Fade key={filesToUploadCount === 0 ? "backToFolder" : "uploadDocuments"}>
							{filesToUploadCount === 0 ? backToFolderButton : uploadDocumentButton}
						</Fade>
					</SwitchTransition>
				))}
			{activeStep === "recap" && (
				<>
					<Button
						onClick={() => {
							if (hasUnsavedEdits) {
								setTimeout(() => {
									pushModal(UnsavedEditsModal, {}).then(() => {
										onClose();
									});
								}, 0);
								return;
							}
							onClose();
						}}
						children={t("global.close")}
						variant="outlined"
						color="secondary"
						startIcon={<CertiblokIcon name="close" color="inherit" size={24} />}
					/>
					<LoadingIconButton
						loading={saveUploadedDocumentEdits.isLoading}
						children={isMd ? (isEditRoom ? t("editRoom.saveInEditRoom") : t("global.saveEdits")) : t("global.save")}
						color="secondary"
						variant="contained"
						onClick={() => {
							saveUploadedDocumentEdits.mutate(
								{},
								{
									onSuccess: () => {
										onClose();
										// navigate(`/folders/${selectedFolder}`);
									},
								}
							);
						}}
						className="flex-grow md:flex-grow-0"
						icon={<CertiblokIcon size={24} color="inherit" name={"success"} />}
					/>
				</>
			)}
		</div>
	);
};
export default UploadDocumentFooter;
