import { useEffect } from "react";
import { useMe } from "../hooks/useMe";
import { setUser } from "@sentry/react";

type SentryUserLoggerProps = {};
const SentryUserLogger: React.FC<SentryUserLoggerProps> = () => {
	const { me: user } = useMe();

	useEffect(() => {
		if (user) {
			setUser({
				email: user.email ?? undefined,
				id: user.id,
			});
		} else {
			setUser(null);
		}
	}, [user]);
	return null;
};

export default SentryUserLogger;
