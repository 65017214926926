import { Box, Button, Fade, IconButton, ModalProps, Typography, useTheme } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { DocumentApi, DocumentmanagerApi, EditRoomApi } from "certiblok-api-manager";
import clsx from "clsx";
import { DateTime } from "luxon";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import type { DropEvent, FileRejection } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { apiErrorParser, instanceApi, useApi } from "../../hooks/api";
import { useBetterMediaQuery } from "../../hooks/useBetterMediaQuery";
import type { CertiblokDocument } from "../../interfaces/Document";
import DocumentPicker from "../DocumentPicker";
import DocumentWithIcon from "../DocumentWithIcon";
import WhiteModal from "../WhiteModal";
import BottomSheet, { BottomSheetV2Ref } from "../bottomSheet/BottomSheet";
import { useBottomSheetOld } from "../bottomSheet/hooks/useBottomSheet";
import LoadingIconButton from "../buttons/LoadingIconButton";
import CertiblokIcon from "../icons/CertiblokIcon";
import FileIcon from "../icons/FileIcon";
import MLDialog from "../poppers";
import AssignExpireDate from "./AssignExpireDate";
import mime from "mime";
import { useIsEditRoom } from "../../../pages/SingleDocument/hooks/useIsEditRoom";
import CertiblokTextInput from "../CertiblokTextInput";

type UpdateOrReplaceFileProps = Omit<ModalProps, "children"> & {
	document: CertiblokDocument;
	toggleOpen: () => void;
	update?: boolean;
	revalidate?: () => void;
	onSuccess?: (document: CertiblokDocument) => void;
};

const UpdateOrReplaceFile: React.FC<UpdateOrReplaceFileProps> = ({
	document,
	toggleOpen,
	update,
	revalidate,
	onSuccess,
	...otherProps
}) => {
	const isMd = useBetterMediaQuery("md");
	const [searchParams, setSearchParams] = useSearchParams();
	const { isInEditRoom } = useIsEditRoom();

	//* APIS
	const documentApi = useApi(DocumentApi);
	const editRoomApi = useApi(EditRoomApi);

	//* STATES
	const theme = useTheme();
	const { t } = useTranslation();
	const [newFile, setNewFile] = useState<{ file: File; url: string } | undefined>();
	const { open, toggleOpen: bsToggleOpen } = useBottomSheetOld();
	const bottomSheetRef = useRef<BottomSheetV2Ref>(null);
	const [newExpireDate, setNewExpireDate] = useState<DateTime | undefined>();
	const [editRoomAnnotation, setEditRoomAnnotation] = useState<string>();

	//* HANDLERS
	const onDrop = useCallback<
		<T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void
	>((acceptedFiles, fileRejections) => {
		acceptedFiles.forEach((file: File) => {
			const url = URL.createObjectURL(file);
			setNewFile({ file, url });
		});

		fileRejections.forEach((fileRejection) => {
			MLDialog.showSnackbar(t("uploadDocument.fileRejection", { filename: fileRejection.file.name }));
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const submitUpdateMutation = useMutation({
		mutationFn: async () => {
			if (!newFile) return;

			const {
				data: { uploadUrl, fileUrl },
			} = await instanceApi(DocumentmanagerApi).getUploadSignedUrlDocument({
				fileName: newFile?.file.name,
				size: newFile?.file.size,
			});
			await axios.put(uploadUrl, newFile?.file, {
				headers: {
					"Content-Type": "",
				},
			});

			if (!isInEditRoom) {
				const newDocument = await documentApi.newVersionFileOfDocumentLimitless(document.id, {
					expireDate: newExpireDate?.toISO() || "",
					uploadedFile: {
						mimetype: newFile?.file.type || mime.getType(newFile?.file.name) || "",
						originalname: newFile?.file.name,
						size: newFile?.file.size,
						url: fileUrl,
					},
				});
				return newDocument;
			} else {
				const editRoomDocument = await editRoomApi.uploadNewEditRoomDocumentVersion(document.id, {
					expireDate: newExpireDate?.toISO() || "",
					annotation: editRoomAnnotation,
					uploadedFile: {
						mimetype: newFile?.file.type || mime.getType(newFile?.file.name) || "",
						originalname: newFile?.file.name,
						size: newFile?.file.size,
						url: fileUrl,
					},
				});
				return editRoomDocument;
			}
		},
		onSuccess: (newDocument) => {
			if (searchParams.get("update") === "true") {
				setSearchParams({}, { replace: true });
			}
			onSuccess?.({ ...document, ...newDocument });
			toggleOpen();
			revalidate?.();
		},
		onError: (err) => {
			MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
		},
	});

	useEffect(() => {
		setNewFile(undefined);
	}, [otherProps.open]);

	const onCloseExpireDate = () => {
		bottomSheetRef.current?.bringToFront();
		bsToggleOpen();
	};

	useEffect(() => {
		if (open) {
			onCloseExpireDate();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMd]);

	const header = (
		<Typography
			children={update ? t("updateOrReplaceFile.uploadUpdate") : t("updateOrReplaceFile.uploadReplace")}
			variant="subtitle1"
		/>
	);

	const body = (
		<Box
			sx={{
				flexGrow: 1,
				position: "relative",
				height: { xs: "100%", md: "auto" },
			}}
		>
			<Fade in={newFile === undefined} mountOnEnter unmountOnExit>
				<Box sx={{ position: "absolute", top: 0, width: "100%" }}>
					<DocumentWithIcon
						name={document.name}
						document={document.lastVersionFile?.[0]}
						sx={{ py: 3, pt: { xs: 0, md: 3 }, height: 28 }}
					/>
					<DocumentPicker
						onDrop={onDrop}
						css={{ height: 234, margin: 0, width: "100%" }}
						externalContainerCss={{ height: "auto" }}
					/>
				</Box>
			</Fade>
			<Fade in={newFile !== undefined} mountOnEnter unmountOnExit>
				<Box
					sx={{
						height: "100%",
						width: "100%",
						pt: { xs: 0, md: 6 },
						display: "flex",
						gap: 6,
						flexDirection: "column",
					}}
				>
					<Box>
						<Typography children={t("updateOrReplaceFile.previousDocument")} variant="label" />

						<DocumentWithIcon document={document.lastVersionFile?.[0]} name={document.name} sx={{ pt: 1, height: 28 }} />
					</Box>
					<Box>
						<Typography children={t("updateOrReplaceFile.newDocument")} variant="label" />
						<Box sx={{ width: "100%", position: "relative", mt: 1 }}>
							<Button
								href={newFile?.url}
								component="a"
								target="_blank"
								rel="noopener noreferrer"
								size="medium"
								fullWidth
								color="secondary"
								sx={{ height: 48, justifyContent: "start", backgroundColor: theme.palette.backgroundSecondary.default }}
							>
								{newFile && <FileIcon file={newFile.file} />}
								{newFile && (
									<Typography
										children={newFile?.file?.name}
										variant="subtitle2"
										noWrap
										sx={{
											fontWeight: 700,
											color: "black",
											width: `calc(100% - ${24 + 40}px)`,
											textAlign: "start",
										}}
									/>
								)}
							</Button>
							<Box sx={{ position: "absolute", right: 0, top: 0, height: "100%", display: "flex", alignItems: "center" }}>
								<IconButton
									onClick={() => {
										setNewFile(undefined);
									}}
								>
									<CertiblokIcon size={24} color="secondary" name={"delete_outline"} />
								</IconButton>
							</Box>
						</Box>
					</Box>
					<Box
						sx={{
							width: "100%",
						}}
					>
						<Typography
							component="p"
							children={t("updateOrReplaceFile.updateExpireDate")}
							variant="label"
							sx={{ width: "100%", paddingBottom: 1 }}
						/>
						<Button
							variant="outlined"
							sx={{
								color: "black",
								borderRadius: 3,
								borderColor: "rgba(0, 0, 0, 0.12)",
								"&:hover": {
									borderColor: "rgba(0, 0, 0, 0.24)",
									backgroundColor: "rgba(0, 0, 0, 0.12)",
								},
							}}
							onClick={() => bsToggleOpen()}
						>
							<CertiblokIcon size={16} color="black" name={"calendar_outline"} />
							<Typography
								children={
									newExpireDate
										? newExpireDate.toFormat("dd.MM.yyyy")
										: document.expireDate
										? DateTime.fromISO(document.expireDate).toFormat("dd.MM.yyyy")
										: t("updateOrReplaceFile.assignExpireDate")
								}
								variant="body2"
								sx={{ opacity: 0.74, flexGrow: 1, textAlign: "start", lineHeight: "unset" }}
							/>
							<CertiblokIcon size={16} color="rgba(0,0,0,0.6)" name={"▾-arrow-3"} />
						</Button>
					</Box>
					{isInEditRoom && (
						<CertiblokTextInput
							label={t("editRoom.insertComment")}
							color="secondary"
							multiline
							maxRows={2}
							placeholder={t("editRoom.insertCommentDescription")}
							value={editRoomAnnotation}
							onChange={(e) => setEditRoomAnnotation(e.target.value)}
						/>
					)}
				</Box>
			</Fade>
		</Box>
	);

	const footer = (
		<Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", paddingTop: 4 }}>
			<Button variant="outlined" color="secondary" onClick={() => toggleOpen()} disabled={submitUpdateMutation.isLoading}>
				<CertiblokIcon size={24} color="inherit" name={"close"} />
				{t("global.cancel")}
			</Button>
			<LoadingIconButton
				icon={<CertiblokIcon size={24} color="inherit" name={"success"} />}
				variant="contained"
				disabled={!newFile}
				color="secondary"
				onClick={() => {
					submitUpdateMutation.mutate();
				}}
				loading={submitUpdateMutation.isLoading}
				children={t("global.saveEdits")}
			/>
		</Box>
	);

	const onClose = () => {
		if (submitUpdateMutation.isLoading) return;
		toggleOpen();
	};

	return (
		<Fragment>
			{isMd ? (
				<WhiteModal
					onClose={onClose}
					containerSx={{
						width: {
							xs: 400,
							md: 500,
							lg: 600,
						},
						height: newFile ? (isInEditRoom ? 500 : 400) : 442,
						boxSizing: "border-box",
						transition: theme.transitions.create(["width", "height", "opacity"]),
						display: "flex",
						flexDirection: "column",
						borderRadius: 10,
					}}
					keepMounted={false}
					{...otherProps}
				>
					<Fragment>
						{header}
						{body}
						{footer}
					</Fragment>
				</WhiteModal>
			) : (
				<BottomSheet
					open={otherProps.open}
					onClose={onClose}
					header={header}
					footer={footer}
					ref={bottomSheetRef}
					// className={clsx(newFile ? "h-[400px]" : "h-[442px]")}
					BodyProps={{
						className: clsx("flex-shrink-0 px-5 basis-[unset] min-h-fit", !newFile ? "h-[286px]" : "overflow-visible"),
					}}
				>
					{body}
				</BottomSheet>
			)}
			<AssignExpireDate
				document={document}
				open={open}
				toggleOpen={onCloseExpireDate}
				onSubmitDate={(date: DateTime) => {
					setNewExpireDate(date);
					onCloseExpireDate();
				}}
				onHeightChange={(height) => {
					if (bottomSheetRef.current) {
						bottomSheetRef.current.sendToBack({ frontElemHeight: height ?? 0 });
					}
				}}
				initialDate={
					newExpireDate ? newExpireDate : document.expireDate ? DateTime.fromISO(document.expireDate) : undefined
				}
			/>
		</Fragment>
	);
};

export default UpdateOrReplaceFile;
