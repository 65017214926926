import { Drawer, styled } from "@mui/material";
import { LG_DRAWER_OPEN_WIDTH } from "../constants/DrawerConstants";

const StyledDrawer = styled(Drawer)(({ theme, open }) => ({
	width: LG_DRAWER_OPEN_WIDTH,
	// transition: theme.transitions.create(["width"]),
	backgroundColor: theme.palette.background.default,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	"& .MuiPaper-root": {
		border: "unset",
		boxSizing: "border-box",
		overflowY: "unset",
		// transition: theme.transitions.create(["width"]),
		backgroundColor: theme.palette.background.default,
		...(!open && {
			visibility: "unset !important",
			transform: "unset !important",
		}),
	},
}));

export default StyledDrawer;
