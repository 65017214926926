import { cn } from "@mabi-ui/utils";
import { Skeleton, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import avatarPlaceholder from "../../../../assets/avatar-placeholder.png";
import { backendMediaUrlParser } from "../../../../utils";
import { useAbility } from "../../../../utils/hooks/useAbilityRules";
import { useSelectedLanguage } from "../../../../utils/hooks/useLanguageSwitcher";
import { useMe } from "../../../../utils/hooks/useMe";
import MembersManagement from "./MembersManagement";

type WelcomeCardProps = { className?: string };

const UserAndTeamCard: React.FC<WelcomeCardProps> = ({ className }) => {
	const { t } = useTranslation();
	const { me, loading } = useMe();

	const selectedLanguage = useSelectedLanguage();

	const now = DateTime.now();

	const { ability } = useAbility();
	const isAdmin = ability.can("update", "Company");

	return (
		<div className={cn("flex flex-row gap-x-4 gap-y-10 md:pb-8 pb-10 items-start", className)}>
			<div className="flex items-start md:gap-6 gap-4 flex-1">
				<div className="rounded-full border-primary border-[3px] border-solid w-[48px] shrink-0 h-[48px] md:w-[88px] md:h-[88px] overflow-hidden">
					<img src={backendMediaUrlParser(me?.profileUrl) ?? avatarPlaceholder} alt="Profile" className="w-full h-full" />
				</div>
				{loading ? (
					<div className="flex-1">
						<Skeleton width={"50%"} height={33} className="mt-1" />
						<Skeleton width={"40%"} height={20} className="mt-2" />
					</div>
				) : (
					<div className="flex-1 flex flex-col md:gap-2 gap-1">
						<h5 className="text-h5 md:leading-9 md:text-[24px] sm:leading-7 sm:text-[22px] leading-5 text-[18px] text-primary mt-1">
							{new Date().getHours() < 14
								? t("dashboard.goodMorning", { name: me?.name ?? "" })
								: t("dashboard.goodEvening", { name: me?.name ?? "" })}
						</h5>
						<Typography variant="body1" className="text-primary sm:text-[16px] text-[14px]">
							{`${now.day === 1 || now.day === 8 || now.day === 11 ? t("dashboard.todayIsAlt") : t("dashboard.todayIs")}
						 ${now.toLocaleString(DateTime.DATE_FULL, {
								locale: selectedLanguage === "it" ? "it-IT" : "en-US",
							})}`}
						</Typography>
					</div>
				)}
			</div>
			{!loading && isAdmin && <MembersManagement />}
		</div>
	);
};

export default UserAndTeamCard;
