import { useMemo } from "react";
import { z } from "zod";
import { useUserDetailsZodSchema } from "./useUserDetailsZodSchema";

export type GroupFormValues = z.infer<ReturnType<typeof useGroupDetailsZodSchema>>;

export const useGroupDetailsZodSchema = () => {
	const userSchema = useUserDetailsZodSchema();

	return useMemo(() => {
		return z.object({
			id: z.string(),
			name: z.string().optional(),
			color: z.string().optional(),
			users: userSchema.array().optional(),
			isNewAddedGroup: z.boolean().optional(),
			teamId: z.string().optional(),
		});
	}, [userSchema]);
};
