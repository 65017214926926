import { Box, Button, Fade, FormControlLabel, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import DocumentWithIcon from "../../../../../utils/components/DocumentWithIcon";
import FolderPicker from "../../../../../utils/components/FolderPicker/FolderPicker";
import SelectedFoldersBreadcrumbs from "../../../../../utils/components/FolderPicker/components/SelectedFoldersBreadcrumbs";
import { useFolderPicker } from "../../../../../utils/components/FolderPicker/controllers/useFolderPicker";
import IOSSwitch from "../../../../../utils/components/IOSSwitch";
import LoadingIconButton from "../../../../../utils/components/buttons/LoadingIconButton";
import CertiblokIcon from "../../../../../utils/components/icons/CertiblokIcon";
import { MoveDocumentContext } from "../controllers/MoveDocumentContext";
import { useIsEditRoom } from "../../../../SingleDocument/hooks/useIsEditRoom";
import { useBetterMediaQuery } from "../../../../../utils/hooks/useBetterMediaQuery";

type Props = {};

export const PickFolderFooter = () => {
	const moveDocumentContext = useContext(MoveDocumentContext);
	const { t } = useTranslation();
	const isMd = useBetterMediaQuery("md");
	return (
		<Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", gap: 3 }}>
			<Button variant="outlined" color="secondary" onClick={() => moveDocumentContext.toggleOpen()}>
				<CertiblokIcon size={24} color="secondary" name={"close"} />
				{t("global.cancel")}
			</Button>
			<LoadingIconButton
				variant="contained"
				color="secondary"
				disabled={moveDocumentContext.selectedFolder === undefined}
				onClick={() => {
					if (moveDocumentContext.shouldReplaceChecked) moveDocumentContext.replaceDocumentBS.toggleOpen();
					else moveDocumentContext.moveToFolder();
				}}
				icon={<CertiblokIcon size={24} color="inherit" name={"success"} />}
				loading={moveDocumentContext.updateLoading}
			>
				{isMd ? t("moveDocuments.move") : t("global.move")}
			</LoadingIconButton>
		</Box>
	);
};

const PickFolder = React.forwardRef<any, Props>(({ ...props }, ref) => {
	const moveDocumentContext = useContext(MoveDocumentContext);

	const folderPicker = useFolderPicker();

	const { t } = useTranslation();

	const { isInEditRoom } = useIsEditRoom();

	return (
		<Box ref={ref} {...props}>
			<Box sx={{ pb: 6 }}>
				<Typography
					children={t("moveDocuments.moveDocuments", { count: moveDocumentContext.currentDocuments.length })}
					variant="subtitle1"
					sx={{ paddingBottom: 6 }}
				/>
				<Box sx={{ flexGrow: 1, display: "flex", gap: 4, boxSizing: "border-box", flexWrap: "wrap" }}>
					{moveDocumentContext.currentDocuments.map((document) => (
						<DocumentWithIcon name={document.name} key={document.id} document={document.lastVersionFile?.[0]} bold />
					))}
				</Box>
			</Box>
			<Fade in={moveDocumentContext.foldersHistory.length > 0} mountOnEnter unmountOnExit>
				<Box sx={{ pb: 6, minHeight: 56 }}>
					<Typography
						children={t("moveDocuments.assignToSubfolder", { count: moveDocumentContext.foldersHistory.length })}
						variant="subtitle1"
					/>
					<SelectedFoldersBreadcrumbs
						folderHistory={moveDocumentContext.foldersHistory}
						onFolderDelete={(folder) => {
							folderPicker.clearAvailableFolders();
							moveDocumentContext.setCurrentFolder(moveDocumentContext.popToFolderFromHistory(folder));
						}}
						isLoading={folderPicker.loading}
						hasMoreSubfolders={folderPicker.availableFolders.length > 0}
					/>
				</Box>
			</Fade>

			{moveDocumentContext.currentDocuments.length === 1 && !isInEditRoom && (
				<FormControlLabel
					color="secondary"
					control={
						<IOSSwitch
							checked={moveDocumentContext.shouldReplaceChecked}
							onClick={moveDocumentContext.toggleShouldReplaceChecked}
							color="secondary"
							variant="filled"
						/>
					}
					label={t("moveDocuments.isReplacing")}
					sx={{
						flexDirection: "row-reverse",
						justifyContent: "flex-end",
						paddingBottom: 6,
						gap: "10px",
						margin: 0,
						"& .MuiFormControlLabel-label": {
							typography: "body2",
						},
					}}
				/>
			)}
			<FolderPicker
				folderPickerControls={folderPicker}
				selectedFolder={moveDocumentContext.selectedFolder}
				onFolderSelect={(folder) => {
					moveDocumentContext.pushFolderInHistory(folder);
					moveDocumentContext.setCurrentFolder(folder);
				}}
				shouldStartFetching={moveDocumentContext.open}
			/>
		</Box>
	);
});

export default PickFolder;
