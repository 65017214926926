import { fromDate } from "@internationalized/date";
import { Button, Divider, Typography } from "@mui/material";
import { EventInstance } from "certiblok-api-manager";
import { useCallback, useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
	ModalNavigator,
	useModalNavigator,
	useModalRoute,
} from "../../../../../../utils/components/ModalNavigator/ModalNavigator";
import LoadingIconButton from "../../../../../../utils/components/buttons/LoadingIconButton";
import ResponsiveDialog from "../../../../../../utils/components/dialogs/ResponsiveDialog";
import CertiblokIcon from "../../../../../../utils/components/icons/CertiblokIcon";
import MLDialog from "../../../../../../utils/components/poppers";
import { formDataToSearchParams } from "../../functions/advancedSettings";
import { useCreateEvent } from "../../hooks/useCreateEvent";
import { useEditCreateEventForm } from "../../hooks/useEditCreateEventForm";
import { EditCreateEventForm } from "../../hooks/useEditCreateEventFormValidator";
import { useInitEditCreateEventForm } from "../../hooks/useInitEditCreateEventForm";
import EditCreateEventAllDayCheckbox from "../EditCreateEventAllDayCheckbox";
import EditCreateEventDates from "../EditCreateEventDates";
import EditCreateEventSharedWith from "../EditCreateEventSharedWith";
import EditCreateEventTimes from "../EditCreateEventTimes";
import EditCreateEventName from "../EditCreateEventTitle";
import AddVideoConferenceButton from "../AddVideoConferenceButton";

type CreateEventDialogProps = {
	startDate?: Date;
	onSuccess?: (event: EventInstance) => void;
	onCancel?: () => void;
	onAdvancedSettings?: () => void;
};
const CreateEventDialog = ModalNavigator.create<CreateEventDialogProps>(
	({ onSuccess, onCancel, startDate, onAdvancedSettings }) => {
		const { t } = useTranslation();
		const navigate = useNavigate();

		const { visible } = useModalRoute();
		const { popModal } = useModalNavigator();

		const form = useEditCreateEventForm();
		const initialFormData = useMemo<Partial<EditCreateEventForm>>(() => {
			if (!startDate)
				return {
					startDate: null,
					endDate: null,
				};
			const zonedStartDate = fromDate(startDate, Intl.DateTimeFormat().resolvedOptions().timeZone);
			return {
				startDate: zonedStartDate,
				endDate: zonedStartDate,
			};
		}, [startDate]);

		useInitEditCreateEventForm({ form, initialValue: initialFormData });

		const editCreateEventMutation = useCreateEvent();

		const closeAndReset = useCallback(() => {
			popModal();
			form.reset({
				startDate: null,
				endDate: null,
				sharedWith: [],
			});
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		const onClose = () => {
			if (editCreateEventMutation.isLoading) return;
			closeAndReset();
			onCancel?.();
		};

		const handleSubmit = useCallback(() => {
			editCreateEventMutation.mutate(
				{ form },
				{
					onSuccess: (newEvent) => {
						closeAndReset();
						onSuccess?.(newEvent);
					},
				}
			);
		}, [closeAndReset, editCreateEventMutation, form, onSuccess]);

		const onErrors = useCallback(
			(errors: (typeof form)["formState"]["errors"]) => {
				MLDialog.showSnackbar(t("events.formError"), { variant: "error" });
			},
			[t]
		);

		return (
			<ResponsiveDialog
				ModalProps={{
					containerSx: {
						borderRadius: 6,
					},
					responsive: true,
				}}
				BottomSheetProps={{
					FooterProps: {
						className: "pt-0",
					},
				}}
				open={visible}
				onClose={onClose}
				header={<Typography variant="subtitle1">{t("events.createNewEvent")}</Typography>}
				footer={
					<div>
						<Divider />
						<div className="flex mt-6 justify-between items-center gap-3">
							<Button disabled={editCreateEventMutation.isLoading} onClick={onClose} variant="outlined" color="secondary">
								<CertiblokIcon color="inherit" name="close" />
								{t("global.cancel")}
							</Button>
							<LoadingIconButton
								icon={<CertiblokIcon color="inherit" name="success" />}
								loading={editCreateEventMutation.isLoading}
								variant="contained"
								onClick={() => {
									form.handleSubmit(handleSubmit, onErrors)();
								}}
								color="secondary"
							>
								{t("event.addEvent")}
							</LoadingIconButton>
						</div>
					</div>
				}
			>
				<FormProvider {...form}>
					<form
						onSubmit={form.handleSubmit(handleSubmit, onErrors)}
						className=" w-full px-5 pb-6 md:py-6 md:px-0 box-border md:max-h-[60vh] md:overflow-auto"
					>
						<div className="flex flex-col gap-6 px-1">
							<EditCreateEventName />
							<EditCreateEventDates />
							<EditCreateEventAllDayCheckbox />
							<EditCreateEventTimes />
							<AddVideoConferenceButton />
							<EditCreateEventSharedWith className="px-0 md:overflow-visible" />
							<Button
								color="secondary"
								onClick={() => {
									const searchParams = formDataToSearchParams(form.getValues());
									navigate(`/calendar/event/create?formData=${JSON.stringify(searchParams)}`);
									onClose();
									onAdvancedSettings?.();
								}}
								className="w-fit"
							>
								{t("events.advancedSettings")}
							</Button>
						</div>
					</form>
				</FormProvider>
			</ResponsiveDialog>
		);
	}
);

export default CreateEventDialog;
