import { Typography, alpha } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ToggleTwoFAModal from "../../../../../../../pages/Profile/pages/Security/components/ToggleTwoFAModal";
import TwoFADisabledSuccessfully from "../../../../../../../pages/Profile/pages/Security/components/TwoFADisabledSuccessfully";
import useMLDialog from "../../../../../../MLDialogV2/controller/useMLDialog";
import { useMe } from "../../../../../../hooks/useMe";
import { ContainedTab, ContainedTabs } from "../../../../../ContainedTabBar";
import InsertSecurityCodeModal from "../../../../../InsertSecurityCodeModal";

export const DrawerAuthenticationInfo = () => {
	const { t } = useTranslation();
	const { me, reload: reloadMe, loading } = useMe();

	const [checkedAuthentication, setCheckedAuthentication] = useState(me?.enabledTfa ?? false);
	const { showCustomModal } = useMLDialog();
	const navigate = useNavigate();

	useEffect(() => {
		setCheckedAuthentication(me?.enabledTfa ?? false);
	}, [me]);

	return (
		<div className="mt-4 mb-4">
			<Typography
				component={"p"}
				variant="caption"
				sx={{ opacity: 0.74, width: "100%", textAlign: "center", color: alpha("#000000", 0.87), mb: 2 }}
				children={t("settings.twoFactorAuth")}
			/>
			<ContainedTabs
				disabled={loading}
				value={loading ? false : checkedAuthentication ? "active" : "non-active"}
				onChange={async (ev, checked) => {
					ev.stopPropagation();
					ev.preventDefault();
					setCheckedAuthentication(checked === "active" ? true : false);
					try {
						await showCustomModal(ToggleTwoFAModal, {
							isActivatingTFA: checked === "active",
						});
						if (checked === "active") {
							navigate("/profile/security/two-factor");
						} else {
							await showCustomModal(InsertSecurityCodeModal, {
								description: t("settings.disableTwoFAWithCode"),
								isDisableTwoFA: true,
							});
							reloadMe();
							showCustomModal(TwoFADisabledSuccessfully, {});
						}
					} catch (e) {
						setCheckedAuthentication((prev) => !prev);
					}
				}}
			>
				<ContainedTab value={"non-active"} label={t("settings.notActive")} className="[&.Mui-selected]:bg-error flex-1" />
				<ContainedTab value={"active"} label={t("settings.active")} className="[&.Mui-selected]:bg-primary flex-1" />
			</ContainedTabs>
		</div>
	);
};
