import { Company } from "certiblok-api-manager";

export const getCompanyRole = (company: Company, userId: string) => {
	if (!company || !userId) {
		return "default";
	}

	if (company.ownerId === userId) {
		return "owner";
	}

	if (company?.adminIds?.includes(userId)) {
		return "admin";
	}

	return "member";
};
