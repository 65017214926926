import type { Folder } from "certiblok-api-manager";
import { useState, useCallback } from "react";

export function useFolderHistory() {
	const [foldersHistory, setFoldersHistory] = useState<Folder[]>([]);

	const pushFolderInHistory = useCallback((selectedFolder: Folder) => {
		setFoldersHistory((prev) => [...prev, selectedFolder]);
		return selectedFolder;
	}, []);

	const popFolderFromHistory = useCallback(() => {
		foldersHistory.pop();
		setFoldersHistory([...foldersHistory]);
		return foldersHistory[foldersHistory.length - 1];
	}, [foldersHistory]);

	const popToFolderFromHistory = useCallback(
		(folder: Folder) => {
			const index = foldersHistory.findIndex((elem) => elem.id === folder.id);
			foldersHistory.splice(index, foldersHistory.length - index);
			setFoldersHistory([...foldersHistory]);
			return foldersHistory[foldersHistory.length - 1];
		},
		[foldersHistory]
	);

	const resetFolderHistory = useCallback(() => {
		setFoldersHistory([]);
	}, []);

	return { foldersHistory, pushFolderInHistory, popFolderFromHistory, popToFolderFromHistory, resetFolderHistory };
}
