import useResizeObserver from "@react-hook/resize-observer";
import type React from "react";
import { useState } from "react";

export const useBottomSheetHeight = (target: React.RefObject<HTMLDivElement>) => {
	const [height, setHeight] = useState<number>();

	// Where the magic happens
	useResizeObserver(target.current, (entry) => {
		if (height !== entry.target.clientHeight) setHeight(entry.target.clientHeight);
	});
	return [height, setHeight] as [number, React.Dispatch<React.SetStateAction<number | undefined>>];
};
