/** @jsxImportSource @emotion/react */
import { cn } from "@mabi-ui/utils";
import { ButtonBase, Tooltip, Typography } from "@mui/material";
import { alpha } from "@mui/system";
import { Tag } from "certiblok-api-manager";
import CertiblokIcon from "../../../../utils/components/icons/CertiblokIcon";
import { useModalNavigator } from "../../../../utils/components/ModalNavigator/ModalNavigator";
import DeleteTagModal from "./DeleteTagModal";

export type SingleTagProps = {
	tag: Tag;
	size?: "small" | "medium" | "large";
	isDeletable?: boolean;
	isEditable?: boolean;
	onClick?: (tag: Tag) => void;
	className?: string;
	typographyClassName?: string;
	hideTooltip?: boolean;
	onDelete?: () => void;
	onEdit?: () => void;
	onDeleteSuccess?: (tag: Tag) => void;
	onEditSuccess?: (tag: Tag) => void;
};

const SingleTag = ({
	tag,
	size = "medium",
	isEditable = false,
	isDeletable = false,
	onClick,
	className,
	typographyClassName,
	hideTooltip,
	onDelete,
	onEdit,
	onDeleteSuccess,
	onEditSuccess,
}: SingleTagProps) => {
	const { pushModal, pushModalById } = useModalNavigator();
	const isClickable = Boolean(onClick);

	return (
		<Tooltip title={hideTooltip ? "" : tag.name}>
			<div
				onClick={() => onClick?.(tag)}
				css={{
					backgroundColor: alpha(tag.color, 0.24),
					"&:hover": { backgroundColor: isClickable ? alpha(tag.color, 0.32) : alpha(tag.color, 0.24) },
					"&:active": {
						backgroundColor: isClickable ? alpha(tag.color, 0.38) : alpha(tag.color, 0.24),
					},
				}}
				className={cn(
					size === "small"
						? "px-2 py-1 max-w-[70px]"
						: size === "medium"
						? "px-4 py-1 max-w-[100px]"
						: "px-4 py-1 max-w-[120px]",
					"relative rounded-md flex items-center justify-between",
					onClick && "cursor-pointer",
					(isDeletable || isEditable) && (size === "small" ? "pr-1 gap-2 max-w-[90px]" : "pr-1 gap-2 max-w-[130px]"),
					isDeletable && isEditable && (size === "small" ? "max-w-[120px]" : "max-w-[200px]"),
					className
				)}
			>
				<Typography
					variant={size === "small" ? "label" : "body2"}
					noWrap
					className={cn(size === "medium" && "text-[14px]", "text-[rgba(0,0,0,0.8)]", typographyClassName)}
				>
					{tag.name}
				</Typography>
				{(isEditable || isDeletable) && (
					<div className="flex items-center gap-[2px] flex-shrink-0">
						{isEditable && (
							<div className={cn("w-6 h-6 relative flex-shrink-0", size === "small" && "w-4 h-4")}>
								<ButtonBase
									className={cn(
										"absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 cursor-pointer flex items-center rounded-full p-[3px] justify-center hover:bg-grey/[0.24]",
										size === "small" && "p-[1px]"
									)}
									onClick={() => {
										if (Boolean(onEdit)) {
											onEdit?.();
										} else {
											pushModalById(
												"edit-create-tag",
												{ key: tag.id + tag.name + tag.color, oldTag: tag, onEditCreateSuccess: onEditSuccess },
												{ shouldHidePrev: true }
											);
										}
									}}
								>
									<CertiblokIcon name="edit_outline_02" size={size === "small" ? 14 : 16} color="rgba(0,0,0,0.8)" />
								</ButtonBase>
							</div>
						)}
						{isDeletable && (
							<div className={cn("w-6 h-6 relative flex-shrink-0", size === "small" && "w-4 h-4")}>
								<ButtonBase
									className={cn(
										"absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 cursor-pointer flex items-center rounded-full w-6 h-6 p-[3px] justify-center hover:bg-grey/[0.24]",
										size === "small" && "p-[1px] w-[18px] h-[18px]"
									)}
									onClick={() => {
										if (Boolean(onDelete)) {
											onDelete?.();
										} else {
											pushModal(
												DeleteTagModal,
												{
													tagId: tag.id,
													onSuccess: (tag) => {
														if (tag) {
															onDeleteSuccess?.(tag);
														}
													},
												},
												{ shouldHidePrev: true }
											);
										}
									}}
								>
									<CertiblokIcon name="close" size={size === "small" ? 16 : 18} color="rgba(0,0,0,0.8)" />
								</ButtonBase>
							</div>
						)}
					</div>
				)}
			</div>
		</Tooltip>
	);
};

export default SingleTag;
