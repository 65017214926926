/** @jsxImportSource @emotion/react */

import { alpha, Button, Link, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import loginIllustration from "../../../../assets/main_illustrations/auth/signup.png";
import { AuthContainer } from "../../../../utils/components/AuthScaffold";
import CertiblokIcon from "../../../../utils/components/icons/CertiblokIcon";
import LoadingTextButton from "../../../../utils/components/LoadingTextButton";
import MLDialog from "../../../../utils/components/poppers";
import { apiErrorParser } from "../../../../utils/hooks/api";
import { useABACHandler } from "../../../../utils/hooks/useABACHandler";
import VerificationCodeTextField, {
	VerificationCodeTextFieldHelperText,
} from "../../../Profile/pages/Security/pages/TwoFAConfiguration/components/VerificationCodeTextField";
import { useVerifyCodeMutation } from "../../../Profile/pages/Security/pages/TwoFAConfiguration/queries/twoFactorAuthQueries";
import useMLDialog from "../../../../utils/MLDialogV2/controller/useMLDialog";
import CantGetCodeModal from "./components/CantGetCodeModal";

const LoginInsertTwoFactor = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const navigate = useNavigate();
	const location = useLocation();
	const from = (location.state as any)?.from || "/";

	const [pin, setPin] = useState("");
	const [helperText, setHelperText] = useState<VerificationCodeTextFieldHelperText>({ value: "", status: "success" });

	const { loadABACPermissionAndNavigateTo } = useABACHandler();

	const { showCustomModal } = useMLDialog();

	const verifyCodeMutation = useVerifyCodeMutation({
		variant: "standardVerification",
		onSuccess: (res) => {
			loadABACPermissionAndNavigateTo(res.data, from);
		},
		onError: (err) => {
			switch (err) {
				case "InvalidCode":
					setHelperText({ value: t("settings.wrongCode"), status: "error" });
					break;
				case "ExpiredCode":
					setHelperText({ value: t("settings.expiredCode"), status: "error" });
					break;
				default:
					MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
					break;
			}
		},
	});

	return (
		<>
			<img
				src={loginIllustration}
				alt={t("login.loginIllustration")}
				css={{
					position: "absolute",
					left: 0,
					alignSelf: "center",
					maxHeight: "max(100%, 836px)",
					[theme.breakpoints.up("xl")]: {
						width: "clamp(1256px, 50%, 1793px)",
						objectFit: "cover",
					},
				}}
			/>
			<AuthContainer>
				<div className="flex flex-col items-center md:items-start h-full">
					<div
						className="group flex items-center gap-2 cursor-pointer"
						onClick={() => {
							localStorage.clear();
							sessionStorage.clear();
							navigate("/login", { replace: true, state: { from } });
						}}
					>
						<CertiblokIcon
							size={24}
							color="primary"
							name={"arrow_mini_outline_left"}
							className="group-hover:-translate-x-1 transition-transform"
						/>
						<h6 className="text-primary text-h6 md:text-h5 font-extrabold">{t("settings.twoFactorAuth")}</h6>
					</div>
					<p className="text-body1 md:text-h6 text-black-a60 font-normal pt-3">{t("login.insertVerificationCode")}</p>
					<div className="flex flex-col items-center w-full flex-grow pt-12 md:pt-16">
						<VerificationCodeTextField
							value={pin}
							onChange={setPin}
							helperText={helperText}
							onEnterPress={(value) => {
								verifyCodeMutation.mutate(value);
							}}
						/>
						<Button
							onClick={() => {
								showCustomModal(CantGetCodeModal, {});
							}}
							color="secondary"
							children={t("login.cantGetCode")}
							className="underline "
						/>
					</div>
					<div className="w-full">
						<LoadingTextButton
							variant="contained"
							children={t("login.login")}
							size="large"
							fullWidth
							css={{ height: 48 }}
							onClick={() => {
								verifyCodeMutation.mutate(pin);
							}}
							loading={verifyCodeMutation.isLoading}
							disabled={pin.length < 6 || verifyCodeMutation.isLoading}
						/>
						<div className="flex items-center pt-3 justify-center md:justify-start">
							<Typography variant="body1" sx={{ color: alpha("#000000", 0.6) }}>
								{t("login.notRegisteredToCertiblok")}
							</Typography>
							<Link
								children={t("global.register")}
								href="/register"
								sx={{ typography: "link", color: alpha("#000000", 0.74), paddingLeft: 1 }}
							/>
						</div>
					</div>
				</div>
			</AuthContainer>
		</>
	);
};
export default LoginInsertTwoFactor;
