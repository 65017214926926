import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { ChatApi } from "certiblok-api-manager";
import { defaultQueryFilter, transformToStringFrom } from "../../../../../utils/components/tables/AdminTable";
import { useApi } from "../../../../../utils/hooks/api";

export const getGetChatMessagesQueryKey = (chatId: string, filter: string) => ["chatMessages", chatId, filter];
export const getGetInfiniteChatMessagesQueryKey = (chatId: string) => ["infiniteChatMessages", chatId];

export const useGetChatMessages = ({ chatId }: { chatId: string }) => {
	const chatApi = useApi(ChatApi);

	return useQuery({
		queryKey: getGetChatMessagesQueryKey(chatId, transformToStringFrom({ ...defaultQueryFilter })),
		queryFn: () => chatApi.getChatMessages(chatId, transformToStringFrom({ ...defaultQueryFilter })),
		select: (data) => data.data,
		enabled: Boolean(chatId),
	});
};

export const useGetInfiniteChatMessages = ({ chatId }: { chatId: string }) => {
	const chatApi = useApi(ChatApi);
	const PER_PAGE = 10;

	return useInfiniteQuery({
		queryKey: getGetInfiniteChatMessagesQueryKey(chatId),
		queryFn: ({ pageParam = 0 }) =>
			chatApi.getChatMessages(
				chatId,
				transformToStringFrom({ page: pageParam, perPage: PER_PAGE, orderBy: "createdAt", order: "desc" })
			),
		getNextPageParam: (lastPage, allPages) => {
			const nextPage = allPages.length;
			const totalCount = parseInt(lastPage.headers["x-total-count"] ?? "0");
			const pagesCount = Math.floor(totalCount / PER_PAGE);
			if (nextPage > pagesCount) {
				return undefined;
			}
			return nextPage;
		},
		enabled: Boolean(chatId),
	});
};
