// @ts-nocheck
import React, { useState, useEffect } from "react";

export class DimensionsHandler {
	constructor(props = {}) {
		this.props = props;
		this.dimensions = {
			width: 0,
			height: 0,
		};
	}

	onResizeComplete = () => {
		//? compute
		const width =
			((typeof document !== `undefined` ? document : {}).documentElement || {}).clientWidth ||
			(typeof window !== `undefined` && window.innerWidth) ||
			0;
		const height =
			((typeof document !== `undefined` ? document : {}).documentElement || {}).clientHeight ||
			(typeof window !== `undefined` && window.innerHeight) ||
			0;
		//? check
		if (width === this.dimensions.width && height === this.dimensions.height) {
			return;
		}

		this.dimensions = {
			width: width,
			height: height,
		};

		if (this.props.callback) this.props.callback(this.dimensions);
	};

	onResize = () => {
		const delay = this.props.delay || 300; //!wip customize delay
		if (delay) {
			if (this.resizeTimeout) clearTimeout(this.resizeTimeout);
			this.resizeTimeout = setTimeout(this.onResizeComplete, delay);
		} else {
			this.onResizeComplete();
		}
	};

	mount() {
		window.addEventListener("resize", this.onResize);
		this.onResizeComplete();
	}

	unmount() {
		window.removeEventListener("resize", this.onResize);
	}
}

export const useDimensions = (props = {}) => {
	const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

	let resizeTimeout;

	let dim = {
		width: dimensions.width,
		height: dimensions.height,
	};

	const onResizeComplete = () => {
		//? compute
		const width =
			(typeof window !== `undefined` && window.innerWidth) || //ho scambianto clientWidth con innerWidth per precisione, dato che non teneva conto della larghezza della scrollbar su desktop
			((typeof document !== `undefined` ? document : {}).documentElement || {}).clientWidth ||
			0;
		const height =
			((typeof document !== `undefined` ? document : {}).documentElement || {}).clientHeight ||
			(typeof window !== `undefined` && window.innerHeight) ||
			0;

		//? check
		if (width === dim.width && height === dim.height) {
			return;
		}

		dim.width = width;
		dim.height = height;

		if (props.callback) props.callback({ width, height });

		//? update
		setDimensions({
			width: width,
			height: height,
		});
	};

	const onResize = () => {
		const delay = props.delay || 300; //!wip customize delay
		if (delay) {
			clearTimeout(resizeTimeout);
			resizeTimeout = setTimeout(onResizeComplete, delay);
		} else {
			onResizeComplete();
		}
	};

	useEffect(() => {
		window.addEventListener("resize", onResize);
		onResizeComplete();
		return () => {
			window.removeEventListener("resize", onResize);
			clearTimeout(resizeTimeout);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return [dimensions];
};

export const withDimensions = (Component) => (props) => {
	const [dimensions] = useDimensions();
	return <Component dimensions={dimensions} {...props} />;
};
