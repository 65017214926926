import { Button, ButtonProps, CircularProgress, Collapse } from "@mui/material";
import { useEffect, useRef, useState } from "react";

type Props = {
	loading?: boolean;
} & ButtonProps;

const LoadingTextButton: React.FC<Props> = ({ loading, children, onClick, sx, ...props }) => {
	const [innerLoading, setInnerLoading] = useState(false);
	const hasBeenUnmounted = useRef(false);
	const shouldUpdateInnerLoading = (value: boolean) => {
		if (loading === undefined && !hasBeenUnmounted.current) {
			setInnerLoading(value);
		}
	};

	useEffect(() => {
		hasBeenUnmounted.current = false;

		return () => {
			hasBeenUnmounted.current = true;
		};
	}, []);

	return (
		<Button
			disabled={loading ?? innerLoading}
			onClick={async (ev) => {
				if (!loading && !innerLoading) {
					shouldUpdateInnerLoading(true);
					await onClick?.(ev);
					shouldUpdateInnerLoading(false);
				}
			}}
			sx={{
				gap: 0,
				...sx,
			}}
			{...props}
		>
			<Collapse orientation="horizontal" in={loading ?? innerLoading} mountOnEnter unmountOnExit>
				<CircularProgress
					color={props.variant === "contained" ? "inherit" : props.color}
					size={props.size === "small" ? 16 : props.size === "medium" ? 20 : 24}
					sx={{ marginRight: 2, marginTop: 1 }}
				/>
			</Collapse>
			{children}
		</Button>
	);

	// return (
	// 	<Button
	// 		disabled={loading ?? innerLoading}
	// 		onClick={async (ev) => {
	// 			if (!loading && !innerLoading) {
	// 				setInnerLoading(true);
	// 				await onClick?.(ev);
	// 				setInnerLoading(false);
	// 			}
	// 		}}
	// 		{...props}
	// 	>
	// 		<Stack
	// 			direction="row"
	// 			alignItems="center"
	// 			justifyContent="center"
	// 			sx={{ position: "absolute", width: "100%", height: "100%" }}
	// 		>
	// 			{(loading ?? innerLoading) && (
	// 				<CircularProgress
	// 					color={props.variant === "contained" ? "inherit" : props.color}
	// 					size={props.size === "small" ? 16 : props.size === "medium" ? 20 : 24}
	// 				/>
	// 			)}
	// 			<Box sx={{ visibility: "hidden" }}>{children}</Box>
	// 		</Stack>
	// 		<Collapse orientation="horizontal" in={loading ?? innerLoading}>
	// 			<CircularProgress
	// 				color={props.variant === "contained" ? "inherit" : props.color}
	// 				size={props.size === "small" ? 16 : props.size === "medium" ? 20 : 24}
	// 				sx={{ opacity: 0 }}
	// 			/>
	// 		</Collapse>
	// 		<Box
	// 			sx={{
	// 				transform: `translateX(${
	// 					loading ?? innerLoading ? (props.size === "small" ? 16 : props.size === "medium" ? 20 : 24) : 0
	// 				}px)`,
	// 				transition: theme.transitions.create(["transform"]),
	// 			}}
	// 		>
	// 			{children}
	// 		</Box>
	// 	</Button>
	// );
};

export default LoadingTextButton;
