import { Document, Folder, FolderApi } from "certiblok-api-manager";
import { useState } from "react";
import { createContext, useContextSelector } from "use-context-selector";
import { generateSearchFilters } from "../../../functions/generateSearchFilters";
import { useApi } from "../../../hooks/api";
import { useDebounce } from "../../../hooks/useDebounce";
import { useInfiniteLoader } from "../../../hooks/useInfiniteLoader";
import { defaultQueryFilter, FilterObject, transformToStringFrom } from "../../tables/AdminTable";
import { MAX_COLUMN_ELEMENTS } from "../PickDocumentReplacement";

export function usePickDocumentReplacementController(selectedFolder?: Folder) {
	const folderApi = useApi(FolderApi);

	const [filter, setFilter] = useState("");
	const debouncedFilter = useDebounce(filter, 300);

	const documentsInfiniteLoader = useInfiniteLoader((filter: FilterObject) =>
		folderApi.readDocumentsOfFolder(
			selectedFolder?.id ?? "",
			transformToStringFrom({
				...defaultQueryFilter,
				perPage: MAX_COLUMN_ELEMENTS,
				filter: [
					generateSearchFilters(debouncedFilter, {
						fields: ["name"],
					}),
				],
				...filter,
			})
		)
	);

	const [selectedFile, setSelectedFile] = useState<Document | undefined>();

	return { filter, setFilter, debouncedFilter, selectedFile, setSelectedFile, documentsInfiniteLoader };
}

type PickDocumentReplacementContextType = ReturnType<typeof usePickDocumentReplacementController>;

const PickDocumentReplacementContext = createContext<PickDocumentReplacementContextType | undefined>(undefined);

export const PickDocumentReplacementContextProvider: React.FC<{
	pickDocumentReplacementController: PickDocumentReplacementContextType;
	children: React.ReactNode;
}> = ({ children, pickDocumentReplacementController }) => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	// const memoChildren = useMemo(() => children, []);
	return (
		<PickDocumentReplacementContext.Provider value={pickDocumentReplacementController}>
			{children}
		</PickDocumentReplacementContext.Provider>
	);
};

export function usePickDocumentReplacementContextSelector<T extends keyof PickDocumentReplacementContextType>(
	field: T
): PickDocumentReplacementContextType[T] {
	return useContextSelector(PickDocumentReplacementContext, (v) => v![field]);
}

export function usePickDocumentReplacementContextUnoptimized() {
	const context = useContextSelector(PickDocumentReplacementContext, (v) => v);

	if (context === undefined) {
		throw new Error("Trying to use context outside of provider");
	}

	return context;
}
