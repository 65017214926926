import type { Theme } from "@mui/material";
import type { SxProps } from "@mui/system";
import { useTranslation } from "react-i18next";
import NoContents from "./NoContents";

type Props = {
	sx?: SxProps<Theme>;
};

const NoMoreFolders: React.FC<Props> = ({ sx = {} }) => {
	const { t } = useTranslation();
	return (
		<NoContents
			variant="folder"
			title={t("uploadDocument.noMoreFolders")}
			sx={{ width: "100%", height: "100%", justifyContent: "center" }}
		/>
	);
};

export default NoMoreFolders;
