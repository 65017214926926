import { DxfViewer as JSDxfViewer } from "dxf-viewer";
import { useEffect, useRef } from "react";

//@ts-ignore
import mainFont from "./fonts/Roboto-Regular.ttf";
//@ts-ignore
import aux1Font from "./fonts/NunitoSans-VariableFont_YTLC,opsz,wdth,wght.ttf";
import { Color } from "three";

type DxfViewerProps = {
	src?: string;
	parentRef?: React.RefObject<HTMLDivElement>;
	onLoad?: (hasLoaded: boolean) => void;
};

const DxfViewer: React.FC<DxfViewerProps> = ({ src, parentRef, onLoad }) => {
	const viewerRef = useRef<JSDxfViewer>();

	useEffect(() => {
		if (!parentRef?.current) return;
		if (!viewerRef.current) {
			viewerRef.current = new JSDxfViewer(parentRef.current, {
				canvasWidth: parentRef.current.clientWidth,
				canvasHeight: parentRef.current.clientHeight,
				autoResize: true,
				clearColor: new Color("#fff"),
				clearAlpha: 0,
				canvasAlpha: true,
				canvasPremultipliedAlpha: true,
				antialias: true,
				colorCorrection: true,
				blackWhiteInversion: false,
				pointSize: 2,
				sceneOptions: {
					/** Target angle for each segment of tessellated arc. */
					arcTessellationAngle: (10 / 180) * Math.PI,
					/** Divide arc to at least the specified number of segments. */
					minArcTessellationSubdivisions: 8,
					/** Render meshes (3DFACE group) as wireframe instead of solid. */
					wireframeMesh: false,
					/** Text rendering options. */
					textOptions: {
						curveSubdivision: 2,
						/** Character to use when the specified fonts does not contain necessary glyph. Several ones can
						 * be specified, the first one available is used.
						 */
						fallbackChar: "\uFFFD?",
					},
					suppressPaperSpace: false,
				},
				retainParsedDxf: true,
				fileEncoding: "utf-8",
				preserveDrawingBuffer: true,
			});
		}
		if (!src) return viewerRef.current.Clear();

		viewerRef.current.Load({
			url: src,
			fonts: [mainFont, aux1Font],
			progressCbk: (_, size, total) => {
				const hasLoaded = Math.ceil((size / total) * 100) >= 100;
				onLoad?.(hasLoaded);
			},
			workerFactory: () => new Worker(new URL("./workers/DxfViewerWorker.ts", import.meta.url)),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [src]);

	useEffect(() => {
		return () => {
			viewerRef.current?.Destroy();
		};
	}, []);

	return null;
};

export default DxfViewer;
