import { useController, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DatePicker from "../../../../../utils/components/DatePicker";
import { EditCreateEventForm } from "../hooks/useEditCreateEventFormValidator";
import { DateTime } from "luxon";
import { DateValue } from "@internationalized/date";

type EditCreateEventDatesProps = {};
const EditCreateEventDates: React.FC<EditCreateEventDatesProps> = () => {
	const { t } = useTranslation();
	const { control, setValue } = useFormContext<EditCreateEventForm>();

	const {
		field: startDateField,
		fieldState: { error: startDateError },
	} = useController({
		control,
		name: "startDate",
	});
	const { field: endDateField } = useController({
		control,
		name: "endDate",
	});

	const onEditDates = () => {
		setValue("recurrence", undefined);
		setValue("selectedRecurrenceOption", "none");
	};

	const onEditStartDate = (startDate?: DateValue) => {
		if (!startDate) {
			endDateField.onChange(startDate);
			return;
		}
		const dateTimeStartDate = DateTime.fromJSDate(startDate.toDate(DateTime.now().toFormat("z")));
		const dateTimeEndDate = endDateField.value
			? DateTime.fromJSDate(endDateField.value.toDate(DateTime.now().toFormat("z")))
			: undefined;
		if (dateTimeEndDate === undefined || dateTimeEndDate < dateTimeStartDate) {
			endDateField.onChange(startDate);
		}
	};

	const onEditEndDate = (endDate?: DateValue) => {
		if (!endDate) {
			endDateField.onChange(endDate);
			return;
		}
		const dateTimeStartDate = startDateField.value
			? DateTime.fromJSDate(startDateField.value.toDate(DateTime.now().toFormat("z")))
			: undefined;
		const dateTimeEndDate = DateTime.fromJSDate(endDate.toDate(DateTime.now().toFormat("z")));
		if (dateTimeStartDate === undefined || dateTimeEndDate < dateTimeStartDate) {
			startDateField.onChange(endDate);
		}
	};

	return (
		<div className="grid grid-cols-2 gap-4">
			<DatePicker
				color={"secondary"}
				size="medium"
				label={`${t("events.startingDate")} *`}
				value={startDateField.value ?? undefined}
				onChange={(value, reason) => {
					startDateField.onChange(value);
					if (reason === "picker") {
						onEditStartDate(value);
					}
					onEditDates();
				}}
				onBlur={() => {
					onEditStartDate(startDateField.value ?? undefined);
				}}
				error={Boolean(startDateError?.message)}
				errorMessage={startDateError?.message}
			/>
			<DatePicker
				label={`${t("events.endDate")} *`}
				size="medium"
				color={"secondary"}
				value={endDateField.value ?? undefined}
				onChange={(value, reason) => {
					endDateField.onChange(value);
					if (reason === "picker") {
						onEditEndDate(value);
					}
					onEditDates();
				}}
				onBlur={() => {
					onEditEndDate(endDateField.value ?? undefined);
				}}
			/>
		</div>
	);
};
export default EditCreateEventDates;
