import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import CertiblokIcon from "../../../../../utils/components/icons/CertiblokIcon";
import {
	ModalNavigator,
	useModalNavigator,
	useModalRoute,
} from "../../../../../utils/components/ModalNavigator/ModalNavigator";
import WhiteModal from "../../../../../utils/components/WhiteModal";
import { useBetterMediaQuery } from "../../../../../utils/hooks/useBetterMediaQuery";

const CantGetCodeModal = ModalNavigator.create(() => {
	const { visible } = useModalRoute();
	const { popModal } = useModalNavigator();
	const isMd = useBetterMediaQuery("md");

	const { t } = useTranslation();

	return (
		<WhiteModal
			open={visible}
			onClose={() => {
				popModal();
			}}
			containerSx={{
				borderRadius: 10,
			}}
			responsive
		>
			<div className="flex flex-col gap-6">
				<div className={"w-16 h-16 md:h-[72px] md:w-[72px] flex items-center justify-center rounded-full bg-secondary-a38"}>
					<CertiblokIcon size={isMd ? 48 : 40} color="secondary" name={"chat_bold"} />
				</div>
				<div>
					<h6 className="text-h6 font-bold md:text-h4 md:font-normal pb-2">{t("login.cantGetCode")}</h6>
					<p className="text-caption font-normal md:text-h6 text-black-a60">{t("login.cantRecoverAuthDescription")}</p>
				</div>
				<div className="bg-secondary-a12 flex justify-center p-[10px] rounded-lg">
					<p className="text-subtitle1 text-secondary md:text-h6">info@certiblok.com</p>
				</div>
				<p className="text-caption font-normal md:text-h6 text-black-a60 md:pt-6">{t("login.clickContactSupport")}</p>
				<div className="flex justify-between gap-3 mt-3">
					<Button
						children={t("global.cancel")}
						color="secondary"
						onClick={popModal}
						size={isMd ? "large" : "small"}
						startIcon={<CertiblokIcon size={24} color="inherit" name={"close"} />}
					/>
					<Button
						children={t("login.contactSupport")}
						className={"flex-grow md:flex-grow-0 md:min-w-[206px]"}
						color="secondary"
						variant={"contained"}
						component="a"
						onClick={popModal}
						href={`mailto:info@certiblok.com?subject=${t("login.codeRecovery")}`}
						size={isMd ? "large" : "small"}
					/>
				</div>
			</div>
		</WhiteModal>
	);
});
export default CantGetCodeModal;
