import { Button, IconButton, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { CloudFile } from "certiblok-api-manager";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import BottomSheet from "../../../../utils/components/bottomSheet/BottomSheet";
import CertiblokIcon from "../../../../utils/components/icons/CertiblokIcon";
import LoadingTextButton from "../../../../utils/components/LoadingTextButton";
import {
	ModalNavigator,
	useModalNavigator,
	useModalRoute,
} from "../../../../utils/components/ModalNavigator/ModalNavigator";
import { useBetterMediaQuery } from "../../../../utils/hooks/useBetterMediaQuery";
import ChooseFilesStep from "./components/ImportFromCloudContent/ChooseFilesStep";
import ImportFilesStep from "./components/ImportFromCloudContent/ImportFilesStep";
import { useImportFoldersAndDocs } from "./hooks/useImportFoldersAndDocs";

export type CloudSource = "googleDrive" | "dropbox" | "oneDrive";

type ImportFromCloudContentModalProps = {
	cloudSource: CloudSource | undefined;
	setImportedFiles: Dispatch<SetStateAction<CloudFile[]>>;
};

const ImportFromCloudContentModal = ModalNavigator.create(
	({ cloudSource, setImportedFiles }: ImportFromCloudContentModalProps) => {
		const { t } = useTranslation();

		const { visible } = useModalRoute();
		const { dismissModal } = useModalNavigator();

		const isMd = useBetterMediaQuery("md");

		const [step, setStep] = useState<"select" | "recap">("select");

		const [filesToImport, setFilesToImport] = useState<CloudFile[]>([]);
		const [breadcrumbs, setBreadcrumbs] = useState<CloudFile[]>([]);

		const onClose = () => {
			setStep("select");
			setFilesToImport([]);
			setBreadcrumbs([]);
			dismissModal();
		};

		const { mutate: startImport, isLoading: isStartingImport } = useImportFoldersAndDocs({ enabledCloud: cloudSource });

		return (
			<BottomSheet
				open={visible}
				HeaderProps={{ className: "pb-0 md:pb-0 md:pt-6 md:px-6 px-6" }}
				FooterProps={{ className: "md:px-4 md:py-6 px-4 pt-3 pb-4" }}
				onClose={() => onClose()}
				className="box-border h-[90vh]"
				BodyProps={{ className: "box-border flex-1 flex flex-col" }}
				header={
					<div>
						<div className="flex justify-between items-center">
							<Typography variant="h6" className="font-bold">
								{t("cloudImport.importFilesFromCloud")}
							</Typography>
							<IconButton onClick={() => onClose()}>
								<CertiblokIcon name="close" color="black" />
							</IconButton>
						</div>
						{isMd && (
							<Stepper activeStep={step === "select" ? 0 : 1} className="mt-3 mb-4">
								<Step completed={step === "recap"}>{<StepLabel>{t("cloudImport.selectFilesOrFolders")}</StepLabel>}</Step>
								<Step>{<StepLabel>{t("cloudImport.showSelectedFiles")}</StepLabel>}</Step>
							</Stepper>
						)}
					</div>
				}
				footer={
					<div className="flex items-center justify-between w-full gap-4">
						<Button
							variant="outlined"
							color="secondary"
							className="md:min-w-[220px]"
							startIcon={
								step === "select" ? (
									<CertiblokIcon name="close" color="secondary" />
								) : (
									<CertiblokIcon name="arrow_direction_outline_left" color="secondary" />
								)
							}
							onClick={() => {
								if (step === "select") {
									onClose();
								} else {
									setStep("select");
								}
							}}
						>
							{step === "select" ? t("global.cancel") : t("cloudImport.gotBackToSelection")}
						</Button>
						<LoadingTextButton
							color="secondary"
							variant="contained"
							className="md:min-w-[220px]"
							startIcon={step === "recap" && <CertiblokIcon name="success" color="inherit" className="mr-2" />}
							loading={isStartingImport}
							disabled={filesToImport.length === 0}
							onClick={() => {
								if (!filesToImport.length) return;
								if (step === "select") {
									setImportedFiles(filesToImport);
									setStep("recap");
								} else {
									startImport({ fileIds: filesToImport.map((file) => file.id) });
									onClose();
								}
							}}
						>
							{step === "select" ? t("cloudImport.goOnWithImport") : t("cloudImport.importFile")}
						</LoadingTextButton>
					</div>
				}
			>
				<div className="box-border pt-3 px-4 w-full flex-1 flex flex-col min-h-full">
					{step === "select" ? (
						<div className="flex-1 flex flex-col">
							<ChooseFilesStep
								cloudSource={cloudSource}
								filesToImport={filesToImport}
								setFilesToImport={setFilesToImport}
								breadcrumbs={breadcrumbs}
								setBreadcrumbs={setBreadcrumbs}
							/>
						</div>
					) : (
						<div className="flex-1 flex flex-col">
							<ImportFilesStep filesToImport={filesToImport} />
						</div>
					)}
				</div>
			</BottomSheet>
		);
	}
);

export default ImportFromCloudContentModal;
