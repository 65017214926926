"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.7.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatMessageTypeEnum = void 0;
exports.ChatMessageTypeEnum = {
    Text: 'text',
    Image: 'image',
    File: 'file',
    FileVersion: 'fileVersion',
    SharedFile: 'sharedFile',
    UnsharedFile: 'unsharedFile'
};
