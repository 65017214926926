import { atom } from "jotai";
import { createContext, useContext, useState } from "react";

export function useInvitationsAtomController() {
	const [credentialsAtom] = useState(() => {
		return atom({ password: "" });
	});

	return { credentialsAtom };
}

type InvitationsAtomContextType = ReturnType<typeof useInvitationsAtomController>;

const InvitationsAtomContext = createContext<InvitationsAtomContextType | undefined>(undefined);

export const InvitationsAtomContextProvider: React.FC<{
	invitationsAtomController: InvitationsAtomContextType;
	children: React.ReactNode;
}> = ({ children, invitationsAtomController }) => {
	return <InvitationsAtomContext.Provider value={invitationsAtomController}>{children}</InvitationsAtomContext.Provider>;
};

export function useInvitationsAtomContext() {
	const context = useContext(InvitationsAtomContext);

	if (context === undefined) {
		throw new Error("Trying to use context outside of provider");
	}

	return context;
}
