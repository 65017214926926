import { RadioGroup } from "@headlessui/react";
import { cn } from "@mabi-ui/utils";
import { Button, Radio, Typography } from "@mui/material";
import { CloudFile } from "certiblok-api-manager";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import ResponsiveDialog from "../../../../utils/components/dialogs/ResponsiveDialog";
import CertiblokIcon from "../../../../utils/components/icons/CertiblokIcon";
import LoadingTextButton from "../../../../utils/components/LoadingTextButton";
import {
	ModalNavigator,
	useModalNavigator,
	useModalRoute,
} from "../../../../utils/components/ModalNavigator/ModalNavigator";
import MLDialog from "../../../../utils/components/poppers";
import { useBetterMediaQuery } from "../../../../utils/hooks/useBetterMediaQuery";
import UnstoppableImportDisclaimer from "./components/UnstoppableImportDisclaimer";
import { useGetAllCloudRootLevelDocs } from "./hooks/useHandleGetFolderAndDocs";
import { useImportFoldersAndDocs } from "./hooks/useImportFoldersAndDocs";
import ImportFromCloudContentModal, { CloudSource } from "./ImportFromCloudContentModal";

const ChooseImportContent = ModalNavigator.create(
	({
		cloudSource,
		setImportedFiles,
	}: {
		cloudSource: CloudSource;
		setImportedFiles: Dispatch<SetStateAction<CloudFile[]>>;
	}) => {
		const isSm = useBetterMediaQuery("sm");

		const { visible } = useModalRoute();
		const { popModal, pushModal } = useModalNavigator();
		const { t } = useTranslation();
		const [importContent, setImportContent] = useState<"all" | "partial">("all");

		const { mutateAsync, isLoading } = useGetAllCloudRootLevelDocs({ enabledCloud: cloudSource });
		const { mutate: startImport, isLoading: isStartingImport } = useImportFoldersAndDocs({ enabledCloud: cloudSource });

		const onClose = () => {
			setImportContent("all");
			popModal();
		};

		return (
			<ResponsiveDialog
				onClose={() => onClose()}
				open={visible}
				ModalProps={{ containerSx: { maxWidth: "550px", minWidth: "300px" } }}
				BottomSheetProps={{
					sx: {
						[`& .container`]: {
							boxSizing: "border-box",
							padding: "20px",
							maxWidth: "none",
							"> div": { marginY: "0px !important" },
						},
					},
				}}
				header={
					<>
						<Typography variant="subtitle1" className="mb-1">
							{t("cloudImport.chooseImport")}
						</Typography>
						<Typography variant="caption">{t("cloudImport.chooseImportDescription")}</Typography>
					</>
				}
				footer={
					<div className="flex justify-between items-center w-full gap-3">
						<Button color="secondary" variant="outlined" startIcon={<CertiblokIcon name="close" />} onClick={() => onClose()}>
							{t("global.close")}
						</Button>
						<LoadingTextButton
							loading={isLoading || isStartingImport}
							color="secondary"
							variant="contained"
							onClick={async () => {
								if (importContent === "partial") {
									onClose();
									pushModal(ImportFromCloudContentModal, { cloudSource, setImportedFiles });
								} else {
									const docs = await mutateAsync();
									setImportedFiles(docs);
									startImport({ fileIds: [] });
									onClose();
									if (!isSm) {
										MLDialog.showSnackbar(t("cloudImport.importOngoing"));
									}
								}
							}}
						>
							{t("cloudImport.goOnWithImport")}
						</LoadingTextButton>
					</div>
				}
			>
				<div className="container">
					<div className="my-8">
						<RadioGroup value={importContent} onChange={(v) => setImportContent(v)}>
							<RadioGroup.Option value="all">
								{({ checked }) => (
									<div
										className={cn(
											"flex gap-3 border-solid border-2 border-black/[0.06] rounded-2xl py-4 outline-2",
											checked && "outline-primary border-primary bg-primary/[0.06]"
										)}
									>
										<Radio checked={checked} className="w-6 h-6 ml-3" />
										<div className="pr-3">
											<Typography variant="caption" component={"p"} className="font-bold">
												{t("cloudImport.importAll")}
											</Typography>
											<Typography variant="caption">{t("cloudImport.importAllDescription")}</Typography>
										</div>
									</div>
								)}
							</RadioGroup.Option>
							<RadioGroup.Option value="partial" className={"mt-5"}>
								{({ checked }) => (
									<div
										className={cn(
											"flex gap-3 border-solid border-2 border-black/[0.06] rounded-2xl py-4 outline-2",
											checked && "outline-primary border-primary bg-primary/[0.06]"
										)}
									>
										<Radio checked={checked} className="w-6 h-6 ml-3" />
										<div className="pr-3">
											<Typography variant="caption" component={"p"} className="font-bold">
												{t("cloudImport.importPartial")}
											</Typography>
											<Typography variant="caption">{t("cloudImport.importPartialDescription")}</Typography>
										</div>
									</div>
								)}
							</RadioGroup.Option>
						</RadioGroup>
						<div className="mt-4">
							<UnstoppableImportDisclaimer />
						</div>
					</div>
				</div>
			</ResponsiveDialog>
		);
	}
);

export default ChooseImportContent;
