import { Box, CircularProgress, Typography } from "@mui/material";
import type { Audit } from "certiblok-api-manager";
import { CSSProperties, useContext } from "react";
import { useTranslation } from "react-i18next";
import AuditRoomSelector from "../../../../../utils/components/auditRooms/AuditRoomSelector";
import type { CertiblokDocument } from "../../../../../utils/interfaces/Document";
import { AuditRoomsContext } from "../controllers/AuditRoomsContext";
import { BOTTOM_MARGIN, ITEM_WIDTH, MAX_COLUMN_ELEMENTS } from "./AuditRoomsInfiniteList";

type Props = {
	index: number;
	style: CSSProperties;
	data: Audit[];
	document?: CertiblokDocument;
};

const AuditRoomsSection: React.FC<Props> = ({ index, style, data, document }) => {
	const { t } = useTranslation();
	const auditRoomsContext = useContext(AuditRoomsContext);

	return (
		<Box style={style} sx={{ paddingRight: 12, boxSizing: "border-box" }}>
			{index < Math.ceil(data.length / MAX_COLUMN_ELEMENTS) ? (
				data.slice(index * MAX_COLUMN_ELEMENTS, index * MAX_COLUMN_ELEMENTS + MAX_COLUMN_ELEMENTS).map((elem) => {
					const selected =
						auditRoomsContext.selectedAuditRooms.findIndex((selectedRoom) => selectedRoom.id === elem.id) !== -1;
					const isDisabled = document && document.audits?.findIndex((audit) => audit.id === elem.id) !== -1;
					return (
						<Box sx={{ marginBottom: 2 }} key={`large_list_document_${elem.id}_${elem.name}`}>
							<AuditRoomSelector
								key={elem.id}
								isDisabled={isDisabled}
								auditRoom={elem}
								isSelected={selected}
								toggleSelected={() => {
									if (!selected) {
										auditRoomsContext.selectAuditRoom(elem);
									} else {
										auditRoomsContext.unselectAuditRoom(elem);
									}
								}}
							/>
						</Box>
					);
				})
			) : (
				<Box
					sx={{
						height: "100%",
						width: ITEM_WIDTH,
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						gap: 3,
						transform: `translateY(${-BOTTOM_MARGIN}px)`,
					}}
				>
					<CircularProgress color="secondary" />
					<Typography children={t("pickDocumentReplacement.documentsLoading")} variant="subtitle1" color="secondary" />
				</Box>
			)}
		</Box>
	);
};
export default AuditRoomsSection;
