import { Button, IconButton, Typography } from "@mui/material";
import { Tag } from "certiblok-api-manager";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ResponsiveDialog from "../../../../utils/components/dialogs/ResponsiveDialog";
import CertiblokIcon from "../../../../utils/components/icons/CertiblokIcon";
import FileIcon from "../../../../utils/components/icons/FileIcon";
import LoadingTextButton from "../../../../utils/components/LoadingTextButton";
import {
	ModalNavigator,
	useModalNavigator,
	useModalRoute,
} from "../../../../utils/components/ModalNavigator/ModalNavigator";
import NoContents from "../../../../utils/components/NoContents";
import { CertiblokDocument } from "../../../../utils/interfaces/Document";
import { useIsEditRoom } from "../../hooks/useIsEditRoom";
import { useEditDocumentTags, useEditDocumentTagsInEditRoom } from "../../hooks/useTagsHooks";
import SingleTag from "./SingleTag";
import TagsAutocomplete from "./TagsAutocomplete";
import { DeletableTag } from "./TagsManagementModal";
import EditCreateTag from "./TagsManagementModal/EditCreateTag";

type DocumentTagsModalProps = {
	document?: CertiblokDocument;
	onEditTags?: () => void;
};

const DocumentTagsModal = ModalNavigator.create<DocumentTagsModalProps>(({ document, onEditTags }) => {
	const { t } = useTranslation();

	const { visible } = useModalRoute();
	const { popModal, pushModalById } = useModalNavigator();

	const { isInEditRoom } = useIsEditRoom();

	const [newDocumentTags, setNewDocumentTags] = useState<DeletableTag[]>(document?.tags ?? []);

	const [userInput, setUserInput] = useState("");

	useEffect(() => {
		setNewDocumentTags(document?.tags ?? []);
	}, [document]);

	const onClose = () => {
		setUserInput("");
		setNewDocumentTags([]);
		onEditTags?.();
		popModal();
	};

	const { mutate: editTags, isLoading: isLoadingDoc } = useEditDocumentTags({
		onSuccess: () => {
			onClose();
		},
	});
	const { mutate: editTagsInEdit, isLoading: isLoadingEditDoc } = useEditDocumentTagsInEditRoom({
		onSuccess: () => {
			onClose();
		},
	});

	const isLoading = isLoadingDoc || isLoadingEditDoc;

	return (
		<>
			<ResponsiveDialog
				open={visible}
				onClose={onClose}
				ModalProps={{ containerSx: { minWidth: "600px" } }}
				BottomSheetProps={{ BodyProps: { className: "px-6" } }}
				header={
					<div className="w-full flex items-center justify-between">
						<Typography variant={"subtitle1"} className="font-bold mt-1">
							{t("tags.documentTags")}
						</Typography>
						<IconButton onClick={onClose} size="small">
							<CertiblokIcon name="close" color="black" />
						</IconButton>
					</div>
				}
				footer={
					<div className="flex items-center justify-between gap-2">
						<Button onClick={onClose} variant="outlined" color="secondary" className="px-6">
							{t("global.close")}
						</Button>
						<div className="flex items-center gap-3">
							<Button
								onClick={() =>
									pushModalById(
										"edit-create-tag",
										{
											key: userInput,
											oldTag: { name: userInput },
											onEditCreateSuccess: (newTag: Tag) => {
												setNewDocumentTags((prev) => [...prev, newTag]);
												setUserInput("");
											},
										},
										{ shouldHidePrev: true }
									)
								}
								variant="text"
								color="secondary"
								className="px-6"
							>
								{t("tags.createTag")}
							</Button>
							<LoadingTextButton
								loading={isLoading}
								variant="contained"
								color="secondary"
								className="px-6"
								onClick={() => {
									if (isInEditRoom) {
										if (document === undefined) return;
										editTagsInEdit({ editRoomDocument: document, tagIds: newDocumentTags.map((t) => t.id) });
									} else {
										editTags({ documentId: document?.id ?? "", tagIds: newDocumentTags.map((t) => t.id) });
									}
								}}
							>
								{t("global.save")}
							</LoadingTextButton>
						</div>
					</div>
				}
			>
				<div className="md:py-6">
					<div className="flex items-center gap-4 mb-4 ">
						<FileIcon file={document?.lastVersionFile?.[0]} />
						<Typography variant="subtitle1">{document?.name ?? ""}</Typography>
					</div>
					<TagsAutocomplete
						disabledTagsIds={newDocumentTags.map((t) => t.id)}
						onTagSelection={(tag) => {
							setNewDocumentTags((prev) => [...prev, tag]);
						}}
						inputValue={userInput}
						onInputChange={(input) => {
							setUserInput(input);
						}}
					/>
					<div className="mt-6">
						{newDocumentTags.length === 0 ? (
							<div className="flex items-center justify-center min-h-[100px]">
								<NoContents variant="document" title={t("tags.noTagsToShow")} />
							</div>
						) : (
							<div className="flex gap-2 flex-wrap min-h-[100px] max-h-[200px] content-start overflow-y-auto">
								{newDocumentTags.map((tag) => (
									<div key={tag.id}>
										<SingleTag
											tag={tag}
											size="medium"
											isDeletable
											onDelete={() => {
												setNewDocumentTags(newDocumentTags.filter((t) => t.id !== tag.id));
											}}
										/>
									</div>
								))}
							</div>
						)}
					</div>
				</div>
			</ResponsiveDialog>
			<EditCreateTag id="edit-create-tag" />
		</>
	);
});

export default DocumentTagsModal;
