/** @jsxImportSource @emotion/react */
import { Button, Stack, Typography, useTheme } from "@mui/material";
import { alpha, Box } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import forgotIllustration from "../../assets/main_illustrations/find.png";
import { AuthContainer } from "../../utils/components/AuthScaffold";
import CertiblokIcon from "../../utils/components/icons/CertiblokIcon";

type Props = {};

const NotFound: React.FC<Props> = () => {
	const theme = useTheme();
	const { t } = useTranslation();
	return (
		<React.Fragment>
			<img
				src={forgotIllustration}
				alt={t("404.loginIllustration")}
				css={{
					position: "absolute",
					left: 0,
					alignSelf: "center",
					maxHeight: "max(100%, 836px)",
					[theme.breakpoints.up("xl")]: {
						width: "clamp(1256px, 50%, 1793px)",
						objectFit: "cover",
					},
				}}
			/>
			<AuthContainer>
				<Stack alignItems={"center"} justifyContent="center" sx={{ height: "100%" }} spacing={9}>
					<Box
						sx={{
							backgroundColor: alpha(theme.palette.primary.main, 0.12),
							borderRadius: 12,
							padding: 3,
							boxSizing: "border-box",
						}}
					>
						<CertiblokIcon name="search_outline" size={112} color="primary" />
					</Box>
					<Box>
						<Typography
							children={t("404.thisPageDoesNotExist")}
							variant="h5"
							sx={{ fontWeight: 800, color: "primary.main", textAlign: "center", paddingBottom: 3 }}
						/>
						<Typography children={t("404.retry")} variant="h6" sx={{ opacity: 0.74, textAlign: "center", fontWeight: 400 }} />
					</Box>
					<Button
						href="/"
						fullWidth
						size="large"
						children={t("openOnDesktop.goToDashboard")}
						variant="contained"
						sx={{ height: 48 }}
					/>
				</Stack>
			</AuthContainer>
		</React.Fragment>
	);
};

export default NotFound;
