import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CertiblokTextInput from "../../../../../utils/components/CertiblokTextInput";
import CertiblokIcon from "../../../../../utils/components/icons/CertiblokIcon";
import WhiteModal from "../../../../../utils/components/WhiteModal";

const SharedNoteModal = NiceModal.create(({ note }: { note: string }) => {
	const { visible, hide } = useModal();
	const { t } = useTranslation();

	return (
		<WhiteModal
			responsive
			open={visible}
			onClose={() => {
				hide();
			}}
		>
			<>
				<div className="flex items-center w-full justify-between mb-6">
					<Typography variant="subtitle1">{t("sharingNotes.sharingNotes")}</Typography>
					<IconButton size="small" onClick={() => hide()}>
						<CertiblokIcon name="close" color="secondary" />
					</IconButton>
				</div>
				<CertiblokTextInput
					readOnly
					fullWidth
					multiline
					rows={3}
					color="secondary"
					value={note}
					sx={{ typography: "body1", color: "rgba(0, 0, 0, .6)" }}
				/>
			</>
		</WhiteModal>
	);
});

export default SharedNoteModal;
