/** @jsxImportSource @emotion/react */

import type { CSSObject } from "@emotion/react";

import { Color, Palette, PaletteColor, Theme, useTheme } from "@mui/material";
import * as Colors from "@mui/material/colors";
import React from "react";
//@ts-ignore
import { theme as importedTheme } from "../../../theme";
import { CertiblokIconsMap } from "./CertiblokIcon.constants";

type ThemeColor = `${keyof typeof importedTheme.palette}` | `${keyof Palette}.${keyof PaletteColor}`;
type StandardColor = `${keyof typeof Colors}` | `${keyof typeof Colors}.${keyof Color}`;

export type CertiblokIconNames = keyof typeof CertiblokIconsMap;

export type CertiblokThemeColor = ThemeColor | StandardColor;
type CertiblokColor =
	| CertiblokThemeColor
	| `#${string}`
	| keyof typeof Colors
	| "black"
	| "white"
	| `rgb(${string})`
	| `rgba(${string})`
	| string
	| "inherit";

interface CertiblokIconProps {
	color?: CertiblokColor;
	size?: 4 | 8 | 12 | 16 | 24 | 28 | 32 | 36 | 40 | 48 | 52 | 56 | 60 | 64 | number;
	name?: CertiblokIconNames;
	iconCss?: CSSObject;
}

interface CertiblokIconProps {
	twoColor?: boolean;
	foreground?: {
		name: CertiblokIconNames;
		color: CertiblokColor;
	};
	background?: {
		name: CertiblokIconNames;
		color: CertiblokColor;
	};
	size?: 4 | 8 | 12 | 16 | 24 | 28 | 32 | 36 | 40 | 48 | 52 | 56 | 60 | 64 | number;
	iconCss?: CSSObject;
	className?: string;
	id?: string;
}

const CertiblokIcon = React.forwardRef<any, CertiblokIconProps>((props, ref) => {
	const { color, size, name, twoColor, foreground, background, iconCss, ...otherProps } = props;
	const theme = useTheme();

	if (!props.twoColor) {
		const customColor = getCustomColorFromTheme(color || "primary", theme);
		if (!name) throw new Error("missing name property");

		return (
			<i
				ref={ref}
				css={{ color: customColor, fontSize: size ?? 24, flexShrink: 0, ...props.iconCss }}
				dangerouslySetInnerHTML={{ __html: CertiblokIconsMap?.[name || "super_success"] }}
				{...otherProps}
			/>
		);
	} else {
		if (!foreground || !background) throw new Error("missing foreground or background property");

		const foregroundColor = getCustomColorFromTheme(foreground.color, theme);
		const backgroundColor = getCustomColorFromTheme(background.color, theme);

		return (
			<div
				ref={ref}
				css={{ position: "relative", height: size ?? 24, width: size ?? 24, display: "inline-block", ...props.iconCss }}
				{...otherProps}
			>
				<i
					css={{ position: "absolute", color: foregroundColor, fontSize: size ?? 24, left: 0 }}
					dangerouslySetInnerHTML={{ __html: CertiblokIconsMap?.[foreground?.name || "super_success"] }}
				/>
				<i
					css={{ position: "absolute", color: backgroundColor, fontSize: size ?? 24, left: 0 }}
					dangerouslySetInnerHTML={{ __html: CertiblokIconsMap?.[background?.name || "super_success"] }}
				/>
			</div>
		);
	}
});

export default CertiblokIcon;
function getCustomColorFromTheme(color: string, theme: Theme) {
	const splittedColor = color?.split(".");
	const typeColor = (splittedColor?.[0] || "primary") as any as PaletteColor;
	const shadeColor = splittedColor?.[1] || "main";

	let customColor = "black";

	if (Number(shadeColor.replace("A", ""))) {
		//@ts-ignore
		customColor = Colors[typeColor]?.[shadeColor] || color || "black";
	} else {
		//@ts-ignore
		customColor = theme.palette?.[typeColor]?.[shadeColor] || color;
	}
	return customColor;
}
