/** @jsxImportSource @emotion/react */
import { Box, CircularProgress, Fade, Typography, darken, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import { useContext } from "react";
import Calendar from "react-calendar";
import { pxToRem } from "../../../functions/theme";
import { useBetterMediaQuery } from "../../../hooks/useBetterMediaQuery";
import { useSelectedLanguage } from "../../../hooks/useLanguageSwitcher";
import CertiblokIcon from "../../icons/CertiblokIcon";
import { GlobalCalendarContext } from "../controllers/GlobalCalendarContext";

const SmallCalendar = () => {
	const calendarContext = useContext(GlobalCalendarContext);
	const theme = useTheme();

	const isSm = useBetterMediaQuery("sm");

	const selectedLocale = useSelectedLanguage();

	return (
		<Calendar
			locale={selectedLocale}
			value={calendarContext.selectedDay?.toJSDate()}
			onClickDay={(date) => {
				const dateTimeDate = DateTime.fromJSDate(date);
				calendarContext.setSelectedDay(dateTimeDate);
			}}
			onActiveStartDateChange={(props) => {
				if (props.view === "month") calendarContext.setVisualizedMonth(DateTime.fromJSDate(props.activeStartDate));
			}}
			activeStartDate={calendarContext.visualizedMonth.toJSDate()}
			next2Label={null}
			prev2Label={null}
			navigationLabel={(props) => {
				return (
					<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
						<Typography children={props.label.capitalize()} variant="h6" sx={{ fontWeight: 800, color: "white" }} />
						<CertiblokIcon size={16} color="white" name={"▾-arrow-3"} />
						<Fade in={calendarContext.loading}>
							<CircularProgress size={16} sx={{ color: "white" }} />
						</Fade>
					</Box>
				);
			}}
			prevLabel={<CertiblokIcon size={24} color="inherit" name={"arrow_mini_outline_left"} />}
			nextLabel={<CertiblokIcon size={24} color="inherit" name={"arrow_mini_outline_right"} />}
			tileClassName={(tile) => {
				return calendarContext.getDayEvents(DateTime.fromJSDate(tile.date)).length === 0
					? "react-calendar__tile"
					: "react-calendar__tile__with__events";
			}}
			defaultActiveStartDate={
				calendarContext.visualizedMonth ? calendarContext.visualizedMonth.toJSDate() : DateTime.now().toJSDate()
			}
			css={{
				width: isSm ? 285 : "100%",
				...(!isSm && {
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}),
				"& .react-calendar__navigation": {
					display: "flex",
					paddingBottom: 10,
					alignItems: "center",
					width: "100%",
					gap: 4,
				},
				"& .react-calendar__viewContainer": {
					width: 285,
				},
				"& .react-calendar__navigation__label": {
					backgroundColor: "unset",
					border: "unset",
					cursor: "pointer",
					order: -1,
					textAlign: "start",
					padding: 3,
					paddingLeft: 0,
				},
				"& .react-calendar__navigation__arrow": {
					backgroundColor: theme.palette.magenta.main,
					border: "unset",
					cursor: "pointer",
					color: "white",
					padding: 3,
					height: 30,
					borderRadius: 15,
					transition: theme.transitions.create(["background-color", "color"]),
					"&:hover": {
						backgroundColor: darken(theme.palette.magenta.main, 0.2),
						color: "white",
					},
				},
				"& .react-calendar__tile": {
					color: "white",
					padding: 0,
					flex: "unset !important",
					margin: 1,
					width: 36,
					height: 36,
					textAlign: "center",
					fontWeight: 400,
					fontSize: pxToRem(20),
					borderRadius: 26,
					borderStyle: "solid",
					backgroundColor: "unset",
					borderColor: "transparent",
					boxSizing: "border-box",
					cursor: "pointer",
					transition: theme.transitions.create(["background-color", "border-color", "color"]),
					"&:hover": {
						color: "white !important",
						backgroundColor: "#3B95A8",
					},
					"&--active": {
						color: "white !important",
						backgroundColor: `${theme.palette.magenta.main} !important`,
						borderColor: "rgba(255, 255, 255, 0.54)",
					},
					"&--now": {
						backgroundColor: "white",
						color: theme.palette.magenta.main,
						borderColor: theme.palette.secondary.main,
					},
				},
				"& .react-calendar__tile__with__events": {
					position: "relative",
					overflow: "unset !important",
					"::after": {
						content: '""',
						width: 30,
						height: 1,
						backgroundColor: theme.palette.magenta.main,
						position: "absolute",
						bottom: -4,
						left: 0,
					},
				},
				"& .react-calendar__month-view__days__day--neighboringMonth": {
					color: "rgba(255, 255, 255, 0.54)",
					backgroundColor: "transparent",
					borderColor: "transparent",
				},
				"& .react-calendar__month-view__weekdays": {
					paddingBottom: 10,
					alignItems: "center",
				},
				"& .react-calendar__month-view__days": {
					gap: "8px 0px",
					alignItems: "center",
				},
				"& .react-calendar__month-view__weekdays__weekday": {
					flex: "unset !important",
					width: 38,
					textAlign: "center",
					textTransform: "uppercase",
					fontWeight: 700,
					fontSize: pxToRem(14),
					color: "rgba(255, 255, 255, 0.54)",
					"abbr[title]": {
						textDecoration: "none",
					},
				},
				"& .react-calendar__year-view__months, .react-calendar__decade-view__years, .react-calendar__century-view": {
					"& .react-calendar__tile": {
						width: "unset",
						flexBasis: "50% !important",
						margin: 0,
						borderColor: theme.palette.secondary.main,
						"&::after": {
							width: 0,
							height: 0,
						},
						"&:hover": {
							color: "white !important",
						},
						"&--now": {
							color: `${theme.palette.secondary.main} !important`,
						},
					},
				},
			}}
		/>
	);
};

export default SmallCalendar;
