import { Typography } from "@mui/material";
import { Trans } from "react-i18next";
import CertiblokIcon from "../../../../../utils/components/icons/CertiblokIcon";

const UnstoppableImportDisclaimer = () => {
	return (
		<div className="bg-alert/[0.12] px-4 py-2 flex items-center gap-4 rounded-xl">
			<CertiblokIcon name="comunication_outline_01" color="warning" />
			<Typography variant="caption">
				<Trans i18nKey={"cloudImport.unstoppableImportDisclaimer"} />
			</Typography>
		</div>
	);
};

export default UnstoppableImportDisclaimer;
