import { cn } from "@mabi-ui/utils";
import { ButtonBase, Skeleton, Typography } from "@mui/material";
import { ReactNode } from "react";
import CertiblokIcon, { CertiblokIconNames } from "../../../../utils/components/icons/CertiblokIcon";

export type DashboardCardProps = {
	children: string | ReactNode;
	icon: CertiblokIconNames;
	color?: "primary" | "secondary";
	count?: number;
	onClick?: () => void;
	href?: string;
	isCountLoading?: boolean;
};

const DashboardSmallCard = ({
	children,
	icon,
	color = "primary",
	count,
	onClick,
	href,
	isCountLoading,
}: DashboardCardProps) => {
	return (
		<ButtonBase
			className={cn(
				color === "primary"
					? "bg-primary hover:bg-primary/[0.92] active:bg-primary/[0.82]"
					: "bg-[#E6F4F7] hover:bg-[#E2F2F5] active:bg-[#DEF0F4]",
				"flex-1 self-stretch p-3 md:p-4 flex flex-col gap-2 md:rounded-2xl rounded-xl text-left justify-start items-start transition-colors"
			)}
			onClick={() => {
				onClick?.();
			}}
			href={href ?? ""}
			disableRipple
		>
			<div className="flex items-center w-full justify-between gap-2">
				<div
					className={cn(
						"p-1 flex items-center justify-center rounded-lg",
						color === "primary" ? "bg-white/[0.38]" : "bg-secondary/[0.12]"
					)}
				>
					<CertiblokIcon name={icon} className={cn(color === "primary" ? "text-white" : "text-secondary")} size={24} />
				</div>
				{isCountLoading ? (
					<Skeleton width={30} height={28} />
				) : (
					count !== undefined && (
						<Typography variant="h6" className={cn("font-bold", color === "primary" ? "text-white/[0.6]" : "text-[#8ACAD7]")}>
							{count}
						</Typography>
					)
				)}
			</div>
			{typeof children === "string" ? (
				<Typography
					variant="subtitle1"
					className={cn("text-[14px] m-0", color === "primary" ? "text-white" : "text-secondary")}
				>
					{children}
				</Typography>
			) : (
				children
			)}
		</ButtonBase>
	);
};

export default DashboardSmallCard;
