import { UnstyledButton } from "@mabi-ui/button";
import { cn } from "@mabi-ui/utils";
import { Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import BetterCheckbox from "../../../../../../utils/components/BetterCheckbox";
import CertiblokIcon from "../../../../../../utils/components/icons/CertiblokIcon";

type SortableHeaderPropsType = {
	isAllPageSelected: boolean;
	onSelectAll: () => void;
	isDisabled: boolean;
	selectableFields: string[];
	activeSorting: { [key: string]: -1 | 1 | undefined };
	setActiveSorting: Dispatch<SetStateAction<{ [key: string]: -1 | 1 | undefined }>>;
};

const SortableField = ({
	isSortable,
	children,
	sorting,
	className,
	onChangeSorting,
}: {
	isSortable: boolean;
	children: string;
	sorting: -1 | 1 | undefined;
	className?: string;
	onChangeSorting: () => void;
}) => {
	return (
		<UnstyledButton
			className={cn(
				"[&>span]:flex [&>span]:items-center [&>span]:justify-start [&>span]:gap-1 text-[14px] font-bold",
				isSortable ? "hover:bg-secondary/[0.06] active:bg-secondary/[0.12]" : "cursor-default",
				className
			)}
			onClick={() => {
				onChangeSorting();
			}}
		>
			<Typography variant="subtitle2" className="">
				{children}
			</Typography>
			{isSortable && (
				<div className="w-4 h-4 flex-shrink-0 relative">
					<CertiblokIcon
						name="▾-arrow-1"
						className="absolute -top-[7px] left-0"
						color={sorting === -1 ? "secondary" : "rgba(0,0,0,0.24)"}
						size={20}
					/>
					<CertiblokIcon
						name="▾-arrow-1"
						size={20}
						className="rotate-180 absolute -bottom-[7px] left-0"
						color={sorting === 1 ? "secondary" : "rgba(0,0,0,0.24)"}
					/>
				</div>
			)}
		</UnstyledButton>
	);
};

const SortableHeader = ({
	isAllPageSelected,
	onSelectAll,
	isDisabled,
	selectableFields,
	activeSorting,
	setActiveSorting,
}: SortableHeaderPropsType) => {
	const { t } = useTranslation();

	const toggleSorting = (field: string) => {
		if (!selectableFields.includes(field)) return;
		setActiveSorting((prev) => {
			return prev?.[field] === undefined
				? { ...prev, [field]: 1 }
				: prev?.[field] === 1
				? { ...prev, [field]: -1 }
				: { ...prev, [field]: undefined };
		});
	};
	const isMimeTypeSortable = selectableFields.includes("mimeType");

	return (
		<div className="grid grid-cols-[24px,64px,1fr,1fr] gap-4 items-center min-w-0 px-4 py-1 rounded-lg bg-[#E6F4F7]">
			<BetterCheckbox
				checked={isAllPageSelected}
				color="secondary"
				className={"p-0"}
				disabled={isDisabled}
				onClick={() => {
					onSelectAll();
				}}
			/>

			<SortableField
				className="w-[64px] [&_.MuiTypography-subtitle2]:w-full"
				isSortable={isMimeTypeSortable || selectableFields.includes("type")}
				sorting={isMimeTypeSortable ? activeSorting?.mimeType : activeSorting?.type}
				onChangeSorting={() => toggleSorting(isMimeTypeSortable ? "mimeType" : "type")}
			>
				{t("documents.type")}
			</SortableField>

			<SortableField
				isSortable={selectableFields.includes("name")}
				sorting={activeSorting?.name}
				onChangeSorting={() => toggleSorting("name")}
			>
				{t("global.name")}
			</SortableField>

			<SortableField
				isSortable={selectableFields.includes("updatedAt")}
				sorting={activeSorting?.updatedAt}
				onChangeSorting={() => toggleSorting("updatedAt")}
			>
				{t("editRoom.lastEdit")}
			</SortableField>
		</div>
	);
};

export default SortableHeader;
