import { cn } from "@mabi-ui/utils";
import { Button, ButtonProps, Fade, Typography } from "@mui/material";
import { Box } from "@mui/system";
import type React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props extends ButtonProps {
	open: boolean;
	icon: React.ReactNode;
}

const CollapsableButton: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const { open, icon, children, disabled, ...otherProps } = { ...props };

	const [showAnimatedTooltip, setShowAnimatedTooltip] = useState(false);
	// const [animationProgression, setAnimationProgression] = useState({ value: 0, direction: 1 });

	// useEffect(() => {
	// 	const editAnimation = () => {
	// 		setAnimationProgression((prev) => {
	// 			if (prev.value === 100) {
	// 				return { value: 99, direction: -1 };
	// 			}
	// 			if (prev.value === 0) {
	// 				return { value: 1, direction: 1 };
	// 			}
	// 			return { ...prev, value: prev.value + prev.direction * 1 };
	// 		});
	// 	};
	// 	const int = setInterval(editAnimation, 15);

	// 	return () => {
	// 		clearInterval(int);
	// 	};
	// }, []);

	return (
		<Button {...otherProps} disabled={disabled}>
			<Box
				sx={{
					position: "absolute",
					width: "100%",
					height: "100%",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					gap: 2,
					pointerEvents: open ? "auto" : "none",
				}}
				onMouseEnter={() => setShowAnimatedTooltip(true)}
				onMouseLeave={() => setShowAnimatedTooltip(false)}
			>
				<div className={cn(open && disabled && showAnimatedTooltip && "blur-[1px]")}>{icon}</div>
				<Fade in={open} mountOnEnter unmountOnExit exit={false}>
					<div className={cn(disabled && showAnimatedTooltip && "blur-[2px]")}>{children}</div>
				</Fade>
				<Fade in={open && disabled && showAnimatedTooltip}>
					<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full rounded-[10px] bg-black/[0.06] flex items-center justify-center">
						<Typography
							variant="button"
							className="font-bold text-black"
							// sx={{
							// 	background: `radial-gradient(circle at ${animationProgression.value}%, #d0d2d3, #ffffff)`,
							// 	// #c8e8f0
							// 	backgroundClip: "text",
							// 	WebkitBackgroundClip: "text",
							// 	WebkitTextFillColor: "transparent",
							// 	/* animation: `${textEffect} 1s infinite`, */
							// }}
						>
							{t("global.comingSoon")}
						</Typography>
					</div>
				</Fade>
			</Box>
		</Button>
	);
};

export default CollapsableButton;
