import { useMutation, useQueryClient } from "@tanstack/react-query";
import { EditRoomApi } from "certiblok-api-manager";
import { UseFormReturn } from "react-hook-form";
import { useApi } from "../../../../../utils/hooks/api";
import { EditRoomFormValues } from "./useEditRoomDetailsZodSchema";
import { useMe } from "../../../../../utils/hooks/useMe";
import { getGetSingleEditRoomFolderQueryKey } from "../../../pages/EditRooms/queries/useGetEditRooms";
import { usePlanUsage } from "../../../../../utils/components/scaffold/components/Drawer/components/SidebarUsage/hooks/usePlanUsage";

export type OptionProps = {
	onMutate?: () => void;
	onSuccess?: () => void;
	onError?: () => void;
	onSettled?: () => void;
};

type EditCreateEditRoomProps = {
	editRoomId?: string;
	fromFolderId?: string;
	fromDocumentId?: string;
	options?: OptionProps;
};

export const useEditCreateEditRoom = ({
	editRoomId,
	fromFolderId,
	fromDocumentId,
	options,
}: EditCreateEditRoomProps) => {
	const editRoomApi = useApi(EditRoomApi);
	const queryClient = useQueryClient();
	const { me } = useMe();

	const { planUsage, revalidatePlanUsage, mutatePlanUsageOptimistic } = usePlanUsage();

	return useMutation({
		onMutate: () => {
			options?.onMutate?.();
		},
		onSuccess: () => {
			queryClient.invalidateQueries(getGetSingleEditRoomFolderQueryKey(editRoomId ?? ""));
			if (!editRoomId) {
				mutatePlanUsageOptimistic({ usedEditRooms: (planUsage?.usedEditRooms ?? 0) + 1 });
				revalidatePlanUsage();
			}
			options?.onSuccess?.();
		},
		onError: () => {
			options?.onError?.();
		},
		onSettled: () => {
			options?.onSettled?.();
		},
		mutationFn: async ({ data }: { data: EditRoomFormValues; form: UseFormReturn<EditRoomFormValues> }) => {
			if (me) {
				const reservedToUserIds = data.reservedToUsers
					? [...data.reservedToUsers?.map((user) => user.id), me.id]
					: undefined;
				const reservedToTeamIds = data.reservedToTeams ? [...data.reservedToTeams?.map((team) => team.id)] : undefined;
				if (editRoomId) {
					return editRoomApi.editEditRoomFolder(editRoomId, {
						name: data.name,
						color: data.color,
						reservedToUserIds: data.lockEditRoomEnabled ? reservedToUserIds : undefined,
						reservedToTeamIds: data.lockEditRoomEnabled ? reservedToTeamIds : undefined,
						expirationDate: data.noExpiration ? "null" : data.expireDate ?? "null",
					});
				} else {
					return editRoomApi.createEditRoom({
						name: data.name,
						color: data.color,
						reservedToUserIds: data.lockEditRoomEnabled ? reservedToUserIds : undefined,
						reservedToTeamIds: data.lockEditRoomEnabled ? reservedToTeamIds : undefined,
						folderId: fromFolderId,
						documentId: fromDocumentId,
					});
				}
			}
		},
	});
};
