import {
	Box,
	LinearProgress,
	linearProgressClasses,
	Stack,
	SxProps,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { forwardRef, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { pxToRem } from "../functions/theme";
import { validityColor } from "../functions/validity";
import CertiblokIcon from "./icons/CertiblokIcon";

type Props = { percentage: number; sx?: SxProps<Theme> };

const ExpiringProgress: React.FC<Props> = forwardRef(({ percentage, sx, ...props }, ref) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up("md"));
	return (
		<Box ref={ref} sx={{ display: "flex", alignItems: "center", gap: 2, ...sx }} {...props}>
			{percentage > 0 ? (
				<Fragment>
					{isMd && (
						<Box sx={{ width: "100%" }}>
							<LinearProgress
								color="primary"
								variant="determinate"
								value={percentage}
								sx={{
									height: 6,
									borderRadius: 1,
									[`& .${linearProgressClasses.bar}`]: {
										backgroundColor: validityColor(percentage),
									},
								}}
							/>
						</Box>
					)}
					<Typography
						children={`${percentage}%`}
						variant={isMd ? "caption" : "body2"}
						sx={{ fontSize: isMd ? pxToRem(10) : "inherit", minWidth: 35, textAlign: "center" }}
					/>
				</Fragment>
			) : (
				<Stack
					direction="row"
					alignItems="center"
					sx={{
						backgroundColor: "rgba(255, 59, 48, 0.12)",
						padding: 1,
						borderRadius: 3,
						px: 2,
						maxWidth: "100%",
						boxSizing: "border-box",
					}}
					gap={1}
				>
					<CertiblokIcon size={12} color="rgba(255, 59, 48, 1)" name={"comunication_outline_03"} />
					<Typography
						children={t("variousComponents.expired")}
						variant={"caption"}
						noWrap
						sx={{
							// fontSize: isMd ? pxToRem(10) : "inherit",
							minWidth: 35,
							textAlign: "center",
							color: "rgba(255, 59, 48, 1)",
						}}
					/>
				</Stack>
			)}
		</Box>
	);
});

export default ExpiringProgress;
