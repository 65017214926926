import { Button, Typography } from "@mui/material";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import enableTwoFa from "../../../../../assets/two_fa/enableTwoFa.jpg";
import {
	ModalNavigator,
	useModalNavigator,
	useModalRoute,
} from "../../../../../utils/components/ModalNavigator/ModalNavigator";
import WhiteModal from "../../../../../utils/components/WhiteModal";
import CertiblokIcon from "../../../../../utils/components/icons/CertiblokIcon";
import { useBetterMediaQuery } from "../../../../../utils/hooks/useBetterMediaQuery";

type Props = {
	isActivatingTFA: boolean;
	onCancel?: () => void;
	onSuccess?: () => void;
};

const ToggleTwoFAModal = ModalNavigator.create(({ isActivatingTFA, onCancel, onSuccess }: Props) => {
	const { t } = useTranslation();
	const { visible } = useModalRoute();
	const { popModal } = useModalNavigator();
	const isOverMd = useBetterMediaQuery("md");
	const isOverLg = useBetterMediaQuery("lg");

	const pros = [t("settings.tfaProFirst"), t("settings.tfaProSecond")];

	const onCloseCancel = () => {
		onCancel?.();
		popModal();
	};
	const onCloseSuccess = () => {
		onSuccess?.();
		popModal();
	};

	return (
		<WhiteModal
			open={visible}
			onClose={onCloseCancel}
			className={clsx(
				isActivatingTFA &&
					"[&_.MuiBox-root]:p-0 [&_.MuiBox-root]:w-[90vw] [&_.MuiBox-root]:md:w-[70vw] [&_.MuiBox-root]:md:max-w-[960px] [&_.MuiBox-root]:max-w-[500px]"
			)}
			responsive={!isActivatingTFA}
		>
			{isActivatingTFA ? (
				<div className="w-full md:h-[70vh] max-h-[640px] flex flex-col md:flex-row">
					<div className="h-[250px] md:h-[unset] md:w-[50%] bg-background md:rounded-bl-2xl md:rounded-tl-2xl rounded-t-2xl md:rounded-tr-none overflow-hidden  flex items-center justify-center">
						<img src={enableTwoFa} alt="" className="md:h-full md:w-auto w-full h-full object-cover object-top" />
					</div>
					<div className="md:w-[50%] md:shadow-[0px_10px_20px_0px_rgba(0,0,0,0.08)] h-full flex flex-col justify-between md:py-4 md:pb-8 md:px-10 box-border p-6">
						<Button
							className="text-black/[0.74] uppercase ml-auto hidden md:inline-flex"
							endIcon={<CertiblokIcon name="close" color="rgba(0,0,0,0.74)" />}
							onClick={onCloseCancel}
						>
							{t("global.close")}
						</Button>
						<div className="pt-2 pb-8 ">
							<Typography
								variant={isOverLg ? "h4" : isOverMd ? "h5" : "h6"}
								className="font-extrabold mb-2 md:mb-8 text-center"
							>
								{t("settings.activateTwoFactor")}
							</Typography>
							<div className="flex flex-col gap-6">
								{pros.map((pro, i) => (
									<div className="flex gap-4" key={i}>
										<CertiblokIcon name="success" color="success" size={isOverLg ? 32 : isOverMd ? 28 : 16} />
										<Typography variant={isOverLg ? "h5" : isOverMd ? "h6" : "caption"}>{pro}</Typography>
									</div>
								))}
							</div>
						</div>
						<div className="w-full flex gap-3 md:block">
							<Button variant="outlined" className="md:hidden flex-1" onClick={onCloseCancel} color="secondary">
								{t("global.cancel")}
							</Button>
							<Button
								variant="contained"
								className="md:uppercase md:w-full max-w-[450px] flex-1 flex-grow-[4]"
								color="secondary"
								href="/profile/security/two-factor"
								onClick={onCloseSuccess}
								endIcon={isOverMd && <CertiblokIcon name="arrow_direction_outline_right" color="white" />}
							>
								{t("settings.activateNow")}
							</Button>
						</div>
					</div>
				</div>
			) : (
				<div className="flex flex-col gap-6">
					{
						<div
							className={clsx("w-16 h-16 md:h-[72px] md:w-[72px] flex items-center justify-center rounded-full bg-alert-a12")}
						>
							<CertiblokIcon size={isOverMd ? 48 : 40} color="warning" name={"shield_bold_02"} />
						</div>
					}
					<div>
						<h6 className="text-h6 font-bold md:text-h4 md:font-normal pb-2">{t("settings.wantDisableTwoFA")}</h6>
						<p className="text-caption font-normal md:text-h6 text-black-a60">{t("settings.wantDisableTwoFADescription")}</p>
					</div>
					<div className="flex justify-between gap-3 mt-3">
						<Button
							children={t("global.cancel")}
							color="secondary"
							variant={"contained"}
							onClick={onCloseCancel}
							size={isOverMd ? "large" : "small"}
							startIcon={<CertiblokIcon size={24} color="inherit" name={"close"} />}
						/>

						<Button
							children={t("global.disable")}
							className={"flex-grow md:flex-grow-0 md:min-w-[206px]"}
							color="secondary"
							variant={"outlined"}
							onClick={onCloseSuccess}
							size={isOverMd ? "large" : "small"}
						/>
					</div>
				</div>
			)}
		</WhiteModal>
	);
});
export default ToggleTwoFAModal;
