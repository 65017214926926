import { Box, Modal, ModalProps, Slide, SxProps, Theme, useTheme } from "@mui/material";
import useResizeObserver from "@react-hook/resize-observer";
import React, { useEffect, useRef } from "react";
import { useDimensions } from "../../hooks/withDimensions";

export interface OldBottomSheetProps extends ModalProps {
	containerSx?: SxProps<Theme>;
	isBehind?: boolean;
	index?: number;
	frontBsHeight?: number;
	boxRef?: React.RefObject<HTMLDivElement>;
	onHeightChange?: (height: number | undefined) => void;
	header?: React.ReactNode;
	footer?: React.ReactNode;
}

/**
 * @deprecated use BottomSheet instead
 */
const OldBottomSheet: React.FC<OldBottomSheetProps> = ({
	children,
	containerSx,
	isBehind = false,
	index = 1,
	frontBsHeight,
	boxRef,
	onHeightChange,
	header,
	footer,
	...otherProps
}) => {
	const theme = useTheme();

	const innerRef = useRef<HTMLDivElement>(null);

	const oldHeight = useRef<number | undefined>(undefined);

	//effect to get height of the content container on first paint, which happens every time open is true (this is because the container is mounted and unmounted on every open change due to be wrapped in Slide)
	useEffect(() => {
		const timeout = setTimeout(() => {
			const newHeight = boxRef ? boxRef.current?.offsetHeight : innerRef.current?.offsetHeight;
			onHeightChange?.(newHeight ?? 0);
		}, 0);
		return () => clearTimeout(timeout);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [otherProps.open]);

	useResizeObserver(boxRef ? boxRef.current : innerRef.current, () => {
		const newHeight = (boxRef ? boxRef.current?.offsetHeight : innerRef.current?.offsetHeight) ?? 0;
		if (oldHeight.current !== newHeight) {
			onHeightChange?.(newHeight);
		}
		oldHeight.current = newHeight;
	});

	const [{ width: pageWidth }] = useDimensions();

	return (
		<Modal
			closeAfterTransition
			BackdropProps={{
				timeout: 500,
			}}
			{...otherProps}
		>
			<Slide direction="up" in={otherProps.open} mountOnEnter unmountOnExit>
				<Box
					sx={{
						position: "absolute",
						bottom: 0,
						outline: "none",
					}}
				>
					<Box
						ref={boxRef ? boxRef : innerRef}
						sx={{
							width: "100vw",
							transform: `scale(${isBehind ? (pageWidth - 48 * 2 * index) / pageWidth : 1}) translate(${0}px, ${
								isBehind
									? -(
											90 * index +
											(frontBsHeight ?? 0) -
											((boxRef ? boxRef?.current?.clientHeight : innerRef.current?.clientHeight) ?? 0)
									  )
									: 0
							}px) `,
							bgcolor: "background.paper",
							boxShadow: 24,
							borderRadius: "48px 48px 0px 0px",
							boxSizing: "border-box",
							pt: { xs: 6, lg: 12 },
							transition: theme.transitions.create(["transform"]),
							...(footer && { pb: { xs: 6 } }),
						}}
					>
						<Box sx={{ px: { xs: 6, lg: 12 } }}>{header}</Box>
						<Box
							className="horizontal-scroll"
							sx={{
								overflowY: "auto",
								minHeight: "50vh",
								maxHeight: footer !== undefined ? "75vh" : "80vh",
								boxSizing: "border-box",
								height: "100%",
								pb: { xs: 6, lg: 12 },
								px: { xs: 6, lg: 12 },
								...containerSx,
							}}
						>
							{children}
						</Box>
						<Box sx={{ px: { xs: 6, lg: 12 } }}>{footer}</Box>
					</Box>
				</Box>
			</Slide>
		</Modal>
	);
};

export default OldBottomSheet;
