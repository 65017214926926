import { Typography } from "@mui/material";
import { EventInstance } from "certiblok-api-manager";
import { DateTime } from "luxon";
import { useParseRecurrenceString } from "../../../functions/parseRecurrenceString";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import clsx from "clsx";
import CertiblokIcon from "../../../../icons/CertiblokIcon";
import { ReactNode } from "react";
import { checkAllDayLongEvent } from "../functions/checkAllDayLongEvent";

const EventTimingDisplay = ({
	isLoading = false,
	event,
	gridClassName,
	gridChildren,
}: {
	isLoading?: boolean;
	event?: EventInstance;
	gridClassName?: string;
	gridChildren?: ReactNode;
}) => {
	const { i18n } = useTranslation();

	const startTime = event?.start && DateTime.fromISO(event?.start).toFormat("HH:mm");
	const endTime = event?.end && DateTime.fromISO(event?.end).toFormat("HH:mm");

	const isMultipleDays = (event?.daysAmount ?? 0) > 0;
	const isRecurrent = (event?.event?.recurrence?.length ?? 0) !== 0;
	const isAllDayLong = checkAllDayLongEvent(startTime, endTime, event?.daysAmount);

	const recurrence = useParseRecurrenceString(event?.event?.recurrence?.[0] ?? "", {
		locale: i18n.language,
		isMultipleDays: isMultipleDays,
	});
	return (
		<div className={twMerge(clsx("grid grid-cols-[24px,1fr] gap-x-2 gap-y-4"), gridClassName)}>
			<CertiblokIcon name="calendar_outline" className="text-black" />
			<div className="flex flex-col gap-2">
				{isLoading ? (
					<div>
						<div className="bg-gray-200 animate-pulse rounded-md w-[130px] h-6" />
					</div>
				) : (
					<Typography variant="body2" className="mt-[2px]">
						{event?.start &&
							event?.end &&
							`${DateTime.fromISO(event?.start).toFormat("dd/MM/yyyy")}${isMultipleDays ? " - " : ""}${
								isMultipleDays ? DateTime.fromISO(event?.end).toFormat("dd/MM/yyyy") : ""
							}`}
					</Typography>
				)}
				{isLoading ? (
					<div className="bg-gray-200 animate-pulse rounded-md w-[170px] h-4" />
				) : (
					isRecurrent && <Typography variant="caption">{recurrence}</Typography>
				)}
			</div>

			{!isAllDayLong && !isMultipleDays && (
				<>
					<CertiblokIcon name="clock_outline_02" className="text-black" />
					{isLoading ? (
						<div className="bg-gray-200 animate-pulse rounded-md w-[130px] h-6 mt-" />
					) : (
						<Typography className="mt-[2px]">{`${startTime} - ${endTime}`}</Typography>
					)}
				</>
			)}
			{gridChildren}
		</div>
	);
};

export default EventTimingDisplay;
