import { useMutation } from "@tanstack/react-query";
import { Audit, FavoriteApi } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import MoveDocument from "../../../../pages/Folders/components/MoveDocument/MoveDocument";
import { chunkRequests } from "../../../functions/chunkRequests";
import { apiErrorParser, instanceApi } from "../../../hooks/api";
import { useMe } from "../../../hooks/useMe";
import type { CertiblokDocument } from "../../../interfaces/Document";
import { useModalNavigator } from "../../ModalNavigator/ModalNavigator";
import { withModalNavigator } from "../../ModalNavigatorWrapper";
import AskFileUpdate from "../../documents/AskFileUpdate";
import RenameFile from "../../documents/RenameFile";
import ShareDocument from "../../documents/ShareDocument";
import UpdateOrReplaceFile from "../../documents/UpdateOrReplaceFile";
import MLDialog from "../../poppers";
import AssignToAuditRoomBS from "../AssignToAuditRoomBS/AssignToAuditRoomBS";
import DeleteDocumentDialog from "../DeleteDocumentDialog";
import type { DocumentMenuProps } from "../DocumentMenu";
import { DownloadQrModal } from "../../../../pages/SingleDocument/components/QrCode/components/DownloadQrModal";

const MoveDocumentWithNavigator = withModalNavigator(MoveDocument);
const RenameFileWithNavigator = withModalNavigator(RenameFile);
const ShareDocumentWithNavigator = withModalNavigator(ShareDocument);
const UpdateOrReplaceWithNavigator = withModalNavigator(UpdateOrReplaceFile);
const AskFileWithNavigator = withModalNavigator(AskFileUpdate);

export function useDocumentMenuActions({
	onSuccess,
	onActionComplete,
	document,
}: {
	onSuccess?: DocumentMenuProps["onSuccess"];
	onActionComplete?: DocumentMenuProps["onActionComplete"];
	document: DocumentMenuProps["document"];
}) {
	const { pushModal } = useModalNavigator();
	const { t } = useTranslation();

	const isDocumentArray = Array.isArray(document);
	const singleDocument = Array.isArray(document) ? (document.length === 1 ? document[0] : undefined) : document;

	const { me } = useMe();

	const onSelect = () => {
		onSuccess?.({ document, action: "select" });
	};

	const onShareDocument = () => {
		pushModal(
			ShareDocumentWithNavigator,
			{
				document: isDocumentArray ? document : [document],
				revalidateDocument: () => {},
			},
			{
				shouldHidePrev: true,
			}
		);
	};

	const onAssignAuditRoom = () => {
		pushModal(
			AssignToAuditRoomBS,
			{
				documents: isDocumentArray ? document : [document],
				canRemoveAlreadyAssignedAudits: false,
				shouldMutate: true,
			},
			{
				shouldHidePrev: true,
			}
		).then((audits: Audit[]) => {
			if (audits) {
				onSuccess?.({
					document: isDocumentArray ? document.map((doc) => ({ ...doc, audits })) : { ...document, audits },
					action: "auditRoom",
				});
			}
		});
	};

	const onMoveDocument = () => {
		pushModal(
			MoveDocumentWithNavigator,
			{
				documents: isDocumentArray ? document : [document],
				onSuccessClick: (newFolder) => {
					onSuccess?.({
						document: isDocumentArray
							? document.map((doc) => ({ ...doc, parentFolder: newFolder, parentFolderId: newFolder.id }))
							: { ...document, parentFolder: newFolder, parentFolderId: newFolder.id },
						action: "move",
					});
				},
			},
			{
				shouldHidePrev: true,
			}
		);
	};

	const onRenameDocument = () => {
		if (!singleDocument) return;
		pushModal(
			RenameFileWithNavigator,
			{
				document: singleDocument,
			},
			{
				shouldHidePrev: true,
			}
		).then((document) => {
			onSuccess?.({ document, action: "rename" });
		});
	};

	const onUpdateOrRequestDocument = () => {
		if (!singleDocument) return;
		if (singleDocument.companyId === me?.companyId) {
			pushModal(
				UpdateOrReplaceWithNavigator,
				{
					document: singleDocument,
					update: true,
				},
				{
					shouldHidePrev: true,
				}
			).then((res: CertiblokDocument) => {
				onSuccess?.({ document: res, action: "update" });
			});
		} else {
			pushModal(
				AskFileWithNavigator,
				{
					document: singleDocument,
				},
				{ shouldHidePrev: true }
			);
		}
	};

	const onReplaceDocument = () => {
		if (!singleDocument) return;
		pushModal(
			UpdateOrReplaceWithNavigator,
			{
				document: singleDocument,
			},
			{
				shouldHidePrev: true,
			}
		).then((res: CertiblokDocument) => {
			onSuccess?.({ document: res, action: "replace" });
		});
	};

	const onDeleteDocument = () => {
		pushModal(
			DeleteDocumentDialog,
			{
				document,
			},
			{ shouldHidePrev: true }
		).then((document: CertiblokDocument | CertiblokDocument[]) => {
			onSuccess?.({ document, action: "delete" });
		});
	};

	const onToggleFavorite = useMutation({
		onMutate: ({ isFavorite }) => {
			onSuccess?.({
				document: isDocumentArray
					? document.map((doc) => ({ ...doc, isFavorite: !isFavorite }))
					: {
							...document,
							isFavorite: !isFavorite,
					  },
				action: "favorite",
			});
		},
		mutationFn: ({ isFavorite }: { isFavorite: boolean }) => {
			return chunkRequests(
				10,
				(item) => {
					return isFavorite
						? instanceApi(FavoriteApi).removeDocumentFromFavorite(item.id)
						: instanceApi(FavoriteApi).addDocumentToFavorite({
								documentId: item.id,
						  });
				},
				Array.isArray(document) ? document : [document]
			);
		},
		onSuccess: (res, { isFavorite }) => {
			if (res.chunkedErrors.length > 0) {
				MLDialog.showSnackbar(
					isFavorite
						? t("documents.removedFromFavoriteFailed", { count: res.chunkedErrors.length })
						: t("documents.failedToAddToFavorite", { count: res.chunkedErrors.length }),
					{
						variant: "error",
					}
				);
			}
			if (res.chunkedSuccessfully.length > 0) {
				MLDialog.showSnackbar(
					isFavorite
						? t("documents.removedFromFavorite", { count: res.chunkedSuccessfully.length })
						: t("documents.addedToFavorite", { count: res.chunkedSuccessfully.length }),
					{ variant: "success" }
				);
			}
			onActionComplete?.({
				document: isDocumentArray
					? document.map((doc) => ({ ...doc, expireDate: undefined }))
					: {
							...document,
							isFavorite: !isFavorite,
					  },
				action: "favorite",
			});
		},
		onError: (err) => {
			MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
		},
	});

	const onDownoadQrCode = () => {
		pushModal(DownloadQrModal, { document });
	};

	return {
		onSelect,
		onShareDocument,
		onAssignAuditRoom,
		onMoveDocument,
		onRenameDocument,
		onUpdateOrRequestDocument,
		onReplaceDocument,
		onDeleteDocument,
		onToggleFavorite,
		onDownoadQrCode,
	};
}
