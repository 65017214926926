import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useBetterMediaQuery } from "../../../hooks/useBetterMediaQuery";
import CertiblokIcon from "../../icons/CertiblokIcon";
import { ModalNavigator, useModalNavigator, useModalRoute } from "../../ModalNavigator/ModalNavigator";
import WhiteModal from "../../WhiteModal";

const UnsavedEditsModal = ModalNavigator.create(({ onSuccess }: { onSuccess?: () => void }) => {
	const { visible } = useModalRoute();
	const { popModal } = useModalNavigator();
	const isMd = useBetterMediaQuery("md");

	const { t } = useTranslation();

	return (
		<WhiteModal
			open={visible}
			onClose={popModal}
			containerSx={{
				borderRadius: 10,
			}}
			responsive
		>
			<div className="flex flex-col gap-6">
				<div className="w-16 flex items-center justify-center h-16 md:w-[72px] md:h-[72px] bg-error-a12 rounded-full">
					<CertiblokIcon size={isMd ? 48 : 40} color="error" name={"log_bold_01"} />
				</div>
				<div>
					<h6 className="text-h6 font-bold md:text-h4 md:font-normal pb-2">{t("global.unsavedEdits")}</h6>
					<p className="text-caption font-normal md:text-h6 text-black-a60">{t("global.allUnsavedEditsWillBeLost")}</p>
				</div>
				<div className="flex justify-between gap-3 mt-3">
					<Button
						children={t("global.cancel")}
						startIcon={<CertiblokIcon size={24} color="inherit" name={"close"} />}
						size={isMd ? "large" : "small"}
						variant="contained"
						onClick={popModal}
						color="secondary"
					/>
					<Button
						children={t("profile.logout")}
						size={isMd ? "large" : "small"}
						variant="outlined"
						onClick={() => {
							onSuccess?.();
							popModal();
						}}
						color="secondary"
						className="md:min-w-[120px]"
					/>
				</div>
			</div>
		</WhiteModal>
	);
});
export default UnsavedEditsModal;
