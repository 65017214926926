import React, { useCallback, useState } from "react";
import { FolderHistoryItem } from "../../../../Folders/interfaces/FolderHistoryItem";
import { IFolderHistoryContext } from "../../../../Folders/interfaces/IFolderHistoryContext";

export function useEditRoomFolderHistoryContext(): IFolderHistoryContext {
	const [folderHistory, setFolderHistory] = useState<FolderHistoryItem[]>([]);

	const updateHistory = useCallback((items: FolderHistoryItem[]) => {
		setFolderHistory([...items]);
	}, []);

	return {
		folderHistory,
		updateHistory,
	};
}

export const EditRoomFolderHistoryContext = React.createContext<IFolderHistoryContext>({} as IFolderHistoryContext);
