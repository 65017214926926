import { alpha, styled, Tab, tabClasses, TabProps, Tabs, tabsClasses, TabsProps } from "@mui/material";

export const ContainedTabs = styled((props: TabsProps & { color?: "primary" | "secondary" }) => <Tabs {...props} />)(
	({ theme, color }) => ({
		backgroundColor: alpha("#000", 0.06),
		borderRadius: 12,
		padding: 2,
		minHeight: 0,
		[`& .${tabsClasses.flexContainer}`]: {
			gap: 8,
		},
		[`& .${tabsClasses.indicator}`]: {
			display: "none",
		},
	})
);

export const ContainedTab = styled((props: TabProps & { color?: "primary" | "secondary" }) => <Tab {...props} />)(
	({ theme, color }) => ({
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		height: 32,
		gap: 8,
		minHeight: 0,
		borderRadius: 10,
		padding: "4px 8px",
		color: theme.palette[color || "primary"].main,
		transition: theme.transitions.create("background-color"),
		backgroundColor: "transparent",
		...theme.typography.caption,
		textTransform: "none",
		"&:hover :not(.Mui-selected)": {
			backgroundColor: alpha(theme.palette[color || "primary"].main, 0.06),
		},
		"&:not(.Mui-selected)": {
			color: alpha("#000", 0.6),
		},
		[`& .${tabClasses.iconWrapper}`]: {
			margin: 0,
		},
		"&.Mui-selected": {
			backgroundColor: theme.palette[color ?? "primary"].main,
			color: "white",
			textShadow: `-0.06ex 0 ${theme.palette.surface.main}, 0.06ex 0 ${theme.palette.surface.main}`,
		},
	})
);
