import NiceModal from "@ebay/nice-modal-react";
import ResponsiveDialog from "../../../../../dialogs/ResponsiveDialog";
import { useModalNavigator, useModalRoute } from "../../../../../ModalNavigator/ModalNavigator";
import { Button, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CertiblokIcon from "../../../../../icons/CertiblokIcon";
import { useEffect, useState } from "react";
import { GeneralEventDeletion } from "./GeneralEventDeletion";
import { EditEventInstanceMode } from "certiblok-api-manager";

type RecurrentEventDeletionSelectionProps = {
	eventInstanceId?: string;
	isMyEvent: boolean;
	onDelete?: () => void;
	noOtherParticipant?: boolean;
};

export const RecurrentEventDeletionSelection = NiceModal.create<RecurrentEventDeletionSelectionProps>(
	({ eventInstanceId, isMyEvent, onDelete, noOtherParticipant }) => {
		const { t } = useTranslation();
		const { visible } = useModalRoute();
		const { popModal, pushModal } = useModalNavigator();

		const [deleteMode, setDeleteMode] = useState<EditEventInstanceMode>("this");

		useEffect(() => {
			setDeleteMode("this");
		}, [eventInstanceId]);

		const onClose = () => {
			setDeleteMode("this");
			popModal();
		};

		return (
			<ResponsiveDialog
				open={visible}
				onClose={() => onClose()}
				ModalProps={{
					containerSx: {
						borderRadius: 6,
					},
					responsive: true,
				}}
				header={<Typography variant="subtitle1">{t("event.recurrentEventDeletion")}</Typography>}
				footer={
					<div className="flex justify-between items-center gap-4">
						<Button
							variant="outlined"
							color="secondary"
							startIcon={<CertiblokIcon name="close" color="secondary" />}
							onClick={() => onClose()}
						>
							{t("global.close")}
						</Button>
						<Button
							variant="contained"
							color="secondary"
							onClick={() =>
								pushModal(
									GeneralEventDeletion,
									{
										eventInstanceId: eventInstanceId,
										deleteMode: deleteMode,
										isMyEvent: isMyEvent,
										onDelete: onDelete,
										noOtherParticipant: noOtherParticipant ?? false,
									},
									{ shouldHidePrev: true }
								)
							}
						>
							{t("event.proceedWithDeletion")}
						</Button>
					</div>
				}
			>
				<div className="mt-6 mb-8 px-6 md:px-0">
					<RadioGroup
						color="secondary"
						value={deleteMode}
						onChange={(_, value) => setDeleteMode(value as EditEventInstanceMode)}
					>
						<FormControlLabel
							control={<Radio color={"secondary"} />}
							value={"this" as EditEventInstanceMode}
							label={<Typography variant="body2">{t("event.thisEvent")}</Typography>}
						/>
						<FormControlLabel
							control={<Radio color={"secondary"} />}
							value={"following" as EditEventInstanceMode}
							label={<Typography variant="body2">{t("event.thisEventAndSubsequentOnes")}</Typography>}
						/>
						<FormControlLabel
							control={<Radio color={"secondary"} />}
							value={"all" as EditEventInstanceMode}
							label={<Typography variant="body2">{t("events.allTheEvents")}</Typography>}
						/>
					</RadioGroup>
				</div>
			</ResponsiveDialog>
		);
	}
);
