import { Typography } from "@mui/material";
import { CBFile, CloudFile } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import CertiblokIcon from "../../../../../../utils/components/icons/CertiblokIcon";
import FileIcon from "../../../../../../utils/components/icons/FileIcon";
import UnstoppableImportDisclaimer from "../UnstoppableImportDisclaimer";

const ImportFilesStep = ({ filesToImport }: { filesToImport: CloudFile[] }) => {
	const { t } = useTranslation();
	return (
		<div className="flex flex-col flex-1">
			<Typography className="text-black/[0.6] mb-4">{t("cloudImport.selectedFilesDescription")}</Typography>
			<div className="gap-4 grid md:grid-cols-2 grid-cols-1 flex-1 basis-0 overflow-auto content-start">
				{filesToImport.map((file) => (
					<div key={file.id} className="flex gap-4 self-start items-center">
						{file.type === "folder" ? (
							<CertiblokIcon name="folder_bold" color="#b0b0b0" />
						) : (
							<FileIcon file={{ type: file.mimeType } as CBFile} />
						)}
						<Typography noWrap className="select-none">
							{file.name}
						</Typography>
					</div>
				))}
			</div>
			<div className="mt-3">
				<UnstoppableImportDisclaimer />
			</div>
		</div>
	);
};

export default ImportFilesStep;
