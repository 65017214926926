import { useMutation } from "@tanstack/react-query";
import { EventApi } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import MLDialog from "../../../../../utils/components/poppers";
import { apiErrorParser, instanceApi } from "../../../../../utils/hooks/api";
import { getEventCreateEditInput } from "../functions/getEventCreateEditInput";
import { EditCreateEventForm } from "./useEditCreateEventFormValidator";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { GlobalCalendarContext } from "../../../../../utils/components/globalCalendar/controllers/GlobalCalendarContext";
import { UseFormReturn } from "react-hook-form";

export function useCreateEvent() {
	const { t } = useTranslation();

	const calendarContext = useContext(GlobalCalendarContext);

	const navigate = useNavigate();

	const createEventMutation = useMutation({
		mutationFn: async ({ form }: { form: UseFormReturn<EditCreateEventForm> }) => {
			const data = form.getValues();
			const createEventBody = getEventCreateEditInput({ form });

			const { data: newEvent } = await instanceApi(EventApi).createEvent(createEventBody);
			await Promise.all(
				(data.sharedWith ?? []).map((user) => {
					if (user.hasBeenDeleted) return Promise.resolve(undefined);

					return instanceApi(EventApi)
						.inviteToEvent({
							eventInstanceId: newEvent.id,
							role: user.role,
							emailOrId: user.userId ?? user.email ?? "",
							mode: "all",
						})
						.catch((err) => {
							MLDialog.showSnackbar(
								t("events.errorInvitingUser", { user: user.userId ? `${user.name ?? ""} ${user.surname ?? ""}` : user.email }),
								{
									variant: "error",
								}
							);
						});
				})
			);
			return newEvent;
		},
		onError: (err) => {
			MLDialog.showSnackbar(apiErrorParser(err), {
				variant: "error",
			});
		},
		onSuccess: () => {
			MLDialog.showSnackbar(t("events.eventCreatedSuccessfully"), {
				variant: "success",
			});
			//!this check is needed because the calendarContext is not always available
			if (calendarContext?.revalidateEvents) {
				calendarContext.revalidateEvents();
			}
			navigate("/calendar");
		},
	});

	return createEventMutation;
}
