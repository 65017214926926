import { useMutation } from "@tanstack/react-query";
import { DriveApi, DropboxApi, OnedriveApi } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import MLDialog from "../../../../../utils/components/poppers";
import { useApi } from "../../../../../utils/hooks/api";
import { CloudSource } from "../ImportFromCloudContentModal";

export const useImportFoldersAndDocs = ({
	enabledCloud,
	onSuccess,
}: {
	enabledCloud: CloudSource | undefined;
	onSuccess?: () => void;
}) => {
	const { t } = useTranslation();
	const driveApi = useApi(DriveApi);
	const dropboxApi = useApi(DropboxApi);
	const onedriveApi = useApi(OnedriveApi);

	return useMutation({
		mutationFn: ({ fileIds }: { fileIds: string[] }) => {
			if (enabledCloud === "googleDrive") {
				return driveApi.importFilesFromDrive({ fileIds });
			} else if (enabledCloud === "dropbox") {
				return dropboxApi.importFilesFromDropbox({ fileIds });
			}
			return onedriveApi.importFilesFromOneDrive({ fileIds });
		},
		onError: () => {
			MLDialog.showSnackbar(t("cloudImport.errorInCloudDocs"), { variant: "error" });
		},
		onSuccess: () => {
			onSuccess?.();
		},
	});
};
