import { TFunction, useTranslation } from "react-i18next";
import { Location, useLocation } from "react-router-dom";
import { BackButtonWithTitle } from "../../../utils/components/BackButtonWithTitle";

const returnCurrentRoute = (location: Location, t: TFunction<"translation", undefined>) => {
	const pathnames = location.pathname.split("/").filter((x) => x);
	switch (pathnames[pathnames.length - 1]) {
		case "security":
			return t("profile.security");
		case "update":
			return t("profile.editProfile");
		case "team":
			return t("profile.team");
		case "settings":
			return t("profile.settings");
		case "sync-folder":
			return t("profile.syncFolder");
		default:
			return "";
	}
};

const ProfileBackButton = () => {
	const { t } = useTranslation();
	const location = useLocation();

	return <BackButtonWithTitle title={returnCurrentRoute(location, t)} className="w-fit" />;
};

export default ProfileBackButton;
