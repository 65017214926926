import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useWeavy, WyMessenger } from "@weavy/uikit-react";
import { WeavyApi } from "certiblok-api-manager";
import { t } from "i18next";
import { useAtomValue } from "jotai";
import OutletContainer from "../../utils/components/OutletContainer";
import { instanceApi } from "../../utils/hooks/api";
import { useFeatureFlags } from "../../utils/hooks/useFeatureFlags";
import { chatAtom } from "./atoms/chatAtom";
import { chatMenuAtom } from "./atoms/chatMenuAtom";
import ChatAppBar from "./components/ChatAppBar";
import ChatList from "./components/ChatList";

const WeavyChat = () => {
	useWeavy({
		url: "https://014ea6bec6e14b308e2e5ed8d348e192.weavy.io",
		tokenFactory: async () => {
			const { data } = await instanceApi(WeavyApi).getWeavyAccessToken();

			return data?.token;
		},
	});

	const theme = useTheme();
	const isOverLg = useMediaQuery(theme.breakpoints.up("lg"));

	const chatMenuOpen = useAtomValue(chatMenuAtom).open;

	const chatId = useAtomValue(chatAtom).chatId;

	const { isFeatureEnabled } = useFeatureFlags();

	const refCallback = (ref: any) => {
		setTimeout(() => {
			if (ref) {
				const shadowRoot = ref.shadowRoot;

				const chatInterval = setInterval(() => {
					if (shadowRoot !== undefined) {
						shadowRoot?.append(
							Object.assign(document.createElement("STYLE"), {
								innerHTML: `
								wy-conversation-list {
									display: none;
									}
									wy-conversation-appbar {
										display: none;
										}
										.wy-message-me {
											--wy-component-background-color: #52bd8f;
											}
											`,
							}) // Object.assign
						);

						const wyConversationExtendedShadowRoot = shadowRoot?.querySelector("wy-conversation-extended")?.shadowRoot;

						if (wyConversationExtendedShadowRoot !== undefined) {
							wyConversationExtendedShadowRoot?.append(
								Object.assign(document.createElement("STYLE"), {
									innerHTML: `
							wy-avatar-header {
								display: none;
								}
								.wy-messages {
									flex: 1 1 calc(100% - 34px) !important;
									}
									`,
								}) // Object.assign
							);

							const wyMessagesShadowRoot = wyConversationExtendedShadowRoot?.querySelector("wy-messages")?.shadowRoot;

							if (wyMessagesShadowRoot !== undefined) {
								wyMessagesShadowRoot?.append(
									Object.assign(document.createElement("STYLE"), {
										innerHTML: `
									.wy-messages {
										flex: 1 1 calc(100% - 58px) !important;
										overflow-y: auto;
										}
										`,
									}) // Object.assign
								);

								const wyMessages = wyMessagesShadowRoot?.querySelector(".wy-messages");

								if (wyMessages !== undefined) {
									wyMessages.scrollTo(0, wyMessages.scrollHeight);
									clearInterval(chatInterval);
								}
							}
						}
					}
				}, 10);
			}
		}, 0);
	};

	if (isFeatureEnabled("chat") === false) {
		return null;
	}

	return (
		<OutletContainer variant="small">
			<Box
				sx={{
					width: "100%",
					display: "flex",
					flexDirection: "column",
					height: "100%",
					maxHeight: "100%",
				}}
			>
				<ChatAppBar />
				<div className="flex flex-row gap-3 flex-1">
					{(chatMenuOpen || isOverLg) && <ChatList />}
					{(!chatMenuOpen || isOverLg) &&
						(Boolean(chatId) ? (
							<WyMessenger
								conversationId={chatId}
								className="h-[calc(100vh_-_184px)]"
								ref={refCallback}
								noCloudFiles
								noMeetings
								noMentions
								key={chatId}
								noThumbnails
							/>
						) : (
							<div className="flex-1 h-[calc(100vh_-_184px)] flex flex-row items-center justify-center">
								<Typography variant="subtitle1" className="text-center">
									{t("chat.noSelectedChat")}
								</Typography>
							</div>
						))}
				</div>
			</Box>
		</OutletContainer>
	);
};

export default WeavyChat;
