import type { Notification } from "certiblok-api-manager";
import { useSnackbar } from "notistack";
import NotificationRow from "../components/NotificationRow";

export function useShowNotification() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const showNotification = (notification: Notification, markAsRead: () => void) => {
		enqueueSnackbar("", {
			anchorOrigin: {
				vertical: "top",
				horizontal: "right",
			},
			content: (key) => (
				<NotificationRow
					notification={notification}
					containerSx={{ maxWidth: 600, margin: 0 }}
					sx={{ boxShadow: "0px 1px 18px rgba(0, 0, 0, 0.12)" }}
					withCloseButton
					closeButtonAction={() => {
						closeSnackbar(key);
					}}
					markAsRead={() => {
						closeSnackbar(key);
						markAsRead();
					}}
				/>
			),
		});
	};

	return {
		showNotification,
	};
}
