import { alpha, Box, Typography } from "@mui/material";
import type { Audit } from "certiblok-api-manager";
import BetterCheckbox from "../BetterCheckbox";
import CertiblokIcon from "../icons/CertiblokIcon";

type Props = {
	auditRoom: Audit;
	isSelected: boolean;
	isDisabled?: boolean;
	toggleSelected: () => void;
};

const AuditRoomSelector: React.FC<Props> = ({ auditRoom, isSelected, toggleSelected, isDisabled }) => {
	return (
		<Box
			key={auditRoom.id}
			onClick={() => {
				if (!isDisabled) toggleSelected();
			}}
			sx={{ width: "100%", height: 32, display: "flex", cursor: isDisabled ? "unset" : "pointer", userSelect: "none" }}
		>
			<BetterCheckbox
				disabled={isDisabled}
				checked={isSelected}
				color="secondary"
				sx={{ width: 32, height: 32, opacity: isDisabled ? 0.38 : 1 }}
			/>
			<Box
				sx={{
					backgroundColor: alpha(auditRoom.color, isDisabled ? 0.06 : 0.24),
					flexGrow: 1,
					minWidth: 0,
					borderRadius: 4,
					boxSizing: "border-box",
					display: "flex",
					alignItems: "center",
					padding: "6.5px 4px",
					gap: 2,
				}}
			>
				<CertiblokIcon
					size={20}
					color={auditRoom.color}
					name={"profile_bold_01"}
					iconCss={{ opacity: isDisabled ? 0.38 : 1 }}
				/>
				<Typography
					children={auditRoom.name}
					variant="body2"
					noWrap
					sx={{ opacity: isDisabled ? 0.38 : 0.6, flexGrow: 1, minWidth: 0 }}
				/>
			</Box>
		</Box>
	);
};

export default AuditRoomSelector;
