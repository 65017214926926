/** @jsxImportSource @emotion/react */
import { keyframes } from "@emotion/css";
import { Fade, IconButton } from "@mui/material";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Tag } from "certiblok-api-manager";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import CertiblokIcon, { CertiblokIconNames } from "../../../utils/components/icons/CertiblokIcon";
import MenuButton from "../../../utils/components/MenuButton";
import { useModalNavigator } from "../../../utils/components/ModalNavigator/ModalNavigator";
import DeleteTagModal from "../../SingleDocument/components/Tags/DeleteTagModal";
import EditCreateTag from "../../SingleDocument/components/Tags/TagsManagementModal/EditCreateTag";

type OptionsProps = {
	tag: Tag;
	shouldShowOptionButton: boolean;
	onSuccess?: () => void;
};

const fade = keyframes`
	from {opacity: 0;}
	to {opacity: 1;}
`;

const TagTableRowOptions: FC<OptionsProps> = ({ shouldShowOptionButton, tag, onSuccess }) => {
	const { t } = useTranslation();
	const { pushModalById } = useModalNavigator();

	const menuButtons: { icon: CertiblokIconNames; children: string; onClick: () => void }[] = [
		{
			icon: "edit_outline_02",
			children: t("tags.editTag"),
			onClick: () => {
				pushModalById(`edit-create-tag-modal-${tag.id}`);
			},
		},
	];

	return (
		<>
			<DropdownMenu.Root>
				<Fade appear={false} in={shouldShowOptionButton}>
					<DropdownMenu.Trigger asChild>
						<IconButton size={"small"}>
							<CertiblokIcon size={24} color="black" name={"more"} />
						</IconButton>
					</DropdownMenu.Trigger>
				</Fade>
				<DropdownMenu.Portal>
					<DropdownMenu.Content
						collisionPadding={15}
						className="min-w-[200px] bg-white box-border flex flex-col p-1 rounded-xl z-[1500] [filter:drop-shadow(0px_1px_18px_rgba(0,0,0,0.12))]"
						css={{
							animation: `${fade} 350ms cubic-bezier(0.4, 0, 0.2, 1)`,
						}}
					>
						{menuButtons.map((button, index) => {
							return (
								<DropdownMenu.Item asChild key={index}>
									<MenuButton children={button.children} onClick={button.onClick} icon={button.icon} />
								</DropdownMenu.Item>
							);
						})}
						<DropdownMenu.Separator className="h-[1px] bg-black-a12 my-1" />
						<DropdownMenu.Item asChild>
							<MenuButton
								onClick={() => {
									pushModalById(`delete-tag-modal-${tag.id}`).then(() => {
										onSuccess?.();
									});
								}}
								children={t("tags.deleteTag")}
								icon="delete_outline"
								isDelete
							/>
						</DropdownMenu.Item>
						<DropdownMenu.Arrow className="fill-white" />
					</DropdownMenu.Content>
				</DropdownMenu.Portal>
			</DropdownMenu.Root>
			<EditCreateTag
				id={`edit-create-tag-modal-${tag.id}`}
				onEditCreateSuccess={() => {
					onSuccess?.();
				}}
				oldTag={tag}
			/>
			<DeleteTagModal
				id={`delete-tag-modal-${tag.id}`}
				tagId={tag.id}
				// onSuccess={() => {

				// 	console.log("onSuccess");
				// }}
			/>
		</>
	);
};

export default TagTableRowOptions;
