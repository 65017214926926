/** @jsxImportSource @emotion/react */
import { Box, Button, Popover, Typography, useTheme } from "@mui/material";
import { useAtomValue } from "jotai";
import { DateTime } from "luxon";
import { useContext, useState } from "react";
import Calendar from "react-calendar";
import { useTranslation } from "react-i18next";
import { pxToRem } from "../../functions/theme";
import { useSelectedLanguage } from "../../hooks/useLanguageSwitcher";
import CertiblokIcon from "../icons/CertiblokIcon";
import { CurrentIndexContext } from "./controllers/CurrentIndexContext";

const YearSelectionButton = ({ onSelect }: { onSelect: (date: DateTime | null) => void }) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const selectedLocale = useSelectedLanguage();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const currentIndexAtom = useContext(CurrentIndexContext);
	const currentIndex = useAtomValue(currentIndexAtom);

	const onClose = () => {
		setAnchorEl(null);
	};

	const onSelectionComplete = (selection: DateTime | null) => {
		onSelect(selection);
		onClose();
	};

	return (
		<>
			<Button
				variant="outlined"
				color="secondary"
				onClick={(e) => {
					setAnchorEl(e.currentTarget);
				}}
				endIcon={<CertiblokIcon size={24} name={"▾-arrow-3"} color="secondary" />}
			>
				{DateTime.now()
					.plus({ months: currentIndex - 2 })
					.toFormat("yyyy")}
			</Button>
			<Popover
				open={Boolean(anchorEl)}
				onClose={() => {
					onClose();
				}}
				anchorEl={anchorEl}
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
				PaperProps={{
					sx: {
						boxShadow: "0px 1px 12px 0px #0000001F;",
						borderRadius: 4,
						padding: 6,
					},
				}}
			>
				<Box sx={{}}>
					<Calendar
						locale={selectedLocale}
						navigationLabel={() => (
							<Typography variant="subtitle2" className="text-center cursor-default">
								{t("calendar.selectYear")}
							</Typography>
						)}
						prev2Label={null}
						next2Label={null}
						prevLabel={<CertiblokIcon size={24} color="inherit" name={"arrow_mini_outline_left"} />}
						nextLabel={<CertiblokIcon size={24} color="inherit" name={"arrow_mini_outline_right"} />}
						minDate={DateTime.now().startOf("year").toJSDate()}
						maxDate={DateTime.now().plus({ years: 20 }).toJSDate()}
						minDetail="decade"
						defaultView="decade"
						view={"decade"}
						onClickYear={(date) => {
							onSelectionComplete(DateTime.fromJSDate(date));
						}}
						tileClassName={(tile) => {
							return DateTime.fromJSDate(tile.date).startOf("year") < DateTime.now().startOf("year") ||
								DateTime.fromJSDate(tile.date).startOf("year") > DateTime.now().plus({ years: 20 })
								? "react-calendar__tile--disabled"
								: "react-calendar__tile";
						}}
						css={{
							margin: "0px 0px",
							padding: 0,
							width: 268,
							"& .react-calendar__navigation": {
								display: "flex",
								paddingBottom: 10,
								alignItems: "center",
								width: "100%",
							},
							"& .react-calendar__navigation__label": {
								backgroundColor: "unset",
								border: "unset",
								cursor: "pointer",
								textAlign: "center",
								padding: 0,
								paddingLeft: 0,
								color: "black",
								userSelect: "none",
							},
							"& .react-calendar__navigation__arrow": {
								color: "black",
								backgroundColor: "unset",
								border: "unset",
								cursor: "pointer",
								padding: 3,
								height: 30,
								borderRadius: 15,
								transition: theme.transitions.create(["background-color", "border-color", "color"]),
								"&:hover": {
									color: "white",
									backgroundColor: "#3B95A8",
								},
							},
							"& .react-calendar__tile": {
								textTransform: "capitalize",
								color: "black",
								padding: 0,
								flex: "unset !important",
								margin: 1,
								width: 36,
								height: 36,
								textAlign: "center",
								fontWeight: 400,
								fontSize: pxToRem(20),
								borderRadius: 8,
								borderStyle: "solid",
								backgroundColor: "unset",
								borderColor: "transparent",
								boxSizing: "border-box",
								cursor: "pointer",
								transition: theme.transitions.create(["background-color", "border-color", "color"]),
								"&:hover": {
									backgroundColor: "#3B95A8",
								},
								// "&--now": {
								// 	backgroundColor: "white",
								// 	color: theme.palette.magenta.main,
								// },
							},
							"& .react-calendar__year-view__months, .react-calendar__decade-view__years, .react-calendar__century-view": {
								"& .react-calendar__tile": {
									width: "unset",
									flexBasis: "50% !important",
									borderRadius: 20,
									margin: 0,
									"&::after": {
										width: 0,
										height: 0,
									},
									"&:hover": {
										color: "white !important",
									},
									"&--disabled": {
										color: "rgba(0, 0, 0, 0.12) !important",
										backgroundColor: "unset !important",
										cursor: "initial",
										"&:hover": {
											color: "rgba(0, 0, 0, 0.12)  !important",
										},
									},
									"&--active": {
										borderColor: theme.palette.secondary.main,
									},
								},
							},
						}}
					/>
				</Box>
			</Popover>
		</>
	);
};

export default YearSelectionButton;
