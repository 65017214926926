import { alpha, Typography } from "@mui/material";
import CertiblokTextInput from "../../../../CertiblokTextInput";
import CertiblokIcon from "../../../../icons/CertiblokIcon";

export const TitleWithTextField = ({
	title,
	value,
	placeholder,
	onClick,
}: {
	title: string;
	value?: string;
	placeholder?: string;
	onClick?: () => void;
}) => {
	return (
		<>
			<Typography children={title} className="!text-label uppercase md:normal-case md:!text-subtitle1 md:text-black" />
			<CertiblokTextInput
				readOnly
				fullWidth
				sx={{ backgroundColor: "white", marginTop: 2, cursor: "pointer" }}
				inputProps={{
					className: "!cursor-pointer",
				}}
				endAdornment={
					<CertiblokIcon
						size={16}
						color="black"
						name={"▾-arrow-3"}
						iconCss={{
							color: alpha("#000000", 0.6),
						}}
					/>
				}
				onClick={onClick}
				placeholder={placeholder}
				value={value}
			/>
		</>
	);
};
