import { alpha, Badge, Box, Button, ButtonProps, Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

interface SelectedButtonProps extends ButtonProps {
	selected: boolean;
	icon: React.ReactNode;
	selectedIcon: React.ReactNode;
	count?: number;
	// width?: number;
	target?: string;
	rel?: string;
	id: string;
}

const SelectedButton = React.forwardRef<HTMLButtonElement, SelectedButtonProps>((props, ref) => {
	const { selected, children, icon, selectedIcon, count, ...otherProps } = { ...props };
	const theme = useTheme();
	const withText = useMediaQuery(theme.breakpoints.up("md"));
	return (
		<Button
			ref={ref}
			size="medium"
			// endIcon={
			// 	<Collapse in={selected} orientation="horizontal">
			// 		<Box
			// 			sx={{
			// 				width: 8,
			// 				height: 8,
			// 				borderRadius: 2,
			// 				backgroundColor: "primary.main",
			// 				// position: "absolute",
			// 				// right: 8,
			// 				opacity: selected ? 1 : 0,
			// 				transition: theme.transitions.create(["opacity"]),
			// 			}}
			// 		/>
			// 	</Collapse>
			// }
			sx={{
				position: "relative",
				alignItems: "center",
				gap: 2,
				borderRadius: 4,
				padding: 2,
				height: 48,
				minWidth: 48,
				width: {
					// xs: selected ? 48 + 16 : 48,
					xs: 48,
					// md: buttonInnerWidth ? (selected ? buttonInnerWidth + 16 : buttonInnerWidth) : "auto",
					md: "auto",
				},
				flexShrink: 0,
				transition: theme.transitions.create(["opacity", "background-color", "width"]),
				backgroundColor: selected ? "white" : "unset",
				"&:hover": {
					backgroundColor: selected ? "white" : alpha(theme.palette.primary.main, 0.12),
				},
				"&.Mui-disabled": {
					opacity: 1,
					color: "primary.main",
					backgroundColor: "white",
					fontWeight: 700,
				},
			}}
			{...otherProps}
		>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="center"
				gap={2}
				sx={{
					// transform: selected ? "translateX(-8px)" : "none",
					transition: theme.transitions.create(["transform"]),
				}}
			>
				<Box
					sx={{
						backgroundColor: selected ? "background.default" : "unset",
						padding: 1,
						borderRadius: 3,
						boxSizing: "border-box",
						height: 32,
						transition: theme.transitions.create(["background-color"]),
					}}
				>
					<Badge
						badgeContent={count}
						max={9}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "right",
						}}
						color="secondary"
						sx={{
							"& .MuiBadge-badge": {
								border: "2px solid",
								borderColor: "background.default",
								paddingX: "2px",
								bottom: 8,
								right: 2,
							},
						}}
					>
						{selected ? selectedIcon : icon}
					</Badge>
				</Box>
				{withText && children}
			</Stack>
		</Button>
	);
});

export default SelectedButton;
