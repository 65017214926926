import { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useModalNavigator } from "../../../../../utils/components/ModalNavigator/ModalNavigator";
import { ScaffoldContext } from "../../../../../utils/components/scaffold/controllers/ScaffoldContext";
import ChooseImportContent from "../ChooseImportContent";
import { CloudSource } from "../ImportFromCloudContentModal";

const useChooseImportContentByParams = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { pushModal } = useModalNavigator();
	const { setImportedFiles } = useContext(ScaffoldContext);

	useEffect(() => {
		if (searchParams.has("linkedCloud")) {
			const cloud = searchParams.get("linkedCloud");

			const newSearchParams = new URLSearchParams(searchParams);
			newSearchParams.delete("linkedCloud");
			setSearchParams(newSearchParams);

			pushModal(ChooseImportContent, {
				cloudSource: cloud as CloudSource,
				setImportedFiles: setImportedFiles,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams, setSearchParams, pushModal]);
};

export const CheckChooseImportContentByParams = () => {
	useChooseImportContentByParams();
	return null;
};
