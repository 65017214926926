import { Button, Tooltip, useTheme } from "@mui/material";
import DocumentWithIcon from "../../../../../utils/components/DocumentWithIcon";
import type { CertiblokDocument } from "../../../../../utils/interfaces/Document";

type Props = {
	document: CertiblokDocument;
	onClick?: () => void;
	selectedDocument?: CertiblokDocument;
	className?: string;
};

const SingleDocumentToChoose: React.FC<Props> = ({ document, selectedDocument, onClick, className }) => {
	const theme = useTheme();
	return (
		<Tooltip title={document.name} enterDelay={1000}>
			<div>
				<Button
					fullWidth
					size="small"
					onClick={onClick}
					color="secondary"
					sx={{
						borderWidth: 1,
						borderStyle: "solid",
						borderColor: selectedDocument && document.id === selectedDocument.id ? "secondary.main" : "transparent",
						backgroundColor: theme.palette.backgroundSecondary.default,
						gap: 2,
						borderRadius: 4,
						p: 1,
					}}
					className={className}
				>
					<DocumentWithIcon
						name={document.name}
						document={document.lastVersionFile?.[0]}
						sx={{ width: "100%", color: "rgba(0, 0, 0, 0.74)" }}
					/>
				</Button>
			</div>
		</Tooltip>
	);
};

export default SingleDocumentToChoose;
