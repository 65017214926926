import { Avatar, Badge, useTheme } from "@mui/material";
import { SharingGroup } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import avatarPlaceholder from "../../../../../../assets/avatar-placeholder.png";
import { isUserOrContact } from "../../../../../../pages/Folders/components/UploadDocument/functions/isUser";
import GroupChip from "../../../../GroupChip";
import { useModalNavigator } from "../../../../ModalNavigator/ModalNavigator";
import UserChip from "../../../../UserChip";
import { useUploadDocumentContext } from "../../../contexts/UploadDocumentContext";
import { useEditUploadedFileDraft, useUploadedFileDraftById } from "../../../stores/uploadedFilesStore";
import ChipsGrid from "../../ChipsGrid";
import ShareUploadedDocument from "../../ShareUploadedDocument/ShareUploadedDocument";
import { TitleWithTextField } from "./TitleWithTextField";

type UploadedDocumentSharesProps = {
	documentId: string;
};

const isSharingGroup = (option: any): option is SharingGroup => {
	return (option as SharingGroup).color !== undefined;
};

const UploadedDocumentShares: React.FC<UploadedDocumentSharesProps> = ({ documentId }) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const editUploadedDocumentDraft = useEditUploadedFileDraft();
	const { pushBottomSheet } = useModalNavigator();
	const { mainBottomSheetRef } = useUploadDocumentContext();

	const uploadedDocumentDraft = useUploadedFileDraftById(documentId);
	const shares = [
		...(uploadedDocumentDraft.shares?.groupsToShareWith ?? []),
		...(uploadedDocumentDraft.shares?.usersToShareWith ?? []),
	];

	return (
		<div className="">
			<TitleWithTextField
				title={t("uploadDocument.shareWithContacts")}
				placeholder="mariorossi@certiblok.com"
				onClick={() => {
					pushBottomSheet({
						component: ShareUploadedDocument,
						ref: mainBottomSheetRef.current,
						props: {
							documentId,
						},
					});
				}}
			/>
			{shares.length > 0 && (
				<ChipsGrid
					data={shares}
					renderChip={(elem) => {
						return isSharingGroup(elem) ? (
							<GroupChip
								group={elem}
								onDelete={() => {
									editUploadedDocumentDraft(documentId, {
										shares: {
											...uploadedDocumentDraft.shares!,
											// @ts-ignore
											groupsToShareWith: shares
												.filter((e) => isSharingGroup(e))
												.filter((e) => isSharingGroup(e) && elem.id !== e.id),
										},
									});
								}}
							/>
						) : (
							<div key={isUserOrContact(elem) ? elem.id : elem} className="max-w-full overflow-hidden">
								<UserChip
									deletable
									user={elem}
									onDelete={() => {
										editUploadedDocumentDraft(documentId, {
											shares: {
												...uploadedDocumentDraft.shares!,
												// @ts-ignore
												usersToShareWith: shares
													.filter((e) => !isSharingGroup(e))
													.filter((e) => (isUserOrContact(e) && isUserOrContact(elem) ? e.id !== elem.id : e !== elem)),
											},
										});
									}}
								/>
							</div>
						);
					}}
					renderMoreLabel={(count) => (
						<Badge
							badgeContent={`+${count}`}
							color="secondary"
							overlap="circular"
							sx={{
								pr: 2,
								"& .MuiBadge-badge": {
									right: 4,
									top: 16,
									border: `2px solid ${theme.palette.background.paper}`,
									padding: "0 4px",
								},
							}}
						>
							<Avatar alt={`avatar`} src={avatarPlaceholder} sx={{ width: 32, height: 32 }} />
						</Badge>
					)}
				/>
			)}
		</div>
	);
};
export default UploadedDocumentShares;
