import { FormState } from "react-hook-form";
import { EditCreateEventForm } from "../hooks/useEditCreateEventFormValidator";

function deepIsDirty<T extends Record<string, any>>(value: T): boolean {
	for (const key in value) {
		const val = value[key];
		if (typeof val === "object") {
			if (deepIsDirty(val)) {
				return true;
			}
		} else if (val) {
			return true;
		}
	}
	return false;
}

export function computeDirtyFields(dirtyFields: FormState<EditCreateEventForm>["dirtyFields"]) {
	const actualDirtyFields: (keyof EditCreateEventForm)[] = [];
	for (const field in dirtyFields) {
		const isDirty = dirtyFields[field as keyof EditCreateEventForm];
		if (isDirty === undefined) {
			continue;
		}
		if (typeof isDirty === "boolean") {
			if (isDirty) actualDirtyFields.push(field as keyof EditCreateEventForm);
		} else {
			const isDeepDirty = deepIsDirty(isDirty);
			if (isDeepDirty) {
				actualDirtyFields.push(field as keyof EditCreateEventForm);
			}
		}
	}
	return actualDirtyFields;
}

export function areFieldsDirty(
	dirtyFields: ReturnType<typeof computeDirtyFields>,
	fields: (keyof EditCreateEventForm)[]
) {
	for (const field of fields) {
		if (dirtyFields.includes(field)) {
			return true;
		}
	}
}
