export const AvailableFolderColors: string[] = [
	"rgba(176, 176, 176, 1)",
	"rgba(239, 68, 68, 1)",
	"rgba(249, 115, 22, 1)",
	"rgba(250, 204, 21, 1)",
	"rgba(74, 222, 128, 1)",
	"rgba(45, 212, 191, 1)",
	"rgba(59, 130, 246, 1)",
	"rgba(99, 102, 241, 1)",
	"rgba(236, 72, 153, 1)",
	"rgba(217, 70, 239, 1)",
	"rgba(139, 92, 246, 1)",
	"rgba(14, 165, 233, 1)",
	"rgba(16, 185, 129, 1)",
	"rgba(132, 204, 22, 1)",
];
