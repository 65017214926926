import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { Company, CompanyApi } from "certiblok-api-manager";
import { instanceApi } from "../../../../../utils/hooks/api";

export const getGetCompanyWithMembersKey = () => ["company", "members"];

export function useGetCompanyWithMembers(
	options?: Omit<UseQueryOptions<AxiosResponse<Company>, unknown, Company, string[]>, "queryKey" | "queryFn">
) {
	return useQuery({
		queryKey: getGetCompanyWithMembersKey(),
		queryFn: () => instanceApi(CompanyApi).getCompanyWithMembers(),
		select: (res) => res.data,
		...options,
	});
}
