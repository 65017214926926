import { Box, Typography } from "@mui/material";
import React, { ReactNode, useRef, useState } from "react";
import WhitePopperWithArrow from "./WhitePopperWithArrow";

type WhitePopperTooltipProps = {
	enabled: boolean;
	title?: ReactNode | string;
	forceOpen?: boolean;
};
const WhitePopperTooltip: React.FC<WhitePopperTooltipProps> = ({ children, enabled, title, forceOpen }) => {
	const [open, setOpen] = useState(false);
	const ref = useRef(null);

	if (!enabled) return <>{children}</>;

	return (
		<React.Fragment>
			<div ref={ref} onMouseEnter={() => enabled && setOpen(true)} onMouseLeave={() => enabled && setOpen(false)}>
				{children}
			</div>

			<WhitePopperWithArrow anchorEl={ref.current} open={open || Boolean(forceOpen)} toggleOpen={() => setOpen((i) => !i)}>
				<Box
					sx={{
						boxSizing: "border-box",
						backgroundColor: "surface.main",
						display: "flex",
						flexDirection: "column",
						padding: 3,
						borderRadius: 3,
						maxWidth: 241,
					}}
				>
					{typeof title === "string" ? <Typography variant="caption" children={title} /> : title}
				</Box>
			</WhitePopperWithArrow>
		</React.Fragment>
	);
};
export default WhitePopperTooltip;
