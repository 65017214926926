import { Tooltip } from "@mui/material";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useBetterMediaQuery } from "../../../hooks/useBetterMediaQuery";

type ChipsGridProps<T> = {
	data: T[];
	renderChip: (item: T) => ReactNode;
	renderMoreLabel: (count: number) => ReactNode;
};

function howMuchChips(isMd: boolean, isLg: boolean) {
	return isLg ? 3 : isMd ? 2 : 1;
}

const ChipsGrid = <T extends any>({ data, renderChip, renderMoreLabel }: ChipsGridProps<T>) => {
	const { t } = useTranslation();
	const isLg = useBetterMediaQuery("lg");
	const isMd = useBetterMediaQuery("md");

	const howMuchExcess = data.length - howMuchChips(isMd, isLg);

	return (
		<div className="grid gap-2 mt-2 [grid-template-columns:1fr_auto] md:[grid-template-columns:repeat(2,1fr)_auto] lg:[grid-template-columns:repeat(3,1fr)_auto]">
			{data.map((elem, index) => {
				return index < howMuchChips(isMd, isLg) ? renderChip(elem) : null;
			})}
			{data.length - 1 >= howMuchChips(isMd, isLg) && (
				<Tooltip title={t("uploadDocument.nMore", { count: howMuchExcess })}>
					<div>{renderMoreLabel(howMuchExcess)}</div>
				</Tooltip>
			)}
		</div>
	);
};
export default ChipsGrid;
