import { Box, alpha, Typography, IconButton } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { SharingGroup } from "certiblok-api-manager";
import { useNavigate } from "react-router-dom";
import CertiblokIcon from "../../../../utils/components/icons/CertiblokIcon";
import { useModalNavigator } from "../../../../utils/components/ModalNavigator/ModalNavigator";
import UserWithMail from "../../../../utils/components/UserWithMail";
import { useBetterMediaQuery } from "../../../../utils/hooks/useBetterMediaQuery";
import DeleteContactGroupModal from "../../components/ContactGroupsManagement/DeleteContactGroupModal";
import EditCreateContactGroup from "../../components/EditCreateContactGroup";
import { getSingleSharingGroupQueryKey, getSharingGroupsQueryKey } from "../../hooks/useContactGroupsHooks";

const GroupDetail = ({ group }: { group: SharingGroup }) => {
	const isSm = useBetterMediaQuery("sm");
	const isMd = useBetterMediaQuery("md");
	const { pushModal } = useModalNavigator();

	const queryClient = useQueryClient();
	const navigate = useNavigate();

	return (
		<div className="bg-backgroundSecondary p-6 rounded-3xl w-full box-border">
			<div className="flex md:gap-6 gap-4 items-center w-full">
				<div className="rounded-full bg-white w-fit">
					<Box
						sx={{
							width: { md: "48px", xs: "36px" },
							height: { md: "48px", xs: "36px" },
							borderRadius: "50%",
							backgroundColor: alpha(group.color, 0.24),
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<CertiblokIcon name="profile_outline_01" color={group.color} size={isMd ? 36 : 28} />
					</Box>
				</div>
				<Typography
					children={group.name}
					variant={isSm ? "h5" : "h6"}
					sx={{ fontWeight: 700, flex: 1, fontSize: { xs: "18px" } }}
				/>
				<div className="flex items-center md:gap-3 gap-2 w-fit">
					<IconButton
						size={isSm ? "medium" : "small"}
						color="secondary"
						onClick={() => {
							pushModal(EditCreateContactGroup, {
								initialGroup: group,
								onSuccess: () => {
									queryClient.invalidateQueries(getSingleSharingGroupQueryKey(group.id!));
								},
							});
						}}
					>
						<CertiblokIcon name="edit_outline_01" color="inherit" size={isSm ? 24 : 20} />
					</IconButton>
					<IconButton
						size={isSm ? "medium" : "small"}
						color="error"
						onClick={() => {
							pushModal(DeleteContactGroupModal, {
								groupIds: [group.id!],
								onSuccess: () => {
									navigate(-1);
									queryClient.invalidateQueries(getSharingGroupsQueryKey());
								},
							});
						}}
					>
						<CertiblokIcon name="delete_outline" color="inherit" size={isSm ? 24 : 20} />
					</IconButton>
				</div>
			</div>
			<div className="mt-6 flex flex-col gap-2 max-h-[160px] overflow-y-auto">
				{group.users?.map((user) => (
					<UserWithMail
						user={user}
						key={user.id}
						deletable={false}
						onDelete={() => {}}
						sx={{ backgroundColor: "white", gap: 3, "& .MuiBox-root": { width: "100%" } }}
					/>
				))}
			</div>
		</div>
	);
};

export default GroupDetail;
