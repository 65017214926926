import { Button, Divider, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { AuditApi } from "certiblok-api-manager";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { getAuditRoomStatus } from "../../../../functions/generalAuditRoomFunctions";
import { useApi } from "../../../../hooks/api";
import AvatarWithName from "../../../AvatarWithName";
import { ModalNavigator, useModalNavigator, useModalRoute } from "../../../ModalNavigator/ModalNavigator";
import AuditRoomStatus from "../../../auditRooms/AuditRoomStatus";
import { switchTitle } from "../../../auditRooms/functions/switchTitle";
import ResponsiveDialog from "../../../dialogs/ResponsiveDialog";
import CertiblokIcon from "../../../icons/CertiblokIcon";
import EventTypeChip from "./components/EventTypeChip";

type AuditRoomDetailsModalProps = {
	eventId: string;
	onFullDetailClick?: () => void;
	onSuccess?: () => void;
};

export const getAuditRoomDetailQueryKey = (id: string) => ["auditRoomDetail", id];

export const AuditRoomDetailsModal = ModalNavigator.create<AuditRoomDetailsModalProps>(
	({ eventId, onFullDetailClick, onSuccess }) => {
		const { visible } = useModalRoute();
		const { popModal } = useModalNavigator();
		const { t } = useTranslation();

		const auditApi = useApi(AuditApi);

		const onClose = () => {
			popModal();
			onSuccess?.();
		};

		const { data: audit, isLoading } = useQuery({
			queryKey: getAuditRoomDetailQueryKey(eventId),
			queryFn: () => {
				return auditApi.singleAuditRoom(eventId);
			},
			select: (data) => data.data,
		});

		return (
			<ResponsiveDialog
				open={visible}
				onClose={onClose}
				header={
					<div className="flex w-full items-center justify-between">
						{isLoading ? (
							<div className="bg-gray-200 animate-pulse rounded-md min-w-[120px] h-6" />
						) : (
							<Typography variant="subtitle1">{audit?.name}</Typography>
						)}
						<EventTypeChip eventType="audit_room" />
					</div>
				}
				ModalProps={{
					responsive: true,
				}}
				footer={
					<>
						<Divider className="mb-6 md:mt-3" />

						<div className="flex justify-between items-center">
							<Button
								variant="outlined"
								color="secondary"
								startIcon={<CertiblokIcon name="close" color="secondary" />}
								onClick={() => onClose()}
							>
								{t("global.close")}
							</Button>
							<Button
								variant="contained"
								color="secondary"
								startIcon={<CertiblokIcon name="arrow_direction_outline_right" color="white" />}
								href={
									audit?.endDate && DateTime.fromISO(audit?.endDate) < DateTime.now().startOf("day")
										? `/audit_rooms/archive/${eventId}`
										: `/audit_rooms/${eventId}`
								}
								disabled={isLoading}
								onClick={() => {
									popModal();
									onFullDetailClick?.();
								}}
							>
								{t("event.fullDetail")}
							</Button>
						</div>
					</>
				}
			>
				<div className="px-6 md:px-0 pb-0 box-border md:max-h-[60vh] md:overflow-auto">
					<div className="md:mt-8 mt-2 grid grid-cols-[1fr,1fr] gap-x-2 gap-y-7 md:p-3 ">
						<div>
							<Typography variant="subtitle1" className="mb-2">
								{t("documents.owner")}
							</Typography>
							{isLoading ? (
								<div className="bg-gray-200 animate-pulse rounded-md min-w-[150px] h-5" />
							) : (
								<AvatarWithName user={audit?.owner} variant="body2" />
							)}
						</div>
						<div>
							<Typography variant="subtitle1" className="mb-1">
								{t("documents.result")}
							</Typography>
							<div className="flex gap-2 items-center">
								<AuditRoomStatus reviews={audit ? [getAuditRoomStatus(audit)] : ["revision"]} onlyStatus />
								{isLoading ? (
									<div className="bg-gray-200 animate-pulse rounded-md min-w-[75px] h-5" />
								) : (
									<Typography variant="caption">{audit && switchTitle(t, getAuditRoomStatus(audit))}</Typography>
								)}
							</div>
						</div>
						<div>
							<Typography variant="subtitle1" className="mb-2">
								{t("events.startingDate")}
							</Typography>
							{isLoading ? (
								<div className="bg-gray-200 animate-pulse rounded-md min-w-[100px] h-5" />
							) : (
								<Typography variant="body2" className={isLoading ? "bg-gray-200 animate-pulse rounded-md min-w-[100px]" : ""}>
									{audit?.startDate ? DateTime.fromISO(audit?.startDate).toFormat("dd/MM/yyyy") : "-"}
								</Typography>
							)}
						</div>
						<div>
							<Typography variant="subtitle1" className="mb-2">
								{t("events.endDate")}
							</Typography>
							{isLoading ? (
								<div className="bg-gray-200 animate-pulse rounded-md min-w-[100px] h-5" />
							) : (
								<Typography variant="body2">
									{audit?.endDate ? DateTime.fromISO(audit?.endDate).toFormat("dd/MM/yyyy") : "-"}
								</Typography>
							)}
						</div>
						{audit?.auditor && (
							<>
								<div>
									<Typography variant="subtitle1" className="mb-2">
										{t("auditRooms.auditor")}
									</Typography>
									<AvatarWithName user={audit?.auditor} variant="body2" />
								</div>
								<div>
									<Typography variant="subtitle1" className="mb-2">
										{t("auditRoom.ispectionBody")}
									</Typography>

									<Typography variant="body2">{audit?.auditor?.company?.name}</Typography>
								</div>
							</>
						)}
					</div>
				</div>
			</ResponsiveDialog>
		);
	}
);
