import { Button, alpha, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { DocumentApi } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import CertiblokIcon from "../../../../../utils/components/icons/CertiblokIcon";
import MLDialog from "../../../../../utils/components/poppers";
import { instanceApi } from "../../../../../utils/hooks/api";

export function useDwgPreview({
	documentId,
	fileId,
	enabled,
}: {
	documentId?: string;
	fileId?: string;
	enabled: boolean;
}) {
	const theme = useTheme();
	const { t } = useTranslation();

	return useQuery({
		queryKey: ["dwgPreview", documentId, fileId],
		queryFn: () => {
			return instanceApi(DocumentApi).getPreviewForFile(documentId ?? "", fileId);
		},
		select: (res) => res.data.url,
		enabled: enabled && Boolean(documentId) && Boolean(fileId),
		retry: false,

		onError: (error) => {
			MLDialog.showModal(t("global.reachedLimit"), t("plans.dwgPreviewLimitReachedWarning"), {
				positiveButton: () => (
					<Button
						color="secondary"
						variant="contained"
						children={t("global.showPlans")}
						onClick={() => {
							MLDialog.hideModal();
						}}
						size="large"
						href="/profile"
					/>
				),
				negativeButton: (
					<Button
						children={t("global.close")}
						onClick={() => {
							MLDialog.hideModal();
						}}
						color="secondary"
						size="large"
					/>
				),
				icon: (
					<CertiblokIcon
						size={48}
						color="orange"
						name={"comunication_outline_01"}
						iconCss={{ padding: 12, backgroundColor: alpha(theme.palette.orange.main, 0.12), borderRadius: 36 }}
					/>
				),
				contentCentered: true,
				maxWidth: 344,
			});
		},
	});
}
