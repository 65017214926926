/* eslint-disable react-hooks/exhaustive-deps */
//@ts-nocheck
import axios, { AxiosResponse } from "axios";
import React, { useRef } from "react";
import useSWR from "swr";

const BACKEND_API_BASENAME = process.env.REACT_APP_BACKEND_BASE_URL;

export const ApiContext = React.createContext<{
	apiKey?: string;
}>({
	apiKey: undefined,
});

// declare type mutateCallback<Data = any> = (currentValue: Data) => Promise<Data> | Data;

function getBuilderFunctionName(call: Function, isNativeCall: boolean) {
	const str = call.toString();

	if (isNativeCall) {
		return ((call as any)._name || call.name.replace("bound ", "")).trim();
	}

	// ƒ (e,t,r){var a=this;return n.AdminDiscountsApiFp(this.configuration).adminDiscountGet(e,t,r).then((function(e){return e(a.axios,a.basePath)}))} undefinedƒ (e,t,r){var a=this;return n.AdminDiscountsApiFp(this.configuration).adminDiscountGet(e,t,r).then((function(e){return e(a.axios,a.basePath)}))}
	// ƒ (){return t.adminDiscountGet("an",!0)}

	const name = str.substr(str.indexOf("()") + 1).split("(")[0];

	return (
		name
			.replace("return", "")
			// .replace("api.", "")
			.replace(/[^A-Za-z0-9]/g, "")
			.trim()
	);
}

export interface FetchOptions {
	skip?: boolean;
}

function getFetchOptionsFromParams(params: any[]) {
	if (params && params.length) {
		const opt = params[params.length - 1];
		if (opt["skip"] !== undefined) return opt;
	}
	return undefined;
}

export function useFetch<T>(
	call: ((...callParams: any) => Promise<AxiosResponse<T>>) | (() => Promise<AxiosResponse<T>>),
	// ...params:any[]
	...params: (string | boolean | number | object | FetchOptions)[]
) {
	// const isNativeCall = call?.toString() === "function () { [native code] }";
	const isNativeCall = call?.toString()?.includes("[native code]");
	// const callToString = call?.toString();
	// const isNativeCall = callToString?.includes("[native code]") && callToString?.length < 100;

	// console.log(isNativeCall, "😀" + call.name + "😎" + (call as any)._name + "😀" + key + "😀");

	const options = getFetchOptionsFromParams(params);
	if (options) params = params.slice(0, params.length - 1);

	let key = (call as any)?._name || getBuilderFunctionName(call, isNativeCall);

	// if (options?.networkOnly === true) key = key + "_" + uuidv4();

	key = key + JSON.stringify({ params });

	const _data = useRef<AxiosResponse<T>>(undefined);

	const result = useSWR(
		options?.skip ? null : key,
		// isNativeCall ? () => call(...params) : () => call(),
		isNativeCall && params?.length ? () => call(...params) : () => call(),
		{
			// revalidateOnMount: false,
			revalidateOnFocus: false,
			// revalidateOnReconnect: true,
		}
	);

	const mutate = (_data: (data: T) => T, shouldRevalidate?: boolean) =>
		result.mutate((res) => ({ ...res, data: _data(res.data) }), shouldRevalidate);

	const mutateOptimistic = (
		newValue: any,
		options?: {
			putFirst?: boolean;
			isDataArray?: boolean;
			shouldRevalidate?: boolean;
		}
	) => {
		mutate((data) => {
			try {
				// if (Array.isArray(data) || options?.isDataArray) {
				if (Array.isArray(data)) {
					//? IS DATA ARRAY
					let found = false;
					let newArray = data.map((item: any) => {
						if (Array.isArray(newValue)) {
							for (const newValueItem of newValue) {
								if (newValueItem.id === item.id) {
									return newValueItem;
								}
							}
						} else if (item?.id === newValue?.id) {
							//? found item
							found = true;
							return newValue;
						}
						return item;
					});
					//? not found update - should put inside array
					if (!found) {
						newArray = options?.putFirst ? [newValue, ...newArray] : [...newArray, newValue];
					}
					return newArray;
				} else {
					//? DATA IS SINGLE ITEM
					if (newValue?.id === (data as any)?.id) {
						return newValue;
					}
					return data || newValue || {};
				}
			} catch (e) {}
			return data || newValue || {};
		}, options?.shouldRevalidate);
	};

	_data.current = result?.data || _data.current;

	const data = (_data?.current?.data as any)?.error ? undefined : _data?.current?.data;

	const error = (_data?.current?.data as any)?.error || result.error;

	if (error) {
		// console.log(8885, error?.response?.data?.status);
		// if (error?.message === "Request failed with status code 401") {
		if (error?.response?.data?.status === 401) {
			localStorage.clear();
			window.location.href = "/";
		}
		console.log(`%c api err > ${key}`, "color: #ff99aa");
	}

	const loading = !result?.data && !error;
	// const loading = !data && !result?.data && !error;

	return {
		...result,
		mutateResult: result.mutate,
		// mutate: (data: T) => result.mutate((res) => ({ ...res, data })),
		mutate,
		mutateOptimistic,
		rawData: _data.current,
		// export declare type mutateInterface<Data = any> = (key: keyInterface, data?: Data | Promise<Data> | mutateCallback<Data>, shouldRevalidate?: boolean) => Promise<Data | undefined>;
		// loading: !_data.current && !result.error,
		loading,
		// data: result?.data?.data,
		headers: _data.current?.headers,
		data,
		error,
	};
}

export function useApi<T>(xyz: T, options?: { basePath?: string; token?: string }) {
	axios.defaults.withCredentials = false;

	const abc = useRef(
		(() => {
			//@ts-ignore
			const instance: InstanceType<T> = new xyz({
				basePath: options?.basePath || `${BACKEND_API_BASENAME}/api/v1`,
				baseOptions: { withCredentials: true },
			});

			Object.getOwnPropertyNames(Object.getPrototypeOf(instance))
				.filter((name) => Boolean(instance[name] instanceof Function))
				.forEach((mtd) => {
					instance[mtd] = instance[mtd].bind(instance);
					instance[mtd]._name = mtd;
				});

			return instance;
		})()
	);

	return abc.current;
}

export function instanceApi<T>(xyz: T, options?: { basePath?: string; token?: string }) {
	axios.defaults.withCredentials = false;

	const instance: InstanceType<T> = new xyz({
		basePath: options?.basePath || `${BACKEND_API_BASENAME}/api/v1`,
		baseOptions: { withCredentials: true },
	});

	Object.getOwnPropertyNames(Object.getPrototypeOf(instance))
		.filter((name) => Boolean(instance[name] instanceof Function))
		.forEach((mtd) => {
			instance[mtd] = instance[mtd].bind(instance);
			instance[mtd]._name = mtd;
		});

	Object.getOwnPropertyNames(Object.getPrototypeOf(instance))
		.filter((name) => Boolean(instance[name] instanceof Function))
		.forEach((mtd) => {
			instance[mtd] = instance[mtd].bind(instance);
			instance[mtd]._name = mtd;
		});

	return instance;
}

export const apiErrorParser = (axiosError: AxiosError) => {
	const resp = axiosError.response?.data;

	return resp?.errors ? resp?.errors?.[0]?.param || "genericError" : resp?.message || "genericError";
};
