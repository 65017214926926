import { sliceArrayIntoChunks } from "../hooks/useChunkingRequests";
import type { ChunkError } from "../interfaces/ChunkErrors";

export async function chunkRequests<T, X extends any[], Z>(
	chunkSize: number,
	request: (item: T, ...args: X) => Promise<Z>,
	dataToChunk: T[],
	...args: X
) {
	const chunkedData = sliceArrayIntoChunks(dataToChunk, chunkSize);
	const chunkedErrors: ChunkError<T>[] = [];
	const chunkedSuccessfully: Z[] = [];
	for (let data of chunkedData) {
		await Promise.allSettled(
			data.map((item) =>
				request(item, ...args)
					.then((res) => {
						chunkedSuccessfully.push(res);
					})
					.catch((err) => {
						chunkedErrors.push({ data: item, error: err });
					})
			)
		);
	}
	return { chunkedSuccessfully, chunkedErrors };
}
