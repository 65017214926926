import { cn } from "@mabi-ui/utils";
import { Button, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useBetterMediaQuery } from "../hooks/useBetterMediaQuery";
import { useMe } from "../hooks/useMe";
import { useLastShowedNoStorageModal } from "../hooks/useShouldShowFirstTimeModal";
import CertiblokIcon from "./icons/CertiblokIcon";
import { ModalNavigator, useModalNavigator, useModalRoute } from "./ModalNavigator/ModalNavigator";
import { usePlanUsage } from "./scaffold/components/Drawer/components/SidebarUsage/hooks/usePlanUsage";
import WhiteModal from "./WhiteModal";

const NoStorageModalPusher = () => {
	const { pushModal } = useModalNavigator();
	const { lastShowedNoStorageModal, setLastShowedNoStorageModal } = useLastShowedNoStorageModal();
	const { planUsage } = usePlanUsage();
	const usedPercentage = ((planUsage?.usedStorage ?? 0) / (planUsage?.totalStorage ?? 0)) * 100;
	const isModalOpenRef = useRef(false);

	useEffect(() => {
		const today = DateTime.now();
		if (
			!isModalOpenRef.current &&
			usedPercentage >= 90 &&
			(!lastShowedNoStorageModal || !DateTime.fromISO(lastShowedNoStorageModal).hasSame(today, "day"))
		) {
			isModalOpenRef.current = true;
			pushModal(NoStorageModal, { isStorageOver: usedPercentage >= 100 }).then(() => {
				isModalOpenRef.current = false;
			});
			setLastShowedNoStorageModal(today.toISO() ?? new Date().toISOString());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [usedPercentage, lastShowedNoStorageModal]);

	return null;
};

const NoStorageModal = ModalNavigator.create(
	({ isStorageOver, onSuccess }: { isStorageOver: boolean; onSuccess?: () => void }) => {
		const { t } = useTranslation();

		const { visible } = useModalRoute();
		const { popModal } = useModalNavigator();

		const isMd = useBetterMediaQuery("md");

		const { me } = useMe();
		const isAdmin = me?.id === me?.company?.ownerId;

		const onClose = () => {
			popModal();
			onSuccess?.();
		};

		return (
			<WhiteModal
				open={visible}
				onClose={() => onClose()}
				responsive
				containerSx={{
					width: {
						xs: "90vw",
						sm: 550,
						lg: 550,
					},
				}}
			>
				<div>
					<div className="mb-6 md:w-16 w-12 md:h-16 h-12 w flex items-center justify-center rounded-full bg-alert/[0.12]">
						<CertiblokIcon name="comunication_bold_01" color="#FFD21F" size={isMd ? 40 : 32} />
					</div>
					<div className="mb-6">
						<h6 className="text-h6 font-bold md:text-h4 md:font-normal">
							{isStorageOver ? t("cloudImport.storageExhausted") : t("cloudImport.storageAlmostExhausted")}
						</h6>
						<Typography className="mt-2 text-black/[0.6]">
							{isStorageOver
								? t("cloudImport.storageExhaustedDescription")
								: t("cloudImport.storageAlmostExhaustedDescription")}
						</Typography>
					</div>
					<div className={cn("flex items-center gap-4", isAdmin ? "justify-between" : "justify-end")}>
						<Button
							variant="outlined"
							color="secondary"
							startIcon={<CertiblokIcon name="close" color="secondary" />}
							sx={{ typography: isMd ? "button" : undefined }}
							size={isMd ? "large" : "medium"}
							onClick={() => onClose()}
						>
							{t("global.close")}
						</Button>
						{isAdmin && (
							<Button
								variant="contained"
								color="secondary"
								href="/profile/plans"
								sx={{ typography: isMd ? "button" : undefined }}
								size={isMd ? "large" : "medium"}
								onClick={() => onClose()}
							>
								{t("cloudImport.goToPlanManagement")}
							</Button>
						)}
					</div>
				</div>
			</WhiteModal>
		);
	}
);

export default NoStorageModalPusher;
