import { Button, Collapse, Typography } from "@mui/material";
import { SharingGroup, UserOrContact } from "certiblok-api-manager";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TransitionGroup } from "react-transition-group";
import GroupContactUserAutocomplete from "../../../../../pages/Contacts/components/EditCreateContactGroup/GroupContactUserAutocomplete";
import { isUser } from "../../../../../pages/Folders/components/UploadDocument/functions/isUser";
import { useBetterMediaQuery } from "../../../../hooks/useBetterMediaQuery";
import BottomSheet from "../../../bottomSheet/BottomSheet";
import LoadingIconButton from "../../../buttons/LoadingIconButton";
import CertiblokTextInput from "../../../CertiblokTextInput";
import DocumentWithIcon from "../../../DocumentWithIcon";
import GroupWithMembers from "../../../GroupWithMembers";
import CertiblokIcon from "../../../icons/CertiblokIcon";
import type { SupportedLanguagesType } from "../../../LanguageSelector";
import LanguageSelector from "../../../LanguageSelector";
import { ModalNavigator, useBottomSheet, useModalNavigator } from "../../../ModalNavigator/ModalNavigator";
import NoContents from "../../../NoContents";
import UserWithMail from "../../../UserWithMail";
import { useEditUploadedFileDraft, useUploadedFileById } from "../../stores/uploadedFilesStore";

type ShareUploadedDocumentProps = {
	documentId: string;
};
const ShareUploadedDocument = ModalNavigator.create<ShareUploadedDocumentProps>(({ documentId }) => {
	const { t, i18n } = useTranslation();
	const isMd = useBetterMediaQuery("md");
	const { visible, bottomSheetProps } = useBottomSheet();
	const { popBottomSheet } = useModalNavigator();

	const uploadedFile = useUploadedFileById(documentId);
	const document = uploadedFile.document;
	const documentDraft = uploadedFile.draft;
	const editDocumentDraft = useEditUploadedFileDraft();

	const [selectedContactToShare, setSelectedContactToShare] = useState<(UserOrContact | string)[]>(
		documentDraft.shares?.usersToShareWith || []
	);
	const [selectedGroupsToShare, setSelectedGroupsToShare] = useState<SharingGroup[]>(
		documentDraft.shares?.groupsToShareWith || []
	);
	const [sharingNote, setSharingNote] = useState(documentDraft.shares?.note ?? "");
	const [selectedLanguage, setSelectedLanguage] = useState<SupportedLanguagesType>(
		i18n.language.startsWith("it") ? "it" : "en"
	);

	useEffect(() => {
		setSelectedContactToShare([...(documentDraft.shares?.usersToShareWith || [])]);
		setSelectedGroupsToShare([...(documentDraft.shares?.groupsToShareWith || [])]);
		setSharingNote(documentDraft.shares?.note ?? "");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [documentDraft.shares]);

	const onClose = () => {
		setSharingNote(documentDraft.shares?.note ?? "");
		setSelectedContactToShare([...(documentDraft.shares?.usersToShareWith || [])]);
		setSelectedGroupsToShare([...(documentDraft.shares?.groupsToShareWith || [])]);
		popBottomSheet();
	};

	return (
		<BottomSheet
			open={visible}
			{...bottomSheetProps}
			onClose={onClose}
			header={
				<Typography
					children={t("shareWithContact.shareDocument")}
					sx={{
						typography: {
							xs: "subtitle2",
							md: "subtitle1",
						},
					}}
				/>
			}
			footer={
				<div className="flex items-center justify-between gap-6">
					<Button
						onClick={onClose}
						children={t("global.cancel")}
						variant="outlined"
						color="secondary"
						startIcon={<CertiblokIcon name="close" color="inherit" size={24} />}
					/>
					<LoadingIconButton
						children={isMd ? t("shareWithContact.share") : t("shareWithContact.shareCompact")}
						color="secondary"
						variant="contained"
						onClick={() => {
							editDocumentDraft(documentId, {
								shares: {
									language: selectedLanguage,
									usersToShareWith: selectedContactToShare,
									note: sharingNote,
									groupsToShareWith: selectedGroupsToShare,
								},
							});
							popBottomSheet();
						}}
						className="flex-grow md:flex-grow-0"
						icon={<CertiblokIcon size={24} color="inherit" name={"success"} />}
					/>
				</div>
			}
		>
			<div className="px-5 md:px-12 min-h-[40vh] md:max-h-[60vh] flex flex-col">
				{document && <DocumentWithIcon name={document.name} document={document.lastVersionFile?.[0]} />}
				<div className="flex flex-col pt-5">
					<CertiblokTextInput
						label={t("documents.insertNote")}
						placeholder={t("documents.insertNote")}
						color="secondary"
						fullWidth
						containerCss={{ paddingTop: 12 }}
						value={sharingNote}
						multiline
						onChange={(ev) => setSharingNote(ev.target.value)}
					/>
				</div>
				<div className="flex flex-row justify-between items-center pt-5">
					<Typography variant="label">{t("auditRoom.selectAuditorLanguage")}</Typography>
					<LanguageSelector language={selectedLanguage} onLanguageChange={setSelectedLanguage} />
				</div>
				<GroupContactUserAutocomplete
					TextFieldProps={{
						inputProps: {
							placeholder: t("shareWithContact.selectShare"),
						},
					}}
					includeGroups
					includeEmails
					onSelectEmail={(value) => setSelectedContactToShare((prev) => [...prev, value])}
					onSelectUser={(value) => setSelectedContactToShare((prev) => [...prev, value])}
					onSelectGroup={(value) => setSelectedGroupsToShare((prev) => [...prev, value])}
					disabledSharingGroupsIds={selectedGroupsToShare.map((group) => group.id)}
					disabledUsersOrContactsIds={selectedContactToShare.map((contact) => (isUser(contact) ? contact.id : ""))}
					disabledEmails={selectedContactToShare.map((contact) => (typeof contact === "string" ? contact : ""))}
				/>
				<div className="flex-1 flex mt-4 md:mt-10 flex-col max-h-[40vh] overflow-auto md:max-h-[unset] md:overflow-hidden">
					{selectedContactToShare.length + selectedGroupsToShare.length > 0 ? (
						<TransitionGroup
							component={"div"}
							className="flex-1 flex flex-col md:flex-wrap max-w-full md:overflow-auto md:h-[326px] md:-ml-2 horizontal-scroll"
						>
							{selectedGroupsToShare.map((group, index) => {
								return (
									<Collapse className="w-full md:w-[50%] md:px-2 box-border" key={group.id}>
										<div className="mb-1">
											<GroupWithMembers
												group={group}
												deletable
												onDelete={() => {
													setSelectedGroupsToShare((prev) => {
														return prev.filter((elem) => elem.id !== group.id);
													});
												}}
											/>
										</div>
									</Collapse>
								);
							})}
							{selectedContactToShare.map((contact, index) => {
								return (
									<Collapse
										className="w-full md:w-[50%] md:px-2 box-border"
										key={isUser(contact) ? contact.id : `invite_${contact}`}
									>
										<div className="mb-1">
											<UserWithMail
												user={contact}
												deletable
												onDelete={(user) => {
													setSelectedContactToShare((prev) => {
														let index;
														if (typeof user === "string") {
															index = prev.findIndex((elem) => {
																if (typeof elem === "string") return elem === user;
																return false;
															});
														} else {
															index = prev.findIndex((elem) => {
																if (typeof elem !== "string") return elem.id === user.id;
																return false;
															});
														}
														if (index >= 0) {
															prev.splice(index, 1);
														}
														return [...prev];
													});
												}}
												sx={{ gap: 3 }}
											/>
										</div>
									</Collapse>
								);
							})}
						</TransitionGroup>
					) : (
						<div className="flex items-center justify-center flex-1">
							<NoContents
								variant="paper"
								title={t("documents.selectContact")}
								sx={{ width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}
							/>
						</div>
					)}
				</div>
			</div>
		</BottomSheet>
	);
});
export default ShareUploadedDocument;
