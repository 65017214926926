import { useQuery } from "@tanstack/react-query";
import { FolderApi } from "certiblok-api-manager";
import { useApi } from "../../../utils/hooks/api";

export const totalFoldersCountQueryKey = ["folders", "count"];

export const useGetTotalFoldersCount = () => {
	const documentRequests = useApi(FolderApi);

	return useQuery({
		queryKey: totalFoldersCountQueryKey,
		queryFn: () => documentRequests.getTotalFoldersCount(),
		select: (res) => res?.data ?? 0,
	});
};
