import { CompanyApi, ParticipationCompany, Notification, EventInstance } from "certiblok-api-manager";
import { useApi } from "../../../../../hooks/api";
import { useTranslation } from "react-i18next";

import { Button, SxProps, Theme, Typography, useTheme } from "@mui/material";
import { useNotificationsContext } from "../controllers/NotificationContext";
import { MouseEvent, useCallback } from "react";
import { showMembersLimitDialog } from "../../../../../../pages/Profile/pages/Team/functions/showMembersLimitDialog";
import { chunkRequests } from "../../../../../functions/chunkRequests";
import MLDialog from "../../../../poppers";
import { useNavigate } from "react-router-dom";
import LoadingTextButton from "../../../../LoadingTextButton";
import LinkBehavior from "../../../../LinkBehavior";
import { useModalNavigator } from "../../../../ModalNavigator/ModalNavigator";
import { EventDetailsModal } from "../../../../globalCalendar/components/EventsDetailsModals/EventDetailsModal";
import DeletedEventModal from "../modals/DeletedEventModal";
import { usePlanUsage } from "../../Drawer/components/SidebarUsage/hooks/usePlanUsage";

const NotificationButton = ({
	text,
	href,
	sx,
	onClick,
}: {
	text: string;
	href?: string;
	sx?: SxProps<Theme>;
	onClick?: (ev: MouseEvent<HTMLButtonElement>) => void;
}) => (
	<Button
		component={href ? LinkBehavior : "button"}
		href={href}
		variant="outlined"
		size="small"
		onClick={onClick}
		fullWidth
	>
		<Typography noWrap children={text} variant="caption" />
	</Button>
);

export const NotificationActions: React.FC<{ notification: Notification; markAsRead: () => void }> = ({
	notification,
	markAsRead,
}) => {
	const companyApi = useApi(CompanyApi);
	const { t } = useTranslation();
	const { planUsage } = usePlanUsage();
	const theme = useTheme();

	const { pushModal } = useModalNavigator();

	const notificationsContext = useNotificationsContext();

	const acceptTeamRequests = useCallback(() => {
		const availableParticipants = (planUsage?.totalUsers ?? 0) - (planUsage?.usedUsers ?? 0);

		if (((notification.content.participations as ParticipationCompany[]).length ?? 0) > availableParticipants) {
			notificationsContext?.closePopper();
			showMembersLimitDialog(theme, () => {}, t, true);
			return;
		}

		return chunkRequests(
			10,
			(request) => {
				return companyApi.approveOrRejectParticipation(request.id, { approve: true });
			},
			notification.content.participations as ParticipationCompany[]
		).then((res) => {
			if (res.chunkedErrors.length > 0) {
				MLDialog.showSnackbar(t("notifications.notApprovedRequests", { count: res.chunkedErrors.length }), {
					variant: "error",
				});
			}
			if (res.chunkedSuccessfully.length > 0) {
				MLDialog.showSnackbar(t("notifications.approvedRequests", { count: res.chunkedSuccessfully.length }), {
					variant: "success",
				});
			}
			markAsRead();
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		companyApi,
		markAsRead,
		notification.content.participations,
		planUsage?.totalUsers,
		planUsage?.usedUsers,
		t,
		theme,
	]);

	const navigate = useNavigate();
	let text = "";
	switch (notification.type) {
		case "team_request_retired":
			return null;
		case "event_accepted":
		case "event_invitation":
		case "event_rejected":
		case "event_reminder":
		case "event_updated":
			return (
				<NotificationButton
					text={t("global.show")}
					onClick={() => {
						pushModal(EventDetailsModal, { eventId: notification.content?.eventInstanceId as unknown as string });
						markAsRead();
					}}
				/>
			);
		case "event_deleted":
			return (
				<NotificationButton
					text={t("notifications.seeNotes")}
					onClick={() => {
						pushModal(DeletedEventModal, {
							event: notification?.content?.eventInstance as EventInstance,
							notes: notification?.content?.annotation as unknown as string,
							allOccurrences: notification?.content?.allOccurrences as unknown as boolean,
						});
						markAsRead();
					}}
				/>
			);
		case "upload_document_request":
		case "new_shared_document":
		case "new_shared_note_document":
		case "updated_document":
		case "closed_audit_room":
		case "as_auditor":
		case "expiring_audit_room":
		case "new_documents_in_audit":
		case "document_modified":
		case "edit_room_document_access_request":
		case "document_chat_message":
			text = t("global.show");
			break;
		case "storage":
		case "document_expiration":
			text = t("global.handle");
			break;
		case "update_document_request":
			return (
				<NotificationButton
					text={t("global.update")}
					onClick={(ev) => {
						ev.stopPropagation();
						ev.preventDefault();
						markAsRead();
						if (notification?.href) navigate(notification.href);
					}}
				/>
			);
		case "member_invitation_accepted":
		case "admin_invitation_accepted":
			return (
				<NotificationButton
					text={t("global.show")}
					onClick={(ev) => {
						ev.stopPropagation();
						ev.preventDefault();
						markAsRead();
						navigate("/profile/team");
					}}
				/>
			);
		case "admin_invitation":
			return (
				<NotificationButton
					text={t("global.show")}
					onClick={(ev) => {
						ev.stopPropagation();
						ev.preventDefault();
						markAsRead();
						navigate(`/invitation?token=${notification.content.invitationToken}`);
					}}
				/>
			);
		case "new_team_request":
			return (
				<>
					<LoadingTextButton
						variant="contained"
						size="small"
						onClick={async (ev) => {
							ev.preventDefault();
							ev.stopPropagation();
							await acceptTeamRequests();
						}}
						fullWidth
					>
						<Typography noWrap children={t("global.accept")} variant="caption" />
					</LoadingTextButton>
					<NotificationButton sx={{ marginTop: 1 }} onClick={markAsRead} text={t("global.show")} href={notification.href} />
				</>
			);
		default:
			return null;
	}
	return <NotificationButton onClick={markAsRead} text={text} href={notification.href} />;
};
