/** @jsxImportSource @emotion/react */
import { Box, Button, Fade, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { backendMediaUrlParser } from "../../../../utils";
import Loading from "../../../../utils/components/Loading";
import CertiblokIcon from "../../../../utils/components/icons/CertiblokIcon";
import { DocumentContext } from "../../controllers/DocumentContext";
import DxfViewer from "./components/DxfViewer";
import { getPreviewType } from "./functions/getPreviewType";
import { StlViewer } from "react-stl-viewer";
import DxfPreviewBackground from "./components/DxfViewer/components/DxfPreviewBackground";
import { useDwgPreview } from "./hooks/useDwgPreview";
import { CBFile } from "certiblok-api-manager";
import { CertiblokDocument } from "../../../../utils/interfaces/Document";

const NoDocumentPreview = () => {
	const { t } = useTranslation();
	const documentContext = useContext(DocumentContext);
	const { currentFile } = documentContext;

	return (
		<Box
			sx={{
				maxWidth: 344,
				border: "1px solid rgba(0, 0, 0, 0.12)",
				borderRadius: 6,
				padding: 6,
				boxSizing: "border-box",
			}}
		>
			<Typography children={t("preview.downloadDocument")} variant="subtitle1" sx={{ padding: 2, textAlign: "center" }} />
			<Typography
				children={t("preview.unsupportedDocument")}
				variant="body2"
				sx={{ opacity: 0.74, textAlign: "center", paddingBottom: 6 }}
			/>
			<Button
				href={backendMediaUrlParser(currentFile?.url, {
					download: true,
					filename: documentContext.document?.name ?? currentFile?.filename,
				})}
				component="a"
				target="_blank"
				rel="noopener noreferrer"
				color="secondary"
				variant="contained"
				fullWidth
			>
				<CertiblokIcon size={24} color="inherit" name={"load_outline_02"} />
				{t("documents.downloadFiles")}
			</Button>
		</Box>
	);
};

const DocumentPreview = (props: {
	document?: CertiblokDocument;
	currentFile?: CBFile;
	className?: string;
	downloadable?: boolean;
}) => {
	const { document, currentFile } = props;

	const { t } = useTranslation();
	// const documentContext = useContext(DocumentContext);
	// const { currentFile } = documentContext;
	const documentMimeType = currentFile?.type ?? t("preview.unknownType");
	const [imageError, setImageError] = useState(false);

	const previewType = getPreviewType(documentMimeType);

	const {
		data: dwgPreviewUrl,
		isLoading: isLoadingDwgPreview,
		fetchStatus: dwgPreviewFetchStatus,
		error: dwgPreviewError,
	} = useDwgPreview({
		documentId: document?.id,
		fileId: currentFile?.id,
		enabled: documentMimeType.includes("dwg"),
	});

	const dxfPreviewUrl = documentMimeType.includes("dwg")
		? backendMediaUrlParser(dwgPreviewUrl)
		: backendMediaUrlParser(currentFile?.url);

	const hasPreviewAvailableOnPlan = !Boolean(dwgPreviewError);
	const isNoPreview = !((previewType === "image" && !imageError) || (previewType !== "image" && previewType !== null));

	const theme = useTheme();
	const isLg = useMediaQuery(theme.breakpoints.up("lg"));

	const [loadingPreview, setLoadingPreview] = useState(isNoPreview ? false : true);

	const lastFetchedVersion = useRef<number | undefined>();

	const parentBoxRef = useRef<HTMLDivElement>(null);

	const isLoadingPreview =
		loadingPreview || (isLoadingDwgPreview && dwgPreviewFetchStatus !== "idle" && !hasPreviewAvailableOnPlan);

	useEffect(() => {
		const alreadyFetched = lastFetchedVersion.current === currentFile?.version;
		setImageError(false);
		if (!alreadyFetched) {
			if (isNoPreview) {
				setLoadingPreview(false);
			} else {
				setLoadingPreview(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentFile]);

	useEffect(() => {
		if (!hasPreviewAvailableOnPlan) {
			setLoadingPreview(false);
		}
	}, [hasPreviewAvailableOnPlan]);

	if ((isNoPreview || !hasPreviewAvailableOnPlan) && !isLg) {
		return <NoDocumentPreview />;
	}

	return (
		<Box
			sx={{
				width: { xs: "100%", sm: "70%", lg: "100%" },
				height: { xs: 250, lg: "100%" },
				backgroundColor: "#E6F4F7",
				borderRadius: {
					xs: 6,
					lg: 0,
				},
				borderTopLeftRadius: { xs: "24px", lg: "48px !important" },
				borderTopRightRadius: { xs: "24px", lg: "48px !important" },
				padding: { xs: 2, lg: 6 },
				paddingBottom: { lg: "0px !important" },
				position: "relative",
				boxSizing: "border-box",
			}}
			className={props.className}
		>
			<Box ref={parentBoxRef} sx={{ width: "100%", height: "100%", overflow: "hidden" }}>
				<Fade in={!isLoadingPreview}>
					{previewType === "image" && !imageError ? (
						<img
							src={backendMediaUrlParser(currentFile?.url)}
							alt={document?.name}
							css={{ width: "100%", height: "100%", objectFit: "contain" }}
							onError={() => {
								setImageError(true);
								setLoadingPreview(false);
							}}
							onLoad={(ev) => {
								lastFetchedVersion.current = currentFile?.version;
								setLoadingPreview(false);
							}}
						/>
					) : previewType === "video" ? (
						<Box
							sx={{
								width: "100%",
								height: "100%",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								boxSizing: "border-box",
								position: "relative",
							}}
						>
							<video
								onCanPlay={(ev) => {
									lastFetchedVersion.current = currentFile?.version;
									setLoadingPreview(false);
								}}
								onError={() => {
									setImageError(true);
									setLoadingPreview(false);
								}}
								width="320"
								height="240"
								controls
								autoPlay
								muted
								css={{ width: "100%", objectFit: "contain" }}
							>
								<source src={backendMediaUrlParser(currentFile?.url)} type={documentMimeType} />
							</video>
						</Box>
					) : previewType === "pdf" ? (
						<iframe
							src={backendMediaUrlParser(currentFile?.url)}
							title={document?.name ?? ""}
							css={{ width: "100%", height: "100%", border: 0 }}
							onLoad={(ev) => {
								lastFetchedVersion.current = currentFile?.version;
								setLoadingPreview(false);
							}}
							onError={() => {
								setImageError(true);
								setLoadingPreview(false);
							}}
						/>
					) : previewType === "office" ? (
						<iframe
							src={`https://view.officeapps.live.com/op/embed.aspx?src=${backendMediaUrlParser(currentFile?.url)}`}
							title={document?.name ?? ""}
							css={{ width: "100%", height: "100%", border: 0, paddingBottom: 24, boxSizing: "border-box" }}
							onLoad={(ev) => {
								lastFetchedVersion.current = currentFile?.version;
								setLoadingPreview(false);
							}}
						/>
					) : previewType === "dxf" && hasPreviewAvailableOnPlan ? (
						<DxfPreviewBackground />
					) : previewType === "stl" ? (
						<div className="h-full bg-white">
							<StlViewer
								modelProps={{
									color: "#0D8EA9",
								}}
								style={{ width: "100%", height: "100%" }}
								onFinishLoading={() => {
									lastFetchedVersion.current = currentFile?.version;
									setLoadingPreview(false);
								}}
								orbitControls
								shadows
								url={backendMediaUrlParser(currentFile?.url)!}
							/>
						</div>
					) : (
						<Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
							<NoDocumentPreview />
						</Box>
					)}
				</Fade>
				{!imageError && (
					<Tooltip title="Open full screen" enterDelay={500}>
						<Button
							variant="contained"
							color="secondary"
							href={
								previewType === "stl"
									? `/stl-preview?fileUrl=${dxfPreviewUrl}`
									: previewType === "dxf"
									? `/dxf-preview?fileUrl=${dxfPreviewUrl}`
									: previewType === "office"
									? `https://view.officeapps.live.com/op/view.aspx?src=${backendMediaUrlParser(currentFile?.url)}`
									: backendMediaUrlParser(currentFile?.url)
							}
							{...(previewType !== "stl" &&
								previewType !== "dxf" && { target: "_blank", rel: "noopener noreferrer", component: "a" })}
							sx={{
								position: "absolute",
								top: 16,
								right: 24,
								minWidth: 0,
								padding: "6px",
								borderRadius: 12,
								boxSizing: "border-box",
								width: 48,
								height: 48,
								zIndex: 10,
							}}
						>
							<CertiblokIcon size={36} color="inherit" name={"open-full-screen_outline"} />
						</Button>
					</Tooltip>
				)}
				{!imageError && props.downloadable && (
					<Tooltip title={t("documents.downloadFiles")} enterDelay={500}>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => {
								window.open(
									backendMediaUrlParser(document?.lastVersionFile?.[0]?.url, {
										download: true,
										filename: document?.name ?? document?.lastVersionFile?.[0]?.filename,
									}),
									"_blank",
									"noopener noreferrer"
								);
							}}
							sx={{
								position: "absolute",
								top: 80,
								right: 24,
								minWidth: 0,
								padding: "6px",
								borderRadius: 12,
								boxSizing: "border-box",
								width: 48,
								height: 48,
								zIndex: 10,
							}}
						>
							<CertiblokIcon size={32} color="white" name={"load_outline_02"} />
						</Button>
					</Tooltip>
				)}
				{previewType === "dxf" && <DxfViewer parentRef={parentBoxRef} onLoad={setLoadingPreview} src={dxfPreviewUrl} />}
				<Fade in={isLoadingPreview}>
					<Loading title={t("preview.loading")} sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }} />
				</Fade>
			</Box>
		</Box>
	);
};

export default DocumentPreview;
