import { Audit, DocumentApi, Folder } from "certiblok-api-manager";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBottomSheetOld } from "../../../utils/components/bottomSheet/hooks/useBottomSheet";
import { useFolderHistory } from "../../../utils/components/FolderPicker/controllers/useFolderHistory";
import MLDialog from "../../../utils/components/poppers";
import { useApi } from "../../../utils/hooks/api";

import type { CertiblokDocument } from "../../../utils/interfaces/Document";

export function useAssignToFolderContext(documents: CertiblokDocument[]) {
	// const toggleOpen = toggleOpenFunction ? toggleOpenFunction : () => {};

	const [currentDocuments, setCurrentDocuments] = useState(documents);
	useEffect(() => {
		setCurrentDocuments(documents);
	}, [documents]);

	const [step, setStep] = useState(0);

	const goToNextStep = useCallback(() => {
		if (step !== 2) setStep(step + 1);
	}, [step]);

	const goToStep = useCallback((index: number) => {
		if (index >= 0 && index <= 2) setStep(index);
	}, []);

	const goToPrevStep = useCallback(() => {
		if (step !== 0) setStep(step - 1);
	}, [step]);

	const [selectedFolder, setSelectedFolder] = useState<Folder | undefined>();

	const setCurrentFolder = useCallback((folder: Folder) => {
		setSelectedFolder(folder);
	}, []);

	const folderHistory = useFolderHistory();

	const [shouldReplaceChecked, setShouldReplaceChecked] = useState(false);

	const [selectedAuditRooms, setSelectedAuditRooms] = useState<Audit[]>([]);

	const selectAuditRoom = useCallback(
		(auditRoom: Audit) => {
			setSelectedAuditRooms([...selectedAuditRooms, auditRoom]);
		},
		[selectedAuditRooms]
	);

	const selectAuditRooms = useCallback((auditRooms: Audit[]) => {
		setSelectedAuditRooms([...auditRooms]);
	}, []);

	const unselectAuditRoom = useCallback(
		(auditRoom: Audit) => {
			const index = selectedAuditRooms.findIndex((elem) => elem.id === auditRoom.id);
			if (index !== -1) {
				selectedAuditRooms.splice(index, 1);
				setSelectedAuditRooms([...selectedAuditRooms]);
			}
		},
		[selectedAuditRooms]
	);

	const resetSelectedAuditRooms = useCallback(() => {
		setSelectedAuditRooms([]);
	}, []);

	const [documentToReplace, setDocumentToReplace] = useState<CertiblokDocument | undefined>();

	const documentApi = useApi(DocumentApi);
	const { t } = useTranslation();
	const replaceDocument = useCallback(
		async (document: CertiblokDocument) => {
			setDocumentToReplace(document);
			await documentApi
				.moveToFolderAndReplaceFile(currentDocuments[0].id, {
					documentId: document.id,
					parentFolderId: selectedFolder?.id ?? "",
				})
				.catch((error) => {
					MLDialog.showSnackbar(t("folders.movingError", { documentName: document.name }), { variant: "error" });
				});
		},
		[currentDocuments, documentApi, selectedFolder?.id, t]
	);

	const replaceDocumentBS = useBottomSheetOld(false);

	const resetContext = useCallback(() => {
		setStep(0);
		setSelectedFolder(undefined);
		setShouldReplaceChecked(false);
		folderHistory.resetFolderHistory();
	}, [folderHistory]);

	const createAuditRoomControls = useBottomSheetOld();

	return {
		// toggleOpen,
		currentDocuments,
		step,
		goToNextStep,
		goToStep,
		goToPrevStep,
		selectedFolder,
		setCurrentFolder,
		...folderHistory,
		shouldReplaceChecked,
		setShouldReplaceChecked,
		documentToReplace,
		replaceDocument,
		replaceDocumentBS,
		resetContext,
		selectedAuditRooms,
		selectAuditRoom,
		selectAuditRooms,
		unselectAuditRoom,
		resetSelectedAuditRooms,
		createAuditRoomControls,
	};
}

type AssignToFolderContextType = ReturnType<typeof useAssignToFolderContext>;

export const AssignToFolderContext = React.createContext<AssignToFolderContextType>({} as AssignToFolderContextType);
