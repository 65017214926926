import { DocumentApi, SearchApi, UserOrContact } from "certiblok-api-manager";
import { useCallback, useEffect, useState } from "react";
import { useApi } from "../../../hooks/api";
import type { TextFieldStatus } from "../../../interfaces/TextFieldStatus";
import type { SupportedLanguagesType } from "../../LanguageSelector";
import { useTranslation } from "react-i18next";

export function useSendRequest() {
	const { i18n } = useTranslation();

	//* APIS
	const searchApi = useApi(SearchApi);
	const documentApi = useApi(DocumentApi);

	//* STATE
	const [availableContacts, setAvailableContacts] = useState<UserOrContact[]>([]);
	const [loading, setLoading] = useState(false);
	const [currentInputValue, setCurrentInputValue] = useState("");
	const [selectedLanguage, setSelectedLanguage] = useState<SupportedLanguagesType>(
		i18n.language.startsWith("it") ? "it" : "en"
	);

	//* HANDLERS

	const fetchContacts = useCallback(
		(input?: string) => {
			const finalInput = input || currentInputValue;

			if ((finalInput?.length || 0) < 3) return;

			setLoading(true);

			searchApi
				.searchUsersOrContacts(finalInput)
				.then((resp) => {
					setAvailableContacts(resp.data || []);
				})
				.catch(console.log)
				.finally(() => {
					setLoading(false);
				});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[currentInputValue]
	);

	const [selectedContacts, setSelectedContacts] = useState<(UserOrContact | string)[]>([]);

	const selectContact = useCallback(
		(contact: UserOrContact | string) => {
			let index;
			if (typeof contact === "string") {
				index = selectedContacts.findIndex((elem) => {
					if (typeof elem === "string") return elem === contact;
					return false;
				});
			} else {
				index = selectedContacts.findIndex((elem) => {
					if (typeof elem !== "string") return elem.id === contact.id;
					return false;
				});
			}
			if (index === -1) setSelectedContacts([...selectedContacts, contact]);
		},
		[selectedContacts]
	);

	const selectContacts = useCallback((contacts: (UserOrContact | string)[]) => {
		setSelectedContacts([...contacts]);
	}, []);

	const unselectContact = useCallback(
		(contact: UserOrContact | string) => {
			let index;
			if (typeof contact === "string") {
				index = selectedContacts.findIndex((elem) => {
					if (typeof elem === "string") return elem === contact;
					return false;
				});
			} else {
				index = selectedContacts.findIndex((elem) => {
					if (typeof elem !== "string") return elem.id === contact.id;
					return false;
				});
			}
			if (index >= 0) {
				selectedContacts.splice(index, 1);
				setSelectedContacts([...selectedContacts]);
			}
		},
		[selectedContacts]
	);

	const resetSelectedContact = useCallback(() => {
		setSelectedContacts([]);
	}, []);

	const [reason, setReason] = useState("");

	const [autocompleteStatus, setAutocompleteStatus] = useState<{
		status?: TextFieldStatus;
		message?: string | undefined;
	}>({ status: undefined, message: undefined });

	const onInputChange = useCallback((input: string) => {
		setCurrentInputValue(input);
		fetchContacts(input);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setAutocompleteStatus({ status: loading ? "loading" : undefined, message: "loading" });
	}, [loading]);

	const onLanguageChange = useCallback((newLanguage: SupportedLanguagesType) => {
		setSelectedLanguage(newLanguage);
	}, []);

	const [loadingSubmit, setLoadingSubmit] = useState(false);

	const submit = useCallback(() => {
		setLoadingSubmit(true);

		const emails = selectedContacts.map((contact) => (typeof contact === "string" ? contact : contact?.email));

		//! add request

		const promises = emails.map((email) =>
			documentApi.sendRequest({ email, message: reason, language: selectedLanguage })
		);

		Promise.all(promises)
			.catch(console.log)
			.finally(() => {
				setLoadingSubmit(false);
				setSelectedLanguage("it");
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reason, selectedContacts]);

	return {
		availableContacts,
		fetchContacts,
		loading,
		selectedLanguage,
		onLanguageChange,
		filterContacts: fetchContacts,
		selectedContacts,
		selectContact,
		selectContacts,
		unselectContact,
		resetSelectedContact,
		autocompleteStatus,
		setAutocompleteStatus,
		currentInputValue,
		onInputChange,
		loadingSubmit,
		submit,
		reason,
		setReason,
	};
}
