import { useInfiniteQuery } from "@tanstack/react-query";
import { AuditApi } from "certiblok-api-manager";
import { instanceApi, apiErrorParser } from "../../../../hooks/api";
import MLDialog from "../../../poppers";
import { transformToStringFrom, defaultQueryFilter } from "../../../tables/AdminTable";
import { GROUP_SIZE } from "../component/AuditRoomList";

export function useInfiniteAuditList() {
	return useInfiniteQuery({
		queryKey: ["auditRooms", "list"],
		queryFn: ({ pageParam }) => {
			return instanceApi(AuditApi).listOnlyOwnerAuditRooms(
				transformToStringFrom({ ...defaultQueryFilter, perPage: GROUP_SIZE, page: pageParam ?? 0 })
			);
		},
		getNextPageParam: (lastPage, pages) => {
			const totalAuditRooms = pages.reduce((acc, page) => acc + page.data.length, 0);
			return totalAuditRooms < lastPage.headers.count ? pages.length : undefined;
		},
		onError: (err) => {
			MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
		},
	});
}
