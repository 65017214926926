import { alpha, Button, Stack, Theme, Typography } from "@mui/material";
import { useTranslation, type TFunction } from "react-i18next";
import ResponsiveDialog from "../../../../../utils/components/dialogs/ResponsiveDialog";
import CertiblokIcon from "../../../../../utils/components/icons/CertiblokIcon";
import {
	ModalNavigator,
	useModalNavigator,
	useModalRoute,
} from "../../../../../utils/components/ModalNavigator/ModalNavigator";
import MLDialog from "../../../../../utils/components/poppers";
import { useBetterMediaQuery } from "../../../../../utils/hooks/useBetterMediaQuery";

export function showMembersLimitDialog(theme: Theme, goBackAction: () => void, t: TFunction, disableBack?: boolean) {
	MLDialog.showModal(t("team.membersLimitOverflow"), t("team.membersLimitOverflowWarning"), {
		negativeButton: !disableBack ? (
			<Button
				size="large"
				color="secondary"
				variant="text"
				onClick={() => {
					MLDialog.hideModal();
					goBackAction();
				}}
			>
				<CertiblokIcon name="arrow_mini_outline_left" color="inherit" />
				{t("team.backToChoice")}
			</Button>
		) : (
			<div></div>
		),
		positiveButton: () => {
			return (
				<Button
					href={"/profile"}
					onClick={MLDialog.hideModal}
					color="secondary"
					variant="contained"
					size="large"
					sx={{ textAlign: "center" }}
				>
					<CertiblokIcon name="success" color="inherit" />
					{t("profile.changePlan")}
				</Button>
			);
		},
		icon: (
			<CertiblokIcon
				size={48}
				color="error"
				name={"log_outline_01"}
				iconCss={{ padding: 12, backgroundColor: alpha(theme.palette.error.main, 0.12), borderRadius: 56 }}
			/>
		),
	});
}

export const MembersLimitDialog = ModalNavigator.create<{ disableBack?: boolean }>(({ disableBack }) => {
	const { t } = useTranslation();

	const { visible } = useModalRoute();
	const { popModal, dismissModal } = useModalNavigator();

	const isMd = useBetterMediaQuery("md");

	return (
		<ResponsiveDialog
			footer={
				<Stack
					sx={{ flexShrink: 0, width: "100%" }}
					gap={1}
					direction="row"
					justifyContent="space-between"
					className="md:mt-8"
				>
					{disableBack ? (
						<div></div>
					) : (
						<Button
							size={isMd ? "large" : "small"}
							color="secondary"
							variant="text"
							onClick={() => {
								popModal();
							}}
						>
							<CertiblokIcon name="arrow_mini_outline_left" color="inherit" />
							{t("team.backToChoice")}
						</Button>
					)}

					<Button
						href={"/profile"}
						onClick={dismissModal}
						color="secondary"
						variant="contained"
						size={isMd ? "large" : "small"}
						sx={{ textAlign: "center" }}
					>
						<CertiblokIcon name="success" color="inherit" />
						{t("profile.changePlan")}
					</Button>
				</Stack>
			}
			open={visible}
			onClose={popModal}
		>
			<div className="px-6 md:px-0">
				<CertiblokIcon size={48} color="error" name={"log_outline_01"} className="p-3 bg-error/[0.12] rounded-full" />

				<Typography
					sx={{
						typography: {
							xs: "h6",
							md: "h4",
						},
					}}
					children={t("team.membersLimitOverflow")}
					className="mb-2 mt-6"
				/>
				<Typography
					sx={{
						typography: {
							xs: "caption",
							md: "h6",
						},
					}}
					color="GrayText"
					children={t("team.membersLimitOverflowWarning")}
				/>
			</div>
		</ResponsiveDialog>
	);
});
