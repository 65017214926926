import { useQuery } from "@tanstack/react-query";
import { DocumentApi } from "certiblok-api-manager";
import { defaultQueryFilter, transformToStringFrom } from "../../../utils/components/tables/AdminTable";
import { useApi } from "../../../utils/hooks/api";

export const inboxDocumentsCountQueryKey = ["inboxDocuments", "count"];

export const useGetInboxDocumentsCount = () => {
	const documentApi = useApi(DocumentApi);

	return useQuery({
		queryKey: inboxDocumentsCountQueryKey,
		queryFn: () => documentApi.listInboxDocuments(transformToStringFrom({ ...defaultQueryFilter, perPage: 1 })),
		select: (res) => res.headers["x-total-count"],
	});
};
