/** @jsxImportSource @emotion/react */
import type { CSSObject } from "@emotion/react";
import { Popover, PopoverProps, useTheme } from "@mui/material";
import React, { useRef } from "react";

type ArrowPlacementType =
	| "bottom-end"
	| "bottom-start"
	| "bottom"
	| "left-end"
	| "left-start"
	| "left"
	| "right-end"
	| "right-start"
	| "right"
	| "top-end"
	| "top-start"
	| "top";

export type ArrowPopoverProps = PopoverProps & {
	backgroundColor?: string;
	placement?: ArrowPlacementType;
	css?: CSSObject;
};
export default function ArrowPopover(props: ArrowPopoverProps) {
	const nodeRef = useRef(null);
	const { children, open, backgroundColor, placement, css, ...others } = props;
	const theme = useTheme();
	//   const placementProps = getPlacementProps(placement);
	return (
		<Popover
			ref={nodeRef}
			css={{
				".MuiPaper-root": {
					backgroundColor: backgroundColor || theme.palette.background.paper,
					"::-webkit-scrollbar": { display: "none" },
					overflowX: "unset",
					overflowY: "unset",
					marginBottom: "-10px",
					boxShadow: "0px 9px 46px rgba(0, 0, 0, 0.12)",
					borderRadius: "24px",
					"&::after": {
						content: '""',
						position: "absolute",
						marginRight: "-0.71em",
						top: 0,
						left: "calc(90% - 5px)",
						display: "block",
						width: 10,
						height: 10,
						backgroundColor: backgroundColor || theme.palette.background.paper,
						boxShadow: theme.shadows[1],
						transform: `translateY(-5px) rotate(315deg)`,
						clipPath: "polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))",
					},
				},
				transform: "unset",
				marginTop: 46,
				...css,
			}}
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
			transformOrigin={{ vertical: "top", horizontal: "center" }}
			anchorEl={props?.anchorEl}
			open={props.open}
			{...others}
		>
			{/* <span
        css={{
          content: '""',
          position: "absolute",
          marginRight: "-0.71em",
          paddingBottom: "10px",
          marginBottom: "-10px",
          top: 0,
          left: "calc(50% - 5px)",
          display: "block",
          width: 10,
          height: 10,
          backgroundColor: backgroundColor || theme.palette.background.paper,
          boxShadow: theme.shadows[1],
          transform: `translateY(-5px) rotate(315deg)`,
          clipPath: "polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))"
        }}
      /> */}
			<div
				css={{
					// overflowY: "scroll",
					overflowX: "hidden",
					// maxHeight: "60vh",
					"::-webkit-scrollbar": { display: "none" },
					borderRadius: "inherit",
					overscrollBehaviorY: "contain",
				}}
			>
				{children}
			</div>
		</Popover>
	);
}

// function getPlacementProps(placement: ArrowPlacementType) {
//   let placementProps: {
//     rotation: string;
//     x: string;
//     y: string;
//     anchorOrigin: PopoverOrigin;
//     transformOrigin: PopoverOrigin;
//     verticalAnchor: "top" | "bottom";
//     horizontalAnchor: "left" | "right";
//   };

//   switch (placement) {
//     case "top":
//       placementProps = {
//         rotation: "315deg",
//         x: "calc(50% - 5px)",
//         y: "-5px",
//         anchorOrigin: { vertical: "top", horizontal: "center" },
//         transformOrigin: { vertical: "top", horizontal: "center" },
//         verticalAnchor: "top",
//         horizontalAnchor: "left"
//       };
//       break;
//     case "top-start":
//       placementProps = {
//         rotation: "315deg",
//         x: "30px",
//         y: "-5px",
//         anchorOrigin: { vertical: "top", horizontal: "center" },
//         transformOrigin: { vertical: "top", horizontal: "center" },
//         verticalAnchor: "top",
//         horizontalAnchor: "left"
//       };
//       break;
//     case "top-end":
//       placementProps = {
//         rotation: "315deg",
//         x: "-30px",
//         y: "-5px",
//         anchorOrigin: { vertical: "top", horizontal: "center" },
//         transformOrigin: { vertical: "top", horizontal: "center" },
//         verticalAnchor: "top",
//         horizontalAnchor: "left"
//       };
//       break;

//     case "left":
//       placementProps = {
//         rotation: "227deg",
//         x: "-4px",
//         y: "calc(50% - 5px)",
//         anchorOrigin: { vertical: "top", horizontal: "center" },
//         transformOrigin: { vertical: "top", horizontal: "center" },
//         verticalAnchor: "top",
//         horizontalAnchor: "left"
//       };
//       break;
//     case "left-start":
//       placementProps = {
//         rotation: "227deg",
//         x: "-4px",
//         y: "20px",
//         anchorOrigin: { vertical: "top", horizontal: "center" },
//         transformOrigin: { vertical: "top", horizontal: "center" },
//         verticalAnchor: "top",
//         horizontalAnchor: "left"
//       };
//       break;
//     case "left-start":
//       placementProps = {
//         rotation: "227deg",
//         x: "-4px",
//         y: "calc(100% - 30px)",
//         anchorOrigin: { vertical: "top", horizontal: "center" },
//         transformOrigin: { vertical: "top", horizontal: "center" },
//         verticalAnchor: "top",
//         horizontalAnchor: "left"
//       };
//       break;

//     case "right":
//       placementProps = {
//         rotation: "45deg",
//         x: "-5px",
//         y: "calc(50% - 5px)",
//         anchorOrigin: { vertical: "top", horizontal: "center" },
//         transformOrigin: { vertical: "top", horizontal: "center" },
//         verticalAnchor: "top",
//         horizontalAnchor: "left"
//       };
//       break;

//     case "right-start":
//       placementProps = {
//         rotation: "45deg",
//         x: "-5px",
//         y: "20px",
//         anchorOrigin: { vertical: "top", horizontal: "center" },
//         transformOrigin: { vertical: "top", horizontal: "center" },
//         verticalAnchor: "top",
//         horizontalAnchor: "left"
//       };
//       break;
//     case "right-end":
//       placementProps = {
//         rotation: "45deg",
//         x: "-5px",
//         y: "calc(100% - 30px)",
//         anchorOrigin: { vertical: "top", horizontal: "center" },
//         transformOrigin: { vertical: "top", horizontal: "center" },
//         verticalAnchor: "top",
//         horizontalAnchor: "left"
//       };
//       break;

//     case "bottom":
//       placementProps = {
//         rotation: "135deg",
//         x: "calc(50% - 5px)",
//         y: "4px",
//         anchorOrigin: { vertical: "bottom", horizontal: "center" },
//         transformOrigin: { vertical: "bottom", horizontal: "center" },
//         verticalAnchor: "bottom",
//         horizontalAnchor: "left"
//       };
//       break;
//     case "bottom-start":
//       placementProps = {
//         rotation: "135deg",
//         x: "20px",
//         y: "4px",
//         anchorOrigin: { vertical: "bottom", horizontal: "center" },
//         transformOrigin: { vertical: "bottom", horizontal: "center" },
//         verticalAnchor: "bottom",
//         horizontalAnchor: "left"
//       };
//       break;
//     case "bottom-end":
//       placementProps = {
//         rotation: "135deg",
//         x: "calc(100% - 30px)",
//         y: "4px",
//         anchorOrigin: { vertical: "bottom", horizontal: "center" },
//         transformOrigin: { vertical: "bottom", horizontal: "center" },
//         verticalAnchor: "bottom",
//         horizontalAnchor: "left"
//       };
//       break;

//     default:
//       placementProps = {
//         rotation: "315deg",
//         x: "20px",
//         y: "-5px",
//         anchorOrigin: { vertical: "top", horizontal: "center" },
//         transformOrigin: { vertical: "top", horizontal: "center" },
//         verticalAnchor: "top",
//         horizontalAnchor: "left"
//       };
//       break;
//   }

//   return placementProps;
// }
