import { cn } from "@mabi-ui/utils";
import { Typography } from "@mui/material";
import { ChatMessage } from "certiblok-api-manager";
import { DateTime } from "luxon";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useMe } from "../../../../../../utils/hooks/useMe";

const SingleInfoMessage = ({ note, isLastNote }: { note: ChatMessage; isLastNote: boolean }) => {
	const { me } = useMe();
	const { t } = useTranslation();

	const onMount = useCallback(
		(ref: HTMLDivElement) => {
			if (isLastNote) {
				ref?.scrollIntoView({ behavior: "smooth" });
			}
		},
		[isLastNote]
	);

	const isMyNote = note.ownerId === me?.id;

	const getNoteMessage = (note: ChatMessage) => {
		switch (note.type) {
			case "fileVersion":
				return t(isMyNote ? "sharingNotes.youUpdatedAtVersion" : "sharingNotes.documentUpdatedAtV", {
					version: note.content ?? 1,
					date: note.createdAt ? DateTime.fromISO(note.createdAt).toFormat("dd/MM/yyyy") : "-",
					time: note.createdAt ? DateTime.fromISO(note.createdAt).toFormat("HH:mm") : "-",
				});
			case "sharedFile":
				return t(isMyNote ? "sharingNotes.youSharedFileOnAt" : "sharingNotes.fileWasSharedWithYouOnAt", {
					date: note.createdAt ? DateTime.fromISO(note.createdAt).toFormat("dd/MM/yyyy") : "-",
					time: note.createdAt ? DateTime.fromISO(note.createdAt).toFormat("HH:mm") : "-",
				});
			case "unsharedFile":
				return t(isMyNote ? "sharingNotes.youInterruptedSharingOnAt" : "sharingNotes.sharingWasInterruptedOnAt", {
					date: note.createdAt ? DateTime.fromISO(note.createdAt).toFormat("dd/MM/yyyy") : "-",
					time: note.createdAt ? DateTime.fromISO(note.createdAt).toFormat("HH:mm") : "-",
				});
			default:
				return "";
		}
	};

	return (
		<div
			className={cn(
				"w-full box-border flex justify-center my-2 bg-secondary-dark/[0.06] py-[2px] px-4 text-black/[0.8] text-center"
			)}
			ref={onMount}
		>
			<Typography variant="caption">{getNoteMessage(note)}</Typography>
		</div>
	);
};

export default SingleInfoMessage;
