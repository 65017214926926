/** @jsxImportSource @emotion/react */
import {
	Box,
	Fade,
	Grid,
	menuClasses,
	MenuItem,
	Select,
	selectClasses,
	Stack,
	SxProps,
	Theme,
	Typography,
} from "@mui/material";
import type React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import engFlag from "../../assets/flags/english.png";
import itaFlag from "../../assets/flags/ita.png";
import logotype from "../../assets/logo/logotype.png";
import { useBetterMediaQuery } from "../hooks/useBetterMediaQuery";
import useLogout from "../hooks/useLogout";
import LoadingIconButton from "./buttons/LoadingIconButton";
import CertiblokIcon from "./icons/CertiblokIcon";
import { useTranslation } from "react-i18next";
import { getPlatformLanguageFromI18n } from "../hooks/useLanguageSwitcher";

export const AUTH_CONTAINER_PADDING = { xs: 5, md: 8, xl: 12 };
export const AUTH_CONTAINER_PADDING_TOP = { xs: 8, xl: 12 };

type Props = { sx?: SxProps<Theme> };

export const AuthContainer: React.FC<Props> = ({ children, sx = {} }) => {
	return (
		<Grid
			item
			xs={12}
			md={7}
			lg={5}
			xl={4}
			sx={{
				backgroundColor: "white",
				borderRadius: { xs: 8, md: 12, xl: 16 },
				borderBottomRightRadius: { xs: 0, md: 12 * 4, xl: 16 * 4 },
				borderBottomLeftRadius: { xs: 0, md: 12 * 4, xl: 16 * 4 },
				padding: AUTH_CONTAINER_PADDING,
				paddingTop: AUTH_CONTAINER_PADDING_TOP,
				zIndex: 1,
				position: "unset",
				...sx,
			}}
			children={children}
		/>
	);
};

const LANGUAGE_OPTIONS = [
	{ id: 0, language: "Italiano", flag: itaFlag },
	{ id: 1, language: "English", flag: engFlag },
];

const AuthScaffold: React.FC = () => {
	const isMd = useBetterMediaQuery("md");
	const location = useLocation();
	const logout = useLogout();
	const { i18n } = useTranslation();

	const shouldShowLogoutButton = new RegExp("/register/.+").test(location.pathname);

	const [selectedLanguageId, setSelectedLanguageId] = useLocalStorage(
		"platform-language",
		getPlatformLanguageFromI18n(i18n)
	);

	return (
		<Box
			sx={{
				backgroundColor: { xs: "background.default", md: "surface.main" },
				width: "100%",
				height: "100%",
				maxHeight: "100%",
				display: "flex",
				alignItems: "center",
				overflowY: "auto",
				flexDirection: "column",
				padding: { xs: 4, md: 6 },
				paddingTop: { xs: 4, md: 6 },
				gap: 4,
				boxSizing: "border-box",
			}}
		>
			<Stack direction="row" alignItems={"center"} sx={{ justifyContent: { xs: "start", md: "center" }, width: "100%" }}>
				<img src={logotype} alt="Certiblok logo" css={{ height: isMd ? 64 : 36 }} loading="lazy" />
				<Stack
					direction="row"
					alignItems={"center"}
					justifyContent="center"
					gap={2}
					className="ml-auto md:ml-[unset]"
					sx={{ position: { md: "absolute" }, right: { xs: 24, md: 48, lg: 96 } }}
				>
					<Fade in={shouldShowLogoutButton} mountOnEnter unmountOnExit>
						<LoadingIconButton
							sx={{ height: "100%" }}
							icon={<CertiblokIcon name="log_outline_01" color="inherit" />}
							onClick={logout}
						>
							{isMd && "Logout"}
						</LoadingIconButton>
					</Fade>
					<Box>
						<Select
							value={selectedLanguageId}
							color="secondary"
							fullWidth
							variant="standard"
							size="medium"
							onChange={(ev) => setSelectedLanguageId(parseInt(`${ev.target.value}`))}
							renderValue={(value) => {
								const selectedLanguage = LANGUAGE_OPTIONS.find((elem) => elem.id === value);
								return selectedLanguage ? (
									<Stack alignItems={"center"} sx={{ pr: 4, pl: 2 }}>
										<img
											src={selectedLanguage.flag}
											alt={`${selectedLanguage.language} flag`}
											css={{ width: 16, height: 16, borderRadius: 8 }}
										/>
									</Stack>
								) : null;
							}}
							MenuProps={{
								sx: {
									[`& .${menuClasses.paper}`]: {
										marginTop: 1,
									},
								},
							}}
							sx={{
								"& .MuiSelect-select": {
									height: "48px !important",
									border: "unset",
									backgroundColor: "background.default",
									paddingLeft: 3,
								},
								[`& .${selectClasses.icon}`]: {
									right: 12,
								},
							}}
						>
							{LANGUAGE_OPTIONS.map((elem) => (
								<MenuItem key={elem.id} value={elem.id} sx={{ py: 1, px: 3 }}>
									<Stack direction="row" alignItems="center" justifyContent="flex-start" gap={2} sx={{ width: "100%" }}>
										<img src={elem.flag} alt={`${elem.language} flag`} css={{ width: 16, height: 16, borderRadius: 8 }} />
										<Typography
											variant="body2"
											children={elem.language.slice(0, 3).toUpperCase()}
											sx={{ textAlign: "center", flexGrow: 1 }}
										/>
									</Stack>
								</MenuItem>
							))}
						</Select>
					</Box>
				</Stack>
			</Stack>
			<Grid
				container
				justifyContent="flex-end"
				sx={{
					background:
						"linear-gradient(87.55deg, #E0EAE7 0%, #F2F5EE 100%), linear-gradient(87.55deg, #CCDAD7 0%, #E8E3D9 100%);",
					px: {
						xs: 0,
						md: 6,
					},
					py: { xs: 0, md: 6 },
					borderRadius: { xs: 10, md: 16, xl: 20 },
					borderBottomRightRadius: { xs: 0, md: 16 * 4, xl: 20 * 4 },
					borderBottomLeftRadius: { xs: 0, md: 16 * 4, xl: 20 * 4 },
					width: "100%",
					flexGrow: 1,
					flexShrink: 0,
					overflow: "hidden",
					position: "relative",
				}}
			>
				<Outlet />
			</Grid>
		</Box>
	);
};

export default AuthScaffold;
