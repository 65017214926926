import { Box, Button, ModalProps, Typography } from "@mui/material";
import { DocumentApi, EditRoomApi } from "certiblok-api-manager";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { apiErrorParser, instanceApi } from "../../hooks/api";
import type { CertiblokDocument } from "../../interfaces/Document";
import CertiblokTextInput from "../CertiblokTextInput";
import LoadingIconButton from "../buttons/LoadingIconButton";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";
import CertiblokIcon from "../icons/CertiblokIcon";
import MLDialog from "../poppers";

type RenameFileProps = Omit<ModalProps, "children"> & {
	document: CertiblokDocument;
	toggleOpen: () => void;
	onSuccess?: (document: CertiblokDocument) => void;
	loading?: boolean;
};

const RenameFile: React.FC<RenameFileProps> = ({ document, toggleOpen, onSuccess, open }) => {
	const [fileName, setFileName] = useState(document.name);

	const location = useLocation();
	const isInEditRoom = location.pathname.includes("/edit_rooms/");

	useEffect(() => {
		setFileName(document.name);
	}, [document]);

	const [loading, setLoading] = useState(false);

	const submit = useCallback(() => {
		setLoading(true);

		if (isInEditRoom) {
			instanceApi(EditRoomApi)
				.updateEditRoomDocument(document?.id, { name: fileName, expireDate: document.expireDate || undefined })
				.then(() => {
					setLoading(false);
					toggleOpen?.();
					onSuccess?.({ ...document, name: fileName });
				})
				.catch((err) => {
					setLoading(false);
					MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
				});
		} else {
			instanceApi(DocumentApi)
				.editDocument(document?.id, { name: fileName, expireDate: document.expireDate || undefined })
				.then(() => {
					setLoading(false);
					toggleOpen?.();
					onSuccess?.({ ...document, name: fileName });
				})
				.catch((err) => {
					setLoading(false);
					MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
				});
		}
	}, [document, fileName, onSuccess, toggleOpen, isInEditRoom]);

	const { t } = useTranslation();

	return (
		<ResponsiveDialog
			open={open}
			onClose={() => {
				toggleOpen();
			}}
			ModalProps={{ responsive: true }}
			header={<Typography children={t("documents.renameFile")} variant="subtitle1" />}
			footer={
				<Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", paddingTop: 12 }}>
					<Button variant="outlined" color="secondary" onClick={() => toggleOpen()}>
						<CertiblokIcon size={24} color="secondary" name={"close"} />
						{t("global.cancel")}
					</Button>
					<LoadingIconButton
						icon={<CertiblokIcon size={24} color="white" name={"success"} />}
						loading={loading}
						variant="contained"
						color="secondary"
						onClick={submit}
					>
						{t("global.saveEdits")}
					</LoadingIconButton>
				</Box>
			}
		>
			<div className="min-h-[25vh] md:min-h-[unset] px-5 md:px-0 md:pt-3">
				<CertiblokTextInput
					label="Nome file"
					color="secondary"
					fullWidth
					value={fileName}
					onChange={(ev) => setFileName(ev.target.value)}
				/>
			</div>
		</ResponsiveDialog>
	);
};

export default RenameFile;
