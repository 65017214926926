import OutletContainer from "../../utils/components/OutletContainer";
import TableHeader from "./components/TableHeader";
import TagsTable from "./components/TagsTable";
import { TagsContext, useTagsContext } from "./contexts/TagsContext";
import usePathQueryUpdater from "../../utils/hooks/usePathQueryUpdater";

const TagsPage = () => {
	const tagsContext = useTagsContext();
	usePathQueryUpdater([tagsContext.queryFilter, tagsContext.setQueryFilter]);

	return (
		<TagsContext.Provider value={tagsContext}>
			<OutletContainer>
				<TableHeader />
				<TagsTable />
			</OutletContainer>
		</TagsContext.Provider>
	);
};

export default TagsPage;
