import { Tab, TabProps } from "@headlessui/react";
import { UnstyledButton } from "@mabi-ui/button";
import { cn } from "@mabi-ui/utils";
import { Typography } from "@mui/material";
import { FC, Fragment, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import dropboxLogo from "../../../../../assets/cloud_import/dropbox.png";
import oneDriveLogo from "../../../../../assets/cloud_import/onedrive.png";
import driveLogo from "../../../../../assets/cloud_import/drive.png";

const CloudTab: FC<TabProps<any> & { image: ReactNode; title: string; disabled?: boolean }> = ({
	image,
	title,
	disabled,
	...props
}) => {
	const { t } = useTranslation();

	return (
		<Tab as={Fragment}>
			{({ selected }) => (
				<UnstyledButton
					className={cn(
						"relative group py-4 px-3 bg-transparent rounded-2xl border-black/[0.12] border-solid border-[1px] w-[min(calc(30%-6px),130px)] outline-2 outline outline-transparent",
						selected && "border-primary outline-primary bg-primary/[0.06]",
						disabled && "cursor-default"
					)}
					{...props}
					isDisabled={disabled}
				>
					{disabled && (
						<div className="absolute flex items-center justify-center opacity-0 group-hover:opacity-100 top-0 left-0 transition-opacity bg-grey/[0.12] w-full h-full rounded-2xl">
							<Typography variant="body2" className="text-[14px] text-black/[0.4] uppercase font-bold">
								{t("global.comingSoon")}
							</Typography>
						</div>
					)}
					<div className={cn("w-full h-[24px]", disabled && "group-hover:blur-[3px] opacity-50")}>{image}</div>
					<Typography variant="body2" className={cn("mt-3", disabled && "group-hover:blur-[3px] opacity-20")}>
						{title}
					</Typography>
				</UnstyledButton>
			)}
		</Tab>
	);
};

const CloudTabs = ({
	selectedTab,
	setSelectedTab,
}: {
	selectedTab: number;
	setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
}) => {
	const { t } = useTranslation();

	return (
		<Tab.Group selectedIndex={selectedTab} onChange={(i) => setSelectedTab(i)}>
			<Tab.List className={"w-full flex gap-6 justify-center"}>
				{/* // TODO MEM: google drive disabled */}
				<CloudTab
					image={<img src={driveLogo} alt={t("cloudImport.googleDrive")} className="h-full w-full object-contain " />}
					title={t("cloudImport.googleDrive")}
					disabled
				/>
				<CloudTab
					image={<img src={dropboxLogo} alt={t("cloudImport.dropbox")} className="h-full w-full object-contain" />}
					title={t("cloudImport.dropbox")}
				/>
				{/* // TODO MEM: one drive disabled */}
				<CloudTab
					image={<img src={oneDriveLogo} alt={t("cloudImport.oneDrive")} className="h-full w-full object-contain" />}
					title={t("cloudImport.oneDrive")}
					disabled
				/>
			</Tab.List>
		</Tab.Group>
	);
};

export default CloudTabs;
