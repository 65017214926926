import { alpha, Button, styled } from "@mui/material";

const ActionButton = styled(Button)(({ theme, color }) => ({
	alignItems: "center",
	gap: 3,
	borderRadius: 12,
	color: alpha("#000000", 1),
	transition: theme.transitions.create(["opacity", "background-color"]),
	backgroundColor: "unset",
	lineHeight: "inherit",
	"&:hover": {
		backgroundColor: "unset",
		"&.MuiButton-text": {
			color: color !== undefined && color !== "inherit" ? theme.palette[color].main : theme.palette.primary.main,
		},
	},
	"& .MuiTouchRipple-root > *": {
		color: alpha("#000000", 0.12),
	},
}));

export default ActionButton;
