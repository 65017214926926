import { useQuery } from "@tanstack/react-query";
import { FavoriteApi } from "certiblok-api-manager";
import { useApi } from "../../../utils/hooks/api";

export const favoriteDocumentsInDashboardQueryKey = ["favoriteDocuments", "dashboard"];

export const useGetFavoriteDocumentsInDashboard = () => {
	const favoriteApi = useApi(FavoriteApi);
	return useQuery({
		queryKey: favoriteDocumentsInDashboardQueryKey,
		// @ts-ignore
		queryFn: () => favoriteApi.getDashboardFavorite(),
		select: (data) => data?.data?.data,
	});
};
