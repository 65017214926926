/** @jsxImportSource @emotion/react */
import { alpha, Box, Button, Stack, SxProps, Theme, Typography, useTheme } from "@mui/material";
import { PlanApi } from "certiblok-api-manager";
import type { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CertiblokIcon from "../../../utils/components/icons/CertiblokIcon";
import LoadingTextButton from "../../../utils/components/LoadingTextButton";
import MLDialog from "../../../utils/components/poppers";
import { apiErrorParser, useApi } from "../../../utils/hooks/api";
import { useBetterMediaQuery } from "../../../utils/hooks/useBetterMediaQuery";
import { useMe } from "../../../utils/hooks/useMe";
import type { SinglePlan } from "../interfaces/SinglePlan";

export const YourPlanLabel = () => {
	const { t } = useTranslation();
	return (
		<Box
			className="rounded-b-lg"
			sx={{
				position: "absolute",
				width: "30%",
				maxWidth: { xs: 60, md: 70 },
				top: 0,
				right: 20,
				isolation: "isolate",
				backgroundColor: (theme) => theme.palette.primary.main,
				// 	"::before, ::after": {
				// 		content: '""',
				// 		position: "absolute",
				// 		top: -12,
				// 		width: "100%",
				// 		height: { xs: 50, md: 62 },
				// 		borderRadius: 2,
				// 		zIndex: -1,
				// 		backgroundColor: (theme) => theme.palette.primary.main,
				// 	},
				// 	"::before": {
				// 		transform: { md: "skew(0deg, 20deg);" },
				// 	},
				// 	"::after": {
				// 		transform: { md: "skew(0deg, -20deg);" },
				// 	},
			}}
		>
			<div className="py-1 px-2">
				<Typography
					children={t("profile.yourPlan")}
					className="!text-label md:!text-subtitle2 font-bold text-white text-center "
					sx={{ zIndex: 2, width: "100%", pt: "6px" }}
				/>
			</div>
		</Box>
	);
};

type PlanCardProps = {
	isAdmin: boolean;
	plan: SinglePlan;
	isActive: boolean;
	activeRecurrency?: "month" | "year";
	containerSx?: SxProps<Theme>;
	isAnnual?: boolean;
	isInUpdate?: boolean;
	customPriceComponent?: React.ReactNode;
	nextPayment?: DateTime;
	expirationDate?: DateTime;
	hasPaid?: boolean;
	hideYourPlanLabel?: boolean;
	className?: string;
	isManagementCard?: boolean;
	customAction?: React.ReactNode;
};
const PlanCard: React.FC<PlanCardProps> = ({
	isActive,
	activeRecurrency,
	plan,
	isAdmin,
	containerSx = {},
	isAnnual,
	isInUpdate,
	customPriceComponent,
	nextPayment,
	expirationDate,
	hasPaid,
	hideYourPlanLabel,
	isManagementCard,
	className,
	customAction,
}) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const planApi = useApi(PlanApi);
	const { me } = useMe();
	const navigate = useNavigate();

	const isMd = useBetterMediaQuery("md");

	const isFree = plan.id === "-1";

	const onChangePlanClick = async () => {
		if (expirationDate) {
			const canUpdatePlan = (await planApi.canUserUpdatePlan()).data;
			if (!canUpdatePlan) {
				MLDialog.showModal(t("plan.unableToUpdatePlan"), t("plan.unableToUpdatePlanDescription"), {
					maxWidth: 600,
					icon: (
						<Box sx={{ backgroundColor: (theme) => alpha(theme.palette.orange.main, 0.12), padding: 3, borderRadius: "50%" }}>
							<CertiblokIcon size={48} color="orange" name={"comunication_outline_02"} />
						</Box>
					),
					positiveButton: () => (
						<Button color="secondary" onClick={() => MLDialog.hideModal()} size="large">
							<CertiblokIcon name="close" color="inherit" />
							{t("global.cancel")}
						</Button>
					),
				});
				return;
			}
		}
		if (!me?.company?.plan) {
			try {
				const paymentLink = await planApi.generatePlanLink(plan.id, isAnnual ? "year" : "month");
				window.location.href = paymentLink.data;
			} catch (e) {
				MLDialog.showSnackbar(apiErrorParser(e), { variant: "error" });
			}
		} else {
			navigate(
				`/profile/plan/update/${plan.id}?recurrency=${isAnnual ? "year" : "month"}${
					!hasDifferentRecurrency && isActive ? "&isRenew=true" : ""
				}`
			);
		}
	};

	const hasDifferentRecurrency =
		(activeRecurrency === "month" && isAnnual) || (activeRecurrency === "year" && !isAnnual);
	const hasEditPlanButton =
		isAdmin &&
		!(isFree && isActive) &&
		(hasDifferentRecurrency || !isActive || expirationDate !== undefined) &&
		!(isFree && expirationDate !== undefined);

	return (
		<Box
			className={className}
			sx={{
				width: "100%",
				height: "100%",
				borderRadius: 6,
				backgroundColor: isAdmin ? "white" : "transparent",
				boxSizing: "border-box",
				display: "flex",
				flexDirection: "column",
				gap: 3,
				// borderWidth: isActive ? 1 : 0,
				// borderColor: isActive ? "secondary.main" : "primary.main",
				padding: `${16 + (isActive ? 0 : 1)}px`,
				// borderStyle: "solid",
				position: "relative",
				...containerSx,
			}}
		>
			{isActive && isAdmin && !hideYourPlanLabel && <YourPlanLabel />}
			<Box
				sx={{ display: "flex", alignItems: "center", gap: 3, minHeight: { xs: 58, md: isManagementCard ? "unset" : 68 } }}
			>
				<Box
					sx={{
						backgroundColor: theme.palette.backgroundSecondary.default,
						padding: 2,
						borderRadius: { xs: 2, md: 3 },
						width: {
							xs: 36,
							md: 44,
						},
						height: {
							xs: 36,
							md: 44,
						},
						boxSizing: "border-box",
					}}
				>
					<CertiblokIcon
						twoColor
						background={{ name: plan.iconName[0], color: "secondary" }}
						foreground={{ name: plan.iconName[1], color: "secondary.light" }}
						size={isMd ? 28 : 20}
					/>
				</Box>
				<Box>
					<Typography
						children={plan.title}
						color="secondary"
						sx={{
							typography: {
								xs: "subtitle2",
								md: "subtitle1",
							},
						}}
					/>
					{customPriceComponent ?? (
						<Typography
							color="secondary"
							sx={{
								typography: {
									xs: "label",
									md: "subtitle2",
								},
								fontWeight: "700 !important",
								color: (theme) => `${theme.palette.secondary.main} !important`,
							}}
						>
							<Typography
								sx={{
									typography: {
										xs: "subtitle1",
										md: "h6",
									},
									fontWeight: "700 !important",
								}}
								component="b"
								color="secondary"
							>
								{isInUpdate && isAnnual && (
									<>
										<Typography
											sx={{
												typography: {
													xs: "subtitle1",
													md: "h6",
												},
												fontWeight: "700 !important",
												textDecoration: "line-through",
											}}
											component="b"
											color="secondary"
										>
											{`€${(plan.monthlyPrice / 100).toFixed(2)}`.replace(".", ",")}
										</Typography>{" "}
									</>
								)}

								{isFree
									? "€0"
									: `€${isAnnual ? (plan.yearlyPrice / 12 / 100).toFixed(2) : (plan.monthlyPrice / 100).toFixed(2)}`.replace(
											".",
											","
									  )}
							</Typography>
							{!isFree && ` ${t("profile.monthly")} ${isManagementCard ? t("profile.plusIVA") : ""}`}
						</Typography>
					)}
					{!isFree && !isManagementCard && (
						<Typography
							variant="caption"
							color={"secondary"}
							children={
								isAnnual
									? t("profile.yearlyPaymentOf", { price: (plan.yearlyPrice / 100).toFixed(2).replace(".", ",") })
									: t("profile.plusIVA")
							}
						/>
					)}
				</Box>
			</Box>

			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 5,
					backgroundColor: "backgroundSecondary.default",
					padding: 3,
					boxSizing: "border-box",
					borderRadius: 3,
					flex: 1,
				}}
			>
				<Box sx={{ display: "flex", gap: 1 }}>
					<CertiblokIcon size={16} color="secondary" name={"success"} iconCss={{ paddingRight: 4 }} />
					<Typography variant="subtitle2" color="secondary" sx={{ fontWeight: 400 }}>
						<b>{plan.numUser} user</b> {t("profile.inYourTeam")}
					</Typography>
				</Box>
				<Box sx={{ display: "flex", gap: 1 }}>
					<CertiblokIcon size={16} color="secondary" name={"success"} iconCss={{ paddingRight: 4 }} />
					<Typography variant="subtitle2" color="secondary" sx={{ fontWeight: 400 }}>
						<b>{plan.storageAvailable}</b> {t("profile.ofStorage")}
					</Typography>
				</Box>
				<Box sx={{ display: "flex", gap: 1 }}>
					<CertiblokIcon size={16} color="secondary" name={"success"} iconCss={{ paddingRight: 4 }} />
					<Typography variant="subtitle2" color="secondary" sx={{ fontWeight: 400 }}>
						<b>{plan.numAuditRoom} auditroom</b> {t("profile.openedCuncurrently")}
					</Typography>
				</Box>
				<Box sx={{ display: "flex", gap: 1 }}>
					<CertiblokIcon size={16} color="secondary" name={"success"} iconCss={{ paddingRight: 4 }} />
					<Typography variant="subtitle2" color="secondary" sx={{ fontWeight: 400 }}>
						<b>{plan.dwgDocs}</b> {t("plans.previewDwgLabel")}
					</Typography>
				</Box>
				<Box sx={{ display: "flex", gap: 1 }}>
					<CertiblokIcon size={16} color="secondary" name={"success"} iconCss={{ paddingRight: 4 }} />
					<Typography variant="subtitle2" color="secondary" sx={{ fontWeight: 400 }}>
						<b>{t("profile.infinite")}</b> {t("profile.shares")}
					</Typography>
				</Box>
				{plan.hasSyncFolder && (
					<Box sx={{ display: "flex", gap: 1 }}>
						<CertiblokIcon size={16} color="secondary" name={"success"} iconCss={{ paddingRight: 4 }} />
						<Typography variant="subtitle2" color="secondary" sx={{ fontWeight: 400 }}>
							<b>{t("profile.syncFolder")}</b>
						</Typography>
					</Box>
				)}
			</Box>
			{customAction ??
				(isAdmin && (
					<Stack
						direction="row"
						alignItems={"center"}
						gap={2}
						sx={{ flexWrap: { xs: "wrap", sm: "nowrap" }, minHeight: 40, marginTop: "auto" }}
					>
						{isActive && !isFree && (expirationDate !== undefined || nextPayment !== undefined) && (
							<Typography
								children={
									expirationDate
										? t("plan.activeUntil", { date: expirationDate.toFormat("dd/MM/yyyy") })
										: nextPayment
										? t("plan.nextInvoice", { date: nextPayment.toFormat("dd/MM/yyyy") })
										: ""
								}
								variant="caption"
								color={hasPaid ? "secondary" : "error"}
								component="p"
								sx={{
									textAlign: "center",
									maxWidth: {
										xs: "100%",
										sm: !hasEditPlanButton ? "100%" : "40%",
									},
									width: "100%",
								}}
							/>
						)}
						{hasEditPlanButton && (
							<LoadingTextButton
								onClick={onChangePlanClick}
								color="secondary"
								sx={{ height: { xs: 32, md: 40 }, gap: 2, flexGrow: 1 }}
								variant={isFree && !isActive ? "text" : "contained"}
								size={!isMd ? "small" : "medium"}
								// className="mt-2 md:mt-[10px]"
								{...(isManagementCard && {
									href: "/profile/plans/all",
									onClick: () => {},
								})}
							>
								{isFree && !isActive
									? t("profile.backToFreePlan")
									: hasDifferentRecurrency || !isActive
									? isManagementCard
										? t("plans.goToPlan", { name: plan.title.toLowerCase() })
										: t("profile.editPlan")
									: t("plan.resumePlan")}
								<CertiblokIcon size={24} color="inherit" name={"arrow_direction_outline_right"} />
							</LoadingTextButton>
						)}
						{isAdmin && isFree && isActive && (
							<Typography
								children={t("profile.chooseProPlan")}
								variant="caption"
								color="secondary"
								sx={{ textAlign: "center", width: "100%" }}
							/>
						)}
					</Stack>
				))}
		</Box>
	);
};

export default PlanCard;
