import { useCallback, useRef, useState } from "react";
import { useBottomSheetHeight } from "./useBottomSheetHeight";

export type BottomSheetControls = {
	open: boolean;
	toggleOpen: () => void;
	isBehind: boolean;
	toggleBehind: () => void;
	ref: React.MutableRefObject<null>;
	height: number;
	setHeight: React.Dispatch<React.SetStateAction<number | undefined>>;
};

/**
 * @deprecated use BottomSheet with new useBottomSheet hook
 */
export function useBottomSheetOld(defaultState?: boolean): BottomSheetControls {
	const [open, setOpen] = useState(defaultState ?? false);
	const [isBehind, setIsBehind] = useState(false);

	const toggleOpen = useCallback(() => {
		setOpen(!open);
	}, [open]);
	const toggleBehind = useCallback(() => {
		setIsBehind(!isBehind);
	}, [isBehind]);

	const ref = useRef(null);
	const [height, setHeight] = useBottomSheetHeight(ref);

	return {
		open,
		toggleOpen,
		isBehind,
		toggleBehind,
		ref,
		height,
		setHeight,
	};
}
