import { Button, Skeleton, Typography } from "@mui/material";
import { FavoriteFolderOrDocument } from "certiblok-api-manager";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CertiblokIcon from "../../../../utils/components/icons/CertiblokIcon";
import { useModalNavigator } from "../../../../utils/components/ModalNavigator/ModalNavigator";
import NoContents from "../../../../utils/components/NoContents";
import { useBetterMediaQuery } from "../../../../utils/hooks/useBetterMediaQuery";
import { useGetFavoriteDocumentsInDashboard } from "../../hooks/useGetFavoriteDocumentsInDashboard";
import FavoritePopupMenu from "./FavoritePopupMenu";
import FavoriteRow from "./FavoriteRow";

const FavoritesSection = () => {
	const { t } = useTranslation();
	const isMd = useBetterMediaQuery("md");

	const { pushModalById } = useModalNavigator();

	const [rowMenuSpecs, setRowMenuSpecs] = useState<{
		anchorEl: HTMLElement | null;
		folderOrDocument: FavoriteFolderOrDocument | undefined;
	}>({ anchorEl: null, folderOrDocument: undefined });

	const { data: favoriteElements, isLoading: isLoadingFavoriteElements } = useGetFavoriteDocumentsInDashboard();

	return (
		<div className="px-4 py-4 bg-[#F4FAFB] md:rounded-3xl rounded-2xl">
			<div className="flex justify-between gap-2 items-center mb-3">
				<div className="flex items-center gap-3">
					<div className="w-9 h-9 relative flex items-center justify-center">
						<CertiblokIcon name="star_bulk_02" className="absolute text-secondary/[0.24]" size={36} />
						<CertiblokIcon name="star_bulk_01" color="secondary" size={36} />
					</div>
					<Typography variant="subtitle1" className="text-secondary">
						{t("favorites.favorites")}
					</Typography>
				</div>
				<Button
					variant="text"
					color="secondary"
					endIcon={<CertiblokIcon color="secondary" name="arrow_mini_outline_right" />}
					href="/favorites"
				>
					{t("global.viewAll")}
				</Button>
			</div>
			<div className="grid grid-cols-1 md:grid-cols-2 gap-3">
				{isLoadingFavoriteElements ? (
					<>
						{Array(isMd ? 8 : 4)
							.fill("placeholder")
							.map((_, index) => (
								<Skeleton key={index} variant="rectangular" width="100%" height={48} className={"rounded-lg"} />
							))}
					</>
				) : (favoriteElements?.length ?? 0) === 0 ? (
					<div className="md:col-span-2 mb-4">
						<NoContents variant="document" title={t("folders.stillNoElement")} />
					</div>
				) : (
					(favoriteElements ?? []).slice(0, isMd ? 8 : 4).map((element) => {
						return (
							<FavoriteRow
								key={element.id}
								element={element}
								setRowMenuSpecs={setRowMenuSpecs}
								openMenu={() => pushModalById("favorite-popup-menu")}
							/>
						);
					})
				)}
			</div>

			<FavoritePopupMenu
				anchorEl={rowMenuSpecs.anchorEl ?? null}
				folderOrDocument={rowMenuSpecs.folderOrDocument}
				id="favorite-popup-menu"
				onRemoveFavorite={() => setRowMenuSpecs({ anchorEl: null, folderOrDocument: undefined })}
			/>
		</div>
	);
};

export default FavoritesSection;
