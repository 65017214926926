import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { FavoriteApi, Folder } from "certiblok-api-manager";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CertiblokIcon from "../../../utils/components/icons/CertiblokIcon";
import MLDialog from "../../../utils/components/poppers";
import { apiErrorParser, useApi } from "../../../utils/hooks/api";
import type { CertiblokDocument } from "../../../utils/interfaces/Document";

type Props = {
	className?: string;
	onMutate?: (isFavorite: boolean) => void;
	onSuccess?: (isFavorite: boolean) => void;
	iconSize?: number;
} & ({ element: CertiblokDocument; isFolder?: false } | { element: Folder; isFolder: true });

const ToggleFavoriteButton = ({ element, isFolder, className, onMutate, onSuccess, iconSize }: Props) => {
	const { t } = useTranslation();
	const [stillFavorite, setStillFavorite] = useState(() => {
		if (element.isFavorite || element.favorites) {
			return element.isFavorite || (element.favorites?.length ?? 0) > 0;
		}
		return true;
	});
	const timerRef = useRef<NodeJS.Timeout>();

	const favoriteApi = useApi(FavoriteApi);

	const toggleDocumentFromFavorite = useMutation({
		mutationFn: ({ favorite }: { favorite: boolean }) => {
			return favorite
				? favoriteApi.addDocumentToFavorite({ documentId: element.id })
				: favoriteApi.removeDocumentFromFavorite(element.id);
		},
		onMutate: ({ favorite }) => {
			onMutate?.(favorite);
		},
		onSuccess: (res, { favorite }) => {
			onSuccess?.(favorite);
		},
		onError: (err) => MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" }),
	});

	const toggleFolderFromFavorite = useMutation({
		mutationFn: ({ favorite }: { favorite: boolean }) => {
			return favorite
				? favoriteApi.addFolderToFavorite({ folderId: element.id })
				: favoriteApi.removeFolderFromFavorite(element.id);
		},
		onMutate: ({ favorite }) => {
			onMutate?.(favorite);
		},
		onSuccess: (res, { favorite }) => {
			onSuccess?.(favorite);
		},
		onError: (err) => MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" }),
	});

	const isMutationLoading = toggleDocumentFromFavorite.isLoading || toggleFolderFromFavorite.isLoading;

	const startTimer = () => {
		timerRef.current = setTimeout(() => {
			if (isFolder) {
				toggleFolderFromFavorite.mutate({ favorite: !stillFavorite });
			} else {
				toggleDocumentFromFavorite.mutate({ favorite: !stillFavorite });
			}
		}, 3000);
	};
	const stopTimer = () => {
		clearTimeout(timerRef.current);
		timerRef.current = undefined;
	};

	return isMutationLoading ? (
		<div className="w-9 h-9 flex items-center">
			<CircularProgress color="secondary" size={iconSize ?? 20} />
		</div>
	) : (
		<Tooltip title={t("favorites.favorites")} enterDelay={100}>
			<IconButton
				sx={{ margin: "auto" }}
				onClick={(e) => {
					setStillFavorite((prev) => !prev);
					timerRef.current ? stopTimer() : startTimer();
					e.stopPropagation();
				}}
				className={className}
			>
				<CertiblokIcon name={stillFavorite ? "star_bold" : "star_outline"} size={iconSize ?? 20} color="secondary" />
			</IconButton>
		</Tooltip>
	);
};

export default ToggleFavoriteButton;
