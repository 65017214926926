import { TFunction } from "react-i18next";
import { Audit, Notification, ParticipationCompany, User } from "certiblok-api-manager";
import { getFullName } from "../../..";
import { CertiblokDocument } from "../../../interfaces/Document";
import { switchTitle } from "../../auditRooms/functions/switchTitle";
import { parseReminderOccurrenceString } from "../../globalCalendar/functions/parseReminderOccurrenceString";

export function getNotificationTitle(notification: Notification, t: TFunction): string {
	switch (notification.type) {
		case "event_accepted":
			return t("notifications.eventAccepted", {
				fullName: getFullName(notification.content.user as User),
				eventName: notification.content.eventName ?? "",
			});
		case "event_rejected":
			return t("notifications.eventRejected", {
				fullName: getFullName(notification.content.user as User),
				eventName: notification.content.eventName ?? "",
			});
		case "event_invitation":
			return t("notifications.eventInvitation", { fullName: getFullName(notification.content.user as User) });
		case "event_updated":
			return t("notifications.eventUpdated", {
				fullName: getFullName(notification.content.user as User),
				eventName: notification.content.eventName ?? "",
			});
		case "event_deleted":
			return t("notifications.eventDeleted", {
				fullName: getFullName(notification.content.user as User),
				eventName: notification.content.eventName ?? "",
			});
		case "event_reminder":
			return t("notifications.eventReminder", {
				eventName: notification.content.eventName ?? "",

				inTime: parseReminderOccurrenceString(
					t,
					notification.content.minutesUntilEvent as unknown as number,
					false
				).toLowerCase(),
			});
		case "storage":
			return t("notifications.storage");
		case "new_team_request":
			const reducedPersons = (notification.content.participations as ParticipationCompany[])?.reduce(
				(prev, current, index, arr) => {
					return index < 2
						? `${prev} ${current.owner?.name} ${current.owner?.surname}${index < 1 && arr.length > 1 ? "," : ""}`
						: prev;
				},
				""
			);
			const hasMoreThan2Persons = (notification.content.participations as ParticipationCompany[])?.length > 2;
			const otherPersons = (notification.content.participations as ParticipationCompany[])?.length - 2;
			const personsCount = (notification.content.participations as ParticipationCompany[]).length;
			return hasMoreThan2Persons
				? t("notifications.newTeamRequestWithOtherPeople", { members: reducedPersons, count: otherPersons })
				: t("notifications.newTeamRequest", { members: reducedPersons, count: personsCount });
		case "team_request_retired":
			return t("notifications.teamRequestRetired", {
				fullName: getFullName(notification.content.user as User),
			});
		case "upload_document_request":
			return t("notifications.uploadDocumentRequest", {
				fullName: getFullName(notification.content.user as User),
			});
		case "document_expiration":
			return t("notifications.documentExpiration", {
				documentName: (notification.content.document as CertiblokDocument)?.name ?? "",
			});
		case "new_shared_document":
			return t("notifications.newSharedDocument", { fullName: getFullName(notification.content.user as User) });
		case "new_shared_note_document":
			return t("notifications.newSharedDocumentWithNote", { fullName: getFullName(notification.content.user as User) });
		case "updated_document":
			return t("notifications.updatedDocument", {
				fullName: getFullName(notification.content.user as User),
				documentName: (notification.content.document as CertiblokDocument)?.name ?? "",
			});
		case "closed_audit_room":
			return t("notifications.closedAuditRoom", {
				fullName: getFullName(notification.content.auditor as User),
				auditName: (notification.content.auditRoom as Audit)?.name ?? "",
				status: switchTitle(t, (notification.content.auditRoom as Audit)?.status),
			});
		case "update_document_request":
			return t("notifications.updateDocumentRequest", {
				fullName: getFullName(notification.content.user as User),
				documentName: (notification.content.document as CertiblokDocument)?.name ?? "",
			});
		case "as_auditor":
			return t("notifications.asAuditor", {
				fullName: getFullName(notification.content.user as User),
				auditName: (notification.content.auditRoom as Audit)?.name ?? "",
			});
		case "expiring_audit_room":
			return t("notifications.expiringAuditRoom", {
				auditName: (notification.content.auditRoom as Audit)?.name ?? "",
			});
		case "new_documents_in_audit":
			return t("notifications.newDocumentsInAudit", {
				auditName: (notification.content.auditRoom as Audit)?.name ?? "",
			});
		case "document_modified":
			return t("notifications.documentModified", {
				fullName: getFullName(notification.content.user as User),
				documentName: (notification.content.oldDocument as CertiblokDocument)?.name ?? "",
				newDocumentName: (notification.content.newDocument as CertiblokDocument)?.name ?? "",
			});
		case "member_invitation_accepted":
			return t("notifications.memberInvitationAccepted", {
				fullName: getFullName(notification.content.user as User),
			});
		case "member_invitation_rejected":
			return t("notifications.memberInvitationRejected", {
				fullNameOrEmail: notification.content.user
					? getFullName(notification.content.user as User)
					: notification.content.email,
			});
		case "admin_invitation":
			return t("notifications.adminInvitation", {
				fullName: getFullName(notification.content.user as User),
				company: notification.content.companyName,
			});
		case "admin_invitation_accepted":
			return t("notifications.adminInviteAccepted", { fullName: getFullName(notification.content.user as User) });
		case "admin_invitation_rejected":
			return t("notifications.adminInviteRejected", {
				fullNameOrEmail: notification.content.user
					? getFullName(notification.content.user as User)
					: notification.content.email,
			});
		case "edit_room_document_access_request":
			return t("notifications.editRoomDocumentAccessRequest", {
				fullName: getFullName(notification.content.user as User),
			});
		case "document_chat_message": {
			return t("notifications.documentChatMessage", {
				fullName: getFullName(notification.content.user as User),
				documentName: (notification.content.document as CertiblokDocument)?.name ?? "",
			});
		}
		default:
			return t("notifications.noContent");
	}
}
