import { Avatar, Chip, ChipProps, SxProps, Theme, Tooltip } from "@mui/material";
import type { UserOrContact } from "certiblok-api-manager";
import React from "react";
import { backendMediaUrlParser } from "..";
import avatarPlaceholder from "../../assets/avatar-placeholder.png";
import { isUser } from "../../pages/Folders/components/UploadDocument/functions/isUser";
import CertiblokIcon from "./icons/CertiblokIcon";
import LinkBehavior from "./LinkBehavior";

type Props<T extends UserOrContact> = {
	user?: T | string;
	deletable: boolean;
	onDelete?: (user: T | string) => void;
	sx?: SxProps<Theme> | undefined;
	color?: "primary" | "secondary";
	isHref?: boolean;
	noIcon?: boolean;
} & ChipProps;

const UserChip = React.forwardRef<any, Props<any>>(
	({ user, deletable, onDelete, children, sx, color = "secondary", isHref, noIcon, ...otherProps }, ref) => {
		return (
			<Tooltip title={isUser(user) ? `${user.name} ${user.surname}` : user ?? ""}>
				<Chip
					{...(isHref && {
						component: LinkBehavior,
						href: `/contacts/${isUser(user) ? user.id : user}`,
					})}
					ref={ref}
					color={color}
					avatar={
						isUser(user) ? (
							<Avatar
								alt={`${user.name} ${user.surname} avatar`}
								src={isUser(user) && user.profileUrl ? backendMediaUrlParser(user.profileUrl) : avatarPlaceholder}
							/>
						) : undefined
					}
					icon={isUser(user) || noIcon ? undefined : <CertiblokIcon size={20} color="secondary" name={"mail_outline"} />}
					label={isUser(user) ? `${user.name} ${user.surname}` : user}
					onDelete={
						deletable
							? (e) => {
									e.stopPropagation();
									e.preventDefault();
									user && onDelete?.(user);
							  }
							: undefined
					}
					sx={{
						width: "100%",
						justifyContent: "space-between",
						textAlign: "start",
						cursor: isHref ? "pointer" : undefined,
						"& span": {
							flexGrow: 1,
						},

						...sx,
					}}
					{...otherProps}
				/>
			</Tooltip>
		);
	}
);

export default UserChip;
