import { alpha, Button, Tooltip, Typography } from "@mui/material";
import type { Folder } from "certiblok-api-manager";
import CertiblokIcon from "../../../../../utils/components/icons/CertiblokIcon";

type Props = { folder: Folder; onClick?: (e: React.MouseEvent) => void; className?: string };

const SingleFolderToChoose: React.FC<Props> = ({ folder, onClick, className }) => {
	return (
		<Tooltip title={folder.name} enterDelay={500}>
			<div className={className}>
				<Button
					fullWidth
					size="small"
					onClick={onClick}
					disabled={onClick === undefined}
					sx={{
						color: "black",
						backgroundColor: alpha(folder.color ?? "rgba(0,0,0,0)", 0.12),
						padding: 1,
						boxSizing: "border-box",
						gap: 2,
						borderRadius: 4,
						"&:hover": {
							backgroundColor: alpha(folder.color ?? "rgba(0,0,0,0)", 0.24),
						},
						"&.Mui-disabled": {
							opacity: 1,
						},
					}}
				>
					<CertiblokIcon size={20} color={folder.color} name={"folder_bold"} />
					<Typography
						children={folder.name}
						variant="body2"
						noWrap
						sx={{ opacity: 0.74, width: `calc(100% - ${20 + 4 * 2 + 8}px)`, textAlign: "start" }}
					/>
				</Button>
			</div>
		</Tooltip>
	);
};

export default SingleFolderToChoose;
