import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import IconDialog from "../../../../utils/components/dialogs/IconDialog";
import CertiblokIcon from "../../../../utils/components/icons/CertiblokIcon";
import LoadingTextButton from "../../../../utils/components/LoadingTextButton";
import {
	ModalNavigator,
	useModalNavigator,
	useModalRoute,
} from "../../../../utils/components/ModalNavigator/ModalNavigator";
import { useBetterMediaQuery } from "../../../../utils/hooks/useBetterMediaQuery";
import { useDeleteContactGroup, useDeleteContactGroups } from "../../hooks/useContactGroupsHooks";

const DeleteContactGroupModal = ModalNavigator.create(
	({ groupIds, onSuccess }: { groupIds: string[]; onSuccess?: () => void }) => {
		const { t } = useTranslation();
		const isOverMd = useBetterMediaQuery("md");

		const { visible } = useModalRoute();
		const { popModal } = useModalNavigator();

		const onClose = () => {
			popModal();
		};

		const onDeleteSuccess = () => {
			onSuccess?.();
			onClose();
		};

		const { mutate: deleteGroup, isLoading } = useDeleteContactGroup({ onSuccess: onDeleteSuccess });
		const { mutate: deleteGroups, isLoading: areLoading } = useDeleteContactGroups({ onSuccess: onDeleteSuccess });

		return (
			<IconDialog
				DialogProps={{}}
				open={visible}
				onClose={() => onClose()}
				icon="comunication_outline_01"
				IconProps={{ containerClassName: "bg-alert/[0.12]", iconColor: "warning" }}
				title={t("contactsGroups.deleteGroupTitle", { count: groupIds.length })}
				description={t("contactsGroups.deleteGroupDescription", { count: groupIds.length })}
				footer={
					<div className="flex justify-between items-center gap-2 md:pt-9">
						<Button onClick={popModal} color="secondary" size={isOverMd ? "large" : "small"}>
							<CertiblokIcon name="close" color="inherit" />
							{t("global.cancel")}
						</Button>
						<LoadingTextButton
							onClick={() => {
								if (groupIds.length > 1) {
									deleteGroups(groupIds);
								} else {
									deleteGroup(groupIds[0]);
								}
							}}
							className="min-w-[218px]"
							color="error"
							size={isOverMd ? "large" : "small"}
							variant="contained"
							loading={isLoading || areLoading}
						>
							{t("contactsGroups.deleteGroup", { count: groupIds.length })}
						</LoadingTextButton>
					</div>
				}
			/>
		);
	}
);

export default DeleteContactGroupModal;
