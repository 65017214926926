import { IconButton, useTheme, Box } from "@mui/material";
import { forwardRef, useState } from "react";

const availableColors = ["#FFF", "#000", "#C0C2CC"];

const DxfPreviewBackground = forwardRef<HTMLDivElement, any>((props, ref) => {
	const theme = useTheme();
	const [color, setColor] = useState(availableColors[0]);

	return (
		<div
			ref={ref}
			style={{
				backgroundColor: color,
			}}
			className="w-full h-full absolute transition-colors"
		>
			<div className="flex absolute bottom-4 right-4 bg-primary-a54 rounded-full">
				{availableColors.map((elem) => {
					return (
						<IconButton
							key={elem}
							onClick={() => {
								setColor(elem);
							}}
							sx={{
								color: elem,
								width: 48,
								height: 48,
							}}
						>
							<Box
								className="shadow-2"
								sx={{
									zIndex: 30,
									width: 24,
									height: 24,
									borderRadius: 4,
									backgroundColor: elem,
									borderWidth: 2,
									borderStyle: "solid",
									borderColor: elem === color ? theme.palette.primary.main : "#FFFFFF",
									transition: theme.transitions.create(["border-width", "border-color"]),
								}}
							></Box>
						</IconButton>
					);
				})}
			</div>
		</div>
	);
});

export default DxfPreviewBackground;
