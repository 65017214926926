import { useMutation } from "@tanstack/react-query";
import { InputEditProfilePreferredLanguageEnum, UserApi } from "certiblok-api-manager";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "usehooks-ts";
import { instanceApi } from "../hooks/api";
import { getPlatformLanguageFromI18n } from "../hooks/useLanguageSwitcher";
import { useMe } from "../hooks/useMe";

type LanguageSyncerProps = object;

export function useUpdateUserLanguage() {
	return useMutation({
		mutationFn: (language: InputEditProfilePreferredLanguageEnum) =>
			instanceApi(UserApi).editProfile({ preferredLanguage: language }),
	});
}

const LanguageSyncer: React.FC<LanguageSyncerProps> = () => {
	const { i18n } = useTranslation();
	const { me, reload } = useMe();
	const [, setSelectedLanguageId] = useLocalStorage("platform-language", getPlatformLanguageFromI18n(i18n));

	useEffect(() => {
		if (!me) return;
		if (me?.preferredLanguage) {
			i18n.changeLanguage(me.preferredLanguage);
			setSelectedLanguageId(me.preferredLanguage === "it" ? 0 : 1);
		} else {
			instanceApi(UserApi)
				.editProfile({ preferredLanguage: getPlatformLanguageFromI18n(i18n) === 0 ? "it" : "en" })
				.then(reload)
				.catch(() => {});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [me]);

	return null;
};

export default LanguageSyncer;
