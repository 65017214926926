import { Button, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Audit, AuditApi } from "certiblok-api-manager";
import { useAtom, useSetAtom } from "jotai";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { chunkRequests } from "../../../functions/chunkRequests";
import { instanceApi } from "../../../hooks/api";
import { useBetterMediaQuery } from "../../../hooks/useBetterMediaQuery";
import type { CertiblokDocument } from "../../../interfaces/Document";
import BottomSheet from "../../bottomSheet/BottomSheet";
import LoadingIconButton from "../../buttons/LoadingIconButton";
import DocumentWithIcon from "../../DocumentWithIcon";
import CertiblokIcon from "../../icons/CertiblokIcon";
import { ModalNavigator, useBottomSheet, useModalNavigator } from "../../ModalNavigator/ModalNavigator";
import MLDialog from "../../poppers";
import { alreadySelectedAuditRoomIdsAtom, selectedAuditRoomIdsAtom } from "./atoms/selectedAuditRoomIdsAtom";
import AuditRoomList from "./component/AuditRoomList";
import CreateAuditRoomBottomSheet from "./component/CreateAuditRoomBottomSheet";
import { useInfiniteAuditList } from "./queries/auditRoomsListInfiniteQuery";

type AssignToAuditRoomBSProps = {
	documents: CertiblokDocument[];
	onSuccess?: (selectedAuditRooms: Audit[]) => void;
	shouldMutate?: boolean;
	canRemoveAlreadyAssignedAudits?: boolean;
};

const AssignToAuditRoomBS = ModalNavigator.create<AssignToAuditRoomBSProps>(
	({ documents, onSuccess, shouldMutate, canRemoveAlreadyAssignedAudits }) => {
		const { t } = useTranslation();
		const isMd = useBetterMediaQuery("md");
		const { visible, bottomSheetProps } = useBottomSheet();
		const { popBottomSheet, pushBottomSheet } = useModalNavigator();

		const queryClient = useQueryClient();

		// const editDocumentDraft = useEditUploadedFileDraft();

		const [selectedAuditRoomIds, setSelectedAuditRoomIds] = useAtom(selectedAuditRoomIdsAtom);
		const setAlreadySelectedAuditRoomIds = useSetAtom(alreadySelectedAuditRoomIdsAtom);

		const { data: auditRoomsList } = useInfiniteAuditList();

		const initializeBS = useCallback(() => {
			if (canRemoveAlreadyAssignedAudits) {
				if (documents.length === 1) {
					const document = documents[0];
					setSelectedAuditRoomIds(document.audits?.map((audit) => audit.id) ?? []);
				} else {
					setSelectedAuditRoomIds([]);
				}
				setAlreadySelectedAuditRoomIds([]);
			} else {
				setSelectedAuditRoomIds([]);
				if (documents.length === 1) {
					const document = documents[0];
					setAlreadySelectedAuditRoomIds(document.audits?.map((audit) => audit.id) ?? []);
				} else {
					setAlreadySelectedAuditRoomIds([]);
				}
			}
		}, [canRemoveAlreadyAssignedAudits, documents, setAlreadySelectedAuditRoomIds, setSelectedAuditRoomIds]);

		const assignDocumentToAuditRoomMutation = useMutation({
			mutationFn: (selectedAuditRooms: Audit[]) => {
				return chunkRequests(
					10,
					(auditRoom) => {
						const isToRemove = !selectedAuditRooms.some((elem) => elem.id === auditRoom?.id);
						const documentIds = documents.map((elem) => elem.id);
						return instanceApi(AuditApi).editAuditRoom(auditRoom.id, {
							documentIds: [
								...(auditRoom.documentIds ?? []).filter((documentId) => !documentIds.includes(documentId)),
								...(isToRemove ? [] : documentIds),
							],
						});
					},
					[
						...selectedAuditRooms,
						...(canRemoveAlreadyAssignedAudits && documents.length === 1 ? documents[0].audits ?? [] : []),
					]
				);
			},
			onSuccess: (res, selectedAuditRooms) => {
				if (res.chunkedErrors.length > 0) {
					MLDialog.showSnackbar(t("auditRoomBS.failedAssignment", { count: res.chunkedErrors.length }), {
						variant: "error",
					});
				}
				onSuccess?.(selectedAuditRooms);
				onClose();
			},
		});

		useEffect(() => {
			initializeBS();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [documents, canRemoveAlreadyAssignedAudits]);

		const onClose = useCallback(() => {
			if (assignDocumentToAuditRoomMutation.isLoading) return;
			popBottomSheet();
			initializeBS();
		}, [assignDocumentToAuditRoomMutation.isLoading, initializeBS, popBottomSheet]);

		return (
			<BottomSheet
				open={visible}
				onClose={onClose}
				{...bottomSheetProps}
				header={
					<Typography
						children={t("folders.assignToAuditRoom_one")}
						sx={{
							typography: {
								xs: "subtitle2",
								md: "subtitle1",
							},
						}}
					/>
				}
				footer={
					<div className="flex items-center justify-between gap-6">
						<Button
							onClick={onClose}
							children={t("global.cancel")}
							variant="outlined"
							color="secondary"
							disabled={assignDocumentToAuditRoomMutation.isLoading}
							startIcon={<CertiblokIcon name="close" color="inherit" size={24} />}
						/>
						<LoadingIconButton
							children={isMd ? t("auditRoomBS.insertInAuditRoom") : t("global.insert")}
							color="secondary"
							variant="contained"
							onClick={() => {
								if (shouldMutate) {
									assignDocumentToAuditRoomMutation.mutate(
										auditRoomsList?.pages.reduce((acc, currentPage) => {
											return [
												...acc,
												...(currentPage.data ?? []).filter((auditRoom) => selectedAuditRoomIds.includes(auditRoom.id)),
											];
										}, [] as Audit[]) ?? []
									);
									// editDocumentDraft(documentId, {
									// 	auditRooms:
									// 		auditRoomsList?.pages.reduce((acc, currentPage) => {
									// 			return [
									// 				...acc,
									// 				...(currentPage.data ?? []).filter((auditRoom) => selectedAuditRoomIds.includes(auditRoom.id)),
									// 			];
									// 		}, [] as Audit[]) ?? [],
									// });
								} else {
									onSuccess?.(
										auditRoomsList?.pages.reduce((acc, currentPage) => {
											return [
												...acc,
												...(currentPage.data ?? []).filter((auditRoom) => selectedAuditRoomIds.includes(auditRoom.id)),
											];
										}, [] as Audit[]) ?? []
									);
									popBottomSheet();
								}
							}}
							loading={assignDocumentToAuditRoomMutation.isLoading}
							className="flex-grow md:flex-grow-0"
							icon={<CertiblokIcon size={24} color="inherit" name={"success"} />}
						/>
					</div>
				}
			>
				<div className="px-5 md:px-12">
					<div className="flex gap-2">
						{documents.map((document) => (
							<DocumentWithIcon name={document.name} key={document.id} document={document.lastVersionFile?.[0]} />
						))}
					</div>
					<div className="flex justify-between flex-wrap md:flex-nowrap items-center pt-4 md:pt-6 gap-3 pb-2 md:pb-6">
						<p className="text-label">{t("auditRoomBS.selectAuditRoom_one")}</p>
						<Button
							color="secondary"
							size="small"
							onClick={() => {
								pushBottomSheet({
									component: CreateAuditRoomBottomSheet,
									options: {
										shouldHidePrev: false,
									},
									ref: bottomSheetProps.ref.current,
								}).then((auditRoom) => {
									queryClient.invalidateQueries(["auditRooms", "list"]);
								});
							}}
							startIcon={<CertiblokIcon size={24} color="inherit" name={"add"} />}
						>
							{t("auditRooms.create")}
						</Button>
					</div>
					<AuditRoomList />
				</div>
			</BottomSheet>
		);
	}
);
export default AssignToAuditRoomBS;
