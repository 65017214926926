import { useRef, useEffect } from "react";
import { useBottomSheet, useModalNavigator } from "../../../../../utils/components/ModalNavigator/ModalNavigator";
import { useBetterMediaQuery } from "../../../../../utils/hooks/useBetterMediaQuery";

export const useModalManagement = () => {
	const isMd = useBetterMediaQuery("md");
	const { visible, bottomSheetProps } = useBottomSheet();
	const { popBottomSheet, openedBottomSheetRefs } = useModalNavigator();

	const prevIsMdValue = useRef(isMd);

	useEffect(() => {
		const bottomSheets = openedBottomSheetRefs.current;
		if (!prevIsMdValue.current && isMd && visible) {
			if (bottomSheets.length > 0) {
				bottomSheets[bottomSheets.length - 1].bringToFront();
			}
		}
		prevIsMdValue.current = isMd;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMd]);
	return {
		bottomSheetProps,
		popBottomSheet,
		visible,
	};
};
