import { UserApi } from "certiblok-api-manager";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MLDialog from "../components/poppers";
import { useApi } from "./api";
import { useResetAbilities } from "./useAbilityRules";

function useLogout() {
	const { t } = useTranslation();

	const userApi = useApi(UserApi);
	const navigate = useNavigate();
	const resetAbilities = useResetAbilities();

	return useCallback(async () => {
		try {
			await userApi.logout();
			resetAbilities();
			localStorage.clear();
			navigate("/login", { replace: true });
		} catch (err) {
			return MLDialog.showSnackbar(t("global.somethingWentWrong"), { variant: "error" });
		}
	}, [navigate, resetAbilities, t, userApi]);
}

export default useLogout;
