export const treeViewQueryKeys = {
	base: ["treeView"],
	root: (rootFolderId: string, params: { page: number; perPage: number }, otherFilters: { [key: string]: string }) => [
		...treeViewQueryKeys.base,
		"root",
		rootFolderId,
		params.page,
		params.perPage,
		otherFilters,
	],
	folder: {
		base: () => [...treeViewQueryKeys.base, "folder"],
		byFolderId: (folderId: string) => [...treeViewQueryKeys.folder.base(), folderId],
		withPagination: (params: {
			folderId: string;
			page: number;
			perPage: number;
			otherFilters: { [key: string]: string };
		}) => [...treeViewQueryKeys.base, "folder", params.folderId, params.page, params.perPage, params.otherFilters],
	},
};
