import { useMutation } from "@tanstack/react-query";
import { DocumentApi, QrcodeApi } from "certiblok-api-manager";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import MLDialog from "../../../../../../../utils/components/poppers";
import { chunkRequests } from "../../../../../../../utils/functions/chunkRequests";
import { useApi } from "../../../../../../../utils/hooks/api";
import { CertiblokDocument } from "../../../../../../../utils/interfaces/Document";

export const useDownloadMultipleQr = ({ onMutate }: { onMutate?: () => void }) => {
	const { t } = useTranslation();
	const documentApi = useApi(DocumentApi);
	const qrApi = useApi(QrcodeApi);

	return useMutation({
		mutationFn: async ({ documents, type }: { documents: CertiblokDocument[]; type: "image" | "pdf" }) => {
			const { chunkedSuccessfully, chunkedErrors } = await chunkRequests(
				10,
				async (item) => {
					if (type === "image") {
						const qrCodeImageBlob = await documentApi.downloadDocumentQr(item.id, { responseType: "blob" });
						const url = window.URL.createObjectURL(qrCodeImageBlob.data);
						const link = window.document.createElement("a");
						link.href = url;
						link.download = `QRCode_${item.name}_${DateTime.now().toMillis()}.png`;
						link.click();
					} else {
						const qrCodePdfBlob = await qrApi.downloadQrCodePdf(item.id, { responseType: "blob" });
						const url = window.URL.createObjectURL(qrCodePdfBlob.data);
						const link = window.document.createElement("a");
						link.href = url;
						link.download = `QRCode_${item.name}_${DateTime.now().toMillis()}.pdf`;
						link.click();
					}
				},
				documents
			);
			if (chunkedSuccessfully.length > 0) {
				MLDialog.showSnackbar(t("qrCode.downloadedSuccessfully", { count: chunkedSuccessfully.length }), {
					variant: "success",
				});
			}
			if (chunkedErrors.length > 0) {
				MLDialog.showSnackbar(t("qrCode.downloadedWithErrors", { count: chunkedErrors.length }), { variant: "error" });
			}
		},
		onMutate: ({ documents }) => {
			MLDialog.showSnackbar(t("qrCode.downloadingQR", { count: documents.length }));
			onMutate?.();
		},
	});
};
