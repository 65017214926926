/** @jsxImportSource @emotion/react */
import { Box, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import type { Folder } from "certiblok-api-manager";
import { DateTime } from "luxon";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { backendMediaUrlParser } from "../../../utils";
import AvatarWithName from "../../../utils/components/AvatarWithName";
import BetterCheckbox from "../../../utils/components/BetterCheckbox";
import DocumentMenu from "../../../utils/components/DocumentComponents/DocumentMenu";
import ExpiringProgress from "../../../utils/components/ExpiringProgress";
import CertiblokIcon from "../../../utils/components/icons/CertiblokIcon";
import FileIcon from "../../../utils/components/icons/FileIcon";
import type { IHeadCell } from "../../../utils/components/tables/AdminTable";
import CombinedLinkCell from "../../../utils/components/tables/cells/CombinedLinkCell";
import TouchTargetCell from "../../../utils/components/tables/cells/TouchTargetCell";
import { validityPercentage } from "../../../utils/functions/validity";
import type { CertiblokDocument } from "../../../utils/interfaces/Document";
import ToggleFavoriteButton from "../../Favorites/components/ToggleFavoriteButton";
import { useFavoriteContext } from "../../Favorites/controllers/FavoriteContext";
import { favoriteQueryKeyFactory } from "../../Favorites/controllers/favoriteQuery";
import TagsTableCell from "../../SingleDocument/components/Tags/TagsTableCell";
import { AllDocumentsContext } from "../controllers/AllDocumentsContext";

type Props = {
	document: CertiblokDocument;
	headCells: IHeadCell[];
	checked: boolean;
	onSelect: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	fromFavorite?: boolean;
	shouldShowOptionButton?: boolean;
};

const AllDocumentsTableCell: React.FC<Props> = ({
	document,
	headCells,
	onSelect,
	checked,
	fromFavorite,
	shouldShowOptionButton,
}) => {
	const ownerColSpan = headCells[2]?.colSpan ?? 0;
	const variantColSpan = headCells[3]?.colSpan ?? 0;
	const expireDateColSpan = headCells[4]?.colSpan ?? 0;
	const validityColSpan = headCells[5]?.colSpan ?? 0;
	const acquisitionDateColSpan = headCells[6]?.colSpan ?? 0;
	const tagsColSpan = headCells[7]?.colSpan ?? 0;

	const allDocumentsContext = useContext(AllDocumentsContext);
	const favoriteContext = useFavoriteContext();
	const queryClient = useQueryClient();

	const { t } = useTranslation();

	return (
		<TableRow
			sx={{
				cursor: "unset !important",
				"&:hover": {
					backgroundColor: "unset !important",
				},
			}}
		>
			<TouchTargetCell>
				<BetterCheckbox
					color="secondary"
					size="small"
					sx={{ width: 32, height: 32 }}
					checked={checked}
					onClick={onSelect}
				/>
			</TouchTargetCell>
			<CombinedLinkCell
				colSpans={headCells.slice(0, 2).map((elem) => elem.colSpan ?? 1)}
				widths={headCells.slice(0, 2).map((elem) => elem.width)}
				href={fromFavorite ? `../all_documents/document/${document.id}` : `./document/${document.id}`}
				sx={{ gap: 5 }}
			>
				<FileIcon
					file={document.lastVersionFile?.[0]}
					dataUrl={backendMediaUrlParser(document?.lastVersionFile?.[0]?.url)}
				/>
				<Tooltip title={document.name} enterDelay={500}>
					<Typography variant="body2" noWrap children={`${document.name}`} />
				</Tooltip>
			</CombinedLinkCell>
			{ownerColSpan > 0 && (
				<TableCell colSpan={ownerColSpan} sx={{ padding: "0px !important" }}>
					<AvatarWithName
						user={document?.lastVersionFile?.[0]?.owner}
						variant="body2"
						isHref
						typographySx={{ opacity: 1 }}
						sx={{ padding: { xs: 2, lg: 3 } }}
					/>
				</TableCell>
			)}
			{variantColSpan > 0 && (
				<TableCell colSpan={variantColSpan}>
					<Typography variant="overline" children={`V${document?.lastVersionFile?.[0]?.version}`} />
				</TableCell>
			)}
			{expireDateColSpan > 0 && (
				<TableCell colSpan={expireDateColSpan}>
					{document.noExpiration ? (
						<Tooltip title={t("variousComponents.noExpiring") as string} enterDelay={500}>
							<Typography
								className="px-2 py-1 bg-[rgba(67,169,191,0.12)] text-center rounded-2xl truncate"
								variant="caption"
								component="p"
								children={t("variousComponents.noExpiring")}
							/>
						</Tooltip>
					) : (
						document.expireDate && (
							// <Typography variant="caption" children={moment(document.expireDate).format("DD/MM/YYYY")} />
							<ExpiringProgress
								percentage={Math.round(validityPercentage(DateTime.fromISO(document.expireDate)))}
								sx={{ justifyContent: "center" }}
							/>
						)
					)}
				</TableCell>
			)}
			{validityColSpan > 0 && (
				<TableCell colSpan={validityColSpan}>
					{document.parentFolder && (
						<Tooltip title={document.parentFolder.name} enterDelay={500}>
							<Box sx={{ width: "100%", display: "flex", alignItems: "center", gap: 1 }}>
								<CertiblokIcon size={20} color={document.parentFolder.color} name={"folder_outline"} />
								<Typography children={document.parentFolder.name} variant="caption" noWrap />
							</Box>
						</Tooltip>
					)}
				</TableCell>
			)}
			{acquisitionDateColSpan > 0 && (
				<TableCell colSpan={acquisitionDateColSpan}>
					{document.lastVersionFile?.[0]?.createdAt && (
						<Typography
							variant="caption"
							children={DateTime.fromISO(document.lastVersionFile?.[0]?.createdAt).toFormat("dd/MM/yyyy")}
						/>
					)}
				</TableCell>
			)}
			{tagsColSpan > 0 && <TagsTableCell colSpan={tagsColSpan} tags={document.tags} />}
			{fromFavorite && (
				<TouchTargetCell>
					<ToggleFavoriteButton
						element={document}
						isFolder={false}
						onMutate={() => {
							queryClient.setQueryData<AxiosResponse<Folder[]>>(
								favoriteQueryKeyFactory.documents.filtered(favoriteContext.queryFilter, favoriteContext.textFilter),
								(old) => {
									if (!old) {
										return old;
									}
									return {
										...old,
										data: old.data?.filter((documents) => documents.id !== document.id),
									};
								}
							);
						}}
						onSuccess={() => {
							queryClient.invalidateQueries(favoriteQueryKeyFactory.documents.base());
						}}
					/>
				</TouchTargetCell>
			)}
			<TouchTargetCell>
				<DocumentMenu
					document={document}
					onSuccess={({ action, document: resDocument }) => {
						if (
							action === "expirationDate" &&
							(Array.isArray(document)
								? document.every((doc) => doc.expireDate === undefined)
								: document.expireDate === undefined)
						) {
							return;
						}
						if (fromFavorite) {
							queryClient.invalidateQueries(favoriteQueryKeyFactory.documents.base());
						} else {
							allDocumentsContext.revalidateDocuments();
						}
					}}
					onActionComplete={() => {
						if (fromFavorite) {
							queryClient.invalidateQueries(favoriteQueryKeyFactory.documents.base());
						} else {
							allDocumentsContext.revalidateDocuments();
						}
					}}
					actionsToRemove={["favorite"]}
					shouldShowOptionButton={shouldShowOptionButton}
				/>
			</TouchTargetCell>
		</TableRow>
	);
};

export default AllDocumentsTableCell;
