/** @jsxImportSource @emotion/react */
import { Box, Button, ButtonProps, Tooltip } from "@mui/material";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import en_flag from "../../assets/flags/english.png";
import it_flag from "../../assets/flags/ita.png";
import ActionButton from "./buttons/ActionButton";
import CertiblokIcon from "./icons/CertiblokIcon";
import { ModalNavigator, useModalNavigator, useModalRoute } from "./ModalNavigator/ModalNavigator";
import RowMenu from "./poppers/RowMenu";

export type SupportedLanguagesType = "it" | "en";

const supportedLanguages: SupportedLanguagesType[] = ["it", "en"];

const getDataFromLanguage = (language: SupportedLanguagesType) => {
	switch (language) {
		case "it":
			return { image: it_flag, description: "global.translated-italian" };
		case "en":
			return { image: en_flag, description: "global.translated-english" };
		default:
			return { image: it_flag, description: "global.translated-italian" };
	}
};

const LanguageChangePopoverMenu = ModalNavigator.create(
	({
		anchorRef,
		onLanguageChange,
		buttonColor,
	}: {
		anchorRef: HTMLElement | null;
		onLanguageChange: (newLang: SupportedLanguagesType) => void;
		buttonColor?: ButtonProps["color"];
	}) => {
		const { visible } = useModalRoute();
		const { popModal } = useModalNavigator();
		const { t } = useTranslation();

		return (
			<RowMenu open={visible} onClose={() => popModal()} anchorEl={anchorRef}>
				<Box
					sx={{
						boxSizing: "border-box",
						backgroundColor: "surface.main",
						display: "flex",
						flexDirection: "column",
						padding: 1,
						borderRadius: 3,
					}}
				>
					{supportedLanguages.map((lang) => {
						return (
							<ActionButton
								color={buttonColor ?? "secondary"}
								onClick={() => {
									onLanguageChange(lang);
									popModal();
								}}
								key={getDataFromLanguage(lang).description}
								sx={{
									color: buttonColor === "error" ? "red" : "inherit",
									gap: "12px",
								}}
							>
								<img
									src={getDataFromLanguage(lang).image}
									// @ts-ignore
									alt={t(getDataFromLanguage(lang).description ?? "global.italian")}
									css={{ width: 22, height: 16, borderRadius: 2 }}
								/>
								{/* @ts-ignore */}
								{t(getDataFromLanguage(lang).description)}
							</ActionButton>
						);
					})}
				</Box>
			</RowMenu>
		);
	}
);

const LanguageSelector = ({
	language,
	onLanguageChange,
	buttonColor,
}: {
	language: SupportedLanguagesType;
	onLanguageChange: (newLang: SupportedLanguagesType) => void;
	buttonColor?: ButtonProps["color"];
}) => {
	const { t } = useTranslation();
	const { pushModal } = useModalNavigator();
	const anchorElRef = useRef<HTMLButtonElement>(null);

	return (
		<Tooltip title={t("request.languageSelectorTooltip")} enterDelay={300}>
			<Button
				color={buttonColor ?? "secondary"}
				onClick={() =>
					pushModal(LanguageChangePopoverMenu, {
						anchorRef: anchorElRef.current,
						onLanguageChange: onLanguageChange,
						buttonColor: buttonColor,
					})
				}
			>
				<img
					src={getDataFromLanguage(language).image}
					// @ts-ignore
					alt={t(getDataFromLanguage(language).description ?? "global.italian")}
					css={{ width: 22, height: 16, borderRadius: 2 }}
				/>
				<CertiblokIcon size={24} color="black" name={"▾-arrow-3"} ref={anchorElRef} />
			</Button>
		</Tooltip>
	);
};

export default LanguageSelector;
