import { TableCell, Typography } from "@mui/material";
import { Tag } from "certiblok-api-manager";
import SimpleTooltip from "../../../../utils/components/tooltips/SimpleTooltip";
import SingleTag from "./SingleTag";

const TagsTableCell = ({ colSpan, tags }: { colSpan: number; tags?: Tag[] }) => {
	return (
		<TableCell colSpan={colSpan}>
			{(tags?.length ?? 0) > 0 && (
				<SimpleTooltip
					enterDelay={500}
					arrow
					title={
						<div className="box-border p-3 flex flex-col gap-3 max-h-[250px] overflow-y-auto">
							{(tags ?? []).map((t) => (
								<SingleTag size={"medium"} tag={t} key={t.id} className="box-border max-w-full w-fit" />
							))}
						</div>
					}
				>
					<div className="w-fit">
						{tags && (tags ?? []).length > 0 && (
							<div className="flex items-center gap-1">
								<SingleTag hideTooltip tag={tags[0]} size={"small"} className="flex-shrink min-w-0 w-auto" />
								{tags.length > 1 && (
									<Typography variant="caption" color="rgba(0,0,0,0.6)" className="text-[11px]">{` +${
										tags.length - 1
									}`}</Typography>
								)}
							</div>
						)}
					</div>
				</SimpleTooltip>
			)}
		</TableCell>
	);
};

export default TagsTableCell;
