import { Button, Typography } from "@mui/material";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import {
	ModalNavigator,
	useModalNavigator,
	useModalRoute,
} from "../../../../../utils/components/ModalNavigator/ModalNavigator";
import ResponsiveDialog from "../../../../../utils/components/dialogs/ResponsiveDialog";
import CertiblokIcon from "../../../../../utils/components/icons/CertiblokIcon";

const AddVideoConferenceModal = ModalNavigator.create(() => {
	const { t } = useTranslation();
	const { visible } = useModalRoute();
	const { popModal } = useModalNavigator();

	return (
		<ResponsiveDialog
			open={visible}
			onClose={popModal}
			footer={
				<div className="flex justify-center w-full">
					<Button variant="outlined" color="secondary" onClick={popModal}>
						<CertiblokIcon name="close" color="inherit" />
						{t("global.close")}
					</Button>
				</div>
			}
			ModalProps={{
				responsive: true,
			}}
		>
			<div className="px-6 md:px-0 flex flex-col items-center md:pb-12">
				<CertiblokIcon
					name="video_outline"
					color="secondary"
					size={48}
					className="p-3 rounded-full bg-secondary/[0.12] mb-6"
				/>
				<Typography variant="h4" className="text-center mb-2">
					{t("calendar.automaticVideoConferenceCreation")}
				</Typography>
				<Typography variant="h6" className="text-center text-black/[0.74]">
					{t("calendar.automaticVideoConferenceCreationDescription")}
				</Typography>
			</div>
		</ResponsiveDialog>
	);
});

type AddVideoConferenceButtonProps = {
	className?: string;
};

const AddVideoConferenceButton: React.FC<AddVideoConferenceButtonProps> = ({ className }) => {
	const { t } = useTranslation();
	const { pushModal } = useModalNavigator();

	return (
		// <div className={twMerge(clsx("flex items-center bg-primary-a12 rounded-xl p-2 gap-4", className))}>
		// 	{/* <div className="bg-primary-a12 rounded-lg flex items-center justify-center w-8 h-8">
		// 		<CertiblokIcon name="comunication_outline_02" color="black" />
		// 	</div>
		// 	<Typography variant="label" className="text-black">
		// 		{t("event.videoConferenceWillBeAutomaticallyCreated")}
		// 	</Typography> */}
		// </div>
		<Button
			color="secondary"
			onClick={() => {
				pushModal(AddVideoConferenceModal, {}, { shouldHidePrev: true });
			}}
			variant="contained"
			className={twMerge(clsx("w-fit", className))}
		>
			{t("calendar.addCertiblokVideoConference")}
		</Button>
	);
};

export default AddVideoConferenceButton;
