import type { ButtonProps } from "@mui/material";
import type React from "react";
import ActionButton from "../../../../../utils/components/buttons/ActionButton";
import CertiblokIcon, { CertiblokIconNames } from "../../../../../utils/components/icons/CertiblokIcon";

interface PopupButtonProps extends ButtonProps {
	icon: CertiblokIconNames;
	component?: string;
	target?: string;
	rel?: string;
}

export const DocumentPopupButton: React.FC<PopupButtonProps> = ({ icon, children, sx, ...props }) => {
	return (
		<ActionButton
			onClick={(event) => {
				event.stopPropagation();
				props.onClick?.(event);
			}}
			color="secondary"
			fullWidth
			sx={{ gap: 2, justifyContent: "start", borderRadius: 2, ...sx }}
			{...props}
		>
			<CertiblokIcon size={24} color={"inherit"} name={icon} />
			{children}
		</ActionButton>
	);
};
