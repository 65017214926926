import { Button } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { DocumentApi } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import { apiErrorParser, instanceApi } from "../../hooks/api";
import { useBetterMediaQuery } from "../../hooks/useBetterMediaQuery";
import type { CertiblokDocument } from "../../interfaces/Document";
import LoadingIconButton from "../buttons/LoadingIconButton";
import IconDialog from "../dialogs/IconDialog";
import CertiblokIcon from "../icons/CertiblokIcon";
import { ModalNavigator, useModalNavigator, useModalRoute } from "../ModalNavigator/ModalNavigator";
import MLDialog from "../poppers";

type DeleteDocumentDialogProps = {
	document: CertiblokDocument | CertiblokDocument[];
	onSuccess?: (document: CertiblokDocument | CertiblokDocument[]) => void;
};

const DeleteDocumentDialog = ModalNavigator.create<DeleteDocumentDialogProps>(({ document, onSuccess }) => {
	const { t } = useTranslation();
	const { visible } = useModalRoute();
	const { popModal, dismissModal } = useModalNavigator();
	const isMd = useBetterMediaQuery("md");

	const deleteFolderMutation = useMutation({
		mutationFn: () => {
			return instanceApi(DocumentApi).deleteOneOrMoreDocuments({
				toDeleteIds: Array.isArray(document) ? document.map((item) => item.id) : [document.id],
			});
		},
		onSuccess: () => {
			MLDialog.showSnackbar(
				t("documents.documentDeletionInProgress", { count: Array.isArray(document) ? document.length : 1 }),
				{ variant: "success" }
			);
			dismissModal();
			onSuccess?.(document);
		},
		onError: (err) => {
			MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
		},
	});

	const onClose = () => {
		if (deleteFolderMutation.isLoading) return;
		popModal();
	};

	return (
		<IconDialog
			open={visible}
			onClose={onClose}
			icon="comunication_outline_01"
			IconProps={{
				iconColor: "orange",
				containerClassName: "bg-[rgba(255,149,0,0.12)]",
			}}
			DialogProps={{
				className: "flex flex-col items-center",
			}}
			title={t("global.attention")}
			description={t("documents.deleteDocuments", { count: Array.isArray(document) ? document.length : 1 })}
			DescriptionProps={{ className: "text-center" }}
			ModalProps={{
				responsive: false,
				containerSx: {
					maxWidth: 440,
				},
			}}
			footer={
				<div className="flex items-center justify-between gap-2 md:pt-9">
					<Button
						disabled={deleteFolderMutation.isLoading}
						onClick={onClose}
						size={isMd ? "large" : "small"}
						children={
							<>
								<CertiblokIcon size={24} color="inherit" name={"close"} />
								{t("global.cancel")}
							</>
						}
					/>
					<LoadingIconButton
						loading={deleteFolderMutation.isLoading}
						onClick={() => {
							deleteFolderMutation.mutate();
						}}
						icon={<CertiblokIcon size={24} color="inherit" name={"delete_bold"} />}
						color="error"
						variant="contained"
						size={isMd ? "large" : "small"}
						children={t("global.moveToBin")}
					/>
				</div>
			}
		/>
	);
});
export default DeleteDocumentDialog;
