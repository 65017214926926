import React, { ReactNode } from "react";
import errorIllustration from "../../assets/main_illustrations/find.png";
import * as Sentry from "@sentry/react";

const ErrorBoundary = ({ children }: { children: ReactNode }) => {
	return (
		<Sentry.ErrorBoundary
			fallback={
				<div
					style={{
						width: "100%",
						height: "100%",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						backgroundColor: "#ffffff",
						borderRadius: "48px 48px 0px 0px",
					}}
				>
					<img src={errorIllustration} alt="Something went wrong" style={{ width: 528, marginBottom: 20 }} />
					<h6 style={{ fontFamily: '"Nunito Sans"', fontWeight: 700, fontSize: 20, letterSpacing: "0.75px", margin: 0 }}>
						Qualcosa è andato storto
					</h6>
					<p style={{ fontFamily: '"Nunito Sans"', fontWeight: 400, fontSize: 16, letterSpacing: "0.5px", margin: 0 }}>
						Non è possibile concludere l'azione richiesta
					</p>
					<a
						href="/"
						style={{
							fontFamily: '"Nunito Sans"',
							fontWeight: 400,
							fontSize: 16,
							letterSpacing: "0.5px",
							margin: 0,
							backgroundColor: "#52BD8F",
							color: "#ffffff",
							textDecoration: "none",
							padding: "8px 12px",
							borderRadius: "10px",
							marginTop: 12,
						}}
					>
						Torna indietro
					</a>
				</div>
			}
		>
			{children}
		</Sentry.ErrorBoundary>
	);
};

// class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
// 	constructor(props: any) {
// 		super(props);
// 		this.state = { hasError: false };
// 	}

// 	static getDerivedStateFromError(error: any) {
// 		// Update state so the next render will show the fallback UI.
// 		return { hasError: true };
// 	}

// 	componentDidCatch(error: any, errorInfo: any) {
// 		// You can also log the error to an error reporting services
// 		console.log("ERROR", error, errorInfo);
// 	}

// 	render() {
// 		if (this.state.hasError) {
// 			return (
// 				<div
// 					style={{
// 						width: "100%",
// 						height: "100%",
// 						display: "flex",
// 						flexDirection: "column",
// 						alignItems: "center",
// 						justifyContent: "center",
// 						backgroundColor: "#ffffff",
// 						borderRadius: "48px 48px 0px 0px",
// 					}}
// 				>
// 					<img src={errorIllustration} alt="Something went wrong" style={{ width: 528, marginBottom: 20 }} />
// 					<h6 style={{ fontFamily: '"Nunito Sans"', fontWeight: 700, fontSize: 20, letterSpacing: "0.75px", margin: 0 }}>
// 						Qualcosa è andato storto
// 					</h6>
// 					<p style={{ fontFamily: '"Nunito Sans"', fontWeight: 400, fontSize: 16, letterSpacing: "0.5px", margin: 0 }}>
// 						Non è possibile concludere l'azione richiesta
// 					</p>
// 					<a
// 						href="/"
// 						style={{
// 							fontFamily: '"Nunito Sans"',
// 							fontWeight: 400,
// 							fontSize: 16,
// 							letterSpacing: "0.5px",
// 							margin: 0,
// 							backgroundColor: "#52BD8F",
// 							color: "#ffffff",
// 							textDecoration: "none",
// 							padding: "8px 12px",
// 							borderRadius: "10px",
// 							marginTop: 12,
// 						}}
// 					>
// 						Torna indietro
// 					</a>
// 				</div>
// 			);
// 		}

// 		return this.props.children;
// 	}
// }

export default ErrorBoundary;
