import { Dispatch, SetStateAction, useCallback, useEffect, useRef } from "react";

import queryString from "querystring";
import { useLocation, useSearchParams } from "react-router-dom";
import type { IQueryFilter } from "../components/tables/AdminTable";

function usePathQueryUpdater(queryFilterHook: [IQueryFilter, Dispatch<SetStateAction<IQueryFilter>>]) {
	const location = useLocation();

	const isStartedOnce = useRef(false);

	const [searchParams, setSearchParams] = useSearchParams();

	const updateQueryParams = useCallback(
		(newParams: IQueryFilter) => {
			searchParams.set("queryFilter", JSON.stringify(newParams as any));
			setSearchParams(searchParams, { replace: true });
		},
		[searchParams, setSearchParams]
	);

	const lastQueryFilter = useRef(queryFilterHook[0]);

	useEffect(() => {
		lastQueryFilter.current = queryFilterHook[0];
		if (!lastQueryFilter.current) return;
		if (!isStartedOnce.current) {
			const search = (location?.search || "").replace("?", "");
			const tmpParseQuery: any = queryString.parse(search);
			const parsedQuery: any = JSON.parse(tmpParseQuery.queryFilter || "{}");

			if (parsedQuery?.perPage) {
				const newQueryFilter = {
					...parsedQuery,
					page: parseInt(parsedQuery.page),
					perPage: parseInt(parsedQuery.perPage),
				};
				queryFilterHook[1](newQueryFilter);
			}

			isStartedOnce.current = true;
		}
		const timeout = setTimeout(() => {
			updateQueryParams(lastQueryFilter.current);
		}, 300);
		return () => clearTimeout(timeout);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryFilterHook[0]]);
}

export default usePathQueryUpdater;
