import { alpha, Box, Breadcrumbs, Button, Chip, Tooltip, Typography, useTheme } from "@mui/material";
import type { Folder } from "certiblok-api-manager";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import DocumentWithIcon from "../../../../../utils/components/DocumentWithIcon";
import CertiblokIcon from "../../../../../utils/components/icons/CertiblokIcon";
import { MoveDocumentContext } from "../controllers/MoveDocumentContext";

type Props = {
	onSuccessClick?: (newFolder: Folder) => void;
	buttonText?: string;
};

export const MoveDocumentSuccessFooter: React.FC<Props> = ({ buttonText, onSuccessClick }) => {
	const moveDocumentContext = useContext(MoveDocumentContext);

	return (
		<Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
			<Button
				variant="contained"
				color="secondary"
				disabled={moveDocumentContext.selectedFolder === undefined}
				onClick={() => {
					if (moveDocumentContext.selectedFolder) {
						onSuccessClick?.(moveDocumentContext.selectedFolder);
						moveDocumentContext.toggleOpen();
					}
				}}
			>
				<CertiblokIcon size={24} color="inherit" name={"success"} />
				{buttonText}
			</Button>
		</Box>
	);
};

const MoveDocumentSuccess = React.forwardRef<any, {}>(({ ...props }, ref) => {
	const moveDocumentContext = useContext(MoveDocumentContext);

	const theme = useTheme();

	const { t } = useTranslation();

	return (
		<Box
			ref={ref}
			{...props}
			sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", flexGrow: 1, paddingBottom: 6 }}
		>
			<Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
				<CertiblokIcon
					size={24}
					color="success"
					name={"success"}
					iconCss={{ padding: 12, backgroundColor: alpha(theme.palette.success.main, 0.12), borderRadius: 24 }}
				/>
				<Typography
					children={t("moveDocuments.movedDocuments", { count: moveDocumentContext.currentDocuments.length })}
					variant="subtitle1"
				/>
			</Box>
			<Box>
				<Typography
					children={t("global.documents", { count: moveDocumentContext.currentDocuments.length })}
					variant="subtitle2"
					sx={{ paddingBottom: 2 }}
				/>
				<Box sx={{ flexGrow: 1, display: "flex", gap: 4, boxSizing: "border-box", flexWrap: "wrap" }}>
					{moveDocumentContext.currentDocuments.map((document) => (
						<DocumentWithIcon name={document.name} key={document.id} document={document.lastVersionFile?.[0]} bold />
					))}
				</Box>
			</Box>
			{moveDocumentContext.documentToReplace && (
				<Box>
					<Typography children={t("moveDocuments.documentReplacement")} variant="subtitle2" sx={{ paddingBottom: 2 }} />
					<DocumentWithIcon
						name={moveDocumentContext.documentToReplace.name}
						document={moveDocumentContext.documentToReplace.lastVersionFile?.[0]}
						bold
					/>
				</Box>
			)}
			<Box>
				<Typography children={t("moveDocuments.assignedFolder")} variant="subtitle2" />
				<Breadcrumbs
					maxItems={100}
					separator={
						<CertiblokIcon
							size={24}
							color="rgba(0, 0, 0, 0.54)"
							name={"arrow_mini_outline_right"}
							iconCss={{ marginTop: 4 }}
						/>
					}
				>
					{moveDocumentContext.foldersHistory.map((folder, index) => (
						<Tooltip key={folder.id} title={folder.name} enterDelay={500}>
							<Chip
								label={
									<Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
										<CertiblokIcon size={20} color={folder.color} name={"folder_bold"} />
										<Typography children={folder.name} variant="body2" noWrap sx={{ opacity: 0.74 }} />
									</Box>
								}
								sx={{ backgroundColor: alpha(folder.color ?? "rgba(0,0,0,0)", 0.12), maxWidth: 154, marginTop: 1 }}
							/>
						</Tooltip>
					))}
				</Breadcrumbs>
			</Box>
		</Box>
	);
});

export default MoveDocumentSuccess;
