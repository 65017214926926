import { cn } from "@mabi-ui/utils";
import { Avatar, Button, CircularProgress, Typography } from "@mui/material";
import { t } from "i18next";
import { useAtomValue, useSetAtom } from "jotai";
import { useEffect } from "react";
import { useMe } from "../../../utils/hooks/useMe";
import { chatAtom } from "../atoms/chatAtom";
import { chatMenuAtom } from "../atoms/chatMenuAtom";
import { useGetAllUserChats } from "../hooks/useGetAllUserChats";

type ChatListProps = {
	children?: React.ReactNode;
};

export type ProfileType = {
	id: number;
	uid?: string;
	display_name?: string;
	avatar_url?: string;
	access?: string;
	presence?: string;
};

const ChatList: React.FC<ChatListProps> = (props) => {
	const { data, isLoading } = useGetAllUserChats();

	const selectedChat = useAtomValue(chatAtom);
	const setChat = useSetAtom(chatAtom);
	const setChatMenuOpen = useSetAtom(chatMenuAtom);

	const { me } = useMe();

	useEffect(() => {
		if (data && selectedChat.chatId === undefined) {
			const chatId = data[0].id;
			setChat({ chatId, chatName: data[0].display_name });
		}
	}, [data, selectedChat.chatId, setChat]);

	if (!data && !isLoading) {
		return (
			<div className="w-[360px] h-full overflow-auto bg-primary-a06 flex flex-col items-stretch py-6">
				<Typography className="text-center">Nessuna chat</Typography>
			</div>
		);
	}

	return (
		<div className="w-full lg:w-[360px] h-full overflow-auto bg-primary-a06 flex flex-col items-stretch">
			<div className="bg-primary-a06 w-full p-2 box-border mb-2">
				<Typography variant="caption" className="text-center">
					{t("chat.externalServerMessage")}
				</Typography>
			</div>
			{data?.map((chat) => {
				const profile = chat?.members?.data?.find((member) => (member as ProfileType).uid !== me?.id) as ProfileType;

				if (!profile) {
					return null;
				}

				return (
					<Button
						key={chat.id}
						className={cn("w-full normal-case", chat.id === selectedChat.chatId ? "lg:bg-primary-a12" : "")}
						size="large"
						onClick={() => {
							setChat({ chatId: chat.id, chatName: chat.display_name });
							setChatMenuOpen({ open: false });
						}}
					>
						<div className="w-full flex flex-row items-center gap-2">
							<Avatar
								sx={{
									width: 42,
									height: 42,
									typography: "caption",
									color: "white",
									backgroundColor: "orange.main",
								}}
								src={profile.avatar_url ?? undefined}
								children={profile.avatar_url === undefined ? chat.display_name?.charAt(0).toUpperCase() : undefined}
							/>
							<Typography variant="body2" className="flex-1 text-black text-left">
								{chat.display_name}
							</Typography>
						</div>
					</Button>
				);
			})}
			{isLoading && (
				<div className="flex flex-row justify-center py-6">
					<CircularProgress />
				</div>
			)}
		</div>
	);
};

export default ChatList;
