import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TimePicker from "../../../../../utils/components/TimePicker";
import { EditCreateEventForm } from "../hooks/useEditCreateEventFormValidator";
import { DateTime } from "luxon";
import { Time } from "@internationalized/date";

type EditCreateEventTimesProps = {};
const EditCreateEventTimes: React.FC<EditCreateEventTimesProps> = () => {
	const { t } = useTranslation();
	const { control, getValues, setValue } = useFormContext<EditCreateEventForm>();

	const isAllDay = useWatch({
		control,
		name: "isAllDay",
	});

	const startDate = useWatch({
		control,
		name: "startDate",
	});

	const endDate = useWatch({
		control,
		name: "endDate",
	});

	const isOnDifferentDate =
		startDate &&
		endDate &&
		!DateTime.fromJSDate(startDate?.toDate(DateTime.now().toFormat("z"))).hasSame(
			DateTime.fromJSDate(endDate?.toDate(DateTime.now().toFormat("z"))),
			"day"
		);

	if (isAllDay || isOnDifferentDate) return null;

	return (
		<div className="grid grid-cols-2 gap-4">
			<Controller
				name="startTime"
				control={control}
				render={({ field }) => {
					return (
						<TimePicker
							color="secondary"
							size="medium"
							label={t("events.startTime")}
							value={field.value ?? null}
							onChange={field.onChange}
							onBlur={() => {
								const startTime = getValues("startTime");
								const endTime = getValues("endTime");
								if (
									startTime &&
									(!endTime ||
										startTime.hour > endTime.hour ||
										(startTime.hour === endTime.hour && startTime.minute >= endTime.minute))
								) {
									setValue("endTime", new Time(startTime.hour + 1, startTime.minute));
								}
							}}
						/>
					);
				}}
			/>
			<Controller
				name="endTime"
				control={control}
				render={({ field }) => {
					return (
						<TimePicker
							color="secondary"
							size="medium"
							label={t("events.endTime")}
							value={field.value ?? null}
							onChange={field.onChange}
							onBlur={() => {
								const startTime = getValues("startTime");
								const endTime = getValues("endTime");
								if (
									endTime &&
									(!startTime ||
										startTime.hour > endTime.hour ||
										(startTime.hour === endTime.hour && startTime.minute >= endTime.minute))
								) {
									setValue("startTime", new Time(endTime.hour - 1, endTime.minute));
								}
							}}
						/>
					);
				}}
			/>
		</div>
	);
};
export default EditCreateEventTimes;
