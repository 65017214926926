import { Button, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Audit, AuditApi } from "certiblok-api-manager";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import FolderOrAuditRoomNamePicker from "../../../../../pages/Folders/components/FolderNamePicker";
import { AvailableFolderColors } from "../../../../../pages/Folders/constants/AvailableFolderColors";
import { apiErrorParser, instanceApi } from "../../../../hooks/api";
import BottomSheet from "../../../bottomSheet/BottomSheet";
import LoadingIconButton from "../../../buttons/LoadingIconButton";
import CertiblokIcon from "../../../icons/CertiblokIcon";
import { ModalNavigator, useBottomSheet, useModalNavigator } from "../../../ModalNavigator/ModalNavigator";
import MLDialog from "../../../poppers";

type CreateAuditRoomBottomSheetProps = {
	onSuccess?: (audit: Audit) => void;
};
const CreateAuditRoomBottomSheet = ModalNavigator.create<CreateAuditRoomBottomSheetProps>(({ onSuccess }) => {
	const { t } = useTranslation();
	const { bottomSheetProps, visible } = useBottomSheet();
	const { popBottomSheet } = useModalNavigator();

	const [auditRoomName, setAuditRoomName] = useState("");
	const [auditRoomColor, setAuditRoomColor] = useState(AvailableFolderColors[0]);

	const createAuditRoomMutation = useMutation({
		mutationFn: (params: { name: string; color: string }) => {
			return instanceApi(AuditApi).createAuditRoom({
				language: "it",
				...params,
				documentIds: [],
			});
		},
		onError: (err) => {
			MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
		},
	});

	const onClose = () => {
		if (createAuditRoomMutation.isLoading) return;
		popBottomSheet();
		setAuditRoomName("");
		setAuditRoomColor(AvailableFolderColors[0]);
	};

	return (
		<BottomSheet
			open={visible}
			onClose={onClose}
			header={<Typography children={t("auditRooms.create")} variant="subtitle1" />}
			footer={
				<div className="flex items-center justify-between gap-6">
					<Button
						onClick={onClose}
						children={t("global.cancel")}
						disabled={createAuditRoomMutation.isLoading}
						variant="outlined"
						color="secondary"
						startIcon={<CertiblokIcon name="close" color="inherit" size={24} />}
					/>
					<LoadingIconButton
						children={t("global.createAuditRoom")}
						color="secondary"
						variant="contained"
						loading={createAuditRoomMutation.isLoading}
						disabled={auditRoomName.length === 0 || createAuditRoomMutation.isLoading}
						onClick={() => {
							createAuditRoomMutation.mutate(
								{ name: auditRoomName, color: auditRoomColor },
								{
									onSuccess: (audit) => {
										onSuccess?.(audit.data);
										onClose();
									},
								}
							);
						}}
						className="flex-grow md:flex-grow-0"
						icon={<CertiblokIcon size={24} color="inherit" name={"success"} />}
					/>
				</div>
			}
			{...bottomSheetProps}
		>
			<div className="px-5 md:px-12 min-h-[40vh] md:min-h-[30vh]">
				<FolderOrAuditRoomNamePicker
					folderName={auditRoomName}
					updateFolderName={(value: string) => {
						setAuditRoomName(value);
					}}
					selectedColor={auditRoomColor}
					updateColor={(value: string) => {
						setAuditRoomColor(value);
					}}
					isAuditRoom
					ColorPickerProps={{
						className: "flex-wrap md:flex-nowrap",
					}}
					textInputProps={{
						onBlur: () => {},
						size: "medium",
						sx: {
							backgroundColor: "backgroundSecondary.default",
						},
					}}
				/>
			</div>
		</BottomSheet>
	);
});
export default CreateAuditRoomBottomSheet;
