import { Button, CircularProgress, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AttendeeResponseStatusEnum, EventApi } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { apiErrorParser, instanceApi } from "../../../../../hooks/api";
import CertiblokIcon from "../../../../icons/CertiblokIcon";
import MLDialog from "../../../../poppers";
import { eventInstanceQueryKeys } from "../../../../../../pages/Calendar/pages/EditCreateEvent/hooks/useEventInstanceById";
import { globalCalendarQueryKeys } from "../../../controllers/GlobalCalendarContext";
import { useEffect, useRef } from "react";

export const EventParticipationOptions = ({
	eventId,
	eventLink,
	ownParticipation,
	isLoading,
	isOwner,
	isParticipating,
	shouldEditParticipation,
}: {
	eventId: string;
	eventLink: string;
	ownParticipation: AttendeeResponseStatusEnum;
	isLoading: boolean;
	isOwner: boolean;
	shouldEditParticipation?: boolean;
	isParticipating?: boolean;
}) => {
	const { t } = useTranslation();
	const eventApi = instanceApi(EventApi);
	const queryClient = useQueryClient();

	const { mutate: acceptParticipation, isLoading: isAcceptingParticipation } = useMutation({
		mutationFn: () => eventApi.reviewParticipation({ eventOrOccurranceId: eventId, newStatus: "accepted" }),
		onSuccess: () => {
			queryClient.invalidateQueries(eventInstanceQueryKeys.byId(eventId));
			queryClient.invalidateQueries(globalCalendarQueryKeys.base);
		},
		onError: (err) => MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" }),
	});
	const { mutate: declineParticipation, isLoading: isDecliningParticipation } = useMutation({
		mutationFn: () => eventApi.reviewParticipation({ eventOrOccurranceId: eventId, newStatus: "declined" }),
		onSuccess: () => {
			queryClient.invalidateQueries(eventInstanceQueryKeys.byId(eventId));
			queryClient.invalidateQueries(globalCalendarQueryKeys.base);
		},
		onError: (err) => MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" }),
	});

	const hasHandledParticipation = useRef(false);

	useEffect(() => {
		if (shouldEditParticipation && !hasHandledParticipation.current) {
			if (isParticipating) {
				acceptParticipation();
			} else {
				declineParticipation();
			}
			hasHandledParticipation.current = true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<div className="flex  md:flex-row flex-col justify-between md:items-center mt-6 md:gap-2">
				<Typography>{t("event.eventLink")}</Typography>
				<div className="flex md:flex-row flex-col md:items-center mt-2 md:mt-0 gap-4">
					<Button
						variant="contained"
						className="w-fit"
						color="secondary"
						startIcon={<CertiblokIcon name="video_bold" className={"text-inherit"} />}
						href={eventLink}
						component={"a"}
						target={"_blank"}
						disabled={isLoading}
					>
						{t("event.takePartWithVideocall")}
					</Button>
					<Button
						variant="outlined"
						color="secondary"
						className="w-fit"
						startIcon={<CertiblokIcon name="content_copy" className={"text-inherit"} />}
						onClick={() => {
							navigator.clipboard.writeText(eventLink);
							MLDialog.showSnackbar(t("event.copiedToClipboard"), { variant: "success" });
						}}
						disabled={isLoading}
					>
						{t("event.copyLink")}
					</Button>
				</div>
			</div>
			{!isOwner && (
				<div className="flex md:flex-row flex-col justify-between md:items-center mt-6 md:gap-4">
					<Typography>{t("event.areYouTakingPart")}</Typography>
					<div className="flex md:items-center mt-6 md:mt-0 gap-2">
						<Button
							disabled={isLoading || isDecliningParticipation}
							variant="outlined"
							color="secondary"
							startIcon={
								isAcceptingParticipation ? (
									<div className="w-5 h-5 flex items-center justify-center">
										<CircularProgress size={20} color="secondary" />
									</div>
								) : (
									<CertiblokIcon name={"success"} className={"text-inherit"} />
								)
							}
							className={twMerge("min-w-[124px] w-fit", ownParticipation === "accepted" && "bg-secondary-a12")}
							onClick={() => acceptParticipation()}
						>
							{t("global.yes")}
						</Button>
						<Button
							disabled={isLoading || isAcceptingParticipation}
							variant="outlined"
							className={twMerge(
								"min-w-[124px] w-fit",
								(ownParticipation === "declined" || ownParticipation === "deleted") && "bg-secondary-a12"
							)}
							color="secondary"
							startIcon={
								isDecliningParticipation ? (
									<div className="w-5 h-5 flex items-center justify-center">
										<CircularProgress size={20} color="secondary" />
									</div>
								) : (
									<CertiblokIcon name="close" className={"text-inherit"} />
								)
							}
							onClick={() => declineParticipation()}
						>
							{t("global.no")}
						</Button>
					</div>
				</div>
			)}
		</div>
	);
};
