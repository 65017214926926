import { alpha, Box, IconButton, SxProps, Theme, Tooltip, Typography } from "@mui/material";
import { SharingGroup, Team } from "certiblok-api-manager";
import { theme } from "../../theme";
import CertiblokIcon from "./icons/CertiblokIcon";

type GroupWithMembersProps = {
	sx?: SxProps<Theme> | undefined;
	group: SharingGroup | Team;
	deletable?: boolean;
	onDelete?: (group: SharingGroup | Team) => void;
};

const GroupWithMembers = ({ sx, group, deletable, onDelete }: GroupWithMembersProps) => {
	return (
		<Box
			sx={{
				width: "100%",
				height: 48,
				display: "flex",
				alignItems: "center",
				boxSizing: "border-box",
				padding: 3,
				backgroundColor: alpha(theme.palette.secondary.main, 0.06),
				borderRadius: 3,
				gap: 3,
				...sx,
			}}
		>
			<Box className="rounded-full bg-white">
				<Box
					className="w-6 h-6 rounded-full flex items-center justify-center"
					sx={{ backgroundColor: alpha(group.color, 0.24) }}
				>
					<CertiblokIcon name="profile_outline_01" color={group.color} size={20} />
				</Box>
			</Box>
			<Box sx={{ flex: 1, minWidth: 0, display: "flex", alignItems: "center" }}>
				<Typography children={group.name} variant="body1" noWrap sx={{ width: `50%` }} />

				<Tooltip
					title={
						<div className="flex flex-col gap-1">
							{group.users?.map((u, i) => (
								<Typography key={u.id ?? i} variant="label" className="text-white">{`${u.name} ${u.surname}`}</Typography>
							))}
						</div>
					}
				>
					<Typography
						children={group.users?.map((u) => `${u.name} ${u.surname}`).join(", ")}
						variant="label"
						color="secondary"
						noWrap
						sx={{ width: `50%`, textAlign: "end" }}
					/>
				</Tooltip>
			</Box>
			{deletable && (
				<IconButton
					size="small"
					color="secondary"
					onClick={() => {
						onDelete?.(group);
					}}
				>
					<CertiblokIcon size={24} color="secondary" name={"canc"} />
				</IconButton>
			)}
		</Box>
	);
};

export default GroupWithMembers;
