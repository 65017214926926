import { useForm } from "react-hook-form";
import { EditCreateEventForm, useEditCreateEventFormValidator } from "./useEditCreateEventFormValidator";

export function useEditCreateEventForm() {
	const { validator } = useEditCreateEventFormValidator();

	return useForm<EditCreateEventForm>({
		resolver: validator,
	});
}
