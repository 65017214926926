/** @jsxImportSource @emotion/react */
import { Box, Button, Typography } from "@mui/material";
import { DocumentApi } from "certiblok-api-manager";
import { Fragment, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactVirtualizedAutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import CertiblokIcon from "../../../../utils/components/icons/CertiblokIcon";
import Loading from "../../../../utils/components/Loading";
import {
	ModalNavigator,
	useModalNavigator,
	useModalRoute,
} from "../../../../utils/components/ModalNavigator/ModalNavigator";
import NoContents from "../../../../utils/components/NoContents";
import MLDialog from "../../../../utils/components/poppers";
import { defaultQueryFilter, transformToStringFrom } from "../../../../utils/components/tables/AdminTable";
import WhiteModal from "../../../../utils/components/WhiteModal";
import { apiErrorParser, instanceApi, useApi } from "../../../../utils/hooks/api";
import { useInfiniteLoader } from "../../../../utils/hooks/useInfiniteLoader";
import { CertiblokDocument } from "../../../../utils/interfaces/Document";
import ExpireDocumentsRow from "./ExpireDocumentsRow";

const ExpireDocumentModal = ModalNavigator.create(
	({ revalidateTotalCount, ...otherProps }: { revalidateTotalCount: () => void }) => {
		const { visible } = useModalRoute();
		const { dismissModal } = useModalNavigator();

		const { t } = useTranslation();

		const documentApi = useApi(DocumentApi);

		const {
			data: expiringDocuments,
			// loading: expiringDocumentsLoading,s
			hasNextPage: hasMoreExpiringDocuments,
			fetchData: fetchExpiringDocuments,
			firstLoading: expiringDocumentsFirstLoading,
			revalidateData: revalidateExpiringDocuments,
		} = useInfiniteLoader(
			useCallback(
				(filter) =>
					instanceApi(DocumentApi).listExpiringDocuments(
						transformToStringFrom({ ...defaultQueryFilter, orderBy: "expireDate", order: "asc", perPage: 10, ...filter })
					),
				[]
			)
		);

		const removeExpireDate = useCallback(
			async (documentId: string) => {
				await documentApi
					.editDocument(documentId, { expireDate: "null", noExpiration: false })
					.catch((err) => MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" }));
				revalidateExpiringDocuments();
				revalidateTotalCount();
			},
			[documentApi, revalidateExpiringDocuments, revalidateTotalCount]
		);

		useEffect(() => {
			fetchExpiringDocuments();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		const renderCallback = useCallback(
			({ index, style, data }) => {
				return index >= data.length ? (
					<Loading title={t("pickDocumentReplacement.documentsLoading")} style={style} />
				) : (
					<ExpireDocumentsRow
						document={data[index] as CertiblokDocument}
						removeExpirationFunction={() => removeExpireDate(data[index].id)}
						style={style}
					/>
				);
			},
			[t, removeExpireDate]
		);

		const itemCount = hasMoreExpiringDocuments ? expiringDocuments.length + 1 : expiringDocuments.length;

		// const isSm = useBetterMediaQuery("sm");

		return (
			<WhiteModal
				open={visible}
				onClose={() => {
					dismissModal();
				}}
				responsive
				containerSx={{
					width: {
						xs: "90vw",
						sm: 500,
						md: 650,
						lg: 800,
						xl: 1000,
					},
				}}
				sx={{ zIndex: 1200 }}
				keepMounted={false}
				{...otherProps}
			>
				<Fragment>
					<Typography children={t("dashboard.expiringDocuments")} variant="subtitle1" sx={{ paddingBottom: 6 }} />
					<Box
						sx={{
							height: "50vh",
							marginBottom: 6,
						}}
					>
						{expiringDocumentsFirstLoading ? (
							<Loading title={t("pickDocumentReplacement.documentsLoading")} sx={{ height: "100%" }} />
						) : (expiringDocuments ?? []).length > 0 ? (
							<ReactVirtualizedAutoSizer>
								{({ height, width }) => (
									<InfiniteLoader
										isItemLoaded={(index) => !hasMoreExpiringDocuments || index < expiringDocuments.length}
										itemCount={itemCount}
										loadMoreItems={fetchExpiringDocuments}
									>
										{({ onItemsRendered, ref }) => (
											<FixedSizeList
												height={height}
												itemCount={itemCount}
												// itemSize={isSm ? 48 + 8 : 74 + 8}
												itemSize={48 + 8}
												onItemsRendered={onItemsRendered}
												ref={ref}
												width={width}
												itemData={expiringDocuments}
											>
												{renderCallback}
											</FixedSizeList>
										)}
									</InfiniteLoader>
								)}
							</ReactVirtualizedAutoSizer>
						) : (
							<NoContents
								variant="document"
								title={t("dashboard.noExpiringDocument")}
								sx={{ height: "100%", justifyContent: "center" }}
							/>
						)}
					</Box>
					<Button variant="outlined" color="secondary" onClick={() => dismissModal()}>
						<CertiblokIcon size={24} color="secondary" name={"close"} />
						{t("global.close")}
					</Button>
				</Fragment>
			</WhiteModal>
		);
	}
);

export default ExpireDocumentModal;
