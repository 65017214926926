import { DateTime, Duration } from "luxon";
import { EditCreateEventForm } from "../hooks/useEditCreateEventFormValidator";
import { EditEventInstanceMode, EventCreateEditInput, EventInstance, EventReminderType } from "certiblok-api-manager";
import { UseFormReturn } from "react-hook-form";
import { areFieldsDirty, computeDirtyFields } from "./computeDirtyFields";

export const getEventCreateEditInput = ({
	form,
	editMode,
	eventInstance,
	isEdit,
}: {
	form: UseFormReturn<EditCreateEventForm>;
	editMode?: EditEventInstanceMode;
	eventInstance?: EventInstance;
	isEdit?: boolean;
}) => {
	const data = form.getValues();
	if (!data.startDate) return;
	const dateTimeStartDate = DateTime.fromJSDate(data.startDate.toDate(DateTime.now().toFormat("z")));
	const dateTimeEndDate = data.endDate
		? DateTime.fromJSDate(data.endDate.toDate(DateTime.now().toFormat("z")))
		: dateTimeStartDate;

	const dateTimeStartTime = data.startTime
		? dateTimeStartDate.set({
				hour: data.startTime.hour,
				minute: data.startTime.minute,
		  })
		: undefined;
	const dateTimeEndTime = data.endTime
		? dateTimeStartDate.set({
				hour: data.endTime.hour,
				minute: data.endTime.minute,
		  })
		: undefined;

	const spansMultipleDay = dateTimeEndDate && !dateTimeStartDate.hasSame(dateTimeEndDate, "day");

	const startTime =
		!data.isAllDay && !spansMultipleDay && dateTimeStartTime ? dateTimeStartTime : dateTimeStartDate.startOf("day");
	const endTime =
		!data.isAllDay && !spansMultipleDay && dateTimeEndTime ? dateTimeEndTime : dateTimeEndDate.endOf("day");

	const originalStartDate = eventInstance?.event?.start?.date
		? DateTime.fromISO(eventInstance.event.start.date)
		: undefined;
	const hasEditedStartDate = form.getFieldState("startDate")?.isDirty;
	const originalEndDate = eventInstance?.event?.end?.date ? DateTime.fromISO(eventInstance.event.end.date) : undefined;
	const hasEditedEndDate = form.getFieldState("endDate")?.isDirty;
	const isRecurring = Boolean(data.recurrence);

	const duration = Duration.fromDurationLike({ [data.reminder?.unit || "hours"]: data.reminder?.amount || 1 });
	const reminderMinutes = data.reminder.isAtEventTime ? 0 : duration.as("minutes");

	const dirtyFields = computeDirtyFields(form.formState.dirtyFields);

	const editCreateBody: EventCreateEditInput = {
		name: !isEdit || areFieldsDirty(dirtyFields, ["name"]) ? data.name : undefined,
		annotation: !isEdit || areFieldsDirty(dirtyFields, ["annotation"]) ? data.annotation : undefined,
		start:
			!isEdit || areFieldsDirty(dirtyFields, ["startDate", "startTime"])
				? {
						date:
							isRecurring && editMode === "all" && originalStartDate && !hasEditedStartDate
								? originalStartDate.startOf("day").toISO()!
								: dateTimeStartDate.startOf("day").toISO()!,
						time: startTime.toFormat("HH:mm"),
				  }
				: undefined,
		end:
			!isEdit || areFieldsDirty(dirtyFields, ["endDate", "endTime"])
				? {
						date:
							isRecurring && editMode === "all" && originalEndDate && !hasEditedEndDate
								? originalEndDate.startOf("day").toISO()!
								: dateTimeEndDate.endOf("day").toISO()!,
						time: endTime.toFormat("HH:mm"),
				  }
				: undefined,
		recurrence:
			(!isEdit || areFieldsDirty(dirtyFields, ["recurrence"])) && data.recurrence && editMode !== "this"
				? [data.recurrence?.toString()]
				: undefined,
		reminder:
			!isEdit || areFieldsDirty(dirtyFields, ["reminder"])
				? {
						useDefault: false,
						overrides: [
							...(data.notifications?.email
								? [
										{
											method: "email",
											minutes: reminderMinutes,
										} as EventReminderType,
								  ]
								: []),
							...(data.notifications?.inApp
								? [
										{
											method: "popup",
											minutes: reminderMinutes,
										} as EventReminderType,
								  ]
								: []),
						],
				  }
				: undefined,
		timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
	};

	return editCreateBody;
};
