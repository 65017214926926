import { useSetAtom } from "jotai";
import { useCallback } from "react";
import { selectedFolderAtom, selectedFolderHistoryAtom, uploadDocumentStepAtom } from "../atoms/uploadDocumentAtoms";
import { useResetFilesToUpload } from "../stores/filesToUploadStore";
import { useResetUploadedFiles } from "../stores/uploadedFilesStore";

export function useResetUploadDocuments() {
	const setUploadDocumentStep = useSetAtom(uploadDocumentStepAtom);
	const setSelectedFolder = useSetAtom(selectedFolderAtom);
	const setSelectedFolderHistory = useSetAtom(selectedFolderHistoryAtom);
	const resetFilesToUpload = useResetFilesToUpload();
	const resetUploadedFiles = useResetUploadedFiles();

	return useCallback(() => {
		setTimeout(() => {
			setUploadDocumentStep("chooseFolder");
			setSelectedFolder(undefined);
			setSelectedFolderHistory([]);
			resetFilesToUpload();
			resetUploadedFiles();
			//we wait for the animation of closing bottom sheet to finish
		}, 300);
	}, [resetFilesToUpload, resetUploadedFiles, setSelectedFolder, setSelectedFolderHistory, setUploadDocumentStep]);
}
