import { useQuery } from "@tanstack/react-query";
import { UserApi } from "certiblok-api-manager";
import { useApi } from "../../../utils/hooks/api";

export const useGetRecoverData = (options?: { onError?: () => void; onSuccess?: () => void }) => {
	const userApi = useApi(UserApi);

	return useQuery({
		queryKey: ["recoverData"],
		queryFn: () => userApi.getRecoverData(),

		retry: 1,
		onError: () => {
			options?.onError?.();
		},
		onSuccess: () => {
			options?.onSuccess?.();
		},
		select: (data) => {
			return data.data;
		},
	});
};
