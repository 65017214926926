import { useMutation } from "@tanstack/react-query";
import { ChatApi } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import MLDialog from "../../../../../utils/components/poppers";
import { useApi } from "../../../../../utils/hooks/api";

export const useSendMessage = ({ chatId, onSuccess }: { chatId: string; onSuccess?: () => void }) => {
	const chatApi = useApi(ChatApi);
	const { t } = useTranslation();

	return useMutation({
		mutationFn: ({ content, replyToMessageId }: { content: string; replyToMessageId?: string }) => {
			// * a reply to a specific message is already setup

			// @ts-expect-error
			return chatApi.sendMessage(chatId, { content, replyToMessageId });
		},
		onError: () => {
			MLDialog.showSnackbar(t("sharingNotes.errorWhileReplying"), { variant: "error" });
		},
		onSuccess: () => {
			onSuccess?.();
		},
	});
};
