import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Collapse, Typography } from "@mui/material";
import { SharingGroup, UserOrContact } from "certiblok-api-manager";
import { FormEvent, useEffect } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import LoadingTextButton from "../../../../utils/components/LoadingTextButton";
import {
	ModalNavigator,
	useModalNavigator,
	useModalRoute,
} from "../../../../utils/components/ModalNavigator/ModalNavigator";
import UserChip from "../../../../utils/components/UserChip";
import WhiteModal from "../../../../utils/components/WhiteModal";
import { AvailableFolderColors } from "../../../Folders/constants/AvailableFolderColors";
import { useCreateContactGroup, useEditContactGroup } from "../../hooks/useContactGroupsHooks";
import ColorSelector from "./ColorSelector";
import GroupContactUserAutocomplete from "./GroupContactUserAutocomplete";
import NameSelector from "./NameSelector";

const EditCreateContactGroup = ModalNavigator.create(
	({ initialGroup, onSuccess }: { initialGroup?: SharingGroup; onSuccess?: () => void }) => {
		const { t } = useTranslation();

		const { visible } = useModalRoute();
		const { popModal } = useModalNavigator();

		const formSchema = z
			.object({
				name: z.string().min(1, { message: t("contactsGroups.insertValidName") }),
				color: z.string().min(1),
				contacts: z.array(
					z.object({
						id: z.string(),
						name: z.string().nullish(),
						surname: z.string().nullish(),
						email: z.string().nullish(),
						profileUrl: z.string().nullish(),
					})
				),
				newContacts: z.array(z.object({ email: z.string() })),
			})
			.refine((data) => data.contacts.length > 0 || data.newContacts.length > 0, {
				message: t("contactsGroups.insertAtLeastOneContact"),
				path: ["contacts"],
			});

		type GroupFormValues = z.infer<typeof formSchema>;

		const form = useForm<GroupFormValues>({
			resolver: zodResolver(formSchema),
			defaultValues: {
				name: initialGroup?.name ?? "",
				color: initialGroup?.color ?? AvailableFolderColors[0],
				contacts: initialGroup?.users ?? [],
				newContacts: [],
			},
		});

		const {
			append: appendContact,
			fields: contactsField,
			remove: removeContact,
		} = useFieldArray({
			control: form.control,
			name: "contacts",
			keyName: "fieldId",
		});

		const {
			append: appendEmail,
			fields: emailField,
			remove: removeEmail,
		} = useFieldArray({
			control: form.control,
			name: "newContacts",
			keyName: "fieldId",
		});

		const onClose = () => {
			form.reset();
			popModal();
		};

		const onMutationSuccess = () => {
			onSuccess?.();
			onClose();
		};

		const { mutate: createGroup, isLoading: isCreating } = useCreateContactGroup({ onSuccess: onMutationSuccess });
		const { mutate: editGroup, isLoading: isEditing } = useEditContactGroup({ onSuccess: onMutationSuccess });

		const onSubmit = (e: FormEvent) => {
			e.preventDefault();
			form.handleSubmit((data) => {
				if (initialGroup?.id) {
					editGroup({
						groupId: initialGroup.id,
						name: data.name,
						color: data.color,
						userIds: data.contacts.map((contact) => contact.id ?? contact.email!),
						emails: data.newContacts.map((e) => e.email),
					});
				} else {
					createGroup({
						name: data.name,
						color: data.color,
						userIds: data.contacts.map((contact) => contact.id),
						emails: data.newContacts.map((e) => e.email) ?? [],
					});
				}
			})();
		};

		useEffect(() => {
			form.reset({
				name: initialGroup?.name ?? "",
				color: initialGroup?.color ?? AvailableFolderColors[0],
				contacts: initialGroup?.users ?? ([] as UserOrContact[]),
				newContacts: [],
			});
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [initialGroup]);

		return (
			<WhiteModal open={visible} onClose={onClose} containerSx={{ width: "440px" }}>
				<FormProvider {...form}>
					<form onSubmit={onSubmit}>
						<Typography variant="subtitle1" className="mb-6">
							{initialGroup ? t("contactsGroups.editGroup") : t("contactsGroups.createGroup")}
						</Typography>
						<div className="flex flex-col gap-4">
							<NameSelector />
							<ColorSelector />
							<div>
								<GroupContactUserAutocomplete
									includeEmails
									onSelectEmail={(value: string) => {
										appendEmail({
											email: value,
										});
									}}
									onSelectUser={(value: UserOrContact) => {
										appendContact({
											// @ts-ignore
											id: value?.linkedUserId ?? value.id,
											name: value.name,
											surname: value.surname,
											email: value.email,
											profileUrl: value?.profileUrl,
										});
									}}
									disabledUsersOrContactsIds={contactsField.map((field) => field.id ?? "")}
									TextFieldProps={{
										onFocus: () => {
											form.clearErrors("contacts");
										},
									}}
								/>
								{contactsField.length === 0 && emailField.length === 0 && (
									<div className="min-h-[20px] w-full">
										<Collapse in={Boolean(form.formState.errors.contacts)}>
											<Typography
												variant="label"
												component="p"
												className="bg-error/[0.12] text-error px-2 py-[3px] font-bold text-[10px] w-full box-border rounded-sm mt-1"
											>
												{form.formState.errors.contacts?.message}
											</Typography>
										</Collapse>
									</div>
								)}
							</div>

							{[...contactsField, ...emailField].length > 0 && (
								<div className="flex gap-1 flex-wrap max-h-[105px] overflow-y-auto mb-3">
									{emailField.map((field, index) => (
										<div key={`${field.fieldId}-mail`}>
											<UserChip user={field.email} deletable onDelete={() => removeEmail(index)} />
										</div>
									))}
									{contactsField.map((field, index) => (
										<div key={field.fieldId}>
											<UserChip user={field} deletable onDelete={() => removeContact(index)} />
										</div>
									))}
								</div>
							)}
						</div>
						<div className="flex w-full justify-between items-center gap-3 mt-3">
							<Button variant="outlined" color="secondary" onClick={onClose} className="px-6">
								{t("global.cancel")}
							</Button>
							<LoadingTextButton
								variant="contained"
								color="secondary"
								onClick={onSubmit}
								className="px-6"
								loading={isCreating || isEditing}
							>
								{initialGroup ? t("contactsGroups.editGroup") : t("contactsGroups.createGroup")}
							</LoadingTextButton>
						</div>
					</form>
				</FormProvider>
			</WhiteModal>
		);
	}
);

export default EditCreateContactGroup;
