"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.7.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlanApi = exports.PlanApiFactory = exports.PlanApiFp = exports.PlanApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * PlanApi - axios parameter creator
 * @export
 */
const PlanApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *  **Permission required**: [\"readOne\", \"Company\"]
         * @summary Can user update plan | Permission required: [\"readOne\", \"Company\"]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canUserUpdatePlan: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/plan/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readOne\", \"Company\"]
         * @summary Generate plan link | Permission required: [\"readOne\", \"Company\"]
         * @param {string} planId
         * @param {'month' | 'year'} [recurrence]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePlanLink: (planId, recurrence, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'planId' is not null or undefined
            (0, common_1.assertParamExists)('generatePlanLink', 'planId', planId);
            const localVarPath = `/plan/link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (planId !== undefined) {
                localVarQueryParameter['planId'] = planId;
            }
            if (recurrence !== undefined) {
                localVarQueryParameter['recurrence'] = recurrence;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all plans
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPlans: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/plan/list/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readAll\", \"Company\"]
         * @summary Get current usage | Permission required: [\"readAll\", \"Company\"]
         * @param {'raw' | 'B' | 'KB' | 'MB' | 'GB'} [unit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUsage: (unit, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/plan/usage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (unit !== undefined) {
                localVarQueryParameter['unit'] = unit;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get custom plan payment link
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomPlanPaymentLink: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getCustomPlanPaymentLink', 'id', id);
            const localVarPath = `/plan/custom`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readOne\", \"Company\"]
         * @summary Get my subscription | Permission required: [\"readOne\", \"Company\"]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMySubscription: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/plan/subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get plan by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlanById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getPlanById', 'id', id);
            const localVarPath = `/plan/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readOne\", \"Company\"]
         * @summary Update plan | Permission required: [\"readOne\", \"Company\"]
         * @param {InputUpdatePlan} [inputUpdatePlan]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlan: (inputUpdatePlan, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/plan/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputUpdatePlan, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.PlanApiAxiosParamCreator = PlanApiAxiosParamCreator;
/**
 * PlanApi - functional programming interface
 * @export
 */
const PlanApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.PlanApiAxiosParamCreator)(configuration);
    return {
        /**
         *  **Permission required**: [\"readOne\", \"Company\"]
         * @summary Can user update plan | Permission required: [\"readOne\", \"Company\"]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canUserUpdatePlan(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.canUserUpdatePlan(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readOne\", \"Company\"]
         * @summary Generate plan link | Permission required: [\"readOne\", \"Company\"]
         * @param {string} planId
         * @param {'month' | 'year'} [recurrence]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePlanLink(planId, recurrence, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.generatePlanLink(planId, recurrence, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all plans
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPlans(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllPlans(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readAll\", \"Company\"]
         * @summary Get current usage | Permission required: [\"readAll\", \"Company\"]
         * @param {'raw' | 'B' | 'KB' | 'MB' | 'GB'} [unit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUsage(unit, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getCurrentUsage(unit, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get custom plan payment link
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomPlanPaymentLink(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getCustomPlanPaymentLink(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readOne\", \"Company\"]
         * @summary Get my subscription | Permission required: [\"readOne\", \"Company\"]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMySubscription(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getMySubscription(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get plan by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlanById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getPlanById(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readOne\", \"Company\"]
         * @summary Update plan | Permission required: [\"readOne\", \"Company\"]
         * @param {InputUpdatePlan} [inputUpdatePlan]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlan(inputUpdatePlan, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updatePlan(inputUpdatePlan, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.PlanApiFp = PlanApiFp;
/**
 * PlanApi - factory interface
 * @export
 */
const PlanApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.PlanApiFp)(configuration);
    return {
        /**
         *  **Permission required**: [\"readOne\", \"Company\"]
         * @summary Can user update plan | Permission required: [\"readOne\", \"Company\"]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canUserUpdatePlan(options) {
            return localVarFp.canUserUpdatePlan(options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readOne\", \"Company\"]
         * @summary Generate plan link | Permission required: [\"readOne\", \"Company\"]
         * @param {string} planId
         * @param {'month' | 'year'} [recurrence]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePlanLink(planId, recurrence, options) {
            return localVarFp.generatePlanLink(planId, recurrence, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all plans
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPlans(options) {
            return localVarFp.getAllPlans(options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readAll\", \"Company\"]
         * @summary Get current usage | Permission required: [\"readAll\", \"Company\"]
         * @param {'raw' | 'B' | 'KB' | 'MB' | 'GB'} [unit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUsage(unit, options) {
            return localVarFp.getCurrentUsage(unit, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get custom plan payment link
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomPlanPaymentLink(id, options) {
            return localVarFp.getCustomPlanPaymentLink(id, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readOne\", \"Company\"]
         * @summary Get my subscription | Permission required: [\"readOne\", \"Company\"]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMySubscription(options) {
            return localVarFp.getMySubscription(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get plan by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlanById(id, options) {
            return localVarFp.getPlanById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readOne\", \"Company\"]
         * @summary Update plan | Permission required: [\"readOne\", \"Company\"]
         * @param {InputUpdatePlan} [inputUpdatePlan]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlan(inputUpdatePlan, options) {
            return localVarFp.updatePlan(inputUpdatePlan, options).then((request) => request(axios, basePath));
        },
    };
};
exports.PlanApiFactory = PlanApiFactory;
/**
 * PlanApi - object-oriented interface
 * @export
 * @class PlanApi
 * @extends {BaseAPI}
 */
class PlanApi extends base_1.BaseAPI {
    /**
     *  **Permission required**: [\"readOne\", \"Company\"]
     * @summary Can user update plan | Permission required: [\"readOne\", \"Company\"]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanApi
     */
    canUserUpdatePlan(options) {
        return (0, exports.PlanApiFp)(this.configuration).canUserUpdatePlan(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readOne\", \"Company\"]
     * @summary Generate plan link | Permission required: [\"readOne\", \"Company\"]
     * @param {string} planId
     * @param {'month' | 'year'} [recurrence]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanApi
     */
    generatePlanLink(planId, recurrence, options) {
        return (0, exports.PlanApiFp)(this.configuration).generatePlanLink(planId, recurrence, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all plans
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanApi
     */
    getAllPlans(options) {
        return (0, exports.PlanApiFp)(this.configuration).getAllPlans(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readAll\", \"Company\"]
     * @summary Get current usage | Permission required: [\"readAll\", \"Company\"]
     * @param {'raw' | 'B' | 'KB' | 'MB' | 'GB'} [unit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanApi
     */
    getCurrentUsage(unit, options) {
        return (0, exports.PlanApiFp)(this.configuration).getCurrentUsage(unit, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get custom plan payment link
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanApi
     */
    getCustomPlanPaymentLink(id, options) {
        return (0, exports.PlanApiFp)(this.configuration).getCustomPlanPaymentLink(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readOne\", \"Company\"]
     * @summary Get my subscription | Permission required: [\"readOne\", \"Company\"]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanApi
     */
    getMySubscription(options) {
        return (0, exports.PlanApiFp)(this.configuration).getMySubscription(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get plan by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanApi
     */
    getPlanById(id, options) {
        return (0, exports.PlanApiFp)(this.configuration).getPlanById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readOne\", \"Company\"]
     * @summary Update plan | Permission required: [\"readOne\", \"Company\"]
     * @param {InputUpdatePlan} [inputUpdatePlan]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanApi
     */
    updatePlan(inputUpdatePlan, options) {
        return (0, exports.PlanApiFp)(this.configuration).updatePlan(inputUpdatePlan, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.PlanApi = PlanApi;
