import { useMutation } from "@tanstack/react-query";
import { DocumentApi } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import MLDialog from "../../../../../utils/components/poppers";
import { useApi } from "../../../../../utils/hooks/api";

export const useInterruptSharing = ({
	documentId,
	userId,
	sharingGroupId,
	onSuccess,
}: {
	documentId: string;
	userId?: string;
	sharingGroupId?: string;
	onSuccess?: () => void;
}) => {
	const { t } = useTranslation();
	const documentApi = useApi(DocumentApi);

	return useMutation({
		mutationFn: () => {
			if (sharingGroupId) {
				return documentApi.unshareDocumentsWithSharingGroup(documentId, { sharingGroupId: sharingGroupId });
			} else if (userId) {
				return documentApi.unshareDocumentWithUser(documentId, { shareUserId: userId });
			}
			return Promise.reject();
		},
		onSuccess: () => {
			onSuccess?.();
		},
		onError: (error) => {
			MLDialog.showSnackbar(t("global.error"), { variant: "error" });
		},
	});
};
