import type { DateTime } from "luxon";
import { useEffect } from "react";
import { CalendarContext, useCalendarContext } from "./controllers/CalendarContext";
import { CurrentIndexContext, useCurrentIndexContext } from "./controllers/CurrentIndexContext";
import InfiniteCalendar from "./InfiniteCalendar";

export type CalendarProps = {
	onDateChange?: (date: DateTime) => void;
	initialDate?: DateTime;
	disabledBefore?: DateTime;
	prevSelectedDate?: DateTime;
	shouldHideSelectedDate?: boolean;
};

const Calendar: React.FC<CalendarProps> = ({
	onDateChange,
	initialDate,
	prevSelectedDate,
	disabledBefore,
	shouldHideSelectedDate,
}) => {
	const calendarContext = useCalendarContext(initialDate, shouldHideSelectedDate);
	const currentIndexContext = useCurrentIndexContext();

	useEffect(() => {
		onDateChange?.(calendarContext.selectedDate);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [calendarContext.selectedDate]);

	return (
		<CalendarContext.Provider value={calendarContext}>
			<CurrentIndexContext.Provider value={currentIndexContext}>
				<InfiniteCalendar initialDate={initialDate} prevSelectedDate={prevSelectedDate} disabledBefore={disabledBefore} />
			</CurrentIndexContext.Provider>
		</CalendarContext.Provider>
	);
};

export default Calendar;
