import { MenuItem, Select, SelectProps, Typography } from "@mui/material";
import { CertiblokDocument } from "../../../utils/interfaces/Document";

type DocumentVersionSelectProps = {
	document?: CertiblokDocument;
	selectedVersion?: { versionNumber: number; fileId: string };
	onSelectedVersionChange: (version: { versionNumber: number; fileId: string }) => void;
} & Omit<SelectProps, "value" | "onChange">;

const DocumentVersionSelect: React.FC<DocumentVersionSelectProps> = ({
	document,
	selectedVersion,
	onSelectedVersionChange,
	color = "secondary",
	...props
}) => {
	return (
		<Select
			color={color}
			value={
				selectedVersion
					? JSON.stringify({
							versionNumber: selectedVersion?.versionNumber,
							fileId: selectedVersion?.fileId,
					  })
					: ""
			}
			onChange={(ev) => {
				const { value } = ev.target;
				if (typeof value === "string") {
					onSelectedVersionChange(JSON.parse(value));
				}
			}}
			{...props}
		>
			{(document?.files || []).reduce(
				(acc, file) => [
					<MenuItem key={file.id} value={JSON.stringify({ versionNumber: file.version, fileId: file.id })}>
						<Typography children={`V${file.version}`} variant="body2" />
					</MenuItem>,
					...acc,
				],
				[] as JSX.Element[]
			)}
		</Select>
	);
};
export default DocumentVersionSelect;
