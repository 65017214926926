import { useMutation, useQuery } from "@tanstack/react-query";
import { FavoriteApi } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import MLDialog from "../../../utils/components/poppers";
import { IQueryFilter, transformToStringFrom } from "../../../utils/components/tables/AdminTable";
import { chunkRequests } from "../../../utils/functions/chunkRequests";
import { apiErrorParser, instanceApi } from "../../../utils/hooks/api";
import { useFavoriteContext } from "./FavoriteContext";

export const favoriteQueryKeyFactory = {
	base: ["favorites"],
	documents: {
		base: () => [...favoriteQueryKeyFactory.base, "documents"],
		filtered: (queryFilter: IQueryFilter, textFilter: string) => [
			...favoriteQueryKeyFactory.documents.base(),
			queryFilter,
			textFilter,
		],
	},
	folders: {
		base: () => [...favoriteQueryKeyFactory.base, "folders"],
		filtered: (queryFilter: IQueryFilter, textFilter: string) => [
			...favoriteQueryKeyFactory.folders.base(),
			queryFilter,
			textFilter,
		],
	},
};

export const useFavoriteDocuments = () => {
	const favoriteContext = useFavoriteContext();

	return useQuery({
		queryKey: favoriteQueryKeyFactory.documents.filtered(favoriteContext.queryFilter, favoriteContext.textFilter),
		queryFn: () => {
			return instanceApi(FavoriteApi).getFavoriteDocuments(
				transformToStringFrom({
					...favoriteContext.queryFilter,
					filter: [
						...(favoriteContext.queryFilter.filter ?? []).filter((elem) => elem.name === undefined),
						{ name: favoriteContext.textFilter },
					],
				})
			);
		},
		keepPreviousData: true,
	});
};

export const useFavoriteFolders = () => {
	const favoriteContext = useFavoriteContext();

	return useQuery({
		queryKey: favoriteQueryKeyFactory.folders.filtered(favoriteContext.queryFilter, favoriteContext.textFilter),
		queryFn: () => {
			return instanceApi(FavoriteApi).getFavoriteFolders(
				transformToStringFrom({
					...favoriteContext.queryFilter,
					filter: [
						...(favoriteContext.queryFilter.filter ?? []).filter((elem) => elem.name === undefined),
						{ name: favoriteContext.textFilter },
					],
				})
			);
		},
		keepPreviousData: true,
	});
};

export const useToggleFavoriteItemMutation = (
	onMutate?: (favorite: boolean) => void,
	onSuccess?: (favorite: boolean) => void
) => {
	const { t } = useTranslation();

	return useMutation({
		mutationFn: ({ items, favorite }: { items: { id: string; type: "document" | "folder" }[]; favorite: boolean }) => {
			return chunkRequests(
				10,
				(item) => {
					if (favorite) {
						return item.type === "document"
							? instanceApi(FavoriteApi).addDocumentToFavorite({ documentId: item.id })
							: instanceApi(FavoriteApi).addFolderToFavorite({ folderId: item.id });
					} else {
						return item.type === "document"
							? instanceApi(FavoriteApi).removeDocumentFromFavorite(item.id)
							: instanceApi(FavoriteApi).removeFolderFromFavorite(item.id);
					}
				},
				items
			);
		},
		onMutate: ({ favorite }) => {
			onMutate?.(favorite);
		},
		onSuccess: ({ chunkedErrors, chunkedSuccessfully }, { favorite }) => {
			if (chunkedErrors.length > 0) {
				MLDialog.showSnackbar(
					favorite
						? t("folders.addItemsToFavoriteFailed", { count: chunkedErrors.length })
						: t("folders.itemsRemovalFailed", { count: chunkedErrors.length }),
					{ variant: "error" }
				);
			}
			if (chunkedSuccessfully.length > 0) {
				MLDialog.showSnackbar(
					favorite
						? t("folders.itemsAddedToFavorite", { count: chunkedSuccessfully.length })
						: t("folders.itemsRemovedFromFavorite", { count: chunkedSuccessfully.length }),
					{ variant: "success" }
				);
			}
			onSuccess?.(favorite);
		},
		onError: (err) => {
			MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
		},
	});
};
