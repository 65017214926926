import { useQuery } from "@tanstack/react-query";
import { EditRoomApi } from "certiblok-api-manager";
import { useApi } from "../../../../../utils/hooks/api";

export const getGetEditRoomChangesHistoryKey = (editRoomFolderOrDocumentId?: string) =>
	editRoomFolderOrDocumentId ? ["editRoomChangesHistory", editRoomFolderOrDocumentId] : ["editRoomChangesHistory"];

export const useGetEditRoomChangesHistory = ({
	editRoomFolderId,
	editRoomDocumentId,
}: {
	editRoomFolderId?: string;
	editRoomDocumentId?: string;
}) => {
	const editRoomApi = useApi(EditRoomApi);

	return useQuery({
		queryKey: getGetEditRoomChangesHistoryKey(editRoomFolderId ?? editRoomDocumentId),
		queryFn: () => {
			if (editRoomDocumentId) {
				return editRoomApi.getEditRoomDocumentUpdates(editRoomDocumentId);
			}
			return editRoomApi.getAllEditRoomFolderUpdates(editRoomFolderId ?? "");
		},
		select: (data) => data.data,
		enabled: Boolean(editRoomFolderId ?? editRoomDocumentId),
	});
};
