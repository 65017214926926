import { SearchApi, SharingGroup, User, UserOrContact } from "certiblok-api-manager";
import React, { useCallback, useState } from "react";
import { useApi } from "../../../../../utils/hooks/api";
import type { IShareWithContactContext } from "../interfaces/IShareWithContactContext";

export function useShareWithContactContext(): IShareWithContactContext {
	const searchApi = useApi(SearchApi);
	const [currentInputValue, setCurrentInputValue] = useState("");

	const [availableContacts, setAvailableContacts] = useState<UserOrContact[]>([]);
	const [loading, setLoading] = useState(false);

	const fetchContacts = useCallback(
		(input?: string) => {
			const finalInput = input || currentInputValue;

			if ((finalInput?.length || 0) < 3) return;

			setLoading(true);

			searchApi
				.searchUsersOrContacts(finalInput)
				.then((resp) => {
					setAvailableContacts(resp.data || []);
				})
				.catch(console.log)
				.finally(() => {
					setLoading(false);
				});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[currentInputValue]
	);

	const [selectedContacts, setSelectedContacts] = useState<UserOrContact[]>([]);
	const [selectedGroups, setSelectedGroups] = useState<SharingGroup[]>([]);
	const [selectedEmails, setSelectedEmails] = useState<string[]>([]);

	// const selectContact = useCallback(
	// 	(contact: UserOrContact | string) => {
	// 		let index;
	// 		if (typeof contact === "string") {
	// 			index = selectedContacts.findIndex((elem) => {
	// 				if (typeof elem === "string") return elem === contact;
	// 				return false;
	// 			});
	// 		} else {
	// 			index = selectedContacts.findIndex((elem) => {
	// 				if (typeof elem !== "string") return elem.id === contact.id;
	// 				return false;
	// 			});
	// 		}
	// 		if (index === -1) setSelectedContacts([...selectedContacts, contact]);
	// 	},
	// 	[selectedContacts]
	// );

	const selectGroup = useCallback((group: SharingGroup) => {
		setSelectedGroups((prev) => [...prev, group]);
	}, []);

	const selectEmail = useCallback((email: string) => {
		setSelectedEmails((prev) => [...prev, email]);
	}, []);

	const selectContact = useCallback((contact: User | UserOrContact) => {
		setSelectedContacts((prev) => [...prev, contact]);
	}, []);

	const selectContacts = useCallback((contacts: UserOrContact[]) => {
		setSelectedContacts([...contacts]);
	}, []);

	const unselectContact = useCallback(
		(contact: UserOrContact) => {
			// let index;
			// if (typeof contact === "string") {
			// 	index = selectedContacts.findIndex((elem) => {
			// 		if (typeof elem === "string") return elem === contact;
			// 		return false;
			// 	});
			// } else {
			// 	index = selectedContacts.findIndex((elem) => {
			// 		if (typeof elem !== "string") return elem.id === contact.id;
			// 		return false;
			// 	});
			// }
			// if (index >= 0) {
			// 	selectedContacts.splice(index, 1);
			// 	setSelectedContacts([...selectedContacts]);
			// }
			setSelectedContacts((prev) => prev.filter((elem) => elem.id !== contact.id));
		},
		// [selectedContacts]
		[]
	);

	const unselectEmail = useCallback((email: string) => {
		setSelectedEmails((prev) => prev.filter((elem) => elem !== email));
	}, []);

	const unselectGroup = useCallback((groupId: string) => {
		setSelectedGroups((prev) => prev.filter((elem) => elem.id !== groupId));
	}, []);

	const resetSelectedUsers = useCallback(() => {
		setSelectedEmails([]);
		setSelectedGroups([]);
		setSelectedContacts([]);
	}, []);

	return {
		currentInputValue,
		setCurrentInputValue,
		availableContacts,
		fetchContacts,
		loading,
		filterContacts: fetchContacts,
		selectedContacts,
		selectedGroups,
		selectedEmails,
		selectContact,
		selectEmail,
		selectGroup,
		selectContacts,
		unselectContact,
		unselectEmail,
		unselectGroup,
		resetSelectedUsers,
	};
}

export const ShareWithContactContext = React.createContext<IShareWithContactContext>({} as IShareWithContactContext);
