/** @jsxImportSource @emotion/react */
import { Avatar, Box, Button, Chip, ModalProps, Typography } from "@mui/material";
import { DocumentApi } from "certiblok-api-manager";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { backendMediaUrlParser } from "../..";
import avatarPlaceholder from "../../../assets/avatar-placeholder.png";
import { apiErrorParser, useApi } from "../../hooks/api";
import { useBetterMediaQuery } from "../../hooks/useBetterMediaQuery";
import type { CertiblokDocument } from "../../interfaces/Document";
import BottomSheet from "../bottomSheet/BottomSheet";
import LoadingIconButton from "../buttons/LoadingIconButton";
import CertiblokTextInput from "../CertiblokTextInput";
import DocumentWithIcon from "../DocumentWithIcon";
import CertiblokIcon from "../icons/CertiblokIcon";
import LanguageSelector, { SupportedLanguagesType } from "../LanguageSelector";
import MLDialog from "../poppers";
import WhiteModal from "../WhiteModal";

type AskFileUpdateProps = Omit<ModalProps, "children"> & {
	document: CertiblokDocument;
	toggleOpen: () => void;
};

const AskFileUpdate: React.FC<AskFileUpdateProps> = ({ document, toggleOpen, ...otherProps }) => {
	const { t, i18n } = useTranslation();

	const isMd = useBetterMediaQuery("md");
	const [reason, setReason] = useState("");
	const [language, setLanguage] = useState<SupportedLanguagesType>(i18n.language.startsWith("it") ? "it" : "en");
	const [submitLoading, setSubmitLoading] = useState(false);

	const documentApi = useApi(DocumentApi);

	const documentOwner = document.owner;

	useEffect(() => {
		setReason("");
	}, [document, otherProps.open]);

	const submit = useCallback(() => {
		setSubmitLoading(true);
		documentApi
			.requestDocumentUpdate(document.id, { message: reason, language: language })
			.catch((err) => {
				MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
			})
			.finally(() => {
				setSubmitLoading(false);
				toggleOpen();
				setLanguage("it");
			});
	}, [document.id, documentApi, reason, language, toggleOpen]);

	const header = (
		<Typography
			children={t("askFileUpdate.askFileTo", {
				name: documentOwner?.name || "",
				surname: documentOwner?.surname || "",
			})}
			variant="subtitle1"
			sx={{ paddingBottom: { xs: 0, md: 6 } }}
		/>
	);

	const body = (
		<>
			<Typography component="p" children={t("askFileUpdate.sendRequest")} variant="label" sx={{ paddingBottom: 1 }} />
			<Chip
				label={`${documentOwner?.name || ""} ${documentOwner?.surname || ""}`}
				color="primary"
				avatar={<Avatar src={backendMediaUrlParser(documentOwner?.profileUrl) ?? avatarPlaceholder} />}
			/>
			<div className="pt-3 flex justify-between items-center">
				<Typography variant="caption" sx={{ color: "rgba(0, 0, 0, 0.54)" }}>
					{t("request.selectLanguage")}
				</Typography>
				<LanguageSelector language={language} onLanguageChange={setLanguage} />
			</div>
			<Typography
				component="p"
				children={t("askFileUpdate.askUpdate")}
				variant="label"
				sx={{ paddingBottom: 1, paddingTop: 4 }}
			/>
			<DocumentWithIcon name={document.name} document={document.lastVersionFile?.[0]} bold sx={{ paddingBottom: 6 }} />
			<CertiblokTextInput
				label={t("askFileUpdate.insertNoteToRequest")}
				color="secondary"
				fullWidth
				containerCss={{ paddingTop: 12 }}
				value={reason}
				multiline
				onChange={(ev) => setReason(ev.target.value)}
				css={{ height: "auto" }}
				maxRows={10}
			/>
		</>
	);

	const footer = (
		<Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", paddingTop: 12 }}>
			<Button
				variant="outlined"
				color="secondary"
				onClick={() => {
					if (!submitLoading) {
						setLanguage("it");
						toggleOpen();
					}
				}}
			>
				<CertiblokIcon size={24} color="secondary" name={"close"} />
				{t("global.cancel")}
			</Button>
			<LoadingIconButton
				loading={submitLoading}
				icon={<CertiblokIcon size={24} color="white" name={"success"} />}
				variant="contained"
				color="secondary"
				onClick={submit}
			>
				{isMd ? t("askFileUpdate.sendUpdateRequest") : t("global.sendRequest")}
			</LoadingIconButton>
		</Box>
	);

	return isMd ? (
		<WhiteModal
			onClose={() => {
				if (!submitLoading) toggleOpen();
			}}
			responsive
			keepMounted={false}
			{...otherProps}
		>
			<Fragment>
				{header}
				{body}
				{footer}
			</Fragment>
		</WhiteModal>
	) : (
		<BottomSheet
			open={otherProps.open}
			onClose={() => {
				if (!submitLoading) toggleOpen();
			}}
			header={header}
			footer={footer}
			BodyProps={{
				className: "overflow-visible",
			}}
		>
			<div className="px-5">{body}</div>
		</BottomSheet>
	);
};

export default AskFileUpdate;
