import { CloudFile } from "certiblok-api-manager";
import throttle from "lodash/throttle";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Virtuoso } from "react-virtuoso";
import CertiblokTextInput from "../../../../../../utils/components/CertiblokTextInput";
import CertiblokIcon from "../../../../../../utils/components/icons/CertiblokIcon";
import NoContents from "../../../../../../utils/components/NoContents";
import ImportFromCloudContentBreadcrumbs from "../../components/ImportFromCloudContent/ImportFromCloudContentBreadcrumbs";
import ImportFromCloudContentLoading from "../../components/ImportFromCloudContent/ImportFromCloudContentLoading";
import SingleDocOrFolderRow from "../../components/ImportFromCloudContent/SingleDocOrFolderRow";
import { useGetCloudDocs } from "../../hooks/useHandleGetFolderAndDocs";
import { type CloudSource } from "../../ImportFromCloudContentModal";
import SortableHeader from "./SortableHeader";

const ChooseFilesStep = ({
	cloudSource,
	filesToImport,
	setFilesToImport,
	breadcrumbs,
	setBreadcrumbs,
}: {
	cloudSource?: CloudSource;
	filesToImport: CloudFile[];
	setFilesToImport: Dispatch<SetStateAction<CloudFile[]>>;
	breadcrumbs: CloudFile[];
	setBreadcrumbs: Dispatch<SetStateAction<CloudFile[]>>;
}) => {
	const { t } = useTranslation();

	const [search, setSearch] = useState("");
	const [throttledSearch, setThrottledSearch] = useState("");

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const throtthledFilterChange = useCallback(
		throttle(
			(value: string) => {
				setThrottledSearch(value);
			},
			500,
			{ trailing: true, leading: false }
		),
		[]
	);

	const [activeSorting, setActiveSorting] = useState<{ [key: string]: -1 | 1 | undefined }>({});

	const { data, fetchNextPage, hasNextPage, isLoading } = useGetCloudDocs({
		enabledCloud: cloudSource,
		search: throttledSearch,
		parentFolderId: breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1]?.id : undefined,
		// @ts-ignore
		sortObject: activeSorting,
	});

	const totalData =
		data?.pages.reduce((acc: CloudFile[], page) => {
			return [...acc, ...page.data];
		}, []) ?? [];

	const sortableFields = data?.pages?.[0]?.headers?.["sortable-fields"]?.split(",") ?? [];

	const isAllPageSelected =
		totalData.length > 0 && totalData.every((f) => filesToImport.map((f) => f.id).includes(f.id));

	return (
		<>
			<CertiblokTextInput
				size="small"
				className="w-full max-w-[550px]"
				endAdornment={<CertiblokIcon name="search_outline" color="black" size={20} />}
				value={search}
				onChange={(e) => {
					setSearch(e.target.value);
					throtthledFilterChange(e.target.value);
				}}
				containerCss={{ width: "100%", marginBottom: 16, paddingLeft: 8, paddingRight: 8 }}
				placeholder={t("cloudImport.searchbarPlaceholder")}
			/>
			<ImportFromCloudContentBreadcrumbs breadcrumbs={breadcrumbs} setBreadcrumbs={setBreadcrumbs} />
			<SortableHeader
				isAllPageSelected={isAllPageSelected}
				isDisabled={isLoading || totalData.length === 0}
				onSelectAll={() => {
					if (isAllPageSelected) {
						setFilesToImport((prev) => prev.filter((f) => !totalData.map((f) => f.id).includes(f.id)));
					} else {
						setFilesToImport((prev) => {
							const newArr = [...prev, ...totalData];
							const set = new Set(newArr);
							return Array.from(set);
						});
					}
				}}
				selectableFields={sortableFields}
				activeSorting={activeSorting}
				setActiveSorting={setActiveSorting}
			/>
			{isLoading ? (
				<ImportFromCloudContentLoading />
			) : totalData?.length === 0 ? (
				<div className="my-2">
					<NoContents variant="document" title={t("search.noDocumentsFound")} sx={{ py: 20 }} />
				</div>
			) : (
				<div className="flex-1 flex flex-col h-full">
					<Virtuoso
						style={{
							flex: 1,
						}}
						data={totalData ?? []}
						itemContent={(i, file) => {
							return (
								<SingleDocOrFolderRow
									file={file}
									selected={filesToImport.map((f) => f.id).includes(file.id)}
									onToggleSelection={() => {
										setFilesToImport((prev) => {
											if (filesToImport.map((f) => f.id).includes(file.id)) {
												return prev.filter((f) => f.id !== file.id);
											} else {
												return [...prev, file];
											}
										});
									}}
									onFolderClick={() => setBreadcrumbs((prev) => [...prev, file])}
								/>
							);
						}}
						endReached={() => {
							if (hasNextPage) {
								fetchNextPage();
							}
						}}
					/>
				</div>
			)}
		</>
	);
};

export default ChooseFilesStep;
