import { NonUndefined, UseFormReturn, useController } from "react-hook-form";
import { EditCreateEventForm } from "../../../../hooks/useEditCreateEventFormValidator";
import { MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { InputNumber } from "@mabi-ui/input-number";
import { CustomizationSelect } from "../../../CustomizationSelect";

type CustomizeReminderFormProps = {
	form: UseFormReturn<NonUndefined<EditCreateEventForm["reminder"]>>;
};
const CustomizeReminderForm: React.FC<CustomizeReminderFormProps> = ({ form }) => {
	const { t } = useTranslation();

	const { field: amountField } = useController({
		control: form.control,
		name: "amount",
	});

	const { field: isAtEventTimeField } = useController({
		control: form.control,
		name: "isAtEventTime",
	});
	const { field: unitField } = useController({
		control: form.control,
		name: "unit",
	});

	return (
		<div className="flex items-center gap-2">
			<Typography variant="body2" className="flex-1">
				{t("events.alert")}
			</Typography>

			{!isAtEventTimeField.value && (
				<InputNumber
					min={1}
					clampValueOnBlur
					value={amountField.value}
					onChange={(_, value) => amountField.onChange(value)}
					color="secondary"
					inputMode="numeric"
					aria-label={t("events.quantity")}
					shape="squared"
					variant="contained"
					arrows
					maxChars={3}
					className={"[&_.InputNumber-input]:text-secondary [&_.InputNumber-inputContainer]:rounded-xl"}
				/>
			)}
			<CustomizationSelect
				aria-label={t("events.unit")}
				value={isAtEventTimeField.value ? "at-event-time" : unitField.value}
				onChange={(ev) => {
					const newValue = ev.target.value;
					if (newValue === "at-event-time") {
						isAtEventTimeField.onChange(true);
					} else {
						if (isAtEventTimeField.value) {
							amountField.onChange(1);
						}
						isAtEventTimeField.onChange(false);
						unitField.onChange(newValue);
					}
				}}
				sx={{ minWidth: 140 }}
			>
				<MenuItem value={"at-event-time"}>
					<Typography variant="body2">{t("events.customReminderOptions.atEventTime")}</Typography>
				</MenuItem>
				<MenuItem value={"minutes"}>
					<Typography variant="body2">{t("events.customReminderOptions.minutesEarlier")}</Typography>
				</MenuItem>
				<MenuItem value={"hours"}>
					<Typography variant="body2">{t("events.customReminderOptions.hoursEarlier")}</Typography>
				</MenuItem>
				<MenuItem value={"days"}>
					<Typography variant="body2">{t("events.customReminderOptions.daysEarlier")}</Typography>
				</MenuItem>
				<MenuItem value={"weeks"}>
					<Typography variant="body2">{t("events.customReminderOptions.weeksEarlier")}</Typography>
				</MenuItem>
				<MenuItem value={"months"}>
					<Typography variant="body2">{t("events.customReminderOptions.monthsEarlier")}</Typography>
				</MenuItem>
			</CustomizationSelect>
		</div>
	);
};
export default CustomizeReminderForm;
