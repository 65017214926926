import { CalendarApi, CalendarEvent, CalendarEventTypeOfEventEnum } from "certiblok-api-manager";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useState } from "react";
import { useApi } from "../../../hooks/api";
import { useAbility } from "../../../hooks/useAbilityRules";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export const globalCalendarQueryKeys = {
	base: ["globalCalendar"],
	betweenDates: (startDate: DateTime, endDate: DateTime) => [
		...globalCalendarQueryKeys.base,
		startDate.toISO(),
		endDate.toISO(),
	],
};

export function useGlobalCalendarContext() {
	const { ability } = useAbility();
	const canReadCompany = ability.can("readAll", "Company");

	//* APIS
	const calendarApi = useApi(CalendarApi);
	const [calendarOpen, setCalendarOpen] = useState(false);
	const queryClient = useQueryClient();

	//* STATES
	const [selectedDay, setSelectedDay] = useState<DateTime | undefined>(DateTime.now());
	const [visualizedMonth, setVisualizedMonth] = useState<DateTime>(DateTime.now().startOf("month"));

	const [events, setEvents] = useState<CalendarEvent[]>([]);

	const { data: rawEvents, isLoading: loading } = useQuery({
		queryKey: globalCalendarQueryKeys.betweenDates(
			visualizedMonth.minus({ days: 7 }),
			visualizedMonth.endOf("month").plus({ days: 7 })
		),
		queryFn: () =>
			calendarApi.getCalendarEvents(visualizedMonth.minus({ days: 7 }).toISO()!, visualizedMonth.endOf("month").toISO()!),
		select: (data) => data.data,
		enabled: canReadCompany,
	});

	const [visibleEventsTypes, setVisibleEventsTypes] = useState<CalendarEventTypeOfEventEnum[]>([
		"audit_room",
		"document",
		"event",
	]);

	useEffect(() => {
		setEvents((rawEvents ?? []).filter((e) => visibleEventsTypes.includes(e.typeOfEvent)));
	}, [visibleEventsTypes, rawEvents]);

	const getDayEvents = useCallback(
		(day: DateTime) => {
			return events.filter((event) =>
				event.typeOfEvent === "document"
					? DateTime.fromISO(event.endDate).hasSame(day, "day")
					: day >= DateTime.fromISO(event?.startDate || "").startOf("day") &&
					  day <= DateTime.fromISO(event?.endDate || "").endOf("day")
			);
		},
		[events]
	);
	useEffect(() => {
		if (selectedDay && !selectedDay.hasSame(visualizedMonth, "month")) {
			setVisualizedMonth(selectedDay.startOf("month"));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDay]);

	const revalidateEvents = useCallback(
		() =>
			queryClient.invalidateQueries(
				globalCalendarQueryKeys.betweenDates(
					visualizedMonth.minus({ days: 7 }),
					visualizedMonth.endOf("month").plus({ days: 7 })
				)
			),
		[queryClient, visualizedMonth]
	);

	return {
		calendarOpen,
		setCalendarOpen,
		events,
		loading,
		getDayEvents,
		selectedDay,
		setSelectedDay,
		visualizedMonth,
		setVisualizedMonth,
		visibleEventsTypes,
		setVisibleEventsTypes,
		revalidateEvents,
	};
}

type GlobalContextType = ReturnType<typeof useGlobalCalendarContext>;

export const GlobalCalendarContext = React.createContext<GlobalContextType>({} as GlobalContextType);
