import { ButtonBase, IconButton, Typography } from "@mui/material";
import type { FavoriteFolderOrDocument } from "certiblok-api-manager";
import { Dispatch, SetStateAction, useRef } from "react";
import DocumentWithIcon from "../../../../utils/components/DocumentWithIcon";
import CertiblokIcon from "../../../../utils/components/icons/CertiblokIcon";

const FavoriteRow = ({
	element,
	openMenu,
	setRowMenuSpecs,
}: {
	element: FavoriteFolderOrDocument;
	openMenu: () => void;
	setRowMenuSpecs: Dispatch<
		SetStateAction<{ anchorEl: HTMLElement | null; folderOrDocument: FavoriteFolderOrDocument | undefined }>
	>;
}) => {
	const menuAnchorEl = useRef<HTMLButtonElement>(null);
	return (
		<ButtonBase
			className="px-1 py-1 bg-white rounded-lg flex flex-row justify-between h-12 box-border items-center gap-2 hover:bg-white/[0.74] transition-colors"
			href={element.isFolder ? `/folders/${element?.folder?.id}` : `/all_documents/document/${element?.document?.id}`}
			disableRipple
		>
			<div className="flex-1 w-0">
				{element.isFolder ? (
					<div className="flex flex-row w-full gap-2 items-center truncate px-1 py-1">
						<CertiblokIcon name={element.folder?.isReserved ? "lock_bold_02" : "folder_bold"} color={element.folder?.color} />
						<Typography variant="body2" sx={{ fontSize: "14px" }} noWrap>
							{element.folder?.name}
						</Typography>
					</div>
				) : (
					<DocumentWithIcon
						document={element.document?.lastVersionFile[0]}
						name={element?.document?.name ?? ""}
						sx={{
							textOverflow: "ellipsis",
							width: "fit-content",
							padding: "4px",
							gap: 2,
							":hover": { backgroundColor: "inherit" },
						}}
						typographySx={{ fontSize: "14px" }}
					/>
				)}
			</div>
			<IconButton
				sx={{ flexShrink: 0, isolation: "isolate" }}
				ref={menuAnchorEl}
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					setRowMenuSpecs({ anchorEl: menuAnchorEl.current, folderOrDocument: element });
					openMenu();
				}}
			>
				<CertiblokIcon name="more" color="black" size={20} />
			</IconButton>
		</ButtonBase>
	);
};

export default FavoriteRow;
