import { Outlet } from "react-router-dom";
import {
	EditRoomFolderHistoryContext,
	useEditRoomFolderHistoryContext,
} from "../controllers/EditRoomFolderHistoryContext";

const EditRoomFolderHistoryContextProvider = () => {
	const folderHistory = useEditRoomFolderHistoryContext();
	return (
		<EditRoomFolderHistoryContext.Provider value={folderHistory}>
			<Outlet />
		</EditRoomFolderHistoryContext.Provider>
	);
};

export default EditRoomFolderHistoryContextProvider;
