import { Collapse, Stack, Typography } from "@mui/material";
import { forwardRef, memo, useCallback } from "react";
import type { DropEvent, FileRejection } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { TransitionGroup } from "react-transition-group";
import { v4 as uuidv4 } from "uuid";
import { useBetterMediaQuery } from "../../../hooks/useBetterMediaQuery";
import DocumentPicker from "../../DocumentPicker";
import MLDialog from "../../poppers";
import { useAddFileToUpload, useFilesToUploadCount, useFileToUploadIds } from "../stores/filesToUploadStore";
import DocumentToUpload from "./DocumentToUpload";

const UploadDocumentPickDocument = forwardRef<any, any>((props, ref) => {
	const filesToUploadCount = useFilesToUploadCount();
	const { t } = useTranslation();
	const isMd = useBetterMediaQuery("md");

	const addFileToUpload = useAddFileToUpload();

	const fileToUploadIds = useFileToUploadIds();

	const onDrop = useCallback<
		<T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void
	>((acceptedFiles, fileRejections) => {
		acceptedFiles.forEach((file: File) => {
			const url = URL.createObjectURL(file);
			addFileToUpload({
				id: uuidv4(),
				file: file,
				dataUrl: url,
				uploaded: false,
				sendingChunks: false,
				loading: { isLoading: false },
			});
		});
		fileRejections.forEach((fileRejection) => {
			fileRejection.errors.forEach((error) => {
				if (error.code === "file-too-large") {
					MLDialog.showSnackbar(t("uploadDocument.fileTooLarge", { filename: fileRejection.file.name }), {
						variant: "error",
					});
				} else {
					MLDialog.showSnackbar(t("uploadDocument.fileRejection", { filename: fileRejection.file.name }), {
						variant: "error",
						autoHideDuration: 3000,
					});
				}
			});
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const chosenDocumentTypography = (
		<Typography
			children={t("uploadDocument.selectedDocuments")}
			className="pb-4 md:pb-6"
			sx={{
				typography: {
					xs: "subtitle2",
					md: "subtitle1",
				},
			}}
		/>
	);

	return (
		<div
			className="md:min-h-[452px] flex flex-col md:flex-row px-5 md:px-12 gap-6 md:gap-12 overflow-hidden"
			ref={ref}
			{...props}
		>
			<Stack
				sx={{
					boxSizing: "border-box",
					flexBasis: { xs: "unset", md: filesToUploadCount === 0 ? "100%" : "20%" },
					flexShrink: 0,
					height: {
						xs: filesToUploadCount === 0 ? 350 : 250,
						md: "auto",
					},
					transition: (theme) => theme.transitions.create(["flex-basis", "height"]),
					minWidth: 250,
				}}
			>
				<Typography
					children={filesToUploadCount === 0 ? t("uploadDocument.uploadDocument") : t("uploadDocument.uploadOtherDocuments")}
					className="pb-4 md:pb-6"
					sx={{
						typography: {
							xs: "subtitle2",
							md: "subtitle1",
						},
					}}
				/>
				<DocumentPicker
					onDrop={onDrop}
					css={{ height: "100%", margin: 0 }}
					externalContainerCss={{ flexGrow: 1, maxHeight: 500 }}
					multiple
				/>
			</Stack>
			<div className="flex flex-col flex-grow">
				{isMd ? chosenDocumentTypography : <Collapse in={fileToUploadIds.length > 0}>{chosenDocumentTypography}</Collapse>}
				<TransitionGroup className="max-h-[45vh] md:max-h-[55vh] overflow-y-auto">
					{fileToUploadIds.length > 0 &&
						fileToUploadIds.map((id) => (
							<Collapse key={id}>
								<DocumentToUpload fileId={id} />
							</Collapse>
						))}
					{/* : !isMd && (
								<Collapse key="pickFileToUploa">
									<NoContents variant="document" title={t("uploadDocument.chooseFileToContinue")} />
								</Collapse>
						  )} */}
				</TransitionGroup>
			</div>
		</div>
	);
});
export default memo(UploadDocumentPickDocument);
