import { Box, Divider, Link } from "@mui/material";
import type React from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { backendMediaUrlParser } from "../../../utils";
import { useModalNavigator } from "../../../utils/components/ModalNavigator/ModalNavigator";
import MLDialog from "../../../utils/components/poppers";
import RowMenu from "../../../utils/components/poppers/RowMenu";
import { useBetterMediaQuery } from "../../../utils/hooks/useBetterMediaQuery";
import { useMe } from "../../../utils/hooks/useMe";
import type { CertiblokDocument } from "../../../utils/interfaces/Document";
import { DocumentPopupButton } from "../../Folders/pages/SingleFolder/components/DocumentPopupMenu";
import { AllDocumentsContext } from "../controllers/AllDocumentsContext";
import DeleteDocumentsModal from "../modals/DeleteDocumentsModal";

type Props = {
	open: boolean;
	toggleOpen: () => void;
	anchorEl: HTMLElement | null;
	selectedDocument: CertiblokDocument;
	fromFavoritePage?: boolean;
};

const AllDocumentPopupMenu: React.FC<Props> = ({
	open,
	toggleOpen,
	anchorEl,
	selectedDocument,
	fromFavoritePage = false,
}) => {
	const sharedDocumentsContext = useContext(AllDocumentsContext);

	const isMd = useBetterMediaQuery("md");

	const me = useMe();
	const { t } = useTranslation();

	const { pushModal } = useModalNavigator();

	return (
		<RowMenu open={open} anchorEl={anchorEl} onClose={() => toggleOpen()}>
			<Box
				sx={{
					boxSizing: "border-box",
					backgroundColor: "surface.main",
					display: "flex",
					flexDirection: "column",
					padding: 1,
					borderRadius: 3,
				}}
			>
				<DocumentPopupButton
					onClick={() => {
						toggleOpen();
						sharedDocumentsContext.openAssignExpireDateModal([selectedDocument], fromFavoritePage);
					}}
					icon="calendar_outline"
				>
					{t("global.assignExpireDate")}
				</DocumentPopupButton>
				<DocumentPopupButton
					onClick={() => {
						toggleOpen();
						sharedDocumentsContext.updateExpirationDateMutation.mutate({
							documentIds: [selectedDocument.id],
							expireDate: "null",
							noExpiration: false,
							fromFavorite: fromFavoritePage,
						});
					}}
					icon="close"
				>
					{t("inbox.removeExpireDate")}
				</DocumentPopupButton>

				<Link
					href={backendMediaUrlParser(selectedDocument?.lastVersionFile?.[0]?.url, {
						download: true,
						filename: selectedDocument?.name,
					})}
					component="a"
					target="_blank"
					rel="noopener noreferrer"
					sx={{ textDecoration: "none" }}
				>
					<DocumentPopupButton
						onClick={() => {
							toggleOpen();
						}}
						icon="load_outline_02"
					>
						{t("documents.downloadFiles")}
					</DocumentPopupButton>
				</Link>

				{isMd && (
					<DocumentPopupButton
						onClick={() => {
							toggleOpen();
							sharedDocumentsContext.openAssignAuditRoomsModal([selectedDocument], fromFavoritePage);
						}}
						icon="profile_outline_01"
					>
						{t("global.assignAuditRoom")}
					</DocumentPopupButton>
				)}
				{isMd && (
					<DocumentPopupButton
						onClick={() => {
							toggleOpen();
							sharedDocumentsContext.openMoveDocumentsModal([selectedDocument], fromFavoritePage);
						}}
						icon="folder_outline"
					>
						{t("documents.move")}
					</DocumentPopupButton>
				)}

				<DocumentPopupButton
					onClick={() => {
						toggleOpen();
						sharedDocumentsContext.toggleDocumentFromPreferenceMutation.mutate(
							{ docId: selectedDocument.id, isPreference: selectedDocument.isFavorite ?? false },
							{
								onSuccess: () => {
									MLDialog.showSnackbar(
										t(!selectedDocument.isFavorite ? "favorites.isAddToFavorite" : "favorites.isRemovedFromFavorite"),
										{ variant: "success" }
									);
								},
							}
						);
					}}
					icon="star_outline"
				>
					{t(selectedDocument.isFavorite ? "documents.removeFromFavorite" : "documents.addToFavorite")}
				</DocumentPopupButton>

				<DocumentPopupButton
					onClick={() => {
						toggleOpen();
						sharedDocumentsContext.openRenameDocumentModal(selectedDocument, fromFavoritePage);
					}}
					icon="edit_outline_02"
				>
					{t("documents.renameFile")}
				</DocumentPopupButton>
				<DocumentPopupButton
					onClick={() => {
						toggleOpen();
						sharedDocumentsContext.openShareDocumentModal([selectedDocument]);
					}}
					icon="load_outline_01"
				>
					{t("documents.shareFiles")}
				</DocumentPopupButton>

				<DocumentPopupButton
					onClick={() => {
						toggleOpen();
						if (selectedDocument?.companyId === me.me?.companyId) {
							sharedDocumentsContext.openUpdateOrReplaceDocumentModal(selectedDocument, true);
						} else {
							sharedDocumentsContext.openAskDocumentUpdateModal(selectedDocument);
						}
					}}
					icon="paper_outline_03"
				>
					{selectedDocument?.companyId === me.me?.companyId ? t("documents.updateFile") : t("documents.askFileUpdate")}
				</DocumentPopupButton>

				{selectedDocument?.companyId !== me.me?.companyId && (
					<DocumentPopupButton
						onClick={() => {
							toggleOpen();
							sharedDocumentsContext.openUpdateOrReplaceDocumentModal(selectedDocument, false, fromFavoritePage);
						}}
						icon="swap_outline"
					>
						{t("documents.replaceFile")}
					</DocumentPopupButton>
				)}
				<Divider sx={{ my: 0.5 }} />
				<DocumentPopupButton
					color="error"
					sx={{ color: "error.main", gap: 2, justifyContent: "start", borderRadius: 2 }}
					onClick={() => {
						toggleOpen();
						pushModal(
							DeleteDocumentsModal,
							{
								onPositiveClick: async () => {
									if (selectedDocument)
										await sharedDocumentsContext.deleteDocumentsMutation.mutateAsync({ documentIds: [selectedDocument.id] });
									return Promise.resolve();
								},
								isMultiple: false,
							},
							{
								shouldHidePrev: true,
							}
						);
					}}
					icon="delete_outline"
				>
					{t("documents.deleteFile")}
				</DocumentPopupButton>
			</Box>
		</RowMenu>
	);
};

export default AllDocumentPopupMenu;
