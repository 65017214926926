import { useContext } from "react";
import { DocumentsTable } from "../../../../AllDocuments/components/Table";
import { AllDocumentsContext } from "../../../../AllDocuments/controllers/AllDocumentsContext";

const DocumentsByTagTable = () => {
	const { allDocumentsQuery, allDocumentsFetching, allDocumentsLoading } = useContext(AllDocumentsContext);
	return (
		<DocumentsTable
			data={allDocumentsQuery?.data ?? []}
			totalCount={allDocumentsQuery?.totalDocumentsCount ?? 0}
			isLoading={allDocumentsLoading}
			isFetching={allDocumentsFetching}
		/>
	);
};

export default DocumentsByTagTable;
