import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";
import { CompanyApi } from "certiblok-api-manager";
import { instanceApi } from "../hooks/api";

const editCreateFolderModalQueries = createQueryKeys("", {
	company: {
		queryKey: null,
		queryFn: () => instanceApi(CompanyApi).getCompanyWithMembers(),
	},
});

export function useCompany() {
	return useQuery({
		...editCreateFolderModalQueries.company,
	});
}
