import NiceModal from "@ebay/nice-modal-react";
import { ComponentProps, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import { useModalNavigator } from "../../components/ModalNavigator/ModalNavigator";

const useMLDialog = () => {
	const { pushModalById, popModal } = useModalNavigator();

	// const showModal = useCallback(
	// 	(props: Omit<ComponentProps<typeof MLDialogV2>, "children" | "onSuccess">) => {
	// 		const modalId = uuidv4();
	// 		NiceModal.register(modalId, MLDialogV2);
	// 		return pushModalById(modalId, props).then((res) => res as MLDialogCloseReason);
	// 	},
	// 	[pushModalById]
	// );

	const showCustomModal = useCallback(
		<T extends React.FC<any>>(
			component: T,
			props: Omit<ComponentProps<T>, "id">,
			options?: { shouldHidePrev?: boolean }
		) => {
			const modalId = uuidv4();
			NiceModal.register(modalId, component);
			return pushModalById(modalId, props, options);
		},
		[pushModalById]
	);

	return {
		// showModal,
		hideModal: popModal,
		showCustomModal,
	};
};
export default useMLDialog;
