/** @jsxImportSource @emotion/react */
import type { CSSObject } from "@emotion/styled";
import { alpha, Button, Fade, Typography, useTheme } from "@mui/material";
import type { CSSProperties } from "@mui/material/styles/createMixins";
import { forwardRef, LegacyRef, MouseEventHandler } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import CertiblokIcon from "./icons/CertiblokIcon";

type DocumentPickerProps = {
	files?: any[];
	onDrop: DropzoneOptions["onDrop"];
	multiple?: boolean;
	maxFiles?: number;
	css?: CSSObject;
	className?: string;
	border?: boolean;
	color?: CSSProperties["color"];
	hideContent?: boolean;
	onClick?: MouseEventHandler<HTMLDivElement>;
	onOpenRequest?: (openFunction: () => void) => void;
	externalContainerCss?: CSSObject;
};

const DocumentPicker = forwardRef((props: DocumentPickerProps, ref?: LegacyRef<HTMLInputElement>) => {
	const theme = useTheme();
	const { /*files: _initialFiles*/ onDrop, multiple, css = {} } = props;

	// const [files, setFiles] = useState<
	//   { id: string; url?: string; name?: string; externalId?: string }[]
	// >(
	//   (initialFiles || []).map((file) => {
	//     return {
	//       ...lastVersionFile,
	//       url: isValidHttpUrl(file.lastVersionFile)
	//         ? file.lastVersionFile || ""
	//         : backendMediaUrlParser(file.lastVersionFile),
	//     };
	//   })
	// );

	const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
		onDrop,
		maxFiles: props.maxFiles || undefined,
		// maxSize: 33_554_432, // 32MB in bytes: 32 * 1024 * 1024 = 33_554_432
		multiple: multiple ?? false,
	});

	const inputProps = getInputProps();

	const { t } = useTranslation();

	return (
		<div
			// container
			// direction="column"
			// spacing={2}
			{...getRootProps()}
			onClick={(e) => {
				// console.log(others.);

				if (props.onOpenRequest) {
					e.preventDefault();
					props.onOpenRequest(open);
				}

				if (!e.defaultPrevented) {
					open();
				}
			}}
			css={{
				width: "100%",
				height: "100%",
				"&:focus": {
					outline: "none",
					"div:first-of-type": {
						borderWidth: 2,
					},
				},
				...props.externalContainerCss,
			}}
		>
			<input {...inputProps} />
			<div
				className={props.className}
				css={{
					boxSizing: "border-box",
					flex: 1,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					gap: 3,
					zIndex: 3,
					justifyContent: "center",
					borderRadius: 12,
					marginTop: 16,
					borderWidth: 1,
					borderColor: theme.palette.secondary.main,
					borderStyle: isDragActive ? "dashed" : "solid",
					padding: 36,
					cursor: "pointer",
					transition: "500ms",
					backgroundColor: isDragActive ? alpha(theme.palette.lightblue.main, 0.12) : "unset",
					"&:hover": {
						backgroundColor: alpha(theme.palette.lightblue.main, 0.12),
					},
					...css,
				}}
			>
				<CertiblokIcon
					size={40}
					iconCss={{
						color: props?.color || theme.palette.secondary.main,
						backgroundColor: alpha(props?.color || theme.palette.secondary.main, 0.12),
						borderRadius: 32,
						padding: 12,
					}}
					name={"load_outline_01"}
				/>
				<Typography
					children={t("uploadDocument.dropFiles")}
					color="primary"
					css={{ color: props?.color || theme.palette.secondary.main, textAlign: "center" }}
				/>
				<Fade in={!isDragActive}>
					<Button children={t("uploadDocument.browseFiles")} color="secondary" variant="outlined" sx={{ marginTop: 3 }} />
				</Fade>
			</div>
		</div>
	);
});

export default DocumentPicker;
