import produce from "immer";
import { useMemo, useRef } from "react";
import { create } from "zustand";
import { shallow } from "zustand/shallow";
import type { IFileToUpload } from "../../../../pages/Folders/components/UploadDocument/interfaces/IFileToUpload";

type FilesToUploadStoreType = {
	addFile: (newFile: IFileToUpload) => void;
	deleteFile: (id: string) => void;
	editFile: <T extends IFileToUpload>(fileId: string, newFile: Partial<T>) => void;
	resetStore: () => void;
	files: {
		[x: string]: IFileToUpload;
	};
};

const useFilesToUploadStore = create<FilesToUploadStoreType>()((set, get) => ({
	files: {},
	addFile: (newFile) => {
		set(
			produce((state) => {
				state.files[newFile.id] = newFile;
			})
		);
	},
	editFile: (id, newFile) => {
		set(
			produce((state) => {
				state.files[id] = {
					...get().files[id],
					...newFile,
				};
			})
		);
	},
	deleteFile: (id) => {
		set(
			produce((state) => {
				delete state.files[id];
			})
		);
	},
	resetStore: () => {
		set({ files: {} });
	},
}));

export function useResetFilesToUpload() {
	return useFilesToUploadStore((state) => state.resetStore);
}

export function useFilesToUpload() {
	return useFilesToUploadStore((state) => Object.values(state.files));
}

export function getFileToUploadById(id: string) {
	return useFilesToUploadStore.getState().files[id];
}

export function useFileToUploadById(id: string) {
	const file = useFilesToUploadStore((state) => state.files[id]);
	const oldFile = useRef(file);

	return useMemo(() => {
		if (file) {
			oldFile.current = file;
			return file;
		} else {
			return oldFile.current;
		}
	}, [file]);
}

export function useFileToUploadIds() {
	return useFilesToUploadStore((state) => Object.keys(state.files), shallow);
}

export function useFilesToUploadCount() {
	return useFilesToUploadStore((state) => Object.keys(state.files).length, shallow);
}

export function useAreAllFilesUploaded() {
	return useFilesToUploadStore(
		(state) => Object.values(state.files).every((file) => file.uploaded || file.error),
		shallow
	);
}

export function useCanUploadDocuments() {
	return useFilesToUploadStore(
		(state) => !Object.values(state.files).some((file) => file.error || file.loading.isLoading),
		shallow
	);
}

export function useAddFileToUpload() {
	return useFilesToUploadStore((state) => state.addFile);
}

export function useRemoveFileToUpload() {
	return useFilesToUploadStore((state) => state.deleteFile);
}

export function useEditFileToUpload() {
	return useFilesToUploadStore((state) => state.editFile);
}
