import { cn } from "@mabi-ui/utils";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CertiblokIcon from "../../../../../utils/components/icons/CertiblokIcon";
import {
	ModalNavigator,
	useModalNavigator,
	useModalRoute,
} from "../../../../../utils/components/ModalNavigator/ModalNavigator";
import MLDialog from "../../../../../utils/components/poppers";
import WhiteModal from "../../../../../utils/components/WhiteModal";
import { useBetterMediaQuery } from "../../../../../utils/hooks/useBetterMediaQuery";
import { queryClient } from "../../../../../utils/reactQueryClient";
import { getContactSharedDocumentsQueryKey } from "../../../hooks/useGetContactSharedDocuments";
import {
	getGroupSharedDocumentsQueryKey,
	useUnshareDocumentsWitGroup,
} from "../../../hooks/useGetGroupSharedDocuments";
import { useUnshareDocumentsWithUser, useUnshareDocumentWithUser } from "../../../hooks/useUnshareDocumentWithUser";

export const UnshareDocumentsModal = ModalNavigator.create(
	({
		documentIds,
		userId,
		contactId,
		onMutate,
	}: {
		documentIds: string[];
		userId: string;
		contactId: string;
		onMutate?: () => void;
	}) => {
		const { t } = useTranslation();
		const isMd = useBetterMediaQuery("md");

		const { visible } = useModalRoute();
		const { popModal } = useModalNavigator();
		const isSingleDocument = documentIds.length === 1;

		const { mutate: unshareDocuments } = useUnshareDocumentsWithUser({
			onMutate: () => {
				popModal();
				onMutate?.();
				queryClient.setQueryData(getContactSharedDocumentsQueryKey(contactId), (oldData: any) => {
					return {
						...oldData,
						data: oldData?.data.map((d: any) => (documentIds.includes(d.documentId) ? { ...d, isActive: false } : d)),
					};
				});
			},
			onSettled: () => {
				queryClient.invalidateQueries(getContactSharedDocumentsQueryKey(contactId));
			},
			onSuccess: () => {
				MLDialog.showSnackbar(t("shareWithContact.sharingInterruptedWithSuccess"), { variant: "success" });
			},
		});

		const { mutate: unshareDocument } = useUnshareDocumentWithUser({
			onSuccess: () => {
				popModal();
				queryClient.invalidateQueries(getContactSharedDocumentsQueryKey(contactId));
				onMutate?.();
			},
		});

		return (
			<WhiteModal
				open={visible}
				onClose={() => popModal()}
				sx={{ margin: 4 }}
				containerSx={{ width: "100%", boxSizing: "border-box", maxWidth: { xs: "420px", md: "520px" } }}
			>
				<div className="">
					<div className="mb-6 md:w-16 w-12 md:h-16 h-12 w flex items-center justify-center rounded-full bg-alert/[0.12]">
						<CertiblokIcon name="comunication_outline_01" color="#FFD21F" size={isMd ? 40 : 32} />
					</div>
					<Typography variant={isMd ? "h4" : "h6"} className={cn(!isMd && "font-bold")}>
						{t("global.attention")}
					</Typography>
					<Typography variant={isMd ? "h6" : "body1"} component={"p"} className="mt-2 mb-8 text-black-a60">
						{isSingleDocument
							? t("shareWithContact.interruptSharingDescription")
							: t("shareWithContact.interruptSharingMultipleDescription")}
					</Typography>
					<div className="flex items-center justify-between gap-2 flex-wrap">
						<Button
							variant="outlined"
							size={isMd ? "medium" : "small"}
							color="secondary"
							sx={{ typography: { md: "button" } }}
							onClick={() => popModal()}
						>
							{isMd && <CertiblokIcon name="close" color="secondary" />}
							{t("global.cancel")}
						</Button>
						<Button
							variant="contained"
							size={isMd ? "medium" : "small"}
							color="error"
							sx={{ typography: { md: "button" } }}
							onClick={() => {
								if (isSingleDocument) {
									unshareDocument({ documentId: documentIds[0], userId });
								} else {
									unshareDocuments({ documentIds, userId });
								}
							}}
						>
							{t("shareWithContact.interruptSharing")}
						</Button>
					</div>
				</div>
			</WhiteModal>
		);
	}
);

export const UnshareDocumentsWithGroupModal = ModalNavigator.create(
	({ documentIds, groupId, onMutate }: { documentIds: string[]; groupId: string; onMutate?: () => void }) => {
		const { t } = useTranslation();
		const isMd = useBetterMediaQuery("md");

		const { visible } = useModalRoute();
		const { popModal } = useModalNavigator();
		const isSingleDocument = documentIds.length === 1;

		const { mutate: unshareDocuments } = useUnshareDocumentsWitGroup({
			onMutate: () => {
				popModal();
				onMutate?.();
				queryClient.setQueryData(getGroupSharedDocumentsQueryKey(groupId), (oldData: any) => {
					return {
						...oldData,
						data: oldData?.data.map((d: any) => (documentIds.includes(d.documentId) ? { ...d, isActive: false } : d)),
					};
				});
			},
			onSettled: () => {
				queryClient.invalidateQueries(getGroupSharedDocumentsQueryKey(groupId));
			},
			onSuccess: () => {
				MLDialog.showSnackbar(t("shareWithContact.sharingInterruptedWithSuccess"), { variant: "success" });
			},
		});

		return (
			<WhiteModal
				open={visible}
				onClose={() => popModal()}
				sx={{ margin: 4 }}
				containerSx={{ width: "100%", boxSizing: "border-box", maxWidth: { xs: "420px", md: "520px" } }}
			>
				<div className="">
					<div className="mb-6 md:w-16 w-12 md:h-16 h-12 w flex items-center justify-center rounded-full bg-alert/[0.12]">
						<CertiblokIcon name="comunication_outline_01" color="#FFD21F" size={isMd ? 40 : 32} />
					</div>
					<Typography variant={isMd ? "h4" : "h6"} className={cn(!isMd && "font-bold")}>
						{t("global.attention")}
					</Typography>
					<Typography variant={isMd ? "h6" : "body1"} component={"p"} className="mt-2 mb-8 text-black-a60">
						{isSingleDocument ? t("contactsGroups.interruptSharing") : t("contactsGroups.interruptMultipleSharing")}
					</Typography>
					<div className="flex items-center justify-between gap-2 flex-wrap">
						<Button
							variant="outlined"
							size={isMd ? "medium" : "small"}
							color="secondary"
							sx={{ typography: { md: "button" } }}
							onClick={() => popModal()}
						>
							{isMd && <CertiblokIcon name="close" color="secondary" />}
							{t("global.cancel")}
						</Button>
						<Button
							variant="contained"
							size={isMd ? "medium" : "small"}
							color="error"
							sx={{ typography: { md: "button" } }}
							onClick={() => {
								unshareDocuments({ documentIds, groupId });
							}}
						>
							{t("shareWithContact.interruptSharing")}
						</Button>
					</div>
				</div>
			</WhiteModal>
		);
	}
);
