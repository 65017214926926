import { useMutation } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { AuthApi } from "certiblok-api-manager";
import { instanceApi } from "../../../../../../../utils/hooks/api";
type Variant = "firstVerification" | "disableTwoFAVerification" | "standardVerification";
export function useVerifyCodeMutation<T extends Variant>({
	variant,
	onSuccess,
	onError,
}: {
	variant: T;
	onSuccess?: (
		res: T extends "firstVerification"
			? Awaited<ReturnType<AuthApi["executeFirstVerification"]>>
			: T extends "disableTwoFAVerification"
			? Awaited<ReturnType<AuthApi["reset2FA"]>>
			: Awaited<ReturnType<AuthApi["verifyCode"]>>
	) => void;
	onError?: (error: "InvalidCode" | "ExpiredCode" | AxiosError) => void;
}) {
	return useMutation({
		//@ts-expect-error
		mutationFn: async (code: string) => {
			switch (variant) {
				case "firstVerification":
					return instanceApi(AuthApi).executeFirstVerification({
						code,
					});
				case "disableTwoFAVerification":
					return instanceApi(AuthApi).reset2FA(code);
				case "standardVerification":
					return instanceApi(AuthApi).verifyCode({
						code,
					});
			}
		},
		onSuccess,
		onError: (err: AxiosError) => {
			const reason = err.response?.data?.message as "InvalidCode" | "ExpiredCode" | undefined | string;
			switch (reason) {
				case "InvalidCode":
					onError?.("InvalidCode");
					break;
				case "ExpiredCode":
					onError?.("ExpiredCode");
					break;
				default:
					onError?.(err);
					break;
			}
		},
	});
}
