import { alpha, Box, CheckboxProps, Fade, IconButton, useTheme } from "@mui/material";
import CertiblokIcon from "./icons/CertiblokIcon";

const BetterCheckbox: React.FC<CheckboxProps> = (props) => {
	const theme = useTheme();
	return (
		<IconButton
			color={props.color}
			onClick={props.onClick}
			onChange={props.onChange as unknown as any}
			sx={props.sx}
			disabled={props.disabled}
			className={props.className}
		>
			<Box
				sx={{
					width: 20,
					height: 20,
					backgroundColor: props.checked
						? theme.palette[props.color && props.color !== "default" ? props.color : "primary"].main
						: "transparent",
					borderWidth: 2,
					borderColor: !props.checked ? alpha("#000000", 0.38) : "transparent",
					borderStyle: "solid",
					boxSizing: "border-box",
					borderRadius: 1,
					transition: theme.transitions.create(["background-color", "border-color"]),
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flexShrink: 0,
				}}
			>
				<Fade in={props.checked}>
					<CertiblokIcon size={14} color="white" name={"success"} />
				</Fade>
			</Box>
		</IconButton>
	);
};

export default BetterCheckbox;
