import { Fade } from "@mui/material";
import { useContext } from "react";
import { useBetterMediaQuery } from "../../../../../../hooks/useBetterMediaQuery";
import { ScaffoldContext } from "../../../../controllers/ScaffoldContext";
import { DrawerAuthenticationInfo } from "./DrawerAuthenticationInfo";

export const DrawerUserCard = ({ onClick }: { onClick: () => void }) => {
	const { open } = useContext(ScaffoldContext);
	const isMd = useBetterMediaQuery("md");

	// const location = useLocation();
	// const isInProfileSettings = location.pathname.includes("profile/settings");
	// const isInTeam = location.pathname.includes("profile/team");

	// const { me } = useMe();
	// const { data: company } = useGetCompanyWithMembers();
	// const isGuest = (company?.adminIds ?? []).includes(me?.id ?? "");
	// const { ability } = useAbility();

	return (
		<div className="relative flex flex-col gap-6">
			<Fade in={open || !isMd} unmountOnExit mountOnEnter exit={false}>
				<div>
					<DrawerAuthenticationInfo />
				</div>
			</Fade>
			{/* <div className={twMerge(clsx("flex items-center justify-between gap-2", !open && isMd && "flex-col"))}>
				{isAdmin && !isGuest ? (
					<Button onClick={onClick} href="/profile/team" className={clsx(isInTeam && "bg-white")}>
						<CertiblokIcon name="profile_outline_03" />
						{(open || !isMd) && t("scaffold.inviteUsers")}
					</Button>
				) : (
					<div />
				)}
				<Button onClick={onClick} href="/profile/settings" className={clsx(isInProfileSettings && "bg-white")}>
					<CertiblokIcon name="settings_outline" />
				</Button>
			</div> */}
		</div>
	);
};
