declare global {
	interface String {
		capitalize(): string;
	}
}

// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function () {
	return `${this.charAt(0).toUpperCase()}${this.substring(1)}`;
};

export {};
