import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { CompanyApi, User } from "certiblok-api-manager";
import { instanceApi } from "../../../../../utils/hooks/api";
import { AxiosResponse } from "axios";

type GetCompanyAdminsProps =
	| {
			options?: Omit<UseQueryOptions<AxiosResponse<User[]>, unknown, User[], string[]>, "queryKey" | "queryFn">;
	  }
	| undefined;

export const getGetCompanyAdminsKey = () => ["company", "admins"];

export function useGetCompanyAdmins(props?: GetCompanyAdminsProps) {
	return useQuery({
		queryKey: getGetCompanyAdminsKey(),
		queryFn: () => instanceApi(CompanyApi).getAllCompanyAdmins(),
		select: (res) => res.data,
		...props?.options,
	});
}
