import { cn } from "@mabi-ui/utils";
import { Typography } from "@mui/material";
import { CBFile, CloudFile } from "certiblok-api-manager";
import { DateTime } from "luxon";
import BetterCheckbox from "../../../../../../utils/components/BetterCheckbox";
import CertiblokIcon from "../../../../../../utils/components/icons/CertiblokIcon";
import FileIcon from "../../../../../../utils/components/icons/FileIcon";

const SingleDocOrFolderRow = ({
	file,
	selected,
	onToggleSelection,
	onFolderClick,
}: {
	file: CloudFile;
	selected: boolean;
	onToggleSelection: () => void;
	onFolderClick: () => void;
}) => {
	return (
		<div
			className={cn(
				"grid grid-cols-[24px,64px,1fr,1fr] gap-4 items-center min-w-0 my-2 px-4 py-2 rounded-lg hover:bg-secondary/[0.06]",
				file.type === "folder" && "cursor-pointer"
			)}
			onClick={() => {
				if (file.type === "folder") {
					onFolderClick();
				} else {
					onToggleSelection();
				}
			}}
		>
			<BetterCheckbox
				checked={selected}
				color="secondary"
				className={"p-0"}
				onClick={(e) => {
					if (file.type === "folder") {
						e.stopPropagation();
						onToggleSelection();
					}
				}}
			/>
			<div className="m-auto h-6">
				{file.type === "folder" ? (
					<CertiblokIcon name="folder_bold" color="#b0b0b0" />
				) : (
					<FileIcon file={{ type: file.mimeType } as CBFile} />
				)}
			</div>
			<Typography noWrap className="select-none px-[10px]">
				{file.name}
			</Typography>
			<Typography noWrap className="select-none px-[10px]">
				{/*  @ts-expect-error - missing type */}
				{file?.updatedAt && DateTime.fromISO(file.updatedAt).toFormat("dd/LL/yyyy")}
			</Typography>
		</div>
	);
};

export default SingleDocOrFolderRow;
