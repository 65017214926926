import { zodResolver } from "@hookform/resolvers/zod";
import { cn } from "@mabi-ui/utils";
import { Button, IconButton, Typography } from "@mui/material";
import { Tag } from "certiblok-api-manager";
import { FormEvent } from "react";
import { useController, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import CertiblokTextInput from "../../../../../utils/components/CertiblokTextInput";
import LoadingTextButton from "../../../../../utils/components/LoadingTextButton";
import {
	ModalNavigator,
	useModalNavigator,
	useModalRoute,
} from "../../../../../utils/components/ModalNavigator/ModalNavigator";
import WhiteModal from "../../../../../utils/components/WhiteModal";
import { AvailableFolderColors } from "../../../../Folders/constants/AvailableFolderColors";
import { useCreateNewTag, useEditTag } from "../../../hooks/useTagsHooks";

const EditCreateTag = ModalNavigator.create(
	({ oldTag, onEditCreateSuccess }: { oldTag?: Partial<Tag>; onEditCreateSuccess?: (tag: Tag) => void }) => {
		const { t } = useTranslation();

		const { visible } = useModalRoute();
		const { popModal } = useModalNavigator();

		const formSchema = z.object({
			name: z.string().min(1, { message: t("tags.tagShouldHaveAName") }),
			color: z.string().min(1),
		});

		const { reset, control, handleSubmit } = useForm({
			resolver: zodResolver(formSchema),
			defaultValues: {
				name: oldTag?.name ?? "",
				color: oldTag?.color ?? AvailableFolderColors[0],
			},
		});

		const onClose = () => {
			reset();
			popModal();
		};

		const onSuccess = (tag: Tag) => {
			onEditCreateSuccess?.(tag);
			onClose();
		};

		const {
			field: nameField,
			fieldState: { error: nameError },
		} = useController({
			control,
			name: "name",
		});
		const { field: colorField } = useController({
			control,
			name: "color",
		});

		const { mutate: createTag, isLoading: isCreating } = useCreateNewTag({ onSuccess });
		const { mutate: editTag, isLoading: isEditing } = useEditTag({ onSuccess });

		const onSubmit = (e: FormEvent) => {
			e.preventDefault();
			handleSubmit((data) => {
				if (oldTag?.id) {
					editTag({ id: oldTag.id ?? "", name: data.name, color: data.color });
				} else {
					createTag({ name: data.name, color: data.color });
				}
			})();
		};

		return (
			<WhiteModal
				open={visible}
				onClose={() => onClose()}
				containerSx={{
					minWidth: { md: "400px", xs: "0px" },
					width: { md: "436px", xs: "290px" },
					maxWidth: { xs: "90vh" },
				}}
			>
				<form onSubmit={onSubmit}>
					<div>
						<CertiblokTextInput
							value={nameField.value}
							onChange={nameField.onChange}
							onBlur={nameField.onBlur}
							disabled={nameField.disabled}
							status={nameError ? "error" : undefined}
							helperText={nameError?.message}
							label={t("tags.tagName")}
							color="secondary"
							placeholder={t("tags.chooseANameForTheTag")}
							fullWidth
						/>
						<div className="mt-6">
							<Typography variant="label" className="mb-1">
								{t("tags.chooseTagColor")}
							</Typography>
							<div className="">
								<div className="flex flex-wrap">
									{AvailableFolderColors.map((elem, index) => {
										return (
											<IconButton
												key={index}
												sx={{
													color: elem,
													width: 48,
													height: 48,
												}}
												onClick={() => colorField.onChange(elem)}
											>
												<div
													className={cn(
														"w-6 h-6 rounded-full border-solid",
														elem === colorField.value ? "border-4 border-secondary" : "border-2 border-white"
													)}
													style={{
														backgroundColor: elem,
													}}
												/>
											</IconButton>
										);
									})}
								</div>
							</div>
						</div>
						<div className="flex w-full justify-between items-center mt-6 gap-3">
							<Button variant="outlined" color="secondary" onClick={onClose} className="px-6">
								{t("global.cancel")}
							</Button>
							<LoadingTextButton
								variant="contained"
								color="secondary"
								onClick={onSubmit}
								className="px-6"
								loading={isCreating || isEditing}
							>
								{oldTag?.id ? t("tags.editTag") : t("tags.createTag")}
							</LoadingTextButton>
						</div>
					</div>
				</form>
			</WhiteModal>
		);
	}
);

export default EditCreateTag;
