"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.7.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DriveApi = exports.DriveApiFactory = exports.DriveApiFp = exports.DriveApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * DriveApi - axios parameter creator
 * @export
 */
const DriveApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get drive folder
         * @param {string} [pageToken]
         * @param {string} [query]
         * @param {string} [parentId]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDriveFolder: (pageToken, query, parentId, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/drive/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (pageToken !== undefined) {
                localVarQueryParameter['pageToken'] = pageToken;
            }
            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }
            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }
            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Import files from drive
         * @param {InputImportFilesFromDrive} inputImportFilesFromDrive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importFilesFromDrive: (inputImportFilesFromDrive, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'inputImportFilesFromDrive' is not null or undefined
            (0, common_1.assertParamExists)('importFilesFromDrive', 'inputImportFilesFromDrive', inputImportFilesFromDrive);
            const localVarPath = `/drive/import/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputImportFilesFromDrive, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.DriveApiAxiosParamCreator = DriveApiAxiosParamCreator;
/**
 * DriveApi - functional programming interface
 * @export
 */
const DriveApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.DriveApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Get drive folder
         * @param {string} [pageToken]
         * @param {string} [query]
         * @param {string} [parentId]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDriveFolder(pageToken, query, parentId, sort, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getDriveFolder(pageToken, query, parentId, sort, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Import files from drive
         * @param {InputImportFilesFromDrive} inputImportFilesFromDrive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importFilesFromDrive(inputImportFilesFromDrive, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.importFilesFromDrive(inputImportFilesFromDrive, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.DriveApiFp = DriveApiFp;
/**
 * DriveApi - factory interface
 * @export
 */
const DriveApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.DriveApiFp)(configuration);
    return {
        /**
         *
         * @summary Get drive folder
         * @param {string} [pageToken]
         * @param {string} [query]
         * @param {string} [parentId]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDriveFolder(pageToken, query, parentId, sort, options) {
            return localVarFp.getDriveFolder(pageToken, query, parentId, sort, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Import files from drive
         * @param {InputImportFilesFromDrive} inputImportFilesFromDrive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importFilesFromDrive(inputImportFilesFromDrive, options) {
            return localVarFp.importFilesFromDrive(inputImportFilesFromDrive, options).then((request) => request(axios, basePath));
        },
    };
};
exports.DriveApiFactory = DriveApiFactory;
/**
 * DriveApi - object-oriented interface
 * @export
 * @class DriveApi
 * @extends {BaseAPI}
 */
class DriveApi extends base_1.BaseAPI {
    /**
     *
     * @summary Get drive folder
     * @param {string} [pageToken]
     * @param {string} [query]
     * @param {string} [parentId]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriveApi
     */
    getDriveFolder(pageToken, query, parentId, sort, options) {
        return (0, exports.DriveApiFp)(this.configuration).getDriveFolder(pageToken, query, parentId, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Import files from drive
     * @param {InputImportFilesFromDrive} inputImportFilesFromDrive
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriveApi
     */
    importFilesFromDrive(inputImportFilesFromDrive, options) {
        return (0, exports.DriveApiFp)(this.configuration).importFilesFromDrive(inputImportFilesFromDrive, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.DriveApi = DriveApi;
