function isValidOfficeViewerDocument(documentType: string) {
	return (
		documentType.includes("vnd.openxmlformats-officedocument.wordprocessingml.document") ||
		documentType.includes("msword") ||
		documentType.includes("opendocument.text") ||
		documentType.includes("ms-excel") ||
		documentType.includes("spreadsheetml") ||
		documentType.includes("application/vnd.oasis.opendocument.spreadsheet") ||
		documentType.includes("presentation") ||
		documentType.includes("application/vnd.oasis.opendocument.presentation") ||
		documentType.includes("application/vnd.openxmlformats-officedocument.presentationml.presentation") ||
		documentType.includes("ms-powerpoint")
	);
}

export function getPreviewType(mimeType: string) {
	if (mimeType.includes("dxf") || mimeType.includes("dwg")) {
		return "dxf";
	}
	if (mimeType.includes("video")) {
		return "video";
	}
	if (isValidOfficeViewerDocument(mimeType)) {
		return "office";
	}
	if (mimeType.includes("pdf")) {
		return "pdf";
	}
	if (mimeType.includes("image")) {
		return "image";
	}
	if (mimeType.includes("model/stl")) {
		return "stl";
	}
	return null;
}
