import { Button } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { CompanyApi, FolderOrDocument } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import LoadingIconButton from "../../../../../utils/components/buttons/LoadingIconButton";
import IconDialog from "../../../../../utils/components/dialogs/IconDialog";
import CertiblokIcon from "../../../../../utils/components/icons/CertiblokIcon";
import {
	ModalNavigator,
	useModalNavigator,
	useModalRoute,
} from "../../../../../utils/components/ModalNavigator/ModalNavigator";
import MLDialog from "../../../../../utils/components/poppers";
import { apiErrorParser, instanceApi } from "../../../../../utils/hooks/api";
import { useBetterMediaQuery } from "../../../../../utils/hooks/useBetterMediaQuery";

type DeleteSelectedItemsDialogProps = {
	items: FolderOrDocument[];
	onSuccess?: () => void;
};
const DeleteSelectedItemsDialog = ModalNavigator.create<DeleteSelectedItemsDialogProps>(({ items, onSuccess }) => {
	const isMd = useBetterMediaQuery("md");
	const { t } = useTranslation();
	const { visible } = useModalRoute();
	const { popModal } = useModalNavigator();

	const deleteItemsMutation = useMutation({
		mutationFn: () => {
			return instanceApi(CompanyApi).deleteItems(
				items.map((item) => ({
					id: item.id,
					isFolder: item.isFolder,
				}))
			);
		},
		onSuccess: () => {
			popModal();
			onSuccess?.();
			MLDialog.showSnackbar(t("global.itemsDeletionInProgress"), { variant: "success" });
		},
		onError: (err) => {
			MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
		},
	});

	const onClose = () => {
		if (deleteItemsMutation.isLoading) return;
		popModal();
	};

	return (
		<IconDialog
			open={visible}
			onClose={onClose}
			icon="comunication_outline_01"
			IconProps={{
				iconColor: "orange",
				containerClassName: "bg-[rgba(255,149,0,0.12)]",
			}}
			DialogProps={{
				className: "flex flex-col items-center",
			}}
			title={t("global.attention")}
			description={t("folders.deleteFolderItem", { count: items.length })}
			DescriptionProps={{ className: "text-center" }}
			ModalProps={{
				responsive: false,
				containerSx: {
					maxWidth: 440,
				},
			}}
			footer={
				<div className="flex items-center justify-between gap-2 md:pt-9">
					<Button
						disabled={deleteItemsMutation.isLoading}
						onClick={onClose}
						size={isMd ? "large" : "small"}
						children={
							<>
								<CertiblokIcon size={24} color="inherit" name={"close"} />
								{t("global.cancel")}
							</>
						}
					/>
					<LoadingIconButton
						loading={deleteItemsMutation.isLoading}
						onClick={() => {
							deleteItemsMutation.mutate();
						}}
						icon={<CertiblokIcon size={24} color="inherit" name={"delete_bold"} />}
						color="error"
						variant="contained"
						size={isMd ? "large" : "small"}
						children={<>{t("global.delete")}</>}
					/>
				</div>
			}
		/>
	);
});
export default DeleteSelectedItemsDialog;
