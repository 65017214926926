import { AuditApi, DocumentApi, EditRoomApi, Folder, InputEditDocument } from "certiblok-api-manager";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useBottomSheetOld } from "../../../utils/components/bottomSheet/hooks/useBottomSheet";
import MLDialog from "../../../utils/components/poppers";
import { useApi, useFetch } from "../../../utils/hooks/api";
import type { CertiblokDocument, CertiblokDocumentReview } from "../../../utils/interfaces/Document";
import { queryClient } from "../../../utils/reactQueryClient";
import { treeViewQueryKeys } from "../../../utils/controllers/TreeViewController/queries/treeViewQueries";
import { editRoomFolderQueries } from "../../EditRooms/pages/EditRoom/queries/editRoomFolderQueries";
import { getGetEditRoomChangesHistoryKey } from "../../EditRooms/components/EditRoomHistoryModal/hooks/useGetEditRoomChangesHistory";

export function useDocumentContext(documentId: string) {
	const { t } = useTranslation();
	//* PAGE PARAMS
	const { auditRoomId } = useParams();
	//* APIS
	const documentApi = useApi(DocumentApi);
	const auditRoomApi = useApi(AuditApi);
	const editRoomApi = useApi(EditRoomApi);

	const location = useLocation();
	const isEditRoom = location?.pathname?.includes("/edit_rooms/");

	//* STATES
	const [document, setDocument] = useState<CertiblokDocument | undefined>();
	const [loading, setLoading] = useState(false);
	const [editDocumentLoading, setEditDocumentLoading] = useState(false);
	const [selectedVersion, setSelectedVersion] = useState<{ versionNumber: number; fileId: string } | undefined>();
	const [parentFolder, setParentFolder] = useState<Folder | undefined>();
	const [revalidateLoading, setRevalidateLoading] = useState(false);
	const [documentNotFound, setDocumentNotFound] = useState(false);

	const { data: currentAuditRoom, loading: currentAuditRoomLoading } = useFetch(
		auditRoomApi.singleAuditRoom,
		auditRoomId ?? "",
		{ skip: !auditRoomId }
	);

	useEffect(() => {
		if (document) revalidateDocument(document.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedVersion]);

	//* HANDLER
	const editDocument = useCallback((documentId: string, input: InputEditDocument) => {
		setEditDocumentLoading(true);

		if (isEditRoom) {
			return editRoomApi
				.updateEditRoomDocument(documentId, input)
				.then((resp) => {
					setEditDocumentLoading(false);
					if (resp.data) {
						setDocument(resp.data as CertiblokDocument);
						setParentFolder(resp.data.parentFolder);
					}
				})
				.catch((e) =>
					MLDialog.showSnackbar(t("documents.errorInEdit", { documentName: document?.name }), { variant: "error" })
				);
		}

		return documentApi
			.editDocument(documentId, input)
			.then((resp) => {
				setEditDocumentLoading(false);
				if (resp.data) {
					setDocument(resp.data as CertiblokDocument);
					setParentFolder(resp.data.parentFolder);
				}
			})
			.catch((e) =>
				MLDialog.showSnackbar(t("documents.errorInEdit", { documentName: document?.name }), { variant: "error" })
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchDocument = useCallback(
		(documentId: string) => {
			setLoading(true);

			if (isEditRoom) {
				editRoomApi
					.getSingleEditRoomDocument(documentId, selectedVersion?.fileId)
					.then((resp) => {
						if (resp.data) {
							setDocument(resp.data as CertiblokDocument);
							setParentFolder(resp.data.parentFolder);
						}
					})
					.catch((err) => {
						if (err.isAxiosError && err.response.status === 400) {
							setDocumentNotFound(true);
						}
					})
					.finally(() => {
						setLoading(false);
					});
			} else {
				documentApi
					.getSingleDocument(documentId, selectedVersion?.fileId)
					.then((resp) => {
						if (resp.data) {
							setDocument(resp.data as CertiblokDocument);
							setParentFolder(resp.data.parentFolder);
						}
					})
					.catch((err) => {
						if (err.isAxiosError && err.response.status === 400) {
							setDocumentNotFound(true);
						}
					})
					.finally(() => {
						setLoading(false);
					});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedVersion]
	);

	const revalidateDocument = useCallback(
		(documentId: string) => {
			setRevalidateLoading(true);
			if (isEditRoom) {
				queryClient.invalidateQueries(treeViewQueryKeys.base);
				queryClient.invalidateQueries(editRoomFolderQueries.editRoomFolderById.base(document?.parentFolderId ?? ""));
				queryClient.invalidateQueries(getGetEditRoomChangesHistoryKey());

				editRoomApi
					.getSingleEditRoomDocument(documentId, selectedVersion?.fileId)
					.then((resp) => {
						if (resp.data) {
							setDocument(resp.data as CertiblokDocument);
							setParentFolder(resp.data.parentFolder);
						}
					})
					.catch((e) => MLDialog.showSnackbar(t("documents.errorInUploading"), { variant: "error" }))
					.finally(() => {
						setRevalidateLoading(false);
					});
			} else {
				queryClient.invalidateQueries(["documentLogs", documentId]);
				documentApi
					.getSingleDocument(documentId, selectedVersion?.fileId)
					.then((resp) => {
						if (resp.data) {
							setDocument(resp.data as CertiblokDocument);
							setParentFolder(resp.data.parentFolder);
						}
					})
					.catch((e) => MLDialog.showSnackbar(t("documents.errorInUploading"), { variant: "error" }))
					.finally(() => {
						setRevalidateLoading(false);
					});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedVersion]
	);

	const mutateDocument = useCallback(
		(partialDocument: Partial<Omit<CertiblokDocument, "id">>) => {
			if (document) {
				setDocument({ ...document, ...partialDocument });
			}
		},
		[document]
	);

	const currentFile = useMemo(() => {
		return document?.files.find((file) => file.id === (selectedVersion?.fileId ?? document.lastVersionFile?.[0]?.id));
	}, [document, selectedVersion?.fileId]);

	const [auditRoomReview, setAuditRoomReview] = useState<CertiblokDocumentReview | undefined>();

	const findAuditRoom = useCallback(
		(id: string) => {
			setAuditRoomReview((document?.reviews ?? []).find((elem) => elem.auditId === id));
			// setAuditRoomReview({
			// 	id: "",
			// 	note:
			// 		"siuuuuuuuuusdgbsdfgbjhksdfjhksdfhjksdfjhksdfjasdasdasdadasdadasdamsdfgjklasdfgjksdfgjhkadfgjhkdgfjhkdfgajhklfgjhkdfgajhdfgjhkgdfjkhlfdgjhkldfgjhkladfgkjghdfkjghdfjkgsdfklghsdfkgdfklgjdfklghsdfklgjsdklghsdkdfkghdskhksd",
			// 	status: "approved",
			// 	auditRoom: FakeAuditRoom[0],
			// 	createdAt: DateTime.now().toISO(),
			// 	updatedAt: DateTime.now().toISO(),
			// });
		},
		[document]
	);

	const assignToFolderBSControls = useBottomSheetOld();

	return {
		document,
		loading,
		revalidateLoading,
		fetchDocument,
		revalidateDocument,
		editDocumentLoading,
		editDocument,
		parentFolder,
		auditRoomReview,
		findAuditRoom,
		assignToFolderBSControls,
		mutateDocument,
		selectedVersion,
		setSelectedVersion,
		currentFile,
		documentNotFound,
		currentAuditRoom,
		currentAuditRoomLoading,
	};
}

type DocumentContextType = ReturnType<typeof useDocumentContext>;

export const DocumentContext = React.createContext<DocumentContextType>({} as DocumentContextType);
