import { TableCell, TableRow } from "@mui/material";
import { Tag } from "certiblok-api-manager";
import { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";
import BetterCheckbox from "../../../utils/components/BetterCheckbox";
import { IHeadCell } from "../../../utils/components/tables/AdminTable";
import TouchTargetCell from "../../../utils/components/tables/cells/TouchTargetCell";
import SingleTag from "../../SingleDocument/components/Tags/SingleTag";
import { TagsContext } from "../contexts/TagsContext";
import TagTableRowOptions from "./TagTableRowOptions";

type Props = {
	tag: Tag;
	headCells: IHeadCell[];
	checked: boolean;
	onSelect: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	shouldShowOptions?: boolean;
};

const TagTableRow: FC<Props> = ({ checked, onSelect, headCells, shouldShowOptions, tag }) => {
	const navigate = useNavigate();
	const { revalidateTags } = useContext(TagsContext);
	return (
		<TableRow
			sx={{
				cursor: "unset !important",
				"&:hover": {
					backgroundColor: "unset !important",
				},
			}}
		>
			<TouchTargetCell>
				<BetterCheckbox
					color="secondary"
					size="small"
					sx={{ width: 32, height: 32 }}
					checked={checked}
					onClick={onSelect}
				/>
			</TouchTargetCell>
			<TableCell colSpan={headCells?.[0]?.colSpan ?? 0}>
				<div className="w-fit bg-white rounded-lg">
					<SingleTag
						tag={tag}
						size="large"
						className="max-w-full w-fit"
						onClick={() => {
							navigate(`/tags/${tag.id}`);
						}}
					/>
				</div>
			</TableCell>
			<TouchTargetCell>
				<TagTableRowOptions
					shouldShowOptionButton={shouldShowOptions ?? true}
					tag={tag}
					onSuccess={() => {
						revalidateTags();
					}}
				/>
			</TouchTargetCell>
		</TableRow>
	);
};

export default TagTableRow;
