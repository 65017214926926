import NiceModal from "@ebay/nice-modal-react";
import { Button, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { EditEventInstanceMode, EventApi } from "certiblok-api-manager";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { apiErrorParser, useApi } from "../../../../../../hooks/api";
import CertiblokTextInput from "../../../../../CertiblokTextInput";
import { useModalNavigator, useModalRoute } from "../../../../../ModalNavigator/ModalNavigator";
import LoadingIconButton from "../../../../../buttons/LoadingIconButton";
import ResponsiveDialog from "../../../../../dialogs/ResponsiveDialog";
import CertiblokIcon from "../../../../../icons/CertiblokIcon";
import MLDialog from "../../../../../poppers";

type GeneralEventDeletionProps = {
	eventInstanceId?: string;
	isMyEvent: boolean;
	onDelete?: () => void;
	noOtherParticipant?: boolean;
	deleteMode: EditEventInstanceMode;
};

export const GeneralEventDeletion = NiceModal.create<GeneralEventDeletionProps>(
	({ eventInstanceId, isMyEvent, onDelete, noOtherParticipant, deleteMode }) => {
		const { t } = useTranslation();
		const { visible } = useModalRoute();
		const { dismissModal, popTo } = useModalNavigator();

		const [notes, setNotes] = useState("");

		const onClose = () => {
			dismissModal();
			setNotes("");
		};

		const onSuccess = () => {
			onDelete?.();
			MLDialog.showSnackbar(t("event.eventDeletedWithSuccess"), { variant: "success" });
			onClose();
		};

		const onCancel = () => {
			popTo(0);
			setNotes("");
		};

		const eventsApi = useApi(EventApi);
		const { mutate: deleteEvent, isLoading: isDeleting } = useMutation({
			mutationFn: async () => {
				if (isMyEvent) {
					await eventsApi.deleteEvent({ eventInstanceId: eventInstanceId ?? "", mode: deleteMode, annotation: notes });
				} else {
					await eventsApi.reviewParticipation({ eventOrOccurranceId: eventInstanceId ?? "", newStatus: "deleted" });
				}
			},
			onSuccess: () => onSuccess(),
			onError: (err) => MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" }),
		});

		return (
			<ResponsiveDialog
				open={visible}
				onClose={() => onCancel()}
				ModalProps={{
					containerSx: {
						borderRadius: 6,
					},
					responsive: true,
				}}
				header={
					<div className="flex items-center justify-center flex-col gap-6">
						<div className="p-3 bg-[rgba(252,147,1,0.12)] rounded-full">
							<CertiblokIcon name="comunication_outline_01" className="text-[rgb(252,147,1)]" size={48} />
						</div>
						<Typography variant="h4">{t("global.attention")}</Typography>
					</div>
				}
				footer={
					<div className="flex justify-between items-center gap-4">
						<Button
							variant="text"
							size="large"
							color="secondary"
							disabled={isDeleting}
							startIcon={<CertiblokIcon name="close" color="secondary" />}
							onClick={() => onCancel()}
						>
							{t("global.cancel")}
						</Button>
						<LoadingIconButton
							variant="contained"
							size="large"
							color="error"
							className="flex-1 max-w-[350px]"
							loading={isDeleting}
							icon={<CertiblokIcon name="delete_bold" color="white" />}
							onClick={() => {
								deleteEvent();
							}}
						>
							{t("global.delete")}
						</LoadingIconButton>
					</div>
				}
			>
				<div className=" px-6 md:px-0">
					<Typography className="mt-3 mb-8">
						{isMyEvent ? t("event.deleteForAllDescription") : t("event.deleteForYouDescription")}
					</Typography>
					{isMyEvent && !noOtherParticipant && (
						<CertiblokTextInput
							onChange={(e) => setNotes(e.target.value)}
							value={notes}
							fullWidth
							multiline
							minRows={3}
							color="secondary"
							label={t("event.addNote")}
							componentsProps={{ input: { className: "text-sm" } }}
							className="mb-12"
							placeholder={t("event.deletionNoteMessage")}
						/>
					)}
				</div>
			</ResponsiveDialog>
		);
	}
);
