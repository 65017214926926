import { cn } from "@mabi-ui/utils";
import { IconButton, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AvailableFolderColors } from "../../../Folders/constants/AvailableFolderColors";

const ColorSelector = () => {
	const { t } = useTranslation();

	const { setValue, watch } = useFormContext();

	// const colorValue = getValues("color");
	const watchColor = watch("color");
	return (
		<div>
			<Typography variant="label" className="mb-1">
				{t("teamGroups.chooseAColor")}
			</Typography>
			<div className="md:max-w-[680px] overflow-x-scroll">
				<div className="flex  flex-wrap">
					{AvailableFolderColors.map((elem, index) => {
						return (
							<IconButton
								key={index}
								onClick={() => {
									setValue("color", elem);
								}}
								sx={{
									color: elem,
									width: 48,
									height: 48,
								}}
							>
								<div
									className={cn(
										"w-6 h-6 rounded-full border-solid",
										elem === watchColor ? "border-4 border-secondary" : "border-2 border-white"
									)}
									style={{
										backgroundColor: elem,
									}}
								/>
							</IconButton>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default ColorSelector;
