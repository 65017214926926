/** @jsxImportSource @emotion/react */
import { Fade } from "@mui/material";
import { Fragment } from "react";
import logotype from "../../../../assets/logo/logotype.png";
import symbol from "../../../../assets/logo/symbol.png";

export const CollapsableImage = ({ open }: { open: boolean }) => {
	return (
		<Fragment>
			<Fade in={open} easing={"ease-in-out"} timeout={250}>
				<img
					src={logotype}
					alt="Certiblok logo"
					css={{
						position: "absolute",
						height: 48,
					}}
				/>
			</Fade>
			<Fade in={!open} easing={"ease-in-out"} timeout={250}>
				<img
					src={symbol}
					alt="Certiblok logo"
					css={{
						position: "absolute",
						height: 48,
					}}
				/>
			</Fade>
		</Fragment>
	);
};
