import { Avatar, IconButton, Tooltip, Typography } from "@mui/material";
import { Chat } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import { backendMediaUrlParser } from "../../../../../../utils";
import CertiblokIcon from "../../../../../../utils/components/icons/CertiblokIcon";
import { useBetterMediaQuery } from "../../../../../../utils/hooks/useBetterMediaQuery";
import { useMe } from "../../../../../../utils/hooks/useMe";
import { useInterruptSharing } from "../../hooks/useInterrupSharing";
import SharingOptions from "./SharingOptions";

type ShareNotesHeaderProps = {
	documentId: string;
	onNavigateBack?: () => void;
	onClose: () => void;
	chat?: Chat;
	invalidateSharings: () => void;
	isSingleChat: boolean;
};

const ShareNotesHeader = ({
	documentId,
	invalidateSharings,
	onClose,
	chat,
	onNavigateBack,
	isSingleChat,
}: ShareNotesHeaderProps) => {
	const { t } = useTranslation();
	const isSm = useBetterMediaQuery("sm");
	const { me } = useMe();

	const otherUser = (chat?.members ?? []).find((member) => member.userId !== me?.id);

	const { mutate: interruptSharings } = useInterruptSharing({
		documentId,
		userId: otherUser?.userId ?? "",
		sharingGroupId: otherUser?.sharingGroupId,
		onSuccess: () => invalidateSharings(),
	});
	return (
		<div className="py-3 px-4 bg-secondary-light/[0.18] flex flex-col box-border">
			<div className="flex justify-between items-center overflow-hidden gap-2 min-w-0 flex-wrap">
				{!isSm && isSingleChat && (
					<IconButton onClick={onNavigateBack} size="small">
						<CertiblokIcon name="arrow_mini_outline_left" color="inherit" size={20} />
					</IconButton>
				)}
				<div className="flex-1 overflow-hidden min-w-0 max-w-full">
					{chat && (
						<div className="flex items-center gap-4 overflow-hidden min-w-0 max-w-full">
							<Avatar
								src={otherUser?.user?.profileUrl ? backendMediaUrlParser(otherUser?.user?.profileUrl) : undefined}
								className="w-8 h-8 bg-gradient-to-br from-gray-100 to-gray-300 text-white"
							/>
							<div className="min-h-[44px] flex flex-col justify-center overflow-hidden">
								<Typography variant="h6" noWrap className="font-bold text-[18px] max-w-full min-w-0">
									{`${otherUser?.user?.name ?? ""} ${otherUser?.user?.surname ?? ""}`}
								</Typography>
								{otherUser?.sharingGroup && (
									<Tooltip title={otherUser?.sharingGroup?.name}>
										<div className="flex gap-1">
											<CertiblokIcon name="profile_outline_01" size={16} color={otherUser?.sharingGroup.color} />
											<Typography variant="button" component="p" className="text-[10px] min-w-0" noWrap>
												{otherUser?.sharingGroup?.name}
											</Typography>
										</div>
									</Tooltip>
								)}
							</div>
						</div>
					)}
				</div>
				<div className="flex items-center flex-shrink-0 gap-1">
					{isSingleChat && !chat?.isActive && (
						<Typography variant="caption" className="text-black/[0.6] uppercase text-[12px]">
							{t("sharingNotes.archived")}
						</Typography>
					)}
					<SharingOptions
						onInterruptSharing={interruptSharings}
						isSharingGroup={Boolean(otherUser?.sharingGroupId)}
						canInterruptSharing={
							Boolean((chat?.members ?? []).find((member) => member.userId === me?.id)?.isCreator) && Boolean(chat?.isActive)
						}
					/>
					<IconButton onClick={onClose} size={"small"}>
						<CertiblokIcon name="close" color="inherit" size={20} />
					</IconButton>
				</div>
			</div>
		</div>
	);
};

export default ShareNotesHeader;
