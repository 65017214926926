import { Outlet } from "react-router-dom";
import registerIllustration from "../../assets/main_illustrations/auth/signup.png";
import { AuthContainer } from "../../utils/components/AuthScaffold";
import { InvitationsAtomContextProvider, useInvitationsAtomController } from "./providers/InvitationsAtomProvider";

type InvitationProps = {};

const Invitation: React.FC<InvitationProps> = () => {
	const invitationsAtomController = useInvitationsAtomController();
	return (
		<InvitationsAtomContextProvider invitationsAtomController={invitationsAtomController}>
			<img
				src={registerIllustration}
				alt="Register illustration"
				className="absolute left-0 self-center max-h-[max(100%,836px)] xl:object-cover xl:w-[clamp(1256px,50%,1793px)]"
			/>
			<AuthContainer>
				<Outlet />
			</AuthContainer>
		</InvitationsAtomContextProvider>
	);
};
export default Invitation;
