import type { ComponentType } from "react";
import { ModalNavigator, useModalNavigator, useModalRoute } from "./ModalNavigator/ModalNavigator";

export const withModalNavigator = <TProps extends { open: boolean; toggleOpen: () => void }>(
	Component: ComponentType<TProps>
) => {
	return ModalNavigator.create((props: Omit<TProps, "open" | "toggleOpen">) => {
		const { visible } = useModalRoute();
		const { popModal } = useModalNavigator();

		//@ts-expect-error
		return <Component {...props} open={visible} toggleOpen={popModal} />;
	});
};
export const withBottomSheetNavigator = <TProps extends { open: boolean; toggleOpen: () => void }>(
	Component: ComponentType<TProps>
) => {
	return ModalNavigator.create((props: Omit<TProps, "open" | "toggleOpen">) => {
		const { visible } = useModalRoute();
		const { popBottomSheet } = useModalNavigator();

		//@ts-expect-error
		return <Component {...props} open={visible} toggleOpen={popBottomSheet} />;
	});
};
