import { useLocalStorage } from "usehooks-ts";

export const useShouldShowImportFromCloudModal = () => {
	const [shouldShowCloudImportModal, setShouldShowCloudImportModal] = useLocalStorage("shouldShowCloudModal", true);

	return {
		shouldShowCloudImportModal,
		setShouldShowCloudImportModal,
	};
};

export const useLastShowedNoStorageModal = () => {
	const [lastShowedNoStorageModal, setLastShowedNoStorageModal] = useLocalStorage<string | undefined>(
		"lastShowedNoStorageModal",
		undefined
	);

	return {
		lastShowedNoStorageModal,
		setLastShowedNoStorageModal,
	};
};
