/** @jsxImportSource @emotion/react */
import { Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import logotype from "../../../../assets/logo/logotype.png";
import LinkBehavior from "../../LinkBehavior";

const NoAuthHeader = () => {
	const { t } = useTranslation();
	return (
		<Stack
			direction="row"
			alignItems="center"
			justifyContent="space-between"
			sx={{ backgroundColor: "background.default", padding: 12, py: 4, boxSizing: "border-box" }}
		>
			<img
				src={logotype}
				alt="Certiblok logo"
				css={{
					height: 64,
				}}
			/>
			<Stack direction="row" gap={3}>
				<Button size="medium" variant="outlined" component={LinkBehavior} href="/login">
					{t("variousComponents.doLogin")}
				</Button>
				<Button size="medium" variant="contained" component={LinkBehavior} href="/register" sx={{ width: 168 }}>
					{t("variousComponents.registerOnCertiblok")}
				</Button>
			</Stack>
		</Stack>
	);
};

export default NoAuthHeader;
