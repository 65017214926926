import { useEffect, useRef, useState } from "react";

export function useDebounce<T>(thing: T, time: number): T {
	const [debouncedValue, setDebouncedValue] = useState<T>(thing);
	const prevTimeout = useRef<any>();
	useEffect(() => {
		clearTimeout(prevTimeout.current);
		prevTimeout.current = setTimeout(() => {
			setDebouncedValue(thing);
		}, time);

		return () => {
			clearTimeout(prevTimeout.current);
		};
	}, [thing, time]);

	return debouncedValue;
}

export function useDebounceCallback<T>(initialValue: T, time: number): [T, (value: T) => void] {
	const [debouncedValue, setDebouncedValue] = useState(initialValue);
	const prevTimeout = useRef<any>();

	const setValue = (value: T) => {
		if (prevTimeout.current) clearTimeout(prevTimeout.current);
		prevTimeout.current = setTimeout(() => {
			setDebouncedValue(value);
		}, time);
	};

	return [debouncedValue, setValue];
}
