import { Button, useMediaQuery, useTheme } from "@mui/material";

import { useTranslation } from "react-i18next";
import IconDialog from "../../../../utils/components/dialogs/IconDialog";
import CertiblokIcon from "../../../../utils/components/icons/CertiblokIcon";
import LoadingTextButton from "../../../../utils/components/LoadingTextButton";
import {
	ModalNavigator,
	useModalRoute,
	useModalNavigator,
} from "../../../../utils/components/ModalNavigator/ModalNavigator";

const RemoveExpireDateModal = ModalNavigator.create(
	({ removeExpirationFunction }: { removeExpirationFunction: () => Promise<void> }) => {
		const { visible } = useModalRoute();
		const { popModal, popTo } = useModalNavigator();
		const { t } = useTranslation();
		const theme = useTheme();
		const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

		return (
			<IconDialog
				DialogProps={{ className: "text-center" }}
				open={visible}
				onClose={() => popTo(-2)}
				icon="comunication_outline_02"
				IconProps={{ containerClassName: "bg-success/[0.12] m-auto" }}
				title={t("inbox.removeExpireDate")}
				description={t("dashboard.removeExpireDocumentDescription")}
				footer={
					<div className="flex justify-between items-center gap-2 md:pt-9">
						<Button onClick={popModal} color="primary" size={isOverMd ? "large" : "small"}>
							<CertiblokIcon name="close" color="inherit" />
							{t("global.cancel")}
						</Button>
						<LoadingTextButton
							onClick={async () => {
								await removeExpirationFunction();
								popTo(-2);
							}}
							className="min-w-[218px]"
							color="primary"
							size={isOverMd ? "large" : "small"}
							variant="contained"
						>
							{t("inbox.removeExpireDate")}
						</LoadingTextButton>
					</div>
				}
			/>
		);
	}
);

export default RemoveExpireDateModal;
