import { PinInput, PinInputField } from "@chakra-ui/pin-input";
import { Fade } from "@mui/material";
import { clsx } from "clsx";
import { useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";

export type VerificationCodeTextFieldHelperText = {
	value: string;
	status: "error" | "success";
};

type VerificationCodeTextFieldProps = {
	value: string;
	onChange: (value: string) => void;
	onEnterPress?: (value: string) => void;
	helperText?: VerificationCodeTextFieldHelperText;
	disabled?: boolean;
	length?: number;
	className?: string;
};

const VerificationCodeTextField: React.FC<VerificationCodeTextFieldProps> = ({
	value,
	onChange,
	disabled,
	length = 6,
	className,
	helperText,
	onEnterPress,
}) => {
	const valueRef = useRef(value);
	useEffect(() => {
		const enterPressCallback = (ev: KeyboardEvent) => {
			if (ev.key === "Enter") {
				onEnterPress?.(valueRef.current);
			}
		};
		window.addEventListener("keypress", enterPressCallback);
		return () => {
			window.removeEventListener("keypress", enterPressCallback);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<div className={twMerge("flex gap-1 lg:gap-5 items-center justify-center", className)}>
				<PinInput
					value={value}
					onChange={(value) => {
						valueRef.current = value;
						onChange(value);
					}}
					placeholder=""
					autoFocus
					isDisabled={disabled}
					otp
				>
					{[...Array(length)].map((_, index) => (
						//@ts-ignore
						<PinInputField
							key={index}
							className={
								"w-12 lg:w-[60px] px-4 py-4 lg:py-2 flex-shrink bg-primary-a06 box-border rounded-2xl border-none text-h5 lg:text-h3 text-secondary"
							}
						/>
					))}
				</PinInput>
			</div>
			<Fade key={helperText?.value} appear in={Boolean(helperText?.value) || true}>
				<p
					className={clsx(
						"text-label md:text-caption pt-4 md:pt-6",
						helperText?.status === "error" ? "text-error" : "text-primary"
					)}
					dangerouslySetInnerHTML={{
						__html: helperText?.value || "<br>",
					}}
				/>
			</Fade>
		</div>
	);
};

export default VerificationCodeTextField;
