import { useTheme } from "@mui/material";
import { useContext, useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import { LG_DRAWER_CLOSED_WIDTH, LG_DRAWER_OPEN_WIDTH } from "../../constants/DrawerConstants";
import { SX_HEIGHT } from "../../constants/TopBarConstants";
import { useBetterMediaQuery } from "../../hooks/useBetterMediaQuery";
import ErrorBoundary from "../ErrorBoundary";
import { ScaffoldContext } from "./controllers/ScaffoldContext";

const Body = () => {
	const { open } = useContext(ScaffoldContext);

	const windowWidth = useRef(window.innerWidth);

	const [{ width }, api] = useSpring(() => ({
		width: open ? windowWidth.current - LG_DRAWER_OPEN_WIDTH : windowWidth.current - LG_DRAWER_CLOSED_WIDTH,
		config: {
			tension: 200,
		},
	}));

	const openRef = useRef(open);

	useEffect(() => {
		const windowResizeEventHandler = () => {
			const width = window.innerWidth;
			windowWidth.current = width;
			api.stop();
			api.set({ width: openRef.current ? width - LG_DRAWER_OPEN_WIDTH : width - LG_DRAWER_CLOSED_WIDTH });
		};
		window.addEventListener("resize", windowResizeEventHandler);
		return () => {
			window.removeEventListener("resize", windowResizeEventHandler);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		openRef.current = open;
		api.stop();
		api.start({
			width: open ? windowWidth.current - LG_DRAWER_OPEN_WIDTH : windowWidth.current - LG_DRAWER_CLOSED_WIDTH,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	const isMd = useBetterMediaQuery("md");
	const isLg = useBetterMediaQuery("lg");
	const theme = useTheme();

	return (
		<animated.main
			style={{
				right: 0,
				width: width.to((width) => (isLg ? width : isMd ? `calc(100vw - ${LG_DRAWER_CLOSED_WIDTH}px)` : "100vw")),
				// width: {
				// 	xs: "100vw",
				// 	md: `calc(100vw - ${LG_DRAWER_CLOSED_WIDTH}px)`,
				// 	lg: `calc(100vw - ${open ? LG_DRAWER_OPEN_WIDTH : LG_DRAWER_CLOSED_WIDTH}px)`,
				// },
				height: `calc(100% - ${SX_HEIGHT}px)`,
				backgroundColor: theme.palette.background.default,
				overflow: "hidden",
				bottom: 0,
				zIndex: 1100,
				position: "fixed",
				transform: isLg
					? "unset"
					: isMd
					? `translateX(${open ? LG_DRAWER_OPEN_WIDTH - LG_DRAWER_CLOSED_WIDTH : 0}px)`
					: `translateX(${open ? "100vw" : "0px"})`,
				// transform: {
				// 	xs: `translateX(${open ? "100vw" : "0px"})`,
				// 	md: `translateX(${open ? LG_DRAWER_OPEN_WIDTH - LG_DRAWER_CLOSED_WIDTH : 0}px)`,
				// 	lg: "unset",
				// },
				// transition: theme.transitions.create(["transform", "width"]),
				transition: theme.transitions.create(["transform"]),
			}}
		>
			<ErrorBoundary>
				<Outlet />
			</ErrorBoundary>
		</animated.main>
	);
};

export default Body;
