import type { FilterObject } from "../components/tables/AdminTable";

type generateSearchFiltersParams = {
	fields: string[];
	ignoreSpaces?: boolean;
};

export const generateSearchFilters = (searchValue: string, params: generateSearchFiltersParams): FilterObject => {
	const queryText = (searchValue || "").trim();
	if (!queryText || queryText.length === 0) {
		return {};
	}

	const filters: FilterObject = [];
	const fields = [...(params?.fields || [])];
	const matchWords = params?.ignoreSpaces ? queryText.split(" ") : [queryText];

	matchWords.forEach((word) => {
		fields.forEach((field) => {
			const filterField = {} as FilterObject;
			filterField[field] = word;
			filters.push(filterField);
		});
	});

	const searchFilter: FilterObject = {
		OR: filters,
	};

	return searchFilter;
};
