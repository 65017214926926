import { atom, PrimitiveAtom } from "jotai";
import React, { useState } from "react";

type CurrentIndexContextProps = PrimitiveAtom<number>;

export function useCurrentIndexContext(): CurrentIndexContextProps {
	const [currentIndexAtom] = useState(() => atom(0));

	return currentIndexAtom;
}

export const CurrentIndexContext = React.createContext<CurrentIndexContextProps>({} as CurrentIndexContextProps);
