"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.7.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuditDocumentReviewApi = exports.AuditDocumentReviewApiFactory = exports.AuditDocumentReviewApiFp = exports.AuditDocumentReviewApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * AuditDocumentReviewApi - axios parameter creator
 * @export
 */
const AuditDocumentReviewApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Create or edit review
         * @param {InputCreateOrEditReview} [inputCreateOrEditReview]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrEditReview: (inputCreateOrEditReview, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/audit-document-review/create-edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputCreateOrEditReview, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.AuditDocumentReviewApiAxiosParamCreator = AuditDocumentReviewApiAxiosParamCreator;
/**
 * AuditDocumentReviewApi - functional programming interface
 * @export
 */
const AuditDocumentReviewApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.AuditDocumentReviewApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Create or edit review
         * @param {InputCreateOrEditReview} [inputCreateOrEditReview]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrEditReview(inputCreateOrEditReview, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createOrEditReview(inputCreateOrEditReview, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.AuditDocumentReviewApiFp = AuditDocumentReviewApiFp;
/**
 * AuditDocumentReviewApi - factory interface
 * @export
 */
const AuditDocumentReviewApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.AuditDocumentReviewApiFp)(configuration);
    return {
        /**
         *
         * @summary Create or edit review
         * @param {InputCreateOrEditReview} [inputCreateOrEditReview]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrEditReview(inputCreateOrEditReview, options) {
            return localVarFp.createOrEditReview(inputCreateOrEditReview, options).then((request) => request(axios, basePath));
        },
    };
};
exports.AuditDocumentReviewApiFactory = AuditDocumentReviewApiFactory;
/**
 * AuditDocumentReviewApi - object-oriented interface
 * @export
 * @class AuditDocumentReviewApi
 * @extends {BaseAPI}
 */
class AuditDocumentReviewApi extends base_1.BaseAPI {
    /**
     *
     * @summary Create or edit review
     * @param {InputCreateOrEditReview} [inputCreateOrEditReview]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditDocumentReviewApi
     */
    createOrEditReview(inputCreateOrEditReview, options) {
        return (0, exports.AuditDocumentReviewApiFp)(this.configuration).createOrEditReview(inputCreateOrEditReview, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.AuditDocumentReviewApi = AuditDocumentReviewApi;
