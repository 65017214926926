import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Chat, ChatApi } from "certiblok-api-manager";
import ResponsiveDialog from "../../../../../utils/components/dialogs/ResponsiveDialog";
import {
	ModalNavigator,
	useModalNavigator,
	useModalRoute,
} from "../../../../../utils/components/ModalNavigator/ModalNavigator";
import { instanceApi } from "../../../../../utils/hooks/api";
import ShareNotes from "../../../../SingleDocument/components/SharesModal/components/ShareNotes";
import { getContactSharedDocumentsQueryKey } from "../../../hooks/useGetContactSharedDocuments";

const SingleChat = ModalNavigator.create(
	({
		chatId,
		documentId,
		contactId,
		isSharingActive,
	}: {
		chatId: string;
		documentId: string;
		contactId: string;
		isSharingActive: boolean;
	}) => {
		const { visible } = useModalRoute();
		const { popModal } = useModalNavigator();

		const queryClient = useQueryClient();

		const onClose = () => {
			popModal();
		};

		const { data: singleChat } = useQuery({
			queryKey: ["getSingleChat", chatId],
			queryFn: () => {
				return instanceApi(ChatApi).getChatById(chatId);
			},
			select: (data) => data.data,
		});

		const invalidateSharings = () => {
			queryClient.invalidateQueries(getContactSharedDocumentsQueryKey(contactId));
		};

		return (
			<ResponsiveDialog
				open={visible}
				onClose={() => onClose()}
				ModalProps={{
					containerSx: {
						width: "80vw",
						maxWidth: "500px",
						height: "80vh",
						maxHeight: "600px",
						padding: 0,
						boxSizing: "border-box",
						"&>div": {
							height: "100%",
							maxHeight: "100%",
							width: "100%",
							overflow: "hidden",
							borderRadius: 6,
						},
					},
				}}
				BottomSheetProps={{
					HeaderProps: { className: "hidden w-0 h-0 p-0" },
					FooterProps: { className: "hidden w-0 h-0 p-0" },
					className:
						"min-h-[90vh] [&>div]:flex [&>div]:flex-col [&>div]:overflow-hidden [&>div]:rounded-t-[20px] [&>div]:md:rounded-t-[48px]",
				}}
				header={null}
				footer={null}
			>
				<div className="flex-1 h-full min-h-full min-w-0 flex flex-col">
					<ShareNotes
						invalidateSharings={() => {
							invalidateSharings();
						}}
						chat={Boolean(singleChat) ? { ...(singleChat as Chat), isActive: isSharingActive } : undefined}
						onClose={onClose}
						documentId={documentId}
						isSingleChat
					/>
				</div>
			</ResponsiveDialog>
		);
	}
);

export default SingleChat;
