/** @jsxImportSource @emotion/react */
import { keyframes } from "@emotion/css";
import { IconButton } from "@mui/material";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useTranslation } from "react-i18next";

import CertiblokIcon from "../../../../../../utils/components/icons/CertiblokIcon";
import MenuButton from "../../../../../../utils/components/MenuButton";

type SharingOptionsProps = {
	onInterruptSharing: () => void;
	isSharingGroup: boolean;
	canInterruptSharing: boolean;
};

const fade = keyframes`
	from {opacity: 0;}
	to {opacity: 1;}
`;

const SharingOptions = ({ canInterruptSharing, onInterruptSharing, isSharingGroup }: SharingOptionsProps) => {
	const { t } = useTranslation();
	if (!canInterruptSharing) return null;
	return (
		<DropdownMenu.Root>
			<DropdownMenu.Trigger asChild>
				<IconButton size={"small"}>
					<CertiblokIcon name="more" color="inherit" size={20} />
				</IconButton>
			</DropdownMenu.Trigger>
			<DropdownMenu.Portal>
				<DropdownMenu.Content
					collisionPadding={15}
					className="min-w-[200px] bg-white box-border flex flex-col p-1 rounded-xl z-[1500] [filter:drop-shadow(0px_1px_18px_rgba(0,0,0,0.12))]"
					css={{
						animation: `${fade} 350ms cubic-bezier(0.4, 0, 0.2, 1)`,
					}}
				>
					<DropdownMenu.Item>
						<MenuButton
							children={
								isSharingGroup ? t("shareWithContact.interruptSharingWithGroup") : t("shareWithContact.interruptSharing")
							}
							onClick={() => {
								onInterruptSharing();
							}}
							icon={"close"}
						/>
					</DropdownMenu.Item>
				</DropdownMenu.Content>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	);
};

export default SharingOptions;
