import type { Audit, Document, SharingGroup, UserOrContact } from "certiblok-api-manager";
import produce from "immer";
import { useCallback, useMemo, useRef } from "react";
import { create } from "zustand";
import { shallow } from "zustand/shallow";
import type { CertiblokDocument } from "../../../interfaces/Document";
import type { SupportedLanguagesType } from "../../LanguageSelector";

type UploadedDocument = {
	document: CertiblokDocument;
	draft: {
		expirationDate?: string | null;
		shares?: {
			note?: string;
			usersToShareWith: (UserOrContact | string)[];
			language: SupportedLanguagesType;
			groupsToShareWith: SharingGroup[];
		};
		auditRooms?: Audit[];
		documentName?: string;
	};
};

type UploadedFilesStoreType = {
	addFile: (newFile: UploadedDocument) => void;
	deleteFile: (id: string) => void;
	editFile: <T extends UploadedDocument>(fileId: string, newFile: Partial<T>) => void;
	resetStore: () => void;
	files: {
		[x: string]: UploadedDocument;
	};
};

const useUploadedFilesStore = create<UploadedFilesStoreType>()((set, get) => ({
	files: {},
	addFile: (newFile) => {
		set(
			produce((state) => {
				state.files[newFile.document.id] = newFile;
			})
		);
	},
	editFile: (id, newFile) => {
		set(
			produce((state) => {
				state.files[id] = {
					...get().files[id],
					...newFile,
				};
			})
		);
	},
	deleteFile: (id) => {
		set(
			produce((state) => {
				delete state.files[id];
			})
		);
	},
	resetStore: () => {
		set({ files: {} });
	},
}));

export function useResetUploadedFiles() {
	return useUploadedFilesStore((state) => state.resetStore);
}

export function getUploadedFile(id: string) {
	return useUploadedFilesStore.getState().files[id];
}

export function useUploadedFileById(id: string) {
	const file = useUploadedFilesStore((state) => state.files[id]);

	const oldFile = useRef(file);

	return useMemo(() => {
		if (file) {
			oldFile.current = file;
			return file;
		} else {
			return oldFile.current;
		}
	}, [file]);
}

export function useUploadedFileDraftById(id: string) {
	const draft = useUploadedFilesStore((state) => state.files[id]?.draft);

	const oldFile = useRef(draft);

	return useMemo(() => {
		if (draft) {
			oldFile.current = draft;
			return draft;
		} else {
			return oldFile.current;
		}
	}, [draft]);
}

export function useUploadedFileIds() {
	return useUploadedFilesStore((state) => Object.keys(state.files), shallow);
}

export function useAddUploadedFile() {
	return useUploadedFilesStore((state) => state.addFile);
}

export function useRemoveUploadedFile() {
	return useUploadedFilesStore((state) => state.deleteFile);
}

export function useEditUploadedFile() {
	return useUploadedFilesStore((state) => state.editFile);
}

export function useEditUploadedFileDraft() {
	const editFile = useEditUploadedFile();
	return useCallback(
		(documentId: string, draft?: Partial<UploadedDocument["draft"]>) => {
			const fileDraft = useUploadedFilesStore.getState().files[documentId].draft;
			editFile(documentId, { draft: { ...fileDraft, ...draft } });
		},
		[editFile]
	);
}

export function useHasUnsavedEdits() {
	return useUploadedFilesStore(
		(state) =>
			Object.values(state.files).some((file) => file.draft.auditRooms || file.draft.shares || file.draft.expirationDate),
		shallow
	);
}

export function getAllUploadedFiles() {
	return Object.values(useUploadedFilesStore.getState().files);
}

export function getUploadedCBDocuments(): Document[] {
	return getAllUploadedFiles().map((file) => ({
		...file.document,
		expireDate: file.draft.expirationDate || undefined,
		noExpiration: file.draft.expirationDate === null,
		audits: file.draft.auditRooms || [],
		name: file.draft.documentName || file.document.name,
	}));
}
