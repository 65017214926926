/** @jsxImportSource @emotion/react */

import type { NiceModalHocProps } from "@ebay/nice-modal-react";
import { Slide } from "@mui/material";
import { ComponentType, createElement, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import NoContents from "../../../utils/components/NoContents";
import AdminTable, { type IHeadCell } from "../../../utils/components/tables/AdminTable";
import { useResponsiveValue } from "../../../utils/hooks/useResponsiveValue";
import type { CertiblokDocument } from "../../../utils/interfaces/Document";
import { AllDocumentsContext } from "../controllers/AllDocumentsContext";
import { allDocumentsModal } from "../modals/allDocumentsModal";
import AllDocumentsTableCell from "./AllDocumentsTableCell";

export const DocumentsTable = ({
	data,
	totalCount,
	isLoading,
	isFetching,
}: {
	data: CertiblokDocument[];
	totalCount: number;
	isLoading: boolean;
	isFetching: boolean;
}) => {
	const allDocumentContext = useContext(AllDocumentsContext);

	const { queryFilter, setQueryFilter } = allDocumentContext;

	const fileFilterColSpan = useResponsiveValue({ xs: 3, md: 3 });
	const ownerColSpan = useResponsiveValue({ xs: 0, sm: 2, xl: 3 });
	const versionColSpan = useResponsiveValue({ xs: 0, lg: 1 });
	const expireDateColSpan = useResponsiveValue({ xs: 0, sm: 1, md: 2 });
	const folderColSpan = useResponsiveValue({ xs: 0, md: 2, lg: 3 });
	const acquisitionDateColSpan = useResponsiveValue({ xs: 0, md: 2 });
	const tagsColSpan = useResponsiveValue({ xs: 0, md: 2 });

	const { t } = useTranslation();

	const headCells: IHeadCell[] = useMemo(
		() => {
			return [
				{ id: "lastVersionFile.type", label: t("documents.type"), colSpan: 1, width: 40, notSelectable: true },
				{
					id: "name",
					label: t("inbox.fileName"),
					colSpan: fileFilterColSpan,
				},
				{ id: "ownerId", label: t("documents.owner"), colSpan: ownerColSpan },
				{ id: "lastVersionFile.id", label: t("documents.version"), colSpan: versionColSpan, notSelectable: true },
				{ id: "expireDate", label: t("documents.expireDate"), colSpan: expireDateColSpan },
				{ id: "parentFolderId", label: t("global.folder"), colSpan: folderColSpan },
				{
					id: "acquisitionDate",
					label: t("document.acquisitionDate"),
					colSpan: acquisitionDateColSpan,
					notSelectable: true,
				},
				{ id: "tags", label: t("tags.tags"), colSpan: tagsColSpan, notSelectable: true },
			];
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			acquisitionDateColSpan,
			expireDateColSpan,
			fileFilterColSpan,
			ownerColSpan,
			folderColSpan,
			versionColSpan,
			tagsColSpan,
			queryFilter.filter,
		]
	);

	return (
		<AdminTable
			headCells={headCells}
			queryFilter={[queryFilter, setQueryFilter]}
			loading={isLoading}
			fetching={isFetching}
			count={totalCount}
			data={data}
			fullWidth
			size="small"
			color="secondary"
			paperCss={{ height: "100%" }}
			allRowSelected={data.length !== 0 && allDocumentContext.selectedDocumentsId.length === data.length}
			onSelectAllRow={() => {
				allDocumentContext.toggleAllDocumentSelection();
			}}
			hasOption
			noDataComponent={<NoContents variant="document" title={t("search.noDocumentsFound")} sx={{ py: 20 }} />}
		>
			{(data) =>
				data.map((elem) => (
					<AllDocumentsTableCell
						key={elem.id}
						headCells={headCells}
						document={elem as any}
						checked={allDocumentContext.selectedDocumentsId.includes(elem.id)}
						onSelect={(event) => {
							allDocumentContext.toggleSingleDocumentSelection(elem.id);
							event.stopPropagation();
						}}
						shouldShowOptionButton={allDocumentContext.selectedDocumentsId.length === 0}
					/>
				))
			}
		</AdminTable>
	);
};

const Table = () => {
	const allDocumentContext = useContext(AllDocumentsContext);

	return (
		<>
			<div className="relative overflow-hidden flex-1">
				<Slide direction="right" in={allDocumentContext.selectedDocumentsTab === "all"}>
					<div className="absolute h-full">
						<DocumentsTable
							isLoading={allDocumentContext.allDocumentsLoading}
							isFetching={allDocumentContext.allDocumentsFetching}
							totalCount={allDocumentContext.allDocumentsQuery?.totalDocumentsCount ?? 0}
							data={allDocumentContext.allDocumentsQuery?.data ?? []}
						/>
					</div>
				</Slide>
				<Slide direction="left" in={allDocumentContext.selectedDocumentsTab === "shared"}>
					<div className="absolute h-full">
						<DocumentsTable
							isLoading={allDocumentContext.sharedDocumentsLoading}
							isFetching={allDocumentContext.sharedDocumentsFetching}
							totalCount={allDocumentContext.sharedDocumentsQuery?.totalDocumentsCount ?? 0}
							data={allDocumentContext.sharedDocumentsQuery?.data ?? []}
						/>
					</div>
				</Slide>
			</div>
			{createElement(allDocumentsModal.allDocumentPopupMenu.component as ComponentType<NiceModalHocProps>, {
				id: allDocumentsModal.allDocumentPopupMenu.id,
			})}
		</>
	);
};

export default Table;
