import type { ComponentProps } from "react";
import AskFileUpdate from "../../../utils/components/documents/AskFileUpdate";
import AssignExpireDate from "../../../utils/components/documents/AssignExpireDate";
import RenameFile from "../../../utils/components/documents/RenameFile";
import ShareDocument from "../../../utils/components/documents/ShareDocument";
import UpdateOrReplaceFile from "../../../utils/components/documents/UpdateOrReplaceFile";
import { withModalNavigator } from "../../../utils/components/ModalNavigatorWrapper";
import MoveDocument from "../../Folders/components/MoveDocument/MoveDocument";
import AuditRoomBS from "../../Folders/components/UploadDocument/components/AuditRoomBS";
import AllDocumentPopupMenu from "../components/AllDocumentPopupMenu";

const AuditRoomModal = ({
	open,
	toggleOpen,
	...props
}: { open: boolean; toggleOpen: () => void } & ComponentProps<typeof AuditRoomBS>) => {
	return <AuditRoomBS {...props} controls={{ ...props.controls, open, toggleOpen }} />;
};

export const allDocumentsModal = {
	allDocumentPopupMenu: { id: "allDocumentPopupMenu", component: withModalNavigator(AllDocumentPopupMenu) },
	assignExpireDate: { id: "assignExpireDate", component: withModalNavigator(AssignExpireDate) },
	shareDocument: { id: "shareDocument", component: withModalNavigator(ShareDocument) },
	renameDocument: { id: "renameDocument", component: withModalNavigator(RenameFile) },
	updateOrReplaceDocument: { id: "updateOrReplaceDocument", component: withModalNavigator(UpdateOrReplaceFile) },
	askDocumentUpdate: { id: "askDocumentUpdate", component: withModalNavigator(AskFileUpdate) },
	moveDocuments: { id: "moveDocument", component: withModalNavigator(MoveDocument) },
	assignAuditRoom: { id: "assignAuditRoom", component: withModalNavigator(AuditRoomModal) },
} as const;
