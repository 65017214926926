/** @jsxImportSource @emotion/react */
import { autoUpdate, flip, FloatingPortal, offset, size as floatingSize, useFloating } from "@floating-ui/react";
import { Spinner } from "@mabi-ui/spinner";
import { cn } from "@mabi-ui/utils";
import { alpha, TextField, TextFieldProps, Typography, useTheme } from "@mui/material";
import { Tag } from "certiblok-api-manager";
import { useCombobox } from "downshift";
import throttle from "lodash/throttle";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CertiblokIcon from "../../../../utils/components/icons/CertiblokIcon";
import { useGetTags } from "../../hooks/useTagsHooks";
import SingleTag, { SingleTagProps } from "./SingleTag";

type TagsAutocompleteProps = {
	className?: string;
	TextFieldProps?: TextFieldProps;
	optionsClassName?: string;
	onTagSelection?: (item: Tag) => void;
	disabledTagsIds?: string[];
	TagProps?: Omit<SingleTagProps, "tag">;
	inputValue?: string;
	onInputChange?: (value: string) => void;
};

const TagsAutocomplete = ({
	className,
	TextFieldProps,
	optionsClassName,
	onTagSelection,
	disabledTagsIds,
	inputValue,
	onInputChange: onExternalInputChange,
	TagProps,
}: TagsAutocompleteProps) => {
	const { t } = useTranslation();
	const theme = useTheme();

	const [throttledSearchFilter, setThrottledSearchFilter] = useState("");

	const { data: tags, isLoading, isFetching } = useGetTags({ searchFilter: throttledSearchFilter });
	// * Qui vengono fetchati solamente i primi 10 risultati corrispondenti alla ricerca

	const flatTags = useMemo(() => tags?.pages?.reduce((acc, curr) => [...acc, ...curr.data], [] as Tag[]) ?? [], [tags]);

	const throttleSearchFilter =
		// eslint-disable-next-line react-hooks/exhaustive-deps
		useCallback(
			throttle(
				(value: string) => {
					setThrottledSearchFilter(value);
				},
				500,
				{ trailing: true, leading: false }
			),
			[]
		);

	const onInputChange = useCallback((value: string) => {
		onExternalInputChange?.(value);
		throttleSearchFilter(value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { refs, floatingStyles } = useFloating({
		placement: "bottom",
		whileElementsMounted: autoUpdate,
		middleware: [
			offset(4),
			flip({ padding: 10 }),
			floatingSize({
				apply({ rects, elements }) {
					Object.assign(elements.floating.style, {
						width: `${rects.reference.width}px`,
					});
				},
			}),
		],
	});

	const { isOpen, getInputProps, getItemProps, getMenuProps, highlightedIndex } = useCombobox({
		items: flatTags,
		inputValue: inputValue,
		onInputValueChange: ({ inputValue }) => {
			onInputChange(inputValue);
		},
		itemToString: (item) => "",
		onIsOpenChange: () => {},
		selectedItem: null,
		onSelectedItemChange: ({ selectedItem }) => {
			if (disabledTagsIds?.includes(selectedItem.id)) return;
			onTagSelection?.(selectedItem);
		},
	});

	return (
		<div className={cn("relative", className)} ref={refs.setReference}>
			<TextField
				fullWidth
				variant="standard"
				size={"small"}
				color={"secondary"}
				{...TextFieldProps}
				{...getInputProps()}
				InputProps={{
					sx: {
						"&.Mui-focused": {
							outlineWidth: "2px",
							outlineStyle: "solid",
							outlineColor: theme.palette.secondary.main,
						},
					},
					placeholder: t("tags.searchBetweenTags"),
					className: cn("bg-secondary/[0.06] px-3 py-2 items-center flex [&>input]:pb-0"),
					endAdornment:
						isLoading || isFetching ? (
							<Spinner className="w-4 h-4 border-2 border-secondary border-t-transparent" />
						) : (
							<CertiblokIcon name="search_outline" size={16} color="rgba(0,0,0,0.6)" />
						),
					...TextFieldProps?.InputProps,
				}}
			/>
			<FloatingPortal>
				<div
					className="box-border z-[3000] "
					{...getMenuProps({}, { suppressRefError: true })}
					ref={refs.setFloating}
					style={floatingStyles}
				>
					{isOpen && !isLoading && (
						<ul
							className={cn(
								"bg-white m-0 px-4 py-3 shadow-8 grid md:grid-cols-3 grid-cols-1 sm:grid-cols-2 gap-3 rounded-xl max-h-[100px] overflow-y-scroll",
								optionsClassName
							)}
						>
							{flatTags.length === 0 ? (
								<span>
									<Typography variant="caption" className="px-3 font-semibold italic">
										{t("global.noResult")}
									</Typography>
								</span>
							) : (
								flatTags.map((option, i) => {
									const isDisabled = disabledTagsIds?.includes(option.id);
									return (
										<li
											key={option.id}
											className={cn(
												"list-none m-0 p-0 rounded-md w-full",
												isDisabled ? "opacity-60" : "cursor-pointer",
												highlightedIndex === i && !isDisabled && ""
											)}
											css={{
												"&:hover": { backgroundColor: isDisabled ? "transparent" : alpha(option.color, 0.06) },
												"&:active": { backgroundColor: isDisabled ? "transparent" : alpha(option.color, 0.12) },
											}}
											{...getItemProps({ item: option, index: i })}
										>
											<SingleTag
												tag={option}
												hideTooltip={isDisabled}
												{...TagProps}
												size={TagProps?.size ?? "medium"}
												className={cn("w-full max-w-[100%] box-border", TagProps?.className ?? "")}
											/>
										</li>
									);
								})
							)}
						</ul>
					)}
				</div>
			</FloatingPortal>
		</div>

		// const TagsAutocomplete = () => {
		// 	return <div></div>;
	);
};

export default TagsAutocomplete;
