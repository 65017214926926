import { Button, Typography } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { CompanyApi } from "certiblok-api-manager";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { backendMediaUrlParser } from "../../..";
import companyPlaceholder from "../../../../assets/company-placeholder.png";
import { LogoutModalWithNiceModal } from "../../../../pages/Profile/components/LogoutModal";
import { getCompanyRole } from "../../../functions/getCompanyRole";
import { instanceApi } from "../../../hooks/api";
import { useBetterMediaQuery } from "../../../hooks/useBetterMediaQuery";
import { useMe } from "../../../hooks/useMe";
import ArrowPopover from "../../ArrowPopover";
import { useModalNavigator } from "../../ModalNavigator/ModalNavigator";
import BottomSheet from "../../bottomSheet/BottomSheet";
import CertiblokIcon from "../../icons/CertiblokIcon";
import MLDialog from "../../poppers";

type CompanySelectorProps = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	anchorEl: HTMLElement | null;
};

const CompanySelector: React.FC<CompanySelectorProps> = (props) => {
	const { open, setOpen, anchorEl } = props;
	const navigate = useNavigate();

	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();

	const isSm = useBetterMediaQuery("sm");
	const { t } = useTranslation();

	const { me } = useMe();

	const { pushModal } = useModalNavigator();

	const { data: companies } = useQuery({
		queryKey: ["getAllUserLinkedCompanies"],
		queryFn: () => {
			return instanceApi(CompanyApi).getAllUserLinkedCompanies();
		},
		select: (data) => data?.data,
	});

	const { mutateAsync: setSelectedCompany } = useMutation({
		mutationFn: (companyId: string) => {
			return instanceApi(CompanyApi).setCompany({
				companyId,
			});
		},
	});

	const selectedCompany =
		me?.company !== null && me?.selectedCompanyId === me?.companyId
			? me?.company
			: // @ts-ignore
			  me?.managedCompanies?.find((company) => {
					return company?.id === me?.selectedCompanyId;
			  });

	useEffect(() => {
		if (location.search.includes("selectedCompanyChanged=true")) {
			MLDialog.showSnackbar(t("scaffold.comapnySuccessfullyChanged"), { variant: "success" });
			setSearchParams({ ...searchParams, selectedCompanyChanged: undefined });
			navigate("/", { replace: true });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const content = (
		<div className="flex flex-col items-stretch gap-6 bg-white sm:w-[320px] p-6">
			<div className="flex flex-col gap-2 items-center w-full">
				<div className="rounded-full w-20 h-20 overflow-hidden">
					<img
						src={backendMediaUrlParser(selectedCompany?.pictureUrl) ?? companyPlaceholder}
						alt="Company"
						className="w-full h-full bg-cover"
					/>
				</div>
				<div className="flex flex-col items-center w-full">
					<Typography
						variant="h6"
						className="font-bold  break-words whitespace-pre-wrap max-w-full line-clamp-3 text-center"
					>
						{selectedCompany?.name}
					</Typography>
					<Typography>{t([`invitation.role.${getCompanyRole(selectedCompany!, me?.id!)}`])}</Typography>
				</div>
			</div>
			<div className="flex flex-col gap-3 items-stretch">
				<Typography variant="subtitle1">{t("invitation.changeSelectedCompany")}</Typography>
				{(companies ?? [])?.map((company) => {
					return (
						<Button
							key={company?.id}
							onClick={() => {
								setSelectedCompany(company?.id).then(() => {
									setSearchParams({ ...searchParams, selectedCompanyChanged: "true" });
									window.location.reload();
								});
							}}
							className="h-auto border-b-[1px] last:border-b-0 border-0 border-black-a12 border-solid"
						>
							<div className="w-full flex flex-row justify-start items-center gap-4 text-black">
								<div className="rounded-full w-14 h-14 overflow-hidden">
									<img
										src={company?.pictureUrl ? backendMediaUrlParser(company?.pictureUrl) : companyPlaceholder}
										alt="Company"
										className="w-full h-full bg-cover"
									/>
								</div>
								<div className="flex flex-col items-stretch flex-1 text-start truncate">
									<Typography variant="h6" className="font-bold truncate">
										{company?.name}
									</Typography>
									<Typography>{t([`invitation.role.${getCompanyRole(company, me?.id!)}`])}</Typography>
								</div>
							</div>
						</Button>
					);
				})}
			</div>
			<Button
				color="secondary"
				variant="outlined"
				endIcon={<CertiblokIcon name="log_outline_01" color="secondary" />}
				onClick={() => {
					setOpen(false);
					pushModal(LogoutModalWithNiceModal);
				}}
			>
				{t("profile.logout")}
			</Button>
		</div>
	);

	return isSm ? (
		<ArrowPopover
			open={open}
			onClose={() => {
				setOpen(false);
			}}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: "center",
				horizontal: "right",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			css={{ marginTop: 40 }}
		>
			{content}
		</ArrowPopover>
	) : (
		<BottomSheet
			open={open}
			onClose={() => {
				setOpen(false);
			}}
			sx={{ paddingBottom: "0px !important" }}
		>
			{content}
		</BottomSheet>
	);
};

// const companies = [
// 	{
// 		id: 1,
// 		name: "Company 1",
// 		role: "Membro",
// 	},
// 	{
// 		id: 2,
// 		name: "Company 2",
// 		role: "Guest",
// 	},
// 	{
// 		id: 3,
// 		name: "Company 3",
// 		role: "Membro",
// 	},
// ];

export default CompanySelector;
