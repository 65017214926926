import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { EditRoomApi, EditRoomFolder, Folder, User } from "certiblok-api-manager";
import { defaultQueryFilter, transformToStringFrom } from "../../../../../utils/components/tables/AdminTable";
import { useApi } from "../../../../../utils/hooks/api";
import { CertiblokDocument } from "../../../../../utils/interfaces/Document";

export type EditRoom = {
	id: string;
	name: string;
	color: string;
	originalFolderExists: boolean;
	folders?: Folder[];
	documents?: CertiblokDocument[];
	lockEditRoomEnabled?: boolean;
	reservedToUsers?: User[];
	temporaryReservedToUsers?: User[];
	folderId: string;
};

export const getGetAllEditRoomsQueryKey = () => {
	return ["allEditRooms"];
};

export const useGetAllEditRooms = () => {
	const editRoomApi = useApi(EditRoomApi);

	return useQuery({
		queryKey: getGetAllEditRoomsQueryKey(),
		queryFn: () => {
			return editRoomApi.getAllEditRooms();
		},
	});
};

export const useGetInfiniteEditRooms = () => {
	const editRoomApi = useApi(EditRoomApi);
	const perPage = 4;

	const query = useInfiniteQuery({
		queryKey: getGetAllEditRoomsQueryKey(),
		queryFn: ({ pageParam = 0 }) => {
			return editRoomApi.getAllEditRooms(
				transformToStringFrom({ ...defaultQueryFilter, page: pageParam, perPage: perPage, order: "desc" })
			);
		},
		getNextPageParam: (lastPage, allPages) => {
			const nextPage = allPages.length;
			const totalCount = parseInt(lastPage.headers["x-total-count"] ?? "0");
			const pagesCount = Math.floor(totalCount / perPage);
			if (nextPage > pagesCount) {
				return undefined;
			}
			return nextPage;
		},
	});

	const editRooms =
		query.data?.pages?.reduce((acc, page) => {
			return [...acc, ...page.data];
		}, [] as EditRoomFolder[]) ?? [];

	return { editRooms, ...query };
};

export const getGetSingleEditRoomFolderQueryKey = (editRoomFolderId: string) => {
	return ["editRoomFolder", editRoomFolderId];
};

export const useGetSingleEditRoomFolder = (editRoomId?: string) => {
	const editRoomApi = useApi(EditRoomApi);

	return useQuery({
		queryKey: getGetSingleEditRoomFolderQueryKey(editRoomId ?? ""),
		queryFn: () => {
			return editRoomApi.getPlainEditRoomFolder(editRoomId ?? "");
		},
		enabled: Boolean(editRoomId),
		select: (data) => data.data,
	});
};

export const getGetSingleEditRoomDocumentQueryKey = (editRoomDocumentId: string) => {
	return ["editRoomDocument", editRoomDocumentId];
};

export const useGetSingleEditRoomDocument = (editRoomDocumentId?: string) => {
	const editRoomApi = useApi(EditRoomApi);

	return useQuery({
		queryKey: getGetSingleEditRoomDocumentQueryKey(editRoomDocumentId ?? ""),
		queryFn: () => {
			return editRoomApi.getSingleEditRoomDocument(editRoomDocumentId ?? "");
		},
		enabled: Boolean(editRoomDocumentId),
		select: (data) => data.data,
	});
};
