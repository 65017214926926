import { Box, Link, SxProps, TableCell, Theme, useTheme } from "@mui/material";
import useResizeObserver from "@react-hook/resize-observer";
import { useEffect, useRef, useState } from "react";

type Props = {
	colSpans: number[];
	widths?: (number | undefined)[];
	children: React.ReactNode[];
	href?: string;
	sx?: SxProps<Theme>;
};

const CombinedLinkCell = ({ colSpans, href, children, sx }: Props) => {
	const theme = useTheme();
	const [cellWidth, setCellWidth] = useState(0);
	const containerRef = useRef<HTMLTableCellElement>(null);
	// const isLg = useMediaQuery(theme.breakpoints.up("lg"));

	useEffect(() => {
		setCellWidth(containerRef.current?.offsetWidth ?? 0);
	}, []);

	useResizeObserver(containerRef.current, (entry) => {
		if (cellWidth !== entry.target.clientWidth) setCellWidth(entry.target.clientWidth);
	});

	const totalSpan = colSpans.reduce((acc, current) => acc + current, 0);

	const content = (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				width: "100%",
				padding: { xs: 2, lg: 3 },
				boxSizing: "border-box",
				borderRadius: 3,
				transition: theme.transitions.create(["background-color"]),
				"&:hover": {
					backgroundColor: "backgroundSecondary.default",
				},
				...sx,
			}}
		>
			{children}
		</Box>
	);

	return (
		<TableCell ref={containerRef} colSpan={totalSpan} sx={{ padding: "0px !important" }}>
			{href ? (
				<Link href={href} sx={{ color: "black", textDecoration: "unset" }}>
					{content}
				</Link>
			) : (
				content
			)}
		</TableCell>
	);
};

export default CombinedLinkCell;
