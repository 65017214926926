import { Fade, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { DocumentApi } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import logotype from "../../../assets/logo/logotype.png";
import CertiblokIcon from "../../../utils/components/icons/CertiblokIcon";
import Loading from "../../../utils/components/Loading";
import { useApi } from "../../../utils/hooks/api";
import { useBetterMediaQuery } from "../../../utils/hooks/useBetterMediaQuery";
import DocumentPreview from "../../SingleDocument/components/DocumentPreview";

const PublicDocument = () => {
	const { t } = useTranslation();

	const params = useParams();
	const documentId = params.documentId;

	const documentApi = useApi(DocumentApi);

	const { data: document, isLoading } = useQuery({
		queryKey: ["document", documentId],
		queryFn: () => documentApi.getSingleDocument(documentId ?? ""),
		enabled: Boolean(documentId),
		select: (data) => data?.data,
	});

	const isMd = useBetterMediaQuery("md");

	return (
		<>
			<div className="bg-white w-full h-full max-h-full flex items-center flex-col overflow-y-auto p-4 md:p-6 gap-4 box-border">
				<div className="flex items-center justify-start md:justify-center w-full">
					<a href="https://certiblok.com/" className="md:h-16 h-8" target="_blank" rel="noreferrer">
						<img src={logotype} alt="Certiblok logo" className="md:h-16 h-8" />
					</a>
				</div>
				<div className="w-full flex-1 flex items-center justify-center bg-[#E6F4F7] rounded-t-[36px] md:px-4 md:py-6 relative">
					{isLoading ? (
						<Fade in={isLoading}>
							<Loading title={t("preview.loading")} sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }} />
						</Fade>
					) : document ? (
						<DocumentPreview
							downloadable
							document={document}
							currentFile={document?.lastVersionFile?.[0]}
							className="w-full min-h-full"
						/>
					) : (
						<div className="flex flex-col justify-center items-center max-w-[530px] p-4">
							<div className="w-14 h-14 flex justify-center items-center bg-error-a12 rounded-full">
								<CertiblokIcon name="comunication_outline_01" size={36} color="#F0372D" />
							</div>
							<Typography variant={isMd ? "h4" : "h6"} className="mt-3 font-extrabold text-black text-center">
								{t("qrCode.publicErrorDocument")}
							</Typography>
							<Typography variant={isMd ? "h6" : "body2"} className="md:mt-4 mt-2 text-black-a60 max-w-[470px] text-center">
								{t("qrCode.publicErrorDocumentDescription")}
							</Typography>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default PublicDocument;
