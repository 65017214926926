import { cn } from "@mabi-ui/utils";
import { Typography } from "@mui/material";
import { CloudFile } from "certiblok-api-manager";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useBetterMediaQuery } from "../../../../../../utils/hooks/useBetterMediaQuery";

const SlicedSpan = () => (
	<>
		<Typography variant="caption" component="span" className={cn("text-black/[0.6] ml-2")}>
			/
		</Typography>
		<Typography variant="caption" component="span" className={cn("text-black/[0.6] ml-2")}>
			...
		</Typography>
	</>
);

const ImportFromCloudContentBreadcrumbs = ({
	breadcrumbs,
	setBreadcrumbs,
}: {
	breadcrumbs: CloudFile[];
	setBreadcrumbs: Dispatch<SetStateAction<CloudFile[]>>;
}) => {
	const { t } = useTranslation();
	const isMd = useBetterMediaQuery("md");

	const slicedBreadcrumbs =
		breadcrumbs.length > (isMd ? 4 : 1)
			? [...(isMd ? breadcrumbs.slice(0, 1) : []), ...breadcrumbs.slice(isMd ? -2 : -1)]
			: breadcrumbs;

	return (
		<div className=" mb-2">
			<Typography
				variant="caption"
				component={"span"}
				className="text-black/[0.6] hover:underline cursor-pointer"
				onClick={() => setBreadcrumbs([])}
			>
				{t("documents.allFiles")}
			</Typography>
			{!isMd && slicedBreadcrumbs.length !== breadcrumbs.length && <SlicedSpan />}
			{slicedBreadcrumbs.map((breadcrumb, i) => {
				const isLast = i === slicedBreadcrumbs.length - 1;
				return (
					<Typography component="span" variant="caption" className="ml-2">
						<span className={cn("text-black/[0.6]")}>/</span>
						<span
							className={cn(isLast ? "text-black" : "hover:underline cursor-pointer text-black/[0.6]", "ml-2")}
							onClick={() =>
								setBreadcrumbs((prev) => {
									const index = prev.findIndex((b) => b.id === breadcrumb.id);
									return prev.slice(0, index + 1);
								})
							}
						>
							{breadcrumb.name}
						</span>
						{isMd && slicedBreadcrumbs.length !== breadcrumbs.length && i === 0 && <SlicedSpan />}
					</Typography>
				);
			})}
		</div>
	);
};

export default ImportFromCloudContentBreadcrumbs;
