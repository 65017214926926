import { Box, SxProps, Theme } from "@mui/material";
import useResizeObserver from "@react-hook/resize-observer";
import { useLayoutEffect, useRef, useState } from "react";

type Props = {
	sx?: SxProps<Theme>;
	disableScroll?: boolean;
};

const FillScreenScrollView: React.FC<Props> = ({ children, sx, disableScroll }) => {
	const ref = useRef<HTMLDivElement>();

	const [height, setHeight] = useState((ref.current?.offsetParent?.clientHeight ?? 0) - (ref.current?.offsetTop ?? 0));
	useLayoutEffect(() => {
		const newHeight = (ref.current?.offsetParent?.clientHeight ?? 0) - (ref.current?.offsetTop ?? 0);
		setHeight(newHeight);
	}, []);

	useResizeObserver(document.body, () => {
		const newHeight = (ref.current?.offsetParent?.clientHeight ?? 0) - (ref.current?.offsetTop ?? 0);
		if (height !== newHeight) setHeight(newHeight);
	});

	return (
		<Box
			ref={ref}
			sx={{
				width: "100%",
				height: disableScroll ? "auto" : height,
				overflowY: disableScroll ? undefined : "auto",
				...sx,
			}}
		>
			{children}
		</Box>
	);
};

export default FillScreenScrollView;
