import { Modal, ModalProps, Slide, useTheme } from "@mui/material";
import { animated, to, useSpring } from "@react-spring/web";
import clsx from "clsx";
import { forwardRef, ReactNode, useEffect, useImperativeHandle, useRef } from "react";
import { twMerge } from "tailwind-merge";
import useResizeObserver from "use-resize-observer";

type BottomSheetV2Props = {
	header?: ReactNode;
	footer?: ReactNode;
	className?: string;
	HeaderProps?: {
		className?: string;
	};
	BodyProps?: {
		className?: string;
		ref?: (ref: HTMLDivElement) => void;
	};
	FooterProps?: {
		className?: string;
	};
	onHeightChange?: (height: number) => void;
} & ModalProps;

export type BottomSheetV2Ref = {
	sendToBack: (args: { frontElemHeight: number }) => void;
	bringToFront: () => void;
	hide: () => void;
};

/**
 * ## Suggestion:
 * use it in combo with useBottomSheet hook and ModalNavigator
 */
const BottomSheet = forwardRef<BottomSheetV2Ref, BottomSheetV2Props>(
	(
		{ open, onClose, header, footer, children, className, HeaderProps, FooterProps, BodyProps, onHeightChange, ...props },
		ref
	) => {
		const containerRef = useRef<HTMLDivElement>(null);
		useResizeObserver({
			ref: containerRef,
			onResize: ({ height }) => {
				if (statusRef.current !== "front") return;
				onHeightChange?.(height ?? 0);
			},
		});

		const [{ translateY, scale }, api] = useSpring(
			() => ({
				translateY: 0,
				scale: 1,
			}),
			[]
		);

		const statusRef = useRef<"back" | "front" | "close">("front");

		// const [isBack, setIsBack] = useState(false);

		const theme = useTheme();

		useImperativeHandle(
			ref,
			() => {
				return {
					bringToFront: () => {
						statusRef.current = "front";
						onHeightChange?.(containerRef.current?.clientHeight ?? 0);
						// setIsBack(false);
						api.start({
							translateY: 0,
							scale: 1,
						});
					},
					sendToBack: ({ frontElemHeight }) => {
						statusRef.current = "back";
						// setIsBack(true);
						const screenWidth = window.innerWidth;
						api.start({
							translateY: (containerRef.current?.clientHeight ?? 0) - (frontElemHeight + 80),
							scale: screenWidth >= theme.breakpoints.values.md ? (screenWidth - 48 * 2) / screenWidth : 1,
						});
					},
					hide: () => {
						statusRef.current = "back";
						// setIsBack(true);
						const screenWidth = window.innerWidth;
						api.start({
							translateY: containerRef.current?.clientHeight ?? 0,
							scale: screenWidth >= theme.breakpoints.values.md ? (screenWidth - 48 * 2) / screenWidth : 1,
						});
					},
				};
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[]
		);

		useEffect(() => {
			const resizeHandler = () => {
				const screenWidth = window.innerWidth;
				if (statusRef.current === "back") {
					api.start({
						scale: screenWidth >= theme.breakpoints.values.md ? (screenWidth - 48 * 2) / screenWidth : 1,
					});
				}
			};
			window.addEventListener("resize", resizeHandler);
			return () => {
				window.removeEventListener("resize", resizeHandler);
			};
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		useEffect(() => {
			const timeout = setTimeout(() => {
				if (open && statusRef.current === "front") {
					onHeightChange?.(containerRef.current?.clientHeight ?? 0);
				}
			}, 0);

			if (!open) {
				statusRef.current = "close";
			} else {
				statusRef.current = "front";
			}

			return () => {
				clearTimeout(timeout);
			};
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [open]);

		return (
			<Modal
				componentsProps={{
					backdrop: {
						style: {
							// opacity: isBack ? 0 : 1,
							// transition: theme.transitions.create("opacity", {
							// 	easing: "linear",
							// }),
						},
					},
				}}
				open={open}
				onClose={onClose}
				closeAfterTransition
				{...props}
			>
				<Slide direction="up" in={open} enter>
					<div className="absolute bottom-0 outline-none w-full">
						<animated.div
							ref={containerRef}
							style={{
								maxHeight: "85vh",
								// @ts-ignore
								// eslint-disable-next-line no-dupe-keys
								maxHeight: "85svh",
								transform: to([translateY, scale], (translateY, scale) => {
									// return `translateY(${translateY}px) scale(${scale}})`;
									return `scale(${scale}) translate(0, ${translateY}px)`;
								}),
							}}
							className={twMerge(
								clsx("w-full bg-white rounded-t-[20px] md:rounded-t-[48px] flex flex-col shadow-12", className)
							)}
						>
							{header && (
								<div className={twMerge(clsx("pt-5 px-5 md:pt-6 md:px-12 pb-6 md:pb-12", HeaderProps?.className))}>
									{header}
								</div>
							)}
							<div
								ref={BodyProps?.ref}
								className={twMerge(clsx("overflow-auto horizontal-scroll flex-1", BodyProps?.className))}
							>
								{children}
							</div>
							{footer && <div className={twMerge(clsx("p-6 md:p-12", FooterProps?.className))}>{footer}</div>}
						</animated.div>
					</div>
				</Slide>
			</Modal>
		);
	}
);
export default BottomSheet;
