import { Box, IconButton, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import FillScreenScrollView from "../../FillScreenScrollView";
import CertiblokIcon from "../../icons/CertiblokIcon";
import { GlobalCalendarContext } from "../controllers/GlobalCalendarContext";
import SingleEvent from "./SingleEvent";

const DayEvents = React.forwardRef<any, any>((props, ref) => {
	const { i18n } = useTranslation();

	const calendarContext = useContext(GlobalCalendarContext);

	const events = calendarContext.selectedDay ? calendarContext.getDayEvents(calendarContext.selectedDay) : [];

	return (
		<Box
			ref={ref}
			sx={{
				width: "100%",
				paddingTop: 8,
				paddingBottom: {
					xs: 16,
					sm: 0,
				},
			}}
			{...props}
		>
			<Box sx={{ width: "100%", height: "1px", backgroundColor: "rgba(255, 255, 255, 0.12)" }} />
			<Box sx={{ py: 3, display: "flex", alignItems: "center" }}>
				<Typography
					children={calendarContext.selectedDay?.toFormat("cccc dd MMMM", { locale: i18n.language })}
					variant="subtitle1"
					sx={{ color: "white", flexGrow: 1, textTransform: "capitalize" }}
				/>
				<IconButton
					color="secondary"
					sx={{ color: "white" }}
					onClick={() => {
						calendarContext.setSelectedDay(calendarContext.selectedDay?.minus({ days: 1 }));
					}}
				>
					<CertiblokIcon size={24} color="white" name={"arrow_mini_outline_left"} iconCss={{ opacity: 0.74 }} />
				</IconButton>
				<IconButton
					color="secondary"
					sx={{ color: "white" }}
					onClick={() => {
						calendarContext.setSelectedDay(calendarContext.selectedDay?.plus({ days: 1 }));
					}}
				>
					<CertiblokIcon size={24} color="white" name={"arrow_mini_outline_right"} iconCss={{ opacity: 0.74 }} />
				</IconButton>
			</Box>
			<FillScreenScrollView disableScroll sx={{ minHeight: events.length <= 2 ? 100 * events.length : undefined }}>
				{calendarContext.selectedDay &&
					events.length > 0 &&
					events.map((elem) => {
						return (
							<SingleEvent
								event={elem}
								key={`${
									elem.typeOfEvent === "document" ? "Documento" : elem.typeOfEvent === "audit_room" ? "Audit_room" : "Event_"
								}_${elem.elementId}`}
							/>
						);
					})}
			</FillScreenScrollView>
		</Box>
	);
});

export default DayEvents;
