/** @jsxImportSource @emotion/react */
import { cn } from "@mabi-ui/utils";
import { Box, Stack, Typography } from "@mui/material";
import { UserOrContact } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import { backendMediaUrlParser } from "..";
import avatarPlaceholder from "../../assets/avatar-placeholder.png";

const UserAutocompleteOption = ({
	user,
	isDisabled,
	isGuest,
	color = "secondary",
}: {
	user: UserOrContact;
	isDisabled?: boolean;
	isGuest?: boolean;
	color?: "primary" | "secondary" | "neutral";
}) => {
	const { t } = useTranslation();
	return (
		<div
			className={cn("w-full flex flex-row gap-4 items-center justify-between", isDisabled && "opacity-60 cursor-default")}
		>
			<Stack direction="row" gap={4} alignItems="center" justifyContent="flex-start" className="flex-1">
				<img
					src={user.profileUrl ? backendMediaUrlParser(user.profileUrl) : avatarPlaceholder}
					alt={`${user.name} avatar`}
					css={{ width: 24, height: 24, borderRadius: 12, backgroundColor: "rgba(0,0,0,0.06)" }}
				/>
				<Box
					sx={{
						display: "flex",
						width: `calc(100% - ${24 + 16}px)`,
						flexDirection: "column",
						alignItems: "start",
						justifyContent: "center",
						textAlign: "start",
					}}
				>
					<Typography
						children={`${user.name} ${user.surname}`}
						noWrap
						variant="subtitle2"
						color={color === "neutral" ? "black" : color}
						sx={{ width: "100%" }}
					/>
					<Typography
						children={user.email}
						noWrap
						variant="caption"
						color={color === "neutral" ? "rgba(0,0,0,0.6)" : color}
						sx={{ width: "100%" }}
					/>
				</Box>
			</Stack>
			{isGuest && (
				<Typography
					children={t("invitation.role.admin")}
					variant="overline"
					noWrap
					sx={{
						backgroundColor: "primary.main",
						color: "white",
						px: 1,
						py: 0.5,
						borderRadius: 1,
						fontWeight: 700,
						flexShrink: 0,
					}}
				/>
			)}
		</div>
	);
};

export default UserAutocompleteOption;
