import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BackButtonWithTitle } from "../../../utils/components/BackButtonWithTitle";
import Loading from "../../../utils/components/Loading";
import OutletContainer from "../../../utils/components/OutletContainer";
import { useGetSingleSharingGroup } from "../hooks/useContactGroupsHooks";
import GroupDetail from "./components/GroupDetail";
import SharedNotesTable from "./components/SharedNotesTable/SharedNotesTable";

const SingleGroup = () => {
	const { t } = useTranslation();
	const { id } = useParams();

	const { data: group, isLoading } = useGetSingleSharingGroup(id!);

	return (
		<OutletContainer>
			{isLoading ? (
				<Loading title={t("contactsGroups.loadingGroup")} sx={{ width: "100%", height: "100%" }} />
			) : group ? (
				<div className="box-border">
					<BackButtonWithTitle title={t("contactsGroups.groupDetail")} className="mb-6 w-fit" />
					<Grid container sx={{ width: "100%" }} rowSpacing={10} alignItems={"flex-start"}>
						<Grid item xs={12} md={8} lg={6}>
							<GroupDetail group={group} />
						</Grid>
						<Grid item xs={12} md={12} lg={12}>
							<SharedNotesTable groupId={group.id} />
						</Grid>
					</Grid>
				</div>
			) : null}
		</OutletContainer>
	);
};

export default SingleGroup;
