import { Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useBetterMediaQuery } from "../../../../../hooks/useBetterMediaQuery";
import CertiblokTextInput from "../../../../CertiblokTextInput";
import FileIcon from "../../../../icons/FileIcon";
import { useUploadDocumentContext } from "../../../contexts/UploadDocumentContext";
import {
	useEditUploadedFileDraft,
	useUploadedFileById,
	useUploadedFileDraftById,
} from "../../../stores/uploadedFilesStore";
import DocumentAssingedAuditRooms from "./DocumentAssingedAuditRooms";
import DocumentExpirationDate from "./DocumentExpirationDate";
import UploadedDocumentShares from "./UploadedDocumentShares";

type UploadedDocumentDetailsProps = {
	documentId: string;
};
const UploadedDocumentDetails: React.FC<UploadedDocumentDetailsProps> = memo(({ documentId }) => {
	const { t } = useTranslation();
	const isLg = useBetterMediaQuery("lg");

	const uploadedFile = useUploadedFileById(documentId);
	const document = uploadedFile.document;
	const editDocumentDraft = useEditUploadedFileDraft();
	const uploadedDocumentDraft = useUploadedFileDraftById(document.id);

	const { type } = useUploadDocumentContext();
	const isEditRoom = type === "uploadEditRoomDocument";

	const documentDetails = (
		<div className="flex justify-between flex-col">
			<Typography
				children={t("uploadDocument.documentDetails")}
				className="!text-label uppercase md:normal-case md:!text-subtitle1 font-bold text-black"
			/>
			<div className="flex items-center gap-2 w-full">
				<FileIcon file={document?.lastVersionFile?.[0]} />
				<CertiblokTextInput
					placeholder="Nome documento"
					value={uploadedDocumentDraft?.documentName}
					onChange={(e) => {
						editDocumentDraft(document.id, { documentName: e.target.value });
					}}
					size="small"
					color="secondary"
					containerCss={{
						flex: 1,
					}}
					className="bg-white"
				/>
			</div>
			{/* <DocumentWithIcon document={document?.lastVersionFile?.[0]} name={document.name} sx={{ mb: { xs: 1, md: "6px" } }} /> */}
		</div>
	);

	return (
		<div className="w-full bg-backgroundSecondary rounded-2xl grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4 p-4 box-border">
			{isLg ? (
				<div className="grid grid-cols-2 col-span-2 gap-x-6 ">
					<div className="grid grid-cols-2 gap-x-6">
						{documentDetails}
						<DocumentExpirationDate document={document} />
					</div>
				</div>
			) : (
				<>
					{documentDetails}
					<DocumentExpirationDate document={document} />
				</>
			)}
			{!isEditRoom && (
				<>
					<DocumentAssingedAuditRooms documentId={documentId} />
					<UploadedDocumentShares documentId={documentId} />
				</>
			)}
		</div>
	);
});
export default UploadedDocumentDetails;
