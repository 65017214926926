import { IconButton, lighten, touchRippleClasses } from "@mui/material";

import { useNavigate } from "react-router-dom";
import CertiblokIcon from "../../icons/CertiblokIcon";

type TableHeaderBackButtonProps = {
	onClick?: () => void;
	goToDashboard?: boolean;
};

const TableHeaderBackButton: React.FC<TableHeaderBackButtonProps> = ({ onClick, goToDashboard }) => {
	const navigate = useNavigate();

	return (
		<IconButton
			onClick={() => {
				onClick ? onClick() : goToDashboard ? navigate("/") : navigate(-1);
			}}
			sx={{
				backgroundColor: (theme) => theme.palette.secondary.main,
				padding: 0,
				borderRadius: 2,
				"&:hover": {
					backgroundColor: (theme) => lighten(theme.palette.secondary.main, 0.06),
				},
				[`& .${touchRippleClasses.child}`]: {
					borderRadius: 2,
				},
			}}
		>
			<CertiblokIcon name="arrow_mini_outline_left" size={26} color="white" />
		</IconButton>
	);
};

export default TableHeaderBackButton;
