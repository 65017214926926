import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { useUserDetailsZodSchema } from "./useUserDetailsZodSchema";
import { DateTime } from "luxon";
import { useGroupDetailsZodSchema } from "./useGroupDetailsZodSchema";

export type EditRoomFormValues = z.infer<ReturnType<typeof useEditRoomDetailsZodSchema>>;

export const useEditRoomDetailsZodSchema = () => {
	const { t } = useTranslation();

	const userSchema = useUserDetailsZodSchema();
	const groupSchema = useGroupDetailsZodSchema();

	return useMemo(() => {
		return z.object({
			name: z.string().min(1, {
				message: t("editRoom.editRoomMustHaveAName"),
			}),
			color: z.string(),
			lockEditRoomEnabled: z.boolean().optional(),
			reservedToUsers: userSchema.array().optional(),
			reservedToTeams: groupSchema.array().optional(),
			noExpiration: z.boolean().optional(),
			expireDate: z
				.string()
				.nullish()
				.refine((v) => !v || DateTime.fromISO(v).isValid || v === "null"),
		});
	}, [t, userSchema, groupSchema]);
};
