import React from "react";
import { useCallback, useState } from "react";
import type { FolderHistoryItem } from "../interfaces/FolderHistoryItem";
import type { IFolderHistoryContext } from "../interfaces/IFolderHistoryContext";

export function useFolderHistoryContext(): IFolderHistoryContext {
	const [folderHistory, setFolderHistory] = useState<FolderHistoryItem[]>([]);

	const updateHistory = useCallback((items: FolderHistoryItem[]) => {
		setFolderHistory([...items]);
	}, []);

	return {
		folderHistory,
		updateHistory,
	};
}

export const FolderHistoryContext = React.createContext<IFolderHistoryContext>({} as IFolderHistoryContext);
