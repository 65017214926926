import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
	useGetSingleEditRoomDocument,
	useGetSingleEditRoomFolder,
} from "../../../pages/EditRooms/queries/useGetEditRooms";
import { EditRoomFormValues, useEditRoomDetailsZodSchema } from "./useEditRoomDetailsZodSchema";
import { AvailableFolderColors } from "../../../../Folders/constants/AvailableFolderColors";

export const useInitEditRoomDetailsForm = ({
	editRoomFolderId: editRoomId,
	open,
	editRoomDocumentId,
}: {
	editRoomFolderId?: string;
	editRoomDocumentId?: string;
	open?: boolean;
}) => {
	const editRoomDetailsZodSchema = useEditRoomDetailsZodSchema();

	const editRoomDetailsForm = useForm<EditRoomFormValues>({
		resolver: zodResolver(editRoomDetailsZodSchema),
		defaultValues: {
			color: AvailableFolderColors[0],
			lockEditRoomEnabled: false,
			name: "",
			reservedToUsers: [],
			reservedToTeams: [],
			expireDate: undefined,
			noExpiration: false,
		},
	});

	const { data: editRoom } = useGetSingleEditRoomFolder(editRoomId);

	const { data: editRoomDocument } = useGetSingleEditRoomDocument(editRoomDocumentId);

	useEffect(() => {
		if (!open) {
			editRoomDetailsForm.reset({
				color: AvailableFolderColors[0],
				lockEditRoomEnabled: false,
				name: "",
				reservedToUsers: [],
				reservedToTeams: [],
				expireDate: undefined,
				noExpiration: false,
			});
			return;
		}
		if (editRoomDocument) {
			editRoomDetailsForm.reset({
				name: editRoomDocument.name,
				expireDate: editRoomDocument.expireDate,
				noExpiration: editRoomDocument.noExpiration ?? false,
				color: "#000000",
				lockEditRoomEnabled: false,
				reservedToUsers: [],
				reservedToTeams: [],
			});
		}
		if (editRoom) {
			editRoomDetailsForm.reset({
				name: editRoom.name,
				color: editRoom.color,
				reservedToUsers: (editRoom.reservedToUsers ?? []).map((user) => ({
					...user,
					isNewAddedUser: false,
					userId: user.id,
				})),
				reservedToTeams: (editRoom.reservedToTeams ?? []).map((team) => ({
					...team,
					isNewAddedUser: false,
					teamId: team.id,
				})),
				lockEditRoomEnabled: editRoom.isReserved,
				expireDate: undefined,
				noExpiration: false,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, editRoom, editRoomDocument]);

	return editRoomDetailsForm;
};
