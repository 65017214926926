import { Box, Button, ButtonProps, CircularProgress, Fade } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

interface Props extends ButtonProps {
	icon: React.ReactChild;
	loading?: boolean;
}

export function isPromise(value: any): value is Promise<any> {
	return value?.then !== undefined;
}

const LoadingIconButton = React.forwardRef<any, Props>((props, ref) => {
	const { icon, children, loading, onClick, ...otherProps } = { ...props };

	const [innerLoading, setInnerLoading] = useState(false);

	const hasBeenUnmounted = useRef(false);

	useEffect(() => {
		hasBeenUnmounted.current = false;

		return () => {
			hasBeenUnmounted.current = true;
		};
	}, []);

	const loadingClick = async (ev: any) => {
		if (onClick) {
			const res = onClick(ev);
			if (isPromise(res)) {
				setInnerLoading(true);
				await res;
				if (!hasBeenUnmounted.current) {
					setInnerLoading(false);
				}
			}
		}
	};

	return (
		<Button disabled={loading || innerLoading || props.disabled} onClick={loadingClick} ref={ref} {...otherProps}>
			<Box sx={{ position: "relative" }}>
				<Fade in={loading ?? innerLoading} unmountOnExit>
					<CircularProgress size={24} sx={{ color: "inherit", position: "absolute", left: 0 }} />
				</Fade>
				<Fade in={!(loading ?? innerLoading)}>
					<Box>{icon}</Box>
				</Fade>
			</Box>
			{children}
		</Button>
	);
});

export default LoadingIconButton;
