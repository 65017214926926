import { TableRow, TableCell, TableCellProps, TableRowProps } from "@mui/material";
import type { IHeadCell } from "../AdminTable";

interface Props extends TableCellProps {
	hasOption: boolean;
	hasCheckbox: boolean;
	headCells: IHeadCell[];
	rowProps?: TableRowProps;
}

const FullWidthEmptyCell: React.FC<Props> = ({
	hasOption,
	hasCheckbox,
	headCells,
	children,
	rowProps,
	...otherProps
}) => {
	return (
		<TableRow {...rowProps}>
			<TableCell
				colSpan={
					headCells.reduce((acc, currentCell) => {
						return acc + (currentCell.colSpan ?? 1);
					}, 0) +
					(hasCheckbox ? 1 : 0) +
					(hasOption ? 1 : 0)
				}
				sx={{
					"&.MuiTableCell-root": {
						padding: 0,
					},
				}}
				{...otherProps}
			>
				{children}
			</TableCell>
		</TableRow>
	);
};

export default FullWidthEmptyCell;
