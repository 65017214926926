import type { CSSObject } from "@emotion/styled";
import { alpha, useTheme } from "@mui/material";
import { blendWithWhite, rgbaStringToRGBA, RGBAtoString } from "../../functions/colors";
import type { AuditStatuses } from "../../interfaces/AuditRoom";
import CertiblokIcon from "../icons/CertiblokIcon";

type IconStatusProps = {
	status?: AuditStatuses;
	iconCss?: CSSObject;
	hasBorder?: boolean;
};

export const AuditRoomIconStatus: React.FC<IconStatusProps> = ({ status, hasBorder, iconCss = {} }) => {
	const theme = useTheme();
	switch (status) {
		case "approved":
			return (
				<CertiblokIcon
					size={16}
					color="success"
					name={"success"}
					iconCss={{
						padding: 6,
						borderRadius: 8,
						backgroundColor: RGBAtoString(
							blendWithWhite(rgbaStringToRGBA(alpha(theme.palette.success.main, hasBorder ? 0.38 : 0.12)))
						),
						...iconCss,
					}}
				/>
			);
		case "approved-with-reserve":
			return (
				<CertiblokIcon
					size={16}
					color="orange"
					name={"comunication_outline_01"}
					iconCss={{
						padding: 6,
						borderRadius: 8,
						backgroundColor: RGBAtoString(
							blendWithWhite(rgbaStringToRGBA(alpha(theme.palette.orange.main, hasBorder ? 0.38 : 0.12)))
						),
						...iconCss,
					}}
				/>
			);
		case "rejected":
			return (
				<CertiblokIcon
					size={16}
					color="error"
					name={"comunication_outline_02"}
					iconCss={{
						padding: 6,
						borderRadius: 8,
						backgroundColor: RGBAtoString(
							blendWithWhite(rgbaStringToRGBA(alpha(theme.palette.error.main, hasBorder ? 0.38 : 0.12)))
						),
						...iconCss,
					}}
				/>
			);
		case "waiting":
			return (
				<CertiblokIcon
					size={16}
					color="indaco"
					name={"calendar_outline"}
					iconCss={{
						padding: 6,
						borderRadius: 8,
						backgroundColor: RGBAtoString(
							blendWithWhite(rgbaStringToRGBA(alpha(theme.palette.indaco.main, hasBorder ? 0.38 : 0.12)))
						),
						...iconCss,
					}}
				/>
			);
		case "revision":
			return (
				<CertiblokIcon
					size={16}
					color="lightBlue"
					name={"spinner"}
					iconCss={{
						padding: 6,
						borderRadius: 8,
						backgroundColor: RGBAtoString(
							blendWithWhite(rgbaStringToRGBA(alpha(theme.palette.lightblue.main, hasBorder ? 0.38 : 0.12)))
						),
						...iconCss,
					}}
				/>
			);

		default:
			return (
				<CertiblokIcon
					size={16}
					color="orange"
					name={"comunication_outline_02"}
					iconCss={{
						padding: 6,
						borderRadius: 8,
						backgroundColor: RGBAtoString(
							blendWithWhite(rgbaStringToRGBA(alpha(theme.palette.orange.main, hasBorder ? 0.38 : 0.12)))
						),
						...iconCss,
					}}
				/>
			);
	}
};
