import { Duration } from "luxon";
import { TFunction } from "react-i18next";

export const parseReminderOccurrenceString = (
	t: TFunction<"translation", undefined>,
	mins?: number,
	before: boolean = true
) => {
	// const normalizedObj = Duration.fromObject({ month: 0, week: 0, day: 0, hour: 0, minutes: mins })
	// 	.normalize()
	// 	.toObject();
	// const zeroStrippedObj = Object.keys(normalizedObj).reduce((tot, curr) => {
	// 	if (!(normalizedObj[curr as keyof typeof normalizedObj] === 0)) {
	// 		return { ...tot, [curr]: normalizedObj[curr as keyof typeof normalizedObj] };
	// 	}
	// 	return tot;
	// }, {} as DurationObjectUnits);

	if (mins === 0) {
		return before ? t("recurrence.atTheEventTime") : t("global.now");
	}

	if (!mins) return "";

	const duration = Duration.fromObject({ minutes: mins }, {conversionAccuracy: "longterm"}).rescale();

	const getTime = () => {
		if (duration.years > 0) {
			return `${t("recurrence.yearsBefore", { count: duration.years })}`;
		} else if (duration.months > 0 && duration.days < 1) {
			return `${t("recurrence.monthsBefore", { count: duration.months })}`;
		} else if (duration.months > 0) {
			return `${t("recurrence.monthsBefore", { count: duration.months })} ${t("global.and")} ${t("recurrence.daysBefore", {
				count: duration.days,
			}).toLowerCase()}`;
		} else if (duration.weeks > 0 && duration.days === 0) {
			return `${t("recurrence.weeksBefore", { count: duration.weeks })}`;
		} else if (duration.weeks > 0 || duration.days > 0) {
			return `${t("recurrence.daysBefore", { count: duration.weeks * 7 + duration.days })}`;
		} else if (duration.hours > 0 && duration.minutes < 1) {
			return `${t("recurrence.hoursBefore", { count: duration.hours })}`;
		} else if (duration.hours > 0) {
			return `${t("recurrence.hoursBefore", { count: duration.hours })} ${t("global.and")} ${t(
				duration.minutes === 30 ? "recurrence.halfAnHour" : "recurrence.minutesBefore",
				{ count: duration.minutes }
			).toLowerCase()}`;
		} else {
			return `${t(duration.minutes === 30 ? "recurrence.halfAnHour" : "recurrence.minutesBefore", {
				count: duration.minutes,
			})}`;
		}
	};

	return before ? `${getTime()} ${t("recurrence.before")}` : `${t("recurrence.in")} ${getTime()}`;
};
