import { alpha, createTheme, lighten, ThemeOptions } from "@mui/material";
import CertiblokIcon from "./utils/components/icons/CertiblokIcon";
import LinkBehavior from "./utils/components/LinkBehavior";
import { pxToRem } from "./utils/functions/theme";
declare module "@mui/material/styles" {
	interface TypographyVariants {
		display1: React.CSSProperties;
		display2: React.CSSProperties;
		link: React.CSSProperties;
		label: React.CSSProperties;
	}

	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		display1?: React.CSSProperties;
		display2?: React.CSSProperties;
		link?: React.CSSProperties;
		label?: React.CSSProperties;
	}

	interface Palette {
		backgroundSecondary: Palette["background"];
		surface: Palette["primary"];
		magenta: Palette["primary"];
		orange: Palette["primary"];
		lightblue: Palette["primary"];
		blue: Palette["primary"];
		indaco: Palette["primary"];
		purple: Palette["primary"];
		pink: Palette["primary"];
	}
	interface PaletteOptions {
		backgroundSecondary: PaletteOptions["background"];
		surface: PaletteOptions["primary"];
		magenta: PaletteOptions["primary"];
		orange: PaletteOptions["primary"];
		lightblue: PaletteOptions["primary"];
		blue: PaletteOptions["primary"];
		indaco: PaletteOptions["primary"];
		purple: PaletteOptions["primary"];
		pink: PaletteOptions["primary"];
	}
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
	interface TypographyPropsVariantOverrides {
		display1: true;
		display2: true;
		link: true;
		label: true;
	}
}

export let theme = createTheme({
	typography: {
		fontFamily: ['"Nunito Sans"', "Roboto", "sans-serif"].join(","),
		display1: {
			fontSize: pxToRem(288),
			letterSpacing: -15,
			lineHeight: "392.8px",
			fontWeight: 700,
			fontFamily: ['"Nunito Sans"', "Roboto", "sans-serif"].join(","),
		},
		display2: {
			fontSize: pxToRem(144),
			letterSpacing: -7.5,
			lineHeight: "196.4px",
			fontWeight: 700,
			fontFamily: ['"Nunito Sans"', "Roboto", "sans-serif"].join(","),
		},
		h1: {
			fontSize: pxToRem(96),
			letterSpacing: -1.5,
			lineHeight: "130.9px",
			fontWeight: 700,
		},
		h2: {
			fontSize: pxToRem(64),
			letterSpacing: -0.5,
			lineHeight: "87.3px",
		},
		h3: {
			fontSize: pxToRem(48),
			letterSpacing: 0,
			lineHeight: "65.5px",
		},
		h4: {
			fontSize: pxToRem(36),
			letterSpacing: 0.5,
			lineHeight: "49.1px",
		},
		h5: {
			fontSize: pxToRem(24),
			letterSpacing: 0.5,
			lineHeight: "32.7px",
		},
		h6: {
			fontSize: pxToRem(20),
			letterSpacing: 0.75,
			lineHeight: "28px",
		},
		subtitle1: {
			fontSize: pxToRem(16),
			letterSpacing: 0.15,
			lineHeight: "20px",
			fontWeight: 700,
		},
		subtitle2: {
			fontSize: pxToRem(14),
			letterSpacing: 0.1,
			lineHeight: "18px",
			fontWeight: 700,
		},
		body1: {
			fontSize: pxToRem(16),
			letterSpacing: 0.5,
			lineHeight: "20px",
			fontWeight: 400,
		},
		body2: {
			fontSize: pxToRem(16),
			letterSpacing: 0.5,
			lineHeight: "20px",
			fontWeight: 400,
		},
		button: {
			fontSize: pxToRem(14),
			letterSpacing: 1.25,
			lineHeight: "16px",
			fontWeight: 400,
		},
		caption: {
			fontSize: pxToRem(14),
			letterSpacing: 0.25,
			lineHeight: "18px",
			fontWeight: 400,
		},
		link: {
			fontSize: pxToRem(14),
			letterSpacing: 1.25,
			lineHeight: "16px",
			textDecoration: "underline",
			fontFamily: ['"Nunito Sans"', "Roboto", "sans-serif"].join(","),
		},
		overline: {
			fontSize: pxToRem(10),
			letterSpacing: 1.5,
			lineHeight: "12px",
		},
		label: {
			fontSize: pxToRem(12),
			letterSpacing: 0.4,
			lineHeight: "14px",
			color: alpha("#000000", 0.6),
			fontFamily: ['"Nunito Sans"', "Roboto", "sans-serif"].join(","),
		},
	},
	palette: {
		primary: {
			main: "#52BD8F",
			light: "#75DB8F",
			dark: "#339699",
			contrastText: "#FFFFFF",
		},
		secondary: {
			main: "#43A9BF",
			light: "#9DD2DE",
			dark: "#3B95A8",
			contrastText: "#FFFFFF",
		},
		success: {
			main: "#34C759",
			contrastText: "#FFFFFF",
		},
		error: {
			main: "#FF3B30",
			contrastText: "#FFFFFF",
		},
		warning: {
			main: "#FFCC00",
			contrastText: "#FFFFFF",
		},
		background: {
			default: "#E7F5E6",
		},
		backgroundSecondary: {
			default: "#F4FAFB",
		},
		surface: {
			main: "#FFFFFF",
		},
		magenta: {
			main: "#FF2D55",
		},
		orange: {
			main: "#FF9500",
		},
		lightblue: {
			main: "#5AC8FA",
		},
		blue: {
			main: "#007AFF",
		},
		indaco: {
			main: "#5856D6",
		},
		purple: {
			main: "#AF52DE",
		},
		pink: {
			main: "#FFA8E2",
		},
	},
	spacing: 4,
});

theme = createTheme(theme, {
	components: {
		MuiInputBase: {
			styleOverrides: {
				input: {
					"::placeholder": {
						opacity: 0.74,
					},
					// fontSize: `${pxToRem(16)} !important`,
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					boxShadow: "none",
					gap: 8,
					transition: theme.transitions.create(["background-color", "color", "opacity", "box-shadow", "border-color"]),
					"&.Mui-disabled": {
						opacity: 0.38,
						color: "#000000",
					},
					"&.Mui-focusVisible": {
						"& span:first-of-type": {
							display: "none",
						},
					},
				},
				endIcon: {
					marginLeft: 0,
				},
				startIcon: {
					marginRight: 0,
				},
				sizeLarge: {
					borderRadius: 12,
					padding: "21px 16px",
					fontSize: pxToRem(14),
					height: 56,
					fontWeight: 700,
					lineHeight: "unset",
					letterSpacing: 1.25,
					textTransform: "uppercase",
				},
				sizeMedium: {
					borderRadius: 10,
					height: 40,
					padding: "10.5px 12px",
					fontSize: pxToRem(14),
					fontWeight: 700,
					lineHeight: "unset",
					letterSpacing: 0.25,
					textTransform: "unset",
					// "&:hover": {
					// 	// padding: "13px 12px",
					// 	fontWeight: 700,
					// 	// letterSpacing: "calc(0.0000002vw)",
					// 	letterSpacing: "0.000001px",
					// },
				},
				sizeSmall: {
					height: 32,
					borderRadius: 4,
					padding: "8px",
					fontSize: pxToRem(12),
					fontWeight: 700,
					lineHeight: "unset",
					letterSpacing: 0.4,
					textTransform: "unset",
					// "&:hover": {
					// 	fontWeight: 700,
					// 	letterSpacing: "0.2px",
					// },
				},
				contained: {
					"&:hover": {
						boxShadow: "0px 3px 14px rgba(0, 0, 0, 0.12)",
					},
					"&:active": {
						boxShadow: "0px 3px 14px rgba(0, 0, 0, 0.12)",
					},
					"&.Mui-disabled": {
						backgroundColor: alpha("#000000", 0.12),
					},
				},
				outlined: {
					"&.Mui-disabled": {
						border: `1px solid ${alpha("#000000", 0.38)}`,
					},
				},
				containedPrimary: ({ theme }) => ({
					"&:hover": {
						backgroundColor: lighten(theme.palette.primary.main, 0.12),
						boxShadow: "0px 3px 1px rgba(0, 0, 0, 0.12)",
						"&:not(.MuiButton-sizeLarge)": {
							boxShadow: "0px 3px 14px rgba(0, 0, 0, 0.12)",
						},
					},
					"&.Mui-disabled": {
						"& .MuiButton-startIcon": {
							color: alpha("#000000", 0.6),
						},
					},
					"&.Mui-focusVisible": {
						backgroundColor: lighten(theme.palette.primary.main, 0.12),
					},
				}),
				outlinedPrimary: ({ theme }) => ({
					border: `1px solid ${theme.palette.primary.dark}`,
					"&:hover": {
						backgroundColor: alpha(`${theme.palette.primary.main}`, 0.12),
					},
					"&.Mui-focusVisible": {
						backgroundColor: alpha(`${theme.palette.primary.main}`, 0.12),
					},
				}),
				textPrimary: {
					"&:hover": {
						backgroundColor: alpha(`${theme.palette.primary.main}`, 0.12),
					},
					"&.Mui-focusVisible": {
						backgroundColor: alpha(`${theme.palette.primary.main}`, 0.12),
					},
				},
				containedSecondary: {
					"&:hover": {
						backgroundColor: "#5AB3C7",
						"& .MuiButton-startIcon": {
							color: alpha("#FFFFFF", 0.87),
						},
					},
					"&.Mui-disabled": {
						backgroundColor: alpha("#000000", 0.12),
						color: alpha("#000000", 0.38),
						"& .MuiButton-startIcon": {
							color: "#000000",
						},
					},
					"&.Mui-focusVisible": {
						backgroundColor: "#5AB3C7",
					},
				},
				outlinedSecondary: {
					border: `1px solid #3B95A8`,
					"&:hover": {
						backgroundColor: alpha(`${theme.palette.secondary.main}`, 0.12),
					},
					"&.Mui-focusVisible": {
						backgroundColor: alpha(`${theme.palette.secondary.main}`, 0.12),
					},
					// "&.Mui-disabled": {
					// 	color: alpha("#000000", 0.38),
					// },
				},
				textSecondary: {
					"&:hover": {
						backgroundColor: alpha(`${theme.palette.secondary.main}`, 0.12),
					},
					"&.Mui-disabled": {
						color: alpha("#000000", 0.38),
						"& .MuiButton-startIcon": {
							color: "#000000",
						},
					},
					"&.Mui-focusVisible": {
						backgroundColor: alpha(`${theme.palette.secondary.main}`, 0.12),
					},
				},
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				label: {
					fontWeight: 700,
					lineHeight: "14px",
					fontSize: pxToRem(14),
					letterSpacing: 0.1,
				},
			},
		},
		MuiSwitch: {
			styleOverrides: {
				track: {
					backgroundColor: alpha("#000000", 0.12),
				},
			},
		},
		MuiFab: {
			styleOverrides: {
				root: {
					fontSize: pxToRem(14),
					lineHeigth: "14px",
					letter: 1.25,
					padding: 6,
				},
				circular: {
					"&.MuiFab-sizeSmall": {
						height: 36,
						width: 36,
						"& .MuiSvgIcon-root": {
							fontSize: pxToRem(24),
						},
					},
					"&.MuiFab-sizeMedium": {
						height: 40,
						width: 40,
						"& .MuiSvgIcon-root": {
							fontSize: pxToRem(28),
						},
					},
					"&.MuiFab-sizeLarge": {
						height: 48,
						width: 48,
						"& .MuiSvgIcon-root": {
							fontSize: pxToRem(36),
						},
					},
				},
				extended: {
					paddingRight: 16,
					"&.MuiFab-sizeSmall": {
						height: 36,
						"& .MuiSvgIcon-root": {
							fontSize: pxToRem(24),
						},
					},
					"&.MuiFab-sizeMedium": {
						height: 40,
						"& .MuiSvgIcon-root": {
							fontSize: pxToRem(28),
						},
					},
					"&.MuiFab-sizeLarge": {
						height: 48,
						"& .MuiSvgIcon-root": {
							fontSize: pxToRem(36),
						},
					},
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				root: {
					transition: theme.transitions.create(["background-color", "color", "filter", "box-shadow"]),
					"&.Mui-disabled": {
						backgroundColor: alpha("#000000", 0.06),
						color: alpha("#000000", 0.87),
						opacity: 0.38,
						"& .MuiChip-deleteIcon": {
							color: alpha("#000000", 0.38),
						},
						"& .MuiChip-avatar": {
							backgroundColor: alpha("#000000", 0.38),
						},
					},
				},
				colorPrimary: {
					backgroundColor: alpha(theme.palette.primary.main, 0.06),
					color: alpha("#000000", 0.6),
					"&.Mui-focusVisible": {
						backgroundColor: alpha(theme.palette.primary.main, 0.12),
					},
					"&:hover": {
						backgroundColor: alpha(theme.palette.primary.main, 0.12),
						color: "#000000",
					},
					"&:focus-visible": {
						filter: "brightness(0.8)",
						color: "#000000",
					},
					"&:active": {
						backgroundColor: alpha(theme.palette.primary.main, 0.24),
						boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.12)",
						color: "#000000",
					},
				},
				deleteIconColorPrimary: {
					color: alpha(theme.palette.primary.main, 0.6),
					transition: theme.transitions.create(["color"]),
					"&:hover": {
						color: alpha(theme.palette.primary.main, 1),
					},
					"&:active": {
						color: alpha(theme.palette.primary.main, 1),
					},
				},
				iconColorPrimary: {
					color: alpha(theme.palette.primary.main, 1),
				},
				iconColorSecondary: {
					color: alpha(theme.palette.secondary.main, 1),
				},
				colorSecondary: {
					backgroundColor: alpha(theme.palette.secondary.main, 0.06),
					color: alpha("#000000", 0.6),
					"&.Mui-focusVisible": {
						backgroundColor: alpha(theme.palette.secondary.main, 0.12),
					},
					"&:hover": {
						backgroundColor: alpha(theme.palette.secondary.main, 0.12),
						color: "#000000",
					},
					"&:focus-visible": {
						filter: "brightness(0.8)",
						color: "#000000",
					},
					"&:active": {
						backgroundColor: alpha(theme.palette.secondary.main, 0.24),
						boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.12)",
						color: "#000000",
					},
				},
				deleteIconColorSecondary: {
					color: alpha(theme.palette.secondary.main, 0.6),
					transition: theme.transitions.create(["color"]),
					"&:hover": {
						color: alpha(theme.palette.secondary.main, 1),
					},
					"&:active": {
						color: alpha(theme.palette.secondary.main, 1),
					},
				},
				avatarMedium: {
					width: 20,
					height: 20,
				},
			},
			defaultProps: {
				deleteIcon: <CertiblokIcon size={20} color="inherit" name={"canc"} />,
			},
		},
		MuiLinearProgress: {
			styleOverrides: {
				root: {
					backgroundColor: alpha("#FFFFFF", 0.12),
					borderRadius: 2,
				},
				colorPrimary: {
					backgroundColor: alpha("#000000", 0.06),
				},
				barColorSecondary: {
					backgroundColor: theme.palette.blue.main,
					borderRadius: 2,
				},
			},
		},
		MuiSnackbarContent: {
			styleOverrides: {
				root: {
					// backgroundImage: `url(${frostedGradient})`,
					backgroundColor: "unset",
					backgroundSize: "cover",
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat",
					borderRadius: 16,
					boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.24)",
					boxSizing: "border-box",
				},
				message: {
					fontSize: pxToRem(16),
					lineHeight: "20px",
					letterSpacing: 0.5,
				},
			},
		},
		MuiLink: {
			defaultProps: {
				component: LinkBehavior,
			},
		},
		MuiButtonBase: {
			defaultProps: {
				LinkComponent: LinkBehavior,
			},
		},
		MuiSlider: {
			styleOverrides: {
				sizeMedium: {
					height: 4,
				},
				rail: {
					color: alpha("#000000", 0.12),
				},
				track: {
					border: "unset",
				},
				thumb: {
					width: 16,
					height: 16,
				},
			},
		},
		MuiAppBar: {
			styleOverrides: {
				root: {
					backgroundColor: theme.palette.background.default,
					boxShadow: "unset",
					"& .MuiToolbar-root": {
						boxSizing: "border-box",
						height: "100%",
						display: "flex",
						alignItems: "center",
					},
				},
			},
		},
		MuiAccordion: {
			styleOverrides: {
				root: {
					border: "unset",
					borderRadius: 14,
					boxShadow: "unset",
					"&:first-of-type": {
						borderRadius: 14,
					},
					"&:last-of-type": {
						borderRadius: 14,
					},
					"&::before": {
						display: "none",
					},
					"&.Mui-expanded": {
						margin: 0,
					},
				},
			},
		},
		MuiAccordionSummary: {
			styleOverrides: {
				root: {
					flexDirection: "row-reverse",
					"&.Mui-expanded": {
						minHeight: 48,
					},
					"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
						transform: "rotate(90deg)",
					},
					"& .MuiAccordionSummary-content": {
						marginLeft: theme.spacing(1),
					},
					"& .MuiAccordionSummary-content.Mui-expanded": {
						margin: "12px 0px",
						marginLeft: theme.spacing(1),
					},
				},
			},
		},
		MuiAccordionDetails: {
			styleOverrides: {
				root: {
					paddingBottom: 10,
					paddingLeft: 28,
					paddingRight: 28,
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					borderBottom: "unset",
				},
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					":not(.disable-row-hover)": {
						cursor: "pointer",
						transition: theme.transitions.create(["background-color"]),
					},
					":hover": {
						":not(.disable-row-hover)": {
							backgroundColor: theme.palette.backgroundSecondary.default,
						},
					},
					"td:first-of-type": {
						borderTopLeftRadius: 12,
						borderBottomLeftRadius: 12,
					},
					"td:last-of-type": {
						borderTopRightRadius: 12,
						borderBottomRightRadius: 12,
					},
				},
			},
		},
		MuiTablePagination: {
			styleOverrides: {
				menuItem: {
					transition: theme.transitions.create(["background-color"]),
					"&.Mui-selected": {
						backgroundColor: theme.palette.backgroundSecondary.default,
						"&:hover": {
							backgroundColor: alpha(theme.palette.secondary.main, 0.12),
						},
						"&.Mui-focusVisible": {
							backgroundColor: alpha(theme.palette.secondary.main, 0.12),
						},
					},
				},
			},
		},
		MuiInput: {
			styleOverrides: {
				underline: {
					borderRadius: 12,
					backgroundColor: "white",
					"&:focus": {
						borderRadius: 12,
					},
					"&::before": {
						border: "none !important",
					},
					"&::after": {
						border: "none !important",
					},
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				root: {},
				select: {
					borderRadius: 12,
					height: 32,
					boxSizing: "border-box",
					padding: "4px 8px",
					alignItems: "center",
					display: "flex",
					"&.MuiInputBase-input.MuiOutlinedInput-input": {
						paddingRight: 28,
					},
				},
				outlined: {},
				icon: {
					// width: 16,
					// height: 16,
					right: 0,
					color: "black",
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: 8,
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					"&.Mui-selected": {
						backgroundColor: alpha(theme.palette.secondary.main, 0.24),
						"&:hover": {
							backgroundColor: alpha(theme.palette.secondary.main, 0.24),
						},
						"&:focus": {
							backgroundColor: alpha(theme.palette.secondary.main, 0.24),
						},
					},
					"&:hover": {
						backgroundColor: alpha(theme.palette.secondary.main, 0.12),
					},
				},
			},
		},
		MuiTooltip: {
			defaultProps: {
				enterNextDelay: 500,
			},
		},
		MuiPopover: {
			styleOverrides: {
				paper: {
					borderRadius: 12,
					boxShadow: "0px 1px 18px rgba(0, 0, 0, 0.12)",
				},
			},
		},
	},
} as ThemeOptions);
