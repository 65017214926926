import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { ComponentProps, createContext, FC, ReactNode, useCallback, useContext, useRef } from "react";
import type { BottomSheetV2Ref } from "../bottomSheet/BottomSheet";
import type { ModalRoute } from "./types/ModalRoute";

export function useModalNavigatorController() {
	const currentModals = useRef<{ modal: string | FC; props?: any }[]>([]);

	const innerPushModal = useCallback((modal: string | FC, props: any, options?: { shouldHidePrev?: boolean }) => {
		if (currentModals.current.length > 0 && options?.shouldHidePrev) {
			NiceModal.hide(currentModals.current[currentModals.current.length - 1]?.modal);
		}
		currentModals.current.push({ modal: modal, props });
	}, []);

	const pushModalRoute = useCallback(
		<T extends ModalRoute, X extends T["result"]>(
			route: T,
			props?: Omit<ComponentProps<T["component"]>, "id">,
			options?: { shouldHidePrev?: boolean }
		) => {
			return new Promise<X>((resolve, reject) => {
				const allProps = { onSuccess: resolve, onCancel: reject, ...props };
				innerPushModal(route.id, allProps, options);
				NiceModal.show(route.id, allProps);
			});
		},
		[innerPushModal]
	);

	const pushModalById = useCallback(
		<PropsType extends object>(id: string, props?: PropsType, options?: { shouldHidePrev?: boolean }) => {
			return new Promise<any>((resolve, reject) => {
				const allProps = { onSuccess: resolve, onCancel: reject, ...props };
				innerPushModal(id, allProps, options);
				NiceModal.show(id, allProps);
			});
		},
		[innerPushModal]
	);

	const pushModal = useCallback(
		<T extends FC<any>>(component: T, props?: Omit<ComponentProps<T>, "id">, options?: { shouldHidePrev?: boolean }) => {
			return new Promise<any>((resolve, reject) => {
				const allProps = { onSuccess: resolve, onCancel: reject, ...props };
				innerPushModal(component, allProps, options);
				NiceModal.show(component, allProps);
			});
		},
		[innerPushModal]
	);

	const popModal = useCallback(() => {
		const poppedModal = currentModals.current.pop();
		if (poppedModal) {
			NiceModal.hide(poppedModal?.modal);
		}
		if (currentModals.current.length > 0) {
			NiceModal.show(
				//@ts-expect-error
				currentModals.current[currentModals.current.length - 1].modal,
				currentModals.current[currentModals.current.length - 1].props
			);
		}
	}, []);

	const popTo = useCallback((index: number) => {
		if (currentModals.current.length > 0) {
			NiceModal.hide(currentModals.current[currentModals.current.length - 1].modal);
		}
		let actualIndex = index >= 0 ? index : currentModals.current.length - 1 + index;

		if (actualIndex >= currentModals.current.length) {
			return;
		}
		if (actualIndex < 0) {
			currentModals.current = [];
			return;
		}
		const modalToGoTo = currentModals.current[actualIndex];
		currentModals.current = currentModals.current.slice(0, actualIndex + 1);
		if (modalToGoTo) {
			//@ts-ignore
			NiceModal.show(modalToGoTo.modal, modalToGoTo.props);
		}
	}, []);

	const dismissModal = useCallback(() => {
		currentModals.current.forEach((modal) => {
			NiceModal.hide(modal.modal);
		});
		currentModals.current = [];
	}, []);

	const openedBottomSheetRefs = useRef<BottomSheetV2Ref[]>([]);

	const pushBottomSheet = useCallback(
		<T extends FC<any>>({
			component,
			props,
			ref,
			options,
		}: {
			component: T;
			props?: Omit<ComponentProps<T>, "id">;
			ref: BottomSheetV2Ref | null;
			options?: { shouldHidePrev?: boolean };
		}) => {
			if (ref) openedBottomSheetRefs.current.push(ref);
			return pushModal(component, props, options);
		},
		[pushModal]
	);

	const popBottomSheet = useCallback(() => {
		popModal();
		const poppedModal = openedBottomSheetRefs.current.pop();
		if (poppedModal) {
			poppedModal.bringToFront();
		}
	}, [popModal]);

	const dismissBottomSheet = useCallback(() => {
		currentModals.current.forEach((modal) => {
			NiceModal.hide(modal.modal);
		});
		currentModals.current = [];
		openedBottomSheetRefs.current = [];
	}, []);

	return {
		dismissModal,
		pushModalRoute,
		popModal,
		popTo,
		pushModal,
		openedBottomSheetRefs,
		popBottomSheet,
		dismissBottomSheet,
		pushBottomSheet,
		pushModalById,
	};
}

type ModalNavigatorContextType = ReturnType<typeof useModalNavigatorController>;

const ModalNavigatorContext = createContext<ModalNavigatorContextType | undefined>(undefined);

export const ModalNavigatorProvider: FC<{
	children: ReactNode;
}> = ({ children }) => {
	const ModalNavigatorController = useModalNavigatorController();
	return (
		<ModalNavigatorContext.Provider value={ModalNavigatorController}>
			<NiceModal.Provider>{children}</NiceModal.Provider>
		</ModalNavigatorContext.Provider>
	);
};

export function useModalNavigator() {
	const context = useContext(ModalNavigatorContext);

	if (context === undefined) {
		throw new Error("Trying to use context outside of provider");
	}

	return context;
}

export function useModalRoute() {
	const { visible, id, keepMounted, remove, args, delayVisible } = useModal();

	return {
		visible,
		id,
		keepMounted,
		remove,
		args,
		delayVisible,
	};
}
export function useBottomSheet() {
	const { visible, id, keepMounted, remove, args, delayVisible } = useModal();
	const { openedBottomSheetRefs } = useModalNavigator();

	const ref = useRef<BottomSheetV2Ref>(null);

	const handleHeightChange = useCallback(
		(height: number) => {
			openedBottomSheetRefs.current.forEach((ref, index, arr) => {
				if (index === arr.length - 1) {
					ref.sendToBack({ frontElemHeight: height });
				} else {
					ref.hide();
				}
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return {
		visible,
		id,
		keepMounted,
		remove,
		args,
		delayVisible,
		bottomSheetProps: { ref, onHeightChange: handleHeightChange },
	};
}

export function useHandleHeightChange() {
	const { openedBottomSheetRefs } = useModalNavigator();

	return useCallback(
		(height: number) => {
			openedBottomSheetRefs.current.forEach((ref, index, arr) => {
				if (index === arr.length - 1) {
					ref.sendToBack({ frontElemHeight: height });
				} else {
					ref.hide();
				}
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);
}

export const ModalNavigator = {
	...NiceModal,
	registerRoute: <T extends ModalRoute>(route: T, initialProps?: Omit<ComponentProps<T["component"]>, "id">) => {
		//@ts-ignore
		NiceModal.register(route.id, route.component, initialProps);
	},
};
