import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { DocumentApi, EditRoomApi, EditRoomDocument, Tag, TagsApi } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import MLDialog from "../../../utils/components/poppers";
import { transformToStringFrom } from "../../../utils/components/tables/AdminTable";
import { useApi } from "../../../utils/hooks/api";

export const getTagsQueryKey = (searchFilter?: string) => {
	return ["tags", ...(searchFilter ? [searchFilter] : [])];
};

const PER_PAGE = 10;

export const useGetTags = ({ searchFilter }: { searchFilter?: string }) => {
	const tagsApi = useApi(TagsApi);
	return useInfiniteQuery({
		queryKey: getTagsQueryKey(searchFilter),
		queryFn: ({ pageParam = 0 }) => {
			return tagsApi.getAllTags(
				transformToStringFrom({ page: pageParam, perPage: PER_PAGE, filter: [{ name: searchFilter }] })
			);
		},
		getNextPageParam: (lastPage, allPages) => {
			const nextPage = allPages.length;
			const totalCount = parseInt(lastPage.headers["x-total-count"] ?? "0");
			const pagesCount = Math.floor(totalCount / PER_PAGE);
			if (nextPage > pagesCount) {
				return undefined;
			}
			return nextPage;
		},
		keepPreviousData: true,
	});
};

export const useEditDocumentTags = (options?: { onSuccess?: () => void; onSettled?: () => void }) => {
	const { t } = useTranslation();
	const documentApi = useApi(DocumentApi);

	return useMutation({
		mutationFn: ({ documentId, tagIds }: { documentId: string; tagIds: string[] }) => {
			return documentApi.editDocument(documentId, { tagIds: tagIds });
		},
		onError: () => MLDialog.showSnackbar(t("tags.genericErrorMessage"), { variant: "error" }),
		onSettled: () => {
			options?.onSettled?.();
		},
		onSuccess: () => {
			options?.onSuccess?.();
		},
	});
};
export const useEditDocumentTagsInEditRoom = (options?: { onSuccess?: () => void; onSettled?: () => void }) => {
	const { t } = useTranslation();
	const editRoomApi = useApi(EditRoomApi);

	return useMutation({
		mutationFn: ({ tagIds, editRoomDocument }: { tagIds: string[]; editRoomDocument: EditRoomDocument }) => {
			return editRoomApi.updateEditRoomDocument(editRoomDocument.id, {
				expireDate: editRoomDocument.expireDate ?? undefined,
				name: editRoomDocument.name,
				noExpiration: editRoomDocument.noExpiration,
				parentFolderId: editRoomDocument.parentFolderId,
				tagIds: tagIds,
			});
		},
		onError: () => MLDialog.showSnackbar(t("tags.genericErrorMessage"), { variant: "error" }),
		onSettled: () => {
			options?.onSettled?.();
		},
		onSuccess: () => {
			options?.onSuccess?.();
		},
	});
};

export const useCreateNewTag = (options?: { onSuccess?: (tag: Tag) => void; onSettled?: (tag?: Tag) => void }) => {
	const { t } = useTranslation();
	const tagsApi = useApi(TagsApi);

	return useMutation({
		mutationFn: ({ name, color }: { name: string; color: string }) => {
			return tagsApi.createTag({ name, color });
		},
		onSettled: (res) => {
			options?.onSettled?.(res?.data);
		},
		onSuccess: (res) => {
			options?.onSuccess?.(res.data);
		},
		onError: () => MLDialog.showSnackbar(t("tags.genericErrorMessage"), { variant: "error" }),
	});
};

export const useEditTag = (options?: { onSuccess?: (tag: Tag) => void; onSettled?: (tag?: Tag) => void }) => {
	const { t } = useTranslation();
	const tagsApi = useApi(TagsApi);
	return useMutation({
		mutationFn: ({ id, name, color }: { id: string; name: string; color: string }) => {
			return tagsApi.editTag(id, { name, color });
		},
		onSettled: (res) => {
			options?.onSettled?.(res?.data);
		},
		onSuccess: (res) => {
			options?.onSuccess?.(res.data);
		},
		onError: () => MLDialog.showSnackbar(t("tags.genericErrorMessage"), { variant: "error" }),
	});
};

export const useDeleteTag = (options?: { onSuccess?: (tag: Tag) => void; onSettled?: (tag?: Tag) => void }) => {
	const { t } = useTranslation();
	const tagsApi = useApi(TagsApi);
	return useMutation({
		mutationFn: ({ id }: { id: string }) => {
			return tagsApi.deleteTag(id);
		},
		onSettled: (res) => {
			options?.onSettled?.(res?.data);
		},
		onSuccess: (res) => {
			options?.onSuccess?.(res.data);
		},
		onError: () => MLDialog.showSnackbar(t("tags.genericErrorMessage"), { variant: "error" }),
	});
};

export const useDeleteMultipleTag = (options?: { onSuccess?: () => void; onSettled?: () => void }) => {
	const { t } = useTranslation();
	const tagsApi = useApi(TagsApi);
	return useMutation({
		mutationFn: ({ ids }: { ids: string[] }) => {
			return Promise.all(ids.map((id) => tagsApi.deleteTag(id)));
		},
		onSettled: (res) => {
			options?.onSettled?.();
		},
		onSuccess: (res) => {
			options?.onSuccess?.();
		},
		onError: () => MLDialog.showSnackbar(t("tags.genericErrorMessage"), { variant: "error" }),
	});
};
