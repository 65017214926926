import { Button, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMe } from "../../../../hooks/useMe";
import { ModalNavigator, useModalNavigator, useModalRoute } from "../../../ModalNavigator/ModalNavigator";
import ResponsiveDialog from "../../../dialogs/ResponsiveDialog";
import CertiblokIcon from "../../../icons/CertiblokIcon";

import { EventParticipationOptions } from "./components/EventParticipationOptions";
import EventTimingDisplay from "./components/EventTimingDisplay";
import EventTypeChip from "./components/EventTypeChip";
import { ParticipantsSection } from "./components/ParticipantsSection";

import { useEventInstanceById } from "../../../../../pages/Calendar/pages/EditCreateEvent/hooks/useEventInstanceById";
import { GeneralEventDeletion } from "./components/DeleteEvent/GeneralEventDeletion";
import { RecurrentEventDeletionSelection } from "./components/DeleteEvent/RecurrentEventDeletionSelection";
import { parseReminderOccurrenceString } from "../../functions/parseReminderOccurrenceString";

type EventDetailsModalProps = {
	eventId: string;
	shouldEditParticipation?: boolean;
	isParticipating?: boolean;
	onDelete?: () => void;
	onSuccess?: () => void;
};

export const EventDetailsModal = ModalNavigator.create<EventDetailsModalProps>(
	({ eventId, onDelete: _onDelete, onSuccess, isParticipating, shouldEditParticipation }) => {
		const { t } = useTranslation();
		const { visible } = useModalRoute();
		const { popModal, pushModal } = useModalNavigator();

		const { me } = useMe();

		const onClose = () => {
			popModal();
			onSuccess?.();
		};

		const onDelete = () => {
			_onDelete?.();
			onSuccess?.();
		};

		const { data: event, isLoading } = useEventInstanceById(eventId);

		const myAttendance = event?.attendees?.find((att) => att.userId === me?.id);

		const reminderOccurrence = parseReminderOccurrenceString(t, myAttendance?.reminder?.overrides?.[0]?.minutes);

		const isMyEvent = me?.id === event?.ownerId;
		const ownParticipation = event?.attendees?.find((participant) => participant.userId === me?.id)?.responseStatus;
		const isRecurrent = (event?.event?.recurrence?.length ?? 0) !== 0;

		return (
			<ResponsiveDialog
				open={visible}
				onClose={onClose}
				ModalProps={{
					responsive: true,
				}}
				header={
					<div className="flex w-full items-center justify-between">
						{isLoading ? (
							<div className="bg-gray-200 animate-pulse rounded-md min-w-[100px] h-6" />
						) : (
							<Typography variant="subtitle1">{event?.event?.name}</Typography>
						)}
						<EventTypeChip eventType="event" />
					</div>
				}
				footer={
					<>
						<Divider orientation="horizontal" className="md:pt-6" />
						<div className="flex justify-between items-center mt-6">
							<Button
								variant="outlined"
								color="secondary"
								startIcon={<CertiblokIcon name="close" color="secondary" />}
								onClick={onClose}
							>
								{t("global.close")}
							</Button>

							<div className="flex justify-between items-center gap-2">
								<Button
									variant="text"
									color="error"
									onClick={() => {
										const hasOtherParticipants = (event?.attendees?.length ?? 0) > 1;
										if (isRecurrent) {
											pushModal(
												RecurrentEventDeletionSelection,
												{
													isMyEvent,
													noOtherParticipant: !hasOtherParticipants,
													eventInstanceId: event?.id,
													onDelete,
												},
												{ shouldHidePrev: true }
											);
										} else {
											pushModal(
												GeneralEventDeletion,
												{
													deleteMode: "this",
													isMyEvent,
													eventInstanceId: event?.id,
													noOtherParticipant: !hasOtherParticipants,
													onDelete,
												},
												{ shouldHidePrev: true }
											);
										}
									}}
								>
									{t("global.delete")}
								</Button>
								<Button
									onClick={() => {
										popModal();
									}}
									variant="contained"
									color="secondary"
									href={`/calendar/event/${eventId}`}
									startIcon={<CertiblokIcon name="edit_outline_01" color="white" />}
								>
									{t("events.editEvent")}
								</Button>
							</div>
						</div>
					</>
				}
			>
				<div className="px-6 md:px-0 box-border md:max-h-[60vh] md:overflow-auto">
					{isLoading ? (
						<div className="flex flex-col gap-2 mt-4">
							<div className="bg-gray-200 animate-pulse rounded-md min-w-full h-5" />
							<div className="bg-gray-200 animate-pulse rounded-md w-[70%] h-5" />
						</div>
					) : (
						event?.event?.annotation && (
							<Typography variant="body2" className="mt-4">
								{event?.event?.annotation}
							</Typography>
						)
					)}
					<EventTimingDisplay
						event={event}
						isLoading={isLoading}
						gridClassName="md:mt-8 mt-2 gap-y-6"
						gridChildren={
							<>
								{(myAttendance?.reminder?.useDefault ||
									(myAttendance?.reminder?.overrides && myAttendance?.reminder?.overrides?.length > 0)) && (
									<>
										<CertiblokIcon name="bell_outline" className="text-black" />
										<Typography className="mt-[2px]">{reminderOccurrence}</Typography>
									</>
								)}
								<CertiblokIcon name="profile_outline_01" className="text-black" />
								<ParticipantsSection participants={event?.attendees ?? []} isLoading={isLoading} />
							</>
						}
					/>

					<Divider orientation="horizontal" />
					<EventParticipationOptions
						isOwner={isMyEvent}
						isLoading={isLoading}
						eventId={eventId}
						eventLink={`https://meet.jit.si/certiblok/${eventId}`}
						ownParticipation={ownParticipation ?? "needsAction"}
						isParticipating={isParticipating}
						shouldEditParticipation={shouldEditParticipation}
					/>
				</div>
			</ResponsiveDialog>
		);
	}
);
