import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import TagManager from "react-gtm-module";
import * as Sentry from "@sentry/react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import { CaptureConsole, HttpClient } from "@sentry/integrations";

const tagManagerArgs = {
	gtmId: "GTM-T2PB7ZB",
};

if (process.env.REACT_APP_ENV === "production") {
	TagManager.initialize(tagManagerArgs);
}

Sentry.init({
	dsn: "https://c94dc2a7422f9f3004cb6bb4f1efdca4@o4506196648984576.ingest.sentry.io/4506376265859072",
	integrations: [
		new Sentry.BrowserTracing({
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			// tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
			routingInstrumentation: Sentry.reactRouterV6Instrumentation(
				React.useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes
			),
		}),
		new CaptureConsole({
			levels: ["error"],
		}),
		new Sentry.Replay(),
		new HttpClient(),
	],
	sendDefaultPii: true,
	enabled: process.env.NODE_ENV === "production",
	environment: process.env.REACT_APP_ENV === "production" ? "production" : "staging",
	// Performance Monitoring
	tracesSampleRate: 0.01, //  Capture 100% of the transactions
	// Session Replay
	replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
