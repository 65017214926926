/** @jsxImportSource @emotion/react */
import {
	alpha,
	Box,
	Button,
	Collapse,
	FormControl,
	Grid,
	InputLabel,
	ModalProps,
	Skeleton,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { DocumentApi, EditRoomApi, SharingGroup, UserOrContact } from "certiblok-api-manager";
import { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";
import { getFullName } from "../..";
import GroupContactUserAutocomplete, {
	isSharingGroup,
} from "../../../pages/Contacts/components/EditCreateContactGroup/GroupContactUserAutocomplete";
import { useShareWithContactContext } from "../../../pages/Folders/components/UploadDocument/controllers/ShareWithContactsContext";
import { isUser, isUserOrContact } from "../../../pages/Folders/components/UploadDocument/functions/isUser";
import DocumentVersionSelect from "../../../pages/SingleDocument/components/DocumentVersionSelect";
import { apiErrorParser, useApi } from "../../hooks/api";
import type { CertiblokDocument } from "../../interfaces/Document";
import LoadingIconButton from "../buttons/LoadingIconButton";
import CertiblokTextInput from "../CertiblokTextInput";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";
import DocumentWithIcon from "../DocumentWithIcon";
import GroupChip from "../GroupChip";
import GroupWithMembers from "../GroupWithMembers";
import CertiblokIcon from "../icons/CertiblokIcon";
import LanguageSelector, { SupportedLanguagesType } from "../LanguageSelector";
import NoContents from "../NoContents";
import MLDialog from "../poppers";
import UserChip from "../UserChip";
import UserWithMail from "../UserWithMail";

const computeLatestVersion = (document: CertiblokDocument) => {
	let latestVersion = document.lastVersionFile?.[0];
	document.lastVersionFile?.forEach((version) => {
		if (version.version > latestVersion?.version) {
			latestVersion = version;
		}
	});
	return latestVersion;
};

type ShareDocumentProps = Omit<ModalProps, "children"> & {
	document: CertiblokDocument | CertiblokDocument[];
	revalidateDocument: () => void;
	toggleOpen: () => void;
	selectedVersion?: { versionNumber: number; fileId: string };
};

const ShareDocument: React.FC<ShareDocumentProps> = ({
	document,
	revalidateDocument,
	toggleOpen,
	open,
	selectedVersion: _selectedVersion,
}) => {
	const { t, i18n } = useTranslation();
	//* APIS
	const documentApi = useApi(DocumentApi);
	const editRoomApi = useApi(EditRoomApi);

	const isSingleDocument = !Array.isArray(document) || document.length === 1;
	const singleDocument = isSingleDocument ? (Array.isArray(document) ? document[0] : document) : undefined;

	const [selectedVersion, setSelectedVersion] = useState<{ versionNumber: number; fileId: string } | undefined>(
		_selectedVersion
	);

	const [selectedLanguage, setSelectedLanguage] = useState<SupportedLanguagesType>(
		i18n.language.startsWith("it") ? "it" : "en"
	);

	const [noteToShare, setNoteToShare] = useState("");

	const location = useLocation();
	const isEditRoom = location?.pathname?.includes("/edit_rooms/");

	const {
		data: singleDocumentDetails,
		isLoading: isLoadingSingleDocumentDetails,
		refetch: refetchSingleDocumentDetails,
	} = useQuery({
		queryKey: ["document", singleDocument?.id, selectedVersion?.fileId],
		queryFn: () => {
			if (isEditRoom) {
				return editRoomApi.getSingleEditRoomDocument(singleDocument?.id ?? "");
			} else {
				return documentApi.getSingleDocument(singleDocument?.id ?? "", selectedVersion?.fileId);
			}
		},
		select: (res) => res.data,
		onError: (err) => {
			MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
		},
		keepPreviousData: true,
		enabled: !!singleDocument?.id,
	});

	const hasAlreadySetLastVersion = useRef(false);

	useEffect(() => {
		const lastDocumentVersion = singleDocumentDetails?.lastVersionFile?.[0];
		if (lastDocumentVersion && !hasAlreadySetLastVersion.current) {
			setSelectedVersion({
				versionNumber: lastDocumentVersion?.version,
				fileId: lastDocumentVersion?.id,
			});
			hasAlreadySetLastVersion.current = true;
		}
	}, [singleDocumentDetails]);

	useEffect(() => {
		if (open && _selectedVersion) {
			setSelectedVersion(_selectedVersion);
			hasAlreadySetLastVersion.current = true;
		}
	}, [_selectedVersion, open]);

	useEffect(() => {
		refetchSingleDocumentDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	//* STATE
	const shareWithContact = useShareWithContactContext();

	const timeoutRef = useRef<NodeJS.Timeout>();

	const shareDocumentMutation = useMutation({
		mutationFn: () => {
			if (isSingleDocument) {
				const documentToShare = Array.isArray(document) ? document[0] : document;
				return documentApi.shareDocumentsWithUsers({
					documents: [
						{
							documentId: documentToShare.id,
							//@ts-expect-error
							versionFileId: selectedVersion?.fileId ?? computeLatestVersion(documentToShare)._id,
						},
					],
					shareUserIdsOrEmails: [
						...(shareWithContact.selectedContacts || []).map((user) => user.id),
						...(shareWithContact.selectedEmails || []),
					],
					sharingGroupIds: shareWithContact.selectedGroups.map((group) => group.id),
					note: noteToShare,
					language: selectedLanguage,
				});
			} else {
				return documentApi.shareDocumentsWithUsers({
					documents: document.map((doc) => ({
						documentId: doc.id,
						//@ts-expect-error
						versionFileId: computeLatestVersion(doc)._id,
					})),
					shareUserIdsOrEmails: [
						...(shareWithContact.selectedContacts || []).map((user) => user.id),
						...(shareWithContact.selectedEmails || []),
					],
					sharingGroupIds: shareWithContact.selectedGroups.map((group) => group.id),
					note: noteToShare,
					language: selectedLanguage,
				});
			}
		},
		onSuccess: (res) => {
			res.data.failed.forEach((fail) => {
				if (fail.errorCode === "AlreadySharedWithGroup") {
					const failedGroup = shareWithContact.selectedGroups.find((group) => group.id === fail.sharingGroupId);
					const failedDocument = Array.isArray(document) ? document.find((doc) => doc.id === fail.documentId) : document;
					MLDialog.showSnackbar(
						isSingleDocument
							? t("variousComponents.fileAlreadyShared", {
									name: failedGroup?.name ?? "",
							  })
							: t("variousComponents.fileNameAlreadyShared", {
									documentName: failedDocument?.name ?? "",
									name: failedGroup?.name ?? "",
							  }),
						{
							variant: "error",
						}
					);
				} else if (fail.errorCode === "AlreadySharedWithUser") {
					const failedUser = [...(shareWithContact.selectedContacts || []), ...(shareWithContact.selectedEmails || [])].find(
						(user) => {
							if (user === undefined) return false;
							if (typeof user === "string") {
								return user === fail.shareUserIdOrEmail;
							} else {
								return user.id === fail.shareUserIdOrEmail;
							}
						}
					);
					const failedDocument = !Array.isArray(document) ? document : document.find((doc) => doc.id === fail.documentId);
					MLDialog.showSnackbar(
						isSingleDocument
							? t("variousComponents.fileAlreadyShared", {
									name: isUserOrContact(failedUser) ? getFullName(failedUser) : failedUser,
							  })
							: t("variousComponents.fileNameAlreadyShared", {
									documentName: failedDocument?.name ?? "",
									name: isUserOrContact(failedUser) ? getFullName(failedUser) : failedUser,
							  }),
						{
							variant: "error",
						}
					);
				} else {
					MLDialog.showSnackbar(fail.message ?? "", { variant: "error" });
				}
			});
			setSelectedLanguage("it");
			revalidateDocument?.();
			setSelectedVersion(undefined);
			setNoteToShare("");
			refetchSingleDocumentDetails?.();
			toggleOpen?.();
			hasAlreadySetLastVersion.current = false;
			timeoutRef.current = setTimeout(() => {
				shareWithContact.resetSelectedUsers();
			}, 300);
		},
		onError: (err) => {
			MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
		},
	});

	useEffect(() => {
		return () => {
			clearTimeout(timeoutRef.current);
		};
	}, []);

	const interrupActiveSharing = (elem: UserOrContact | SharingGroup) => {
		MLDialog.showModal(
			t("global.attention"),
			t("documents.removeSharing", { contactName: isSharingGroup(elem) ? elem.name : getFullName(elem) ?? elem }),
			{
				onPositiveClick: () =>
					isSharingGroup(elem)
						? documentApi
								.unshareDocumentsWithSharingGroup(singleDocumentDetails!.id, { sharingGroupId: elem.id })
								.then(() => {
									refetchSingleDocumentDetails();
									revalidateDocument();
								})
						: documentApi
								.unshareDocumentWithUser(Array.isArray(document) ? document[0].id : document!.id, {
									shareUserId: elem.id,
								})
								.then(() => {
									refetchSingleDocumentDetails();
									revalidateDocument();
								}),
				positiveButton: (onClick, loading) => (
					<LoadingIconButton
						loading={loading}
						icon={<CertiblokIcon size={24} color="inherit" name={"delete_bold"} />}
						variant="contained"
						size="large"
						color="error"
						onClick={onClick}
					>
						{t("global.remove")}
					</LoadingIconButton>
				),
				negativeButton: (
					<Button variant="text" size="large" color="secondary" onClick={() => MLDialog.hideModal()}>
						<CertiblokIcon size={24} color="inherit" name={"close"} />
						{t("global.cancel")}
					</Button>
				),
				icon: (
					<CertiblokIcon
						size={36}
						color="warning"
						name={"comunication_outline_01"}
						iconCss={{ padding: 12, backgroundColor: alpha(theme.palette.warning.main, 0.12), borderRadius: 36 }}
					/>
				),
				contentCentered: true,
				maxWidth: 344,
			}
		);
	};

	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up("md"));

	const onClose = () => {
		if (shareDocumentMutation.isLoading) return;
		toggleOpen?.();
		setSelectedLanguage("it");
		setSelectedVersion(undefined);
		setNoteToShare("");
		hasAlreadySetLastVersion.current = false;
		shareWithContact.resetSelectedUsers();
	};

	const sharings = [
		...((singleDocumentDetails as CertiblokDocument)?.sharedWithGroups ?? []),
		...(singleDocumentDetails?.sharedWith ?? []),
	];

	return (
		<ResponsiveDialog
			open={open}
			onClose={onClose}
			ModalProps={{
				responsive: true,
			}}
			header={<Typography children={t("shareWithContact.shareDocument")} variant="subtitle1" />}
			footer={
				<Box sx={{ paddingTop: 7, width: "100%", display: "flex", justifyContent: "space-between" }}>
					<Button variant="outlined" color="secondary" onClick={onClose}>
						<CertiblokIcon size={24} color="inherit" name={"close"} />
						{t("global.cancel")}
					</Button>
					<LoadingIconButton
						loading={shareDocumentMutation.isLoading}
						icon={<CertiblokIcon size={24} color="inherit" name={"success"} />}
						variant="contained"
						color="secondary"
						onClick={() => shareDocumentMutation.mutate()}
					>
						{isMd ? t("shareWithContact.shareDocument") : t("shareWithContact.shareCompact")}
					</LoadingIconButton>
				</Box>
			}
		>
			<div className="px-5 md:px-0">
				<Grid container spacing={2} sx={{ width: "100%", py: 6, pt: { xs: 0, md: 6 } }}>
					{(isSingleDocument && !Array.isArray(document) ? [document] : document).map((elem) => (
						<Grid item xs key={elem.id} sx={{ maxWidth: "50% !important" }}>
							<DocumentWithIcon name={elem.name} document={elem.lastVersionFile?.[0]} sx={{ width: "fit-content" }} />
						</Grid>
					))}
				</Grid>

				{isSingleDocument && (
					<FormControl fullWidth className="mb-6">
						<InputLabel
							className="transform-none relative mb-2 text-black-a54"
							sx={{ typography: "label" }}
							id="document-version-select-label"
						>
							{t("documents.chooseVersionToShare")}
						</InputLabel>
						{isLoadingSingleDocumentDetails || selectedVersion === undefined ? (
							<Skeleton variant="rectangular" width={60} height={32} className="rounded-lg" />
						) : (
							<DocumentVersionSelect
								labelId="document-version-select-label"
								id="document-version-select"
								selectedVersion={selectedVersion}
								onSelectedVersionChange={setSelectedVersion}
								document={singleDocumentDetails}
								className="w-fit"
							/>
						)}
					</FormControl>
				)}

				<CertiblokTextInput
					label={t("documents.insertNote")}
					color="secondary"
					fullWidth
					multiline
					rows={2}
					value={noteToShare}
					onChange={(e) => setNoteToShare(e.target.value)}
					sx={{ marginBottom: 3 }}
					placeholder={t("documents.insertNote")}
				/>

				<div className="pb-3 flex items-center justify-between w-full">
					<Typography variant="label">{t("request.selectLanguage")}</Typography>
					<LanguageSelector language={selectedLanguage} onLanguageChange={setSelectedLanguage} />
				</div>

				<GroupContactUserAutocomplete
					onSelectUser={shareWithContact.selectContact}
					onSelectGroup={shareWithContact.selectGroup}
					onSelectEmail={shareWithContact.selectEmail}
					includeGroups
					includeEmails
					disabledSharingGroupsIds={shareWithContact.selectedGroups.map((group) => group.id)}
					disabledUsersOrContactsIds={shareWithContact.selectedContacts.map((user) => user.id)}
					disabledEmails={shareWithContact.selectedEmails}
					TextFieldProps={{
						InputProps: {
							placeholder: t("shareWithContact.selectShare"),
						},
					}}
				/>
				<Grid container spacing={1} sx={{ paddingTop: 2, maxHeight: "80px", overflowY: "auto" }}>
					{shareWithContact.selectedEmails.map((elem, index) => {
						return (
							<Grid item key={`invite_${index}`} xs={6} md={4} lg={3}>
								<UserChip user={elem} deletable onDelete={shareWithContact.unselectEmail} />
							</Grid>
						);
					})}
					{shareWithContact.selectedGroups.map((elem, index) => {
						return (
							<Grid item key={elem.id} xs={6} md={4} lg={3}>
								<GroupChip
									group={elem}
									onDelete={(elem) => {
										console.log(elem);
										shareWithContact.unselectGroup(elem.id);
									}}
								/>
							</Grid>
						);
					})}
					{shareWithContact.selectedContacts.map((elem, index) => {
						return (
							<Grid item key={elem.id} xs={6} md={4} lg={3}>
								<UserChip user={elem} deletable onDelete={shareWithContact.unselectContact} />
							</Grid>
						);
					})}
				</Grid>

				{singleDocumentDetails && (
					<Fragment>
						<Typography
							component="p"
							children={t("shareWithContact.alreadySharedWith")}
							variant="label"
							sx={{ paddingTop: 6 }}
						/>
						<TransitionGroup
							css={{
								paddingTop: 8,
								maxHeight: "150px",
								overflowY: "scroll",
								boxSizing: "border-box",
							}}
						>
							{sharings.length > 0 ? (
								sharings.map((elem, index) => {
									return (
										<Collapse key={isSharingGroup(elem) || isUser(elem) ? elem.id : `invite_${index}`} sx={{ marginBottom: 1 }}>
											{isSharingGroup(elem) ? (
												<GroupWithMembers
													group={elem}
													deletable
													onDelete={() => {
														interrupActiveSharing(elem);
													}}
												/>
											) : (
												<UserWithMail
													user={elem}
													deletable
													onDelete={() => {
														interrupActiveSharing(elem);
													}}
													sx={{ gap: 3 }}
												/>
											)}
										</Collapse>
									);
								})
							) : (
								<Collapse key="request_no_contents">
									<NoContents variant="paper" title={t("variousComponents.noActiveShares")} />
								</Collapse>
							)}
						</TransitionGroup>
					</Fragment>
				)}
			</div>
		</ResponsiveDialog>
	);
};

export default ShareDocument;
