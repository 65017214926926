import { Backdrop, Box, Fade, Portal } from "@mui/material";
import { useState } from "react";
import MuiArrowPopper, { PopperArrow } from "./MuiArrowPopper";

type Props = {
	open: boolean;
	anchorEl: HTMLElement | null;
	onClose: () => void;
};

const RowMenu: React.FC<Props> = ({ open, anchorEl, onClose, children }) => {
	const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);

	return (
		<>
			<MuiArrowPopper
				open={open}
				arrow={true}
				anchorEl={anchorEl}
				placement="bottom"
				transition
				modifiers={[
					{
						name: "offset",
						enabled: true,
						options: {
							offset: [-50, 5],
						},
					},
					{
						name: "arrow",
						enabled: true,
						options: {
							element: arrowRef,
						},
					},
				]}
				sx={{
					zIndex: 1302,
				}}
			>
				{({ TransitionProps }) => (
					<Fade {...TransitionProps} timeout={350}>
						<Box sx={{ filter: "drop-shadow(0px 1px 18px rgba(0, 0, 0, 0.12));" }}>
							<PopperArrow
								ref={(node) => {
									setArrowRef(node);
								}}
								className="MuiPopper-arrow"
							/>
							{children}
						</Box>
					</Fade>
				)}
			</MuiArrowPopper>
			<Portal>
				<Backdrop open={open} onClick={() => onClose()} sx={{ zIndex: 1301, backgroundColor: "transparent" }} />
			</Portal>
		</>
	);
};

export default RowMenu;
