import { DateTime } from "luxon";

const EXPIRE_DAYS = 30;

export function validityPercentage(finish: DateTime): number {
	const finishDate = finish.startOf("day");
	const now = DateTime.now().startOf("day");
	const diffDays = finishDate.diff(now, "days");
	if (diffDays.days > EXPIRE_DAYS) {
		return 100;
	}
	if (finishDate < now || finishDate.equals(now)) {
		return 0;
	}
	return (diffDays.days / EXPIRE_DAYS) * 100;
}

export function validityColor(percentage: number): string {
	if (percentage >= 100) {
		return "#52BD8F";
	}
	const green = (255 * percentage) / 100;
	return `rgba(255, ${green}, 0, 1)`;
}
