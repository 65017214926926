import { Box, Typography } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import type { AuditStatuses } from "../../interfaces/AuditRoom";
import type { CertiblokDocumentReview } from "../../interfaces/Document";
import SimpleTooltip from "../tooltips/SimpleTooltip";
import { AuditRoomIconStatus } from "./AuditRoomIconStatus";
import { switchTitle } from "./functions/switchTitle";

type Props =
	| {
			reviews: CertiblokDocumentReview[];
			onlyStatus?: false;
	  }
	| {
			onlyStatus: true;
			reviews: (AuditStatuses | undefined)[];
	  };

function isCertiblokDocumentReview(review: any): review is CertiblokDocumentReview {
	return review && (review as CertiblokDocumentReview).id !== undefined;
}

const AuditRoomStatus: React.FC<Props> = ({ reviews, onlyStatus = false }) => {
	const { t } = useTranslation();
	if (reviews.length <= 0) return null;
	return (
		<SimpleTooltip
			enterDelay={500}
			arrow
			title={
				<Box sx={{ boxSizing: "border-box", padding: 3, display: "flex", flexDirection: "column", gap: 3 }}>
					{reviews.map((elem, index) =>
						isCertiblokDocumentReview(elem) ? (
							<Box key={elem.id} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
								{elem.status && <AuditRoomIconStatus status={elem.status} />}
								<Box>
									{elem.status && <Typography variant="body2">{switchTitle(t, elem.status)}</Typography>}
									<Typography variant="caption">{elem.audit?.name}</Typography>
								</Box>
							</Box>
						) : (
							<Box key={`audit_status_key_tooltip_${elem}_${index}`} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
								<AuditRoomIconStatus status={elem} />
								<Box>
									<Typography variant="body2">{switchTitle(t, elem)}</Typography>
								</Box>
							</Box>
						)
					)}
				</Box>
			}
		>
			<Box sx={{ width: 28, height: "100%", position: "relative" }}>
				{reviews.length > 1 && (
					<AuditRoomIconStatus
						status={isCertiblokDocumentReview(reviews[1]) ? reviews[1].status : reviews[1]}
						hasBorder
						iconCss={{
							position: "absolute",
							top: -5,
							left: 5,
						}}
					/>
				)}
				<Fragment>
					<AuditRoomIconStatus
						status={isCertiblokDocumentReview(reviews[0]) ? reviews[0].status : reviews[0]}
						iconCss={{
							position: "absolute",
							top: 0,
							left: 0,
							// boxShadow: audits.length > 1 ? "0px 0.5px 10px rgba(0, 0, 0, 0.06)" : "unset",
						}}
					/>
					<AuditRoomIconStatus
						status={isCertiblokDocumentReview(reviews[0]) ? reviews[0].status : reviews[0]}
						iconCss={{ visibility: "hidden" }}
					/>
				</Fragment>
			</Box>
		</SimpleTooltip>
	);
};

export default AuditRoomStatus;
