import { Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useBetterMediaQuery } from "../../../../hooks/useBetterMediaQuery";
import ArrowPopover from "../../../ArrowPopover";
import OldBottomSheet from "../../../bottomSheet/OldBottomSheet";
import CertiblokIcon from "../../../icons/CertiblokIcon";
import Loading from "../../../Loading";
import LoadingTextButton from "../../../LoadingTextButton";
import NoContents from "../../../NoContents";
import NotificationList from "./components/NotificationList";
import { NOTIFICATIONS_PER_PAGE } from "./constants/NotificationsPerPage";
import { NotificationsContextProvider, useNotifications } from "./controllers/NotificationContext";

type Props = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	readAllNotification: () => void;
	readOneNotification: (notificationId: string) => void;
	anchorEl: HTMLElement | null;
};

const NotificationPopper: React.FC<Props> = ({ open, setOpen, anchorEl, readAllNotification, readOneNotification }) => {
	const { t } = useTranslation();
	const notificationsContext = useNotifications(() => setOpen(false));

	useEffect(() => {
		let resetTimeout: NodeJS.Timeout | undefined;
		if (open) {
			notificationsContext.fetchNotifications(0, NOTIFICATIONS_PER_PAGE);
		} else {
			resetTimeout = setTimeout(notificationsContext.resetNotifications, 300);
		}

		return () => {
			if (resetTimeout) clearTimeout(resetTimeout);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	const isSm = useBetterMediaQuery("sm");

	const content = (
		<Box
			sx={{
				width: { xs: "100%", sm: 420 },
				height: { xs: "100%", sm: "auto" },
				boxSizing: "border-box",
				backgroundColor: "surface.main",
				display: "flex",
				flexDirection: "column",
				padding: { xs: 0, sm: 3 },
			}}
		>
			<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
				{isSm ? (
					<Typography variant="subtitle1" sx={{ fontWeight: 700, color: "black" }}>
						{t("variousComponents.notifications")}
					</Typography>
				) : (
					<Button size="small" onClick={() => setOpen(false)} sx={{ color: "black", typography: "caption" }}>
						<CertiblokIcon name="close" color="inherit" />
						{t("global.close")}
					</Button>
				)}
				<LoadingTextButton
					onClick={async () => {
						await readAllNotification();
						notificationsContext.fetchNotifications(0, NOTIFICATIONS_PER_PAGE);
					}}
					size="small"
					color="primary"
				>
					{t("variousComponents.markAllAsRead")}
				</LoadingTextButton>
			</Box>
			{notificationsContext.loading && notificationsContext.notifications.length === 0 ? (
				<Loading title={t("variousComponents.loadingNotifications")} color="primary" sx={{ py: 3, height: "100%" }} />
			) : notificationsContext.notifications.length > 0 ? (
				<NotificationList setOpen={setOpen} readOneNotification={readOneNotification} />
			) : (
				<NoContents title={t("variousComponents.noNotifications")} variant="paper" />
			)}
		</Box>
	);

	return (
		<NotificationsContextProvider notificationsContext={notificationsContext}>
			{isSm ? (
				<ArrowPopover
					open={open}
					onClose={() => {
						setOpen(false);
					}}
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: "center",
						horizontal: "right",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
					css={{ marginTop: 40 }}
				>
					{content}
				</ArrowPopover>
			) : (
				<OldBottomSheet
					open={open}
					onClose={() => {
						setOpen(false);
					}}
					containerSx={{ paddingBottom: "0px !important", height: "85vh" }}
				>
					{content}
				</OldBottomSheet>
			)}
		</NotificationsContextProvider>
	);
};

export default NotificationPopper;
