"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.7.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditRoomApi = exports.EditRoomApiFactory = exports.EditRoomApiFp = exports.EditRoomApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * EditRoomApi - axios parameter creator
 * @export
 */
const EditRoomApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Cancel edit room document update
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelEditRoomDocumentUpdate: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('cancelEditRoomDocumentUpdate', 'id', id);
            const localVarPath = `/edit-room/document/{id}/update/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Create edit room
         * @param {EditRoomFolderInput} [editRoomFolderInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEditRoom: (editRoomFolderInput, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/edit-room/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(editRoomFolderInput, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Create edit room document
         * @param {InputCreateEditRoomDocument} [inputCreateEditRoomDocument]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEditRoomDocument: (inputCreateEditRoomDocument, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/edit-room/document/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputCreateEditRoomDocument, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Create edit room folder
         * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEditRoomFolder: (uNKNOWNBASETYPE, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/edit-room/folder/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(uNKNOWNBASETYPE, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Create original document
         * @param {string} id
         * @param {InputCreateOriginalDocument} inputCreateOriginalDocument
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOriginalDocument: (id, inputCreateOriginalDocument, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('createOriginalDocument', 'id', id);
            // verify required parameter 'inputCreateOriginalDocument' is not null or undefined
            (0, common_1.assertParamExists)('createOriginalDocument', 'inputCreateOriginalDocument', inputCreateOriginalDocument);
            const localVarPath = `/edit-room/document/{id}/original/create`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputCreateOriginalDocument, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Create original folder
         * @param {string} id
         * @param {InputCreateOriginalFolder} [inputCreateOriginalFolder]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOriginalFolder: (id, inputCreateOriginalFolder, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('createOriginalFolder', 'id', id);
            const localVarPath = `/edit-room/folder/{id}/original/create`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputCreateOriginalFolder, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete edit room document version
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEditRoomDocumentVersion: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('deleteEditRoomDocumentVersion', 'id', id);
            const localVarPath = `/edit-room/document/file/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete edit room folder
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEditRoomFolder: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('deleteEditRoomFolder', 'id', id);
            const localVarPath = `/edit-room/folder/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete one or more edit room documents
         * @param {InputDeleteOneOrMoreEditRoomDocuments} [inputDeleteOneOrMoreEditRoomDocuments]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneOrMoreEditRoomDocuments: (inputDeleteOneOrMoreEditRoomDocuments, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/edit-room/document/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputDeleteOneOrMoreEditRoomDocuments, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit edit room document by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editEditRoomDocumentById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('editEditRoomDocumentById', 'id', id);
            const localVarPath = `/edit-room/document/{id}/edit`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit edit room folder
         * @param {string} id
         * @param {InputEditRoomFolder} [inputEditRoomFolder]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editEditRoomFolder: (id, inputEditRoomFolder, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('editEditRoomFolder', 'id', id);
            const localVarPath = `/edit-room/folder/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PATCH' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputEditRoomFolder, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all edit room folder updates
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEditRoomFolderUpdates: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getAllEditRoomFolderUpdates', 'id', id);
            const localVarPath = `/edit-room/folder/{id}/updates/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all edit rooms
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {string} [parentFolderId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEditRooms: (filter, parentFolderId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/edit-room/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            if (parentFolderId !== undefined) {
                localVarQueryParameter['parentFolderId'] = parentFolderId;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get ancestors of edit room folder
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAncestorsOfEditRoomFolder: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getAncestorsOfEditRoomFolder', 'id', id);
            const localVarPath = `/edit-room/folder/{id}/ancestors`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get edit room document updates
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditRoomDocumentUpdates: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getEditRoomDocumentUpdates', 'id', id);
            const localVarPath = `/edit-room/document/{id}/update/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get edit room folder tree view
         * @param {string} id
         * @param {number} [limit]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditRoomFolderTreeView: (id, limit, page, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getEditRoomFolderTreeView', 'id', id);
            const localVarPath = `/edit-room/folder/{id}/tree/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get plain edit room folder
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlainEditRoomFolder: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getPlainEditRoomFolder', 'id', id);
            const localVarPath = `/edit-room/folder/{id}/plain`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get referred document parent folder
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferredDocumentParentFolder: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getReferredDocumentParentFolder', 'id', id);
            const localVarPath = `/edit-room/referred/{id}/folder/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get single edit room document
         * @param {string} id
         * @param {string} [preferredVersionFileId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleEditRoomDocument: (id, preferredVersionFileId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getSingleEditRoomDocument', 'id', id);
            const localVarPath = `/edit-room/document/{id}/single`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (preferredVersionFileId !== undefined) {
                localVarQueryParameter['preferredVersionFileId'] = preferredVersionFileId;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get total edit room folders count
         * @param {string} parentFolderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalEditRoomFoldersCount: (parentFolderId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'parentFolderId' is not null or undefined
            (0, common_1.assertParamExists)('getTotalEditRoomFoldersCount', 'parentFolderId', parentFolderId);
            const localVarPath = `/edit-room/folder/countTotalFolders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (parentFolderId !== undefined) {
                localVarQueryParameter['parentFolderId'] = parentFolderId;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Read documents of edit room folder
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDocumentsOfEditRoomFolder: (id, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('readDocumentsOfEditRoomFolder', 'id', id);
            const localVarPath = `/edit-room/folder/{id}/documents`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Read edit room folder from parent
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readEditRoomFolderFromParent: (id, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('readEditRoomFolderFromParent', 'id', id);
            const localVarPath = `/edit-room/folder/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Request edit room document update access
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestEditRoomDocumentUpdateAccess: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('requestEditRoomDocumentUpdateAccess', 'id', id);
            const localVarPath = `/edit-room/document/{id}/update/request`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Resume edit room update
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeEditRoomUpdate: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('resumeEditRoomUpdate', 'id', id);
            const localVarPath = `/edit-room/update/{id}/resume/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Update edit room document
         * @param {string} id
         * @param {InputUpdateEditRoomDocument} [inputUpdateEditRoomDocument]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEditRoomDocument: (id, inputUpdateEditRoomDocument, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('updateEditRoomDocument', 'id', id);
            const localVarPath = `/edit-room/document/{id}/update/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PATCH' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputUpdateEditRoomDocument, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Update original document
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOriginalDocument: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('updateOriginalDocument', 'id', id);
            const localVarPath = `/edit-room/document/{id}/original/update`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Update original folder
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOriginalFolder: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('updateOriginalFolder', 'id', id);
            const localVarPath = `/edit-room/folder/{id}/original/update`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Upload new edit room document version
         * @param {string} id
         * @param {InputUploadNewEditRoomDocumentVersion} [inputUploadNewEditRoomDocumentVersion]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNewEditRoomDocumentVersion: (id, inputUploadNewEditRoomDocumentVersion, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('uploadNewEditRoomDocumentVersion', 'id', id);
            const localVarPath = `/edit-room/document/{id}/version/upload`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputUploadNewEditRoomDocumentVersion, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.EditRoomApiAxiosParamCreator = EditRoomApiAxiosParamCreator;
/**
 * EditRoomApi - functional programming interface
 * @export
 */
const EditRoomApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.EditRoomApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Cancel edit room document update
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelEditRoomDocumentUpdate(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.cancelEditRoomDocumentUpdate(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Create edit room
         * @param {EditRoomFolderInput} [editRoomFolderInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEditRoom(editRoomFolderInput, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createEditRoom(editRoomFolderInput, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Create edit room document
         * @param {InputCreateEditRoomDocument} [inputCreateEditRoomDocument]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEditRoomDocument(inputCreateEditRoomDocument, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createEditRoomDocument(inputCreateEditRoomDocument, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Create edit room folder
         * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEditRoomFolder(uNKNOWNBASETYPE, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createEditRoomFolder(uNKNOWNBASETYPE, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Create original document
         * @param {string} id
         * @param {InputCreateOriginalDocument} inputCreateOriginalDocument
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOriginalDocument(id, inputCreateOriginalDocument, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createOriginalDocument(id, inputCreateOriginalDocument, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Create original folder
         * @param {string} id
         * @param {InputCreateOriginalFolder} [inputCreateOriginalFolder]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOriginalFolder(id, inputCreateOriginalFolder, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createOriginalFolder(id, inputCreateOriginalFolder, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete edit room document version
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEditRoomDocumentVersion(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteEditRoomDocumentVersion(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete edit room folder
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEditRoomFolder(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteEditRoomFolder(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete one or more edit room documents
         * @param {InputDeleteOneOrMoreEditRoomDocuments} [inputDeleteOneOrMoreEditRoomDocuments]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneOrMoreEditRoomDocuments(inputDeleteOneOrMoreEditRoomDocuments, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteOneOrMoreEditRoomDocuments(inputDeleteOneOrMoreEditRoomDocuments, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit edit room document by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editEditRoomDocumentById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editEditRoomDocumentById(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit edit room folder
         * @param {string} id
         * @param {InputEditRoomFolder} [inputEditRoomFolder]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editEditRoomFolder(id, inputEditRoomFolder, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editEditRoomFolder(id, inputEditRoomFolder, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all edit room folder updates
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEditRoomFolderUpdates(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllEditRoomFolderUpdates(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all edit rooms
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {string} [parentFolderId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEditRooms(filter, parentFolderId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllEditRooms(filter, parentFolderId, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get ancestors of edit room folder
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAncestorsOfEditRoomFolder(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAncestorsOfEditRoomFolder(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get edit room document updates
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditRoomDocumentUpdates(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getEditRoomDocumentUpdates(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get edit room folder tree view
         * @param {string} id
         * @param {number} [limit]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditRoomFolderTreeView(id, limit, page, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getEditRoomFolderTreeView(id, limit, page, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get plain edit room folder
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlainEditRoomFolder(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getPlainEditRoomFolder(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get referred document parent folder
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferredDocumentParentFolder(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getReferredDocumentParentFolder(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get single edit room document
         * @param {string} id
         * @param {string} [preferredVersionFileId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleEditRoomDocument(id, preferredVersionFileId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getSingleEditRoomDocument(id, preferredVersionFileId, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get total edit room folders count
         * @param {string} parentFolderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalEditRoomFoldersCount(parentFolderId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getTotalEditRoomFoldersCount(parentFolderId, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Read documents of edit room folder
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDocumentsOfEditRoomFolder(id, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.readDocumentsOfEditRoomFolder(id, filter, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Read edit room folder from parent
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readEditRoomFolderFromParent(id, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.readEditRoomFolderFromParent(id, filter, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Request edit room document update access
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestEditRoomDocumentUpdateAccess(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.requestEditRoomDocumentUpdateAccess(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Resume edit room update
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeEditRoomUpdate(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.resumeEditRoomUpdate(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Update edit room document
         * @param {string} id
         * @param {InputUpdateEditRoomDocument} [inputUpdateEditRoomDocument]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEditRoomDocument(id, inputUpdateEditRoomDocument, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateEditRoomDocument(id, inputUpdateEditRoomDocument, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Update original document
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOriginalDocument(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateOriginalDocument(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Update original folder
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOriginalFolder(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateOriginalFolder(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Upload new edit room document version
         * @param {string} id
         * @param {InputUploadNewEditRoomDocumentVersion} [inputUploadNewEditRoomDocumentVersion]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNewEditRoomDocumentVersion(id, inputUploadNewEditRoomDocumentVersion, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.uploadNewEditRoomDocumentVersion(id, inputUploadNewEditRoomDocumentVersion, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.EditRoomApiFp = EditRoomApiFp;
/**
 * EditRoomApi - factory interface
 * @export
 */
const EditRoomApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.EditRoomApiFp)(configuration);
    return {
        /**
         *
         * @summary Cancel edit room document update
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelEditRoomDocumentUpdate(id, options) {
            return localVarFp.cancelEditRoomDocumentUpdate(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create edit room
         * @param {EditRoomFolderInput} [editRoomFolderInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEditRoom(editRoomFolderInput, options) {
            return localVarFp.createEditRoom(editRoomFolderInput, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create edit room document
         * @param {InputCreateEditRoomDocument} [inputCreateEditRoomDocument]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEditRoomDocument(inputCreateEditRoomDocument, options) {
            return localVarFp.createEditRoomDocument(inputCreateEditRoomDocument, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create edit room folder
         * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEditRoomFolder(uNKNOWNBASETYPE, options) {
            return localVarFp.createEditRoomFolder(uNKNOWNBASETYPE, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create original document
         * @param {string} id
         * @param {InputCreateOriginalDocument} inputCreateOriginalDocument
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOriginalDocument(id, inputCreateOriginalDocument, options) {
            return localVarFp.createOriginalDocument(id, inputCreateOriginalDocument, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create original folder
         * @param {string} id
         * @param {InputCreateOriginalFolder} [inputCreateOriginalFolder]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOriginalFolder(id, inputCreateOriginalFolder, options) {
            return localVarFp.createOriginalFolder(id, inputCreateOriginalFolder, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete edit room document version
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEditRoomDocumentVersion(id, options) {
            return localVarFp.deleteEditRoomDocumentVersion(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete edit room folder
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEditRoomFolder(id, options) {
            return localVarFp.deleteEditRoomFolder(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete one or more edit room documents
         * @param {InputDeleteOneOrMoreEditRoomDocuments} [inputDeleteOneOrMoreEditRoomDocuments]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneOrMoreEditRoomDocuments(inputDeleteOneOrMoreEditRoomDocuments, options) {
            return localVarFp.deleteOneOrMoreEditRoomDocuments(inputDeleteOneOrMoreEditRoomDocuments, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit edit room document by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editEditRoomDocumentById(id, options) {
            return localVarFp.editEditRoomDocumentById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit edit room folder
         * @param {string} id
         * @param {InputEditRoomFolder} [inputEditRoomFolder]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editEditRoomFolder(id, inputEditRoomFolder, options) {
            return localVarFp.editEditRoomFolder(id, inputEditRoomFolder, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all edit room folder updates
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEditRoomFolderUpdates(id, options) {
            return localVarFp.getAllEditRoomFolderUpdates(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all edit rooms
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {string} [parentFolderId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEditRooms(filter, parentFolderId, options) {
            return localVarFp.getAllEditRooms(filter, parentFolderId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get ancestors of edit room folder
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAncestorsOfEditRoomFolder(id, options) {
            return localVarFp.getAncestorsOfEditRoomFolder(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get edit room document updates
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditRoomDocumentUpdates(id, options) {
            return localVarFp.getEditRoomDocumentUpdates(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get edit room folder tree view
         * @param {string} id
         * @param {number} [limit]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditRoomFolderTreeView(id, limit, page, options) {
            return localVarFp.getEditRoomFolderTreeView(id, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get plain edit room folder
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlainEditRoomFolder(id, options) {
            return localVarFp.getPlainEditRoomFolder(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get referred document parent folder
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferredDocumentParentFolder(id, options) {
            return localVarFp.getReferredDocumentParentFolder(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get single edit room document
         * @param {string} id
         * @param {string} [preferredVersionFileId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleEditRoomDocument(id, preferredVersionFileId, options) {
            return localVarFp.getSingleEditRoomDocument(id, preferredVersionFileId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get total edit room folders count
         * @param {string} parentFolderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalEditRoomFoldersCount(parentFolderId, options) {
            return localVarFp.getTotalEditRoomFoldersCount(parentFolderId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Read documents of edit room folder
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDocumentsOfEditRoomFolder(id, filter, options) {
            return localVarFp.readDocumentsOfEditRoomFolder(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Read edit room folder from parent
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readEditRoomFolderFromParent(id, filter, options) {
            return localVarFp.readEditRoomFolderFromParent(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Request edit room document update access
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestEditRoomDocumentUpdateAccess(id, options) {
            return localVarFp.requestEditRoomDocumentUpdateAccess(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Resume edit room update
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeEditRoomUpdate(id, options) {
            return localVarFp.resumeEditRoomUpdate(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update edit room document
         * @param {string} id
         * @param {InputUpdateEditRoomDocument} [inputUpdateEditRoomDocument]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEditRoomDocument(id, inputUpdateEditRoomDocument, options) {
            return localVarFp.updateEditRoomDocument(id, inputUpdateEditRoomDocument, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update original document
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOriginalDocument(id, options) {
            return localVarFp.updateOriginalDocument(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update original folder
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOriginalFolder(id, options) {
            return localVarFp.updateOriginalFolder(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Upload new edit room document version
         * @param {string} id
         * @param {InputUploadNewEditRoomDocumentVersion} [inputUploadNewEditRoomDocumentVersion]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNewEditRoomDocumentVersion(id, inputUploadNewEditRoomDocumentVersion, options) {
            return localVarFp.uploadNewEditRoomDocumentVersion(id, inputUploadNewEditRoomDocumentVersion, options).then((request) => request(axios, basePath));
        },
    };
};
exports.EditRoomApiFactory = EditRoomApiFactory;
/**
 * EditRoomApi - object-oriented interface
 * @export
 * @class EditRoomApi
 * @extends {BaseAPI}
 */
class EditRoomApi extends base_1.BaseAPI {
    /**
     *
     * @summary Cancel edit room document update
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditRoomApi
     */
    cancelEditRoomDocumentUpdate(id, options) {
        return (0, exports.EditRoomApiFp)(this.configuration).cancelEditRoomDocumentUpdate(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Create edit room
     * @param {EditRoomFolderInput} [editRoomFolderInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditRoomApi
     */
    createEditRoom(editRoomFolderInput, options) {
        return (0, exports.EditRoomApiFp)(this.configuration).createEditRoom(editRoomFolderInput, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Create edit room document
     * @param {InputCreateEditRoomDocument} [inputCreateEditRoomDocument]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditRoomApi
     */
    createEditRoomDocument(inputCreateEditRoomDocument, options) {
        return (0, exports.EditRoomApiFp)(this.configuration).createEditRoomDocument(inputCreateEditRoomDocument, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Create edit room folder
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditRoomApi
     */
    createEditRoomFolder(uNKNOWNBASETYPE, options) {
        return (0, exports.EditRoomApiFp)(this.configuration).createEditRoomFolder(uNKNOWNBASETYPE, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Create original document
     * @param {string} id
     * @param {InputCreateOriginalDocument} inputCreateOriginalDocument
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditRoomApi
     */
    createOriginalDocument(id, inputCreateOriginalDocument, options) {
        return (0, exports.EditRoomApiFp)(this.configuration).createOriginalDocument(id, inputCreateOriginalDocument, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Create original folder
     * @param {string} id
     * @param {InputCreateOriginalFolder} [inputCreateOriginalFolder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditRoomApi
     */
    createOriginalFolder(id, inputCreateOriginalFolder, options) {
        return (0, exports.EditRoomApiFp)(this.configuration).createOriginalFolder(id, inputCreateOriginalFolder, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete edit room document version
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditRoomApi
     */
    deleteEditRoomDocumentVersion(id, options) {
        return (0, exports.EditRoomApiFp)(this.configuration).deleteEditRoomDocumentVersion(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete edit room folder
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditRoomApi
     */
    deleteEditRoomFolder(id, options) {
        return (0, exports.EditRoomApiFp)(this.configuration).deleteEditRoomFolder(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete one or more edit room documents
     * @param {InputDeleteOneOrMoreEditRoomDocuments} [inputDeleteOneOrMoreEditRoomDocuments]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditRoomApi
     */
    deleteOneOrMoreEditRoomDocuments(inputDeleteOneOrMoreEditRoomDocuments, options) {
        return (0, exports.EditRoomApiFp)(this.configuration).deleteOneOrMoreEditRoomDocuments(inputDeleteOneOrMoreEditRoomDocuments, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit edit room document by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditRoomApi
     */
    editEditRoomDocumentById(id, options) {
        return (0, exports.EditRoomApiFp)(this.configuration).editEditRoomDocumentById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit edit room folder
     * @param {string} id
     * @param {InputEditRoomFolder} [inputEditRoomFolder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditRoomApi
     */
    editEditRoomFolder(id, inputEditRoomFolder, options) {
        return (0, exports.EditRoomApiFp)(this.configuration).editEditRoomFolder(id, inputEditRoomFolder, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all edit room folder updates
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditRoomApi
     */
    getAllEditRoomFolderUpdates(id, options) {
        return (0, exports.EditRoomApiFp)(this.configuration).getAllEditRoomFolderUpdates(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all edit rooms
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {string} [parentFolderId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditRoomApi
     */
    getAllEditRooms(filter, parentFolderId, options) {
        return (0, exports.EditRoomApiFp)(this.configuration).getAllEditRooms(filter, parentFolderId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get ancestors of edit room folder
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditRoomApi
     */
    getAncestorsOfEditRoomFolder(id, options) {
        return (0, exports.EditRoomApiFp)(this.configuration).getAncestorsOfEditRoomFolder(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get edit room document updates
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditRoomApi
     */
    getEditRoomDocumentUpdates(id, options) {
        return (0, exports.EditRoomApiFp)(this.configuration).getEditRoomDocumentUpdates(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get edit room folder tree view
     * @param {string} id
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditRoomApi
     */
    getEditRoomFolderTreeView(id, limit, page, options) {
        return (0, exports.EditRoomApiFp)(this.configuration).getEditRoomFolderTreeView(id, limit, page, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get plain edit room folder
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditRoomApi
     */
    getPlainEditRoomFolder(id, options) {
        return (0, exports.EditRoomApiFp)(this.configuration).getPlainEditRoomFolder(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get referred document parent folder
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditRoomApi
     */
    getReferredDocumentParentFolder(id, options) {
        return (0, exports.EditRoomApiFp)(this.configuration).getReferredDocumentParentFolder(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get single edit room document
     * @param {string} id
     * @param {string} [preferredVersionFileId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditRoomApi
     */
    getSingleEditRoomDocument(id, preferredVersionFileId, options) {
        return (0, exports.EditRoomApiFp)(this.configuration).getSingleEditRoomDocument(id, preferredVersionFileId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get total edit room folders count
     * @param {string} parentFolderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditRoomApi
     */
    getTotalEditRoomFoldersCount(parentFolderId, options) {
        return (0, exports.EditRoomApiFp)(this.configuration).getTotalEditRoomFoldersCount(parentFolderId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Read documents of edit room folder
     * @param {string} id
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditRoomApi
     */
    readDocumentsOfEditRoomFolder(id, filter, options) {
        return (0, exports.EditRoomApiFp)(this.configuration).readDocumentsOfEditRoomFolder(id, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Read edit room folder from parent
     * @param {string} id
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditRoomApi
     */
    readEditRoomFolderFromParent(id, filter, options) {
        return (0, exports.EditRoomApiFp)(this.configuration).readEditRoomFolderFromParent(id, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Request edit room document update access
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditRoomApi
     */
    requestEditRoomDocumentUpdateAccess(id, options) {
        return (0, exports.EditRoomApiFp)(this.configuration).requestEditRoomDocumentUpdateAccess(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Resume edit room update
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditRoomApi
     */
    resumeEditRoomUpdate(id, options) {
        return (0, exports.EditRoomApiFp)(this.configuration).resumeEditRoomUpdate(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Update edit room document
     * @param {string} id
     * @param {InputUpdateEditRoomDocument} [inputUpdateEditRoomDocument]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditRoomApi
     */
    updateEditRoomDocument(id, inputUpdateEditRoomDocument, options) {
        return (0, exports.EditRoomApiFp)(this.configuration).updateEditRoomDocument(id, inputUpdateEditRoomDocument, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Update original document
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditRoomApi
     */
    updateOriginalDocument(id, options) {
        return (0, exports.EditRoomApiFp)(this.configuration).updateOriginalDocument(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Update original folder
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditRoomApi
     */
    updateOriginalFolder(id, options) {
        return (0, exports.EditRoomApiFp)(this.configuration).updateOriginalFolder(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Upload new edit room document version
     * @param {string} id
     * @param {InputUploadNewEditRoomDocumentVersion} [inputUploadNewEditRoomDocumentVersion]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditRoomApi
     */
    uploadNewEditRoomDocumentVersion(id, inputUploadNewEditRoomDocumentVersion, options) {
        return (0, exports.EditRoomApiFp)(this.configuration).uploadNewEditRoomDocumentVersion(id, inputUploadNewEditRoomDocumentVersion, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.EditRoomApi = EditRoomApi;
