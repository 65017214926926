import { useQuery } from "@tanstack/react-query";
import { WeavyApi } from "certiblok-api-manager";
import { instanceApi } from "../../../utils/hooks/api";

export const useGetAllUserChats = () => {
	return useQuery({
		queryKey: ["getAllUserChats"],
		queryFn: async () => {
			return await instanceApi(WeavyApi).getAllUserChats();
		},
		select: (data) => {
			return data.data;
		},
	});
};
