import { fromDate } from "@internationalized/date";
import { EditCreateEventForm } from "../hooks/useEditCreateEventFormValidator";
import { rrulestr } from "rrule";
import { DateTime } from "luxon";

export function formDataToSearchParams(data: Partial<EditCreateEventForm>) {
	return {
		...data,
		startDate: data.startDate?.toDate(DateTime.now().toFormat("z")).toDateString(),
		endDate: data.endDate?.toDate(DateTime.now().toFormat("z")).toDateString(),
		recurrence: data.recurrence?.toString(),
	};
}

export function searchParamsToFormData(searchParams: string): Partial<EditCreateEventForm> {
	const parsedSearchParams = JSON.parse(searchParams) as ReturnType<typeof formDataToSearchParams>;
	return {
		...parsedSearchParams,
		startDate: parsedSearchParams.startDate
			? fromDate(new Date(parsedSearchParams.startDate), Intl.DateTimeFormat().resolvedOptions().timeZone)
			: undefined,
		endDate: parsedSearchParams.endDate
			? fromDate(new Date(parsedSearchParams.endDate), Intl.DateTimeFormat().resolvedOptions().timeZone)
			: undefined,
		recurrence: parsedSearchParams.recurrence ? rrulestr(parsedSearchParams.recurrence) : undefined,
	};
}
