import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CertiblokTextInput from "../../../../utils/components/CertiblokTextInput";

const NameSelector = () => {
	const { t } = useTranslation();
	const {
		control,
		formState: {
			errors: { name: nameError },
		},
		clearErrors,
	} = useFormContext();

	return (
		<Controller
			name="name"
			control={control}
			render={({ field: { ref, ...otherField } }) => (
				<CertiblokTextInput
					autoFocus
					onFocus={() => {
						clearErrors("name");
					}}
					status={nameError ? "error" : undefined}
					helperText={nameError?.message as string}
					label={t("teamGroups.groupName")}
					fullWidth
					color="secondary"
					sx={{ backgroundColor: "white", marginBottom: 4, border: "2px solid #E0E0E0" }}
					{...otherField}
				/>
			)}
		/>
	);
};

export default NameSelector;
