import { Audit, AuditApi } from "certiblok-api-manager";
import React, { useCallback, useState } from "react";
import { defaultQueryFilter, transformToStringFrom } from "../../../../../utils/components/tables/AdminTable";
import { useApi } from "../../../../../utils/hooks/api";
import { useInfiniteLoader } from "../../../../../utils/hooks/useInfiniteLoader";
import { MAX_COLUMN_ELEMENTS } from "../components/AuditRoomsInfiniteList";

export function useAuditRoomContext() {
	const auditApi = useApi(AuditApi);

	const auditRoomsInfiniteLoader = useInfiniteLoader((filter) =>
		auditApi.listOnlyOwnerAuditRooms(
			transformToStringFrom({ ...defaultQueryFilter, perPage: MAX_COLUMN_ELEMENTS, ...filter })
		)
	);

	const [selectedAuditRooms, setSelectedAuditRooms] = useState<Audit[]>([]);

	const selectAuditRoom = useCallback(
		(auditRoom: Audit) => {
			setSelectedAuditRooms([...selectedAuditRooms, auditRoom]);
		},
		[selectedAuditRooms]
	);

	const selectAuditRooms = useCallback((auditRooms: Audit[]) => {
		setSelectedAuditRooms([...auditRooms]);
	}, []);

	const unselectAuditRoom = useCallback(
		(auditRoom: Audit) => {
			const index = selectedAuditRooms.findIndex((elem) => elem.id === auditRoom.id);
			if (index !== -1) {
				selectedAuditRooms.splice(index, 1);
				setSelectedAuditRooms([...selectedAuditRooms]);
			}
		},
		[selectedAuditRooms]
	);

	const resetSelectedAuditRooms = useCallback(() => {
		setSelectedAuditRooms([]);
	}, []);

	return {
		auditRoomsInfiniteLoader,
		selectedAuditRooms,
		selectAuditRoom,
		selectAuditRooms,
		unselectAuditRoom,
		resetSelectedAuditRooms,
	};
}

type IAuditRoomContext = ReturnType<typeof useAuditRoomContext>;

export const AuditRoomsContext = React.createContext<IAuditRoomContext>({} as IAuditRoomContext);
