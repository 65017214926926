import { Divider, Typography } from "@mui/material";
import { CBFile, CloudFile, ImportStatus, ImportStatusStatusEnum } from "certiblok-api-manager";
import { useCallback, useState } from "react";
import useSocket from "../../../../../hooks/useSocket";
import CertiblokIcon from "../../../../icons/CertiblokIcon";
import FileIcon from "../../../../icons/FileIcon";
import { fileStatusIconSwitch } from "../utils/fileStatusIconSwitch";

export type FileStatusType = { status: ImportStatusStatusEnum; progress: number };

// export const useCounter = (id: string) => {
// 	const counter = useRef(0);

// 	useEffect(() => {
// 		const onLog = (count: number) => {
// 			console.log(`${id}: ${count}`);
// 		};
// 		onLog(counter.current++);
// 	});
// };
const statusesSequence = ["scan", "import", "error", "success"] as ImportStatusStatusEnum[];

const SingleImportedDocOrFolder = ({ file, isGlobalComplete }: { file: CloudFile; isGlobalComplete?: boolean }) => {
	const [fileStatus, setFileStatus] = useState<FileStatusType>({
		status: "scan",
		progress: 0,
	});

	const cloudImportCallback = useCallback(
		(status: ImportStatus) => {
			if (Boolean(status.rootItemId) && status.rootItemId === file.id) {
				setFileStatus((p) => ({
					status:
						(statusesSequence.findIndex((el) => el === status.status) ?? 0) >
						(statusesSequence.findIndex((el) => el === p.status) ?? 0)
							? status.status
							: p.status,
					progress: Boolean(status.progress) && p.progress < (status.progress ?? 0) ? status.progress ?? 0 : p.progress,
				}));
			}
		},
		[file.id]
	);

	// useCounter(file.id);

	useSocket("cloudImport", cloudImportCallback);

	return (
		<div key={file.id}>
			<div className="flex gap-4 items-center justify-between mb-1">
				<div className="flex gap-4 items-center flex-1 min-w-0">
					{file.type === "folder" ? (
						<CertiblokIcon name="folder_bold" color="#b0b0b0" />
					) : (
						<FileIcon file={{ type: file.mimeType } as CBFile} />
					)}
					<Typography noWrap className="">
						{file.name}
					</Typography>
				</div>
				<div className="basis-12 h-10 flex items-center justify-center">
					{fileStatusIconSwitch(isGlobalComplete ? { status: "success", progress: 100 } : fileStatus)}
				</div>
			</div>
			<Divider className="flex-shrink-0" />
		</div>
	);
};

export default SingleImportedDocOrFolder;
