import { Typography } from "@mui/material";
import { Team, User } from "certiblok-api-manager";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import MemberInFolder from "../../../../../../../../Folders/components/EditCreateFolderModal/components/MemberInFolder";
import { EditRoomFormValues } from "../../../../../hooks/useEditRoomDetailsZodSchema";
import { useCompany } from "../../../../../../../../../utils/queries/globalQueries";
import { useMe } from "../../../../../../../../../utils/hooks/useMe";
import TeamInFolder from "../../../../../../../../Folders/components/EditCreateFolderModal/components/TeamInFolder";

type EditRoomSharedRoomProps = {};

const EditRoomSharedRoom: React.FC<EditRoomSharedRoomProps> = (props) => {
	const { t } = useTranslation();

	const { control } = useFormContext<EditRoomFormValues>();

	const { fields: reservedUsers, remove: removeUser } = useFieldArray({
		control,
		name: "reservedToUsers",
	});

	const { fields: reservedTeams, remove: removeTeam } = useFieldArray({
		control,
		name: "reservedToTeams",
	});

	const { me } = useMe();

	const { data: company } = useCompany();

	const companyOwner = company?.data?.owner;

	return (
		<div className="flex flex-col items-stretch gap-2">
			<Typography variant="caption" className="text-black-a54">
				{t("editRoom.sharedWithTeamMembers")}
			</Typography>
			<div className="flex flex-col items-stretch gap-1 max-h-[30vh] overflow-y-auto">
				<MemberInFolder user={companyOwner as User} isAdmin />
				{reservedTeams
					?.filter((team) => !team.isNewAddedGroup)
					?.map((team, i) => {
						return (
							<TeamInFolder
								key={team.id}
								team={team as Team}
								endIcon="delete"
								onEndIconClick={() => {
									const index = reservedTeams.findIndex((fields) => fields.id === team.id);
									removeTeam(index);
								}}
							/>
						);
					})}
				{reservedUsers
					?.filter((user) => !user.isNewAddedUser && user.userId !== me?.id && user.userId !== companyOwner?.id)
					?.map((user, i) => {
						return (
							<MemberInFolder
								key={user.id}
								user={user as User}
								endIcon="delete"
								onEndIconClick={() => {
									const index = reservedUsers.findIndex((fields) => fields.id === user.id);
									removeUser(index);
								}}
							/>
						);
					})}
			</div>
		</div>
	);
};

export default EditRoomSharedRoom;
