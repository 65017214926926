import { Button, Collapse, Typography } from "@mui/material";
import { useInfiniteQuery } from "@tanstack/react-query";
import { EditRoomApi, Folder } from "certiblok-api-manager";
import clsx from "clsx";
import { useAtom } from "jotai";
import { forwardRef, memo, useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import EditCreateFolderModal from "../../../../pages/Folders/components/EditCreateFolderModal/EditCreateFolderModal";
import SingleFolderToChoose from "../../../../pages/Folders/components/UploadDocument/components/SingleFolderToChoose";
import { apiErrorParser, instanceApi } from "../../../hooks/api";
import { useBetterMediaQuery } from "../../../hooks/useBetterMediaQuery";
import SelectedFoldersBreadcrumbs from "../../FolderPicker/components/SelectedFoldersBreadcrumbs";
import Loading from "../../Loading";
import { useModalNavigator } from "../../ModalNavigator/ModalNavigator";
import NoContents from "../../NoContents";
import CertiblokIcon from "../../icons/CertiblokIcon";
import MLDialog from "../../poppers";
import { defaultQueryFilter, transformToStringFrom } from "../../tables/AdminTable";
import { selectedFolderAtom, selectedFolderHistoryAtom } from "../atoms/uploadDocumentAtoms";
import { useUploadDocumentContext } from "../contexts/UploadDocumentContext";

const PER_PAGE_FOLDERS = 14;

const UploadDocumentPickEditRoomFolder = forwardRef<any, any>((props, ref) => {
	const { t } = useTranslation();
	const isMd = useBetterMediaQuery("md");
	const { pushBottomSheet } = useModalNavigator();

	const { mainBottomSheetRef } = useUploadDocumentContext();

	const [selectedFolder, setSelectedFolder] = useAtom(selectedFolderAtom);
	const [selectedFolderHistory, setSelectedFolderHistory] = useAtom(selectedFolderHistoryAtom);

	const hasNextPageRef = useRef(false);
	const fetchNextTimeout = useRef<NodeJS.Timeout>();

	const { data, isFetching, fetchNextPage } = useInfiniteQuery({
		queryKey: ["editRooms", selectedFolder],
		queryFn: ({ pageParam = 0 }) => {
			return instanceApi(EditRoomApi).getAllEditRooms(
				transformToStringFrom({ ...defaultQueryFilter, page: pageParam, perPage: PER_PAGE_FOLDERS }),
				selectedFolder
			);
		},
		onSuccess: () => {
			fetchNextTimeout.current = setTimeout(() => {
				if (hasNextPageRef.current) fetchNextPage();
			}, 0);
		},
		onError: (err) => {
			MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
		},
		getNextPageParam: (lastPage, pages) => {
			const totalFolders = pages.reduce((acc, page) => acc + page.data.length, 0);
			hasNextPageRef.current = totalFolders < lastPage.headers.count;
			return totalFolders < lastPage.headers.count ? pages.length : undefined;
		},
	});

	useEffect(() => {
		return () => {
			clearTimeout(fetchNextTimeout.current);
		};
	}, []);

	const availableFolders = useMemo(
		() => data?.pages?.reduce((acc, page) => [...acc, ...page.data], [] as Folder[]) ?? [],
		[data]
	);

	const groupedFolders = useMemo(() => {
		const groupSize = 7;
		const groups: Folder[][] = [];
		for (let i = 0; i < availableFolders.length; i += groupSize) {
			groups.push(availableFolders.slice(i, i + groupSize));
		}
		return groups;
	}, [availableFolders]);

	const renderSingleFolderToChoose = useCallback((folder: Folder) => {
		return (
			<SingleFolderToChoose
				key={folder.id}
				folder={folder}
				onClick={() => {
					setSelectedFolderHistory((prev) => [...prev, folder]);
					setSelectedFolder(folder.id);
				}}
			/>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onCreateFolder = () => {
		pushBottomSheet({
			component: EditCreateFolderModal,
			props: {
				parentFolderId: selectedFolder,
				isEdit: false,
			},
			ref: mainBottomSheetRef.current,
		}).then((folder) => {
			setSelectedFolderHistory((prev) => [...prev, folder]);
			setSelectedFolder(folder.id);
		});
	};

	return (
		<div className="" ref={ref} {...props}>
			<div className="px-5 md:px-12 pb-2 md:pb-6">
				<div className="flex items-center justify-between pb-2 md:pb-0">
					<Typography children={t("uploadDocument.assignToFolder")} className="!text-subtitle2 md:!text-subtitle1 " />
					{!isMd && (
						<Button
							color="secondary"
							// size="small"
							onClick={onCreateFolder}
							startIcon={<CertiblokIcon size={24} color="inherit" name={"add"} />}
						>
							{t("uploadDocument.createNew")}
						</Button>
					)}
				</div>
				<SelectedFoldersBreadcrumbs
					folderHistory={selectedFolderHistory}
					onFolderDelete={(folder) => {
						const folderIndex = selectedFolderHistory.findIndex((f) => f.id === folder.id);
						setSelectedFolderHistory(selectedFolderHistory.slice(0, folderIndex));
						setSelectedFolder(folder.parentFolderId);
					}}
					hasMoreSubfolders={availableFolders.length > 0}
				/>
			</div>
			{isMd && (
				<div className="flex justify-between items-center px-5 md:px-12 gap-3 pb-2 md:pb-6">
					<p className="text-label">{t("moveDocuments.cooseFolder")}</p>
					<Button
						color="secondary"
						// size="small"
						variant="outlined"
						onClick={onCreateFolder}
						startIcon={<CertiblokIcon size={24} color="inherit" name={"add"} />}
					>
						{t("global.createNewFolder")}
					</Button>
				</div>
			)}
			{isMd ? (
				<div className="w-full px-12 box-border ">
					<div className="flex h-[352px] gap-6 lg:gap-12 w-full overflow-x-auto horizontal-scroll">
						{groupedFolders.map((group, index) => {
							return (
								<div key={index} className="flex flex-col gap-2 basis-[25%] max-w-[25%] flex-shrink-0">
									{group.map((folder) => {
										return renderSingleFolderToChoose(folder);
									})}
									{group.length < 7 && isFetching && <Loading title={t("moveDocuments.foldersLoading")} sx={{ py: 2 }} />}
								</div>
							);
						})}
						{availableFolders.length % 7 === 0 && isFetching && (
							<div className={clsx("flex items-center justify-center h-full", availableFolders.length === 0 && "w-full")}>
								<Loading title={t("moveDocuments.foldersLoading")} sx={{ py: 2 }} />
							</div>
						)}
						{availableFolders.length === 0 && !isFetching && (
							<div className="w-full h-full flex items-center justify-center">
								<NoContents title={t("uploadDocument.noMoreFolders")} variant="folder" />
							</div>
						)}
					</div>
				</div>
			) : (
				<div
					className={clsx(
						"flex flex-col gap-2 px-5 min-h-[30vh] ",
						((isFetching && availableFolders.length === 0) || availableFolders.length === 0) && "justify-center"
					)}
				>
					{availableFolders.length > 0 ? (
						availableFolders.map((folder) => {
							return renderSingleFolderToChoose(folder);
						})
					) : isFetching ? null : (
						<NoContents title={t("uploadDocument.noMoreFolders")} variant="folder" />
					)}

					<Collapse in={isFetching}>
						<Loading
							sx={{ py: 2 }}
							circularProgressProps={{
								size: 20,
							}}
							title={t("moveDocuments.foldersLoading")}
						/>
					</Collapse>
				</div>
			)}
		</div>
	);
});
export default memo(UploadDocumentPickEditRoomFolder);
