import { CalendarEventTypeOfEventEnum } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import CertiblokIcon, { CertiblokIconNames } from "../../../../icons/CertiblokIcon";
import { Typography } from "@mui/material";

const EventTypeChip = ({ eventType }: { eventType: CalendarEventTypeOfEventEnum }) => {
	const { t } = useTranslation();

	const data: { [key in CalendarEventTypeOfEventEnum]: { name: string; iconName: CertiblokIconNames } } = {
		document: {
			iconName: "paper_bold_02",
			name: t("global.documents_one"),
		},
		audit_room: {
			iconName: "comunication_bold_01",
			name: t("documents.auditRoom"),
		},
		event: {
			iconName: "calendar_bold",
			name: t("singleEvent.event"),
		},
	};

	return (
		<div className="px-3 py-[6px] bg-primary-a60/[0.12] flex rounded-2xl items-center gap-2">
			<CertiblokIcon color="primary" name={data?.[eventType].iconName} className="opacity-60" />
			<Typography variant="body2" className="text-black-a60">
				{data?.[eventType].name}
			</Typography>
		</div>
	);
};

export default EventTypeChip;
