import { Fade } from "@mui/material";
import clsx from "clsx";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { isDesktop } from "react-device-detect";
import { SwitchTransition } from "react-transition-group";
import type { CertiblokDocument } from "../../interfaces/Document";
import { ModalNavigator, useBottomSheet, useModalNavigator } from "../ModalNavigator/ModalNavigator";
import BottomSheet from "../bottomSheet/BottomSheet";
import { selectedFolderAtom, uploadDocumentStepAtom } from "./atoms/uploadDocumentAtoms";
import UnsavedEditsModal from "./components/UnsavedEditsModal";
import UploadDocumentFooter from "./components/UploadDocumentFooter";
import UploadDocumentHeader from "./components/UploadDocumentHeader";
import UploadDocumentPickDocument from "./components/UploadDocumentPickDocument";
import UploadDocumentPickEditRoomFolder from "./components/UploadDocumentPickEditRoomFolder";
import UploadDocumentPickFolder from "./components/UploadDocumentPickFolder";
import UploadedDocumentRecap from "./components/UploadedDocumentRecap/UploadedDocumentRecap";
import { UploadDocumentContext, UploadDocumentContextValue } from "./contexts/UploadDocumentContext";
import { useResetUploadDocuments } from "./hooks/useResetUploadDocuments";
import { useEditRoomFolderDetails, useFolderDetails } from "./queries/uploadDocumentQueries";
import { useFilesToUploadCount } from "./stores/filesToUploadStore";
import { getUploadedCBDocuments, useHasUnsavedEdits } from "./stores/uploadedFilesStore";
import { queryClient } from "../../reactQueryClient";
import { editRoomFolderQueries } from "../../../pages/EditRooms/pages/EditRoom/queries/editRoomFolderQueries";
import { treeViewQueryKeys } from "../../controllers/TreeViewController/queries/treeViewQueries";

type UploadDocumentProps = {
	type?: UploadDocumentContextValue["type"];
	currentFolder?: string;
	onSuccess?: (value: { documents: CertiblokDocument[]; selectedFolder: string }) => void;
	onCancel?: () => void;
};

const UploadDocument = ModalNavigator.create<UploadDocumentProps>(
	({ type = "uploadDocument", currentFolder, onSuccess, onCancel }) => {
		const { visible, bottomSheetProps } = useBottomSheet();
		const { popBottomSheet, pushModal } = useModalNavigator();

		const [selectedFolder, setSelectedFolder] = useAtom(selectedFolderAtom);

		const filesToUploadCount = useFilesToUploadCount();
		const [activeStep, setActiveStep] = useAtom(uploadDocumentStepAtom);
		useEffect(() => {
			setSelectedFolder(currentFolder);
			if (currentFolder) {
				setActiveStep("uploadDocuments");
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [currentFolder, visible]);

		const isEditRoomUpload = type === "uploadEditRoomDocument";

		if (isEditRoomUpload) {
			useEditRoomFolderDetails(selectedFolder);
		} else {
			useFolderDetails(selectedFolder);
		}

		const hasUnsavedEdits = useHasUnsavedEdits();
		const resetUploadDocuments = useResetUploadDocuments();

		const onClose = () => {
			if (activeStep === "chooseFolder") {
				onCancel?.();
			} else {
				onSuccess?.({ documents: getUploadedCBDocuments(), selectedFolder: selectedFolder ?? "" });
			}
			resetUploadDocuments();
			if (type === "uploadEditRoomDocument") {
				queryClient.invalidateQueries(treeViewQueryKeys.base);
				queryClient.invalidateQueries(editRoomFolderQueries.editRoomFolderById.base(currentFolder ?? ""));
			}
			popBottomSheet();
		};

		return (
			<UploadDocumentContext.Provider
				value={{
					mainBottomSheetRef: bottomSheetProps.ref,
					onSuccess,
					onCancel,
					type,
				}}
			>
				<BottomSheet
					onClose={() => {
						if (hasUnsavedEdits) {
							setTimeout(() => {
								pushModal(UnsavedEditsModal, {}).then(() => {
									onClose();
								});
							}, 0);
							return;
						}
						if (!(activeStep === "uploadDocuments" && filesToUploadCount > 0)) {
							onClose();
						}
					}}
					open={visible}
					header={<UploadDocumentHeader />}
					HeaderProps={{ className: "pt-3 px-1 md:px-6 md:py-2 md:pt-2" }}
					footer={<UploadDocumentFooter />}
					ref={bottomSheetProps.ref}
					className={clsx(isDesktop && "max-h-[90vh]")}
					// {...bottomSheetProps}
				>
					<SwitchTransition>
						<Fade key={activeStep}>
							{activeStep === "chooseFolder" ? (
								isEditRoomUpload ? (
									<UploadDocumentPickEditRoomFolder />
								) : (
									<UploadDocumentPickFolder />
								)
							) : activeStep === "uploadDocuments" ? (
								<UploadDocumentPickDocument />
							) : !isEditRoomUpload ? (
								<UploadedDocumentRecap />
							) : (
								<UploadedDocumentRecap />
							)}
						</Fade>
					</SwitchTransition>
				</BottomSheet>
			</UploadDocumentContext.Provider>
		);
	}
);
export default UploadDocument;
