import { Typography } from "@mui/material";
import { useCallback } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
	ModalNavigator,
	useModalNavigator,
	useModalRoute,
} from "../../../../utils/components/ModalNavigator/ModalNavigator";
import ResponsiveDialog from "../../../../utils/components/dialogs/ResponsiveDialog";
import EditCreateEditRoomBody from "./components/EditCreateEditRoomBody";
import EditCreateEditRoomFooter from "./components/EditCreateEditRoomFooter";
import EditCreateEditRoomHeader from "./components/EditCreateEditRoomHeader";
import { useEditCreateEditRoom } from "./hooks/useEditCreateEditRoom";
import { useInitEditRoomDetailsForm } from "./hooks/useInitEditRoomDetailsForm";
import { queryClient } from "../../../../utils/reactQueryClient";
import { getGetAllEditRoomsQueryKey } from "../../pages/EditRooms/queries/useGetEditRooms";

export type EditCreateEditRoomModalProps = {
	editRoomId?: string;
	fromFolderId?: string;
	fromDocumentId?: string;
};

const EditCreateEditRoomModal = ModalNavigator.create((props: EditCreateEditRoomModalProps) => {
	const { editRoomId, fromFolderId, fromDocumentId } = props;

	const { visible } = useModalRoute();
	const { popModal } = useModalNavigator();

	const { t } = useTranslation();

	const editRoomDetailsForm = useInitEditRoomDetailsForm({ editRoomFolderId: editRoomId, open: visible });

	const { mutateAsync: editCreateEditRoom, isLoading } = useEditCreateEditRoom({
		editRoomId,
		fromFolderId,
		fromDocumentId,
		options: {
			onSuccess: () => {
				popModal();
				queryClient.invalidateQueries(getGetAllEditRoomsQueryKey());
			},
		},
	});

	const onAction = () => {
		editCreateEditRoom({ data: editRoomDetailsForm.getValues(), form: editRoomDetailsForm });
	};

	const onClose = useCallback(() => {
		popModal();
	}, [popModal]);

	return (
		<FormProvider {...editRoomDetailsForm}>
			<ResponsiveDialog
				open={visible}
				onClose={onClose}
				header={<EditCreateEditRoomHeader isEdit={Boolean(editRoomId)} />}
				footer={
					<EditCreateEditRoomFooter
						onClose={onClose}
						isEdit={Boolean(editRoomId)}
						isLoading={isLoading}
						onAction={onAction}
					/>
				}
			>
				{Boolean(fromFolderId) && (
					<Typography variant="body2" className="my-3 max-w-xl">
						{t("editRoom.fromFoldersLabel")}
					</Typography>
				)}
				<EditCreateEditRoomBody editRoomId={editRoomId} onAction={onAction} />
			</ResponsiveDialog>
		</FormProvider>
	);
});

export default EditCreateEditRoomModal;
