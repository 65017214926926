import { useQuery } from "@tanstack/react-query";
import { DocumentApi } from "certiblok-api-manager";
import { useApi } from "../../../utils/hooks/api";

const useGetIsDocumentAvailable = (documentId: string) => {
	const documentApi = useApi(DocumentApi);

	return useQuery({
		queryKey: ["document", documentId],
		queryFn: async () => {
			const document = await documentApi.getSingleDocument(documentId);
			if (document.data && !document.data.deletedAt) {
				return true;
			}
			return false;
		},
	});
};

export default useGetIsDocumentAvailable;
