import { Button, IconButton, lighten, touchRippleClasses, Typography } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { TagsApi } from "certiblok-api-manager";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import CertiblokIcon from "../../../../../utils/components/icons/CertiblokIcon";
import { useModalNavigator } from "../../../../../utils/components/ModalNavigator/ModalNavigator";
import TableTitleContainer from "../../../../../utils/components/TableTitleContainer";
import { instanceApi } from "../../../../../utils/hooks/api";
import { useBetterMediaQuery } from "../../../../../utils/hooks/useBetterMediaQuery";
import AllDocumentsSecondaryActions from "../../../../AllDocuments/components/AllDocumentsHeader/components/AllDocumentsSecondaryActions";
import { AllDocumentsContext } from "../../../../AllDocuments/controllers/AllDocumentsContext";
import EditCreateTag from "../../../../SingleDocument/components/Tags/TagsManagementModal/EditCreateTag";
import { getTagsQueryKey } from "../../../../SingleDocument/hooks/useTagsHooks";

const DocumentsByTagHeader = () => {
	const { t } = useTranslation();
	const { pushModalById } = useModalNavigator();
	const queryClient = useQueryClient();

	const allDocumentsContext = useContext(AllDocumentsContext);

	const isSm = useBetterMediaQuery("sm");
	const navigate = useNavigate();

	const { id: tagId } = useParams();

	const tagByIdQueryKey = ["tagById", tagId ?? ""];
	const { data: tag } = useQuery({
		queryKey: tagByIdQueryKey,
		queryFn: () => {
			return instanceApi(TagsApi).getTag(tagId ?? "");
		},
		select: (data) => data.data,
		enabled: Boolean(tagId),
	});

	return (
		<>
			<TableTitleContainer className="flex sm:flex-row flex-col sm:items-center sm:justify-between justify-start items-start w-full gap-3 h-auto">
				<div className="flex items-center justify-start gap-1 md:gap-2 lg:gap-3">
					<IconButton
						onClick={() => {
							navigate(-1);
						}}
						sx={{
							backgroundColor: (theme) => theme.palette.secondary.main,
							padding: 0,
							borderRadius: 2,
							"&:hover": {
								backgroundColor: (theme) => lighten(theme.palette.secondary.main, 0.06),
							},
							[`& .${touchRippleClasses.child}`]: {
								borderRadius: 2,
							},
						}}
					>
						<CertiblokIcon name="arrow_mini_outline_left" color="white" size={isSm ? 24 : 20} />
					</IconButton>
					{tag && (
						<Typography children={tag?.name} variant={isSm ? "h6" : "subtitle1"} sx={{ fontWeight: 800, flexShrink: 0 }} />
					)}
				</div>

				{allDocumentsContext.selectedDocumentsId.length === 0 ? (
					<Button
						className="self-end"
						variant="contained"
						color="secondary"
						startIcon={<CertiblokIcon name="edit_outline_02" color="inherit" />}
						// size={isSm ? "medium" : "small"}
						disabled={!tag || !tagId}
						onClick={() => {
							pushModalById(`edit-create-tag-${tag?.id ?? ""}`);
						}}
					>
						{t("tags.editTag")}
					</Button>
				) : (
					<div className="flex items-between w-full sm:w-fit self-end md:col-span-1">
						<AllDocumentsSecondaryActions
							onRevalidateItems={() => {
								allDocumentsContext.revalidateDocuments();
								allDocumentsContext.resetSelectAllDocuments();
							}}
							onSuccess={() => {
								allDocumentsContext.revalidateDocuments();
								allDocumentsContext.resetSelectAllDocuments();
							}}
							selectedItems={allDocumentsContext.selectedDocuments.map((elem) => ({ ...elem, isFolder: false }))}
						/>
					</div>
				)}
			</TableTitleContainer>
			<EditCreateTag
				id={`edit-create-tag-${tag?.id ?? ""}`}
				key={(tagId ?? "") + (tag?.color ?? "") + (tag?.name ?? "")}
				oldTag={tag}
				onEditCreateSuccess={() => {
					queryClient.invalidateQueries(tagByIdQueryKey);
					queryClient.invalidateQueries(getTagsQueryKey());
					allDocumentsContext.revalidateDocuments();
				}}
			/>
		</>
	);
};

export default DocumentsByTagHeader;
