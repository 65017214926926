import OutletContainer from "../../../../utils/components/OutletContainer";
import usePathQueryUpdater from "../../../../utils/hooks/usePathQueryUpdater";
import {
	AllDocumentsContext,
	AllDocumentsContextType,
	useAllDocumentsContext,
} from "../../../AllDocuments/controllers/AllDocumentsContext";
import DocumentsByTagHeader from "./components/DocumentsByTagHeader";
import DocumentsByTagTable from "./components/DocumentsByTagTable";
import { useDocumentsByTagContext } from "./contexts/useDocumentsByTagContext";

const SingleTagPage = () => {
	const allDocumentsContext = useAllDocumentsContext();
	const documentsContext = useDocumentsByTagContext();
	usePathQueryUpdater([documentsContext.queryFilter, documentsContext.setQueryFilter]);

	return (
		<AllDocumentsContext.Provider
			value={{ ...allDocumentsContext, ...documentsContext } as unknown as AllDocumentsContextType}
		>
			<OutletContainer>
				<DocumentsByTagHeader />
				<DocumentsByTagTable />
			</OutletContainer>
		</AllDocumentsContext.Provider>
	);
};

export default SingleTagPage;
