import { Box, CircularProgress, Typography } from "@mui/material";
import type { Document } from "certiblok-api-manager";
import type { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import SingleDocumentToChoose from "../../../pages/Folders/components/MoveDocument/components/SingleDocumentToChoose";
import type { CertiblokDocument } from "../../interfaces/Document";
import { usePickDocumentReplacementContextUnoptimized } from "./controllers/PickDocumentReplacementController";
import { ITEM_SIZE, MAX_COLUMN_ELEMENTS } from "./PickDocumentReplacement";

type ReplacingDocumentItemsProps = {
	index: number;
	style: CSSProperties;
	data: Document[];
};
const ReplacingDocumentItems: React.FC<ReplacingDocumentItemsProps> = ({ index, style, data }) => {
	const { t } = useTranslation();
	const { setSelectedFile, selectedFile } = usePickDocumentReplacementContextUnoptimized();

	return (
		<Box style={style} sx={{ paddingRight: 12, boxSizing: "border-box" }}>
			{index < Math.ceil(data.length / MAX_COLUMN_ELEMENTS) ? (
				data.slice(index * MAX_COLUMN_ELEMENTS, index * MAX_COLUMN_ELEMENTS + MAX_COLUMN_ELEMENTS).map((elem) => {
					return (
						<Box sx={{ marginBottom: 2 }} key={`large_list_document_${elem.id}_${elem.name}`}>
							<SingleDocumentToChoose
								key={elem.id}
								document={elem as CertiblokDocument}
								selectedDocument={selectedFile as CertiblokDocument}
								onClick={() => setSelectedFile(elem)}
							/>
						</Box>
					);
				})
			) : (
				<Box
					sx={{ height: "100%", width: ITEM_SIZE, display: "flex", alignItems: "center", justifyContent: "center", gap: 3 }}
				>
					<CircularProgress color="secondary" />
					<Typography children={t("pickDocumentReplacement.documentsLoading")} variant="subtitle1" color="secondary" />
				</Box>
			)}
		</Box>
	);
};
export default ReplacingDocumentItems;
