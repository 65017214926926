import {
	Box,
	CircularProgress,
	CircularProgressProps,
	SxProps,
	Theme,
	Typography,
	TypographyProps,
} from "@mui/material";
import React, { CSSProperties } from "react";

type Props = {
	title: string;
	sx?: SxProps<Theme>;
	color?: "inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined;
	style?: CSSProperties;
	circularProgressProps?: CircularProgressProps;
	typographyProps?: TypographyProps;
};

const Loading = React.forwardRef<any, Props>(
	({ title, sx, color = "secondary", circularProgressProps, typographyProps, ...otherProps }, ref) => {
		return (
			<Box
				ref={ref}
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					gap: 3,
					...sx,
				}}
				{...otherProps}
			>
				<CircularProgress color={color} {...circularProgressProps} />
				<Typography children={title} variant="subtitle1" color={color} {...typographyProps} />
			</Box>
		);
	}
);

export default Loading;
