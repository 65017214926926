import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DocumentApi, FavoriteApi } from "certiblok-api-manager";
import type { DateTime } from "luxon";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import { useModalNavigator } from "../../../utils/components/ModalNavigator/ModalNavigator";
import { useBottomSheetOld } from "../../../utils/components/bottomSheet/hooks/useBottomSheet";
import MLDialog from "../../../utils/components/poppers";
import { IQueryFilter, defaultQueryFilter, transformToStringFrom } from "../../../utils/components/tables/AdminTable";
import { chunkRequests } from "../../../utils/functions/chunkRequests";
import { apiErrorParser, useApi } from "../../../utils/hooks/api";
import type { CertiblokDocument } from "../../../utils/interfaces/Document";
import { allDocumentsModal } from "../modals/allDocumentsModal";

const allDocumentsQueryKeys = {
	base: ["documents", "list"],
	all: (filters?: IQueryFilter) => [...allDocumentsQueryKeys.base, "all", filters],
	shared: (filters?: IQueryFilter) => [...allDocumentsQueryKeys.base, "shared", filters],
};

export function useAllDocumentsContext() {
	const { t } = useTranslation();
	//* APIS
	const documentApi = useApi(DocumentApi);
	const [searchParams, setSearchParams] = useSearchParams();

	const favoriteApi = useApi(FavoriteApi);

	const queryClient = useQueryClient();

	const location = useLocation();
	const isInDocumentsByTag = location.pathname.includes("/tags/");

	const [queryFilter, setQueryFilter] = useState<IQueryFilter>(() => {
		const queryFilterString = searchParams.get("queryFilter");
		return !queryFilterString
			? {
					...defaultQueryFilter,
					orderBy: "name",
					order: "asc",
			  }
			: JSON.parse(searchParams.get("queryFilter") || "{}");
	});

	const [selectedDocumentsTab, setSelectedDocumentsTab] = useState<"all" | "shared">(
		(searchParams.get("section") as "all" | "shared" | null) || "all"
	);
	const updateSelectedDocumentsTab = useCallback(
		(tab: "all" | "shared") => {
			searchParams.set("section", tab);
			setSearchParams(searchParams, { replace: true });
			setSelectedDocumentsTab(tab);
			setQueryFilter((prev) => ({ ...defaultQueryFilter, filter: prev.filter }));
		},
		[searchParams, setSearchParams]
	);

	const {
		data: allDocumentsQuery,
		isLoading: allDocumentsLoading,
		isFetching: allDocumentsFetching,
	} = useQuery({
		queryKey: allDocumentsQueryKeys.all(queryFilter),
		queryFn: () => documentApi.listAllDocuments(transformToStringFrom({ ...queryFilter })),
		onError: (e) => {
			MLDialog.showSnackbar(apiErrorParser(e), {
				variant: "error",
			});
		},
		keepPreviousData: true,
		select: (res) => {
			return {
				...res,
				data: res.data as CertiblokDocument[],
				totalDocumentsCount: parseInt(res?.headers?.["x-total-count"]),
			};
		},
		enabled: !isInDocumentsByTag,
	});

	const {
		data: sharedDocumentsQuery,
		isLoading: sharedDocumentsLoading,
		isFetching: sharedDocumentsFetching,
	} = useQuery({
		queryKey: allDocumentsQueryKeys.shared(queryFilter),
		queryFn: () => documentApi.listSharedDocuments(transformToStringFrom({ ...queryFilter })),
		onError: (e) => {
			MLDialog.showSnackbar(apiErrorParser(e), {
				variant: "error",
			});
		},
		keepPreviousData: true,
		select: (res) => {
			return {
				...res,
				data: res.data as CertiblokDocument[],
				totalDocumentsCount: parseInt(res?.headers?.["x-total-count"]),
			};
		},
		enabled: !isInDocumentsByTag,
	});

	const revalidateFavoriteDocuments = useCallback(
		() => queryClient.invalidateQueries(["favorites", "documents"]),
		[queryClient]
	);

	const revalidateDocuments = useCallback(() => {
		queryClient.invalidateQueries(allDocumentsQueryKeys.base);
	}, [queryClient]);

	const toggleDocumentFromPreferenceMutation = useMutation({
		mutationFn: async ({
			docId,
			isPreference,
			fromFavorite,
		}: {
			docId: string;
			isPreference: boolean;
			fromFavorite?: boolean;
		}) => {
			if (isPreference) {
				await favoriteApi.removeDocumentFromFavorite(docId);
			} else {
				await favoriteApi.addDocumentToFavorite({ documentId: docId });
			}
		},
		onSuccess: (_, variables) => {
			if (variables.fromFavorite) {
				revalidateFavoriteDocuments();
			} else {
				revalidateDocuments();
			}
		},
		onError: (err) => {
			MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
		},
	});

	// toggleDocumentFromPreferenceMutation.mutateAsync({docId: 'jhhjf', isPreference: false }).then(...)
	// toggleDocumentFromPreferenceMutation.mutate({docId: 'jhhjf', isPreference: false }, {onError: ()=> {}})

	const [selectedDocumentsId, setSelectedDocumentsId] = useState<string[]>([]);
	const selectedDocuments = useMemo(() => {
		return (allDocumentsQuery?.data ?? []).filter((elem) => selectedDocumentsId.includes(elem.id));
	}, [allDocumentsQuery?.data, selectedDocumentsId]);

	const toggleSingleDocumentSelection = useCallback((folderId: string) => {
		setSelectedDocumentsId((prevState) => {
			const selectedIndex = prevState.findIndex((elem) => elem === folderId);
			if (selectedIndex === -1) {
				return [...prevState, folderId];
			}
			const selectedFoldersCopy = [...prevState];
			selectedFoldersCopy.splice(selectedIndex, 1);
			return [...selectedFoldersCopy];
		});
	}, []);

	const toggleAllDocumentSelection = useCallback(() => {
		const documentsSection = selectedDocumentsTab === "shared" ? sharedDocumentsQuery?.data : allDocumentsQuery?.data;
		setSelectedDocumentsId((prevState) => {
			if (prevState.length === documentsSection?.length) {
				return [];
			}
			return [...(documentsSection ?? []).map((document) => document.id)];
		});
	}, [allDocumentsQuery, sharedDocumentsQuery, selectedDocumentsTab]);

	const resetSelectAllDocuments = useCallback(() => {
		setSelectedDocumentsId([]);
	}, []);

	const updateExpirationDateMutation = useMutation({
		mutationFn: async (data: {
			documentIds: string[];
			expireDate: DateTime | "null";
			noExpiration?: boolean;
			fromFavorite?: boolean;
		}) => {
			const { chunkedErrors } = await chunkRequests(
				20,
				(documentId) =>
					documentApi.editDocument(documentId, {
						expireDate: data.expireDate === "null" ? "null" : data.expireDate.toISO() ?? "null",
						noExpiration: data.noExpiration ?? false,
					}),
				data.documentIds
			);
			if (chunkedErrors.length > 0) {
				MLDialog.showSnackbar(t("documents.editExpirationDatesError", { count: chunkedErrors.length }), {
					variant: "error",
				});
			}
		},
		onSuccess: (data, { fromFavorite }) => {
			if (fromFavorite) {
				revalidateFavoriteDocuments();
			} else {
				revalidateDocuments();
				resetSelectAllDocuments();
			}
		},
	});

	const deleteDocumentsMutation = useMutation({
		mutationFn: (data: { documentIds: string[]; fromFavorite?: boolean }) => {
			return documentApi.deleteOneOrMoreDocuments({ toDeleteIds: data.documentIds });
		},
		onError: (e) => {
			MLDialog.showSnackbar(t("documents.fileDeletionError"), {
				variant: "error",
			});
		},
		onSettled: (data, error, { fromFavorite }) => {
			if (fromFavorite) {
				revalidateFavoriteDocuments();
			} else {
				revalidateDocuments();
				resetSelectAllDocuments();
			}
		},
	});

	const { pushModalRoute: pushModal, popModal } = useModalNavigator();

	const openAssignExpireDateModal = useCallback(
		(documents: CertiblokDocument[], fromFavorite?: boolean) => {
			pushModal(allDocumentsModal.assignExpireDate, {
				document: documents,
				onSubmitDate: async (date, hasNoExpireDate) => {
					try {
						await updateExpirationDateMutation.mutateAsync(
							{
								documentIds: documents.map((document) => document.id),
								expireDate: date,
								noExpiration: hasNoExpireDate,
								fromFavorite: fromFavorite,
							},
							{ onSuccess: popModal }
						);
					} catch (e) {
						console.log(e);
					}
				},
			});
		},
		[popModal, pushModal, updateExpirationDateMutation]
	);

	const openShareDocumentModal = useCallback(
		(documents: CertiblokDocument[]) => {
			pushModal(allDocumentsModal.shareDocument, {
				document: documents,
				revalidateDocument: revalidateDocuments,
			});
		},
		[pushModal, revalidateDocuments]
	);

	const openRenameDocumentModal = useCallback(
		(document: CertiblokDocument, fromFavorite?: boolean) => {
			pushModal(allDocumentsModal.renameDocument, {
				document,
				onSuccess: () => {
					if (fromFavorite) {
						revalidateFavoriteDocuments();
					} else {
						revalidateDocuments();
					}
				},
			});
		},
		[pushModal, revalidateDocuments, revalidateFavoriteDocuments]
	);

	const openUpdateOrReplaceDocumentModal = useCallback(
		(document: CertiblokDocument, isUpdate: boolean, fromFavorite?: boolean) => {
			pushModal(allDocumentsModal.updateOrReplaceDocument, {
				document,
				update: isUpdate,
				revalidate: () => {
					revalidateFavoriteDocuments();
					revalidateDocuments();
				},
			});
		},
		[pushModal, revalidateDocuments, revalidateFavoriteDocuments]
	);

	const openAskDocumentUpdateModal = useCallback(
		(document: CertiblokDocument) => {
			pushModal(allDocumentsModal.askDocumentUpdate, {
				document,
			});
		},
		[pushModal]
	);

	const openMoveDocumentsModal = useCallback(
		(documents: CertiblokDocument[], fromFavorite?: boolean) => {
			pushModal(allDocumentsModal.moveDocuments, {
				documents,
				onSuccessClick: () => {
					if (fromFavorite) {
						revalidateFavoriteDocuments();
					}
					revalidateDocuments();
					popModal();
				},
				successButtonText: t("global.close"),
			});
		},
		[popModal, pushModal, revalidateDocuments, revalidateFavoriteDocuments, t]
	);

	const assignAuditRoomsControl = useBottomSheetOld();
	const openAssignAuditRoomsModal = useCallback(
		(documents: CertiblokDocument[], fromFavorite?: boolean) => {
			pushModal(allDocumentsModal.assignAuditRoom, {
				document: documents,
				controls: assignAuditRoomsControl,
				onSubmit: () => {
					if (fromFavorite) {
						revalidateFavoriteDocuments();
					} else {
						resetSelectAllDocuments();
						revalidateDocuments();
					}
					return Promise.resolve();
				},
			});
		},
		[assignAuditRoomsControl, pushModal, resetSelectAllDocuments, revalidateDocuments, revalidateFavoriteDocuments]
	);

	return {
		queryFilter,
		setQueryFilter,
		allDocumentsQuery,
		allDocumentsLoading,
		allDocumentsFetching,
		sharedDocumentsQuery,
		sharedDocumentsLoading,
		sharedDocumentsFetching,
		selectedDocumentsId,
		toggleSingleDocumentSelection,
		toggleAllDocumentSelection,
		updateExpirationDateMutation,
		deleteDocumentsMutation,
		resetSelectAllDocuments,
		revalidateDocuments,
		selectedDocuments,
		openAssignExpireDateModal,
		openShareDocumentModal,
		openRenameDocumentModal,
		openUpdateOrReplaceDocumentModal,
		openAskDocumentUpdateModal,
		openMoveDocumentsModal,
		openAssignAuditRoomsModal,
		toggleDocumentFromPreferenceMutation,
		selectedDocumentsTab,
		updateSelectedDocumentsTab,
	};
}

export type AllDocumentsContextType = ReturnType<typeof useAllDocumentsContext>;

export const AllDocumentsContext = React.createContext<AllDocumentsContextType>({} as AllDocumentsContextType);
