import type { User } from "certiblok-api-manager";
import queryString from "querystring";

export function roundDecimals(value: number, decimals: number = 0) {
	const multiplier = Math.pow(10, decimals);
	return Math.round(value * multiplier) / multiplier;
}

export function getFullName(person?: { name?: string | null; surname?: string | null }) {
	return `${person?.name || ""} ${person?.surname || ""}`;
}

export function backendMediaUrlParser(
	fileUrl?: string,
	options?: { download?: boolean; filename?: string; width?: number; height?: number }
) {
	if (!fileUrl) return undefined;
	if (fileUrl.includes("blob:")) return fileUrl;

	const { download, filename, width, height } = options || {};

	let url = `${process.env.REACT_APP_BACKEND_BASE_URL}/media/${fileUrl}`;

	if (width || height) {
		url = `${url}?${width ? `w=${width}` : ""}${height ? `${width ? "&" : ""}h=${height}` : ""}`;
	}

	if (download || filename) {
		const tmpParams = queryString.encode({ download, filename });

		url += `?${tmpParams}`;
	}

	return url;
}

export function fakeUserFromEmail(email: string): User {
	return {
		email,
		name: email.split("@")[0],
		surname: email.split("@")[1],
	} as User;
}
