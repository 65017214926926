/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import {
	Autocomplete,
	autocompleteClasses,
	AutocompleteRenderOptionState,
	Backdrop,
	Box,
	Collapse,
	Fade,
	IconButton,
	Popper,
	PopperPlacementType,
	styled,
	Theme,
	Typography,
	useTheme,
} from "@mui/material";
import { alpha, SxProps } from "@mui/system";
import useResizeObserver from "@react-hook/resize-observer";
import type React from "react";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDebounce } from "../hooks/useDebounce";
import type { CertiblokTextInputProps } from "../interfaces/CertiblokTextInputProps";
import type { TextFieldStatus } from "../interfaces/TextFieldStatus";
import CertiblokTextInput from "./CertiblokTextInput";
import CertiblokIcon from "./icons/CertiblokIcon";

interface PopperComponentProps {
	anchorEl?: any;
	disablePortal?: boolean;
	open: boolean;
}

const StyledAutocompletePopper = styled("div")(() => ({
	[`& .${autocompleteClasses.paper}`]: {
		boxShadow: "none",
		margin: 0,
		color: "inherit",
		backgroundColor: "transparent",
	},
	[`&.${autocompleteClasses.popperDisablePortal}`]: {
		position: "relative",
	},
}));

function PopperComponent(props: PopperComponentProps) {
	const { disablePortal, anchorEl, open, ...other } = props;
	return <StyledAutocompletePopper {...other} />;
}

export type CustomAutocompleteProps<T> = {
	label?: string;
	placeholder?: string;
	options: T[];
	id: keyof T;
	getOptionLabel?: (option: T) => string;
	renderOption?: (value: T) => React.ReactNode;
	onInputChange: (value: string) => void;
	onSelectedValueChange?: (value: T) => void;
	hasBackdrop?: boolean;
	size?: "small" | "medium";
	loading?: boolean;
	color?: "primary" | "secondary";
	extraButton?: React.ReactNode;
	shouldShowButton?: boolean;
	externalTextInputProps?: CertiblokTextInputProps;
	isDummyTextField?: boolean;
	shouldClose?: boolean;
	setShouldClose?: (value: boolean) => void;
	containerSx?: SxProps<Theme> | undefined;
	deletable?: boolean;
	outerFieldStatus?: {
		status?: TextFieldStatus;
		message?: string | undefined;
	};
	setOuterFieldStatus?: (value: { status?: TextFieldStatus; message?: string | undefined }) => void;
	onEnterPress?: (event: React.KeyboardEvent) => void;
	customRenderOption?: (
		props: React.HTMLAttributes<HTMLLIElement>,
		option: T,
		state: AutocompleteRenderOptionState
	) => React.ReactNode;
	popperPlacement?: PopperPlacementType;
};

const CustomAutocomplete = <T extends { [key: string]: any }>({
	label,
	options,
	id,
	getOptionLabel,
	renderOption,
	onInputChange,
	hasBackdrop,
	size = "small",
	loading = false,
	color = "primary",
	placeholder,
	extraButton,
	shouldShowButton,
	externalTextInputProps,
	onSelectedValueChange,
	isDummyTextField,
	shouldClose,
	setShouldClose,
	containerSx = {},
	deletable = false,
	outerFieldStatus,
	setOuterFieldStatus,
	onEnterPress,
	customRenderOption,
	popperPlacement = "bottom",
}: CustomAutocompleteProps<T>) => {
	const popperAnchor = useRef<HTMLDivElement>(null);
	const [popperOpen, setPopperOpen] = useState(false);
	const [inputValue, setInputValue] = useState("");
	const debouncedInput = useDebounce(inputValue, 300);

	const [selectedValue, setSelectedValue] = useState<T | undefined>();

	useEffect(() => {
		onInputChange(debouncedInput);
	}, [debouncedInput]);

	useEffect(() => {
		if (shouldClose) {
			setPopperOpen(false);
			setShouldClose?.(false);
		}
	}, [shouldClose]);

	useEffect(() => {
		if (isDummyTextField) {
			setInputValue("");
			onInputChange("");
		}
	}, [popperOpen]);

	const theme = useTheme();

	const [popperWidth, setPopperWidth] = useState(popperAnchor.current?.clientWidth);
	useLayoutEffect(() => {
		setPopperWidth(popperAnchor.current?.clientWidth);
	}, []);
	useResizeObserver(popperAnchor.current, () => {
		const newWidth = popperAnchor.current?.clientWidth;
		if (popperWidth !== newWidth) setPopperWidth(newWidth);
	});

	return (
		<>
			<Box sx={{ position: "relative", ...containerSx }}>
				{label && (
					<Typography
						children={label}
						variant="label"
						component="p"
						sx={{ paddingBottom: 2, fontWeight: 700, fontSize: 16 }}
					/>
				)}
				<Box ref={popperAnchor} sx={{ cursor: "pointer" }}>
					<CertiblokTextInput
						placeholder={placeholder}
						size={size}
						fullWidth
						readOnly
						onClick={() => {
							setOuterFieldStatus?.({});
							setPopperOpen(!popperOpen);
						}}
						value={isDummyTextField ? "" : selectedValue ? getOptionLabel?.(selectedValue) : ""}
						css={{ cursor: "pointer", "& input": { cursor: "pointer" } }}
						endAdornment={<CertiblokIcon size={16} color="rgba(0,0,0,0.6)" name={"▾-arrow-3"} />}
						status={outerFieldStatus?.status}
						helperText={outerFieldStatus?.message}
						{...externalTextInputProps}
					/>
				</Box>
				<Popper
					open={popperOpen}
					css={{
						zIndex: 1300,
						width: "100%",
						maxWidth: popperWidth,
					}}
					placement={popperPlacement}
					transition
					anchorEl={popperAnchor.current}
					// disablePortal
					modifiers={[
						{
							name: "offset",
							enabled: true,
							options: {
								offset: [-4, (size === "small" ? -36 : -48) - 4],
							},
						},
					]}
				>
					{({ TransitionProps }) => (
						<Fade {...TransitionProps} timeout={350}>
							<Box
								sx={{
									width: "100%",
									padding: 1,
									borderRadius: 4,
									boxShadow: "0px 3px 14px rgba(0, 0, 0, 0.12)",
									backgroundColor: "white",
									[`& .${autocompleteClasses.popper}`]: {
										width: "100% !important",
										[`& .${autocompleteClasses.paper}`]: {
											[`& .${autocompleteClasses.listbox}`]: {
												backgroundColor: "transparent",
												padding: 0,
												"& li:last-of-type": {
													marginBottom: 0,
												},
												[`& .${autocompleteClasses.option}`]: {
													backgroundColor: "white",
													my: 2,
													padding: 2,
													borderRadius: 3,
													borderBottom: "unset",
													"&.Mui-focused": {
														backgroundColor: alpha(theme.palette[color].main, 0.06),
													},
													'&[aria-selected="true"]': {
														backgroundColor: "transparent",
													},
													'&[data-focus="true"], &[data-focus="true"][aria-selected="true"]': {
														backgroundColor: "transparent",
													},
												},
											},
										},
									},
								}}
							>
								<Autocomplete
									disablePortal
									fullWidth
									options={options}
									size={size}
									renderInput={(params) => {
										const { InputLabelProps, size: otherSize, ...otherProps } = { ...params };
										return (
											<CertiblokTextInput
												autoFocus
												placeholder={placeholder}
												size={size}
												status={loading ? "loading" : undefined}
												variant="compact"
												color={color}
												endAdornment={
													deletable ? (
														<IconButton
															onClick={() => {
																setInputValue("");
															}}
															sx={{ right: -4 }}
														>
															<CertiblokIcon size={size === "small" ? 16 : 24} color="black" name={"canc"} />
														</IconButton>
													) : null
												}
												{...otherProps}
											/>
										);
									}}
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											onEnterPress?.(e);
										}
									}}
									renderOption={(props, option, state) => {
										return customRenderOption ? (
											customRenderOption(props, option, state)
										) : (
											<li {...props} key={JSON.stringify(option[id])}>
												{renderOption?.(option)}
											</li>
										);
									}}
									filterOptions={(x) => x}
									//@ts-ignore
									getOptionLabel={getOptionLabel}
									inputValue={inputValue}
									onInputChange={(event, newInputValue) => {
										setInputValue(newInputValue);
									}}
									isOptionEqualToValue={(option, value) => option[id] === value[id]}
									freeSolo
									handleHomeEndKeys
									autoHighlight
									PopperComponent={PopperComponent}
									open
									onChange={(event: any, newValue: string | T | null) => {
										setPopperOpen(false);
										if (newValue && typeof newValue !== "string") {
											setSelectedValue(newValue);
											onSelectedValueChange?.(newValue);
										}
									}}
								/>
								<Collapse in={shouldShowButton}>{extraButton}</Collapse>
							</Box>
						</Fade>
					)}
				</Popper>
			</Box>

			<Backdrop
				open={popperOpen}
				onClick={() => setPopperOpen(false)}
				sx={{ zIndex: 1200, ...(!hasBackdrop && { backgroundColor: "transparent" }) }}
			/>
		</>
	);
};
export default CustomAutocomplete;
