import { Select, SelectProps, menuClasses, selectClasses } from "@mui/material";

export const CustomizationSelect = ({ MenuProps, sx, ...props }: SelectProps) => {
	return (
		<Select
			MenuProps={{
				sx: {
					[`& .${menuClasses.paper}`]: {
						marginTop: 1,
					},
				},
				...MenuProps,
			}}
			sx={{
				"& .MuiSelect-select": {
					backgroundColor: "#E6F4F7",
					height: "50px !important",
					border: "unset",
					paddingLeft: 3,
					paddingRight: "40px !important",
				},
				[`& .${selectClasses.icon}`]: {
					right: 12,
				},
				...sx,
			}}
			variant="standard"
			size="medium"
			color="secondary"
			{...props}
		/>
	);
};
