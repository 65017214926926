import { useInfiniteQuery } from "@tanstack/react-query";
import { ContactApi } from "certiblok-api-manager";
import { defaultQueryFilter, transformToStringFrom } from "../../../utils/components/tables/AdminTable";
import { instanceApi } from "../../../utils/hooks/api";

export const useGetContacts = () => {
	return useInfiniteQuery({
		queryKey: ["contacts"],
		queryFn: async ({ pageParam }) => {
			return instanceApi(ContactApi).listContact(
				transformToStringFrom({
					...defaultQueryFilter,
					perPage: 8,
					page: pageParam,
				})
			);
		},
		getNextPageParam: (lastPage, pages) => {
			const totalContacts = pages.reduce((acc, page) => acc + page.data.length, 0);
			return totalContacts < lastPage.headers.count ? pages.length : undefined;
		},
	});
};
