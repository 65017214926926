import { Box, SxProps, Theme, Typography } from "@mui/material";
import type { CBFile } from "certiblok-api-manager";
import { forwardRef } from "react";
import FileIcon from "./icons/FileIcon";
import LinkBehavior from "./LinkBehavior";

type Props = {
	name: string;
	document?: CBFile;
	dataUrl?: string;
	onClick?: () => void;
	sx?: SxProps<Theme> | undefined;
	bold?: boolean;
	href?: string;
	typographySx?: SxProps<Theme>;
};

const DocumentWithIcon = forwardRef<any, Props>(
	({ document, name, dataUrl, sx, onClick, bold, href, typographySx, ...props }, ref) => {
		return (
			<Box
				ref={ref}
				{...(href != null && {
					component: LinkBehavior,
					href,
				})}
				onClick={onClick}
				sx={{
					display: "flex",
					gap: 1,
					maxWidth: "100%",
					alignItems: "center",
					textDecoration: "none",
					color: "unset",
					borderRadius: 3,
					transition: (theme) => theme.transitions.create(["background-color"]),
					...(href != null && {
						"&:hover": {
							backgroundColor: "backgroundSecondary.default",
						},
					}),
					...sx,
				}}
				{...props}
			>
				<FileIcon file={document} dataUrl={dataUrl} />
				<Typography
					children={`${name}`}
					noWrap
					variant="subtitle2"
					sx={{ fontWeight: bold ? 700 : 400, flexGrow: 1, minWidth: 0, textAlign: "start", ...typographySx }}
				/>
			</Box>
		);
	}
);

export default DocumentWithIcon;
