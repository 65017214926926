import { Controller, useFormContext } from "react-hook-form";
import { EditCreateEventForm } from "../hooks/useEditCreateEventFormValidator";
import CertiblokTextInput from "../../../../../utils/components/CertiblokTextInput";
import { useTranslation } from "react-i18next";

type EditCreateEventNameProps = {};
const EditCreateEventName: React.FC<EditCreateEventNameProps> = () => {
	const { t } = useTranslation();
	const {
		control,
		formState: {
			errors: { name: nameError },
		},
	} = useFormContext<EditCreateEventForm>();

	return (
		<Controller
			name="name"
			control={control}
			defaultValue=""
			render={({ field: { ref, ...otherField } }) => {
				return (
					<CertiblokTextInput
						status={nameError ? "error" : undefined}
						helperText={nameError?.message}
						color="secondary"
						size="medium"
						required
						placeholder={t("events.eventTitlePlaceholder")}
						label={`${t("events.eventTitle")} *`}
						{...otherField}
					/>
				);
			}}
		/>
	);
};
export default EditCreateEventName;
