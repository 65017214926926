import { Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { SyncfolderApi } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import LoadingTextButton from "../../../utils/components/LoadingTextButton";
import MLDialog from "../../../utils/components/poppers";
import { instanceApi } from "../../../utils/hooks/api";

const SyncFolderInstallation: React.FC = () => {
	const { t } = useTranslation();

	const { mutate: mutateWin, isLoading: isLoadingWin } = useMutation({
		mutationFn: async () => {
			const { data: url } = await instanceApi(SyncfolderApi).getSyncFolderDownloadUrl("windows");
			window.open(url, "_blank");
		},
		onError: () => {
			MLDialog.showSnackbar(t("syncFolder.errorDownload"), { variant: "error" });
		},
	});
	const { mutate: mutateMac, isLoading: isLoadingMac } = useMutation({
		mutationFn: async () => {
			const { data: url } = await instanceApi(SyncfolderApi).getSyncFolderDownloadUrl("mac");
			window.open(url, "_blank");
		},
		onError: () => {
			MLDialog.showSnackbar(t("syncFolder.errorDownload"), { variant: "error" });
		},
	});

	return (
		<div className="flex flex-col gap-4">
			<Typography variant="h6" className="font-bold">
				{t("syncFolder.installation")}
			</Typography>
			<Typography>{t("syncFolder.installationDescription")}</Typography>
			<ul className="m-0">
				<li>
					<div className="flex flex-col gap-4">
						<Typography>{t("syncFolder.installationStepOne")}</Typography>
						<div className="flex flex-col sm:flex-row items-start sm:items-center gap-2 sm:gap-4">
							<LoadingTextButton
								loading={isLoadingWin}
								onClick={() => {
									mutateWin();
								}}
								variant="contained"
								color="primary"
							>
								{t("syncFolder.downloadForWindows")}
							</LoadingTextButton>
							<LoadingTextButton
								loading={isLoadingMac}
								onClick={() => {
									mutateMac();
								}}
								variant="contained"
								color="primary"
							>
								{t("syncFolder.downloadForMacos")}
							</LoadingTextButton>
						</div>
					</div>
				</li>
				<li className="mt-4">
					<Typography>{t("syncFolder.installationStepTwo")}</Typography>
				</li>
				<li>
					<Typography>{t("syncFolder.installationStepThree")}</Typography>
				</li>
			</ul>
		</div>
	);
};

export default SyncFolderInstallation;
