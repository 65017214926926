import { Button, Stack, Typography } from "@mui/material";
import { Audit, AuditApi } from "certiblok-api-manager";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import FolderOrAuditRoomNamePicker from "../../../pages/Folders/components/FolderNamePicker";
import { AvailableFolderColors } from "../../../pages/Folders/constants/AvailableFolderColors";
import { apiErrorParser, useApi } from "../../hooks/api";
import OldBottomSheet from "../bottomSheet/OldBottomSheet";
import type { BottomSheetControls } from "../bottomSheet/hooks/useBottomSheet";
import LoadingIconButton from "../buttons/LoadingIconButton";
import CertiblokIcon from "../icons/CertiblokIcon";
import MLDialog from "../poppers";

type CreateAuditRoomBSProps = {
	controls: BottomSheetControls;
	onHeightChange?: (height?: number) => void;
	onSubmit?: (newAuditRoom: Audit) => void;
};
const CreateAuditRoomBS: React.FC<CreateAuditRoomBSProps> = ({ controls, onHeightChange, onSubmit }) => {
	const { ref, setHeight, toggleBehind, toggleOpen, height, ...otherControls } = controls;

	const { t } = useTranslation();

	const [auditRoomName, setAuditRoomName] = useState("");
	const [auditRoomColor, setAuditRoomColor] = useState(AvailableFolderColors[0]);

	const closeAndClear = () => {
		setAuditRoomName("");
		setAuditRoomColor(AvailableFolderColors[0]);
		toggleOpen();
	};

	const [submitLoading, setSubmitLoading] = useState(false);

	const auditApi = useApi(AuditApi);

	const submit = useCallback(() => {
		if (!auditRoomName) return;
		setSubmitLoading(true);
		auditApi
			.createAuditRoom({
				language: "it",
				color: auditRoomColor,
				name: auditRoomName,
				documentIds: [],
			})
			.then((res) => {
				MLDialog.showSnackbar(t("auditRooms.createdSuccessfully"), { variant: "success" });
				onSubmit?.(res.data);
				closeAndClear();
			})
			.catch((err) => {
				MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
			})
			.finally(() => {
				setSubmitLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auditApi, auditRoomColor, auditRoomName, onSubmit]);

	return (
		<OldBottomSheet
			onClose={closeAndClear}
			onHeightChange={(height) => {
				setHeight(height);
				onHeightChange?.(height);
			}}
			containerSx={{ display: "flex", flexDirection: "column" }}
			{...otherControls}
		>
			<>
				<Typography children={t("auditRooms.create")} variant="subtitle1" sx={{ paddingBottom: 6 }} />

				<FolderOrAuditRoomNamePicker
					folderName={auditRoomName}
					updateFolderName={setAuditRoomName}
					selectedColor={auditRoomColor}
					updateColor={setAuditRoomColor}
					isAuditRoom
					ColorPickerProps={{
						className: "flex-wrap md:flex-nowrap",
					}}
					textInputProps={{
						sx: {
							backgroundColor: (theme) => theme.palette.backgroundSecondary.default,
						},
						containerCss: { marginBottom: 12 },
					}}
				/>
				<Stack direction="row" justifyContent={"space-between"} alignItems="flex-end" sx={{ width: "100%", flexGrow: 1 }}>
					<Button variant="outlined" color="secondary" onClick={closeAndClear}>
						<CertiblokIcon size={24} color="secondary" name={"close"} />
						{t("global.cancel")}
					</Button>
					<LoadingIconButton
						loading={submitLoading}
						icon={<CertiblokIcon size={24} color="white" name={"success"} />}
						variant="contained"
						color="secondary"
						onClick={submit}
					>
						{t("global.createAuditRoom")}
					</LoadingIconButton>
				</Stack>
			</>
		</OldBottomSheet>
	);
};
export default CreateAuditRoomBS;
