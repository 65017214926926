import type { TFunction } from "react-i18next";
import type { AuditStatuses } from "../../../interfaces/AuditRoom";

export function switchTitle(t: TFunction, status?: AuditStatuses): string {
	switch (status) {
		case "approved":
			return t("variousComponents.approved");
		case "approved-with-reserve":
			return t("variousComponents.approvedWithReserve");
		case "rejected":
			return t("variousComponents.rejected");
		case "waiting":
			return t("variousComponents.waiting");
		case "revision":
			return t("variousComponents.inRevision");
		default:
			return t("auditRooms.withoutOpeningDate");
	}
}
