import { Button, useMediaQuery, useTheme } from "@mui/material";
import { t } from "i18next";
import { createElement, useState } from "react";
import LoadingIconButton from "../../../utils/components/buttons/LoadingIconButton";
import CertiblokIcon from "../../../utils/components/icons/CertiblokIcon";
import LoadingTextButton from "../../../utils/components/LoadingTextButton";
import {
	ModalNavigator,
	useModalNavigator,
	useModalRoute,
} from "../../../utils/components/ModalNavigator/ModalNavigator";
import MLDialog from "../../../utils/components/poppers";
import WhiteModal from "../../../utils/components/WhiteModal";

const DeleteDocumentsModal = ModalNavigator.create(
	({ onPositiveClick, isMultiple }: { onPositiveClick?: () => Promise<any>; isMultiple?: boolean }) => {
		const theme = useTheme();
		const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

		const { visible } = useModalRoute();
		const { dismissModal } = useModalNavigator();
		const [isLoading, setIsLoading] = useState(false);

		const onClose = () => {
			if (isLoading) return;
			dismissModal();
		};

		return (
			<WhiteModal
				open={visible}
				onClose={onClose}
				containerSx={{
					borderRadius: 10,
				}}
				responsive
			>
				<div className="flex flex-col gap-6">
					<div className="w-16 flex items-center justify-center h-16 md:w-[72px] md:h-[72px] bg-[rgba(252,147,1,0.12)] m-auto rounded-full">
						<CertiblokIcon size={isOverMd ? 48 : 40} color="orange" name={"comunication_outline_01"} />
					</div>
					<div className="text-center">
						<h6 className="text-h6 font-bold md:text-h4 md:font-normal pb-2">{t("global.attention")}</h6>
						<p className="text-caption font-normal md:text-h6 text-black-a60">
							{isMultiple ? t("allDocuments.moveToBinMultiple") : t("allDocuments.moveToBinSingle")}
						</p>
					</div>
					<div className="flex justify-between gap-3 mt-3">
						<Button
							children={t("global.cancel")}
							startIcon={isOverMd ? <CertiblokIcon size={24} color="inherit" name={"close"} /> : null}
							size={isOverMd ? "large" : "small"}
							variant={isOverMd ? "text" : "outlined"}
							color="primary"
							onClick={onClose}
							disabled={isLoading}
						/>
						{/*@ts-ignore*/}
						{createElement(isOverMd ? LoadingIconButton : LoadingTextButton, {
							children: t("global.moveToBin"),
							size: isOverMd ? "large" : "small",
							variant: "contained",
							loading: isLoading,
							onClick: async () => {
								setIsLoading(true);
								await onPositiveClick?.();
								MLDialog.showSnackbar(t("allDocuments.movingToBin"), {
									variant: "default",
								});
								setIsLoading(false);
								onClose();
							},
							color: "error",
							...(isOverMd && { icon: <CertiblokIcon size={24} color="inherit" name={"delete_bold"} /> }),
						})}
					</div>
				</div>
			</WhiteModal>
		);
	}
);

export default DeleteDocumentsModal;
