import { cn } from "@mabi-ui/utils";
import { Collapse, IconButton, Typography, useTheme } from "@mui/material";
import { useController, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CertiblokTextInput from "../../../../../../../utils/components/CertiblokTextInput";
import { AvailableFolderColors } from "../../../../../../Folders/constants/AvailableFolderColors";
import { EditRoomFormValues } from "../../../hooks/useEditRoomDetailsZodSchema";

type EditRoomCustomizationProps = {};

const EditRoomCustomization: React.FC<EditRoomCustomizationProps> = (props) => {
	const { t } = useTranslation();
	const theme = useTheme();

	const {
		register,
		formState: { errors },
	} = useFormContext<EditRoomFormValues>();

	const { field } = useController({
		name: "color",
	});

	const selectedColor = field.value;

	return (
		<div className="max-w-full flex flex-col gap-3 items-stretch">
			<CertiblokTextInput
				label={t("editRoom.editRoomName")}
				required
				placeholder={t("editRoom.editRoomName")}
				{...register("name")}
				status={errors.name ? "error" : undefined}
				helperText={errors.name?.message}
				color="secondary"
				InputProps={{
					...register("name"),
				}}
			/>
			<Typography component="p" children={t("global.color")} variant="label" className="-mb-3 mt-4" />
			<div className="w-full flex overflow-x-auto horizontal-scroll flex-wrap md:flex-nowrap">
				{AvailableFolderColors.map((elem, index) => {
					return (
						<IconButton
							key={index}
							onClick={() => {
								field.onChange(elem);
							}}
							sx={{
								color: elem,
								width: 48,
								height: 48,
							}}
						>
							<div
								className={cn(
									"w-6 h-6 rounded-full border-solid",
									elem === selectedColor ? "border-4" : "border-2 border-white"
								)}
								style={{
									borderColor: elem === selectedColor ? theme.palette.secondary.main : "#FFFFFF",
									backgroundColor: elem,
								}}
							/>
						</IconButton>
					);
				})}
			</div>
			<Collapse in={Boolean(errors.color)}>
				<Typography variant="caption" color="error">
					{t("editRoom.selectAColorForEditRoom")}
				</Typography>
			</Collapse>
		</div>
	);
};

export default EditRoomCustomization;
