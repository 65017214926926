import { useQuery } from "@tanstack/react-query";
import { DocumentRequestApi } from "certiblok-api-manager";
import { defaultQueryFilter, transformToStringFrom } from "../../../utils/components/tables/AdminTable";
import { useApi } from "../../../utils/hooks/api";

export const documentRequestsCountQueryKey = ["documentRequests", "count"];

export const useGetDocumentRequestsCount = () => {
	const documentRequests = useApi(DocumentRequestApi);

	return useQuery({
		queryKey: documentRequestsCountQueryKey,
		queryFn: () =>
			documentRequests.listDocumentRequests(
				"received",
				transformToStringFrom({ ...defaultQueryFilter, perPage: 1, filter: [{ read: { $ne: true } }] })
			),
		select: (res) => res.headers["x-total-count"],
	});
};
