import { useCallback, useState } from "react";

export function useModal() {
	const [open, setOpen] = useState(false);

	const toggleOpen = useCallback(() => {
		setOpen((prev) => !prev);
	}, []);

	return {
		open,
		toggleOpen,
	};
}
