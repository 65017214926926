import { Switch } from "@mabi-ui/switch";
import { Typography } from "@mui/material";
import { useController, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EditRoomFormValues } from "../../../../../hooks/useEditRoomDetailsZodSchema";

type EditRoomLockRoomProps = {};

const EditRoomLockRoom: React.FC<EditRoomLockRoomProps> = (props) => {
	const { t } = useTranslation();

	const { control } = useFormContext<EditRoomFormValues>();

	const { field } = useController({
		name: "lockEditRoomEnabled",
		control: control,
	});

	const isLocked = field.value;

	return (
		<div className="flex flex-row items-center gap-3">
			<div className="flex flex-col gap-2 items-stretch">
				<Typography variant="subtitle2">{t("editRoom.setEditRoomPrivate")}</Typography>
				<Typography className="body2">{t("editRoom.setEditRoomPrivateDescription")}</Typography>
			</div>
			<div>
				<Switch
					checked={isLocked}
					onChange={(checked) => {
						field.onChange(checked);
					}}
					color="primary"
				/>
			</div>
		</div>
	);
};

export default EditRoomLockRoom;
