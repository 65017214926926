import { Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useBetterMediaQuery } from "../../../utils/hooks/useBetterMediaQuery";
import sofaImage from "../../../assets/main_illustrations/couch.png";
import { useGetRecoverData } from "../hooks/useGetRecoverData";

const RecoverProfileIndex = () => {
	const { t } = useTranslation();
	const isMd = useBetterMediaQuery("md");

	const { data: me } = useGetRecoverData();
	return (
		<div className="box-border w-full h-full flex flex-col justify-between p-4 md:p-0">
			<div className="flex-1 flex flex-col">
				<Typography variant={isMd ? "h5" : "h6"} className="font-extrabold text-primary">
					{t("recoverProfile.recoverProfile")}
				</Typography>
				<div className="max-w-[290px] mx-auto mt-6">
					<img src={sofaImage} alt="" className="w-full object-contain" />
				</div>
				<Typography className="mt-6 text-black-a60" variant="body1">
					{t("recoverProfile.recoverDescription")}
				</Typography>
			</div>
			<div className="mt-8">
				<Button
					fullWidth
					variant="contained"
					sx={{ typography: "button" }}
					href={me ? "/recover-profile/confirm" : "/recover-profile/credentials"}
				>
					{t("recoverProfile.reactivateProfile")}
				</Button>
			</div>
		</div>
	);
};

export default RecoverProfileIndex;
