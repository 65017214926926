import { Box, Button, Divider, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { DocumentApi } from "certiblok-api-manager";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { AvailableFolderColors } from "../../../../../pages/Folders/constants/AvailableFolderColors";
import { getFileExtension } from "../../../../../pages/SingleDocument/functions/getFileExtension";
import { useApi } from "../../../../hooks/api";
import AvatarWithName from "../../../AvatarWithName";
import { ModalNavigator, useModalNavigator, useModalRoute } from "../../../ModalNavigator/ModalNavigator";
import ResponsiveDialog from "../../../dialogs/ResponsiveDialog";
import CertiblokIcon from "../../../icons/CertiblokIcon";
import { AssignedAuditsSection } from "./components/AssignedAuditsSection";
import EventTypeChip from "./components/EventTypeChip";
import { SharedWithSection } from "./components/SharedWithSection";

type AuditRoomDetailsModalProps = {
	eventId: string;
	onFullDetailClick?: () => void;
	onSuccess?: () => void;
};

export const DocumentDetailsModal = ModalNavigator.create<AuditRoomDetailsModalProps>(
	({ eventId, onFullDetailClick, onSuccess }) => {
		const { visible } = useModalRoute();
		const { popModal } = useModalNavigator();
		const { t } = useTranslation();

		const documentApi = useApi(DocumentApi);

		const onClose = () => {
			popModal();
			onSuccess?.();
		};

		const { data: document, isLoading } = useQuery({
			queryKey: ["documentDetail", eventId],
			queryFn: () => {
				return documentApi.getSingleDocument(eventId);
			},
			select: (data) => data.data,
		});

		return (
			<ResponsiveDialog
				open={visible}
				onClose={onClose}
				header={
					<div className="flex w-full items-center justify-between">
						{isLoading ? (
							<div className="bg-gray-200 animate-pulse rounded-md min-w-[100px] h-6" />
						) : (
							<Typography variant="subtitle1">{document?.name}</Typography>
						)}
						<EventTypeChip eventType="document" />
					</div>
				}
				ModalProps={{
					responsive: true,
				}}
				footer={
					<>
						<Divider className="mb-6 md:mt-3" />
						<div className="flex justify-between items-center">
							<Button
								variant="outlined"
								color="secondary"
								startIcon={<CertiblokIcon name="close" color="secondary" />}
								onClick={() => onClose()}
							>
								{t("global.close")}
							</Button>
							<Button
								variant="contained"
								color="secondary"
								startIcon={<CertiblokIcon name="arrow_direction_outline_right" color="white" />}
								disabled={isLoading}
								href={
									isLoading
										? undefined
										: document?.parentFolderId
										? `/folders/${document?.parentFolderId || ""}/document/${document?.id}`
										: `/inbox/document/${document?.id}`
								}
								onClick={() => {
									popModal();
									onFullDetailClick?.();
								}}
							>
								{t("event.fullDetail")}
							</Button>
						</div>
					</>
				}
			>
				<div className="px-6 md:px-0 pb-0 flex-1 box-border md:max-h-[60vh] md:overflow-auto">
					<div className="md:mt-8 mt-2 grid grid-cols-[1fr,1fr] gap-x-2 gap-y-7 md:p-3 ">
						<div>
							<Typography variant="subtitle1" className="mb-2">
								{t("documents.owner")}
							</Typography>
							{isLoading ? (
								<div className="bg-gray-200 animate-pulse rounded-md min-w-[50px] h-5" />
							) : (
								<AvatarWithName user={document?.owner} variant="body2" />
							)}
						</div>
						<div>
							<Typography variant="subtitle1" className="mb-2">
								{t("documents.visualizedVersion")}
							</Typography>
							{isLoading ? (
								<div className="bg-gray-200 animate-pulse rounded-md min-w-[50px] h-5" />
							) : (
								<div className="flex gap-2 items-center">{`V${document?.lastVersionFile?.[0].version}`}</div>
							)}
						</div>
						<div>
							<Typography variant="subtitle1" className="mb-2">
								{t("documents.creationDate")}
							</Typography>
							<Typography variant="body2">
								{isLoading ? (
									<div className="bg-gray-200 animate-pulse rounded-md min-w-[150px] h-5" />
								) : document?.createdAt ? (
									DateTime.fromISO(document?.createdAt).toFormat("dd/MM/yyyy")
								) : (
									"-"
								)}
							</Typography>
						</div>
						<div>
							<Typography variant="subtitle1" className="mb-2">
								{t("documents.dateOfExpiration")}
							</Typography>
							<Typography variant="body2">
								{isLoading ? (
									<div className="bg-gray-200 animate-pulse rounded-md min-w-[150px] h-5" />
								) : document?.expireDate ? (
									DateTime.fromISO(document?.expireDate).toFormat("dd/MM/yyyy")
								) : (
									"-"
								)}
							</Typography>
						</div>
						<div>
							<Typography variant="subtitle1" className="mb-2">
								{t("documents.fileType")}
							</Typography>
							<Typography variant="body2">
								{isLoading ? (
									<div className="bg-gray-200 animate-pulse rounded-md min-w-[70px] h-5" />
								) : (
									getFileExtension(t, document?.files?.[0])
								)}
							</Typography>
						</div>
						<div>
							<Typography variant="subtitle1" className={isLoading ? "mb-2" : "mb-1"}>
								{t("documents.folderMembership")}
							</Typography>
							{isLoading ? (
								<div className="bg-gray-200 animate-pulse rounded-md min-w-[150px] h-5" />
							) : (
								document?.parentFolder && (
									<div className="flex gap-1 items-center">
										<CertiblokIcon name="folder_bold" color={document.parentFolder?.color ?? AvailableFolderColors[0]} />
										<Typography variant="body2">{document.parentFolder?.name}</Typography>
									</div>
								)
							)}
							<Typography variant="body2">{}</Typography>
						</div>
					</div>

					<div className="md:p-3 mt-6 md:mt-0">
						<Typography variant="subtitle1" className="mb-2">
							{t("documents.activeShares")}
						</Typography>
						{isLoading ? (
							<div className="flex gap-2 flex-wrap">
								{[100, 120, 200].map((elem) => (
									<Box
										className="bg-gray-200 animate-pulse rounded-xl h-6"
										sx={{ width: `${elem}px` }}
										key={`skeleton${elem}`}
									/>
								))}
							</div>
						) : (
							<SharedWithSection users={document?.sharedWith ?? []} />
						)}
					</div>
					<div className="md:p-3 mt-6 md:mt-0">
						<Typography variant="subtitle1" className="mb-2">
							{t("global.assignedAuditRooms")}
						</Typography>
						{isLoading ? (
							<div className="flex gap-2 flex-wrap">
								{[80, 100].map((elem) => (
									<Box
										className="bg-gray-200 animate-pulse rounded-xl h-6"
										sx={{ width: `${elem}px` }}
										key={`skeleton${elem}`}
									/>
								))}
							</div>
						) : (
							<AssignedAuditsSection audits={document?.audits ?? []} />
						)}
					</div>
				</div>
			</ResponsiveDialog>
		);
	}
);
