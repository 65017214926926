import axios from "axios";
import { DocumentApi, DocumentmanagerApi, EditRoomApi } from "certiblok-api-manager";
import { useAtomValue } from "jotai";
import mime from "mime";
import { useCallback } from "react";
import { instanceApi } from "../../../hooks/api";
import { selectedFolderAtom } from "../atoms/uploadDocumentAtoms";
import { useUploadDocumentContext } from "../contexts/UploadDocumentContext";
import {
	getFileToUploadById,
	useEditFileToUpload,
	useFilesToUpload,
	useRemoveFileToUpload,
} from "../stores/filesToUploadStore";
import { useAddUploadedFile } from "../stores/uploadedFilesStore";

export function useUploadFiles() {
	const removeFileToUpload = useRemoveFileToUpload();
	const editFileToUpload = useEditFileToUpload();
	const addUploadedFile = useAddUploadedFile();
	const filesToUpload = useFilesToUpload();
	const selectedFolder = useAtomValue(selectedFolderAtom);

	const { type } = useUploadDocumentContext();
	const isEditRoom = type === "uploadEditRoomDocument";

	return useCallback(async () => {
		if (selectedFolder) {
			try {
				await Promise.all(
					filesToUpload?.map(async (file, index) => {
						if (file.uploaded) {
							return;
						}

						try {
							const cancelToken = axios.CancelToken.source();

							editFileToUpload(file.id, {
								cancelToken: cancelToken,
								sendingChunks: true,
								loading: { isLoading: true, percentage: 0 },
								uploaded: false,
							});

							const {
								data: { uploadUrl, fileUrl },
							} = await instanceApi(DocumentmanagerApi).getUploadSignedUrlDocument(
								{
									fileName: file.file.name,
									size: file.file.size,
								},
								{
									cancelToken: cancelToken.token,
								}
							);

							await axios.put(uploadUrl, file.file, {
								cancelToken: cancelToken.token,
								onUploadProgress: (data) => {
									const uploadingFile = getFileToUploadById(file.id);
									const percentage = (data.loaded / data.total) * 100;
									const prevPercentage = uploadingFile.loading.percentage;

									editFileToUpload(file.id, {
										cancelToken: cancelToken,
										sendingChunks: percentage < 100,
										loading: { isLoading: true, percentage: percentage < 100 ? percentage : prevPercentage || 99 },
										uploaded: false,
									});
								},
								headers: {
									"Content-Type": "",
								},
							});

							const value = await (isEditRoom
								? instanceApi(EditRoomApi).createEditRoomDocument({
										parentFolderId: selectedFolder,
										uploadedFile: {
											mimetype: file.file.type || mime.getType(file.file.name) || "",
											originalname: file.file.name,
											size: file.file.size,
											url: fileUrl,
										},
								  })
								: instanceApi(DocumentApi).createDocumentLimitless({
										parentFolderId: selectedFolder,
										uploadedFile: {
											mimetype: file.file.type || mime.getType(file.file.name) || "",
											originalname: file.file.name,
											size: file.file.size,
											url: fileUrl,
										},
								  }));

							editFileToUpload(file.id, {
								loading: { isLoading: false, percentage: 100 },
								uploaded: true,
								documentId: value.data.id,
							});
							addUploadedFile({
								document: { ...value.data, audits: [], reviews: [] },
								draft: {
									documentName: value.data.name,
								},
							});
						} catch (err: any) {
							if (err.message === "canceled") {
								removeFileToUpload(file.id);
							} else {
								editFileToUpload(file.id, {
									loading: { isLoading: false, percentage: 0 },
									error: err?.response?.data?.message ?? true,
								});
							}
						}
					})
				);
			} catch (e) {
				console.log(e);
			}
		}
	}, [addUploadedFile, editFileToUpload, filesToUpload, removeFileToUpload, selectedFolder, isEditRoom]);
}
