import { AppBar, AppBarProps, styled } from "@mui/material";

interface StyledAppBarProps extends AppBarProps {
	open?: boolean;
}

export const StyledAppBar = styled(AppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})<StyledAppBarProps>(({ theme, open }) => ({
	backgroundColor: "unset",
	transition: theme.transitions.create(["width", "height"]),
	// ...(open && {
	// 	width: `calc(100% - ${LG_DRAWER_OPEN_WIDTH}px)`,
	// }),
	// ...(!open && {
	// 	width: `calc(100% - ${LG_DRAWER_CLOSED_WIDTH}px)`,
	// }),
}));
export default StyledAppBar;
