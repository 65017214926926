import { useQuery } from "@tanstack/react-query";
import { DocumentApi } from "certiblok-api-manager";
import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import MLDialog from "../../../../../utils/components/poppers";
import {
	defaultQueryFilter,
	IQueryFilter,
	transformToObjectFrom,
	transformToStringFrom,
} from "../../../../../utils/components/tables/AdminTable";
import { apiErrorParser, useApi } from "../../../../../utils/hooks/api";
import { CertiblokDocument } from "../../../../../utils/interfaces/Document";

const allDocumentsQueryKeys = {
	base: ["documents", "list"],
	all: (filters?: IQueryFilter) => [...allDocumentsQueryKeys.base, "all", filters],
	shared: (filters?: IQueryFilter) => [...allDocumentsQueryKeys.base, "shared", filters],
	byTag: (tagIds?: string, filters?: IQueryFilter) => [...allDocumentsQueryKeys.base, tagIds, filters],
};

export function useDocumentsByTagContext() {
	//* APIS
	const documentApi = useApi(DocumentApi);
	const { id: tagId } = useParams();
	const [searchParams] = useSearchParams();

	const [queryFilter, setQueryFilter] = useState<IQueryFilter>(() => {
		const queryFilterString = searchParams.get("queryFilter");
		return !queryFilterString
			? ({ ...defaultQueryFilter, orderBy: "name", order: "asc", filter: [{ tagIds: { $in: [tagId] } }] } as IQueryFilter)
			: transformToObjectFrom(searchParams.get("queryFilter") || "{}");
	});

	const {
		data: allDocumentsQuery,
		isLoading: allDocumentsLoading,
		isFetching: allDocumentsFetching,
	} = useQuery({
		queryKey: allDocumentsQueryKeys.byTag(JSON.stringify([tagId]), queryFilter),
		queryFn: () => documentApi.listAllDocuments(transformToStringFrom({ ...queryFilter })),
		onError: (e) => {
			MLDialog.showSnackbar(apiErrorParser(e), {
				variant: "error",
			});
		},
		keepPreviousData: true,
		select: (res) => {
			return {
				...res,
				data: res.data as CertiblokDocument[],
				totalDocumentsCount: parseInt(res?.headers?.["x-total-count"]),
			};
		},
	});

	return {
		queryFilter,
		setQueryFilter,
		allDocumentsQuery,
		allDocumentsLoading,
		allDocumentsFetching,
	};
}
