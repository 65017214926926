import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import { EditRoomApi, Folder, FolderApi } from "certiblok-api-manager";
import MLDialog from "../../../../../utils/components/poppers";
import { apiErrorParser, instanceApi, useApi } from "../../../../../utils/hooks/api";

type editCreateFolderInput = {
	folderName: string;
	folderColor: string;
	reservedToUserIds?: string[];
	reservedToTeamIds?: string[];
} & ({ isEdit: true; folderId: string; parentId?: never } | { isEdit: false; parentId?: string; folderId?: never });

const editCreateFolderModalQueries = createQueryKeys("folder", {
	parentFolder: (folderId: string) => [folderId],
	parentEditRoomFolder: (folderId: string) => [folderId, "editRoom"],
});

export function useEditCreateFolder() {
	const folderApi = useApi(FolderApi);
	return useMutation(
		({
			isEdit,
			folderId,
			folderColor,
			folderName,
			reservedToUserIds,
			parentId,
			reservedToTeamIds,
		}: editCreateFolderInput) => {
			// console.log(folderName, folderColor, reservedToUserIds, parentId);
			return isEdit
				? folderApi.editFolder(folderId, {
						name: folderName,
						color: folderColor,
						reservedToUserIds: reservedToUserIds,
						reservedToTeamIds: reservedToTeamIds,
				  })
				: folderApi.createFolder({
						name: folderName,
						color: folderColor,
						reservedToUserIds: reservedToUserIds,
						parentFolderId: parentId,
						reservedToTeamIds: reservedToTeamIds,
				  });
		},
		{
			onError: (error) => {
				MLDialog.showSnackbar(apiErrorParser(error), { variant: "error" });
			},
		}
	);
}

export function useEditCreateEditRoomFolder() {
	return useMutation(
		({
			isEdit,
			folderId,
			folderColor,
			folderName,
			reservedToUserIds,
			parentId,
			reservedToTeamIds,
		}: editCreateFolderInput) => {
			return isEdit
				? instanceApi(EditRoomApi).editEditRoomFolder(folderId, {
						name: folderName,
						color: folderColor,
						reservedToUserIds: reservedToUserIds,
						reservedToTeamIds: reservedToTeamIds,
				  })
				: instanceApi(EditRoomApi).createEditRoomFolder({
						name: folderName,
						color: folderColor,
						reservedToUserIds: reservedToUserIds,
						parentFolderId: parentId,
						reservedToTeamIds: reservedToTeamIds,
				  });
		},
		{
			onError: (error) => {
				MLDialog.showSnackbar(apiErrorParser(error), { variant: "error" });
			},
		}
	);
}

export function useParentFolder(
	parentFolderId?: string,

	config?: { isInEditRoom: boolean; onSuccess?: (data: AxiosResponse<Folder>) => void; enabled?: boolean }
) {
	const { isInEditRoom, enabled, ..._config } = config ?? {};

	// const location = useLocation();
	// const isEditRoom = location?.pathname?.includes("/edit_rooms");
	// console.log("calling useParentFolder", isEditRoom);

	const folderQuery = useQuery({
		queryKey: editCreateFolderModalQueries.parentFolder(parentFolderId ?? "").queryKey,
		queryFn: () => {
			return instanceApi(FolderApi).getPlainFolder(parentFolderId ?? "");
		},
		enabled: (enabled ?? true) && !!parentFolderId && !isInEditRoom,
		onError: (error) => {
			MLDialog.showSnackbar(apiErrorParser(error), { variant: "error" });
		},
		..._config,
	});

	const editRoomFolderQuery = useQuery({
		queryKey: editCreateFolderModalQueries.parentEditRoomFolder(parentFolderId ?? "").queryKey,
		queryFn: () => {
			return instanceApi(EditRoomApi).getPlainEditRoomFolder(parentFolderId ?? "");
		},
		enabled: (enabled ?? true) && !!parentFolderId && isInEditRoom,
		onError: (error) => {
			MLDialog.showSnackbar(apiErrorParser(error), { variant: "error" });
		},
		..._config,
	});

	return isInEditRoom ? editRoomFolderQuery : folderQuery;
}
