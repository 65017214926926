import { alpha, Box, Stack, SxProps, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CertiblokIcon from "../../../../../utils/components/icons/CertiblokIcon";

type AlreadyInLockedFolderBannerProps = { sx?: SxProps<Theme> };
const AlreadyInLockedFolderBanner: React.FC<AlreadyInLockedFolderBannerProps> = ({ sx }) => {
	const { t } = useTranslation();

	return (
		<Stack
			direction="row"
			gap={6}
			alignItems="center"
			sx={{ borderRadius: 6, backgroundColor: (theme) => alpha(theme.palette.orange.main, 0.1), padding: 4, ...sx }}
		>
			<CertiblokIcon size={32} color="secondary" name={"profile_outline_01"} />
			<Box>
				<Typography children={t("folders.alreadyInPrivateFolder")} variant="subtitle2" />
				<Typography children={t("folders.alreadyInPrivateFolderDescription")} variant="caption" />
			</Box>
		</Stack>
	);
};
export default AlreadyInLockedFolderBanner;
