import { Box, Button, Divider, Popover, PopoverActions, Typography } from "@mui/material";
import { isMobile } from "react-device-detect";
import { NonUndefined, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import BottomSheet from "../../../../../../../utils/components/bottomSheet/BottomSheet";
import CertiblokIcon from "../../../../../../../utils/components/icons/CertiblokIcon";
import { EditCreateEventForm } from "../../../hooks/useEditCreateEventFormValidator";
import CustomizeReminderForm from "./components/CustomizeReminderForm";
import { useRef } from "react";
import useResizeObserver from "use-resize-observer";

type CustomEventReminderProps = {
	anchorEl?: HTMLElement | null;
	open: boolean;
	onClose: () => void;
	onSuccess: (value: EditCreateEventForm["reminder"]) => void;
	initialValue?: EditCreateEventForm["reminder"];
};
const CustomEventReminder: React.FC<CustomEventReminderProps> = ({
	open,
	onClose: _onClose,
	anchorEl,
	onSuccess,
	initialValue: _initialValue,
}) => {
	const { t } = useTranslation();

	const initialFormValue: EditCreateEventForm["reminder"] = _initialValue ?? {
		amount: 1,
		unit: "hours",
	};

	const form = useForm<NonUndefined<EditCreateEventForm["reminder"]>>({
		defaultValues: initialFormValue,
	});

	const onClose = () => {
		_onClose();
		form.reset(initialFormValue);
	};

	const onSubmit = (data: typeof initialFormValue) => {
		onSuccess?.(data);
		_onClose();
	};

	const popoverActionRef = useRef<PopoverActions>(null);

	const { ref: popoverContentRef } = useResizeObserver({
		onResize: ({ height }) => {
			popoverActionRef.current?.updatePosition();
		},
	});

	return !isMobile ? (
		<Popover
			action={popoverActionRef}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
			open={open}
			onClose={onClose}
			anchorEl={anchorEl}
		>
			<Box
				ref={popoverContentRef}
				className="p-6 rounded-3xl"
				sx={{
					boxSizing: "border-box",
					backgroundColor: "surface.main",
					display: "flex",
					flexDirection: "column",
					borderRadius: 3,
					minWidth: 150,
				}}
			>
				<form
					onSubmit={(ev) => {
						ev.stopPropagation();
						ev.preventDefault();
						form.handleSubmit(onSubmit)();
					}}
					className="flex flex-col gap-6"
				>
					<Typography variant="subtitle1">{t("events.customizeReminder")}</Typography>

					<CustomizeReminderForm form={form} />
					<Divider />
					<div className="flex gap-3 items-center justify-between">
						<Button
							onClick={() => {
								onClose();
							}}
							variant="outlined"
							color="secondary"
						>
							<CertiblokIcon size={24} color="inherit" name={"close"} />
							{t("global.cancel")}
						</Button>
						<Button variant="contained" type="submit" color="secondary">
							<CertiblokIcon size={24} color="inherit" name={"success"} />
							{t("events.repetition.confirmCustomization")}
						</Button>
					</div>
				</form>
			</Box>
		</Popover>
	) : (
		<BottomSheet
			header={
				<>
					<Typography variant="subtitle1">{t("events.customizeReminder")}</Typography>
				</>
			}
			footer={
				<>
					<Divider className="mb-6" />
					<div className="flex gap-3 items-center justify-between">
						<Button
							onClick={() => {
								onClose();
							}}
							variant="outlined"
							color="secondary"
						>
							<CertiblokIcon size={24} color="inherit" name={"close"} />
							{t("global.cancel")}
						</Button>
						<Button
							onClick={() => {
								form.handleSubmit(onSubmit)();
							}}
							variant="contained"
							type="submit"
							color="secondary"
						>
							<CertiblokIcon size={24} color="inherit" name={"success"} />
							{t("events.repetition.confirmCustomization")}
						</Button>
					</div>
				</>
			}
			open={open}
			onClose={onClose}
		>
			<form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-6 px-5 md:px-12">
				<CustomizeReminderForm form={form} />
			</form>
		</BottomSheet>
	);
};
export default CustomEventReminder;
