import type { Audit } from "certiblok-api-manager";
import { useAtom } from "jotai";
import { useCallback, useMemo, type CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import ReactVirtualizedAutoSizer from "react-virtualized-auto-sizer";
import { Virtuoso } from "react-virtuoso";
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { useBetterMediaQuery } from "../../../../hooks/useBetterMediaQuery";
import AuditRoomSelector from "../../../auditRooms/AuditRoomSelector";
import Loading from "../../../Loading";
import NoContents from "../../../NoContents";
import { selectedAuditRoomIdsAtom } from "../atoms/selectedAuditRoomIdsAtom";
import { useInfiniteAuditList } from "../queries/auditRoomsListInfiniteQuery";
import GroupedAuditRooms from "./GroupedAuditRooms";

type AuditRoomListProps = {};

export const GROUP_SIZE = 8;
export const BOTTOM_MARGIN = 50;
export const LIST_HEIGHT = (32 + 8) * GROUP_SIZE + BOTTOM_MARGIN;
export const ITEM_WIDTH = 300 + 48;

const AuditRoomList: React.FC<AuditRoomListProps> = () => {
	const { t } = useTranslation();
	const isMd = useBetterMediaQuery("md");

	const { data, hasNextPage, isFetching, isLoading, fetchNextPage } = useInfiniteAuditList();
	const availableAuditRooms = useMemo(() => {
		return data?.pages?.reduce((acc, page) => [...acc, ...page.data], [] as Audit[]) ?? [];
	}, [data]);

	const groupedAuditRooms = useMemo(() => {
		const groups: Audit[][] = [];
		for (let i = 0; i < availableAuditRooms.length; i += GROUP_SIZE) {
			groups.push(availableAuditRooms.slice(i, i + GROUP_SIZE));
		}
		return groups;
	}, [availableAuditRooms]);

	const itemCount = useMemo(
		() => (hasNextPage || isFetching ? groupedAuditRooms.length + 1 : groupedAuditRooms.length),
		[groupedAuditRooms.length, hasNextPage, isFetching]
	);

	const isItemLoaded = useCallback(
		(index: number) => !hasNextPage || isFetching || index < groupedAuditRooms.length,
		[hasNextPage, isFetching, groupedAuditRooms.length]
	);

	const renderCallback = useCallback(
		({ index, data, style }: { index: number; style: CSSProperties; data: Audit[][] }) =>
			index >= data.length ? (
				<div style={style} className="flex h-full items-center justify-start">
					<Loading title={t("auditRooms.loading")} />
				</div>
			) : (
				<div className="" style={style}>
					<GroupedAuditRooms auditRooms={data[index]} />
				</div>
			),
		[t]
	);

	const [selectedAuditRoomIds, setSelectedAuditRoomIds] = useAtom(selectedAuditRoomIdsAtom);

	return (
		<>
			{isLoading ? (
				<Loading title={t("auditRooms.loading")} sx={{ height: { xs: "40vh", md: 350 } }} />
			) : availableAuditRooms.length === 0 ? (
				<NoContents
					variant="lock"
					title={t("auditRooms.noAuditRoomsYet")}
					sx={{ height: { xs: "40vh", md: 350 }, justifyContent: "center" }}
				/>
			) : isMd ? (
				<ReactVirtualizedAutoSizer disableHeight>
					{({ width }) => (
						<InfiniteLoader
							isItemLoaded={isItemLoaded}
							itemCount={itemCount}
							loadMoreItems={() => {
								if (hasNextPage && !isFetching) {
									fetchNextPage();
								}
							}}
						>
							{({ onItemsRendered, ref }) => (
								<List
									height={LIST_HEIGHT}
									itemCount={itemCount}
									itemSize={ITEM_WIDTH}
									onItemsRendered={onItemsRendered}
									ref={ref}
									width={width}
									itemData={groupedAuditRooms}
									layout="horizontal"
									className="horizontal-scroll"
								>
									{renderCallback}
								</List>
							)}
						</InfiniteLoader>
					)}
				</ReactVirtualizedAutoSizer>
			) : (
				<Virtuoso
					style={{ height: "40vh" }}
					data={availableAuditRooms}
					endReached={() => {
						if (hasNextPage && !isFetching) {
							fetchNextPage();
						}
					}}
					components={{
						Footer: () => {
							if (isFetching) {
								return <Loading title={t("auditRooms.loading")} />;
							}
							return null;
						},
					}}
					itemContent={(index, auditRoom) => {
						return (
							<div className="mb-2" key={auditRoom.id}>
								<AuditRoomSelector
									auditRoom={auditRoom}
									isSelected={selectedAuditRoomIds.includes(auditRoom.id)}
									toggleSelected={() => {
										setSelectedAuditRoomIds((prev) => {
											if (prev.includes(auditRoom.id)) {
												return prev.filter((id) => id !== auditRoom.id);
											} else {
												return [...prev, auditRoom.id];
											}
										});
									}}
								/>
							</div>
						);
					}}
				/>
			)}
		</>
	);
};
export default AuditRoomList;
