import { cn } from "@mabi-ui/utils";
import GlobalCalendar from "../../utils/components/globalCalendar/GlobalCalendar";
import OutletContainer from "../../utils/components/OutletContainer";
import { useBetterMediaQuery } from "../../utils/hooks/useBetterMediaQuery";
import BigCardsGrid from "./components/BigCardsGrid";
import FavoritesSection from "./components/FavoritesSection";
import SmallCardsGrid from "./components/SmallCardsGrid";
import UserAndTeamCard from "./components/UserAndTeamCard";

const Dashboard = () => {
	const isLg = useBetterMediaQuery("lg");

	return (
		<OutletContainer
			scrollDisabled
			noPadding
			sx={{
				display: "flex",
				py: "0px !important",
				paddingRight: { md: "0px !important" },
				position: "relative",
				gap: 6,
				overflow: "hidden",
			}}
		>
			<div className={cn("w-full h-full overflow-y-scroll px-6 py-6 lg:py-10 box-border", isLg && "pr-0")}>
				<UserAndTeamCard />
				<SmallCardsGrid />
				<BigCardsGrid />
				<FavoritesSection />
			</div>
			{isLg && <GlobalCalendar />}
		</OutletContainer>
	);
};

export default Dashboard;
