import { i18n } from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "usehooks-ts";

export function getPlatformLanguageFromI18n(i18n: i18n) {
	if (i18n.language === "it" || i18n.language === "it-IT" || i18n.language === "it-CH") {
		return 0;
	}
	return 1;
}

const useLanguageSwitcher = () => {
	const { i18n } = useTranslation();

	const [selectedLanguageId] = useLocalStorage("platform-language", getPlatformLanguageFromI18n(i18n));
	useEffect(() => {
		i18n.changeLanguage(selectedLanguageId === 0 ? "it" : "en");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedLanguageId]);
};
export default useLanguageSwitcher;

export const useSelectedLanguage = () => {
	const { i18n } = useTranslation();

	const [selectedLanguageId] = useLocalStorage("platform-language", getPlatformLanguageFromI18n(i18n));
	return selectedLanguageId === 0 ? "it" : "en";
};
