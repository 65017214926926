import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CertiblokIcon from "../../../utils/components/icons/CertiblokIcon";

const SyncFolderInstructions: React.FC = () => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col gap-4">
			<Typography variant="h6" className="font-bold">
				{t("syncFolder.howToUseTitle")}
			</Typography>
			<Typography>{t("syncFolder.howToUseDescription")}</Typography>
			<div className="flex flex-row items-start md:items-center gap-4">
				<div className="flex flex-row items-center justify-center w-[36px] md:w-[72px] h-[36px] md:h-[72px] bg-primary-a12 rounded-full">
					<CertiblokIcon name="document_outline" className="text-secondary-dark text-[24px] md:text-[40px]" />
				</div>
				<div className="flex flex-col gap-1 flex-1">
					<Typography variant="subtitle1">{t("syncFolder.keepYourFilesUpdated")}</Typography>
					<Typography>{t("syncFolder.keepYourFilesUpdatedDescription")}</Typography>
				</div>
			</div>
			<div className="flex flex-row items-start md:items-center gap-4">
				<div className="flex flex-row items-center justify-center w-[36px] md:w-[72px] h-[36px] md:h-[72px] bg-primary-a12 rounded-full">
					<CertiblokIcon name="shield_outline_02" className="text-secondary-dark text-[24px] md:text-[40px]" />
				</div>
				<div className="flex flex-col gap-1 flex-1">
					<Typography variant="subtitle1">{t("syncFolder.automaticBackup")}</Typography>
					<Typography>{t("syncFolder.automaticBackupDescription")}</Typography>
				</div>
			</div>
		</div>
	);
};

export default SyncFolderInstructions;
