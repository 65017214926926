import { PlanApi } from "certiblok-api-manager";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { usePlanUsage } from "../../../../../utils/components/scaffold/components/Drawer/components/SidebarUsage/hooks/usePlanUsage";
import MLDialog from "../../../../../utils/components/poppers";
import { apiErrorParser, useApi, useFetch } from "../../../../../utils/hooks/api";
import useSocket from "../../../../../utils/hooks/useSocket";
import { AvailablePlans } from "../../../constants/AvailablePlans";
import type { SinglePlan } from "../../../interfaces/SinglePlan";
import { useActivePlan } from "../queries/plansQueries";

export const usePlans = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const shouldRevalidateUser = searchParams.get("revalidateUser") === "true";
	const [hasRevalidatedUser, setHasRevalidatedUser] = useState(false);
	const planApi = useApi(PlanApi);
	const { data, loading: plansLoading, error } = useFetch(planApi.getAllPlans);

	const { data: activePlan, isLoading: activePlanLoading, refetch: refetchActivePlan } = useActivePlan();
	const { planUsage, planUsageLoading } = usePlanUsage();

	useSocket("updatedCompanyPlan", () => {
		if (shouldRevalidateUser) {
			setHasRevalidatedUser(true);
			setSearchParams({}, { replace: true });
			refetchActivePlan();
		}
	});

	const plans: SinglePlan[] = useMemo(() => {
		return [
			{ id: "-1", monthlyPrice: 0, yearlyPrice: 0, ...AvailablePlans[0] },
			...[...(data ?? [])]
				//@ts-ignore
				.sort((a, b) => parseInt(a.metadata?.plan) - parseInt(b.metadata?.plan))
				.map((elem) => {
					//@ts-ignore
					const planIndex = parseInt(elem.metadata?.plan) + 1;
					return {
						id: elem?.id,
						monthlyPrice: elem?.prices?.find((price) => price?.recurring?.interval === "month")?.unit_amount ?? 0,
						yearlyPrice: elem?.prices?.find((price) => price?.recurring?.interval === "year")?.unit_amount ?? 0,
						hasSyncFolder: Boolean(elem?.metadata?.sync),
						...AvailablePlans[planIndex >= AvailablePlans.length ? AvailablePlans.length : planIndex],
					};
				}),
		];
	}, [data]);

	useEffect(() => {
		if (error) {
			MLDialog.showSnackbar(apiErrorParser(error), { variant: "error" });
		}
	}, [error]);

	const isOnFreePlan = (activePlan === undefined && !activePlanLoading) || activePlan?.paidRecurrency === undefined;
	const isOnDynamicPlan = activePlan?.isDynamic ?? false;

	return {
		plans,
		plansLoading: plansLoading || activePlanLoading || planUsageLoading,
		activePlan,
		hasRevalidatedUser,
		shouldRevalidateUser,
		planUsage,
		isOnFreePlan,
		isOnDynamicPlan,
	};
};
