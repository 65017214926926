import { autoUpdate, flip, FloatingPortal, offset, size as floatingSize, useFloating } from "@floating-ui/react";
import { Spinner } from "@mabi-ui/spinner";
import { cn } from "@mabi-ui/utils";
import { TextField, TextFieldProps, Typography, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { CompanyApi, Team, TeamsApi, User } from "certiblok-api-manager";
import { useCombobox } from "downshift";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import CertiblokIcon from "../../../../../utils/components/icons/CertiblokIcon";
import TeamAutocompleteOption from "../../../../../utils/components/TeamAutocompleteOption";
import UserAutocompleteOption from "../../../../../utils/components/UserAutocompleteOption";
import { useApi } from "../../../../../utils/hooks/api";

type TeamMembersGuestGroupsAutocomplete = {
	onSelectUser?: (user: User) => void;
	onSelectGroup?: (group: Team) => void;
	includeMembers?: boolean;
	includeGuests?: boolean;
	includeGroups?: boolean;
	includeOwner?: boolean;
	disabledUsersIds?: string[];
	disabledGroupsIds?: string[];
	TextFieldProps?: TextFieldProps;
	className?: string;
	optionsClassName?: string;
};

const isUser = (userOrTeam: User | Team): userOrTeam is User => {
	return (userOrTeam as User).surname !== undefined;
};

type UserOrGuest = User & { isGuest?: boolean };

const TeamMembersGuestsGroupsAutocomplete = ({
	includeMembers = true,
	includeGuests = true,
	includeGroups = false,
	includeOwner = false,
	onSelectUser,
	onSelectGroup,
	disabledUsersIds = [],
	disabledGroupsIds = [],
	TextFieldProps,
	className,
	optionsClassName,
}: TeamMembersGuestGroupsAutocomplete) => {
	const { t } = useTranslation();
	const theme = useTheme();

	const companyApi = useApi(CompanyApi);
	const teamsApi = useApi(TeamsApi);

	const [filteredOptions, setFilteredOptions] = useState<(UserOrGuest | Team)[]>([]);

	const { data: members, isLoading } = useQuery({
		queryKey: ["company", "admins"],
		queryFn: async () => {
			const { data: company } = await companyApi.getCompanyWithMembers();
			const { data: guests } = includeGuests ? await companyApi.getAllCompanyAdmins() : { data: [] as User[] };
			const { data: groups } = includeGroups ? await teamsApi.getAllCompanyTeams() : { data: [] as Team[] };
			const res: (UserOrGuest | Team)[] = [
				...groups,
				...(includeMembers ? company.members ?? [] : []),
				...guests.map((g) => ({ ...g, isGuest: true })),
			].filter((m) => (includeOwner ? true : m.id !== company?.ownerId));
			return res;
		},
		onSuccess: (res) => {
			setFilteredOptions(res);
		},
		initialData: [],
	});

	const onInputChange = useCallback(
		(value: string) => {
			if (value === "") {
				setFilteredOptions(members);
			} else {
				setFilteredOptions(() => {
					return members.filter((elem) => {
						if (isUser(elem)) {
							return (
								elem.name.toLowerCase().includes(value.toLowerCase()) ||
								elem.surname.toLowerCase().includes(value.toLowerCase()) ||
								elem.email.toLowerCase().includes(value.toLowerCase())
							);
						} else {
							return elem.name.toLowerCase().includes(value.toLowerCase());
						}
					});
				});
			}
		},
		[members]
	);

	const { refs, floatingStyles } = useFloating({
		placement: "bottom",
		whileElementsMounted: autoUpdate,
		middleware: [
			offset(4),
			flip({ padding: 10 }),
			floatingSize({
				apply({ rects, elements }) {
					Object.assign(elements.floating.style, {
						width: `${rects.reference.width}px`,
					});
				},
			}),
		],
	});

	const { isOpen, getInputProps, getItemProps, getMenuProps, highlightedIndex } = useCombobox({
		items: filteredOptions,
		onInputValueChange: ({ inputValue }) => {
			onInputChange(inputValue);
		},
		itemToString: (item) => "",
		onSelectedItemChange: ({ selectedItem }) => {
			if (isUser(selectedItem)) {
				onSelectUser?.(selectedItem);
			} else {
				onSelectGroup?.(selectedItem);
			}
		},
	});

	return (
		<div className={cn("relative", className)} ref={refs.setReference}>
			<TextField
				fullWidth
				variant="standard"
				size={"small"}
				color={"secondary"}
				{...TextFieldProps}
				{...getInputProps()}
				InputProps={{
					sx: {
						"&.Mui-focused": {
							outlineWidth: "2px",
							outlineStyle: "solid",
							outlineColor: theme.palette.secondary.main,
						},
					},
					placeholder: t("global.searchMembersOrGroupsInTeam"),
					className: cn("bg-secondary/[0.06] px-3 py-2 items-center flex [&>input]:pb-0"),
					endAdornment: isLoading ? (
						<Spinner className="w-4 h-4 border-2 border-secondary border-t-transparent" />
					) : (
						<CertiblokIcon name="search_outline" size={16} color="rgba(0,0,0,0.6)" />
					),
					...TextFieldProps?.InputProps,
				}}
			/>
			<FloatingPortal>
				<div
					className="box-border z-[3000] "
					{...getMenuProps({}, { suppressRefError: true })}
					ref={refs.setFloating}
					style={floatingStyles}
				>
					{isOpen && !isLoading && (
						<ul
							className={cn(
								"m-0 px-2 py-2 bg-white shadow-8 flex flex-col gap-3 rounded-xl  max-h-[200px] overflow-y-scroll",
								optionsClassName
							)}
						>
							{filteredOptions.length === 0 ? (
								<span>
									<Typography variant="caption" className="px-3 font-semibold italic">
										{t("global.noResult")}
									</Typography>
								</span>
							) : (
								filteredOptions.map((option, i) => {
									const isDisabled = isUser(option)
										? disabledUsersIds.includes(option.id)
										: disabledGroupsIds.includes(option.id);
									return (
										<li
											key={option.id}
											className={cn(
												"list-none m-0  py-1 px-3 rounded-xl h-9",
												isDisabled ? "bg-grey/[0.06]" : "cursor-pointer active:bg-secondary/[0.12]",
												highlightedIndex === i && !isDisabled && "bg-secondary/[0.06]"
											)}
											{...getItemProps({ item: option, index: i })}
										>
											{isUser(option) ? (
												<UserAutocompleteOption user={option} color="neutral" isDisabled={isDisabled} isGuest={option.isGuest} />
											) : (
												<TeamAutocompleteOption team={option} isDisabled={isDisabled} color="neutral" />
											)}
										</li>
									);
								})
							)}
						</ul>
					)}
				</div>
			</FloatingPortal>
		</div>

		// <Combobox>
		// 	<Combobox.Input onChange={(e) => onInputChange(e.target.value)} displayValue={() => ""}>
		// 		{/* <Combobox.Button className={"bg-yellow-200"}>
		// 			<Button>Ciao</Button>
		// 		</Combobox.Button> */}
		// 	</Combobox.Input>

		// 	<Combobox.Options className={"bg-red-50"}>
		// 		{filteredOptions.map((option) => (
		// 			<Combobox.Option
		// 				key={option.id}
		// 				value={option.name}
		// 				className="bg-primary"
		// 				disabled={isUser(option) ? disabledUsersIds?.includes(option.id) : disabledGroupsIds?.includes(option.id)}
		// 			>
		// 				{isUser(option) ? <UserAutocompleteOption user={option} /> : <div>{option.name}</div>}
		// 			</Combobox.Option>
		// 		))}
		// 	</Combobox.Options>
		// </Combobox>

		// <CustomAutocomplete
		// 	className={className}
		// 	options={filteredOptions ?? []}
		// 	renderInput={(params) => (
		// 		<TextField
		// 			variant="standard"
		// 			color="secondary"
		// 			{...params}
		// 			aria-label={"TeamMembersGuestGroupsAutocomplete"}
		// 			placeholder={t("global.searchMembersOrGroupsInTeam")}
		// 			{...TextFieldProps}
		// 			InputProps={{
		// 				className: cn("bg-secondary/[0.06] px-3 py-1"),
		// 				endAdornment: <CertiblokIcon name="search_outline" size={16} color="rgba(0,0,0,0.6)" />,
		// 			}}
		// 		/>
		// 	)}
		// 	loading={isLoading}
		// 	aria-label="TeamMembersGuestGroupsAutocomplete"
		// 	onInputChange={(inputValue) => onInputChange(inputValue)}
		// 	ListboxProps={{ className: "z-[3000]" }}
		// 	renderOption={(userOrTeam: User | Team) => {
		// 		if (isUser(userOrTeam)) {
		// 			return (
		// 				<UserAutocompleteOption
		// 					color="neutral"
		// 					user={userOrTeam}
		// 					isDisabled={disabledUsersIds?.includes(userOrTeam.id)}
		// 					isGuest={guestsData?.some((g) => g.id === userOrTeam.id)}
		// 				/>
		// 			);
		// 		} else return <div></div>;
		// 	}}
		// />
	);
};

export default TeamMembersGuestsGroupsAutocomplete;
