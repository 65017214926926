import { useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";

export function useResponsiveValue(values: { xs: any; sm?: any; md?: any; lg?: any; xl?: any }): any {
	const theme = useTheme();
	const isSm = useMediaQuery(theme.breakpoints.up("sm"));
	const isMd = useMediaQuery(theme.breakpoints.up("md"));
	const isLg = useMediaQuery(theme.breakpoints.up("lg"));
	const isXl = useMediaQuery(theme.breakpoints.up("xl"));

	const [value, setValue] = useState(values.xs);

	useEffect(() => {
		if (isXl && values.xl !== undefined) {
			setValue(values.xl);
			return;
		}
		if (isLg && values.lg !== undefined) {
			setValue(values.lg);
			return;
		}
		if (isMd && values.md !== undefined) {
			setValue(values.md);
			return;
		}
		if (isSm && values.sm !== undefined) {
			setValue(values.sm);
			return;
		}
		setValue(values.xs);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSm, isMd, isLg, isXl]);

	return value;
}
