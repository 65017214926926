import { Fade } from "@mui/material";
import type { Folder } from "certiblok-api-manager";
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { SwitchTransition } from "react-transition-group";
import OldBottomSheet, { OldBottomSheetProps } from "../../../../utils/components/bottomSheet/OldBottomSheet";
import PickDocumentReplacement from "../../../../utils/components/documents/PickDocumentReplacement";
import type { CertiblokDocument } from "../../../../utils/interfaces/Document";
import MoveDocumentSuccess, { MoveDocumentSuccessFooter } from "./components/MoveDocumentSuccess";
import PickFolder, { PickFolderFooter } from "./components/PickFolder";
import { MoveDocumentContext, useMoveDocumentContext } from "./controllers/MoveDocumentContext";

interface Props extends Omit<OldBottomSheetProps, "children"> {
	toggleOpen: () => void;
	documents: CertiblokDocument[];
	onSuccessClick?: (newFolder: Folder) => void;
	successButtonText?: string;
}

const MoveDocument: React.FC<Props> = ({ toggleOpen, documents, onSuccessClick, successButtonText, ...props }) => {
	const moveDocument = useMoveDocumentContext(documents, props.open, toggleOpen);

	useEffect(() => {
		if (!props.open) moveDocument.resetContext();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.open]);

	const { t } = useTranslation();

	return (
		<MoveDocumentContext.Provider value={moveDocument}>
			<OldBottomSheet
				isBehind={moveDocument.replaceDocumentBS.open}
				frontBsHeight={moveDocument.replaceDocumentBS.height}
				containerSx={{
					minHeight: 420,
					maxHeight: "70vh",
					display: "flex",
					flexDirection: "column",
				}}
				footer={
					moveDocument.step === 0 ? (
						<PickFolderFooter />
					) : (
						<MoveDocumentSuccessFooter
							onSuccessClick={onSuccessClick}
							buttonText={successButtonText ?? t("moveDocuments.goToDocuments")}
						/>
					)
				}
				onClose={() => {
					if (moveDocument.step === 1 && moveDocument.selectedFolder) {
						onSuccessClick?.(moveDocument.selectedFolder);
					}
					toggleOpen();
				}}
				{...props}
			>
				<Fragment>
					<SwitchTransition mode="out-in">
						<Fade key={moveDocument.step} unmountOnExit mountOnEnter>
							{moveDocument.step === 0 ? <PickFolder /> : <MoveDocumentSuccess />}
						</Fade>
					</SwitchTransition>
				</Fragment>
			</OldBottomSheet>
			<PickDocumentReplacement
				replaceDocumentBS={moveDocument.replaceDocumentBS}
				selectedFolder={moveDocument.selectedFolder}
				currentDocuments={moveDocument.currentDocuments}
				replaceDocument={moveDocument.replaceDocument}
				goToNextStep={moveDocument.goToNextStep}
			/>
		</MoveDocumentContext.Provider>
	);
};

export default MoveDocument;
