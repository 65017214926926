import { useQuery } from "@tanstack/react-query";
import { DocumentApi } from "certiblok-api-manager";
import { defaultQueryFilter, transformToStringFrom } from "../../../utils/components/tables/AdminTable";
import { useApi } from "../../../utils/hooks/api";

export const expiringDocumentsCountQueryKey = ["expiringDocuments", "count"];

export const useGetExpiringDocumentsCount = () => {
	const documentApi = useApi(DocumentApi);

	return useQuery({
		queryKey: expiringDocumentsCountQueryKey,
		queryFn: () => documentApi.listExpiringDocuments(transformToStringFrom({ ...defaultQueryFilter, perPage: 1 })),
		select: (res) => res.headers["x-total-count"],
	});
};
