/** @jsxImportSource @emotion/react */
import { Box, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { useSetAtom } from "jotai";
import { DateTime } from "luxon";
import { memo, useCallback, useContext, useRef } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import CertiblokIcon from "../icons/CertiblokIcon";
import { CurrentIndexContext } from "./controllers/CurrentIndexContext";
import SingleCalendar from "./SingleCalendar";
import YearSelectionButton from "./YearSelectionButton";

type Props = {
	initialDate?: DateTime;
	disabledBefore?: DateTime;
	prevSelectedDate?: DateTime;
};

const InfiniteCalendar: React.FC<Props> = memo(({ initialDate, prevSelectedDate, disabledBefore }) => {
	const itemCount = 241;
	const itemSize = 335;

	const listRef = useRef<any>(null);
	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up("md"));

	const currentIndexRef = useRef<number>(0);
	const listWidth = useRef<number>(0);
	const currentIndexAtom = useContext(CurrentIndexContext);
	const setCurrentIndex = useSetAtom(currentIndexAtom);

	const updateCurrentIndex = useCallback((index: number) => {
		setCurrentIndex(index);
		currentIndexRef.current = index;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onYearSelection = useCallback((date: DateTime | null) => {
		if (!date) return;

		listRef.current?.scrollToItem(Math.floor(date.diffNow("months").months + 2), "start");
	}, []);

	return (
		<Box sx={{ width: "100%" }}>
			<Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", paddingBottom: 3 }}>
				{isMd && (
					<Box>
						<IconButton
							color="secondary"
							onClick={() => {
								listRef.current?.scrollToItem(
									currentIndexRef.current - Math.floor(listWidth.current / itemSize) - 1.5,
									"start"
								);
							}}
						>
							<CertiblokIcon size={24} color="secondary" name={"arrow_mini_outline_left"} />
						</IconButton>
						<YearSelectionButton
							onSelect={(date: DateTime | null) => {
								onYearSelection(date);
							}}
						/>
						<IconButton
							color="secondary"
							onClick={() => {
								listRef.current?.scrollToItem(
									currentIndexRef.current - Math.floor(listWidth.current / itemSize) + 1.5,
									"start"
								);
							}}
						>
							<CertiblokIcon size={24} color="secondary" name={"arrow_mini_outline_right"} />
						</IconButton>
					</Box>
				)}
			</Box>
			<Box sx={{ position: "relative" }}>
				<AutoSizer disableHeight>
					{({ width }) => {
						listWidth.current = width;

						return (
							<List
								initialScrollOffset={initialDate ? Math.ceil(initialDate.diffNow("months").months) * itemSize : 0}
								ref={listRef}
								key={"react-calendar-infinite"}
								height={370}
								itemCount={itemCount}
								itemSize={itemSize}
								layout="horizontal"
								width={width}
								onScroll={({ scrollDirection, scrollOffset }) => {
									updateCurrentIndex(Math.floor(scrollOffset / itemSize) + Math.floor(width / itemSize));
								}}
								css={{
									scrollBehavior: "smooth",
								}}
								className="horizontal-scroll"
							>
								{({ index, style }) => (
									<SingleCalendar
										prevSelectedDate={prevSelectedDate}
										disabledBefore={disabledBefore}
										disabled={index === 0}
										style={style}
										index={index - 1}
										key={`calendar_index_${index - 1}`}
									/>
								)}
							</List>
						);
					}}
				</AutoSizer>
			</Box>
		</Box>
	);
});

export default InfiniteCalendar;
