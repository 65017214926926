import { alpha, Avatar, Box, IconButton, Stack, SxProps, Theme, Typography } from "@mui/material";
import type { User } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import { backendMediaUrlParser, getFullName } from "../../../../../utils";
import CertiblokIcon from "../../../../../utils/components/icons/CertiblokIcon";

type MemberInFolderProps = {
	user?: User;
	isAdmin?: boolean;
	endIcon?: "delete" | "eye";
	onEndIconClick?: () => void;
	sx?: SxProps<Theme>;
	excludedFromParent?: boolean;
};
const MemberInFolder: React.FC<MemberInFolderProps> = ({
	user,
	isAdmin,
	endIcon,
	onEndIconClick,
	sx,
	excludedFromParent,
}) => {
	const { t } = useTranslation();

	return (
		<Stack
			direction={"row"}
			alignItems={"center"}
			gap={2}
			sx={{
				backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.06),
				px: 3,
				py: 1,
				borderRadius: 3,
				minHeight: 48,
				...sx,
			}}
		>
			<Avatar
				src={backendMediaUrlParser(user?.profileUrl)}
				sx={{
					width: 24,
					height: 24,
					...(excludedFromParent && {
						opacity: 0.38,
					}),
				}}
			/>
			<Typography
				children={getFullName(user)}
				variant="body1"
				sx={{
					marginRight: isAdmin ? "unset" : "auto",
					...(excludedFromParent && {
						opacity: 0.54,
					}),
				}}
			/>
			{isAdmin && (
				<Typography
					children={t("global.responsible")}
					variant="caption"
					sx={{
						marginRight: "auto",
						backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.06),
						borderRadius: 4,
						px: 2,
						py: 1,
						color: "#00000099",
						...(excludedFromParent && {
							opacity: 0.54,
						}),
					}}
				/>
			)}
			<Typography
				children={user?.email ?? ""}
				variant="label"
				color="secondary"
				noWrap
				sx={{
					...(excludedFromParent && {
						opacity: 0.54,
					}),
				}}
			/>
			{endIcon ? (
				<IconButton
					color="secondary"
					disabled={isAdmin}
					onClick={onEndIconClick}
					sx={{ ...(isAdmin && { visibility: "hidden" }) }}
				>
					<CertiblokIcon
						name={endIcon === "delete" ? "canc" : !excludedFromParent ? "eye_bold_02" : "eye_bold_01"}
						color="inherit"
					/>
				</IconButton>
			) : (
				<Box sx={{ width: 40, height: 40, flexShrink: 0 }} />
			)}
		</Stack>
	);
};
export default MemberInFolder;
