import { useContext } from "react";
import { useTranslation } from "react-i18next";
import NoContents from "../../../utils/components/NoContents";
import AdminTable, { IHeadCell } from "../../../utils/components/tables/AdminTable";
import { useResponsiveValue } from "../../../utils/hooks/useResponsiveValue";
import { TagsContext } from "../contexts/TagsContext";
import TagTableRow from "./TagTableRow";

const TagsTable = () => {
	const { t } = useTranslation();
	const {
		tags,
		queryFilter,
		setQueryFilter,
		isTagsFetching,
		isTagsLoading,
		selectedTagsIds,
		toggleTagSelection,
		toggleAllTagsSelection,
	} = useContext(TagsContext);

	const headCells: IHeadCell[] = [{ id: "name", label: t("global.name"), colSpan: useResponsiveValue({ xs: 1 }) }];

	return (
		<AdminTable
			data={tags?.data ?? []}
			headCells={headCells}
			queryFilter={[queryFilter, setQueryFilter]}
			count={tags?.totalTagsCount ?? 0}
			loading={isTagsLoading}
			fetching={isTagsFetching}
			allRowSelected={((tags?.data ?? []).length ?? 0) !== 0 && selectedTagsIds.length === (tags?.data ?? []).length}
			hasOption
			fullWidth
			size="small"
			color="secondary"
			onSelectAllRow={() => {
				toggleAllTagsSelection();
			}}
			noDataComponent={
				<div className="py-8">
					<NoContents variant="document" title={t("tags.noTagsToShow")} />
				</div>
			}
		>
			{(data) =>
				data.map((tag) => (
					<TagTableRow
						key={tag.id}
						headCells={headCells}
						checked={selectedTagsIds.includes(tag.id)}
						onSelect={() => toggleTagSelection(tag.id)}
						tag={tag}
						shouldShowOptions={selectedTagsIds.length === 0}
					/>
				))
			}
		</AdminTable>
	);
};

export default TagsTable;
