import { SearchApi, SearchResult } from "certiblok-api-manager";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../../../hooks/api";

// async function generateFakeItems(filter: string): Promise<SearchResult[]> {
// 	const documents: CertiblokDocument[] = [];
// 	const contacts = await generateFakeContacts(5);
// 	return [
// 		{
// 			title: "Documenti",
// 			type: "document",
// 			items: [...documents.splice(0, getRandomInt(0, documents.length - 1))],
// 			others: 5,
// 		},
// 		{
// 			title: "Audit room",
// 			type: "audit_room",
// 			items: [...FakeAuditRoom.slice(0, 5)],
// 			others: 0,
// 		},
// 		{
// 			title: "Contatti",
// 			type: "contact",
// 			items: [...contacts.splice(0, getRandomInt(0, contacts.length - 1))],
// 			others: 10,
// 		},
// 		{
// 			title: "Cartelle",
// 			type: "folder",
// 			items: [
// 				{
// 					id: "0",
// 					name: "Cartella 1 dal nome molto molto molto lungo",
// 					documentsCount: 0,
// 					foldersCount: 0,
// 					owner: {
// 						id: "0",
// 						name: "Mario",
// 						surname: "Rossi",
// 						email: "mariorossi@certiblok.com",
// 						createdAt: "",
// 						updatedAt: "",
// 					},
// 					ownerId: "0",
// 					createdAt: "",
// 					updatedAt: "",
// 				},
// 			],
// 			others: 0,
// 		},
// 	];
// }

const switchItemTitle = (item: SearchResult) => {
	switch (item.type) {
		case "audit_room":
			return "globalAutocomplete.audit";
		case "contact":
			return "globalAutocomplete.contatti";
		case "document":
			return "globalAutocomplete.documents";
		case "folder":
			return "globalAutocomplete.folders";
		case "tag":
			return "globalAutocomplete.tags";
	}
};

export function useGlobalAutocomplete() {
	const searchApi = useApi(SearchApi);

	const { t } = useTranslation();

	const [items, setItems] = useState<SearchResult[]>([]);
	const [loading, setLoading] = useState(false);

	const fetchResults = useCallback((filter: string) => {
		if (filter.length > 0) {
			setLoading(true);

			searchApi.searchGlobal(filter).then((res) => {
				setLoading(false);
				setItems([
					...res.data.map((elem) => ({
						...elem,
						// @ts-ignore
						title: (t(switchItemTitle(elem)) ?? "") as string,
					})),
				]);
			});
		} else {
			setItems([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [currentInput, setCurrentInput] = useState("");

	const onInputChange = useCallback(
		(input: string) => {
			setCurrentInput(input);
			fetchResults(input);
		},
		[fetchResults]
	);

	return {
		items,
		loading,
		fetchResults,
		onInputChange,
		currentInput,
	};
}
