import { IconButton, alpha, touchRippleClasses, useTheme, SxProps, Theme } from "@mui/material";
import CertiblokIcon, { CertiblokIconNames } from "../../../utils/components/icons/CertiblokIcon";
import { CSSObject } from "@emotion/react";

const SquareIconButton = ({
	iconName,
	onClick,
	sx,
	iconCss,
}: {
	iconName: CertiblokIconNames;
	onClick?: () => void;
	sx?: SxProps<Theme>;
	iconCss?: CSSObject;
}) => {
	const theme = useTheme();
	return (
		<IconButton
			color="secondary"
			sx={{
				backgroundColor: alpha(theme.palette.secondary.main, 0.12),
				borderRadius: 2,
				":hover": { backgroundColor: alpha(theme.palette.secondary.main, 0.24) },
				[`& .${touchRippleClasses.child}`]: { borderRadius: 2 },
				...sx,
			}}
			children={<CertiblokIcon size={24} color="secondary" name={iconName} iconCss={iconCss} />}
			onClick={() => {
				onClick?.();
			}}
		/>
	);
};

export default SquareIconButton;
