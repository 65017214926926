import { IconButton } from "@mui/material";
import { isFunction } from "lodash";
import type { ComponentProps, FC, ReactNode } from "react";
import { useBetterMediaQuery } from "../../hooks/useBetterMediaQuery";
import BottomSheet from "../bottomSheet/BottomSheet";
import CertiblokIcon from "../icons/CertiblokIcon";
import WhiteModal from "../WhiteModal";

type ResponsiveDialogProps = {
	open: boolean;
	onClose: () => void;
	ModalProps?: Partial<ComponentProps<typeof WhiteModal>>;
	BottomSheetProps?: Partial<ComponentProps<typeof BottomSheet>>;
	header?: ReactNode | ((props: { onClose: () => void; closeIcon: ReactNode }) => ReactNode);
	footer?: ReactNode;
};
const ResponsiveDialog: FC<ResponsiveDialogProps> = ({
	open,
	onClose,
	ModalProps,
	children,
	header,
	footer,
	BottomSheetProps,
}) => {
	const isMd = useBetterMediaQuery("md");

	const closeIcon = (
		<IconButton onClick={onClose} color="secondary">
			<CertiblokIcon name="close" color="inherit" />
		</IconButton>
	);

	return isMd ? (
		<WhiteModal
			open={open}
			onClose={onClose}
			{...ModalProps}
			containerSx={{ borderRadius: 10, ...ModalProps?.containerSx }}
		>
			<div>
				{header && isFunction(header) ? header({ onClose, closeIcon }) : header}
				{children}
				{footer}
			</div>
		</WhiteModal>
	) : (
		<BottomSheet
			header={
				header && isFunction(header)
					? header({ onClose, closeIcon })
					: header ?? <div className="flex justify-end">{closeIcon}</div>
			}
			footer={footer}
			open={open}
			onClose={onClose}
			{...BottomSheetProps}
		>
			<>{children}</>
		</BottomSheet>
	);
};
export default ResponsiveDialog;
