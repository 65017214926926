/** @jsxImportSource @emotion/react */

import { css } from "@emotion/css";
import { Box, Button, DialogActions, Divider, Stack, Typography } from "@mui/material";
import { OptionsObject, SnackbarProvider, SnackbarProviderProps, withSnackbar, WithSnackbarProps } from "notistack";
import * as React from "react";
import { Fragment } from "react";
import CertiblokIcon from "../icons/CertiblokIcon";
import LoadingTextButton from "../LoadingTextButton";
import WhiteModal from "../WhiteModal";
// import SnackMessage from "./WrappedSnackbar";

export class MLDialogProvider extends React.Component<SnackbarProviderProps> {
	render() {
		return (
			<SnackbarProvider
				{...this.props}
				// content={( key, message) => {
				// 	console.log(key);
				// 	return <SnackMessage id={key} message={message} />;
				// }}
			>
				{/* eslint-disable-next-line react/jsx-pascal-case*/}
				<__MLDialog />
				{this.props.children}
				{/* <VariantSnackbar /> */}
			</SnackbarProvider>
		);
	}
}

export const CertiblokMLDialogProvider: React.FC<{}> = ({ children }) => {
	return (
		<MLDialogProvider
			maxSnack={100}
			classes={{
				variantSuccess: css`
					background-color: rgba(52, 199, 89, 1) !important;
					padding: 4px 8px !important;
					border-radius: 8px !important;
					box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.24);
					& .SnackbarItem-action {
						margin-right: unset !important;
					}
				`,
				variantError: css`
					background-color: rgba(240, 55, 45, 1) !important;
					padding: 4px 8px !important;
					border-radius: 8px !important;
					box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.24);
					& .SnackbarItem-action {
						margin-right: unset !important;
					}
				`,
				variantInfo: css`
					background-color: rgba(67, 169, 191, 1) !important;
					padding: 4px 8px !important;
					border-radius: 8px !important;
					box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.24);
					& .SnackbarItem-action {
						margin-right: unset !important;
					}
				`,
				variantWarning: css`
					background-color: rgba(255, 149, 0, 1) !important;
					padding: 4px 8px !important;
					border-radius: 8px !important;
					box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.24);
					& .SnackbarItem-action {
						margin-right: unset !important;
					}
				`,
			}}
			iconVariant={{
				success: <CertiblokIcon size={24} color="white" iconCss={{ marginRight: 4 }} name={"success"} />,
				error: <CertiblokIcon size={24} color="white" iconCss={{ marginRight: 4 }} name={"comunication_outline_03"} />,
				warning: <CertiblokIcon size={24} color="white" iconCss={{ marginRight: 4 }} name={"comunication_outline_01"} />,
				info: <CertiblokIcon size={24} color="white" iconCss={{ marginRight: 4 }} name={"comunication_outline_02"} />,
			}}
		>
			{children}
		</MLDialogProvider>
	);
};

export interface MLDialogParams {
	title?: string;
	message?: string | any;
	isMobile?: boolean;
	onPositiveClick?: () => Promise<boolean | void> | boolean | void; // return true for not closing modal
	onNegativeClick?: (() => boolean | void) | undefined; // return true for not closing modal
	positiveText?: string;
	negativeText?: string;
	positiveButton?: (onClick: any, loading: boolean) => React.ReactElement<any, any>;
	negativeButton?: React.ReactElement<any, any>;
	icon?: React.ReactElement<any, any>;
	contentCentered?: boolean;
	maxWidth?: number;
}

export default class MLDialog extends React.Component<WithSnackbarProps> {
	state = MLDialog.defaultState;

	static __uniqueRef: MLDialog;

	static defaultState: {
		open: boolean;
		title: string;
		message: string;
		isMobile: boolean;
		loading: boolean;
		onPositiveClick: () => Promise<boolean | void> | boolean | void;
		positiveText: string;
		negativeText: string;
		onNegativeClick: () => boolean | void | null;
		positiveButton: (onClick: any, loading: boolean) => React.ReactElement<any, any> | null;
		negativeButton: (onClick: any) => React.ReactElement<any, any> | null;
		icon?: React.ReactElement<any, any>;
		contentCentered?: boolean;
		maxWidth?: number;
	} = {
		open: false,
		title: "",
		message: "",
		isMobile: false,
		loading: false,
		onPositiveClick: () => false,
		// se localizzo queste due stringhe crasha, Cannot read property 'strings' of undefined perchè non legge R
		positiveText: "Ok",
		negativeText: "Annulla",
		//@ts-ignore
		onNegativeClick: null,
		//@ts-ignore
		positiveButton: null,
		//@ts-ignore
		negativeButton: null,
	};

	static showModal(title: String, message: string | any, params?: MLDialogParams) {
		MLDialog.__uniqueRef.showModal(title, message, params);
	}

	static hideModal() {
		MLDialog.__uniqueRef.hideModal();
	}

	static showSnackbar(message: String, options?: OptionsObject) {
		MLDialog.__uniqueRef.showSnackbar(message, options);
	}

	static hideSnackbar(key: string | number | undefined) {
		MLDialog.__uniqueRef.hideSnackbar(key);
	}

	constructor(props: WithSnackbarProps) {
		super(props);
		MLDialog.__uniqueRef = this;
	}

	showModal(title: String, message: string | any, params?: MLDialogParams) {
		this.setState(
			Object.assign({}, MLDialog.defaultState, params, {
				title: title,
				message: message,
				open: true,
			})
		);
	}

	hideModal = () => {
		this.setState({ open: false });
	};

	showSnackbar(message: String, options?: OptionsObject) {
		const { action, ...otherOptions } = { ...options };
		this.props.enqueueSnackbar(message, {
			anchorOrigin: { horizontal: "right", vertical: "bottom" },
			action: (key) => {
				return (
					action && (
						<Fragment>
							<Divider orientation="vertical" sx={{ mr: 1, borderColor: "rgba(255, 255, 255, 0.38)", height: 32 }} />
							{action instanceof Function ? action(key) : action}
						</Fragment>
					)
				);
			},
			...otherOptions,
		});
	}

	hideSnackbar(key: string | number | undefined) {
		this.props.closeSnackbar(key);
	}

	onPositiveClick = async () => {
		if (this.state.onPositiveClick) {
			const result: any = this.state.onPositiveClick();
			if (result.then) {
				this.setState((state) => ({ ...state, loading: true }));
				const response = await result;
				this.setState((state) => ({ ...state, loading: false }));
				if (response === true) {
					return;
				}
			}
			if (result === true) {
				return;
			}
		}
		this.hideModal();
	};

	render() {
		return (
			<WhiteModal
				// fullScreen={this.state?.isMobile}
				keepMounted={false}
				open={this.state.open}
				onClose={this.state.loading ? () => {} : this.hideModal}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				css={{
					zIndex: 2_000_000,
				}}
				containerSx={{ borderRadius: 10, maxWidth: { xs: "100%", md: this.state.maxWidth } }}
			>
				<React.Fragment>
					{this.state.icon && (
						<Box
							sx={{
								width: "100%",
								display: "flex",
								justifyContent: this.state.contentCentered ? "center" : "start",
								paddingBottom: 6,
							}}
						>
							{this.state.icon}
						</Box>
					)}
					<Typography
						variant="h4"
						css={{ "@media only screen and (max-width:500px)": { paddingLeft: 0, paddingRight: 0 } }}
						id="alert-dialog-title"
						children={this.state.title}
						sx={{ marginBottom: 4, textAlign: this.state.contentCentered ? "center" : "start" }}
					/>
					{typeof this.state.message === "string" ? (
						<Typography
							variant="h6"
							id="alert-dialog-description"
							children={this.state.message}
							sx={{ textAlign: this.state.contentCentered ? "center" : "start", color: "rgba(0,0,0,0.74)" }}
						/>
					) : (
						this.state.message
					)}

					<DialogActions sx={{ marginTop: 10, pointerEvents: this.state.loading ? "none" : undefined }}>
						<Stack sx={{ flexShrink: 0, width: "100%" }} gap={1} direction="row" justifyContent="space-between">
							{this.state.negativeButton ||
								(this.state.onNegativeClick && (
									<Button
										variant="text"
										color="primary"
										children={this.state.negativeText}
										size="large"
										disabled={this.state.loading}
										onClick={() => {
											if (this.state.onNegativeClick && (this.state.onNegativeClick! as Function)()) {
												// console.log(this.state.onNegativeClick);
												return;
											}
											this.hideModal();
										}}
									/>
								))}

							{this?.state?.positiveButton?.(this.onPositiveClick, this.state.loading) || (
								<LoadingTextButton
									loading={this.state.loading}
									variant="contained"
									autoFocus
									size="large"
									color="primary"
									sx={{ minWidth: 150 }}
									children={this.state.positiveText}
									onClick={this.onPositiveClick}
								/>
							)}
						</Stack>
					</DialogActions>
				</React.Fragment>
			</WhiteModal>
		);
	}
}

const __MLDialog = withSnackbar(MLDialog);
