import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type EditCreateEditRoomHeaderProps = {
	isEdit?: boolean;
};

const EditCreateEditRoomHeader: React.FC<EditCreateEditRoomHeaderProps> = (props) => {
	const { isEdit } = props;

	const { t } = useTranslation();

	return <Typography children={isEdit ? t("editRoom.edit") : t("editRoom.create")} variant="subtitle1" />;
};

export default EditCreateEditRoomHeader;
