import { useQuery } from "@tanstack/react-query";
import { instanceApi } from "../../../../../utils/hooks/api";
import { EventApi } from "certiblok-api-manager";

export const eventInstanceQueryKeys = {
	base: "eventInstance",
	byId: (instanceId: string) => ["eventInstance", instanceId],
};

export function useEventInstanceById(instanceId?: string) {
	return useQuery({
		queryKey: eventInstanceQueryKeys.byId(instanceId ?? ""),
		queryFn: () => {
			return instanceApi(EventApi).getEventInstanceById(instanceId ?? "");
		},
		select: (data) => data.data,
		enabled: !!instanceId,
	});
}
