import { useMutation, useQuery } from "@tanstack/react-query";
import { SearchApi, SharingGroupApi } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import MLDialog from "../../../utils/components/poppers";
import { IQueryFilter, transformToStringFrom } from "../../../utils/components/tables/AdminTable";
import { useApi } from "../../../utils/hooks/api";

// io passo l'id di un contatto o di un utente alla create, mi viene sempre restituito un utente

export const getAllUsersOrContactsQueryKey = (input: string) => ["userOrContacts", input];
export const getSharingGroupsQueryKey = (queryFilter?: IQueryFilter) => [
	"sharingGroups",
	queryFilter ? transformToStringFrom(queryFilter) : undefined,
];
export const getSingleSharingGroupQueryKey = (groupId: string) => ["singleSharingGroup", groupId];

export const useGetAllUserOrContacts = ({ input }: { input: string }) => {
	const searchApi = useApi(SearchApi);

	return useQuery({
		queryKey: getAllUsersOrContactsQueryKey(input),
		queryFn: () => {
			return searchApi.searchUsersOrContacts(input);
		},
		select: (res) => res.data,
		enabled: input.length >= 3,
	});
};

export const useGetSharingGroups = (queryFilter: IQueryFilter) => {
	const sharingGroupApi = useApi(SharingGroupApi);

	return useQuery({
		queryKey: getSharingGroupsQueryKey(queryFilter),
		queryFn: () => {
			return sharingGroupApi.getAllSharingGroups(transformToStringFrom(queryFilter));
		},
	});
};

export const useGetSingleSharingGroup = (groupId: string) => {
	const sharingGroupApi = useApi(SharingGroupApi);

	return useQuery({
		queryKey: getSingleSharingGroupQueryKey(groupId),
		queryFn: () => {
			return sharingGroupApi.getSharingGroupById(groupId);
		},
		select: (res) => res.data,
	});
};

export const useCreateContactGroup = (props?: { onSuccess?: () => void }) => {
	const { t } = useTranslation();
	const sharingGroupApi = useApi(SharingGroupApi);

	return useMutation({
		mutationFn: ({
			name,
			color,
			userIds,
			emails,
		}: {
			name: string;
			color: string;
			userIds: string[];
			emails: string[];
		}) => {
			return sharingGroupApi.createSharingGroup({
				name,
				color,
				userOrContactIds: userIds,
				userEmails: emails,
			});
		},
		onError: () => {
			MLDialog.showSnackbar(t("global.error"), { variant: "error" });
		},
		onSuccess: () => {
			MLDialog.showSnackbar(t("contactsGroups.groupSuccessfullyCreated"), { variant: "success" });
			props?.onSuccess?.();
		},
	});
};

export const useEditContactGroup = (props?: { onSuccess?: () => void }) => {
	const { t } = useTranslation();
	const sharingGroupApi = useApi(SharingGroupApi);

	return useMutation({
		mutationFn: ({
			groupId,
			name,
			color,
			userIds,
			emails,
		}: {
			groupId: string;
			name: string;
			color: string;
			userIds: string[];
			emails: string[];
		}) => {
			return sharingGroupApi.editSharingGroup(groupId, {
				name,
				color,
				userOrContactIds: userIds,
				userEmails: emails,
			});
		},
		onError: () => {
			MLDialog.showSnackbar(t("global.error"), { variant: "error" });
		},
		onSuccess: () => {
			MLDialog.showSnackbar(t("contactsGroups.groupSuccessfullyEdited"), { variant: "success" });
			props?.onSuccess?.();
		},
	});
};

export const useDeleteContactGroup = (props?: { onSuccess?: () => void }) => {
	const { t } = useTranslation();
	const sharingGroupApi = useApi(SharingGroupApi);

	return useMutation({
		mutationFn: (groupId: string) => {
			return sharingGroupApi.deleteSharingGroup(groupId);
		},
		onError: () => {
			MLDialog.showSnackbar(t("global.error"), { variant: "error" });
		},
		onSuccess: () => {
			MLDialog.showSnackbar(t("contactsGroups.groupSuccessfullyDeleted"), { variant: "success" });
			props?.onSuccess?.();
		},
	});
};

export const useDeleteContactGroups = (props?: { onSuccess?: () => void }) => {
	const { t } = useTranslation();
	const sharingGroupApi = useApi(SharingGroupApi);

	return useMutation({
		mutationFn: (groupIds: string[]) => {
			return Promise.all(groupIds.map((groupId) => sharingGroupApi.deleteSharingGroup(groupId)));
		},
		onError: () => {
			MLDialog.showSnackbar(t("global.error"), { variant: "error" });
		},
		onSuccess: (res) => {
			MLDialog.showSnackbar(t("contactsGroups.groupsSuccessfullyDeleted"), { variant: "success" });
			props?.onSuccess?.();
		},
	});
};
