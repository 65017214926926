import { Button } from "@mui/material";
import CertiblokIcon from "../../../../../utils/components/icons/CertiblokIcon";
import {
	ModalNavigator,
	useModalNavigator,
	useModalRoute,
} from "../../../../../utils/components/ModalNavigator/ModalNavigator";
import WhiteModal from "../../../../../utils/components/WhiteModal";
import { useBetterMediaQuery } from "../../../../../utils/hooks/useBetterMediaQuery";

const TwoFADisabledSuccessfully = ModalNavigator.create(() => {
	const { visible } = useModalRoute();
	const { dismissModal } = useModalNavigator();
	const isMd = useBetterMediaQuery("md");

	return (
		<WhiteModal
			open={visible}
			onClose={() => {
				dismissModal();
			}}
			containerSx={{
				borderRadius: 10,
			}}
			responsive
		>
			<div className="flex flex-col gap-6 items-center">
				<div className="p-3 w-fit rounded-3xl bg-primary-a12 ">
					<CertiblokIcon size={!isMd ? 48 : 64} color="primary" name={"success"} />
				</div>
				<div className="flex gap-4 flex-col items-center">
					<h6 className="text-primary text-h6 text-center">Autenticazione disattivata con successo</h6>
					<p className="text-black-a60 text-body1 font-normal text-center">
						Hai disattivato correttamente l'autenticazione a due fattori.
					</p>
				</div>
				<Button
					variant="outlined"
					startIcon={isMd && <CertiblokIcon size={24} color="inherit" name={"close"} />}
					color="secondary"
					size={isMd ? "large" : "small"}
					onClick={dismissModal}
					className="mt-3 min-w-[100px] md:min-w-[unset]"
				>
					Chiudi
				</Button>
			</div>
		</WhiteModal>
	);
});
export default TwoFADisabledSuccessfully;
