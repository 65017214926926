import { Box, TableCell, TableCellProps } from "@mui/material";
import { forwardRef } from "react";

const TouchTargetCell: React.FC<TableCellProps> = forwardRef((props, ref) => {
	return (
		<TableCell ref={ref} padding="checkbox" sx={{ "&.MuiTableCell-paddingCheckbox": { padding: 0 } }} {...props}>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				{props.children}
			</Box>
		</TableCell>
	);
});

export default TouchTargetCell;
