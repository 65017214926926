/** @jsxImportSource @emotion/react */

import { Box, Button, Typography } from "@mui/material";
import type { Document, Folder } from "certiblok-api-manager";
import React, { CSSProperties, Fragment, memo, useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import type { CertiblokDocument } from "../../interfaces/Document";
import OldBottomSheet from "../bottomSheet/OldBottomSheet";
import type { BottomSheetControls } from "../bottomSheet/hooks/useBottomSheet";
import LoadingIconButton from "../buttons/LoadingIconButton";
import CertiblokTextInput from "../CertiblokTextInput";
import DocumentWithIcon from "../DocumentWithIcon";
import CertiblokIcon from "../icons/CertiblokIcon";
import Loading from "../Loading";
import NoContents from "../NoContents";
import {
	PickDocumentReplacementContextProvider,
	usePickDocumentReplacementContextUnoptimized,
	usePickDocumentReplacementController,
} from "./controllers/PickDocumentReplacementController";
import ReplacingDocumentItems from "./ReplacingDocumentItems";
import { Virtuoso } from "react-virtuoso";
import SingleDocumentToChoose from "../../../pages/Folders/components/MoveDocument/components/SingleDocumentToChoose";
import { useBetterMediaQuery } from "../../hooks/useBetterMediaQuery";

export const MAX_COLUMN_ELEMENTS = 7;
export const LIST_HEIGHT = 308;
export const ITEM_SIZE = 230 + 48;

const SmallInfinteLoadingList = memo(() => {
	const { t } = useTranslation();

	const { documentsInfiniteLoader, selectedFile, setSelectedFile } = usePickDocumentReplacementContextUnoptimized();

	return (
		<Virtuoso
			style={{ height: 200 }}
			totalCount={documentsInfiniteLoader.totalCount}
			data={documentsInfiniteLoader.data}
			itemContent={(index, data) => (
				<SingleDocumentToChoose
					key={data.id}
					document={data as CertiblokDocument}
					selectedDocument={selectedFile as CertiblokDocument}
					onClick={() => setSelectedFile(data)}
					className="my-2"
				/>
			)}
			endReached={() => {
				if (documentsInfiniteLoader.hasNextPage) {
					documentsInfiniteLoader.fetchData();
				}
			}}
			components={{
				Footer: () => {
					return documentsInfiniteLoader.loading ? (
						<Loading title={t("pickDocumentReplacement.documentsLoading")} sx={{ height: "100%" }} />
					) : null;
				},
			}}
		/>
	);
});

const InfiniteLoadingList = memo(() => {
	const { t } = useTranslation();
	const listRef = useRef<InfiniteLoader>(null);
	const hasMountedRef = useRef(false);

	const { debouncedFilter, documentsInfiniteLoader } = usePickDocumentReplacementContextUnoptimized();

	useEffect(() => {
		if (listRef.current && hasMountedRef.current) {
			listRef.current.resetloadMoreItemsCache();
		}
		hasMountedRef.current = true;
	}, [debouncedFilter]);

	const itemCount = useMemo(
		() =>
			documentsInfiniteLoader.hasNextPage
				? Math.ceil(documentsInfiniteLoader.data.length / MAX_COLUMN_ELEMENTS) + 1
				: Math.ceil(documentsInfiniteLoader.data.length / MAX_COLUMN_ELEMENTS),
		[documentsInfiniteLoader.hasNextPage, documentsInfiniteLoader.data]
	);

	const isItemLoaded = useCallback(
		(index: number) =>
			!documentsInfiniteLoader.hasNextPage || index < Math.ceil(documentsInfiniteLoader.data.length / MAX_COLUMN_ELEMENTS),
		[documentsInfiniteLoader.hasNextPage, documentsInfiniteLoader.data.length]
	);
	const renderCallback = useCallback(
		(props: { index: number; style: CSSProperties; data: Document[] }) => <ReplacingDocumentItems {...props} />,
		[]
	);

	return !documentsInfiniteLoader.firstLoading ? (
		itemCount > 0 || documentsInfiniteLoader.hasNextPage ? (
			<AutoSizer disableHeight>
				{({ width }) => (
					<InfiniteLoader
						ref={listRef}
						isItemLoaded={isItemLoaded}
						itemCount={itemCount}
						loadMoreItems={documentsInfiniteLoader.fetchData}
						minimumBatchSize={1}
					>
						{({ onItemsRendered, ref }) => (
							<List
								height={LIST_HEIGHT}
								itemCount={itemCount}
								itemSize={ITEM_SIZE}
								onItemsRendered={onItemsRendered}
								ref={ref}
								width={width}
								itemData={documentsInfiniteLoader.data}
								layout="horizontal"
								className="horizontal-scroll"
							>
								{renderCallback}
							</List>
						)}
					</InfiniteLoader>
				)}
			</AutoSizer>
		) : (
			<NoContents
				variant="document"
				title={t("folders.noDocumentsHere")}
				sx={{ height: "100%", justifyContent: "center" }}
			/>
		)
	) : (
		<Loading title={t("pickDocumentReplacement.documentsLoading")} sx={{ height: "100%" }} />
	);
});

type Props = {
	replaceDocumentBS: BottomSheetControls;
	selectedFolder?: Folder;
	currentDocuments: CertiblokDocument[];
	replaceDocument: (document: CertiblokDocument) => void;
	goToNextStep: () => void;
};

const PickDocumentReplacement: React.FC<Props> = ({
	replaceDocumentBS,
	currentDocuments,
	replaceDocument,
	goToNextStep,
	selectedFolder,
}) => {
	const isMd = useBetterMediaQuery("md");
	const { ref, setHeight, height, toggleBehind, toggleOpen, ...otherControls } = {
		...replaceDocumentBS,
	};

	const documentReplacementController = usePickDocumentReplacementController(selectedFolder);

	useEffect(() => {
		if (otherControls.open && selectedFolder) {
			documentReplacementController.setSelectedFile(undefined);
			documentReplacementController.documentsInfiniteLoader.revalidateData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [otherControls.open, selectedFolder, documentReplacementController.debouncedFilter]);

	const { t } = useTranslation();

	return (
		<PickDocumentReplacementContextProvider pickDocumentReplacementController={documentReplacementController}>
			<OldBottomSheet
				footer={
					<Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", paddingTop: 12, gap: 3 }}>
						<Button variant="outlined" color="secondary" onClick={() => toggleOpen()}>
							<CertiblokIcon size={24} color="secondary" name={"close"} />
							{t("global.cancel")}
						</Button>
						<LoadingIconButton
							icon={<CertiblokIcon size={24} color="inherit" name={"success"} />}
							variant="contained"
							color="secondary"
							disabled={
								documentReplacementController.selectedFile === undefined ||
								documentReplacementController.documentsInfiniteLoader.loading ||
								documentReplacementController.documentsInfiniteLoader.firstLoading
							}
							onClick={async () => {
								if (documentReplacementController.selectedFile) {
									await replaceDocument(documentReplacementController.selectedFile as CertiblokDocument);
									toggleOpen();
									goToNextStep();
								}
							}}
						>
							{isMd ? t("pickDocumentReplacement.replaceWithThisDocument") : t("global.substitute")}
						</LoadingIconButton>
					</Box>
				}
				boxRef={ref}
				onHeightChange={setHeight}
				{...otherControls}
			>
				<Fragment>
					<Box>
						<Typography
							children={t("pickDocumentReplacement.replaceDocument")}
							variant="subtitle1"
							sx={{ paddingBottom: 6 }}
						/>
						{currentDocuments.map((document) => (
							<DocumentWithIcon name={document.name} key={document.id} document={document.lastVersionFile?.[0]} bold />
						))}
					</Box>
					<Box sx={{ py: 6, minHeight: 56 }}>
						<CertiblokTextInput
							label={t("pickDocumentReplacement.findDocument")}
							fullWidth
							color="secondary"
							endAdornment={<CertiblokIcon size={16} color="rgba(0,0,0,0.6)" name={"search_outline"} />}
							value={documentReplacementController.filter}
							onChange={(ev) => {
								documentReplacementController.setFilter(ev.target.value);
							}}
						/>
					</Box>
					<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingBottom: 6 }}>
						<Typography variant="label">{t("pickDocumentReplacement.allDocumentsInFolder")}</Typography>
					</Box>
					{isMd ? (
						<Box sx={{ width: "100%", height: LIST_HEIGHT, position: "relative" }}>
							<InfiniteLoadingList />
						</Box>
					) : (
						<SmallInfinteLoadingList />
					)}
				</Fragment>
			</OldBottomSheet>
		</PickDocumentReplacementContextProvider>
	);
};

export default PickDocumentReplacement;
