import { useFormContext } from "react-hook-form";
import { EditRoomFormValues } from "../../hooks/useEditRoomDetailsZodSchema";
import EditRoomAccess from "./components/EditRoomAccess/index";
import EditRoomCustomization from "./components/EditRoomCustomization";

type EditCreateEditRoomBodyProps = {
	editRoomId?: string;
	onAction?: () => void;
};

const EditCreateEditRoomBody: React.FC<EditCreateEditRoomBodyProps> = (props) => {
	const { handleSubmit } = useFormContext<EditRoomFormValues>();

	return (
		<form
			onSubmit={handleSubmit(() => {
				props?.onAction?.();
			})}
		>
			<div className="md:max-w-xl py-3 flex flex-col md:items-center gap-6 max-md:px-5">
				<EditRoomCustomization />
				<EditRoomAccess />
			</div>
			<button type="submit" className="hidden">
				submit
			</button>
		</form>
	);
};

export default EditCreateEditRoomBody;
