import { IconButton } from "@mui/material";
import { VariantProps, cva } from "class-variance-authority";
import { useCallback, useRef } from "react";
import { DateInput, DateSegment, Group, Label, TimeField, TimeFieldProps, TimeValue } from "react-aria-components";
import CertiblokIcon from "../icons/CertiblokIcon";

type TimePickerProps = {
	label?: string;
} & TimeFieldProps<TimeValue> &
	VariantProps<typeof containerStyle>;

const containerStyle = cva(
	"rounded-xl mt-1 box-border justify-between cursor-text flex items-center border-[1px] border-solid border-transparent  transition-all hover:[filter:brightness(0.90)]",
	{
		variants: {
			size: {
				small: "h-9 px-3 py-2",
				medium: "h-12 py-[14px] px-3 ",
			},
			color: {
				secondary:
					"bg-[rgba(244,250,251,1)] data-[focus-within]:border-secondary data-[focus-within]:shadow-[0_0px_0px_2px_rgba(0,0,0,0.3)] data-[focus-within]:shadow-secondary-a38",
			},
		},
		defaultVariants: {
			size: "small",
			color: "secondary",
		},
	}
);

const segmentStyle = cva("p-[2px] text-body1 data-[type=literal]:p-0 uppercase data-[placeholder]:opacity-40");

const TimePicker: React.FC<TimePickerProps> = ({ label, color, size, ...props }) => {
	const firstInputRef = useRef<HTMLInputElement>();

	const setFirstInputRef = useCallback((node: HTMLInputElement) => {
		if (node !== null && firstInputRef.current === undefined) {
			firstInputRef.current = node;
		}
	}, []);

	const onEmptyInput = useCallback(() => {
		//@ts-ignore
		props.onChange?.(null);
		firstInputRef.current?.focus();
	}, [props]);

	return (
		<TimeField hourCycle={24} {...props}>
			<Label className="text-label">{label}</Label>
			<Group
				className={containerStyle({
					size,
					color,
				})}
			>
				<DateInput className={"flex items-center flex-1"}>
					{(segment) => <DateSegment ref={setFirstInputRef} className={segmentStyle()} segment={segment} />}
				</DateInput>
				{props.value && (
					<IconButton
						disabled={props.isDisabled}
						onClick={(ev) => {
							onEmptyInput();
						}}
						onKeyDown={(ev) => {
							if (ev.code === "Enter" || ev.code === "Space") {
								onEmptyInput();
							}
						}}
						size="small"
					>
						<CertiblokIcon size={16} color={"black"} className="opacity-60" name={"canc"} />
					</IconButton>
				)}
			</Group>
		</TimeField>
	);
};
export default TimePicker;
