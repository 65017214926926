import { Button, Typography } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import CertiblokIcon from "../../../utils/components/icons/CertiblokIcon";
import { useModalNavigator } from "../../../utils/components/ModalNavigator/ModalNavigator";
import TableTitleContainer from "../../../utils/components/TableTitleContainer";
import { useBetterMediaQuery } from "../../../utils/hooks/useBetterMediaQuery";
import DeleteTagModal from "../../SingleDocument/components/Tags/DeleteTagModal";
import EditCreateTag from "../../SingleDocument/components/Tags/TagsManagementModal/EditCreateTag";
import { TagsContext } from "../contexts/TagsContext";
import TableHeaderBackButton from "../../../utils/components/tables/components/TableHeaderBackButton";

const TableHeader = () => {
	const { t } = useTranslation();
	const isSm = useBetterMediaQuery("sm");
	const { pushModal } = useModalNavigator();

	const { revalidateTags, selectedTagsIds, emptyTagsSelection } = useContext(TagsContext);

	return (
		<TableTitleContainer>
			<div className="flex items-between w-full items-center gap-1 md:gap-2 lg:gap-3">
				<TableHeaderBackButton goToDashboard />
				<Typography
					children={t("tags.myTags")}
					variant={isSm ? "h6" : "subtitle1"}
					sx={{ fontWeight: 800, color: "#000000", flexGrow: 1 }}
				/>

				{selectedTagsIds.length === 0 && (
					<Button
						variant="contained"
						color="secondary"
						startIcon={<CertiblokIcon name="add" color="white" />}
						size={isSm ? "medium" : "small"}
						onClick={() => {
							pushModal(EditCreateTag, {
								onEditCreateSuccess: () => {
									revalidateTags();
								},
							});
						}}
					>
						{t("tags.createNewTag")}
					</Button>
				)}
				{/* <Fade in={selectedTagsIds.length > 0} unmountOnExit> */}
				{selectedTagsIds.length > 0 && (
					<Button
						variant="outlined"
						color="error"
						startIcon={<CertiblokIcon name="delete_outline" color="error" />}
						size={isSm ? "medium" : "small"}
						onClick={() => {
							pushModal(
								DeleteTagModal,
								{
									tagId: selectedTagsIds,
									onSuccess: () => {
										revalidateTags();
										emptyTagsSelection();
									},
								},
								{ shouldHidePrev: true }
							);
						}}
					>
						{t("tags.deleteTags")}
					</Button>
				)}

				{/* </Fade> */}
				{/* {selectedTagsIds.length} */}
			</div>
		</TableTitleContainer>
	);
};

export default TableHeader;
