import { Badge, Chip, Tooltip, useTheme } from "@mui/material";
import { alpha } from "@mui/system";
import type { Audit } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import CertiblokIcon from "../../../../icons/CertiblokIcon";
import { useModalNavigator } from "../../../../ModalNavigator/ModalNavigator";
import { useUploadDocumentContext } from "../../../contexts/UploadDocumentContext";
import {
	useEditUploadedFileDraft,
	useUploadedFileById,
	useUploadedFileDraftById,
} from "../../../stores/uploadedFilesStore";
import AssignToAuditRoomBS from "../../../../DocumentComponents/AssignToAuditRoomBS/AssignToAuditRoomBS";
import ChipsGrid from "../../ChipsGrid";
import { TitleWithTextField } from "./TitleWithTextField";

type DocumentAssingedAuditRoomsProps = {
	documentId: string;
};
const DocumentAssingedAuditRooms: React.FC<DocumentAssingedAuditRoomsProps> = ({ documentId }) => {
	const { t } = useTranslation();
	const theme = useTheme();

	const editUploadedDocumentDraft = useEditUploadedFileDraft();
	const { pushBottomSheet } = useModalNavigator();
	const { mainBottomSheetRef } = useUploadDocumentContext();

	const uploadedFile = useUploadedFileById(documentId);
	const documentDraft = useUploadedFileDraftById(documentId);

	const auditRooms = documentDraft.auditRooms ?? [];

	return (
		<div>
			<TitleWithTextField
				title={t("uploadDocument.assignToAuditRoom")}
				placeholder="Nome auditroom"
				onClick={() => {
					pushBottomSheet({
						component: AssignToAuditRoomBS,
						ref: mainBottomSheetRef.current,
						props: {
							documents: [{ ...uploadedFile.document, audits: documentDraft.auditRooms }],
							canRemoveAlreadyAssignedAudits: true,
							shouldMutate: false,
						},
					}).then((auditRooms: Audit[]) => {
						editUploadedDocumentDraft(documentId, {
							auditRooms,
						});
					});
				}}
			/>
			{auditRooms.length > 0 && (
				<ChipsGrid
					data={auditRooms}
					renderChip={(elem) => (
						<div key={elem.id} className="max-w-full overflow-hidden">
							<Tooltip title={elem.name}>
								<Chip
									icon={<CertiblokIcon size={20} color={"inherit"} name={"profile_bold_01"} />}
									label={elem.name}
									onDelete={() => {
										editUploadedDocumentDraft(documentId, {
											auditRooms: auditRooms.filter((e) => e.id !== elem.id),
										});
									}}
									sx={{
										width: "100%",
										backgroundColor: alpha(elem.color, 0.12),
										color: alpha("#000000", 0.6),
										justifyContent: "space-between",
										textAlign: "start",
										"& span": {
											flexGrow: 1,
										},
										"& .MuiChip-icon": {
											color: elem.color,
										},
										"& .MuiChip-deleteIcon": {
											color: alpha(elem.color, 0.6),
											":hover": {
												color: alpha(elem.color, 1),
											},
										},
									}}
								/>
							</Tooltip>
						</div>
					)}
					renderMoreLabel={(count) => (
						<Badge
							badgeContent={`+${count}`}
							color="secondary"
							overlap="circular"
							sx={{
								"& .MuiBadge-badge": {
									right: -4,
									top: 16,
									border: `2px solid ${theme.palette.background.paper}`,
									padding: "0 4px",
								},
							}}
						>
							<CertiblokIcon
								size={20}
								color="secondary"
								name={"profile_bold_01"}
								iconCss={{ padding: 6, backgroundColor: alpha(theme.palette.secondary.main, 0.12), borderRadius: 16 }}
							/>
						</Badge>
					)}
				/>
			)}
		</div>
	);
};
export default DocumentAssingedAuditRooms;
