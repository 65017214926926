export function getWeekdayOccurrence(date?: Date) {
	if (!date) {
		date = new Date();
	}
	let nth = Math.ceil(date.getDate() / 7);
	return nth;
}

export function parseOccurrenceToText(nth: number) {
	switch (nth) {
		case 1:
			return "first";
		case 2:
			return "second";
		case 3:
			return "third";
		case 4:
			return "fourth";
		default:
			return "last";
	}
}
