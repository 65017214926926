import { EditRoomApi, Folder, FolderApi } from "certiblok-api-manager";
import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { instanceApi } from "../../../hooks/api";
import { defaultQueryFilter, transformToStringFrom } from "../../tables/AdminTable";

const perPageInfiniteLoading = 14;

export function useFolderPicker(
	startingFolderId?: string,
	setSelectedFolder?: (folder: Folder) => void,
	onNoFolderFound?: () => void
) {
	const [availableFolders, setAvailableFolders] = useState<Folder[]>([]);
	const [loading, setLoading] = useState(false);

	const location = useLocation();
	const isInEditRoom = location.pathname.includes("/edit_rooms/");

	const fetchFolders = useCallback(
		async (options?: { forceReinit?: boolean }) => {
			setLoading(true);

			let foldersCount = 1;
			let currentFolderCount = options?.forceReinit ? 0 : availableFolders?.length;
			let currentFolderPage = 0;

			const respSingleFolder = startingFolderId
				? isInEditRoom
					? await instanceApi(EditRoomApi).readEditRoomFolderFromParent(
							startingFolderId || "",
							transformToStringFrom({ ...defaultQueryFilter, page: currentFolderPage, perPage: perPageInfiniteLoading })
					  )
					: await instanceApi(FolderApi).readFolderFromParent(
							startingFolderId || "",
							transformToStringFrom({ ...defaultQueryFilter, page: currentFolderPage, perPage: perPageInfiniteLoading })
					  )
				: undefined;

			if (respSingleFolder) {
				const localSingleFolder = respSingleFolder?.data;
				setSelectedFolder?.(localSingleFolder);
				setLoading(false);
				onNoFolderFound?.();
				return;
			}

			let i = 0;

			while (currentFolderCount < foldersCount) {
				const resp = await (isInEditRoom
					? instanceApi(EditRoomApi).getAllEditRooms(
							transformToStringFrom({ ...defaultQueryFilter, page: currentFolderPage, perPage: perPageInfiniteLoading }),
							startingFolderId
					  )
					: instanceApi(FolderApi).getFolders(
							transformToStringFrom({ ...defaultQueryFilter, page: currentFolderPage, perPage: perPageInfiniteLoading }),
							startingFolderId
					  ));

				currentFolderCount += resp?.data?.length || 0;

				foldersCount = resp.headers?.count || 0;
				currentFolderPage++;

				if (i === 0) {
					setAvailableFolders([...(resp?.data || [])]);
					i++;
				} else {
					setAvailableFolders((folders) => [...folders, ...(resp?.data || [])]);
				}

				setLoading(false);
			}
		},
		[availableFolders?.length, onNoFolderFound, setSelectedFolder, startingFolderId, isInEditRoom]
	);

	const fetchFoldersById = useCallback(
		async (id: string, options?: { forceReinit?: boolean }) => {
			setLoading(true);

			let foldersCount = 1;
			let currentFolderCount = options?.forceReinit ? 0 : availableFolders?.length;
			let currentFolderPage = 0;

			let i = 0;

			while (currentFolderCount < foldersCount) {
				const resp = await (isInEditRoom
					? instanceApi(EditRoomApi).getAllEditRooms(
							transformToStringFrom({
								...defaultQueryFilter,
								page: currentFolderPage,
								perPage: perPageInfiniteLoading,
							}),
							id
					  )
					: instanceApi(FolderApi).getFolders(
							transformToStringFrom({
								...defaultQueryFilter,
								page: currentFolderPage,
								perPage: perPageInfiniteLoading,
							}),
							id
					  ));

				currentFolderCount += resp?.data?.length || 0;

				foldersCount = resp.headers?.count || 0;
				currentFolderPage++;

				if (i === 0) {
					setAvailableFolders([...(resp?.data || [])]);
					i++;
				} else {
					setAvailableFolders((folders) => [...folders, ...(resp?.data || [])]);
				}

				setLoading(false);
			}
		},
		[availableFolders?.length, isInEditRoom]
	);

	const clearAvailableFolders = useCallback(() => {
		setAvailableFolders([]);
	}, []);

	return {
		availableFolders,
		clearAvailableFolders,
		loading,
		fetchFolders,
		fetchFoldersById,
	};
}
