/** @jsxImportSource @emotion/react */
import { Button, SxProps, Theme } from "@mui/material";
import type { UserOrContact } from "certiblok-api-manager";

import React, { useState } from "react";
import { useGetCompanyAdmins } from "../../pages/Profile/pages/Team/hooks/useGetCompanyAdmin";
import type { TextFieldStatus } from "../interfaces/TextFieldStatus";
import CustomAutocomplete, { CustomAutocompleteProps } from "./CustomAutocomplete";
import CertiblokIcon from "./icons/CertiblokIcon";
import UserAutocompleteOption from "./UserAutocompleteOption";

type Props<T extends UserOrContact> = {
	label: string;
	options: T[];
	onInputChange: (value: string) => void;
	onSelectedValueChange: (user: T) => void;
	autocompleteStatus: { status?: TextFieldStatus; message?: string };
	setAutocompleteStatus?: React.Dispatch<React.SetStateAction<{ status?: TextFieldStatus; message?: string }>>;
	invitableUser?: boolean;
	buttonText?: string;
	buttonAction?: () => boolean;
	loading?: boolean;
	size?: "small" | "medium";
	sx?: SxProps<Theme> | undefined;
	placeholder?: string;
	disabledUsers?: UserOrContact[];
} & Omit<CustomAutocompleteProps<T>, "id" | "renderOption" | "options">;

const UserPickerAutocomplete = <T extends UserOrContact>({
	label,
	options,
	color = "secondary",
	onInputChange,
	onSelectedValueChange,
	autocompleteStatus,
	invitableUser = false,
	buttonText = "",
	buttonAction,
	loading,
	size = "small",
	sx = {},
	placeholder,
	disabledUsers,
	...otherProps
}: Props<T>) => {
	const [shouldClose, setShouldClose] = useState(false);
	const [inputValue, setInputValue] = useState("");

	const { data: guestsData } = useGetCompanyAdmins();
	const guestsId = guestsData?.map((g) => g.id) ?? [];

	return (
		<CustomAutocomplete
			id={"id"}
			label={label}
			color={color}
			getOptionLabel={(user) => `${user.name} ${user.surname}`}
			options={options || []}
			placeholder={placeholder ?? "Es. Mario Rossi, mario.rossi@mail.com"}
			onInputChange={(value) => {
				setInputValue(value);
				onInputChange(value);
			}}
			renderOption={(elem) => (
				<UserAutocompleteOption
					user={elem}
					isDisabled={(disabledUsers ?? []).some((u) => u.id === elem.id)}
					isGuest={guestsId.includes(elem.id)}
				/>
			)}
			size={size}
			loading={loading || autocompleteStatus.status === "loading"}
			extraButton={
				<Button
					variant="text"
					size="medium"
					fullWidth
					color={color}
					sx={{ justifyContent: "start", gap: 4, boxSizing: "border-box", textTransform: "none", marginTop: 2 }}
					onClick={() => {
						setShouldClose(buttonAction?.() ?? true);
					}}
				>
					<CertiblokIcon size={24} color={color} name={"mail_outline"} />
					{buttonText}
				</Button>
			}
			isDummyTextField
			shouldShowButton={inputValue.length > 0 && invitableUser && options.length === 0}
			onEnterPress={(ev) => {
				if (inputValue.length > 0 && invitableUser && options.length === 0) {
					ev.preventDefault();
					ev.stopPropagation();
					buttonAction?.();
				}
			}}
			onSelectedValueChange={onSelectedValueChange}
			shouldClose={shouldClose}
			setShouldClose={setShouldClose}
			containerSx={sx}
			externalTextInputProps={{
				sx: { backgroundColor: color === "secondary" ? "backgroundSecondary.default" : undefined },
			}}
			deletable
			{...otherProps}
		/>
	);
};

export default UserPickerAutocomplete;
