import { Tooltip, Typography } from "@mui/material";
import { User } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import AvatarWithName from "../../../../AvatarWithName";

export const SharedWithSection = ({ users }: { users: User[] }) => {
	const { t } = useTranslation();

	const nResidualUsers = users.length - 3;
	const upToThreeUsers = users.filter((p, i) => i < 3);
	const overThreeUsers = users.filter((p, i) => i >= 3);

	return (
		<div className="flex gap-2 flex-wrap">
			{users.length === 0 && (
				<div className="px-2 py-1 bg-backgroundSecondary w-fit rounded-xl">
					<Typography>{t("documents.noSharesYet")}</Typography>
				</div>
			)}

			{upToThreeUsers.map((user, i) => (
				<div className="bg-backgroundSecondary px-2 py-1 rounded-xl flex-grow-0 max-w-fit" key={user.id}>
					<AvatarWithName user={user} />
				</div>
			))}

			{nResidualUsers > 0 && (
				<Tooltip
					title={
						<div className="flex flex-col gap-1">
							{overThreeUsers.map((user) => (
								<Typography variant="label" sx={{ color: "white" }} key={user.id}>
									{user.name}
								</Typography>
							))}
						</div>
					}
				>
					<div className="px-6 py-1 bg-backgroundSecondary w-fit rounded-xl">
						<Typography variant="caption" className="text-secondary font-bold">
							+{nResidualUsers}
						</Typography>
					</div>
				</Tooltip>
			)}
		</div>
	);
};
