import { AuthApi } from "certiblok-api-manager";
import { useApi } from "../../../../../utils/hooks/api";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import MLDialog from "../../../../../utils/components/poppers";

export const useHandleGoogleDriveAuth = () => {
	const authApi = useApi(AuthApi);
	const { t } = useTranslation();

	return useMutation({
		mutationFn: () => authApi.getGoogleAuthUrl(),
		onSuccess: (res) => {
			window.location.replace(res.data);
		},
		onError: () => {
			MLDialog.showSnackbar(t("global.errorRetry"), { variant: "error" });
		},
	});
};

export const useHandleDropboxAuth = () => {
	const authApi = useApi(AuthApi);
	const { t } = useTranslation();

	return useMutation({
		mutationFn: () => authApi.getDropboxAuthUrl(),
		onSuccess: (data) => {
			window.location.replace(data.data);
		},
		onError: () => {
			MLDialog.showSnackbar(t("global.errorRetry"), { variant: "error" });
		},
	});
};

export const useHandleOneDriveAuth = () => {
	const authApi = useApi(AuthApi);
	const { t } = useTranslation();

	return useMutation({
		mutationFn: () => authApi.getMicrosoftAuthUrl(),
		onSuccess: (data) => {
			window.location.replace(data.data);
		},
		onError: () => {
			MLDialog.showSnackbar(t("global.errorRetry"), { variant: "error" });
		},
	});
};
