import { Box, switchClasses } from "@mui/material";
import IOSSwitch, { IOSSwitchProps } from "../../../../../utils/components/IOSSwitch";
import CertiblokIcon, { CertiblokIconNames } from "../../../../../utils/components/icons/CertiblokIcon";

const EditCreateFolderLockedSwitch: React.FC<{ icon: CertiblokIconNames } & IOSSwitchProps> = ({
	sx,
	icon,
	...props
}) => {
	return (
		<IOSSwitch
			{...props}
			icon={
				<Box sx={{ padding: "3px", backgroundColor: "white", borderRadius: "50%" }}>
					<CertiblokIcon name={icon} color="black" size={18} />
				</Box>
			}
			checkedIcon={
				<Box sx={{ padding: "3px", backgroundColor: "white", borderRadius: "50%" }}>
					<CertiblokIcon name={icon} color="black" size={18} />
				</Box>
			}
			sx={{
				height: 32,
				width: 52,
				[`& .${switchClasses.track}`]: {
					borderRadius: 35,
				},
				[`& .${switchClasses.switchBase}`]: {
					margin: 1,
					"&.Mui-checked": {
						transform: "translateX(20px)",
					},
				},
				...sx,
			}}
		/>
	);
};

export default EditCreateFolderLockedSwitch;
