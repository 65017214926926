"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.7.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlanUsagePlanTierEnum = void 0;
exports.PlanUsagePlanTierEnum = {
    Free: 'FREE',
    Pro: 'PRO',
    Extra: 'EXTRA',
    Business: 'BUSINESS',
    Company: 'COMPANY',
    Enterprise: 'ENTERPRISE',
    Dynamic: 'DYNAMIC'
};
