import NiceModal from "@ebay/nice-modal-react";
import { Button, Divider, Typography } from "@mui/material";
import { EventInstance } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import { useModalNavigator, useModalRoute } from "../../../../ModalNavigator/ModalNavigator";
import ResponsiveDialog from "../../../../dialogs/ResponsiveDialog";
import EventTimingDisplay from "../../../../globalCalendar/components/EventsDetailsModals/components/EventTimingDisplay";
import CertiblokIcon from "../../../../icons/CertiblokIcon";

type DeletedEventModalProps = {
	event: EventInstance;
	notes: string;
	allOccurrences: boolean;
};

const DeletedEventModal = NiceModal.create<DeletedEventModalProps>(({ event, notes, allOccurrences }) => {
	const { t } = useTranslation();
	const { visible } = useModalRoute();
	const { popModal } = useModalNavigator();

	const onClose = () => {
		popModal();
	};

	const isRecurrent = (event?.event?.recurrence?.length ?? 0) !== 0;

	return (
		<ResponsiveDialog
			open={visible}
			onClose={onClose}
			header={
				<Typography variant="subtitle1" className="md:mb-8">
					{t("event.eventCancellation")}
				</Typography>
			}
			footer={
				<>
					<Divider />
					<Button
						onClick={onClose}
						variant="outlined"
						className="mt-6"
						color="secondary"
						startIcon={<CertiblokIcon name="close" color="secondary" />}
					>
						{t("global.close")}
					</Button>
				</>
			}
			ModalProps={{ responsive: true }}
		>
			<div className="md:p-0 md:pb-6  p-4">
				<div className="px-3 py-1 bg-[rgba(255,184,0,0.24)] w-fit flex items-center gap-2 rounded-2xl">
					<div className="w-6 h-6 shrink-0">
						<CertiblokIcon name="calendar_bold" className="text-[rgb(255,184,0)]" />
					</div>
					<Typography variant="body2" className="text-black-a60">
						{event.event?.name}
					</Typography>
				</div>

				<Typography className="mt-8 text-black-a87" variant="body2">
					{notes}
				</Typography>

				<Typography variant="subtitle1" className="mt-8">
					{t("event.when")}
				</Typography>
				<EventTimingDisplay event={event} gridClassName="mt-4" />
				{isRecurrent && (
					<>
						<Typography variant="subtitle1" className="mt-8">
							{t("event.recurrentEvent")}
						</Typography>
						<Typography className="mt-4">
							{allOccurrences ? t("event.allOccurrencesDeleted") : t("event.singleOccurrenceDeleted")}
						</Typography>
					</>
				)}
			</div>
		</ResponsiveDialog>
	);
});

export default DeletedEventModal;
