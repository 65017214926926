/** @jsxImportSource @emotion/react */

import type { Audit } from "certiblok-api-manager";
import { CSSProperties, memo, useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import ReactVirtualizedAutoSizer from "react-virtualized-auto-sizer";
import { Virtuoso } from "react-virtuoso";
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AuditRoomSelector from "../../../../../utils/components/auditRooms/AuditRoomSelector";
import Loading from "../../../../../utils/components/Loading";
import NoContents from "../../../../../utils/components/NoContents";
import { useBetterMediaQuery } from "../../../../../utils/hooks/useBetterMediaQuery";
import type { CertiblokDocument } from "../../../../../utils/interfaces/Document";
import { AuditRoomsContext } from "../controllers/AuditRoomsContext";
import AuditRoomsSection from "./AuditRoomsSection";

export const MAX_COLUMN_ELEMENTS = 6;
export const BOTTOM_MARGIN = 50;
export const LIST_HEIGHT = (32 + 8) * MAX_COLUMN_ELEMENTS + BOTTOM_MARGIN;
export const ITEM_WIDTH = 300 + 48;

type Props = {
	document?: CertiblokDocument;
	isModalOpen: boolean;
};

const AuditRoomsInfiniteList: React.FC<Props> = memo(({ isModalOpen, document }) => {
	const { t } = useTranslation();
	const isMd = useBetterMediaQuery("md");
	const { auditRoomsInfiniteLoader, selectedAuditRooms, selectAuditRoom, unselectAuditRoom } =
		useContext(AuditRoomsContext);

	const listRef = useRef<InfiniteLoader>(null);
	const hasMountedRef = useRef(false);

	useEffect(() => {
		if (listRef.current && hasMountedRef.current) {
			listRef.current.resetloadMoreItemsCache();
		}
		hasMountedRef.current = true;
		if (isModalOpen) {
			auditRoomsInfiniteLoader.revalidateData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isModalOpen]);

	const itemCount = useMemo(
		() =>
			auditRoomsInfiniteLoader.hasNextPage
				? Math.ceil(auditRoomsInfiniteLoader.data.length / MAX_COLUMN_ELEMENTS) + 1
				: Math.ceil(auditRoomsInfiniteLoader.data.length / MAX_COLUMN_ELEMENTS),
		[auditRoomsInfiniteLoader.hasNextPage, auditRoomsInfiniteLoader.data]
	);

	const isItemLoaded = useCallback(
		(index: number) =>
			!auditRoomsInfiniteLoader.hasNextPage ||
			index < Math.ceil(auditRoomsInfiniteLoader.data.length / MAX_COLUMN_ELEMENTS),
		[auditRoomsInfiniteLoader.hasNextPage, auditRoomsInfiniteLoader.data.length]
	);

	const renderCallback = useCallback(
		(props: { index: number; style: CSSProperties; data: Audit[] }) => (
			<AuditRoomsSection document={document} {...props} />
		),
		[document]
	);

	return !auditRoomsInfiniteLoader.firstLoading ? (
		auditRoomsInfiniteLoader.data.length > 0 || auditRoomsInfiniteLoader.notFetchedYet ? (
			isMd ? (
				<ReactVirtualizedAutoSizer disableHeight>
					{({ width }) => (
						<InfiniteLoader
							ref={listRef}
							isItemLoaded={isItemLoaded}
							itemCount={itemCount}
							loadMoreItems={auditRoomsInfiniteLoader.fetchData}
						>
							{({ onItemsRendered, ref }) => (
								<List
									height={LIST_HEIGHT}
									itemCount={itemCount}
									itemSize={ITEM_WIDTH}
									onItemsRendered={onItemsRendered}
									ref={ref}
									width={width}
									itemData={auditRoomsInfiniteLoader.data}
									layout="horizontal"
									css={{
										scrollBehavior: "smooth",
									}}
									className="horizontal-scroll"
								>
									{renderCallback}
								</List>
							)}
						</InfiniteLoader>
					)}
				</ReactVirtualizedAutoSizer>
			) : (
				<Virtuoso
					style={{ height: "40vh", width: "100%" }}
					data={auditRoomsInfiniteLoader.data}
					endReached={() => {
						if (auditRoomsInfiniteLoader.hasNextPage && !auditRoomsInfiniteLoader.loading) {
							auditRoomsInfiniteLoader.fetchData();
						}
					}}
					components={{
						Footer: () => {
							if (auditRoomsInfiniteLoader.loading) {
								return <Loading title={t("auditRooms.loading")} />;
							}
							return null;
						},
					}}
					itemContent={(index, auditRoom) => {
						return (
							<div className="mb-2" key={auditRoom.id}>
								<AuditRoomSelector
									auditRoom={auditRoom}
									isSelected={selectedAuditRooms.map((audit) => audit.id).includes(auditRoom.id)}
									toggleSelected={() => {
										if (!selectedAuditRooms.map((audit) => audit.id).includes(auditRoom.id)) {
											selectAuditRoom(auditRoom);
										} else {
											unselectAuditRoom(auditRoom);
										}
									}}
								/>
							</div>
						);
					}}
				/>
			)
		) : (
			<NoContents
				variant="lock"
				title={t("auditRooms.noAuditRoomsYet")}
				sx={{ height: { xs: "200px", md: 350 }, justifyContent: "center" }}
			/>
		)
	) : (
		<Loading title={t("auditRooms.loading")} sx={{ height: { xs: "200px", md: 350 } }} />
	);
});

export default AuditRoomsInfiniteList;
