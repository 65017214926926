import { Tooltip, Typography } from "@mui/material";
import { Audit } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import CertiblokIcon from "../../../../icons/CertiblokIcon";

export const AssignedAuditsSection = ({ audits }: { audits: Audit[] }) => {
	const { t } = useTranslation();

	const nResidualAudits = audits.length - 3;
	const upToThreeAudits = audits.filter((p, i) => i < 3);
	const overThreeAudits = audits.filter((p, i) => i >= 3);

	return (
		<div className="flex gap-2 flex-wrap">
			{audits.length === 0 && (
				<div className="px-2 py-1 bg-backgroundSecondary w-fit rounded-xl">
					<Typography>{t("singleDocument.noAssignedAuditRoom")}</Typography>
				</div>
			)}
			{upToThreeAudits.map((audit) => {
				return (
					<div
						className="bg-backgroundSecondary px-2 py-1 rounded-xl flex-grow-0 max-w-fit flex items-center gap-1"
						key={audit.id}
					>
						<CertiblokIcon name="profile_bold_01" size={20} color={audit.color} />
						<Typography variant="caption" className="text-black/[0.74]">
							{audit.name}
						</Typography>
					</div>
				);
			})}
			{nResidualAudits > 0 && (
				<Tooltip
					title={
						<div className="flex flex-col gap-1">
							{overThreeAudits.map((user) => (
								<Typography variant="label" sx={{ color: "white" }} key={user.id}>
									{user.name}
								</Typography>
							))}
						</div>
					}
				>
					<div className="px-6 py-1 bg-backgroundSecondary w-fit rounded-xl">
						<Typography variant="caption" className="text-secondary font-bold">
							+{nResidualAudits}
						</Typography>
					</div>
				</Tooltip>
			)}
		</div>
	);
};
