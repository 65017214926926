import { zodResolver } from "@hookform/resolvers/zod";
import { Time, ZonedDateTime } from "@internationalized/date";
import { AttendeeRoleEnum } from "certiblok-api-manager";
import { DateTime } from "luxon";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RRule } from "rrule";
import * as z from "zod";
import { availableEventRepetitions } from "../components/EditCreateEventRepetition/hooks/useEventRepetitionOptions";
import { availableReminders } from "../components/EditCreateEventReminder";
import { DateValue } from "react-aria-components";

export function useEditCreateEventFormValidator() {
	const { t } = useTranslation();

	const schema = useMemo(() => {
		return z.object({
			name: z.string().min(1, {
				message: t("events.insertATitle"),
			}),
			annotation: z.string().optional(),
			startDate: z
				.custom<DateValue>()
				.nullish()
				.refine(
					(value) => {
						if (!value) {
							return false;
						}
						return true;
					},
					{
						message: t("events.insertAStartDate"),
					}
				),
			endDate: z.custom<DateValue>().nullish(),
			isAllDay: z.boolean().optional(),
			startTime: z.custom<Time>().optional(),
			endTime: z.custom<Time>().optional(),
			recurrence: z.custom<RRule>().optional(),
			selectedRecurrenceOption: z.enum(availableEventRepetitions),
			reminder: z
				.object({
					isAtEventTime: z.boolean().optional(),
					unit: z.enum(["minutes", "hours", "days", "weeks", "months"]).optional(),
					amount: z.number().optional(),
				})
				.optional()
				.default({
					isAtEventTime: false,
					unit: "hours",
					amount: 1,
				}),
			selectedReminderOption: z.enum(availableReminders).optional(),
			notifications: z
				.object({
					email: z.boolean().optional(),
					inApp: z.boolean().optional(),
				})
				.optional(),
			sharedWith: z
				.array(
					z.object({
						userId: z.string().nullish(),
						name: z.string().nullish(),
						surname: z.string().nullish(),
						email: z.string().nullish(),
						profilePictureUrl: z.string().nullish(),
						hasBeenAdded: z.boolean().nullish(),
						hasBeenDeleted: z.boolean().nullish(),
						hasBeenEdited: z.boolean().nullish(),
						role: z.nativeEnum(AttendeeRoleEnum),
					})
				)
				.optional(),
		});
	}, [t]);

	const validator = useCallback(
		async (data: z.infer<typeof schema>, options: any, context: any) => {
			let newSchema: z.ZodObject<any> = schema;

			if (data.startDate != null) {
				newSchema = newSchema.extend({
					endDate: z
						.custom<ZonedDateTime>()
						.optional()
						.refine(
							(value) => {
								if (!value) return true;
								const startDate = DateTime.fromJSDate(data.startDate!.toDate(DateTime.now().toFormat("z")));
								const endDate = DateTime.fromJSDate(value?.toDate());
								return endDate >= startDate;
							},
							{
								message: t("events.endDateCannotBeBeforeStartDate"),
							}
						),
				});
			}

			return zodResolver(newSchema)(data, options, context as any);
		},
		[schema, t]
	);

	return {
		schema,
		validator,
	};
}

export type EditCreateEventForm = z.infer<ReturnType<typeof useEditCreateEventFormValidator>["schema"]>;
