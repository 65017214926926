import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import type { CertiblokDocument } from "../../../../../interfaces/Document";
import AssignExpireDate from "../../../../documents/AssignExpireDate";
import { useHandleHeightChange, useModalNavigator } from "../../../../ModalNavigator/ModalNavigator";
import { withBottomSheetNavigator } from "../../../../ModalNavigatorWrapper";
import { useUploadDocumentContext } from "../../../contexts/UploadDocumentContext";
import { useEditUploadedFileDraft, useUploadedFileDraftById } from "../../../stores/uploadedFilesStore";
import { TitleWithTextField } from "./TitleWithTextField";

const AssignExpireDateWithModalNavigator = withBottomSheetNavigator(AssignExpireDate);

type DocumentExpirationDateProps = {
	document: CertiblokDocument;
};
const DocumentExpirationDate: React.FC<DocumentExpirationDateProps> = ({ document }) => {
	const { t } = useTranslation();
	const { pushBottomSheet, popBottomSheet } = useModalNavigator();
	const { mainBottomSheetRef } = useUploadDocumentContext();
	const uploadedDocumentDraft = useUploadedFileDraftById(document.id);
	const editDocumentDraft = useEditUploadedFileDraft();

	const handleHeightChange = useHandleHeightChange();

	return (
		<div>
			<TitleWithTextField
				onClick={() => {
					pushBottomSheet({
						component: AssignExpireDateWithModalNavigator,
						ref: mainBottomSheetRef.current,
						props: {
							document,
							onHeightChange: (height) => handleHeightChange(height ?? 0),
							onSubmitDate: (date, hasNoExpirationDate) => {
								editDocumentDraft(document.id, {
									expirationDate: hasNoExpirationDate ? null : date.toISO(),
								});
								popBottomSheet();
							},
						},
					});
				}}
				title={t("uploadDocument.documentExpiration")}
				placeholder="Es. 22/10/2023"
				value={
					uploadedDocumentDraft.expirationDate
						? DateTime.fromISO(uploadedDocumentDraft.expirationDate).toFormat("dd/MM/yyyy")
						: ""
				}
			/>
		</div>
	);
};
export default DocumentExpirationDate;
