import { Spinner } from "@mabi-ui/spinner";
import { IconButton, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Chat, ChatMessage } from "certiblok-api-manager";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import CertiblokTextInput from "../../../../../../utils/components/CertiblokTextInput";
import CertiblokIcon from "../../../../../../utils/components/icons/CertiblokIcon";
import NoContents from "../../../../../../utils/components/NoContents";
import { getGetInfiniteChatMessagesQueryKey, useGetInfiniteChatMessages } from "../../hooks/useGetChatMessages";
import { useSendMessage } from "../../hooks/useSendMessage";
import ShareNotesHeader from "./ShareNotesHeader";
import SingleMessage from "./SingleMessage";
import { SlidingLoading } from "@mabi-ui/sliding-loading";
import { cn } from "@mabi-ui/utils";
import SingleInfoMessage from "./SingleInfoMessage";

type ShareNotesProps = {
	documentId: string;
	onNavigateBack?: () => void;
	onClose: () => void;
	chat?: Chat;
	invalidateSharings: () => void;
	isSingleChat?: boolean;
};

const ShareNotes = ({
	onNavigateBack,
	chat,
	onClose,
	documentId,
	invalidateSharings,
	isSingleChat = false,
}: ShareNotesProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const [newReply, setNewReply] = useState("");

	const {
		data: chatMessages,
		isLoading: isLoadingChatMessages,
		hasNextPage: hasMoreMessages,
		fetchNextPage: fetchNewMessages,
		isFetching,
	} = useGetInfiniteChatMessages({
		chatId: chat?.id ?? "",
	});

	const isAtChatBeginning = useInView({
		onChange: (inView) => {
			if (inView && hasMoreMessages && !isFetching) {
				fetchNewMessages();
			}
		},
	});

	const { mutate: reply, isLoading: isReplying } = useSendMessage({
		chatId: chat?.id ?? "",
		onSuccess: () => {
			setNewReply("");
			queryClient.invalidateQueries(getGetInfiniteChatMessagesQueryKey(chat?.id ?? ""));
		},
	});

	const allChatMessages = useMemo(
		() => chatMessages?.pages?.reduce((acc, page) => [...acc, ...page.data], [] as ChatMessage[]) ?? [],
		[chatMessages]
	);

	return (
		<div className="flex-1 h-full min-h-full flex flex-col max-w-full w-full">
			<ShareNotesHeader
				documentId={documentId}
				invalidateSharings={invalidateSharings}
				onClose={onClose}
				chat={chat}
				onNavigateBack={onNavigateBack}
				isSingleChat={isSingleChat}
			/>
			<div className="bg-gray-200 py-1 px-2 min-h-[28px] box-border flex flex-col justify-center">
				{chat?.createdAt && (
					<Typography variant="caption" component="p" className="font-bold text-center w-full leading-[14px]">
						{t("sharingNotes.documentSharedOnAt", {
							date: DateTime.fromISO(chat?.createdAt).toFormat("dd/MM/yyyy"),
							daytime: DateTime.fromISO(chat?.createdAt).toFormat("HH:mm"),
						})}
					</Typography>
				)}
			</div>
			{!chat ? (
				<div className="flex-1"></div>
			) : isLoadingChatMessages ? (
				<div className="flex-1 flex flex-col-reverse overflow-y-auto w-full">
					{Array(4)
						.fill("placeholder")
						.map((_, i) => (
							<div
								key={i}
								className={cn(
									`w-[min(300px,90%)] h-[80px] my-[6px] bg-gray-100 animate-pulse rounded-2xl flex-shrink-0`,
									i % 3 === 0 ? "mr-3 self-end" : "ml-3",
									i === 3 && `w-[max(40%,100px)]`,
									i === 2 && "h-[90px]"
								)}
							/>
						))}
				</div>
			) : allChatMessages.length === 0 ? (
				<div className="flex-1 flex flex-col justify-center">
					<div className="w-fit py-2 px-6 mx-auto bg-secondary-dark/[0.06] rounded-3xl">
						<NoContents variant="paper" title={t("sharingNotes.noMessageYet")} typographyProps={{ className: "mb-2" }} />
					</div>
				</div>
			) : (
				<div className="flex-1 flex flex-col-reverse overflow-y-auto relative">
					{(allChatMessages ?? []).map((message, i) =>
						message.type === "text" ? (
							<SingleMessage
								key={message.id}
								note={message}
								isLastNote={message.id === chatMessages?.pages?.[0]?.data?.[0]?.id}
							/>
						) : (
							<SingleInfoMessage
								key={message.id}
								note={message}
								isLastNote={message.id === chatMessages?.pages?.[0]?.data?.[0]?.id}
							/>
						)
					)}
					<div ref={isAtChatBeginning.ref} className="w-full min-h-[1px]" />
					<div className="absolute top-4 left-1/2 -translate-x-1/2">
						<SlidingLoading isLoading={isFetching} className="[&>div]:border-secondary [&>div]:border-t-gray-300" />
					</div>
				</div>
			)}
			{chat?.isActive === false && (
				<div className="bg-gray-200 py-1 px-4 min-h-[24px] box-border">
					<Typography variant="caption" component={"p"} className="text-black/[0.8] text-center w-full">
						{t("sharingNotes.chatIsNotActiveDescription")}
					</Typography>
				</div>
			)}
			<div className="bg-gray-100 min-h-12 w-full px-3 py-3 pb-4 box-border">
				<div className="flex w-full gap-3 items-center">
					<CertiblokTextInput
						fullWidth
						multiline
						className="bg-white hover:filter-none hover:bg-white/[0.72]"
						color="secondary"
						value={newReply}
						onChange={(e) => setNewReply(e.target.value)}
						maxRows={3}
						placeholder={t("sharingNotes.insertYourMessage")}
						disabled={!chat || isReplying || !chat?.isActive}
					/>
					<IconButton
						size="small"
						className="w-8 h-8 flex-shrink-0 bg-secondary-dark hover:bg-[#358697] active:bg-[#2e7583] disabled:opacity-50"
						disabled={newReply.length === 0 || isLoadingChatMessages || isReplying || !chat?.isActive}
						onClick={() => {
							reply({
								content: newReply,
							});
						}}
					>
						{isReplying ? (
							<Spinner className="w-5 h-5 border-white border-t-transparent" />
						) : (
							<CertiblokIcon name={"send_outline"} color="white" size={20} />
						)}
					</IconButton>
				</div>
			</div>
		</div>
	);
};

export default ShareNotes;
