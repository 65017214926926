import { createContext, RefObject, useContext } from "react";
import type { CertiblokDocument } from "../../../interfaces/Document";
import type { BottomSheetV2Ref } from "../../bottomSheet/BottomSheet";

export type UploadDocumentContextValue = {
	mainBottomSheetRef: RefObject<BottomSheetV2Ref>;
	onSuccess?: (value: { documents: CertiblokDocument[]; selectedFolder: string }) => void;
	onCancel?: () => void;
	type: "uploadDocument" | "uploadEditRoomDocument";
};

export const UploadDocumentContext = createContext({} as UploadDocumentContextValue);

export function useUploadDocumentContext() {
	return useContext(UploadDocumentContext);
}
