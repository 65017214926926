import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationDE from "./locales/de/translation.json";
import translationEN from "./locales/en/translation.json";
import translationES from "./locales/es/translation.json";
import translationFR from "./locales/fr/translation.json";
import translationIT from "./locales/it/translation.json";

export const resources = {
	en: {
		translation: translationEN,
	},
	it: {
		translation: translationIT,
	},
	de: {
		translation: translationDE,
	},
	fr: {
		translation: translationFR,
	},
	es: {
		translation: translationES,
	},
};

i18n
	.use(LanguageDetector) //TODO: attivare per prod
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		fallbackLng: "en",
		// lng: "it", //rimuovere con langauage detector attivo

		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;
