import type { Audit } from "certiblok-api-manager";
import { useAtom, useAtomValue } from "jotai";
import AuditRoomSelector from "../../../auditRooms/AuditRoomSelector";
import { alreadySelectedAuditRoomIdsAtom, selectedAuditRoomIdsAtom } from "../atoms/selectedAuditRoomIdsAtom";

type GroupedAuditRoomsProps = {
	auditRooms: Audit[];
};
const GroupedAuditRooms: React.FC<GroupedAuditRoomsProps> = ({ auditRooms }) => {
	const [selectedAuditRoomIds, setSelectedAuditRoomIds] = useAtom(selectedAuditRoomIdsAtom);
	const alreadySelectedAuditRoomIds = useAtomValue(alreadySelectedAuditRoomIdsAtom);

	return (
		<>
			{auditRooms.map((auditRoom) => {
				return (
					<div className="mb-2 pr-12" key={auditRoom.id}>
						<AuditRoomSelector
							auditRoom={auditRoom}
							isSelected={selectedAuditRoomIds.includes(auditRoom.id)}
							isDisabled={alreadySelectedAuditRoomIds.includes(auditRoom.id)}
							toggleSelected={() => {
								setSelectedAuditRoomIds((prev) => {
									if (prev.includes(auditRoom.id)) {
										return prev.filter((id) => id !== auditRoom.id);
									} else {
										return [...prev, auditRoom.id];
									}
								});
							}}
						/>
					</div>
				);
			})}
		</>
	);
};
export default GroupedAuditRooms;
