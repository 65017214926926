import { Box, Button, Fade, FormControlLabel, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBetterMediaQuery } from "../../hooks/useBetterMediaQuery";
import type { CertiblokDocument } from "../../interfaces/Document";
import BetterCheckbox from "../BetterCheckbox";
import BottomSheet, { BottomSheetV2Ref } from "../bottomSheet/BottomSheet";
import type { OldBottomSheetProps } from "../bottomSheet/OldBottomSheet";
import LoadingIconButton from "../buttons/LoadingIconButton";
import DocumentWithIcon from "../DocumentWithIcon";
import CertiblokIcon from "../icons/CertiblokIcon";
import Calendar from "../scrollableCalendar/Calendar";
import MobileCalendar from "../scrollableCalendar/MobileCalendar";

type AssignExpireDateProps = Omit<OldBottomSheetProps, "children"> & {
	document: CertiblokDocument | CertiblokDocument[];
	toggleOpen: () => void;
	submitLoading?: boolean;
	onSubmitDate?: (date: DateTime, hasNoExpirationDate: boolean) => void | Promise<any>;
	initialDate?: DateTime;
};

const AssignExpireDate = forwardRef<BottomSheetV2Ref, AssignExpireDateProps>(
	({ document, open, toggleOpen, onSubmitDate, initialDate, submitLoading, onHeightChange, ...otherProps }, ref) => {
		const isMd = useBetterMediaQuery("md");

		const [selectedDate, setSelectedDate] = useState<DateTime | undefined>();

		const [hasNoExpireDate, setHasNoExpireDate] = useState(
			Array.isArray(document) && document.length === 1
				? document[0].noExpiration ?? false
				: !Array.isArray(document)
				? document.noExpiration ?? false
				: false
		);

		const [documentExpireDate, setExpireDate] = useState(
			initialDate
				? initialDate
				: Array.isArray(document) && document.length > 1
				? DateTime.now()
				: Array.isArray(document)
				? DateTime.fromISO(document[0]?.expireDate ?? DateTime.now().toISO()!)
				: DateTime.fromISO(document?.expireDate ?? DateTime.now().toISO()!)
		);

		const submitDate = useCallback(() => {
			if (selectedDate) return onSubmitDate?.(selectedDate.startOf("day"), hasNoExpireDate);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [selectedDate, hasNoExpireDate]);

		const onClose = () => {
			// setHasNoExpireDate(false);
			toggleOpen();
		};

		useEffect(() => {
			setHasNoExpireDate(
				Array.isArray(document) && document.length === 1
					? document[0].noExpiration ?? false
					: !Array.isArray(document)
					? document.noExpiration ?? false
					: false
			);

			const documentDate = initialDate
				? initialDate
				: Array.isArray(document) && document.length > 1
				? DateTime.now()
				: Array.isArray(document)
				? DateTime.fromISO(document[0]?.expireDate ?? DateTime.now().toISO()!)
				: DateTime.fromISO(document?.expireDate ?? DateTime.now().toISO()!);
			setExpireDate(documentDate);

			setSelectedDate(documentDate);
		}, [document, initialDate]);

		const { t } = useTranslation();

		const disabledBefore = useMemo(() => {
			return DateTime.now();
		}, []);

		return (
			<BottomSheet
				open={open}
				onClose={onClose}
				// containerSx={{ display: "flex", flexDirection: "column" }}
				keepMounted={false}
				ref={ref}
				header={
					<Typography
						children={t("documents.assignExpireDate", { count: Array.isArray(document) ? document.length : 1 })}
						variant="subtitle1"
					/>
				}
				footer={
					<Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
						<Button variant="outlined" color="secondary" onClick={() => onClose()}>
							<CertiblokIcon size={24} color="secondary" name={"close"} />
							{t("global.cancel")}
						</Button>

						<LoadingIconButton
							variant="contained"
							color="secondary"
							onClick={submitDate}
							icon={<CertiblokIcon size={24} color="white" name={"success"} />}
							loading={submitLoading}
						>
							{t("global.confirm")}
						</LoadingIconButton>
					</Box>
				}
				onHeightChange={onHeightChange}
				// {...otherProps}
			>
				<Box
					sx={{
						flexGrow: 1,
						gap: 6,
						px: {
							xs: 5,
							md: 12,
						},
						boxSizing: "border-box",
					}}
				>
					<Box sx={{ display: "flex", gap: 4, flexWrap: "wrap" }}>
						{Array.isArray(document) ? (
							document.map((elem) => <DocumentWithIcon name={elem.name} key={elem.id} document={elem.lastVersionFile?.[0]} />)
						) : (
							<DocumentWithIcon name={document.name} document={document.lastVersionFile?.[0]} />
						)}
					</Box>
					<Box sx={{ paddingTop: "28px" }}>
						<FormControlLabel
							control={
								<BetterCheckbox
									checked={hasNoExpireDate}
									color="secondary"
									onClick={() => setHasNoExpireDate((prev) => !prev)}
								/>
							}
							label={t("documents.noAssignedExpireDate")}
							sx={{
								"& .MuiFormControlLabel-label": {
									typography: "body1",
								},
							}}
						/>
					</Box>
					{isMd ? (
						<div className="relative">
							<Calendar
								shouldHideSelectedDate={hasNoExpireDate}
								onDateChange={(date) => setSelectedDate(date)}
								initialDate={documentExpireDate}
							/>

							<Fade in={hasNoExpireDate} mountOnEnter unmountOnExit>
								<div className="absolute top-0 left-0 w-full h-full bg-[rgba(255,255,255,0.64)]" />
							</Fade>
						</div>
					) : (
						<div className="flex items-center justify-center pt-6 pb-3 relative">
							<MobileCalendar
								key={hasNoExpireDate ? "hide" : "show"}
								onDateChange={(date) => setSelectedDate(date)}
								date={hasNoExpireDate ? undefined : selectedDate}
								disabledBefore={disabledBefore}
							/>
							<Fade in={hasNoExpireDate} mountOnEnter unmountOnExit>
								<div className="absolute top-0 left-0 w-full h-full bg-[rgba(255,255,255,0.64)]" />
							</Fade>
						</div>
					)}
				</Box>
			</BottomSheet>
		);
	}
);

export default AssignExpireDate;
