import { useMutation, useQuery } from "@tanstack/react-query";
import { DocumentApi, SharingGroupApi } from "certiblok-api-manager";
import { useApi } from "../../../utils/hooks/api";

export const getGroupSharedDocumentsQueryKey = (groupId: string) => ["contactsGroupSharedDocuments", groupId];

export const useGetGroupSharedDocuments = ({ groupId, enabled }: { groupId?: string; enabled?: boolean }) => {
	const sharingGroupApi = useApi(SharingGroupApi);
	return useQuery({
		queryKey: getGroupSharedDocumentsQueryKey(groupId ?? ""),
		queryFn: () => {
			return sharingGroupApi.getSharingGroupSharedDocuments(groupId ?? "");
		},
		select: (data) => data.data,
		keepPreviousData: true,
		enabled: enabled,
	});
};

export const useUnshareDocumentsWitGroup = (
	props: { onSuccess?: () => void; onMutate?: () => void; onSettled?: () => void } | undefined
) => {
	const documentApi = useApi(DocumentApi);
	return useMutation({
		mutationFn: ({ documentIds, groupId }: { documentIds: string[]; groupId: string }) => {
			return Promise.all(
				documentIds.map((documentId) =>
					documentApi.unshareDocumentsWithSharingGroup(documentId, { sharingGroupId: groupId })
				)
			);
		},
		onMutate: () => {
			props?.onMutate?.();
		},
		onSuccess: () => {
			props?.onSuccess?.();
		},
		onSettled: () => {
			props?.onSettled?.();
		},
	});
};

// export const useUnshareAllDocumentsWitGroup = (
// 	props: { onSuccess?: () => void; onMutate?: () => void; onSettled?: () => void } | undefined
// ) => {
// 	const sharingGroupApi = useApi(SharingGroupApi);

// 	return useMutation({
// 		mutationFn: ({ documentIds, groupId }: { documentIds: string[]; groupId: string }) => {
// 			return sharingGroupApi.unshareAllDocumentsWithSharingGroup(groupId);
// 		},
// 		onMutate: () => {
// 			props?.onMutate?.();
// 		},
// 		onSuccess: () => {
// 			props?.onSuccess?.();
// 		},
// 		onSettled: () => {
// 			props?.onSettled?.();
// 		},
// 	});
// };
