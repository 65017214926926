import { useTranslation } from "react-i18next";
import MenuButton from "../../MenuButton";
import { ModalNavigator, useModalNavigator, useModalRoute } from "../../ModalNavigator/ModalNavigator";
import RowMenu from "../../poppers/RowMenu";

type SwitchViewMenuProps = {
	onSuccess?: (view: "tree" | "table") => void;
	anchorEl: HTMLElement | null;
};
const SwitchViewMenu = ModalNavigator.create<SwitchViewMenuProps>(({ onSuccess, anchorEl }) => {
	const { t } = useTranslation();
	const { visible } = useModalRoute();
	const { popModal } = useModalNavigator();

	return (
		<RowMenu open={visible} anchorEl={anchorEl} onClose={popModal}>
			<div className=" box-border bg-white flex flex-col p-1 rounded-xl">
				<MenuButton
					children={t("folders.asTree")}
					onClick={() => {
						popModal();
						onSuccess?.("tree");
					}}
					// icon="tree_view"
					icon="tree_view"
				/>
				<MenuButton
					children={t("folders.asList")}
					onClick={() => {
						popModal();
						onSuccess?.("table");
					}}
					icon="organize_outline"
				/>
			</div>
		</RowMenu>
	);
});
export default SwitchViewMenu;
