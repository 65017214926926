import type { AuthenticationResponse } from "certiblok-api-manager";
import { useSetAtom } from "jotai";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { AbilityAtomStorage } from "./useAbilityRules";
import { useMe } from "./useMe";

export function useABACHandler() {
	const { reload } = useMe();
	const setAbility = useSetAtom(AbilityAtomStorage);
	const navigate = useNavigate();

	const loadABACPermissionAndNavigateTo = useCallback(
		(abacPermissions: AuthenticationResponse, navigateUrl?: string) => {
			if (abacPermissions?.redisKey) {
				localStorage.clear();
				setAbility(abacPermissions?.abilityRules || []);
				reload();

				if (navigateUrl) {
					navigate(navigateUrl, { replace: true });
				}
			}
		},
		[navigate, reload, setAbility]
	);

	return {
		loadABACPermissionAndNavigateTo,
	};
}
