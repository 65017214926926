import { EditRoomApi } from "certiblok-api-manager";
import { useLocation, useParams } from "react-router-dom";
import { useApi, useFetch } from "../../../utils/hooks/api";

export function useIsEditRoom() {
	const location = useLocation();
	const isInEditRoom = location.pathname.includes("edit_rooms");

	const { editRoomId } = useParams();

	const editRoomApi = useApi(EditRoomApi);

	const { data: editRoom } = useFetch(editRoomApi.getPlainEditRoomFolder, editRoomId ?? "", { skip: !editRoomId });

	return { isInEditRoom, editRoom };
}
