import { IconButton, IconButtonProps, SxProps, Theme, Typography, TypographyProps } from "@mui/material";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import CertiblokIcon from "./icons/CertiblokIcon";

type BackButtonWithTitleProps = {
	title?: string;
	onClick?: () => void;
	className?: string;
	typographyClassName?: string;
	href?: string;
	color?: IconButtonProps["color"];
	sx?: SxProps<Theme>;
	typographySx?: SxProps<Theme>;
	variant?: TypographyProps["variant"];
	fullWidth?: boolean;
	size?: IconButtonProps["size"];
};

export const BackButtonWithTitle = ({
	title,
	onClick,
	className,
	typographyClassName,
	href,
	color,
	sx,
	typographySx,
	variant,
	fullWidth,
	size,
}: BackButtonWithTitleProps) => {
	const navigate = useNavigate();

	return (
		<div className={twMerge(clsx("flex gap-2 items-center", fullWidth && "w-full", className))}>
			<IconButton
				onClick={onClick ? () => onClick() : href ? () => {} : () => navigate(-1)}
				color={color ?? "default"}
				href={href ?? ""}
				sx={{ ...sx }}
				className="flex-shrink-0 flex-grow-0"
				size={size ?? "medium"}
			>
				<CertiblokIcon
					name="arrow_mini_outline_left"
					size={size === "small" ? 24 : size === "large" ? 32 : 28}
					color={color ?? "black"}
				/>
			</IconButton>
			{title && (
				<Typography
					variant={variant ?? "h6"}
					className={twMerge(
						clsx(
							"font-extrabold truncate",
							fullWidth && "" && fullWidth && size === "small"
								? "w-full text-center mr-11"
								: fullWidth && size === "large"
								? "w-full text-center mr-14"
								: " w-full text-center mr-[34px]",
							typographyClassName
						)
					)}
					sx={{
						color: (theme) =>
							color === "inherit" ? "inherit" : color === "default" ? "black" : color ? theme.palette[color].main : "black",
						...typographySx,
					}}
				>
					{title}
				</Typography>
			)}
		</div>
	);
};
