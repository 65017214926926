import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Tag, TagsApi } from "certiblok-api-manager";
import React, { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import MLDialog from "../../../utils/components/poppers";
import { defaultQueryFilter, IQueryFilter, transformToStringFrom } from "../../../utils/components/tables/AdminTable";
import { apiErrorParser, useApi } from "../../../utils/hooks/api";
import { getTagsQueryKey } from "../../SingleDocument/hooks/useTagsHooks";

const getTagsTableQueryKey = (filters: IQueryFilter) => ["table-tags", filters];

export function useTagsContext() {
	const queryClient = useQueryClient();
	const [searchParams, setSearchParams] = useSearchParams();

	const tagsApi = useApi(TagsApi);

	const [queryFilter, setQueryFilter] = useState<IQueryFilter>(() => {
		const queryFilterString = searchParams.get("queryFilter");
		return !queryFilterString
			? { ...defaultQueryFilter, orderBy: "name", order: "asc" }
			: JSON.parse(searchParams.get("queryFilter") || "{}");
	});

	const {
		data: tags,
		isLoading: isTagsLoading,
		isFetching: isTagsFetching,
	} = useQuery({
		queryKey: getTagsTableQueryKey(queryFilter),
		queryFn: () => tagsApi.getAllTags(transformToStringFrom({ ...queryFilter })),
		onError: (e) => {
			MLDialog.showSnackbar(apiErrorParser(e), {
				variant: "error",
			});
		},
		keepPreviousData: true,
		select: (res) => {
			return {
				...res,
				data: res.data as Tag[],
				totalTagsCount: parseInt(res?.headers?.["x-total-count"]),
			};
		},
	});

	const revalidateTags = useCallback(() => {
		queryClient.invalidateQueries(getTagsQueryKey());
		queryClient.invalidateQueries(getTagsTableQueryKey(queryFilter));
	}, [queryClient, queryFilter]);

	const [selectedTagsIds, setSelectedTagsIds] = useState<string[]>([]);

	const toggleTagSelection = useCallback((tagId: string) => {
		setSelectedTagsIds((prev) => {
			if (prev.includes(tagId)) {
				return prev.filter((t) => t !== tagId);
			} else {
				return [...prev, tagId];
			}
		});
	}, []);

	const toggleAllTagsSelection = useCallback(() => {
		setSelectedTagsIds((prev) => {
			if (prev.length === (tags?.data ?? []).length) {
				return [];
			} else {
				return tags?.data?.map((t) => t.id) ?? [];
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tags]);

	const emptyTagsSelection = useCallback(() => {
		setSelectedTagsIds([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		tags,
		isTagsFetching,
		isTagsLoading,
		revalidateTags,
		selectedTagsIds,
		toggleTagSelection,
		toggleAllTagsSelection,
		queryFilter,
		setQueryFilter,
		setSearchParams,
		emptyTagsSelection,
	};
}

type TagsContextType = ReturnType<typeof useTagsContext>;

export const TagsContext = React.createContext<TagsContextType>({} as TagsContextType);
