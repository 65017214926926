import { Notification, NotificationApi } from "certiblok-api-manager";
import { createContext, useCallback, useContext, useState } from "react";
import { apiErrorParser, useApi } from "../../../../../hooks/api";
import { defaultQueryFilter, transformToStringFrom } from "../../../../tables/AdminTable";

export function useNotifications(closePopper: () => void) {
	//* APIS
	const notificationApi = useApi(NotificationApi);

	const [notifications, setNotifications] = useState<Notification[]>([]);
	const [loading, setLoading] = useState(false);

	const fetchNotifications = useCallback(
		(page: number, perPage: number) => {
			setLoading(true);
			return notificationApi
				.listNotifications(transformToStringFrom({ ...defaultQueryFilter, page, perPage }))
				.then((res) => {
					if (page === 0) {
						setNotifications(res.data);
					} else {
						setNotifications((nots) => [...nots, ...res.data]);
					}
				})
				.catch((err) => apiErrorParser(err))
				.finally(() => setLoading(false));
		},
		[notificationApi]
	);

	const resetNotifications = useCallback(() => {
		setNotifications([]);
	}, []);

	return {
		notifications,
		loading,
		fetchNotifications,
		resetNotifications,
		closePopper,
	};
}

type NotificationsContextType = ReturnType<typeof useNotifications>;

const NotificationsContext = createContext<NotificationsContextType | undefined>(undefined);

export const NotificationsContextProvider: React.FC<{ notificationsContext: NotificationsContextType }> = ({
	children,
	notificationsContext,
}) => <NotificationsContext.Provider value={notificationsContext}>{children}</NotificationsContext.Provider>;

export function useNotificationsContext() {
	const context = useContext(NotificationsContext);

	return context;
}
