import { cn } from "@mabi-ui/utils";
import { Button, Fade, Typography } from "@mui/material";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { SwitchTransition } from "react-transition-group";
import BetterCheckbox from "../../../../../utils/components/BetterCheckbox";
import { useModalNavigator } from "../../../../../utils/components/ModalNavigator/ModalNavigator";
import NoContents from "../../../../../utils/components/NoContents";
import { useBetterMediaQuery } from "../../../../../utils/hooks/useBetterMediaQuery";
import useGetContactSharedDocuments from "../../../hooks/useGetContactSharedDocuments";
import { useGetGroupSharedDocuments } from "../../../hooks/useGetGroupSharedDocuments";
import SharedNotesRow from "./SharedNotesRow";
import { UnshareDocumentsModal, UnshareDocumentsWithGroupModal } from "./UnshareDocumentsModal";

const commonPadding = "sm:px-3 px-1 sm:py-2 py-1";

const Th = ({ children, className, colSpan }: { children?: ReactNode; colSpan?: number; className?: string }) => {
	return (
		<th
			colSpan={colSpan}
			className={cn(
				// "first-of-type:rounded-tl-md last-of-type:rounded-tr-md",
				commonPadding,
				"bg-secondary/[0.12] text-left",
				className
			)}
		>
			{typeof children === "string" ? <Typography variant="subtitle1">{children}</Typography> : children}
		</th>
	);
};

export const Td = ({
	children,
	className,
	colSpan,
}: {
	children?: ReactNode;
	colSpan?: number;
	className?: string;
}) => {
	return (
		<td className={cn(commonPadding, className)} colSpan={colSpan}>
			{typeof children === "string" ? <Typography noWrap>{children}</Typography> : children}
		</td>
	);
};

const SharedNotesTable = ({
	contactId,
	userId,
	groupId,
}: {
	contactId?: string;
	userId?: string;
	groupId?: string;
}) => {
	const { t } = useTranslation();
	const { pushModal } = useModalNavigator();
	const isSm = useBetterMediaQuery("sm");
	const isMd = useBetterMediaQuery("md");

	const [selectedDocumentsIds, setSelectedDocumentsIds] = useState<string[]>([]);

	const { data: contactList } = useGetContactSharedDocuments({ contactId, enabled: Boolean(userId) });
	const { data: groupList } = useGetGroupSharedDocuments({ groupId, enabled: Boolean(groupId) });

	const data = userId ? contactList : groupList;

	const activeSharing = data?.filter((d) => d.isActive) ?? [];
	const areAllSelected = selectedDocumentsIds.length === activeSharing.length && activeSharing.length > 0;

	return (
		<div className="w-full">
			<div
				className={cn(
					"bg-secondary/[0.06] h-[50px] rounded-t-lg flex items-center gap-3 justify-between",
					commonPadding,
					"px-2"
				)}
			>
				<Typography className={cn("font-bold")} variant={isMd ? "h6" : "subtitle1"}>
					{t("shareWithContact.filesSharedWithContact")}
				</Typography>
				<SwitchTransition mode="out-in">
					<Fade key={selectedDocumentsIds.length > 0 ? "selected" : "unselected"} timeout={150} unmountOnExit mountOnEnter>
						{selectedDocumentsIds.length > 0 ? (
							<Button
								variant="contained"
								color="secondary"
								onClick={() => {
									if (userId && contactId) {
										pushModal(UnshareDocumentsModal, {
											documentIds: selectedDocumentsIds,
											userId,
											contactId,
											onMutate: () => setSelectedDocumentsIds([]),
										});
									}
									if (groupId) {
										pushModal(UnshareDocumentsWithGroupModal, {
											documentIds: selectedDocumentsIds,
											groupId,
											onMutate: () => setSelectedDocumentsIds([]),
										});
									}
								}}
							>
								{t("shareWithContact.interruptSharingMultiple")}
							</Button>
						) : (
							<Button
								variant="contained"
								color="secondary"
								disabled={activeSharing.length === 0}
								onClick={() => {
									if (userId && contactId && activeSharing.length > 0) {
										pushModal(UnshareDocumentsModal, {
											documentIds: activeSharing.map((d) => d.documentId),
											userId,
											contactId,
											onMutate: () => setSelectedDocumentsIds([]),
										});
									}
									if (groupId && activeSharing.length > 0) {
										pushModal(UnshareDocumentsWithGroupModal, {
											documentIds: activeSharing.map((d) => d.documentId),
											groupId,
											onMutate: () => setSelectedDocumentsIds([]),
										});
									}
								}}
							>
								{t("shareWithContact.interruptAllSharings")}
							</Button>
						)}
					</Fade>
				</SwitchTransition>
			</div>
			<table
				className="w-full table-fixed border-[1px] border-secondary/[0.12] border-solid rounded-b-md"
				cellPadding={0}
				cellSpacing={0}
			>
				<thead className="h-[40px]">
					<tr>
						<Th className="w-9">
							<BetterCheckbox
								color="secondary"
								onClick={() => {
									if (areAllSelected) {
										setSelectedDocumentsIds([]);
									} else {
										setSelectedDocumentsIds(activeSharing.map((d) => d.documentId) ?? []);
									}
								}}
								checked={areAllSelected}
							/>
						</Th>
						<Th colSpan={2}>{t("inbox.fileName")}</Th>
						{isMd && <Th colSpan={1}>{t("documents.version")}</Th>}
						<Th colSpan={1}>{t("global.note")}</Th>
						<Th className={"w-[100px]"}>{t("events.date")}</Th>
						<Th className={isSm ? "w-[170px]" : "w-[44px]"}></Th>
					</tr>
				</thead>
				<tbody>
					{data?.length === 0 ? (
						<tr>
							<Td colSpan={isMd ? 7 : 6}>
								<div className="mb-4 mt-1 text-center">
									<NoContents variant="document" title={t("shareWithContact.noSharedFiles")} />
								</div>
							</Td>
						</tr>
					) : (
						(data ?? []).map((sharedDoc, i) => {
							return (
								<SharedNotesRow
									key={sharedDoc.id}
									sharedDoc={sharedDoc}
									isLast={i === (data?.length ?? 0) - 1}
									onRowCheck={() => {
										if (selectedDocumentsIds.includes(sharedDoc.documentId)) {
											setSelectedDocumentsIds(selectedDocumentsIds.filter((id) => id !== sharedDoc.documentId));
										} else {
											setSelectedDocumentsIds([...selectedDocumentsIds, sharedDoc.documentId]);
										}
									}}
									selectedDocumentsIds={selectedDocumentsIds}
									userId={userId}
									contactId={contactId}
									groupId={groupId}
								/>
							);
						})
					)}
				</tbody>
			</table>
		</div>
	);
};

export default SharedNotesTable;
