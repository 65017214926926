const ImportFromCloudContentLoading = () => {
	return (
		<>
			<div className="my-2 box-border flex flex-col gap-2">
				<div className="w-full rounded-lg animate-pulse bg-gray-200/[0.6] h-8 mb-2 mt-0" />
				<div className="w-full rounded-lg animate-pulse bg-gray-200/[0.6] h-8 my-2" />
				<div className="w-full rounded-lg animate-pulse bg-gray-200/[0.6] h-8 my-2" />
			</div>
		</>
	);
};

export default ImportFromCloudContentLoading;
