import { Box, Typography } from "@mui/material";
import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useVirtual } from "react-virtual";
import LoadingTextButton from "../../../../LoadingTextButton";
import { NOTIFICATIONS_PER_PAGE } from "../constants/NotificationsPerPage";
import { useNotificationsContext } from "../controllers/NotificationContext";
import NotificationRow from "./NotificationRow";

type Props = {
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	readOneNotification: (notificationId: string) => void;
};

const NotificationList: React.FC<Props> = ({ setOpen, readOneNotification }) => {
	const { t } = useTranslation();
	const notificationsContext = useNotificationsContext();

	const parentRef = useRef<HTMLDivElement>();

	const estimateSize = useCallback(() => 110, []);

	const rowVirtualizer = useVirtual({
		size: (notificationsContext?.notifications ?? []).length,
		parentRef: parentRef,
		estimateSize: estimateSize,
	});

	if (notificationsContext === undefined) return null;

	return (
		<Box
			ref={parentRef}
			sx={{ overflow: "auto", width: "100%", maxHeight: { xs: "unset", sm: "70vh" }, paddingBottom: { xs: 3, sm: 0 } }}
		>
			<Box
				sx={{
					height: rowVirtualizer.totalSize,
					width: "100%",
					position: "relative",
				}}
			>
				{rowVirtualizer.virtualItems.map((virtualRow) => (
					<div
						key={`notification_${notificationsContext.notifications[virtualRow.index].id}_${virtualRow.index}`}
						ref={virtualRow.measureRef}
						style={{
							position: "absolute",
							top: 0,
							left: 0,
							width: "100%",
							transform: `translateY(${virtualRow.start}px)`,
						}}
					>
						<NotificationRow
							notification={notificationsContext.notifications[virtualRow.index]}
							markAsRead={() => {
								setOpen(false);
								readOneNotification(notificationsContext.notifications[virtualRow.index].id);
							}}
						/>
					</div>
				))}
			</Box>
			{notificationsContext.notifications.length > 0 && (
				<LoadingTextButton
					size="small"
					onClick={async () => {
						await notificationsContext.fetchNotifications(
							Math.ceil(notificationsContext.notifications.length / NOTIFICATIONS_PER_PAGE),
							NOTIFICATIONS_PER_PAGE
						);
					}}
					sx={{ width: "100%", textAlign: "center" }}
					disabled={notificationsContext.loading}
				>
					<Typography children={t("variousComponents.loadPreviousNotifications")} variant="caption" />
				</LoadingTextButton>
			)}
		</Box>
	);
};

export default NotificationList;
