import { Theme, Box, SxProps } from "@mui/material";

type Props = {
	variant?: "small" | "regular";
	scrollDisabled?: boolean;
	noPadding?: boolean;
	sx?: SxProps<Theme> | undefined;
};

const OutletContainer: React.FC<Props> = ({
	variant = "regular",
	scrollDisabled = false,
	noPadding = false,
	children,
	sx,
}) => {
	return (
		<Box
			sx={{
				width: "100%",
				height: "100%",
				overflowY: !scrollDisabled ? "scroll" : "hidden",
				padding: {
					xs: noPadding ? 0 : 6,
					lg: noPadding ? 0 : 9,
					// xs: 6,
					// lg: 9,
				},
				boxSizing: "border-box",
				borderRadius: "48px 48px 0px 0px",
				backgroundColor: "white",
				position: "relative",
				"&::-webkit-scrollbar": {
					display: "none",
				},
				msOverflowStyle: "none",
				scrollbarWidth: "none",
				...sx,
			}}
		>
			{children}
		</Box>
	);
};

export default OutletContainer;
