/** @jsxImportSource @emotion/react */
import { cn } from "@mabi-ui/utils";
import { Fade, IconButton } from "@mui/material";
import { useContext } from "react";
import CertiblokIcon from "../../icons/CertiblokIcon";
import { ScaffoldContext } from "../controllers/ScaffoldContext";

const LockDrawerButton = ({
	showLockButton,
	handleShowLockButton,
}: {
	showLockButton: boolean;
	handleShowLockButton: (newValue: boolean) => void;
}) => {
	const { setLockDrawerOpen, lockDrawerOpen } = useContext(ScaffoldContext);
	return (
		<div
			className={cn(
				"flex flex-col relative md:absolute top-[11px] -left-[26px] z-[1100] gap-2 rounded-full transition-all p-2 scale-50",
				showLockButton && "md:bg-[#FAFDFB] scale-100"
			)}
			css={{
				boxShadow: showLockButton ? "0px 6px 30px rgba(0, 0, 0, 0.12)" : "none",
			}}
			onMouseEnter={() => {
				!lockDrawerOpen && handleShowLockButton(true);
			}}
			onMouseLeave={() => {
				handleShowLockButton(false);
			}}
		>
			<div className="w-[34px] h-[34px]" />
			<Fade in={showLockButton} unmountOnExit>
				<IconButton
					size="small"
					sx={{
						backgroundColor: { md: "white" },
						boxShadow: { md: "0px 1px 10px rgba(0, 0, 0, 0.12)" },

						"&:hover": {
							backgroundColor: { md: "white" },
							boxShadow: { md: "0px 6px 30px rgba(0, 0, 0, 0.12)" },
						},
					}}
					onClick={() => {
						setLockDrawerOpen((p) => !p);
						handleShowLockButton(false);
					}}
				>
					<CertiblokIcon size={24} color="primary" name={lockDrawerOpen ? "lock_outline_01" : "lock_outline_02"} />
				</IconButton>
			</Fade>
		</div>
	);
};

export default LockDrawerButton;
