import type { User } from "certiblok-api-manager";
import React, { useContext } from "react";

export const UserContext = React.createContext<{ user?: User; reload: () => void; loading: boolean }>({} as any);

export function useMe() {
	const { user, reload, loading } = useContext(UserContext);

	return { me: user, reload, loading };
}
