import { Navigate, RouteProps, useLocation } from "react-router-dom";
import { useAbility } from "../../hooks/useAbilityRules";

type Actions = "create" | "readOne" | "readAll" | "update" | "delete";
interface PrivateRouteProps extends RouteProps {
	allowedIf?: { can: Actions; model: string };
	redirectTo?: string;
}

export function ABACRoute({ children, allowedIf, redirectTo, ...rest }: PrivateRouteProps): any {
	const { ability } = useAbility();

	const location = useLocation();

	const canDoIt = allowedIf ? ability.can(allowedIf?.can, allowedIf?.model) : null;
	const needTwoFactor = ability.can("manage", "2FA");

	const isLoggedIn = ability.can("readOne", "User");

	return canDoIt ? (
		children
	) : (
		<Navigate
			to={redirectTo || (isLoggedIn ? "/" : needTwoFactor ? "/login/two-fa" : "/login")}
			state={{ from: location }}
		/>
	);
}
