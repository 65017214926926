import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import Loading from "../../Loading";
import OutletContainer from "../../OutletContainer";

type SuspenseWrapperProps = {
	children?: React.ReactNode;
};
const SuspenseWrapper: React.FC<SuspenseWrapperProps> = ({ children }) => {
	const { t } = useTranslation();
	return (
		<Suspense
			fallback={
				<OutletContainer sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
					<Loading title={t("variousComponents.pageLoading")} />
				</OutletContainer>
			}
		>
			{children}
		</Suspense>
	);
};
export default SuspenseWrapper;
