import { Avatar, AvatarProps, Box } from "@mui/material";
import type { UserOrContact } from "certiblok-api-manager";
import { backendMediaUrlParser } from "..";
import avatarPlaceholder from "../../assets/avatar-placeholder.png";
import { isUser } from "../../pages/Folders/components/UploadDocument/functions/isUser";

type UserAvatarProps = {
	user?: UserOrContact | string;
	withCompany?: boolean;
} & AvatarProps;
const UserAvatar: React.FC<UserAvatarProps> = ({ user, withCompany, ...props }) => {
	return (
		<Box sx={{ position: "relative" }}>
			<Avatar
				src={typeof user !== "string" && user?.profileUrl ? backendMediaUrlParser(user?.profileUrl) : avatarPlaceholder}
				{...props}
			/>
			{withCompany && typeof user !== "string" && isUser(user) && user.company && (
				<Avatar
					src={backendMediaUrlParser(user.company.pictureUrl) ?? avatarPlaceholder}
					sx={{ height: 33, width: 33, position: "absolute", bottom: 0, right: "-50%" }}
				/>
			)}
		</Box>
	);
};
export default UserAvatar;
