import { CircularProgress, Typography } from "@mui/material";
import CertiblokIcon from "../../../../icons/CertiblokIcon";
import { FileStatusType } from "../components/SingleImportedDocOrFolder";

export const fileStatusIconSwitch = ({ status, progress }: FileStatusType) => {
	switch (status) {
		case "scan":
			return <CircularProgress size={24} color="secondary" />;
		case "import":
			return (
				<Typography variant="caption" className="text-[12px] text-black-a60">{`${Math.round(progress ?? 0)}%`}</Typography>
			);
		case "error":
			return (
				<div className="w-6 h-6 flex items-center justify-center rounded-full bg-error">
					<CertiblokIcon name="close" color="white" />
				</div>
			);
		case "success":
			return (
				<div className="w-6 h-6 flex items-center justify-center rounded-full bg-success">
					<CertiblokIcon name="success" color="white" />
				</div>
			);
	}
};
