import React, { useCallback, useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { proxy } from "valtio";
import { IQueryFilter, defaultQueryFilter } from "../../../utils/components/tables/AdminTable";

const favoriteDefaultQueryFilter: IQueryFilter = { ...defaultQueryFilter, orderBy: "addedToFavoriteAt", order: "desc" };

export function useInitFavoriteContext() {
	//* APIS

	const [searchParams, setSearchParams] = useSearchParams();
	const [queryFilter, setQueryFilter] = useState<IQueryFilter>(favoriteDefaultQueryFilter);

	const [selectedDocumentsTab, setSelectedDocumentsTab] = useState<"documents" | "folders">(
		(searchParams.get("section") as "documents" | "folders" | null) || "documents"
	);

	const updateSelectedDocumentsTab = useCallback(
		(tab: "documents" | "folders") => {
			searchParams.set("section", tab);
			setSearchParams(searchParams, { replace: true });
			setSelectedDocumentsTab(tab);
			setQueryFilter((prev) => ({ ...favoriteDefaultQueryFilter, filter: prev.filter }));
		},
		[searchParams, setSearchParams]
	);

	const [textFilter, setTextFilter] = useState("");

	const [selectedFavoriteItemIdsStore] = useState(() => proxy<{ values: string[] }>({ values: [] }));

	const toggleItemSelection = useCallback((itemId: string) => {
		const index = selectedFavoriteItemIdsStore.values.findIndex((i) => i === itemId);
		if (index === -1) {
			selectedFavoriteItemIdsStore.values.push(itemId);
		} else {
			selectedFavoriteItemIdsStore.values.splice(index, 1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const resetItemsSelection = useCallback(() => {
		selectedFavoriteItemIdsStore.values = [];
	}, [selectedFavoriteItemIdsStore]);

	const selectMultipleItems = useCallback((itemIds: string[]) => {
		itemIds.forEach((id) => {
			if (!selectedFavoriteItemIdsStore.values.includes(id)) {
				selectedFavoriteItemIdsStore.values.push(id);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		selectedDocumentsTab,
		updateSelectedDocumentsTab,
		textFilter,
		setTextFilter,
		toggleItemSelection,
		resetItemsSelection,
		selectedFavoriteItemIdsStore,
		queryFilter,
		setQueryFilter,
		selectMultipleItems,
	};
}

type FavoriteContextType = ReturnType<typeof useInitFavoriteContext>;

export function useFavoriteContext() {
	const context = useContext(FavoriteContext);
	return context;
}

export const FavoriteContext = React.createContext<FavoriteContextType>({} as FavoriteContextType);
