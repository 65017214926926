import { fromDate, parseTime } from "@internationalized/date";
import { DateTime, Duration } from "luxon";
import { useMemo, useRef } from "react";
import { NonUndefined, UseFormReturn } from "react-hook-form";
import { RRule } from "rrule";
import { parseString } from "rrule/dist/esm/parsestring";
import { useMe } from "../../../../../utils/hooks/useMe";
import { EditCreateEventForm } from "./useEditCreateEventFormValidator";
import { useEventInstanceById } from "./useEventInstanceById";

function computeDurationUnit(duration: Duration): NonUndefined<EditCreateEventForm["reminder"]["unit"]> | undefined {
	const durationInDays = duration.as("days");
	const durationInHours = duration.as("hours");
	if (durationInDays === 0 && durationInHours === 0) {
		return undefined;
	}
	if (durationInDays % 30 === 0) {
		return "months";
	}
	if (durationInDays % 7 === 0) {
		return "weeks";
	}
	if (durationInDays.toFixed(0) === durationInDays.toString()) {
		return "days";
	}
	if (durationInHours.toFixed(0) === durationInHours.toString()) {
		return "hours";
	}
	return "minutes";
}

function computeSelectedRepetiton({
	rrule,
	startDate,
}: {
	rrule?: RRule;
	startDate: DateTime;
}): NonUndefined<EditCreateEventForm["selectedRecurrenceOption"]> {
	if (!rrule) return "none";
	if (rrule.options.until || rrule.options.interval > 1) {
		return "custom";
	}
	if (rrule.options.freq === RRule.DAILY) {
		return "everyDay";
	}
	if (
		rrule.options.freq === RRule.WEEKLY &&
		rrule.options.byweekday?.length === 1 &&
		rrule.options.byweekday[0] === startDate.weekday - 1
	) {
		return "everyWeek";
	}
	if (rrule.options.freq === RRule.MONTHLY) {
		if (rrule.options.bymonthday?.length === 1) {
			return "everyMonthSameDay";
		}
		if (rrule.options.bynweekday?.length === 1 && rrule.options.bynweekday[0][1] === -1) {
			return "everyMonthLastWeekday";
		}
		if (rrule.options.bynweekday?.length === 1) {
			return "everyMonthSameWeekday";
		}
	}
	if (rrule.options.freq === RRule.YEARLY) {
		return "everyYear";
	}
	return "none";
}

const computeSelectedReminderOption = (
	reminder: EditCreateEventForm["reminder"]
): NonUndefined<EditCreateEventForm["selectedReminderOption"]> => {
	if (reminder.unit === "hours" && reminder.amount === 1) {
		return "1-hour";
	}
	if (reminder.unit === "hours" && reminder.amount === 2) {
		return "2-hour";
	}
	if (reminder.unit === "days" && reminder.amount === 1) {
		return "1-day";
	}
	if (reminder.unit === "weeks" && reminder.amount === 1) {
		return "1-week";
	}
	if (reminder.unit === "months" && reminder.amount === 1) {
		return "1-month";
	}
	return "custom";
};

export function useInitEditCreateEventForm({
	form,
	eventId,
	initialValue,
	onInit,
}: {
	form: UseFormReturn<EditCreateEventForm>;
	eventId?: string;
	initialValue?: Partial<EditCreateEventForm>;
	onInit?: () => void;
}) {
	const {
		data: eventInstance,
		isLoading: isInitiatingForm,
		isError: isFormInitiationError,
	} = useEventInstanceById(eventId);

	const { me } = useMe();

	const eventFormValues: Partial<EditCreateEventForm> = useMemo(() => {
		if (eventInstance) {
			const eventStartDate = DateTime.fromISO(eventInstance.start);
			const eventEndDate = DateTime.fromISO(eventInstance.end ?? new Date().toISOString());
			const eventStartTime = parseTime(eventInstance.event?.start.time ?? "00:00");
			const eventEndTime = parseTime(eventInstance.event?.end?.time ?? "23:59");

			const myReminder = eventInstance.attendees?.find((attendee) => attendee.userId === me?.id)?.reminder;

			const reminderMinutes = myReminder?.overrides?.[0]?.minutes;
			const reminderDuration = Duration.fromObject({ minutes: reminderMinutes ?? 0 });
			const reminderDurationUnit = computeDurationUnit(reminderDuration);

			const hasEmailReminder = myReminder?.overrides?.some((override) => override.method === "email");
			const hasNotificationReminder = myReminder?.overrides?.some((override) => override.method === "popup");

			const eventRrule = eventInstance.event?.recurrence?.[0]
				? new RRule(parseString(eventInstance.event?.recurrence?.[0] ?? ""))
				: undefined;

			const eventReminder: EditCreateEventForm["reminder"] = {
				isAtEventTime: reminderMinutes === undefined || reminderMinutes === 0,
				unit: reminderDurationUnit,
				amount: reminderDurationUnit ? reminderDuration.as(reminderDurationUnit) : undefined,
			};

			return {
				name: eventInstance.event?.name,
				annotation: eventInstance.event?.annotation ?? "",
				startDate: fromDate(eventStartDate.toJSDate(), Intl.DateTimeFormat().resolvedOptions().timeZone),
				endDate: fromDate(eventEndDate.toJSDate(), Intl.DateTimeFormat().resolvedOptions().timeZone),
				startTime: eventStartTime,
				endTime: eventEndTime,
				isAllDay:
					!eventStartDate.hasSame(eventEndDate, "day") ||
					(eventStartDate.toFormat("hh:mm") === eventStartDate.startOf("day").toFormat("hh:mm") &&
						eventEndDate.toFormat("hh:mm") === eventEndDate.endOf("day").toFormat("hh:mm")),
				recurrence: eventRrule,
				selectedRecurrenceOption: computeSelectedRepetiton({ rrule: eventRrule, startDate: eventStartDate }),
				sharedWith: (eventInstance.attendees ?? [])
					.filter((attendee) => attendee.role !== "owner")
					.map((attendee) => ({
						email: attendee.user?.email,
						role: attendee.role,
						hasBeenAdded: false,
						hasBeenDeleted: false,
						hasBeenEdited: false,
						name: attendee.user?.name,
						userId: attendee.userId,
						surname: attendee.user?.surname,
						profilePictureUrl: attendee.user?.profileUrl,
					})),
				notifications: {
					email: hasEmailReminder,
					inApp: hasNotificationReminder,
				},
				reminder: eventReminder,
				selectedReminderOption: computeSelectedReminderOption(eventReminder),
			};
		}
		return {};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [eventInstance, me]);

	const prevEventFormValues = useRef<typeof eventFormValues | undefined>(undefined);
	const prevInitialValue = useRef<typeof initialValue | undefined>(undefined);

	//!this is needed in order to fill form values on the same render cycle thus mounting the form with value already filled (https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes)
	if (eventId) {
		if (prevEventFormValues.current !== eventFormValues) {
			form.reset({ ...eventFormValues });
			prevEventFormValues.current = eventFormValues;
			onInit?.();
		}
	} else {
		if (prevInitialValue.current !== initialValue) {
			form.reset({
				selectedRecurrenceOption: "none",
				selectedReminderOption: "1-hour",
				reminder: { isAtEventTime: false, amount: 1, unit: "hours" },
				notifications: { email: true, inApp: true },
				...initialValue,
			});
			prevInitialValue.current = initialValue;
			onInit?.();
		}
	}

	return {
		isInitiatingForm,
		isFormInitiationError,
	};
}
