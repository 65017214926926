import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

const SimpleTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.surface.main,
		borderRadius: 12,
		padding: 0,
		boxSizing: "border-box",
		color: "black",
		boxShadow: "0px 1px 18px rgba(0, 0, 0, 0.12)",
		// maxWidth: 240,
	},
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.surface.main,
		height: "0.76em",
		width: "1.2em",
	},
}));

export default SimpleTooltip;
