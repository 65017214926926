import { useMemo } from "react";
import { z } from "zod";

export type UserFormValues = z.infer<ReturnType<typeof useUserDetailsZodSchema>>;

export const useUserDetailsZodSchema = () => {
	return useMemo(() => {
		return z.object({
			id: z.string(),
			name: z.string().optional(),
			surname: z.string().optional(),
			email: z.string().email().optional(),
			// emailConfirmed: z.boolean(),
			profileUrl: z.string().optional(),
			companyId: z.string().optional(),
			createdAt: z.string().optional(),
			updatedAt: z.string().optional(),
			isDeleted: z.boolean().optional(),
			isNewAddedUser: z.boolean().optional(),
			userId: z.string().optional(),
		});
	}, []);
};
