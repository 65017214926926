import { useQuery } from "@tanstack/react-query";
import { ContactApi } from "certiblok-api-manager";
import { useApi } from "../../../utils/hooks/api";
export const getContactSharedDocumentsQueryKey = (contactId: string) => ["contactSharedDocuments", contactId];

const useGetContactSharedDocuments = ({ contactId, enabled }: { contactId?: string; enabled?: boolean }) => {
	const contactApi = useApi(ContactApi);
	return {
		...useQuery({
			queryKey: getContactSharedDocumentsQueryKey(contactId ?? ""),
			queryFn: () => {
				return contactApi.listContactSharedDocuments(contactId ?? "");
			},
			select: (data) => data.data,
			keepPreviousData: true,
			enabled: enabled,
		}),
	};
};

export default useGetContactSharedDocuments;
