import { cn } from "@mabi-ui/utils";
import { Button, Typography } from "@mui/material";
import { Tag } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import CertiblokIcon from "../../../../utils/components/icons/CertiblokIcon";
import LoadingTextButton from "../../../../utils/components/LoadingTextButton";
import {
	ModalNavigator,
	useModalNavigator,
	useModalRoute,
} from "../../../../utils/components/ModalNavigator/ModalNavigator";
import WhiteModal from "../../../../utils/components/WhiteModal";
import { useBetterMediaQuery } from "../../../../utils/hooks/useBetterMediaQuery";
import { useDeleteMultipleTag, useDeleteTag } from "../../hooks/useTagsHooks";

const DeleteTagModal = ModalNavigator.create(
	({ tagId, onSuccess }: { tagId: string | string[]; onSuccess?: (tag?: Tag) => void }) => {
		const { t } = useTranslation();
		const isMd = useBetterMediaQuery("md");

		const { visible } = useModalRoute();
		const { popModal } = useModalNavigator();

		const onClose = () => {
			popModal();
		};

		const { mutate: deleteTag, isLoading: isDeleting } = useDeleteTag({
			onSuccess: (tag) => {
				onSuccess?.(tag);
				popModal();
			},
		});

		const { mutate: deleteMultipleTags, isLoading: isDeletingMultiple } = useDeleteMultipleTag({
			onSuccess: () => {
				onSuccess?.();
				popModal();
			},
		});

		const isMultipleTags = Array.isArray(tagId);

		return (
			<WhiteModal
				open={visible}
				onClose={onClose}
				containerSx={{ width: "100%", boxSizing: "border-box", maxWidth: { xs: "420px", md: "520px" } }}
			>
				<div className="">
					<div className="mb-6 md:w-16 w-12 md:h-16 h-12 w flex items-center justify-center rounded-full bg-alert/[0.12]">
						<CertiblokIcon name="comunication_outline_01" color="#FFD21F" size={isMd ? 40 : 32} />
					</div>
					<Typography variant={isMd ? "h4" : "h6"} className={cn(!isMd && "font-bold")}>
						{t("global.attention")}
					</Typography>
					<Typography variant={isMd ? "h6" : "body1"} component={"p"} className="mt-2 mb-8 text-black-a60">
						{t("tags.deleteTagWarning", { count: isMultipleTags ? tagId.length : 1 })}
					</Typography>
					<div className="flex items-center justify-between gap-2 flex-wrap">
						<Button
							variant="outlined"
							size={isMd ? "medium" : "small"}
							color="secondary"
							sx={{ typography: { md: "button" } }}
							onClick={() => popModal()}
						>
							{isMd && <CertiblokIcon name="close" color="secondary" />}
							{t("global.cancel")}
						</Button>
						<LoadingTextButton
							loading={isDeleting || isDeletingMultiple}
							variant="contained"
							size={isMd ? "medium" : "small"}
							color="error"
							sx={{ typography: { md: "button" } }}
							onClick={() => {
								if (Array.isArray(tagId)) {
									deleteMultipleTags({ ids: tagId });
								} else {
									deleteTag({ id: tagId });
								}
							}}
						>
							{isMultipleTags && tagId.length > 1 ? t("tags.deleteTags") : t("tags.deleteTag")}
						</LoadingTextButton>
					</div>
				</div>
			</WhiteModal>
		);
	}
);

export default DeleteTagModal;
