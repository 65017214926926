/** @jsxImportSource @emotion/react */

import type { CSSObject } from "@emotion/react";
import { Stack, SxProps, Theme, Typography, TypographyProps } from "@mui/material";
import { forwardRef } from "react";
import document from "../../assets/no_contents/document.png";
import folder from "../../assets/no_contents/folder.png";
import lock from "../../assets/no_contents/lock.png";
import paper from "../../assets/no_contents/paper.png";

type NoContentsProps = {
	variant: "document" | "lock" | "folder" | "paper";
	title: string;
	noIcon?: boolean;
	sx?: SxProps<Theme>;
	iconCss?: CSSObject;
	typographyProps?: Omit<TypographyProps, "children">;
};

const NoContents = forwardRef<any, NoContentsProps>(
	({ variant, noIcon, sx = {}, iconCss = {}, title, typographyProps, ...props }, ref) => {
		return (
			<Stack alignItems={"center"} sx={{ ...sx }} {...props} ref={ref}>
				{!noIcon && (
					<img
						src={variant === "document" ? document : variant === "lock" ? lock : variant === "folder" ? folder : paper}
						alt="No content"
						css={{ widht: 88, height: 88, ...iconCss }}
					/>
				)}
				<Typography children={title} variant="subtitle2" {...typographyProps} />
			</Stack>
		);
	}
);
export default NoContents;
