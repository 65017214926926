import { alpha, Button, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import EditCreateEditRoomModal, {
	EditCreateEditRoomModalProps,
} from "../../../../pages/EditRooms/components/EditCreateEditRoomModal";
import CertiblokIcon from "../../icons/CertiblokIcon";
import { useModalNavigator } from "../../ModalNavigator/ModalNavigator";
import MLDialog from "../../poppers";

export const useShowEditRoomsModals = () => {
	const { t } = useTranslation();
	const theme = useTheme();

	const { pushModal } = useModalNavigator();

	const showLimitReachedModal = () => {
		MLDialog.showModal(t("global.reachedLimit"), t("editRoom.limitReached"), {
			positiveButton: () => (
				<Button
					color="secondary"
					variant="contained"
					children={t("global.showPlans")}
					onClick={() => {
						MLDialog.hideModal();
					}}
					size="large"
					href="/profile"
				/>
			),
			negativeButton: (
				<Button
					children={t("global.close")}
					onClick={() => {
						MLDialog.hideModal();
					}}
					color="secondary"
					size="large"
				/>
			),
			icon: (
				<CertiblokIcon
					size={48}
					color="orange"
					name={"comunication_outline_01"}
					iconCss={{ padding: 12, backgroundColor: alpha(theme.palette.orange.main, 0.12), borderRadius: 36 }}
				/>
			),
			contentCentered: true,
			maxWidth: 344,
		});
	};

	const createNewEditRoom = (props: EditCreateEditRoomModalProps) => {
		return pushModal(
			EditCreateEditRoomModal,
			{
				...props,
			},
			{ shouldHidePrev: true }
		);
	};

	return {
		showLimitReached: showLimitReachedModal,
		createNewEditRoom,
	};
};
