import { DocumentApi, EditRoomApi, Folder } from "certiblok-api-manager";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useFolderHistory } from "../../../../../utils/components/FolderPicker/controllers/useFolderHistory";
import { useBottomSheetOld } from "../../../../../utils/components/bottomSheet/hooks/useBottomSheet";
import MLDialog from "../../../../../utils/components/poppers";
import { instanceApi, useApi } from "../../../../../utils/hooks/api";
import type { CertiblokDocument } from "../../../../../utils/interfaces/Document";

export function useMoveDocumentContext(
	documents: CertiblokDocument[],
	open?: boolean,
	toggleOpenFunction?: () => void
) {
	const { t } = useTranslation();
	const toggleOpen = toggleOpenFunction ? toggleOpenFunction : () => {};

	const location = useLocation();
	const isInEditRoom = location.pathname.includes("/edit_rooms/");

	const [currentDocuments, setCurrentDocuments] = useState(documents);
	useEffect(() => {
		setCurrentDocuments(documents);
	}, [documents]);

	const [step, setStep] = useState(0);

	const goToNextStep = useCallback(() => {
		if (step !== 2) setStep(step + 1);
	}, [step]);

	const goToStep = useCallback((index: number) => {
		if (index >= 0 && index <= 2) setStep(index);
	}, []);

	const goToPrevStep = useCallback(() => {
		if (step !== 0) setStep(step - 1);
	}, [step]);

	const [selectedFolder, setSelectedFolder] = useState<Folder | undefined>();

	const setCurrentFolder = useCallback((folder: Folder) => {
		setSelectedFolder(folder);
	}, []);

	const folderHistory = useFolderHistory();

	const [updateLoading, setUpdateLoading] = useState(false);
	const documentApi = useApi(DocumentApi);

	const moveToFolder = useCallback(async () => {
		setUpdateLoading(true);
		await Promise.all(
			documents.map((document) => {
				if (isInEditRoom) {
					return instanceApi(EditRoomApi)
						.updateEditRoomDocument(document.id, {
							parentFolderId: selectedFolder?.id,
							expireDate: document.expireDate || undefined,
						})
						.catch((error) => {
							MLDialog.showSnackbar(t("folders.movingError", { documentName: document.name }), { variant: "error" });
						});
				}

				return instanceApi(DocumentApi)
					.editDocument(document.id, { parentFolderId: selectedFolder?.id, expireDate: document.expireDate || undefined })
					.catch((error) => {
						MLDialog.showSnackbar(t("folders.movingError", { documentName: document.name }), { variant: "error" });
					});
			})
		);
		setUpdateLoading(false);
		goToNextStep();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [documentApi, documents, selectedFolder]);

	const [shouldReplaceChecked, setShouldReplaceChecked] = useState(false);

	const toggleShouldReplaceChecked = useCallback(() => {
		setShouldReplaceChecked((prev) => !prev);
	}, []);

	const [documentToReplace, setDocumentToReplace] = useState<CertiblokDocument | undefined>();

	const replaceDocument = useCallback(
		async (document: CertiblokDocument) => {
			setDocumentToReplace(document);
			await documentApi
				.moveToFolderAndReplaceFile(currentDocuments[0].id, {
					documentId: document.id,
					parentFolderId: selectedFolder?.id ?? "",
				})
				.catch((error) => {
					MLDialog.showSnackbar(t("folders.movingError", { documentName: document.name }), { variant: "error" });
				});
		},
		[currentDocuments, documentApi, selectedFolder?.id, t]
	);

	const replaceDocumentBS = useBottomSheetOld(false);

	const resetContext = useCallback(() => {
		setStep(0);
		setSelectedFolder(undefined);
		folderHistory.resetFolderHistory();
	}, [folderHistory]);

	return {
		open,
		toggleOpen,
		currentDocuments,
		step,
		goToNextStep,
		goToStep,
		goToPrevStep,
		selectedFolder,
		...folderHistory,
		updateLoading,
		moveToFolder,
		shouldReplaceChecked,
		toggleShouldReplaceChecked,
		documentToReplace,
		replaceDocument,
		replaceDocumentBS,
		resetContext,
		setCurrentFolder,
	};
}

type IMoveDocumentContext = ReturnType<typeof useMoveDocumentContext>;

export const MoveDocumentContext = React.createContext<IMoveDocumentContext>({} as IMoveDocumentContext);
