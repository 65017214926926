/** @jsxImportSource @emotion/react */
import { Box, Button, Grid, ModalProps, Typography } from "@mui/material";
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import isEmail from "validator/lib/isEmail";
import { isUser } from "../../../../pages/Folders/components/UploadDocument/functions/isUser";
import LoadingIconButton from "../../buttons/LoadingIconButton";
import CertiblokTextInput from "../../CertiblokTextInput";
import CertiblokIcon from "../../icons/CertiblokIcon";
import LanguageSelector from "../../LanguageSelector";
import MLDialog from "../../poppers";
import UserChip from "../../UserChip";
import UserPickerAutocomplete from "../../UserPickerAutocomplete";
import WhiteModal from "../../WhiteModal";
import { useSendRequest } from "../controllers/useSendRequest";

type SendRequestModalProps = Omit<ModalProps, "children"> & {
	toggleOpen: () => void;
};

const SendRequestModal: React.FC<SendRequestModalProps> = ({ toggleOpen, ...otherProps }) => {
	const { t } = useTranslation();

	// const [selectedLanguage, setSelectedLanguage] = useState('en')
	const sendRequest = useSendRequest();
	useEffect(() => {
		sendRequest.setReason("");
		sendRequest.resetSelectedContact();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [otherProps.open]);

	return (
		<WhiteModal
			onClose={() => {
				sendRequest.onLanguageChange("it");
				toggleOpen();
			}}
			responsive
			keepMounted={false}
			{...otherProps}
		>
			<Fragment>
				<Typography children={t("variousComponents.sendDocumentRequest")} variant="subtitle1" sx={{ paddingBottom: 6 }} />

				<UserPickerAutocomplete
					label={t("variousComponents.chooseWhoToSendRequestTo")}
					options={sendRequest.availableContacts}
					onInputChange={sendRequest.onInputChange}
					onSelectedValueChange={sendRequest.selectContact}
					autocompleteStatus={sendRequest.autocompleteStatus}
					setAutocompleteStatus={sendRequest.setAutocompleteStatus}
					invitableUser={sendRequest.currentInputValue.length > 0}
					buttonText={sendRequest.currentInputValue}
					buttonAction={() => {
						if (isEmail(sendRequest.currentInputValue)) {
							sendRequest.selectContact(sendRequest.currentInputValue);
							return true;
						} else {
							MLDialog.showSnackbar(t("global.invalidEmail"), { variant: "error" });
							return false;
						}
					}}
				/>
				<Grid container spacing={1} sx={{ paddingTop: 2 }}>
					{sendRequest.selectedContacts.map((elem, index) => {
						return (
							<Grid item key={isUser(elem) ? elem.id : `invite_${index}`} xs={4} md={3}>
								<UserChip user={elem} deletable onDelete={sendRequest.unselectContact} />
							</Grid>
						);
					})}
				</Grid>
				<div className="pt-7 flex items-center justify-between w-full">
					<Typography variant="label">{t("request.selectLanguage")}</Typography>
					<LanguageSelector language={sendRequest.selectedLanguage} onLanguageChange={sendRequest.onLanguageChange} />
				</div>
				<CertiblokTextInput
					label={t("askFileUpdate.insertNoteToRequest")}
					color="secondary"
					fullWidth
					containerCss={{ paddingTop: 20 }}
					value={sendRequest.reason}
					multiline
					onChange={(ev) => sendRequest.setReason(ev.target.value)}
					css={{ height: "auto" }}
					maxRows={10}
				/>
				<Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", paddingTop: 12 }}>
					<Button
						variant="outlined"
						color="secondary"
						onClick={() => {
							sendRequest.onLanguageChange("it");
							toggleOpen();
						}}
					>
						<CertiblokIcon size={24} color="secondary" name={"close"} />
						{t("global.cancel")}
					</Button>
					<LoadingIconButton
						icon={<CertiblokIcon size={24} color="inherit" name={"success"} />}
						loading={sendRequest.loadingSubmit}
						variant="contained"
						color="secondary"
						disabled={sendRequest.reason.length === 0 || sendRequest.selectedContacts.length === 0}
						onClick={() => {
							toggleOpen();
							MLDialog.showSnackbar(t("requests.sentRequests"), { variant: "success" });
							sendRequest.submit();
						}}
					>
						{t("global.sendRequest")}
					</LoadingIconButton>
				</Box>
			</Fragment>
		</WhiteModal>
	);
};

export default SendRequestModal;
