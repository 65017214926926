import { Typography } from "@mui/material";
import clsx, { ClassValue } from "clsx";
import type { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";
import { useBetterMediaQuery } from "../../hooks/useBetterMediaQuery";
import type { CertiblokIconNames } from "../icons/CertiblokIcon";
import CertiblokIcon from "../icons/CertiblokIcon";
import ResponsiveDialog from "./ResponsiveDialog";

type IconDialogProps = {
	open: boolean;
	onClose: () => void;
	DialogProps?: {
		className?: string | ClassValue[];
	};
	icon?: CertiblokIconNames;
	IconProps?: {
		iconColor?: string;
		iconSize?: number;
		containerClassName?: string | ClassValue[];
	};
	TitleProps?: {
		className?: string | ClassValue[];
	};
	DescriptionProps?: {
		className?: string | ClassValue[];
	};
	title?: string;
	description?: string;
	footer?: React.ReactNode;
	ModalProps?: ComponentProps<typeof ResponsiveDialog>["ModalProps"];
	BottomSheetProps?: ComponentProps<typeof ResponsiveDialog>["BottomSheetProps"];
};

const IconDialog: React.FC<IconDialogProps> = ({
	icon,
	title,
	IconProps,
	DialogProps,
	TitleProps,
	DescriptionProps,
	description,
	ModalProps,
	BottomSheetProps,
	...props
}) => {
	const isMd = useBetterMediaQuery("md");
	return (
		<ResponsiveDialog
			ModalProps={{
				responsive: true,
				...ModalProps,
			}}
			BottomSheetProps={{
				HeaderProps: {
					className: "pb-4",
				},
				...BottomSheetProps,
			}}
			{...props}
		>
			<div className={twMerge(clsx("px-5 md:px-0", DialogProps?.className))}>
				<div
					className={twMerge(
						clsx(
							"flex items-center justify-center w-14 h-14 md:w-[72px] md:h-[72px] rounded-full",
							IconProps?.containerClassName
						)
					)}
				>
					<CertiblokIcon size={IconProps?.iconSize ?? isMd ? 48 : 32} color={IconProps?.iconColor} name={icon} />
				</div>
				<Typography
					sx={{
						typography: {
							xs: "subtitle1",
							md: "h4",
						},
					}}
					className={twMerge(clsx("text-black md:font-normal mt-4 md:mt-6", TitleProps?.className))}
				>
					{title}
				</Typography>
				<Typography
					sx={{
						typography: {
							xs: "caption",
							md: "h5",
						},
					}}
					className={twMerge(clsx("font-normal text-black-a60 pt-2", DescriptionProps?.className))}
				>
					{description}
				</Typography>
			</div>
		</ResponsiveDialog>
	);
};
export default IconDialog;
