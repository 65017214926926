import { Box } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { FavoriteApi, FavoriteFolderOrDocument, ResponseGetDashboardFavorite } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import ActionButton from "../../../../utils/components/buttons/ActionButton";
import CertiblokIcon from "../../../../utils/components/icons/CertiblokIcon";
import {
	ModalNavigator,
	useModalNavigator,
	useModalRoute,
} from "../../../../utils/components/ModalNavigator/ModalNavigator";
import MLDialog from "../../../../utils/components/poppers";
import RowMenu from "../../../../utils/components/poppers/RowMenu";
import { apiErrorParser, instanceApi } from "../../../../utils/hooks/api";
import { favoriteDocumentsInDashboardQueryKey } from "../../hooks/useGetFavoriteDocumentsInDashboard";

const FavoritePopupMenu = ModalNavigator.create(
	({
		anchorEl,
		folderOrDocument,
		onRemoveFavorite,
	}: {
		anchorEl: HTMLElement | null;
		folderOrDocument?: FavoriteFolderOrDocument;
		onRemoveFavorite: () => void;
	}) => {
		const { visible } = useModalRoute();
		const { popModal } = useModalNavigator();

		const queryClient = useQueryClient();

		const { t } = useTranslation();

		const { mutate: removeElementFromFavorites } = useMutation({
			mutationFn: (element: FavoriteFolderOrDocument) => {
				return element.isFolder
					? instanceApi(FavoriteApi).removeFolderFromFavorite(element.folderId ?? "")
					: instanceApi(FavoriteApi).removeDocumentFromFavorite(element.documentId ?? "");
			},
			onMutate: async (element: FavoriteFolderOrDocument) => {
				onRemoveFavorite();
				await queryClient.cancelQueries({ queryKey: favoriteDocumentsInDashboardQueryKey });

				const previousFavorites = queryClient.getQueryData(favoriteDocumentsInDashboardQueryKey);

				queryClient.setQueryData<AxiosResponse<ResponseGetDashboardFavorite>>(
					favoriteDocumentsInDashboardQueryKey,
					(old) => {
						if (!old) {
							return old;
						}
						return {
							...old,
							data: {
								...old.data,
								data: old.data.data.filter((elem) => elem.id !== element.id) ?? [],
							},
						};
					}
				);

				return { previousFavorites };
			},
			onError: (err, element, context) => {
				MLDialog.showSnackbar(apiErrorParser(err));
				queryClient.setQueryData(favoriteDocumentsInDashboardQueryKey, context?.previousFavorites);
			},
			onSettled: () => {
				queryClient.invalidateQueries(favoriteDocumentsInDashboardQueryKey);
			},
		});

		if (!folderOrDocument) {
			return null;
		}

		return (
			<RowMenu open={visible} onClose={() => popModal()} anchorEl={anchorEl}>
				<Box
					sx={{
						minWidth: 180,
						boxSizing: "border-box",
						backgroundColor: "surface.main",
						display: "flex",
						flexDirection: "column",
						padding: 1,
						borderRadius: 3,
					}}
				>
					<ActionButton
						sx={{ gap: 2 }}
						color="secondary"
						onClick={() => {
							popModal();
							removeElementFromFavorites(folderOrDocument);
						}}
					>
						<CertiblokIcon size={24} color="inherit" name="star_outline" />
						{t("documents.removeFromFavorite")}
					</ActionButton>
				</Box>
			</RowMenu>
		);
	}
);

export default FavoritePopupMenu;
