import { Collapse } from "@mui/material";
import { useController } from "react-hook-form";
import EditRoomLockRoom from "./components/EditRoomLockRoom";
import EditRoomShareRoom from "./components/EditRoomShareRoom";
import EditRoomSharedRoom from "./components/EditRoomSharedRoom";

type EditRoomAccessProps = {};

const EditRoomAccess: React.FC<EditRoomAccessProps> = (props) => {
	const { field } = useController({
		name: "lockEditRoomEnabled",
	});

	const isEditRoomLocked = field.value;

	return (
		<div className="flex flex-col items-stretch gap-6">
			<EditRoomLockRoom />
			<Collapse in={isEditRoomLocked}>
				<div className="flex flex-col items-stretch gap-6">
					<EditRoomShareRoom />
					<EditRoomSharedRoom />
				</div>
			</Collapse>
		</div>
	);
};

export default EditRoomAccess;
