import { Avatar, Box, SxProps, Theme, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import type { UserOrContact } from "certiblok-api-manager";
import { useMemo } from "react";
import { backendMediaUrlParser } from "..";
import avatarPlaceholder from "../../assets/avatar-placeholder.png";
import LinkBehavior from "./LinkBehavior";

type Props<T extends UserOrContact> = {
	user?: T;
	size?: "small" | "medium" | "large";
	variant?:
		| "h1"
		| "h2"
		| "h3"
		| "h4"
		| "h5"
		| "h6"
		| "subtitle1"
		| "subtitle2"
		| "body1"
		| "body2"
		| "caption"
		| "button"
		| "overline"
		| "link"
		| "label"
		| "inherit"
		| "display1"
		| "display2"
		| undefined;
	typographySx?: SxProps<Theme> | undefined;
	sx?: SxProps<Theme> | undefined;
	withoutName?: boolean;
	responsiveProfilePicture?: boolean;
	isHref?: boolean;
};

const AvatarWithName = <T extends UserOrContact>({
	user,
	size = "small",
	variant = "caption",
	typographySx = {},
	sx = {},
	withoutName = false,
	responsiveProfilePicture = false,
	isHref = false,
}: Props<T>) => {
	const theme = useTheme();
	const isLg = useMediaQuery(theme.breakpoints.up("lg"));
	const dimension = size === "small" ? 20 : size === "medium" ? 24 : 36;
	const avatar = useMemo(
		() => (
			<Avatar
				sx={{
					width: dimension,
					height: dimension,
				}}
				src={backendMediaUrlParser(user?.profileUrl) ?? avatarPlaceholder}
			/>
		),
		[dimension, user?.profileUrl]
	);
	return (
		<Tooltip title={`${user?.name || ""} ${user?.surname || ""}`} enterDelay={500}>
			<Box
				{...(isHref && {
					component: LinkBehavior,
					href: `/contacts/${user?.id}`,
					onClick: (ev: any) => {
						ev.stopPropagation();
					},
				})}
				sx={{
					display: "flex",
					gap: 2,
					alignItems: "center",
					textDecoration: "none",
					color: "unset",
					...(isHref && {
						// width: "100%",
						p: 1,
						boxSizing: "border-box",
						borderRadius: 2,
						"&:hover": {
							backgroundColor: "backgroundSecondary.default",
						},
					}),
					...sx,
				}}
			>
				{responsiveProfilePicture ? (isLg ? avatar : null) : avatar}
				{!withoutName && (
					<Typography
						children={`${user?.name || ""} ${user?.surname || ""}`}
						variant={variant}
						noWrap
						sx={{
							opacity: 0.74,
							width: `calc(100% - ${responsiveProfilePicture ? (isLg ? dimension : 0) : dimension}px)`,
							...typographySx,
						}}
					/>
				)}
			</Box>
		</Tooltip>
	);
};

export default AvatarWithName;
