import { CBFile } from "certiblok-api-manager";
import mime from "mime";
import { TFunction } from "react-i18next";

export const getFileExtension = (t: TFunction<"translation", undefined>, file?: CBFile) => {
	if (!file) {
		return t("documents.unknownType");
	}
	return (
		mime.getExtension(file?.type ?? "")?.toUpperCase() ??
		file?.filename
			.split(".")
			.filter((value: string, index: number, array: string[]) => index === array.length - 1)?.[0]
			?.toUpperCase() ??
		t("documents.unknownType")
	);
};
