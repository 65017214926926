import { DateTime } from "luxon";
import { useMemo } from "react";
import { DateValue } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { RRule, Weekday } from "rrule";
import { getWeekdayOccurrence, parseOccurrenceToText } from "../../../functions/getWeekdayOccurrence";

export const availableEventRepetitions = [
	"none",
	"everyDay",
	"everyWeek",
	"everyMonthSameDay",
	"everyMonthSameWeekday",
	"everyMonthLastWeekday",
	"everyYear",
	"custom",
] as const;

export type AvailableEventRepetitions = (typeof availableEventRepetitions)[number];

export const useEventRepetitonOptions = (startDate?: DateValue | null, endDate?: DateValue | null) => {
	const { t, i18n } = useTranslation();

	const repetitionOptions = useMemo<{
		[key in AvailableEventRepetitions]?: {
			rrule: RRule | undefined;
			label: string;
		};
	}>(() => {
		const startDateToLuxon = startDate
			? DateTime.fromJSDate(startDate.toDate(DateTime.now().toFormat("z"))).startOf("day")
			: undefined;
		const utcStartDate = startDateToLuxon?.toUTC().setZone("local", { keepLocalTime: true }).toJSDate();
		const endDateToLuxon =
			endDate || startDate
				? DateTime.fromJSDate(
						endDate?.toDate(DateTime.now().toFormat("z")) ?? startDate!.toDate(DateTime.now().toFormat("z"))
				  ).endOf("day")
				: undefined;

		const eventDuration = startDateToLuxon && endDateToLuxon ? endDateToLuxon.diff(startDateToLuxon) : undefined;
		const eventDurationInDays = eventDuration ? eventDuration.as("days") : 0;
		const eventDurationInWeeks = eventDuration ? eventDuration.as("weeks") : 0;
		const eventDurationInMonths = eventDuration ? eventDuration.as("months") : 0;
		const eventDurationInYears = eventDuration ? eventDuration.as("years") : 0;

		const nthOccurrenceOfDay = getWeekdayOccurrence(startDateToLuxon?.toJSDate());
		const ordinalDayOfWeek = parseOccurrenceToText(nthOccurrenceOfDay);

		const isLastOccurrenceOfTheMonth = startDateToLuxon
			? !startDateToLuxon.plus({ day: 7 }).hasSame(startDateToLuxon, "month")
			: false;

		return {
			none: { rrule: undefined, label: t("events.repetition.noRepetition") },
			...(eventDurationInDays <= 1 && {
				everyDay: {
					rrule: new RRule({
						dtstart: utcStartDate,
						freq: RRule.DAILY,
					}),
					label: t("events.repetition.everyDay"),
				},
			}),
			...(eventDurationInWeeks <= 1 && {
				everyWeek: {
					rrule: new RRule({
						dtstart: utcStartDate,
						freq: RRule.WEEKLY,
						byweekday: new Weekday((startDateToLuxon?.weekday ?? 1) - 1),
					}),
					label: t("events.repetition.everyWeek"),
				},
			}),
			...(eventDurationInMonths <= 1 && {
				everyMonthSameDay: {
					rrule: new RRule({
						dtstart: utcStartDate,
						freq: RRule.MONTHLY,
						bymonthday: startDateToLuxon?.day ?? 1,
					}),
					label: t("events.repetition.everyMonthOn", {
						dayOfMonth: startDateToLuxon?.day ?? 1,
						count: eventDurationInDays,
					}),
				},
				...(nthOccurrenceOfDay < 5 && {
					everyMonthSameWeekday: {
						rrule: new RRule({
							dtstart: utcStartDate,
							freq: RRule.MONTHLY,
							byweekday: new Weekday((startDateToLuxon?.weekday ?? 0) - 1, getWeekdayOccurrence(startDateToLuxon?.toJSDate())),
						}),
						label: `${t("events.everyMonthFrom", {
							context: startDateToLuxon?.weekday === 7 ? "female" : "male",
							count: eventDurationInDays,
						})} ${t(`events.ordinalDayOfWeek.${ordinalDayOfWeek}`, {
							dayOfWeek: DateTime.fromJSDate(startDateToLuxon?.toJSDate() ?? new Date()).toFormat("EEEE", {
								locale: i18n.language,
							}),
							context: startDateToLuxon?.weekday === 7 ? "female" : "male",
						}).toLowerCase()}`,
					},
				}),
				...(isLastOccurrenceOfTheMonth && {
					everyMonthLastWeekday: {
						rrule: new RRule({
							dtstart: utcStartDate,
							freq: RRule.MONTHLY,
							byweekday: new Weekday((startDateToLuxon?.weekday ?? 0) - 1, -1),
						}),
						label: `${t("events.everyMonthAt", { count: eventDurationInDays })}${t(`events.ordinalDayOfWeek.last`, {
							dayOfWeek: DateTime.fromJSDate(startDateToLuxon?.toJSDate() ?? new Date()).toFormat("EEEE", {
								locale: i18n.language,
							}),
							context: startDateToLuxon?.weekday === 7 ? "female" : "male",
						}).toLowerCase()}`,
					},
				}),
			}),
			...(eventDurationInYears <= 1 && {
				everyYear: {
					rrule: new RRule({
						dtstart: utcStartDate,
						freq: RRule.YEARLY,
						bymonth: startDateToLuxon?.month ?? 1,
						bymonthday: startDateToLuxon?.day ?? 1,
					}),
					label: t("events.repetition.everyYear", {
						yearDay: DateTime.fromJSDate(startDateToLuxon?.toJSDate() ?? new Date()).toFormat("d MMMM", {
							locale: i18n.language,
						}),
						count: eventDurationInDays,
					}),
				},
			}),
			...(eventDurationInDays <= 1 && {
				custom: {
					rrule: undefined,
					label: t("events.repetition.customize"),
				},
			}),
		};
	}, [endDate, i18n.language, startDate, t]);

	return repetitionOptions;
};
