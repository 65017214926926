import { atom } from "jotai";

type ChatAtom = {
	chatId: number | null;
	chatName: string | null;
};

export const chatAtom = atom<ChatAtom>({
	chatId: null,
	chatName: null,
});
