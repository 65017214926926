import { Button, ButtonProps } from "@mui/material";
import clsx from "clsx";
import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import type { CertiblokIconNames } from "./icons/CertiblokIcon";
import CertiblokIcon from "./icons/CertiblokIcon";

type MenuButtonProps = {
	isDelete?: boolean;
	icon?: CertiblokIconNames;
} & Omit<ButtonProps, "color">;
const MenuButton: React.FC<MenuButtonProps> = forwardRef<any, MenuButtonProps>(
	({ isDelete, icon, children, className, ...props }, ref) => {
		return (
			<Button
				ref={ref}
				children={
					<>
						{icon && <CertiblokIcon name={icon} size={24} color="inherit" />}
						{children}
					</>
				}
				color="secondary"
				className={twMerge(
					clsx(
						"items-center justify-start leading-[unset] gap-2 rounded-lg",
						isDelete ? "text-error hover:text-error hover:bg-error-a12" : "text-black hover:text-secondary",
						!icon && "justify-center",
						className
					)
				)}
				{...props}
			/>
		);
	}
);
export default MenuButton;
