import { Chip, Grid, Typography } from "@mui/material";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CertiblokIcon from "../../../../../../../../../utils/components/icons/CertiblokIcon";
import TeamMembersGuestsGroupsAutocomplete from "../../../../../../../../Folders/components/EditCreateFolderModal/components/TeamMembersGuestsGroupsAutocomplete";
import { EditRoomFormValues } from "../../../../../hooks/useEditRoomDetailsZodSchema";

type EditRoomShareRoomProps = {};

const EditRoomShareRoom: React.FC<EditRoomShareRoomProps> = (props) => {
	const { t } = useTranslation();

	const { control } = useFormContext<EditRoomFormValues>();
	const {
		fields: reservedUsers,
		append: appendUser,
		remove: removeUser,
	} = useFieldArray({
		control,
		name: "reservedToUsers",
	});
	const {
		fields: reservedGroups,
		append: appendGroup,
		remove: removeGroup,
	} = useFieldArray({
		control,
		name: "reservedToTeams",
	});

	return (
		<div className="flex flex-col gap-2 items-stretch">
			<Typography variant="caption" className="text-black-a54">
				{t("editRoom.chooseWhoToGiveAccess")}
			</Typography>
			<TeamMembersGuestsGroupsAutocomplete
				onSelectUser={(user) => {
					appendUser({ ...user, isNewAddedUser: true });
				}}
				disabledUsersIds={reservedUsers.map((user) => user?.userId ?? "")}
				disabledGroupsIds={reservedGroups.map((team) => team?.teamId ?? "")}
				onSelectGroup={(group) => {
					appendGroup({ ...group, isNewAddedGroup: true });
				}}
				includeGroups
			/>
			<Grid container gap={2}>
				{reservedGroups
					.filter((group) => group.isNewAddedGroup)
					.map((group, index) => {
						return (
							<Chip
								label={
									<Typography variant="body2" className="max-w-[64px] truncate">
										{group?.name}
									</Typography>
								}
								color="secondary"
								icon={<CertiblokIcon name="profile_outline_01" color="primary" size={20} />}
								onDelete={() => {
									removeGroup(index);
								}}
								key={group?.id}
							/>
						);
					})}
				{reservedUsers
					.filter((user) => user.isNewAddedUser)
					.map((user, index) => {
						return (
							<Chip
								label={
									<Typography variant="body2" className="max-w-[64px] truncate">
										{user?.email}
									</Typography>
								}
								color="secondary"
								icon={<CertiblokIcon name="mail_outline" color="primary" size={20} />}
								onDelete={() => {
									removeUser(index);
								}}
								key={user?.id}
							/>
						);
					})}
			</Grid>
		</div>
	);
};

export default EditRoomShareRoom;
