import { DateTime, Info } from "luxon";
import { useTranslation } from "react-i18next";
import { RRule } from "rrule";
import { parseOccurrenceToText } from "../../../../pages/Calendar/pages/EditCreateEvent/functions/getWeekdayOccurrence";

export function useParseRecurrenceString(
	str: string,
	options: { locale: string; isMultipleDays: boolean; shouldShowUntil?: boolean }
) {
	const { t } = useTranslation();

	if (str === "") {
		return "";
	}

	const rrule = RRule.fromString(str).options;

	const days = Info.weekdays("long", { locale: options.locale ?? "en" });
	const months = Info.months("long", { locale: options.locale ?? "en" });

	const isYearly = rrule.freq === RRule.YEARLY;
	const isMonthly = rrule.freq === RRule.MONTHLY;
	const isWeekly = rrule.freq === RRule.WEEKLY;
	const isDaily = rrule.freq === RRule.DAILY;

	const count = rrule.interval;

	const month = months[(Array.isArray(rrule?.bymonth) ? rrule?.bymonth?.[0] : rrule?.bymonth ?? 1) - 1];
	const day = Array.isArray(rrule?.bymonthday) ? rrule?.bymonthday?.[0] : rrule?.bymonthday;
	const weekday =
		days[
			((rrule.bynweekday
				? rrule.bynweekday?.[0]?.[0]
				: Array.isArray(rrule?.byweekday)
				? rrule?.byweekday?.[0]
				: rrule?.byweekday) as number) ?? 0
		];
	const weekdays = rrule.byweekday?.sort().map((wd) => days[wd]);
	const nth = parseOccurrenceToText(rrule?.bysetpos?.[0] ?? rrule.bynweekday?.[0]?.[1] ?? -1);

	return `${t("recurrence.token")} ${
		isYearly
			? `${t("recurrence.year", { count: count })} ${
					!options.isMultipleDays &&
					t("recurrence.onMonth", {
						month: month,
					})
			  }`
			: isMonthly
			? `${t("recurrence.month", { count: count })} ${
					!rrule.bynweekday && options.isMultipleDays
						? t("recurrence.fromDay", { day: day })
						: options.isMultipleDays
						? `${t(`recurrence.ordinal.from.${nth}`, { context: weekday === days[6] ? "female" : "male", weekday: weekday })}`
						: rrule.bynweekday || rrule.bysetpos
						? `${t(`recurrence.ordinal.${nth}`, { context: weekday === days[6] ? "female" : "male", weekday: weekday })}`
						: t("recurrence.onDay", { day: day })
			  }`
			: isWeekly
			? `${t("recurrence.week", { count: count })} ${
					options.isMultipleDays
						? t("recurrence.fromWeekday", { weekday: weekday })
						: Array.isArray(rrule.byweekday) && rrule.byweekday?.length > 1
						? t("recurrence.onWeekdays", { weekdays: weekdays })
						: t("recurrence.onWeekday", { weekday: weekday })
			  }`
			: isDaily
			? `${t("recurrence.day", { count: count })}`
			: ""
	}${
		options.shouldShowUntil && rrule.until
			? `, ${t("events.until")} ${DateTime.fromJSDate(rrule.until).toFormat("dd/MM/yy")}`
			: ""
	}`;
}
