import { useMutation } from "@tanstack/react-query";
import { DocumentApi } from "certiblok-api-manager";
import { useApi } from "../../../utils/hooks/api";

export const useUnshareDocumentWithUser = (props: { onSuccess?: () => void } | undefined) => {
	const documentApi = useApi(DocumentApi);
	return useMutation({
		mutationFn: ({ documentId, userId }: { documentId: string; userId: string }) => {
			return documentApi.unshareDocumentWithUser(documentId, { shareUserId: userId });
		},
		onSuccess: () => {
			props?.onSuccess?.();
		},
	});
};

export const useUnshareDocumentsWithUser = (
	props: { onSuccess?: () => void; onMutate?: () => void; onSettled?: () => void } | undefined
) => {
	const documentApi = useApi(DocumentApi);
	return useMutation({
		mutationFn: ({ documentIds, userId }: { documentIds: string[]; userId: string }) => {
			return Promise.all(
				documentIds.map((documentId) => documentApi.unshareDocumentWithUser(documentId, { shareUserId: userId }))
			);
		},
		onMutate: () => {
			props?.onMutate?.();
		},
		onSuccess: () => {
			props?.onSuccess?.();
		},
		onSettled: () => {
			props?.onSettled?.();
		},
	});
};
