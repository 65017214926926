import { fromDate } from "@internationalized/date";
import { Box, Button, FormHelperText, IconButton, Typography, alpha, useTheme } from "@mui/material";
import { VariantProps, cva } from "class-variance-authority";
import clsx from "clsx";
import { DateTime } from "luxon";
import { useCallback, useMemo, useState } from "react";
import type { DateValue, DatePickerProps as ReactAriaDatePickerProps } from "react-aria-components";
import {
	DateInput,
	DateSegment,
	Group,
	I18nProvider,
	Label,
	DatePicker as ReactAriaDatePicker,
} from "react-aria-components";
import { useTranslation } from "react-i18next";
import { returnTextFieldStatusColor } from "../../functions/colors";
import { pxToRem } from "../../functions/theme";
import { useBetterMediaQuery } from "../../hooks/useBetterMediaQuery";
import BottomSheet from "../bottomSheet/BottomSheet";
import CertiblokIcon from "../icons/CertiblokIcon";
import Calendar from "../scrollableCalendar/Calendar";
import MobileCalendar from "../scrollableCalendar/MobileCalendar";

type DatePickerProps = {
	label?: string;
	value?: DateValue;
	onChange?: (value: DateValue, reason: "keyboard" | "picker") => void;
	disabledBefore?: DateValue;
	error?: boolean;
	errorMessage?: string;
} & Omit<ReactAriaDatePickerProps<DateValue>, "value" | "onChange"> &
	VariantProps<typeof containerStyle>;

const containerStyle = cva(
	"rounded-xl mt-1 box-border justify-between cursor-text flex items-center border-[1px] border-solid border-transparent transition-all hover:[filter:brightness(0.90)]",
	{
		variants: {
			size: {
				small: "h-9 px-3 py-2",
				medium: "h-12 py-[14px] px-3 ",
			},
			color: {
				secondary:
					"bg-[rgba(244,250,251,1)] data-[focus-within]:border-secondary data-[focus-within]:shadow-[0_0px_0px_2px_rgba(0,0,0,0.3)] data-[focus-within]:shadow-secondary-a38",
			},
			error: {
				true: "data-[focus-within]:border-error data-[focus-within]:shadow-error-a38",
			},
		},
		defaultVariants: {
			size: "small",
			color: "secondary",
		},
	}
);

const segmentStyle = cva("p-[2px] text-body1 data-[type=literal]:p-0 uppercase data-[placeholder]:opacity-40");

function DatePicker({
	label,
	size,
	color,
	value,
	onChange,
	disabledBefore: _disabledBefore,
	error,
	errorMessage,
	...props
}: DatePickerProps) {
	const { t, i18n } = useTranslation();
	const isMd = useBetterMediaQuery("md");
	const theme = useTheme();

	const [calendarOpen, setCalendarOpen] = useState(false);
	const [selectedCalendarDate, setSelectedCalendarDate] = useState<DateTime>();

	const initialDate = useMemo(() => {
		const dateTimeValue = value ? DateTime.fromJSDate(value?.toDate(DateTime.now().toFormat("z"))) : DateTime.now();
		if (dateTimeValue < DateTime.now()) return DateTime.now();
		return value ? dateTimeValue : undefined;
	}, [value]);

	const disabledBefore = useMemo(() => {
		return _disabledBefore ? DateTime.fromJSDate(_disabledBefore.toDate(DateTime.now().toFormat("z"))) : DateTime.now();
	}, [_disabledBefore]);

	const onCaledarDateChange = useCallback(() => {
		if (selectedCalendarDate?.toJSDate()) {
			onChange?.(fromDate(selectedCalendarDate?.toJSDate()!, selectedCalendarDate.toFormat("z")), "picker");
		}
		setCalendarOpen(false);
	}, [onChange, selectedCalendarDate]);

	return (
		<>
			<I18nProvider locale={i18n.language}>
				<ReactAriaDatePicker
					minValue={fromDate(disabledBefore.toJSDate(), disabledBefore.toFormat("z"))}
					granularity="day"
					value={value ?? null}
					onChange={(value) => {
						onChange?.(value, "keyboard");
					}}
					shouldForceLeadingZeros
					{...props}
				>
					<Label className="text-label">{label}</Label>
					<Group
						className={clsx(
							containerStyle({
								size,
								color,
								error,
							}),
							"relative"
						)}
					>
						<DateInput className={"flex items-center flex-1"}>
							{(segment) => <DateSegment className={segmentStyle()} segment={segment} />}
						</DateInput>
						<IconButton
							disabled={props.isDisabled}
							onKeyDown={(ev) => {
								if (ev.code === "Enter" || ev.code === "Space") {
									ev.stopPropagation();
									ev.preventDefault();
									setCalendarOpen(true);
									setSelectedCalendarDate(initialDate);
								}
							}}
							onClick={(ev) => {
								setCalendarOpen(true);
								setSelectedCalendarDate(initialDate);
							}}
							size="small"
						>
							<CertiblokIcon size={16} color={"black"} name={"▾-arrow-3"} />
						</IconButton>
						<FormHelperText
							sx={{
								position: "absolute",
								top: 0,
								left: 0,
								transform: `translateY(${errorMessage !== undefined && errorMessage !== "" ? "48px" : "24px"})`,
								width: "100%",
								boxSizing: "border-box",
								borderRadius: 1,
								fontSize: pxToRem(10),
								fontWeight: 700,
								lineHeight: "10px",
								letterSpacing: 0.75,
								display: "flex",
								alignItems: "center",
								padding: "4px 8px",
								margin: 0,
								marginTop: 1,
								opacity: errorMessage !== undefined && errorMessage !== "" ? 1 : 0,
								transition: theme.transitions.create(["background-color", "color", "opacity", "transform"]),
								backgroundColor: alpha(returnTextFieldStatusColor(theme, color ?? "secondary", "error"), 0.12),
								color: returnTextFieldStatusColor(theme, color ?? "secondary", "error"),
							}}
						>
							<CertiblokIcon color="inherit" size={12} name={"comunication_outline_03"} iconCss={{ paddingRight: 2 }} />
							{errorMessage}
						</FormHelperText>
					</Group>
				</ReactAriaDatePicker>
			</I18nProvider>
			<BottomSheet
				open={calendarOpen}
				onClose={() => {
					setCalendarOpen(false);
				}}
				HeaderProps={{
					className: "md:pb-6",
				}}
				header={<Typography children={t("events.selectDate")} variant="subtitle1" />}
				footer={
					<Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
						<Button
							variant="outlined"
							color="secondary"
							onClick={() => {
								setCalendarOpen(false);
							}}
						>
							<CertiblokIcon size={24} color="secondary" name={"close"} />
							{t("global.cancel")}
						</Button>

						<Button variant="contained" color="secondary" onClick={onCaledarDateChange}>
							<CertiblokIcon size={24} color="white" name={"success"} />
							{t("global.confirm")}
						</Button>
					</Box>
				}
			>
				<Box
					sx={{
						flexGrow: 1,
						gap: 6,
						px: {
							xs: 5,
							md: 12,
						},
						boxSizing: "border-box",
					}}
				>
					{isMd ? (
						<Calendar disabledBefore={disabledBefore} onDateChange={setSelectedCalendarDate} initialDate={initialDate} />
					) : (
						<div className="flex items-center justify-center pt-6 pb-3 relative">
							<MobileCalendar
								disabledBefore={disabledBefore}
								onDateChange={setSelectedCalendarDate}
								date={selectedCalendarDate}
							/>
						</div>
					)}
				</Box>
			</BottomSheet>
		</>
	);
}

export default DatePicker;
