import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CertiblokIcon from "../../utils/components/icons/CertiblokIcon";
import Loading from "../../utils/components/Loading";
import OutletContainer from "../../utils/components/OutletContainer";
import { useMe } from "../../utils/hooks/useMe";
import { usePlans } from "../Profile/components/admin/hooks/usePlans";
import PlanCard from "../Profile/components/PlanCard";
import ProfileBackButton from "../Profile/components/ProfileBreadcrumbs";
import SyncFolderInstallation from "./components/SyncFolderInstallation";
import SyncFolderInstructions from "./components/SyncFolderInstructions";

type SyncFolderPageProps = {
	children?: React.ReactNode;
};

const SyncFolderPage: React.FC<SyncFolderPageProps> = () => {
	const { t } = useTranslation();

	const { plans, activePlan, plansLoading } = usePlans();

	const planWithSync = plans.find((plan) => plan.hasSyncFolder);

	const { me } = useMe();
	const isAdmin = me?.id === me?.company?.ownerId;

	return (
		<OutletContainer
			sx={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			<div className="pb-6 md:pb-10">
				<ProfileBackButton />
			</div>
			{plansLoading ? (
				<div className="flex-1 flex items-center justify-center">
					<Loading title={t("global.loading")} />
				</div>
			) : (
				<div className="flex flex-col gap-12">
					{activePlan?.metadata?.sync ? (
						<>
							<SyncFolderInstructions />
							<SyncFolderInstallation />
							{/* <SyncFolderFaqs /> */}
						</>
					) : (
						<div className="max-w-screen-md flex flex-col gap-8 ">
							<div>
								<Typography variant="h6" className="font-bold">
									{t("syncFolder.upgradePlan")}
								</Typography>
								<Typography className="mt-2">{t("syncFolder.upgradePlanDescription")}</Typography>
							</div>
							<PlanCard containerSx={{ height: "auto", padding: 0 }} isActive={false} isAdmin={false} plan={planWithSync!} />
							{isAdmin ? (
								<Button
									href="/profile/plans"
									endIcon={<CertiblokIcon name="arrow_direction_outline_right" color="inherit" />}
									color="secondary"
									className="w-full"
									variant="contained"
								>
									{t("cloudImport.goToPlans")}
								</Button>
							) : null}
						</div>
					)}
				</div>
			)}
		</OutletContainer>
	);
};

export default SyncFolderPage;
