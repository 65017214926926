import { alpha, Button, Grid, Typography, useTheme } from "@mui/material";
import { UserApi } from "certiblok-api-manager";
import { Fragment, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingIconButton from "../../../utils/components/buttons/LoadingIconButton";
import CertiblokIcon from "../../../utils/components/icons/CertiblokIcon";
import {
	ModalNavigator,
	useModalNavigator,
	useModalRoute,
} from "../../../utils/components/ModalNavigator/ModalNavigator";
import MLDialog from "../../../utils/components/poppers";
import WhiteModal from "../../../utils/components/WhiteModal";
import { useApi } from "../../../utils/hooks/api";

type Props = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const LogoutModalWithNiceModal = ModalNavigator.create(() => {
	const { visible } = useModalRoute();
	const { popModal } = useModalNavigator();

	return (
		<LogoutModal
			open={visible}
			setOpen={() => {
				popModal();
			}}
		/>
	);
});

const LogoutModal: React.FC<Props> = ({ open, setOpen }) => {
	const { t } = useTranslation();
	const userApi = useApi(UserApi);
	const theme = useTheme();
	const [loading, setLoading] = useState(false);

	const logout = useCallback(() => {
		setLoading(true);

		userApi
			.logout()
			.then(() => {
				localStorage.clear();
				setLoading(false);
				setOpen(false);
				window.location.reload();
			})
			.catch((err) => MLDialog.showSnackbar(t("global.somethingWentWrong"), { variant: "error" }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<WhiteModal
			open={open}
			onClose={() => {
				setOpen(false);
			}}
			containerSx={{
				width: {
					xs: "80vw",
					sm: 500,
					lg: 600,
				},
				borderRadius: 10,
			}}
		>
			<Fragment>
				<CertiblokIcon
					size={48}
					color="error"
					name={"log_outline_01"}
					iconCss={{ padding: 12, backgroundColor: alpha(theme.palette.error.main, 0.12), borderRadius: 56 }}
				/>
				<Typography children={t("profile.logoutFromAllSessionQuestion")} variant="h4" sx={{ paddingTop: 3 }} />
				<Typography
					children={t("profile.logoutFromAllSessionWarning")}
					variant="h6"
					sx={{ paddingTop: 2, fontWeight: 400, opacity: 0.74 }}
				/>

				<Grid container justifyContent="space-between" sx={{ width: "100%", paddingTop: 9 }}>
					<Grid item xs={"auto"} sm={3}>
						<Button
							color="secondary"
							fullWidth
							size="large"
							sx={{ gap: 2 }}
							onClick={() => {
								setOpen(false);
							}}
						>
							<CertiblokIcon size={24} color="secondary" name={"close"} />
							{t("global.cancel")}
						</Button>
					</Grid>

					<Grid item xs={"auto"} sm={3}>
						<LoadingIconButton
							fullWidth
							size="large"
							variant="contained"
							color="secondary"
							icon={<CertiblokIcon size={24} color="white" name={"log_bold_01"} />}
							loading={loading}
							sx={{ gap: 2 }}
							onClick={logout}
						>
							{t("profile.logout")}
						</LoadingIconButton>
					</Grid>
				</Grid>
			</Fragment>
		</WhiteModal>
	);
};

export default LogoutModal;
