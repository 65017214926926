import { alpha, Box, SxProps, Theme, useTheme } from "@mui/material";
import { TABLE_TITLE_HEIGHT } from "../constants/TableTitleHeight";

const TableTitleContainer: React.FC<{ sx?: SxProps<Theme>; className?: string }> = ({ sx, children, className }) => {
	const theme = useTheme();
	return (
		<Box
			className={className}
			sx={{
				width: "100%",
				height: TABLE_TITLE_HEIGHT,
				display: "flex",
				alignItems: "center",
				gap: {
					xs: 1,
					md: 2,
					lg: 3,
				},
				padding: 2,
				boxSizing: "border-box",
				backgroundColor: alpha(theme.palette.secondary.main, 0.06),
				borderTopLeftRadius: "16px",
				borderTopRightRadius: "16px",
				...sx,
			}}
		>
			{children}
		</Box>
	);
};

export default TableTitleContainer;
