import { cn } from "@mabi-ui/utils";
import { Typography } from "@mui/material";
import { Team } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import CertiblokIcon from "./icons/CertiblokIcon";

const TeamAutocompleteOption = ({
	team,
	isDisabled,
	color = "secondary",
}: {
	team: Team;
	isDisabled?: boolean;
	color?: "primary" | "secondary" | "neutral";
}) => {
	const { t } = useTranslation();
	return (
		<div className={cn("w-full flex flex-row gap-[14px] items-center h-9", isDisabled && "opacity-60 cursor-default")}>
			<div
				className="h-7 w-7 rounded-full flex-shrink-0 flex items-center justify-center -ml-[2px]"
				style={{ backgroundColor: team.color }}
			>
				<CertiblokIcon name="profile_outline_01" color="white" size={16} />
			</div>
			<div className="flex flex-col flex-1">
				<Typography
					children={team.name}
					noWrap
					variant="subtitle2"
					color={color === "neutral" ? "black" : color}
					sx={{ width: "100%" }}
				/>
				<Typography
					children={t("teamGroups.nMembers", { count: team.userIds.length })}
					noWrap
					variant="caption"
					color={color === "neutral" ? "rgba(0,0,0,0.6)" : color}
					sx={{ width: "100%" }}
				/>
			</div>
		</div>
	);
};

export default TeamAutocompleteOption;
