import { useQuery } from "@tanstack/react-query";
import { EditRoomApi, EditRoomFolder, Folder, FolderApi, Team, User } from "certiblok-api-manager";
import { useEffect, useState } from "react";
import { instanceApi } from "../../../../../utils/hooks/api";
import { AvailableFolderColors } from "../../../constants/AvailableFolderColors";

export const getUseEditCreateFolderFormKey = (folderId?: string, parentFolderId?: string, isEditRoom?: boolean) => [
	"editCreateInitFolder",
	folderId,
	parentFolderId,
	isEditRoom,
];

export const useEditCreateFolderForm = ({
	folder,
	parentFolderId: _parentFolderId,
	isEditRoom,
}: {
	folder: Folder | undefined;
	parentFolderId?: string;
	isEditRoom?: boolean;
}) => {
	const [folderName, setFolderName] = useState("");
	const [folderColor, setFolderColor] = useState(AvailableFolderColors[0]);

	const [lockFolderEnabled, setLockFolderEnabled] = useState(false);

	const [reservedToUsers, setReservedToUsers] = useState<User[]>([]);
	const [filteredReservedToUserIds, setFilteredReservedToUserIds] = useState<string[]>();

	const [reservedToGroups, setReservedToGroups] = useState<Team[]>([]);
	const [filteredReservedToGroupsIds, setFilteredReservedToGroupsIds] = useState<string[]>();

	const { data, isLoading } = useQuery({
		queryKey: getUseEditCreateFolderFormKey(folder?.id, _parentFolderId, isEditRoom),
		queryFn: async () => {
			// if (!folder.id) {
			// 	return Promise.resolve(null);
			// }
			let folderData: Folder | null = null;
			if (folder) {
				folderData = (await instanceApi(FolderApi).getPlainFolder(folder.id)).data;
			}
			let parentFolder: Folder | EditRoomFolder | null = null;
			const parentFolderId = folder?.parentFolderId ?? _parentFolderId;
			if (parentFolderId) {
				parentFolder = isEditRoom
					? (await instanceApi(EditRoomApi).getPlainEditRoomFolder(parentFolderId ?? "")).data
					: (await instanceApi(FolderApi).getPlainFolder(parentFolderId ?? "")).data;
			}
			return { folderData, parentFolder };
		},
		cacheTime: 0,
		staleTime: 0,
	});

	const isParentRestricted = (data?.parentFolder?.reservedToUsers ?? []).length > 0;

	useEffect(() => {
		setFolderName(data?.folderData?.name ?? "");
		setFolderColor(data?.folderData?.color ?? AvailableFolderColors[0]);
		setLockFolderEnabled(data?.folderData?.isReserved ?? false);
		setReservedToUsers(data?.folderData?.reservedToUsers || data?.parentFolder?.reservedToUsers || []);
		setReservedToGroups(data?.folderData?.reservedToTeams || data?.parentFolder?.reservedToTeams || []);
	}, [data]);

	return {
		folderName,
		setFolderName,
		folderColor,
		setFolderColor,
		lockFolderEnabled,
		setLockFolderEnabled,
		reservedToUsers,
		setReservedToUsers,
		filteredReservedToUserIds,
		setFilteredReservedToUserIds,
		reservedToGroups,
		setReservedToGroups,
		filteredReservedToGroupsIds,
		setFilteredReservedToGroupsIds,
		isParentRestricted,
		isLoading,
		parentFolder: data?.parentFolder,
	};
};
