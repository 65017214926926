import { useQuery } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import { EditRoomApi, Folder } from "certiblok-api-manager";
import MLDialog from "../../../../../utils/components/poppers";
import { IQueryFilter, transformToStringFrom } from "../../../../../utils/components/tables/AdminTable";
import { apiErrorParser, instanceApi } from "../../../../../utils/hooks/api";

export const editRoomFolderQueries = {
	base: ["editRoomFolder"],
	root: () => [...editRoomFolderQueries.base, "root"],
	paginatedRootFolders: (queryFilter: IQueryFilter) => [
		...editRoomFolderQueries.root(),
		transformToStringFrom(queryFilter),
	],
	editRoomFolderById: {
		base: (id: string) => [...editRoomFolderQueries.base, id],
		ancestors: (id: string) => [...editRoomFolderQueries.editRoomFolderById.base(id), "ancestors"],
		paginatedFolder: (id: string, queryFilter: IQueryFilter) => [
			...editRoomFolderQueries.editRoomFolderById.base(id),
			transformToStringFrom(queryFilter),
		],
	},
};

export function useEditRoomFolders(queryFilter: IQueryFilter) {
	return useQuery({
		queryKey: editRoomFolderQueries.paginatedRootFolders(queryFilter),
		queryFn: () => instanceApi(EditRoomApi).getAllEditRooms(transformToStringFrom(queryFilter)),
		select: (res) => {
			const totalFolders = parseInt(res.headers["x-total-count"]);
			return {
				data: res.data,
				totalCount: isNaN(totalFolders) ? 0 : totalFolders,
			};
		},
		onError: (err) => {
			MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
		},
		keepPreviousData: true,
	});
}

export function useEditRoomFoldersById(folderId: string, queryFilter: IQueryFilter) {
	return useQuery({
		queryKey: editRoomFolderQueries.editRoomFolderById.paginatedFolder(folderId, queryFilter),
		queryFn: () => {
			return instanceApi(EditRoomApi).readEditRoomFolderFromParent(folderId, transformToStringFrom(queryFilter));
		},
		select: (res) => {
			return {
				data: res.data,
			};
		},
		onError: (err) => {
			MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
		},
		keepPreviousData: true,
	});
}

export function useAncestorOfEditRoomFolder(folderId: string, onSuccess?: (data: AxiosResponse<Folder[]>) => void) {
	return useQuery({
		queryKey: editRoomFolderQueries.editRoomFolderById.ancestors(folderId),
		queryFn: () => {
			return instanceApi(EditRoomApi).getAncestorsOfEditRoomFolder(folderId);
		},
		onError: (err) => {
			MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
		},
		onSuccess,
		keepPreviousData: true,
	});
}
