import { FormLabel, MenuItem, Select, Typography, menuClasses, selectClasses } from "@mui/material";
import { useRef, useState } from "react";
import { NonUndefined, useController, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { pxToRem } from "../../../../../../utils/functions/theme";
import { EditCreateEventForm } from "../../hooks/useEditCreateEventFormValidator";
import CustomEventReminder from "./CustomEventReminder";

export const availableReminders = ["1-hour", "2-hour", "1-day", "1-week", "1-month", "custom"] as const;

type AvailableReminders = (typeof availableReminders)[number];

type EditCreateEventReminderProps = {};
const EditCreateEventReminder: React.FC<EditCreateEventReminderProps> = () => {
	const { t } = useTranslation();
	const [popperOpen, setPopperOpen] = useState(false);
	const anchorEl = useRef(null);

	const { control } = useFormContext<EditCreateEventForm>();
	const { field: reminderField } = useController({
		control,
		name: "reminder",
	});

	const {
		field: { value: selectedEventReminder, onChange: setSelectedEventReminder },
	} = useController({
		control,
		name: "selectedReminderOption",
	});

	const eventReminderOptions: {
		[key in (typeof availableReminders)[number]]: {
			label: string;
			amount?: number;
			unit?: NonUndefined<EditCreateEventForm["reminder"]>["unit"];
		};
	} = {
		"1-hour": { label: t("events.reminderOptions.hours", { count: 1 }), amount: 1, unit: "hours" },
		"2-hour": { label: t("events.reminderOptions.hours", { count: 2 }), amount: 2, unit: "hours" },
		"1-day": { label: t("events.reminderOptions.days", { count: 1 }), amount: 1, unit: "days" },
		"1-week": { label: t("events.reminderOptions.weeks", { count: 1 }), amount: 1, unit: "weeks" },
		"1-month": { label: t("events.reminderOptions.months", { count: 1 }), amount: 1, unit: "months" },
		custom: { label: t("events.repetition.customize") },
	};

	return (
		<>
			<div ref={anchorEl} className="w-full flex flex-col">
				<FormLabel
					sx={{
						fontSize: pxToRem(12),
						lineHeight: "16.37px",
						letterSpacing: 0.4,
						paddingBottom: 1,
					}}
				>
					{t("events.reminder")}
				</FormLabel>
				<Select
					MenuProps={{
						sx: {
							[`& .${menuClasses.paper}`]: {
								marginTop: 1,
							},
						},
					}}
					value={selectedEventReminder}
					onChange={(ev) => {
						const newValue = ev.target.value as AvailableReminders;
						if (newValue === "custom") {
							return;
						}
						setSelectedEventReminder(ev.target.value as AvailableReminders);
						const selectedReminderOption = eventReminderOptions[newValue];
						if (selectedReminderOption.amount && selectedReminderOption.unit) {
							reminderField.onChange({
								amount: selectedReminderOption.amount,
								unit: selectedReminderOption.unit,
								isAtEventTime: false,
							});
						}
					}}
					renderValue={(value) => {
						return (
							<Typography>
								{value === "custom"
									? `${t("events.repetition.customize")} - ${
											reminderField.value?.isAtEventTime
												? t("events.customReminderOptions.atEventTime")
												: t(`events.reminderOptions.${reminderField.value?.unit ?? "days"}`, {
														count: reminderField.value?.amount ?? 0,
												  })
									  }`
									: eventReminderOptions[value as AvailableReminders].label}
							</Typography>
						);
					}}
					sx={{
						"& .MuiSelect-select": {
							backgroundColor: "rgba(244, 250, 251, 1)",
							height: "48px !important",
							border: "unset",
							paddingLeft: 3,
						},
						[`& .${selectClasses.icon}`]: {
							right: 12,
						},
					}}
					variant="standard"
					size="medium"
					color="secondary"
				>
					<MenuItem value={"1-hour" as AvailableReminders}>
						<Typography variant="body2">{eventReminderOptions["1-hour"].label}</Typography>
					</MenuItem>
					<MenuItem value={"2-hour" as AvailableReminders}>
						<Typography variant="body2">{eventReminderOptions["2-hour"].label}</Typography>
					</MenuItem>
					<MenuItem value={"1-day" as AvailableReminders}>
						<Typography variant="body2">{eventReminderOptions["1-day"].label}</Typography>
					</MenuItem>
					<MenuItem value={"1-week" as AvailableReminders}>
						<Typography variant="body2">{eventReminderOptions["1-week"].label}</Typography>
					</MenuItem>
					<MenuItem value={"1-month" as AvailableReminders}>
						<Typography variant="body2">{eventReminderOptions["1-month"].label}</Typography>
					</MenuItem>
					<MenuItem
						onClick={() => {
							setPopperOpen(true);
						}}
						value={"custom" as AvailableReminders}
					>
						<Typography variant="body2">{eventReminderOptions["custom"].label}</Typography>
					</MenuItem>
				</Select>
			</div>

			<CustomEventReminder
				open={popperOpen}
				onClose={() => {
					setPopperOpen((prev) => !prev);
				}}
				initialValue={reminderField.value}
				key={reminderField.value?.toString()}
				anchorEl={anchorEl.current}
				onSuccess={(value) => {
					setSelectedEventReminder("custom");
					reminderField.onChange(value);
				}}
			/>
		</>
	);
};
export default EditCreateEventReminder;
