import { alpha, Box, IconButton, Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import { CSSProperties, useRef } from "react";
import { useTranslation } from "react-i18next";
import AvatarWithName from "../../../../utils/components/AvatarWithName";
import DocumentWithIcon from "../../../../utils/components/DocumentWithIcon";
import ExpiringProgress from "../../../../utils/components/ExpiringProgress";
import CertiblokIcon from "../../../../utils/components/icons/CertiblokIcon";
import {
	ModalNavigator,
	useModalRoute,
	useModalNavigator,
} from "../../../../utils/components/ModalNavigator/ModalNavigator";
import RowMenu from "../../../../utils/components/poppers/RowMenu";
import { validityPercentage } from "../../../../utils/functions/validity";
import { CertiblokDocument } from "../../../../utils/interfaces/Document";
import { DocumentPopupButton } from "../../../Folders/pages/SingleFolder/components/DocumentPopupMenu";
import RemoveExpireDateModal from "./RemoveExpireDateModal";

type Props = {
	document: CertiblokDocument;
	removeExpirationFunction: () => Promise<void>;
	style?: CSSProperties;
};

const RemoveExpirePopoverMenu = ModalNavigator.create(
	({
		anchorRef,
		removeExpirationFunction,
	}: {
		anchorRef: HTMLElement | null;
		removeExpirationFunction: () => Promise<void>;
	}) => {
		const { visible } = useModalRoute();
		const { pushModal, popModal } = useModalNavigator();

		const { t } = useTranslation();

		return (
			<RowMenu
				open={visible}
				onClose={() => {
					popModal();
				}}
				anchorEl={anchorRef}
			>
				<Box
					sx={{
						boxSizing: "border-box",
						backgroundColor: "surface.main",
						display: "flex",
						flexDirection: "column",
						padding: 1,
						borderRadius: 3,
					}}
				>
					<DocumentPopupButton
						onClick={() => {
							pushModal(RemoveExpireDateModal, { removeExpirationFunction }, { shouldHidePrev: true });
						}}
						icon={"close"}
					>
						{t("inbox.removeExpireDate")}
					</DocumentPopupButton>
				</Box>
			</RowMenu>
		);
	}
);

const ExpireDocumentRow: React.FC<Props> = ({ document, removeExpirationFunction, style }) => {
	const theme = useTheme();
	const isLg = useMediaQuery(theme.breakpoints.up("lg"));
	const isMd = useMediaQuery(theme.breakpoints.up("md"));
	const popoverAnchorRef = useRef<HTMLButtonElement>();
	const { pushModal, popModal } = useModalNavigator();

	return (
		<Box
			style={style}
			sx={{
				boxSizing: "border-box",
				width: "100%",
				paddingTop: "8px !important",
				height: { xs: "auto", md: 48 },
				// height: 48,
			}}
		>
			<Stack
				direction="row"
				gap={2}
				sx={{
					boxSizing: "border-box",
					backgroundColor: "backgroundSecondary.default",
					borderRadius: 3,
					justifyContent: "space-between",
					alignItems: "center",
					px: 2,
					py: 2,
					width: "100%",
					flexWrap: "nowrap",
				}}
			>
				<Tooltip title={document.name}>
					<DocumentWithIcon
						href={`/folders/${document.parentFolderId}/document/${document.id}`}
						onClick={() => {
							popModal();
						}}
						document={document.lastVersionFile?.[0]}
						name={document.name}
						sx={{
							p: 1,
							borderRadius: 2,
							boxSizing: "border-box",
							flex: 1,
							flexGrow: 2,
							minWidth: 0,
							"&:hover": {
								backgroundColor: alpha(theme.palette.secondary.main, 0.12),
							},
						}}
					/>
				</Tooltip>

				{isMd && (
					<AvatarWithName
						isHref
						user={document.owner}
						variant="body2"
						sx={{
							p: 1,
							borderRadius: 2,
							boxSizing: "border-box",
							flex: 1,
							minWidth: 0,
							"&:hover": {
								backgroundColor: alpha(theme.palette.secondary.main, 0.12),
							},
						}}
					/>
				)}
				{isLg && <Typography children={`v${document?.lastVersionFile?.[0]?.version}`} variant="overline" />}
				{document.expireDate && (
					<ExpiringProgress
						percentage={Math.round(validityPercentage(DateTime.fromISO(document.expireDate)))}
						sx={{ minWidth: { md: 150 }, justifyContent: "center" }}
					/>
				)}
				<IconButton
					ref={(ref) => {
						if (ref) {
							popoverAnchorRef.current = ref;
						}
					}}
					onClick={() => {
						pushModal(RemoveExpirePopoverMenu, {
							anchorRef: popoverAnchorRef.current ?? null,
							removeExpirationFunction: removeExpirationFunction,
						});
					}}
				>
					<CertiblokIcon name="more" color="black" size={20} />
				</IconButton>
			</Stack>
		</Box>
	);
};

export default ExpireDocumentRow;
