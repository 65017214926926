import { Button } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import LoadingIconButton from "../../../../../utils/components/buttons/LoadingIconButton";
import CertiblokIcon from "../../../../../utils/components/icons/CertiblokIcon";
import { EditRoomFormValues } from "../hooks/useEditRoomDetailsZodSchema";

type EditCreateEditRoomFooterProps = {
	onClose?: () => void;
	isEdit?: boolean;
	isLoading?: boolean;
	onAction?: () => void;
};

const EditCreateEditRoomFooter: React.FC<EditCreateEditRoomFooterProps> = (props) => {
	const { onClose, isEdit, isLoading, onAction } = props;

	const { t } = useTranslation();

	const { handleSubmit } = useFormContext<EditRoomFormValues>();

	const onSubmit = handleSubmit(() => {
		onAction?.();
	});

	return (
		<div className="flex flex-row justify-between items-center gap-3">
			<Button disabled={isLoading} color="secondary" variant="outlined" size="medium" sx={{ gap: 2 }} onClick={onClose}>
				<CertiblokIcon size={24} color="inherit" name={"close"} />
				{t("global.cancel")}
			</Button>
			<LoadingIconButton
				size="medium"
				variant="contained"
				color="secondary"
				icon={<CertiblokIcon size={24} color="inherit" name={"success"} />}
				loading={isLoading}
				sx={{ gap: 2 }}
				onClick={onSubmit}
				// disabled={folderName === "" || editCreateFolder.isLoading}
			>
				{isEdit ? t("global.saveEdits") : t("editRoom.create")}
			</LoadingIconButton>
		</div>
	);
};

export default EditCreateEditRoomFooter;
