import { Ability } from "@casl/ability";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import React, { useCallback } from "react";

export type CertiblokAbilityActions = "create" | "readOne" | "readAll" | "update" | "delete";

export type CertiblokAbility = Ability<[CertiblokAbilityActions, string]>;

type AbilityContextProps = { ability: CertiblokAbility };

export const AbilityContext = React.createContext<AbilityContextProps>({} as AbilityContextProps);

export const ABILITY_RULES_KEY = "ability-rules";
export const AbilityAtomStorage = atomWithStorage(ABILITY_RULES_KEY, [] as any[]);

const AbilityAtom = atom((get) => {
	return new Ability(get(AbilityAtomStorage));
});

export function useAbility() {
	const ability = useAtomValue(AbilityAtom);
	const resetAbilities = useResetAbilities();

	return { ability, resetAbilities };
}

export function useResetAbilities() {
	const setAbility = useSetAtom(AbilityAtomStorage);
	const resetAbilities = useCallback(() => {
		setAbility([]);
	}, [setAbility]);
	return resetAbilities;
}
