import { Outlet } from "react-router-dom";
import { FolderHistoryContext, useFolderHistoryContext } from "../controllers/FolderHistoryContext";

const FolderHistoryContextProvider = () => {
	const folderHistory = useFolderHistoryContext();
	return (
		<FolderHistoryContext.Provider value={folderHistory}>
			<Outlet />
		</FolderHistoryContext.Provider>
	);
};

export default FolderHistoryContextProvider;
