import { alpha, Box, Button, InputLabel, Stack, Typography } from "@mui/material";
import { Audit, AuditApi } from "certiblok-api-manager";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CreateAuditRoomBS from "../../../../../utils/components/auditRooms/CreateAuditRoomBS";
import OldBottomSheet from "../../../../../utils/components/bottomSheet/OldBottomSheet";
import {
	BottomSheetControls,
	useBottomSheetOld,
} from "../../../../../utils/components/bottomSheet/hooks/useBottomSheet";
import LoadingIconButton from "../../../../../utils/components/buttons/LoadingIconButton";
import DocumentWithIcon from "../../../../../utils/components/DocumentWithIcon";
import CertiblokIcon from "../../../../../utils/components/icons/CertiblokIcon";
import MLDialog from "../../../../../utils/components/poppers";
import { chunkRequests } from "../../../../../utils/functions/chunkRequests";
import { pxToRem } from "../../../../../utils/functions/theme";
import { useApi } from "../../../../../utils/hooks/api";
import type { CertiblokDocument } from "../../../../../utils/interfaces/Document";
import { AuditRoomsContext, useAuditRoomContext } from "../controllers/AuditRoomsContext";
import AuditRoomsInfiniteList from "./AuditRoomsInfiniteList";

type Props = {
	document?: CertiblokDocument | CertiblokDocument[];
	controls: BottomSheetControls;
	onSubmit: (auditRooms: Audit[]) => Promise<any>;
	onHeightChange?: (height?: number) => void;
};

const AuditRoomsBS: React.FC<Props> = ({ document, controls, onSubmit, onHeightChange }) => {
	const { toggleBehind, toggleOpen, open, height, setHeight } = {
		...controls,
	};

	const createAuditRoomControls = useBottomSheetOld();

	const auditRoomsContext = useAuditRoomContext();

	useEffect(() => {
		if (open) {
			auditRoomsContext.selectAuditRooms([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	const { t } = useTranslation();

	const [submitLoading, setSubmitLoading] = useState(false);

	const auditApi = useApi(AuditApi);

	const submit = useCallback(() => {
		if (document) {
			return chunkRequests(
				10,
				(auditRoom) => {
					return auditApi.editAuditRoom(auditRoom.id, {
						documentIds: [
							...(auditRoom.documentIds ?? []),
							...(Array.isArray(document) ? document.map((elem) => elem.id) : [document.id]),
						],
					});
				},
				auditRoomsContext.selectedAuditRooms
			).then((res) => {
				if (res.chunkedErrors.length > 0) {
					MLDialog.showSnackbar(t("auditRoomBS.failedAssignment", { count: res.chunkedErrors.length }), {
						variant: "error",
					});
				}
			});
		}
		return Promise.resolve();
	}, [auditApi, auditRoomsContext.selectedAuditRooms, document, t]);

	return (
		<AuditRoomsContext.Provider value={auditRoomsContext}>
			<OldBottomSheet
				onHeightChange={(height) => {
					onHeightChange?.(height);
					setHeight(height);
				}}
				open={open}
				frontBsHeight={createAuditRoomControls.height ?? 0}
				isBehind={createAuditRoomControls.open}
				containerSx={{ display: "flex", flexDirection: "column" }}
			>
				<Fragment>
					<Typography children={t("folders.assignToAuditRoom", { count: 1 })} variant="subtitle1" />
					<Box sx={{ flexGrow: 1, gap: 6, pt: 6, boxSizing: "border-box" }}>
						<Stack direction="row" gap={2} flexWrap="wrap">
							{document &&
								(Array.isArray(document) ? (
									document.map((document) => (
										<DocumentWithIcon key={document.id} name={document.name} document={document.lastVersionFile?.[0]} />
									))
								) : (
									<DocumentWithIcon name={document.name} document={document.lastVersionFile?.[0]} />
								))}
						</Stack>
						<Box sx={{ display: "flex", alignItems: "center", pt: 6, justifyContent: "space-between" }}>
							<InputLabel
								shrink
								sx={{
									position: "relative",
									transform: "unset",
									fontSize: pxToRem(12),
									lineHeight: "16.37px",
									letterSpacing: 0.4,
									"&.Mui-focused": {
										color: alpha("#000000", 0.6),
									},
								}}
							>
								{t("auditRoomBS.selectAuditRoom", { count: 1 })}
							</InputLabel>
							<Button
								color="secondary"
								size="small"
								onClick={() => {
									toggleBehind();
									createAuditRoomControls.toggleOpen();
								}}
							>
								<CertiblokIcon size={24} color="inherit" name={"add"} />
								{t("auditRooms.create")}
							</Button>
						</Box>
						<Box sx={{ width: "100%", paddingTop: 3, boxSizing: "border-box", position: "relative" }}>
							<AuditRoomsInfiniteList document={Array.isArray(document) ? undefined : document} isModalOpen={controls.open} />
						</Box>
					</Box>
					<Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
						<Button
							variant="outlined"
							color="secondary"
							onClick={() => {
								auditRoomsContext.resetSelectedAuditRooms();
								toggleOpen();
							}}
						>
							<CertiblokIcon size={24} color="secondary" name={"close"} />
							{t("global.cancel")}
						</Button>
						<LoadingIconButton
							loading={submitLoading}
							variant="contained"
							color="secondary"
							onClick={async () => {
								setSubmitLoading(true);
								await submit();
								await onSubmit(auditRoomsContext.selectedAuditRooms);
								setSubmitLoading(false);
								toggleOpen();
							}}
							icon={<CertiblokIcon size={24} color="inherit" name={"success"} />}
						>
							{t("auditRoomBS.insertInAuditRoom")}
						</LoadingIconButton>
					</Box>
				</Fragment>
			</OldBottomSheet>
			<CreateAuditRoomBS
				controls={createAuditRoomControls}
				onHeightChange={(createHeight) => {
					if (createAuditRoomControls.open) {
						onHeightChange?.(createHeight);
					} else {
						onHeightChange?.(height);
					}
				}}
				onSubmit={() => {
					auditRoomsContext.auditRoomsInfiniteLoader.revalidateData();
				}}
			/>
		</AuditRoomsContext.Provider>
	);
};

export default AuditRoomsBS;
