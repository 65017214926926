import { DateTime } from "luxon";
import React, { useCallback, useState } from "react";

type ICalendarContext = {
	selectedDate: DateTime;
	selectDate: (day: Date) => void;
	shouldHideSelectedDate?: boolean;
};

export function useCalendarContext(initialDate?: DateTime, shouldHideSelectedDate?: boolean): ICalendarContext {
	const [selectedDate, setSelectedDate] = useState<DateTime>(initialDate ?? DateTime.now());

	const selectDate = useCallback((day: Date) => {
		setSelectedDate(DateTime.fromJSDate(day));
	}, []);

	return {
		selectedDate,
		selectDate,
		shouldHideSelectedDate,
	};
}

export const CalendarContext = React.createContext<ICalendarContext>({} as ICalendarContext);
