/** @jsxImportSource @emotion/react */
import { Box, Button, Fade, IconButton, Modal, Slide, SxProps, useTheme } from "@mui/material";
import { ReactNode, useContext } from "react";
import { useTranslation } from "react-i18next";
import CreateEventDialog from "../../../pages/Calendar/pages/EditCreateEvent/components/CreateEventDialog";
import { SX_HEIGHT } from "../../constants/TopBarConstants";
import { useModalNavigator } from "../ModalNavigator/ModalNavigator";
import CertiblokIcon from "../icons/CertiblokIcon";
import DayEvents from "./components/DayEvents";
import SmallCalendar from "./components/SmallCalendar";
import Toggles from "./components/Toggles";
import { GlobalCalendarContext } from "./controllers/GlobalCalendarContext";
import { useLocation } from "react-router-dom";
import { useBetterMediaQuery } from "../../hooks/useBetterMediaQuery";

const GlobalCalendar = ({ sx, className, header }: { sx?: SxProps; className?: string; header?: ReactNode }) => {
	const { t } = useTranslation();
	const { selectedDay } = useContext(GlobalCalendarContext);
	const { pushModal } = useModalNavigator();
	const location = useLocation();

	return (
		<Box
			sx={{
				flexShrink: 0,
				width: 357,
				height: "100%",
				overflowY: "scroll",
				overflowX: "unset",
				padding: {
					xs: 6,
					lg: 9,
				},
				paddingBottom: "0px !important",
				boxSizing: "border-box",
				borderRadius: { xs: "48px 48px 0px 0px", sm: "0 48px 0px 0px" },
				backgroundColor: "secondary.main",
				boxShadow: "-12px 6px 24px 0px #5AB3C73D",
				...sx,
			}}
			className={className}
		>
			{header}
			<SmallCalendar />
			<Box className="flex flex-col gap-2 pt-5 w-full">
				<Button
					onClick={() => {
						pushModal(CreateEventDialog, {
							startDate: selectedDay?.toJSDate(),
						}).catch(() => {});
					}}
					size="large"
					fullWidth
					color="secondary"
					sx={{ backgroundColor: "white", "&:hover": { backgroundColor: "white" } }}
					startIcon={<CertiblokIcon name="add" className="text-inherit" size={24} />}
				>
					{t("singleEvent.addNewEvent")}
				</Button>
				{!location.pathname.includes("calendar") && (
					<Button
						href={"calendar"}
						children={t("variousComponents.goToCalendar")}
						size="large"
						fullWidth
						color="secondary"
						variant="text"
						className="text-white bg-secondary-dark"
					/>
				)}
			</Box>

			<Toggles />
			<Fade in={selectedDay !== undefined}>
				<DayEvents />
			</Fade>
		</Box>
	);
};

export const GlobalCalendarModal = () => {
	const { calendarOpen, setCalendarOpen } = useContext(GlobalCalendarContext);
	const theme = useTheme();
	const isSm = useBetterMediaQuery("sm");

	const { t } = useTranslation();

	return (
		<Modal
			open={calendarOpen}
			onClose={() => setCalendarOpen(false)}
			keepMounted
			BackdropProps={{
				sx: {
					backgroundColor: "transparent",
				},
				timeout: 500,
			}}
		>
			<Slide direction="left" in={calendarOpen}>
				<Box
					sx={{
						height: `calc(100% - ${SX_HEIGHT}px)`,
						position: "absolute",
						right: 0,
						bottom: 0,
						zIndex: 1300,
						width: { xs: "100vw", sm: 340 },
					}}
				>
					<GlobalCalendar
						sx={{
							width: { xs: "100vw", sm: 340 },
							// height: {
							// 	xs: `calc(100% - ${SX_HEIGHT}px)`,
							// },
							position: "absolute",
							bottom: 0,
							right: 0,
							transform: { xs: `translateX(${calendarOpen ? 0 : 100}vw)`, sm: `translateX(${calendarOpen ? 0 : 340}px)` },
							transition: theme.transitions.create("transform"),
							boxShadow: "-12px 6px 24px rgba(90, 179, 199, 0.24)",
						}}
						header={
							!isSm && (
								<div className="w-full flex justify-end pb-6">
									<Button
										variant="outlined"
										color="secondary"
										size="small"
										className="text-white border-white"
										onClick={() => setCalendarOpen(false)}
									>
										{t("calendar.closeSideBar")}
									</Button>
								</div>
							)
						}
					/>

					{isSm && (
						<IconButton
							color="secondary"
							// size="small"
							onClick={() => {
								setCalendarOpen(false);
							}}
							sx={{
								width: 46,
								height: 46,
								backgroundColor: "white",
								boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.12)",
								position: "absolute",
								bottom: 16,
								right: { xs: "unset", sm: 340 - 48 / 2 },
								left: { xs: 10, sm: "unset" },
								transform: `translateX(${calendarOpen ? 0 : 340}px)`,
								transition: theme.transitions.create("transform"),
								"&:hover": {
									backgroundColor: "white",
									boxShadow: "0px 3px 14px rgba(0, 0, 0, 0.12)",
								},
							}}
						>
							<CertiblokIcon size={36} color="secondary" name={"arrow_circle_outline_right"} />
							{/* <CertiblokIcon size={36} color="secondary" name={"chart_bulk_02_01"} /> */}
						</IconButton>
					)}
				</Box>
			</Slide>
		</Modal>
	);
};

export default GlobalCalendar;
