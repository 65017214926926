import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

type UseOverrideAuthProps = {
	onAuthOverride?: () => void;
};

export function useOverrideAuth({ onAuthOverride }: UseOverrideAuthProps) {
	const [queryParams, setQueryParams] = useSearchParams();
	const queryClient = useQueryClient();

	const paramsRedisKey = queryParams.get("redisKey");

	useEffect(() => {
		const redisKey = paramsRedisKey;
		if (redisKey) {
			axios.defaults.headers.common["Authorization"] = `${redisKey}`;
			Cookies.remove("CertiblokAccessToken", { domain: "mabiloft.com" });
			if (paramsRedisKey) {
				Cookies.set("CertiblokAuthOverride", paramsRedisKey, {
					expires: DateTime.now().plus({ hours: 1 }).toJSDate(),
					domain: "mabiloft.com",
				});
			}
			const newQueryParams = new URLSearchParams(queryParams);
			newQueryParams.delete("redisKey");
			setQueryParams(newQueryParams, {
				replace: true,
			});
			queryClient.cancelQueries();
			onAuthOverride?.();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paramsRedisKey]);
}

export const AuthOverrider = (props: UseOverrideAuthProps) => {
	useOverrideAuth(props);
	return null;
};
