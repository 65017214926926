import type { AxiosResponse } from "axios";
import { useCallback, useMemo, useRef, useState } from "react";
import MLDialog from "../components/poppers";
import type { FilterObject } from "../components/tables/AdminTable";
import { apiErrorParser } from "./api";

function isAxiosResponse(obj: any): obj is AxiosResponse<any[]> {
	return Boolean(obj);
}

export function useInfiniteLoader<T>(func: (filter: FilterObject) => Promise<AxiosResponse<T[]>>) {
	const [notFetchedYet, setNotFetchedYet] = useState(true);
	const [data, setData] = useState<T[]>([]);
	const [rawData, setRawData] = useState<AxiosResponse<T[]> | undefined>();
	const [firstLoading, setFirstLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const currentPage = useRef(0);

	const fetch = useCallback(
		(page: number) => {
			if (page === 0) {
				setFirstLoading(true);
			}
			setLoading(true);
			currentPage.current = currentPage.current + 1;

			return func({ page })
				.then((res) => {
					setRawData(res);
					if (page === 0) {
						setData(res.data);
					} else {
						setData((prev) => [...prev, ...res.data]);
					}
					setTotalCount(parseInt(res.headers["x-total-count"]));
					setNotFetchedYet(false);
					return res;
				})
				.catch((e) => {
					MLDialog.showSnackbar(apiErrorParser(e), { variant: "error" });
				})
				.finally(() => {
					setLoading(false);
					setFirstLoading(false);
				});
		},
		[func, setData]
	);

	const fetchAll = useCallback(async () => {
		let page = 0;
		const data: T[] = [];
		let totalCount = 0;
		do {
			const res = await fetch(page);
			if (isAxiosResponse(res)) {
				data.push(...res.data);
				totalCount = parseInt(res.headers["x-total-count"]);
			}
			page++;
		} while (data.length < totalCount);
	}, [fetch]);

	const fetchData = useCallback(() => {
		fetch(currentPage.current);
	}, [fetch]);

	const revalidateData = useCallback(() => {
		fetch(0);
		currentPage.current = 0;
	}, [fetch]);

	const hasNextPage = useMemo(() => data.length < totalCount, [data, totalCount]);

	const mutateData = (newData: any, options?: { shouldDelete?: boolean }) => {
		if (Array.isArray(newData)) {
			if (options?.shouldDelete) {
				let deleted = 0;
				setData((prev) =>
					prev.filter((item: any) => {
						const doesExists =
							newData.findIndex((newItem) => (newItem.id ? newItem.id === item.id : newItem === item)) !== -1;
						if (doesExists) {
							deleted++;
						}
						return !doesExists;
					})
				);
				setTotalCount((prev) => prev - deleted);
			} else {
				const newArray = [...data];
				let totalAdded = 0;
				for (const elem of newData) {
					const index = data.findIndex((item: any) => (elem.id ? item.id === elem.id : item === elem));
					if (index !== -1) {
						newArray[index] = elem;
					} else {
						newArray.push(elem);
						totalAdded++;
					}
				}
				setData([...newArray]);
				setTotalCount((prev) => prev + totalAdded);
			}
		} else if (options?.shouldDelete) {
			let hasDeletedOne = false;
			setData((prev) =>
				prev.filter((item: any) => {
					const doesExists = item.id ? item.id === newData.id : item === newData;
					if (doesExists) {
						hasDeletedOne = true;
					}
					return !doesExists;
				})
			);
			if (hasDeletedOne) {
				setTotalCount((prev) => prev - 1);
			}
		} else {
			const dataIndex = data.findIndex((item: any) => {
				return item.id ? item.id === newData.id : item === newData;
			});
			if (dataIndex !== -1) {
				setData((prev) => {
					prev[dataIndex] = newData;
					return [...prev];
				});
			} else {
				setData((prev) => [...prev, newData]);
				setTotalCount((prev) => prev + 1);
			}
		}
	};

	return {
		notFetchedYet,
		data,
		firstLoading,
		loading,
		totalCount,
		fetchData,
		revalidateData,
		hasNextPage,
		rawData,
		mutateData,
		fetchAll,
	};
}
