import { cn } from "@mabi-ui/utils";
import { Typography } from "@mui/material";
import { ChatMessage } from "certiblok-api-manager";
import { DateTime } from "luxon";
import { useCallback } from "react";
import { useMe } from "../../../../../../utils/hooks/useMe";

const SingleMessage = ({ note, isLastNote }: { note: ChatMessage; isLastNote: boolean }) => {
	const { me } = useMe();

	const isMyNote = note.ownerId === me?.id;

	const onMount = useCallback(
		(ref: HTMLDivElement) => {
			if (isLastNote) {
				ref?.scrollIntoView({ behavior: "smooth" });
			}
		},
		[isLastNote]
	);

	return (
		<div className={cn("w-full flex", isMyNote && "justify-end")} ref={onMount}>
			<div
				className={cn(
					"max-w-[min(90%,300px)] px-4 py-2 pb-2 my-[6px] rounded-2xl relative w-fit",
					isMyNote ? "bg-secondary-dark/[0.16] mr-3" : "bg-grey/[0.16] ml-3",
					isLastNote && "mb-3"
				)}
			>
				<Typography className="whitespace-pre-line break-words">{note.content}</Typography>
				<Typography
					variant="label"
					component={"p"}
					noWrap
					className={cn("mt-2 text-black/[0.48] w-fit", isMyNote ? "mr-auto" : "ml-auto")}
				>
					{DateTime.fromISO(note.createdAt).toFormat("dd/LL/yy, HH:mm")}
				</Typography>
			</div>
		</div>
	);
};

export default SingleMessage;
