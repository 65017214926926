import { FormControlLabel, Typography, alpha } from "@mui/material";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import BetterCheckbox from "../../../../../utils/components/BetterCheckbox";
import { EditCreateEventForm } from "../hooks/useEditCreateEventFormValidator";
import { DateTime } from "luxon";

type EditCreateEventAllDayCheckboxProps = {};
const EditCreateEventAllDayCheckbox: React.FC<EditCreateEventAllDayCheckboxProps> = () => {
	const { t } = useTranslation();
	const { control } = useFormContext<EditCreateEventForm>();

	const startDate = useWatch({
		control,
		name: "startDate",
	});

	const endDate = useWatch({
		control,
		name: "endDate",
	});

	const isOnDifferentDate =
		startDate != null &&
		endDate != null &&
		!DateTime.fromJSDate(startDate?.toDate(DateTime.now().toFormat("z"))).hasSame(
			DateTime.fromJSDate(endDate?.toDate(DateTime.now().toFormat("z"))),
			"day"
		);

	return (
		<div>
			<Controller
				control={control}
				name="isAllDay"
				render={({ field }) => {
					return (
						<FormControlLabel
							control={
								<BetterCheckbox
									disabled={isOnDifferentDate}
									checked={field.value || isOnDifferentDate}
									onClick={() => {
										field.onChange(!field.value);
									}}
									color="secondary"
								/>
							}
							label={t("events.isAllDay")}
							sx={{
								m: 0,
								userSelect: "none",
								"& .MuiFormControlLabel-label": {
									lineHeight: "unset",
									fontWeight: 400,
								},
								"& .MuiCheckbox-root": {
									opacity: 0.38,
									transition: (theme) => theme.transitions.create(["opacity"]),
									"&.Mui-checked": {
										opacity: 1,
									},
								},
								"& .MuiButtonBase-root.Mui-disabled > *": {
									backgroundColor: alpha("#000", 0.12),
									"& i": {
										color: alpha("#000", 0.32),
									},
								},
							}}
						/>
					);
				}}
			/>
			{isOnDifferentDate && (
				<Typography component="p" variant="label" className="opacity-60">
					{t("events.mulitpleDayEventsWarning")}
				</Typography>
			)}
		</div>
	);
};
export default EditCreateEventAllDayCheckbox;
