import { Box, styled, switchClasses, Typography } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import CertiblokIcon, { CertiblokIconNames } from "../../icons/CertiblokIcon";
import IOSSwitch from "../../IOSSwitch";
import { GlobalCalendarContext } from "../controllers/GlobalCalendarContext";
import { CalendarEventTypeOfEventEnum } from "certiblok-api-manager";

const CalendarSwitch = styled(IOSSwitch)(({ theme, color }) => ({
	[`& .${switchClasses.switchBase}`]: {
		[`& .${switchClasses.thumb}`]: {
			transition: theme.transitions.create(["background-color", "left", "transform"]),
		},
		"&.Mui-checked": {
			[`& .${switchClasses.thumb}`]: {
				backgroundColor: theme.palette[color ?? "primary"].main,
			},
			"& + .MuiSwitch-track": {
				backgroundColor: "#ffffff",
			},
		},
	},
}));

const SingleToggle = ({
	icon,
	checked,
	onToggle,
	title,
}: {
	icon: CertiblokIconNames;
	checked: boolean;
	onToggle: () => void;
	title: string;
}) => {
	return (
		<Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
			<CertiblokIcon size={24} color="white" name={icon} />
			<Typography children={title} variant="subtitle1" sx={{ color: "white", flexGrow: 1 }} />
			<CalendarSwitch color="primary" checked={checked} onChange={() => onToggle()} />
		</Box>
	);
};

const Toggles = () => {
	const { t } = useTranslation();
	const { visibleEventsTypes, setVisibleEventsTypes } = useContext(GlobalCalendarContext);

	const toggleEventType = (type: CalendarEventTypeOfEventEnum) => {
		setVisibleEventsTypes((visibleTypes) =>
			visibleTypes.includes(type) ? visibleTypes.filter((t) => t !== type) : [...visibleTypes, type]
		);
	};

	return (
		<Box sx={{ display: "flex", flexDirection: "column", gap: 5, paddingTop: 5 }}>
			<SingleToggle
				title={t("variousComponents.auditRooms")}
				icon={"profile_bold_01"}
				checked={visibleEventsTypes.includes("audit_room")}
				onToggle={() => toggleEventType("audit_room")}
			/>
			<SingleToggle
				title={t("global.documents")}
				icon={"paper_bold_02"}
				checked={visibleEventsTypes.includes("document")}
				onToggle={() => toggleEventType("document")}
			/>
			<SingleToggle
				title={t("global.event")}
				icon={"calendar_bold"}
				checked={visibleEventsTypes.includes("event")}
				onToggle={() => toggleEventType("event")}
			/>
		</Box>
	);
};

export default Toggles;
