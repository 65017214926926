/** @jsxImportSource @emotion/react */
import { alpha, Box, IconButton, SxProps, Theme, Typography, useTheme } from "@mui/material";
import type { UserOrContact } from "certiblok-api-manager";
import { backendMediaUrlParser } from "..";
import avatarPlaceholder from "../../assets/avatar-placeholder.png";
import { isUser } from "../../pages/Folders/components/UploadDocument/functions/isUser";
import CertiblokIcon from "./icons/CertiblokIcon";

type UserWithMailProps<T extends UserOrContact> = {
	deletable: boolean;
	onDelete: (user: T | string) => void;
	user?: T | string | null;
	sx?: SxProps<Theme> | undefined;
};

const UserWithMail = <T extends UserOrContact>({ deletable, onDelete, user, sx = {} }: UserWithMailProps<T>) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				width: "100%",
				height: 48,
				display: "flex",
				alignItems: "center",
				boxSizing: "border-box",
				padding: 3,
				backgroundColor: alpha(theme.palette.secondary.main, 0.06),
				borderRadius: 3,
				gap: 1,
				...sx,
			}}
		>
			{isUser(user) ? (
				<img
					src={user.profileUrl ? backendMediaUrlParser(user.profileUrl) : avatarPlaceholder}
					alt={`${user.name} ${user.surname} avatar`}
					css={{ width: 24, height: 24, borderRadius: 12 }}
				/>
			) : (
				<CertiblokIcon size={24} color="secondary" name={"mail_outline"} />
			)}
			<Box sx={{ width: `calc(100% - ${24 + 34 + 4 * 2}px)`, display: "flex", alignItems: "center", minWidth: 0 }}>
				{isUser(user) ? (
					<Typography children={`${user.name} ${user.surname}`} variant="body1" noWrap sx={{ width: `50%` }} />
				) : (
					<Typography children={user} variant="body1" noWrap sx={{ width: `100%` }} />
				)}
				{isUser(user) && (
					<Typography
						children={`${user.email}`}
						variant="caption"
						color="secondary"
						noWrap
						sx={{ width: `50%`, textAlign: "end" }}
					/>
				)}
			</Box>
			{deletable && (
				<IconButton
					size="small"
					color="secondary"
					onClick={() => {
						user && onDelete(user);
					}}
				>
					<CertiblokIcon size={24} color="secondary" name={"canc"} />
				</IconButton>
			)}
		</Box>
	);
};

export default UserWithMail;
