import { Box, alpha, useTheme } from "@mui/material";
import { GeneralNotificationProps } from "./NotificationRow";
import CertiblokIcon from "../../../../icons/CertiblokIcon";
import AvatarWithName from "../../../../AvatarWithName";
import { ParticipationCompany, User } from "certiblok-api-manager";

export const StartIcon: React.FC<GeneralNotificationProps> = ({ notification }) => {
	const theme = useTheme();
	switch (notification.type) {
		case "event_accepted":
		case "event_deleted":
		case "event_invitation":
		case "event_rejected":
		case "event_reminder":
		case "event_updated":
			return (
				<div className="w-9  h-9 flex justify-center items-center shrink-0 bg-white rounded-xl">
					<CertiblokIcon name="calendar_bold" />
				</div>
			);
		case "team_request_retired":
		case "upload_document_request":
		case "new_shared_document":
		case "new_shared_note_document":
		case "updated_document":
		case "update_document_request":
		case "as_auditor":
		case "document_modified":
		case "member_invitation_accepted":
		case "admin_invitation_accepted":
		case "admin_invitation_rejected":
		case "admin_invitation":
		case "edit_room_document_access_request":
		case "document_chat_message":
			return <AvatarWithName withoutName user={notification.content.user as User} size="large" sx={{ height: 36 }} />;
		case "closed_audit_room":
			return <AvatarWithName withoutName user={notification.content.auditor as User} size="large" sx={{ height: 36 }} />;
		case "member_invitation_rejected":
			return notification.content.user ? (
				<AvatarWithName withoutName user={notification.content.user as User} size="large" sx={{ height: 36 }} />
			) : (
				<div className="w-9  h-9 flex justify-center items-center shrink-0 bg-white rounded-xl">
					<CertiblokIcon name="profile_bold_03" />
				</div>
			);
		case "new_team_request":
			return (
				<Box sx={{ position: "relative", width: 36, flexShrink: 0 }}>
					{(notification.content.participations as ParticipationCompany[]).map((user, index) => {
						return (
							index < 2 && (
								<AvatarWithName
									key={`${user.id}_${index}`}
									withoutName
									user={user.owner}
									size={(notification.content.participations as any[]).length > 1 ? "medium" : "large"}
									sx={{
										position: "absolute",
										top: 12 * index,
										left: 12 * index,
										border: index > 0 ? "1px solid #EFF7E6" : "unset",
										borderRadius: 12,
									}}
								></AvatarWithName>
							)
						);
					})}
				</Box>
			);
		default:
			return (
				<Box
					sx={{
						backgroundColor: notification.read ? theme.palette.background.default : "white",
						flexShrink: 0,
						padding: "6px",
						borderRadius: 3,
						height: 36,
						boxSizing: "border-box",
					}}
				>
					<CertiblokIcon
						twoColor
						size={24}
						foreground={{ name: "comunication_bulk_02_01", color: alpha(theme.palette.primary.dark, 0.4) }}
						background={{ name: "comunication_bulk_01_01", color: "primary" }}
					/>
				</Box>
			);
	}
};
