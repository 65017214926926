import { cn } from "@mabi-ui/utils";
import { Button, CircularProgress, Collapse, IconButton, Typography, useTheme } from "@mui/material";
import { ImportStatus } from "certiblok-api-manager";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetCompanyWithMembers } from "../../../../../pages/Profile/pages/Team/hooks/useGetCompanyWithMembers";
import { useMe } from "../../../../hooks/useMe";
import useSocket from "../../../../hooks/useSocket";
import CertiblokIcon from "../../../icons/CertiblokIcon";
import { ScaffoldContext } from "../../controllers/ScaffoldContext";
import SingleImportedDocOrFolder from "./components/SingleImportedDocOrFolder";

const ImportLog = () => {
	const { t } = useTranslation();

	const { importLogVisibility, setImportLogVisibility, importedFiles } = useContext(ScaffoldContext);
	const [importStatus, setImportStatus] = useState<ImportStatus | undefined>();
	const [hasClosedLog, setHasClosedLog] = useState<string[]>([]);

	const { me } = useMe();
	const isAdmin = me?.id === me?.company?.ownerId;

	const { data: company } = useGetCompanyWithMembers();

	const cloudImportCallback = useCallback(
		(status: ImportStatus) => {
			if (hasClosedLog.includes(status.scanId)) return;
			if (!importStatus && importLogVisibility === "none" && !hasClosedLog.includes(status.scanId)) {
				setImportLogVisibility("closed");
			}
			if (!Boolean(status.rootItemId)) {
				setImportStatus(status);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[importLogVisibility, Boolean(importStatus), setImportLogVisibility, hasClosedLog]
	);

	useSocket("cloudImport", cloudImportCallback);

	const theme = useTheme();

	const statusMessageSwitch = () => {
		switch (importStatus?.status) {
			case "scan":
				return t("cloudImport.importStatus.scan");
			case "import":
				return t("cloudImport.importStatus.import", { count: importStatus?.scannedFiles ?? "" });
			case "error":
				return t("cloudImport.importStatus.error");
			case "success":
				return t("cloudImport.importStatus.success");
			default:
				return "";
		}
	};

	if (importStatus?.companyId !== company?.id) {
		return null;
	}

	return (
		<div className="absolute bottom-0 right-16 w-[400px] z-[1500]  bg-background rounded-t-2xl shadow-4">
			<Collapse in={importLogVisibility !== "none"} easing={theme.transitions.easing.easeOut}>
				<div className="w-full flex items-center justify-between px-4 py-3 box-border">
					<div className="flex items-center flex-1 gap-4">
						{importStatus?.status === "success" || importStatus?.status === "error" ? (
							<span
								className={cn(
									"w-6 h-6 rounded-full flex items-center justify-center",
									importStatus?.status === "success" ? "bg-success/[0.24]" : "bg-error-a12"
								)}
							>
								<CertiblokIcon
									name={importStatus?.status === "success" ? "success" : "close"}
									color={importStatus?.status === "success" ? "success" : "error"}
									size={20}
								/>
							</span>
						) : (
							<CircularProgress size={24} color="secondary" />
						)}
						<Typography variant="subtitle1">{statusMessageSwitch()}</Typography>
					</div>
					<div className="flex items-center gap-1">
						<IconButton onClick={() => setImportLogVisibility((p) => (p === "closed" ? "open" : "closed"))}>
							<CertiblokIcon
								name="arrow_mini_outline_down"
								color="black"
								className={cn("transition-transform", importLogVisibility === "closed" && "rotate-180")}
							/>
						</IconButton>
						<IconButton
							onClick={() => {
								setHasClosedLog((prev) => [...prev, importStatus?.scanId ?? ""]);
								setImportLogVisibility("none");
								setImportStatus(undefined);
							}}
						>
							<CertiblokIcon name="close" color="black" />
						</IconButton>
					</div>
				</div>
				<Collapse in={importLogVisibility === "open"}>
					<div className="bg-white ">
						<div className="box-border w-full bg-grey/[0.12] px-3 py-2 flex justify-between items-center">
							<Typography variant="caption">
								{t("cloudImport.totDocsUploaded", {
									loadedCount: importStatus?.processedFiles ?? 0,
									loadingCount: importStatus?.scannedFiles ?? 0,
								})}
							</Typography>
							{/* <Button
								size="small"
								color="secondary"
								className="text-black font-normal underline disabled:bg-grey/[0.38]"
								disabled={importStatus?.status === "success" || importStatus?.status === "error"}
								onClick={() => {
									setImportLogVisibility("closed");
									pushModal(DeleteImportModal);
								}}
							>
								{t("cloudImport.cancelImport")}
							</Button> */}
						</div>
						{importStatus?.status === "error" ? (
							importStatus?.errorCode === "spaceLimitReached" ? (
								<div className="py-6 px-3 flex flex-col gap-3 items-center justify-center w-full box-border">
									<div className="w-12 h-12 rounded-full bg-alert-a12 flex items-center justify-center">
										<CertiblokIcon name="comunication_bold_01" color="#FFD21F" size={32} />
									</div>
									<Typography variant="h6" className="max-w-[250px] text-center font-bold">
										{t("cloudImport.spaceLimitReached")}
									</Typography>
									<Typography className="text-center text-black-a60" variant="caption">
										{t("cloudImport.spaceLimitReachedDescription")}
									</Typography>
									{isAdmin && (
										<Button
											href="/profile/plans"
											onClick={() => {
												setImportLogVisibility("closed");
											}}
											endIcon={<CertiblokIcon name="arrow_direction_outline_right" color="white" />}
											className="w-full mt-4"
											variant="contained"
											color="secondary"
										>
											{t("cloudImport.goToPlans")}
										</Button>
									)}
								</div>
							) : (
								<div className="py-6 px-3 flex flex-col gap-3 items-center justify-center w-full box-border">
									<div className="w-12 h-12 rounded-full bg-error-a12 flex items-center justify-center">
										<CertiblokIcon name="close" color="error" size={32} />
									</div>
									<Typography variant="h6" className="max-w-[250px] text-center font-bold">
										{t("cloudImport.errorInImport")}
									</Typography>
									<Typography className="text-center text-black-a60" variant="caption">
										{t("cloudImport.errorInImportDescription")}
									</Typography>
								</div>
							)
						) : (
							<div className="flex flex-col gap-1 px-3 overflow-y-scroll max-h-[280px] mt-1">
								{importedFiles?.map((file) => (
									<SingleImportedDocOrFolder file={file} key={file.id} isGlobalComplete={importStatus?.status === "success"} />
								))}
							</div>
						)}
					</div>
				</Collapse>
			</Collapse>
		</div>
	);
};

export default ImportLog;
