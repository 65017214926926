import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import NoAuthHeader from "./components/NoAuthHeader";

const NoAuthScaffold = () => {
	return (
		<Fragment>
			<NoAuthHeader />
			<Outlet />
		</Fragment>
	);
};
export default NoAuthScaffold;
